.menu-category-callout {
	@include grid-margin();
	justify-content: center;
	margin-bottom: 32px;
	gap: 32px;

	@include break-min($medium4) {
		gap: 24px;
		margin-bottom: 80px;
	}

	&:last-child {
		margin-bottom: 0px;
	}

	.callout-item {
		border-radius: 32px;
		grid-column: col-start 1 / span 4;
		overflow: hidden;

		a:first-of-type {
			@include break-min($medium4) {
				flex: 50%;
			}
		}

		.g-img-container {
			@extend .menu-mobile-img-container;
			position: relative;

			@include break-min($medium4) {
				height: 100%;
				width: 100%;
			}

			img {
				@include absolute-center();
				height: 100%;
				width: 100%;
			}
		}

		.message-container {
			padding: 0 16px;

			@include break-min($medium4) {
				background: $cool-white;
				// throws off center align of items within box
				height: 100%;

				h1 {
					color: $blue;
					a {
						color: $blue;
					}
				}
			}

			a:not(.btn-round) {
				@include break-min($medium4) {
					pointer-events: none;
				}
			}

			.mobile-toggle {
				@extend .menu-mobile-toggle;

				> :first-child {
					@include break-min($medium4) {
						@include font-size(3.6);
						color: $blue;
						font-weight: $bold-weight;
						line-height: lh(42, 36);
						letter-spacing: -0.5px;
						margin: 0 0 16px;
					}
				}
			}

			.body-text {
				p {
					@extend .menu-mobile-text;

					@include break-min($medium4) {
						@include font-size(1.8);
						font-weight: 400;
						line-height: lh(28, 18);
						letter-spacing: -0.5px;
						margin: 0 0 32px;
					}
				}
			}

			.btn-round {
				display: none;
				min-width: 193px;

				@include break-min($medium4) {
					display: block;
					width: fit-content;
				}
			}
		}

		.mobile-menu-items {
			display: none;
			margin: 32px 0 0;
		}
	}

	&[data-child-count="1"] {
		.callout-item {
			@include break-min($medium4) {
				display: flex;
			}

			@include break-min($medium4) {
				grid-column: col-start 1 / span 8;
			}

			@include break-min($large4) {
				grid-column: col-start 1 / span 12;
			}

			.g-img-container,
			.message-container {
				flex: 50%;
			}

			.message-container {
				@include break-min($medium4) {
					padding: 150px 72px;
				}
			}
		}
	}

	&[data-child-count="2"] {
		.callout-item {
			@include break-min($medium4) {
				grid-column: auto / span 4;
			}

			@include break-min($large4) {
				grid-column: auto / span 6;
			}

			.g-img-container {
				@include break-min($medium4) {
					height: 330px;
				}
			}

			.message-container {
				@include break-min($medium4) {
					padding: 48px 72px;
				}
			}
		}
	}

	&[data-child-count="3"] {
		.callout-item {
			@include break-min($medium4) {
				grid-column: auto / span 4;
			}

			@include break-min($large4) {
				grid-column: auto / span 4;
			}

			.g-img-container {
				@include break-min($medium4) {
					height: 330px;
				}
			}

			.message-container {
				@include break-min($medium4) {
					padding: 46px;
				}

				.body-text p {
					@include break-min($medium4) {
						margin: 0 0 16px;
					}
				}
			}
		}
	}
}
