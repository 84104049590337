.gifting-redeem-qr {
	// sass-lint:disable variable-for-property
	color: pink;
	// sass-lint:enable variable-for-property
	padding: 16px 0 28px;

	.qr-wrapper {
		margin-bottom: 32px;
		padding: 0 73px;
	}

	p {
		@include font-size(2.4);
		color: $red;
		font-weight: bold;
		letter-spacing: -0.01em;
		line-height: calc(32 / 24);
		text-align: center;
	}
}
