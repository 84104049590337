.zipcode-search {
	background: $cool-white;
	padding: 92px 29px 62px;

	@include break-min($large1) {
		padding: 122px 0 72px;
	}

	.title {
		max-width: 960px;
		margin: 0 auto 30px;
		width: 100%;
		padding: 0 32px;
		text-align: center;

		> *:first-child {
			@include type-h1();
			color: $blue;
			text-align: center;
			margin: 0 auto 38px;
			max-width: 1000px;
		}
	}

	input {
		@include font-size(1.6);
		border-radius: 24px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border: none;
		background-color: $white;
		color: $rolling-stone;
		letter-spacing: -0.5px;
		padding: 0 10px 0 16px;
		width: 100%;
	}

	.input-wrapper {
		margin: 0 auto;
		max-width: 562px;
	}

	.btn-round,
	.btn-search {
		margin: 0;
		font-family: $primary;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.5625px;
		padding: 17px 8px 14px;
		width: 100%;

		@include break-min($large1) {
			flex: 65%;
		}

		&-half {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			flex-direction: column;
			display: flex;
			align-items: center;

			&:hover {
				transform: translateY(0px);
			}
		}
	}
}
