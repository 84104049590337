.lll-confirm-location-modal {
	max-width: 550px;

	&.med-width {
		max-width: 600px;

		.vis-lightbox__inner {
			padding: 58px 24px 24px;

			@include break-min($large1) {
				padding: 60px 36px 42px;
			}

			h4 {
				@include break-min($large1) {
					padding: 0 35px;
				}
			}

			.body p {
				margin: -16px 0 -10px;
			}
		}
	}

	.vis-lightbox__inner {
		padding: 58px 24px 24px;
		@include break-min($large1) {
			padding: 60px 40px 34px;
		}

		.vis-lightbox__close:after {
			right: 14px;
			top: 14px;
		}
	}
}
