.homepage-refresh-hero-pwo {
	background: #f1f5f6;
	flex-direction: column;
	padding: 64px 0px 25px;

	@include break-min($large1) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		min-height: 840px;
		padding: 69px 0px 69px 144px;
	}

	.wrapper {
		max-width: 100%;
		padding: 0 25px;
		z-index: 100;

		@include break-min($large1) {
			margin: auto 0;
			max-width: 600px;
			padding: 0;
		}

		p:first-child {
			color: $grey;
			font-size: 18px;
			font-weight: 700;
			line-height: 17px;
			margin-bottom: 16px;

			@include break-min($large1) {
				font-size: 14px;
				line-height: 20px;
				margin-right: 16px;
			}
		}

		p {
			color: $shuttle-grey;
			font-size: 18px;
			letter-spacing: -0.5px;
			line-height: 28px;
			margin-bottom: 24px;
			text-align: center;

			@include break-min($large1) {
				margin-bottom: 40px;
				max-width: 600px;
				text-align: left;
			}
		}

		h1 {
			@include type-h1();
			color: $red;
			font-size: 48px;
			letter-spacing: -0.01em;
			line-height: 48px;
			margin: 0 0 24px 0;
			text-align: center;

			@include break-min($large1) {
				font-size: 78px;
				left: -5px;
				line-height: 84px;
				margin-bottom: 24px;
				position: relative;
				text-align: left;
			}
		}
	}

	.btn-container {
		align-items: center;
		display: flex;
		flex-direction: column;

		@include break-min($large1) {
			flex-direction: row;
		}

		img {
			margin-right: 22px;
		}

		a {
			@include btn-hover-effect();
			align-items: center;
			background: $red;
			border-radius: 12.4838px;
			color: $white;
			display: flex;
			font-size: 14.0442px;
			font-weight: bold;
			height: 64px;
			justify-content: center;
			line-height: 17px;
			margin: 0px 0px 16px 0px;
			text-decoration: none;
			width: 100%;

			@include break-min($large1) {
				max-width: 255px;
			}

			&:first-child {
				margin-right: 0px;

				@include break-min($large1) {
					margin-right: 16px;
				}
			}

			p {
				@include font-size(1.4);
				color: $white;
				position: inherit;
				transform: none;
			}
		}
	}

	.tiled-img {
		@include break-min($large1) {
			margin: auto 0;
			position: inherit;
			right: 0;
			top: 0;
		}

		img {
			width: 100%;
		}

		.mobile-img {
			display: block;

			@include break-min($large1) {
				display: none;
			}
		}

		.desktop-img {
			display: none;

			@include break-min($large1) {
				display: block;
			}
		}
	}
}
