.true-inspiration-learn-more {
	.generic-text-panel {
		background: $cool-white;

		// For static testing - can rmv once on staging
		.btn-container {
			a:nth-of-type(2) {
				display: none;
			}
		}
	}

	.testimonial {
		@extend .testimonial-theme__red;
		margin-bottom: 64px;

		@include break-min($large1) {
			margin-bottom: 88px;
		}
	}

	.nutrients-allergens-callout {
		// For static testing - can rmv once on staging
		.img-wrapper {
			display: none;
		}
	}
}
