.location-page {
	@include break-min($large1) {
		display: flex;
		flex: 0 1 auto;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.frame {
		width: 100%;
		display: flex;
		flex-flow: column;
	}

	.wrapper:not(.footer-modules):not(.account-offers) {
		margin: 0 auto;
		max-width: 1380px;
		padding: 0 24px;
		display: flex;
		flex-flow: column;
		width: 100%;

		@include break-min($large1) {
			flex-flow: row;
			padding: 0 30px;
		}

		.btn-round--red {
			&.icon-opportunity {
				display: block;
				float: left;

				&:after {
					content: "";
					background-image: url("img/openwindowiconwhite.svg");
					background-repeat: no-repeat;
					width: 17px;
					height: 20px;
					background-size: contain;
					float: right;
					margin-left: 10px;
				}
			}
		}
	}

	p {
		@include type-p2();
	}
}
