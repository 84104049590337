.about-us {
	background: $cool-white;
	border-top-left-radius: 40px;
	border-top-right-radius: 40px;

	.gridView {
		@include desktop-grid-max-width();
		@include grid-padding();
	}
}
