.gifting-text-area {
	margin-bottom: 28px;

	@include break-min($large1) {
		grid-column: 2;
		margin-bottom: 15px;
		padding: 0 40px 0 60px;
	}

	@include ie-grid-fix();

	.g-form {
		max-width: none;
		padding: 0;
		text-align: right;

		@include break-min($large1) {
			text-align: left;
		}

		textarea {
			@include font-size(1.8);
			border: 3px solid $faint-grey;
			height: 125px;
			line-height: 1.5;
			margin-bottom: 17px;
			padding: 20px;

			::placeholder {
				color: $light-grey;
			}
		}

		span {
			@include type-p4();
			color: $grey;
			letter-spacing: -0.5px;
			margin-top: 16px;
			text-align: right;
		}
	}
}
