.classic-callout-container {
	@extend .grid-gap;
	margin: 0 0 60px 0;

	&.hide-controls {
		.classic-callout {
			.video-container {
				@include remove-bc-controls();
			}
			.overlay {
				display: block;
			}
		}
	}

	.classic-callout {
		margin: 0;
		padding: 0;
	}

	&[data-has-multiple="true"] {
		@extend .callout-margins;

		@include break-min($huge4) {
			display: flex;
			justify-content: center;
			margin: 0 auto;
		}

		.classic-callout {
			background: $cool-white;
			border-radius: 0px;
			grid-column: col-start 1 / span 4;
			grid-row: auto;
			grid-auto-rows: minmax(min-content, max-content);
			margin: 0;
			position: relative;

			@include break-min($medium4) {
				width: 100%;
			}

			@include break-min($huge4) {
				grid-column-gap: 0;
				justify-content: unset;
			}

			.video-container,
			.img-container {
				@include break-min($huge4) {
					margin-left: 0px;
				}
			}

			.img-container {
				@include break-min($medium4) {
					height: 300px;
					grid-column: col-start 1 / span 8;
				}
			}

			.message-container {
				@include classic-callout-container-heights();

				@include break-min($medium4) {
					grid-column: col-start 1 / span 8;
					grid-row: 2;
					padding: 46px;
				}

				.btn {
					@include break-min($medium4) {
						@include btn-round;
						@include btn-primary-red;
						bottom: 25px;
						margin: auto;
						position: absolute;
					}
				}
			}
		}
	}

	&[data-child-count="2"] {
		&.proportion30-70 {
			@extend .proportion30-70;
		}

		&.proportion70-30 {
			@extend .proportion70-30;
		}

		.classic-callout {
			width: 100%;

			@include break-min($medium4) {
				grid-column: auto / span 4;
			}

			@include break-min($large4) {
				grid-column: auto / span 6;
			}

			@include break-min($huge4) {
				grid-column-gap: 24px;
			}

			.img-container {
				@include break-min($large4) {
					grid-column: col-start 7 / span 6;
					height: 100%;
					// offset gap to keep items flush
					margin-left: -24px;
				}

				@include break-min($huge4) {
					grid-column: col-start 4 / span 3;
				}
			}

			.message-container {
				@include break-min($large4) {
					grid-column: col-start 1 / span 6;
					grid-row: 1;
					margin: 0;
					padding: 32px;
				}

				@include break-min($huge4) {
					grid-column: col-start 1 / span 3;
				}
			}
		}
	}

	&[data-child-count="3"] {
		@include break-min($medium4) {
			display: flex;
		}

		@include break-min($large4) {
			display: grid;
		}

		.classic-callout {
			@include break-min($large4) {
				grid-column-gap: 24px;
				grid-column: auto/span 4;
			}

			@include break-min($huge4) {
				grid-column: auto/span 4;
			}

			.img-container,
			.message-container {
				@include break-min($large4) {
					grid-column: auto/span 12;
				}

				@include break-min($huge4) {
					grid-column: auto/span 4;
				}
			}

			.message-container {
				@include break-min($medium4) {
					padding: 32px;
				}

				@include break-min($large4) {
					// margin-top: -46px;
					padding: 46px;
				}
			}
		}
	}
}
