.promo-cta-tile-img {
	height: 100vh;
	max-height: none;

	@include mobile-long-screen() {
		height: 72vh;
	}

	@include break-min($large1) {
		background-position: center right;
		height: 56vw;
		max-height: calc(100vh - 114px);
	}

	.promo-frame {
		@include break-min($large1) {
			display: flex;
			max-width: none;
			padding: 30px 100px;
		}

		@include break-min($huge1) {
			max-width: 1500px;
		}

		@include break-min($large4) {
			padding: 30px 142px;
		}

		picture {
			@include break-min($large1) {
				margin: auto;
				order: 2;
				width: 50%;
			}

			img {
				margin-top: 70px;

				@include mobile-long-screen() {
					margin-top: 30px;
				}

				@include break-min($large1) {
					margin-top: 0;
				}
			}
		}

		.btn-round {
			@include break-max($large1) {
				width: 100%;
			}
		}
	}

	.promo-content {
		margin-top: 70px;
		text-align: left;

		@include break-min($large1) {
			margin: 0;
		}

		.title {
			@include type-h2(mobileOnly);
			padding-bottom: 24px;

			@include break-min($large1) {
				@include font-size(6.4);
				letter-spacing: -1.5px;
				line-height: lh(74, 64);
			}
		}

		.btn-round {
			margin: 0;
		}
	}
}
