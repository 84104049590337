.signature-drop-landing {
	.header-img-callout {
		@extend .giving-back-callout;
		background: $warm-white;
		margin-bottom: 0;
		padding: 52px 0 72px;

		@include break-min($large1) {
			padding: 52px 100px 72px;
			justify-content: center;
		}

		.img-wrapper {
			margin-bottom: 32px;

			@include break-min($large1) {
				margin-bottom: 0;
			}
		}

		.content {
			@include break-min($large1) {
				margin-right: 125px;
			}

			h4 {
				color: $blue;
			}
		}
	}

	.delivery-directions {
		@extend .delivery-directions-white-bckgrd;
		@extend .delivery-directions-sig-drop;

		h1 {
			padding-bottom: 0;
		}

		.icon-row-txt {
			.icon-wrapper {
				background: $warm-white;
			}
		}
	}

	.accordion {
		@extend .delivery-accordion;
		background: $cool-white;
		margin: 0;
		padding: 64px 24px;

		@include break-min($large1) {
			padding: 112px 0;
		}
	}
}
