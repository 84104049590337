.unsubscribe-submission-message {
	margin-top: -16px;
	padding: 0 20px;

	@include break-min($large1) {
		margin: 46px auto 0;
		max-width: none;
		padding: 0;
		width: $col6;
	}

	@include break-min($large4) {
		width: $col4;
	}

	h3 {
		@include type-h3();
		color: $red;
		font-weight: bold;
		margin-bottom: 32px;
	}

	p {
		@include type-p2();

		a {
			@include link-underline();
		}
	}

	.btn--vis {
		margin: 44px auto 0;

		@include break-min($large1) {
			margin: 32px 0 0;
		}
	}
}
