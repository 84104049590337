.accordion {
	background-color: $cyan;
	padding: 50px 0;

	.accordion-body {
		border-bottom: 1px solid $dkgrey;
		margin-top: 25px;
		overflow: hidden;
		position: relative;

		.toggle {
			@include font-size(2.8);
			border-top: 1px solid $dkgrey;
			color: $red;
			font-family: $primary;
			font-weight: 400;
			letter-spacing: 0;
			line-height: 150%;
			margin: 0;
			padding: 30px 75px 10px 0;
			position: relative;

			@media only screen and (max-width: $mobile) {
				margin: 0 -15px;
				padding: 20px 75px 20px 15px;
			}

			span[tabindex]:focus {
				font-weight: normal;
				outline: -webkit-focus-ring-color auto 5px;
			}

			&:hover {
				cursor: pointer;
			}

			&:after {
				@include font-size(1.8);
				content: "\e902";
				font-family: $icon;
				position: absolute;
				right: 15px;
				top: 20px;
			}

			&.active {
				&:after {
					@include transform(rotate(180deg));
				}
			}
		}

		section {
			display: none;
			overflow: hidden;
			padding-bottom: 0;

			&.scroll {
				overflow-y: scroll;
				padding-bottom: 15px;
			}

			.careers & {
				height: auto;
				overflow-y: visible;
			}

			@media only screen and (max-width: $mobile) {
				margin: 0 -15px;
				padding: 0 15px;
			}

			.faq {
				margin: 0;

				dt {
					margin: 0;
				}
			}
		}
	}

	article {
		max-width: $col8;
		margin: 0 auto;

		@media only screen and (max-width: $mobile) {
			max-width: none;
			padding: 0 15px;
			width: auto;
		}

		h3 {
			@include font-size(2.4);
			letter-spacing: -0.5px;
			line-height: 30px;
			margin: 25px 0 15px;
		}
	}
}
