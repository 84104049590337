.location-listings {
	article {
		padding-top: 50px;

		ul {
			@include flex-direction(column);
			@include flex-wrap(wrap);
			height: 650px;
			display: flex;
			padding: 0;
			margin-bottom: 55px;

			@media only screen and (max-width: $mobile) {
				display: block;
				height: auto;
			}

			li {
				@include link-underline();
				width: 100%;

				@include break-min($large1) {
					width: $col4;
				}
			}
		}
	}

	.location {
		h2 {
			@include link-underline();
			margin: 0;
		}
	}
}

.state-locations-list {
	margin-bottom: 55px;
}
