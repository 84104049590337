.restaurant-openings {
	background: $cool-white;
	padding: 49px 0 22px;
	margin-bottom: 0 52px;

	@include break-min($medium4) {
		// @include grid-margin();
		border-radius: 32px;
		display: grid;
		gap: 24px;
		// grid-template-columns: repeat(12, [col-start] 1fr);
		grid-template-columns: repeat(8, [col-start] 1fr);
		margin-left: 46px;
		margin-right: 46px;
		padding: 41px;
		width: auto;
	}

	@include break-min($large1) {
		margin-top: -80px;
		margin-bottom: 96px;
	}

	// 1200px
	@include break-min($large4) {
		grid-template-columns: repeat(12, [col-start] 1fr);
	}
	// 1728px
	@include break-min($huge4) {
		grid-template-columns: repeat(12, [col-start] 104px);
		margin-left: auto;
		margin-right: auto;
		width: fit-content;
	}

	ul {
		@include grid-margin();
		gap: 22px;
		padding: 0;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
			justify-content: center;
			margin: 0;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 12;
		}

		@include break-min($huge4) {
			grid-template-columns: repeat(12, [col-start] 104px);
			margin-left: auto;
			margin-right: auto;
		}
	}

	li {
		background: $white;
		border-radius: 32px;
		grid-column: col-start 1 / span 4;
		height: 300px;
		list-style-type: none;
		overflow: hidden;
		text-align: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		padding: 10px;

		@include break-min($medium4) {
			grid-column: auto / span 4;
		}

		@include break-min($large4) {
			grid-column: auto / span 3;
		}

		&:first-of-type {
			display: none;

			@include break-min($large1) {
				display: block;
			}
		}

		p {
			&:first-of-type {
				@include type-h2-refresh();
				color: $blue;
				margin: 0 0 8px 0;
			}

			&:nth-of-type(2) {
				@include type-p1();
				margin: 0 0 22px 0;
			}
		}

		.icon-container {
			position: relative;
			height: 30px;
			width: 24px;
			margin: 0 auto 8px;

			img {
				@include absolute-center();
				object-fit: cover;
			}
		}

		a {
			align-self: center;
		}
	}

	.img-tile {
		position: relative;

		img {
			@include absolute-center();
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	&.theme-simple {
		li {
			&:first-of-type {
				@include break-min($large1) {
					display: flex;
				}
			}

			p {
				@include font-size(1.8);
				color: $blue;
				font-weight: 700;
			}
		}
	}
}
