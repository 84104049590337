.delivery-accordion {
	background: inherit;
	margin-bottom: 64px;
	padding: 0 24px;

	@include break-min($large1) {
		margin: -30px auto 96px;
	}

	article {
		padding: 0;
		@include break-min($large1) {
			max-width: $col9;
		}
	}

	h1 {
		@include font-size(3.6);
		color: $blue;
		font-weight: bold;
		letter-spacing: -0.5px;
		line-height: 1.16667;
		margin-bottom: 48px;
		text-align: center;

		@include break-min($large1) {
			margin-bottom: 34px;
			text-align: left;
		}
	}

	.accordion-body {
		border-bottom: 1px solid $light-grey;

		.toggle {
			@include font-size(2);
			border-top: 1px solid $light-grey;
			color: $grey;
			font-weight: normal;
			line-height: 1.5;
			padding: 21px 24px 21px 24px;
			text-align: left;

			@include break-min($large1) {
				@include font-size(2.6);
				padding: 32px 75px 32px 0;
			}

			&:after {
				bottom: 0;
				color: $red;
				display: flex;
				flex-direction: column;
				justify-content: center;
				top: 0;
			}
		}
	}
}
