// sass-lint:disable max-file-line-count

// Menu Calculator (page)
// ------------------------------------------------------ /

.overflow-y-enable {
	@media screen and (max-width: $mobile) {
		overflow-y: scroll;
		width: 100%;
	}

	.disclaimer-txt {
		height: 100%;
		margin: 30px auto 0 auto;
		opacity: 1;
		text-align: center;
		transition: visibility 0s, opacity 0.5s linear;
		width: $col10;
		visibility: visible;

		@media screen and (min-width: $mobile) {
			margin-bottom: 45px;
		}

		@media screen and (min-width: $desktop) {
			width: $col6;
		}
	}
}

.nutrition-allergens-table {
	position: relative;

	main {
		padding: 50px 0;

		@media screen and (min-width: $desktop) {
			margin-bottom: 500px;
		}
	}

	article {
		padding: 0px;
		width: auto;
	}

	.flex-wrapper {
		@media screen and (min-width: $mobile) {
			border-top: 1px solid #eaedee;
			display: flex;
		}

		&.overflow-auto {
			overflow: auto;
		}
	}

	h1 {
		@include h2();
		box-sizing: content-box;
		color: $blue;
		font-weight: 900;
		margin-bottom: 45px;
		text-align: center;

		@media screen and (max-width: $mobile) {
			margin: 0;
			padding: 0;
		}
	}

	caption {
		@include visuallyhidden;
	}

	.no-display {
		display: none;
	}

	.full-width {
		border-bottom: 0;
		width: 100%;
	}

	.home-menu {
		background: $white;
		display: block;
		margin-top: 30px;
		padding-bottom: 17px;
		position: relative;
		text-align: left;
		width: 100%;
		//Desktop view
		@media screen and (min-width: $mobile) {
			border-right: 1px solid #eaedee;
			background: $warm-white;
			position: fixed;
			height: calc(100vh - 64px);
			width: 315px;
			margin-top: 0;
			transition: all 0.7s ease 0s;
			z-index: 29;
			overflow-y: scroll;
		}

		&.lock {
			@media screen and (max-width: $mobile) {
				left: 0;
				border: 0;
				overflow-x: scroll;
				position: fixed;
				top: 34px;
				z-index: 2000;
			}

			@media screen and (min-width: $mobile) {
				top: 63px;
				transition: all 0.7s ease 0s;
			}

			.view-toggle {
				@media screen and (max-width: $mobile) {
					right: 15px;
				}
			}
		}

		ul {
			display: block;
			border-bottom: 1px solid #eaedee;
			border-top: 1px solid #eaedee;
			overflow-x: visible;
			padding: 20px 0 20px 15px;
			white-space: nowrap;

			@media screen and (min-width: $mobile) {
				border: 0;
				margin-left: 0px;
				padding: 0;
				width: 100%;
			}

			@media screen and (max-width: $mobile) {
				overflow-y: scroll;
			}

			li {
				display: inline-block;
				margin: 0 5%;
				text-indent: 0;

				@media screen and (min-width: $mobile) {
					display: block;
					margin: 0 0 15px;
					padding-left: 17px;
					width: 101%;
				}

				&.selected {
					@media screen and (min-width: $mobile) {
						background: $white;
					}
				}
			}

			li:last-child {
				border-left: none;
				padding-left: 0;

				@media screen and (min-width: $mobile) {
					padding-left: 17px;
				}
			}

			a {
				@media screen and (min-width: $mobile) {
					@include p1();
					color: $grey;
				}
			}

			.selected {
				border-bottom: 2px solid $red;

				@media screen and (min-width: $mobile) {
					border-bottom: none;
					color: $blue;
					font-weight: bold;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}

		.btn-group {
			border: 3px solid $red;
			border-radius: 5px;
			position: absolute;
			right: 0;
			top: 0;

			span {
				left: -50px;
				position: absolute;
				top: 13px;
			}
		}

		.btn-switch {
			@include font-size(1.6);
			background: $btn-grad;
			border: 0;
			color: $white;
			display: inline-block;
			font-family: $primary;
			font-weight: 600;
			letter-spacing: -0.5px;
			line-height: 17px;
			padding: 15px 19px;
			text-indent: 0.5px;

			&.white {
				background: $white;
				color: $red;
			}
		}
	}

	.table-wrappers {
		@media screen and (min-width: $mobile) {
			margin-left: 325px;
		}
	}

	.allergens-table-wrapper,
	.nutrients-table-wrapper {
		@media screen and (max-width: $mobile) {
			max-height: 500px;
			max-width: 500vw;
			overflow-x: auto;
			overflow-y: auto;
			position: relative;
		}

		&.no-display {
			height: 0;
			opacity: 0;
			visibility: hidden;
		}
	}

	table {
		position: relative;
		table-layout: fixed;
		word-wrap: break-word;
	}
}

.view-toggle {
	justify-content: center;
	padding-bottom: 25px;
	border-bottom: 0;
	right: 0;
	top: 25px;

	//make focus stand out
	label:focus {
		box-shadow: 0px 0px 3px $red;
	}

	@media screen and (min-width: $mobile) {
		margin-bottom: 25px;
		max-height: 72px;
		position: relative;
	}

	.toggle-switch {
		border: 3px solid $red;
		display: inline-block;
		padding: 10px;
		position: relative;
		text-align: center;
		transition: background 600ms ease, color 600ms ease;

		$border-radius: 8px;

		&.left-rndBrd {
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}

		&.right-rndBrd {
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}

	.toggle {
		display: none;

		& + label {
			color: $red;
			cursor: pointer;
			font-weight: 600;
			width: 46%;

			@media screen and (min-width: $mobile) {
				min-width: 110px;
			}

			&:hover {
				background: none;
				//color: #1a1a1a;
			}

			&:after {
				background: $red;
				content: "";
				height: 100%;
				position: absolute;
				top: 0;
				transition: left 200ms;
				width: 100%;
				z-index: -1;
			}
		}

		&.toggle-left + label {
			border-right: 0;

			&:after {
				left: 100%;
			}
		}

		&.toggle-right + label {
			//margin-left: -5px;
			&:after {
				left: -100%;
			}
		}

		&:checked + label {
			background: $btn-grad;
			cursor: default;
			color: $white;
			transition: color 200ms;

			&:after {
				left: 0;
			}
		}
	}
}

// Menu List Items (tables)
// ------------------------------------------------------ /

.menu-list-nutriAllerg {
	border-collapse: collapse;
	margin: 0 auto;
	width: 100%;

	&.expanded {
		> thead {
			td {
				padding: 0 10px;
			}
		}
	}

	&.additional {
		th {
			div {
				display: none;
			}
		}
	}

	&.modifiers {
		tr:hover {
			border-top: 1px solid #eaedee;
			border-bottom: 2px solid #eaedee;
			transition: 0.5s;

			& td {
				background: none;
			}
		}

		.item {
			margin-top: -15px;
			padding: 25px 10px 0 30px;
		}
	}

	th:nth-child(1),
	td:nth-child(1) {
		width: 210px;

		@media screen and (min-width: $mobile) {
			width: 500px;
		}
	}

	th {
		@include font-size(1);
		border-bottom: 1px solid #eaedee;
		background: $white;
		box-shadow: 0px 3px 0px 0px #eaedee;
		font-family: $primary;
		font-weight: 900;
		height: 110px;
		width: 107px;
		padding-bottom: 30px;
		position: sticky;
		text-align: left;
		text-transform: uppercase;
		white-space: nowrap;
		z-index: 1;

		@media screen and (min-width: $mobile) {
			//top: 137px;
			top: 63px;
			transition: all 0.5s ease 0s;
		}

		&:first-child {
			@include font-size(2.5);
			font-family: $primary;
			height: auto;
			padding-bottom: 5px;
			padding-left: 27px;
			text-transform: none;
			white-space: normal;
			word-wrap: break-word;
			vertical-align: bottom;

			@media screen and (min-width: $mobile) {
				padding-left: 0;
			}
		}

		span {
			display: block;
			padding: 5px 25px;
			width: 35px;

			@media screen and (min-width: $mobile) {
				padding: 5px 10px;
			}
		}
	}

	.tooltip-link {
		position: relative;
	}

	.tooltiptext {
		@include p2();
		background-color: $white;
		border: 2px solid #eaedee;
		border-radius: 6px;
		padding: 10px;
		position: absolute;
		text-align: center;
		transform: none;
		top: 10px;
		visibility: hidden;
		white-space: normal;
		width: 100%; //limited by width of parent container
		z-index: 100000;

		&.menu-header {
			left: 22px;
			top: -53px;
			width: 200px;

			@media screen and (min-width: $desktop) {
				left: 150px;
				top: -35px;
			}
		}

		&.visible {
			visibility: visible;
		}
	}

	.tooltip-link:hover .tooltiptext {
		@media screen and (min-width: $mobile) {
			visibility: visible;
		}
	}

	td {
		@include p2();
		text-align: center;
		padding: 0 15px;

		&:first-child {
			padding: 0;
		}

		&:nth-child(2n) {
			background: $warm-white;
		}

		@media screen and (max-width: 1050px) {
			padding: 0 10px;
		}
	}

	thead {
		@include font-size(1.2);
		font-family: $primary;

		th:nth-child(1) {
			//@include h4();
			@include font-size(2.2);
		}

		h4 {
			font-family: $primary;
			font-size: 16px;
			margin: 0;
		}

		tr {
			border-bottom: 1px solid #eaedee;
		}
	}

	thead th {
		@media screen and (max-width: $mobile) {
			// sass-lint:disable-block no-duplicate-properties
			left: 0;
			position: sticky;
			top: -7px;
			width: 120px;
		}
	}

	tbody {
		@include font-size(1.2);
		font-family: $primary;

		tr {
			height: 60px;
		}

		td {
			@include p2();
			border-bottom: 1px solid #eaedee;
			padding: 15px;
			position: relative;
			text-align: center;
			text-overflow: ellipsis;
			width: 107px;

			&:first-child {
				font-family: $primary;
				padding: -1px 20px 15px 30px;
				text-align: left;
			}
		}

		.item {
			@include font-size(1.6);
			color: $grey;
		}

		.item:not(h3) {
			padding: 25px 10px 0 30px;
		}

		.nutriAllerg-menu-accordion {
			td {
				border-bottom: 0;
			}

			tr {
				border-bottom: 1px solid #eaedee;
			}
		}
	}

	tfoot {
		@include font-size(1.2);
		font-family: $primary;

		td {
			border-bottom: 1px solid #eaedee;
			padding: 15px;
			position: relative;
			text-align: right;
			font-weight: 600;

			&:first-child {
				@include font-size(1.6);
				font-family: $primary;
				padding: 0 20px 0 30px;
				text-align: left;
				width: $col5;
				font-weight: normal;
			}
		}
	}

	.menu-accordion-trigger {
		height: 17px;
		margin: 4px 6px 0 0;
		transform: rotate(-90deg);
		width: 17px;

		&.rotate {
			transform: rotate(0deg);
		}

		&.opaque {
			opacity: 0;
		}

		&:hover {
			cursor: pointer;
		}
	}

	$menu-item-padding-right: 20px;

	.menu-accordion-title {
		@media screen and (max-width: $mobile) {
			padding-right: $menu-item-padding-right;
		}

		a {
			@include link-underline();
			font-weight: normal;
		}
	}

	.customize {
		background: url("img/cp-icons/icon-cp_arrow-down.svg");
		display: block;
		background-repeat: no-repeat;
		left: 0px;
		top: 35%;
		position: absolute;

		span {
			@include visuallyhidden();
		}

		&.rotate {
			transform: rotate(0deg);
		}
	}

	.custom {
		td {
			&:first-child {
				@include align-items(center);
				//display: flex;
				width: auto;
			}
		}

		&.dropdown {
			td {
				&:first-child {
					@include justify-content(space-between);
					//padding-left: 60px;
					padding: 0 $menu-item-padding-right 0 75px;
				}
			}
		}
	}
}

.nutrients-table th span {
	@include transform(translate(15px, 51px) rotate(315deg));
	padding: 5px 17px;

	@media screen and (min-width: $large1) {
		@include transform(translate(-10px, 51px) rotate(315deg));
	}
}

.allergens-table th span {
	@include transform(translate(0, 51px) rotate(315deg));
	padding: 5px 27px;

	@media screen and (min-width: $large1) {
		@include transform(translate(-30px, 51px) rotate(315deg));
		padding: 5px 38px;
	}
}

// Menu Accordions
// ------------------------------------------------------ /
.menu-accordion {
	tbody {
		td {
			border-bottom: 0;
		}
	}

	.menu-accordion-section {
		tr {
			td {
				border-top: 1px solid #eaedee;
			}
		}
	}
}

.mobile-buttons {
	display: none;
}

.table-message {
	margin-top: 15px;
}
