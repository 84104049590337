.location-results-container {
	margin: 40px 0 65px;
	padding: 0 24px;
	position: relative;
	text-align: center;

	@include break-min($large1) {
		margin: 0 auto 96px;
		max-width: 1400px;
		padding: 0 50px;
	}

	@include break-min($large4) {
		padding: 0 100px;
	}

	&.logged-in {
		.delivery-hours {
			@include break-max($large1) {
				.btn-round:nth-of-type(1) {
					display: none;
				}
			}

			.btn-round:nth-of-type(2) {
				@include break-max($large1) {
					display: inline-block;
				}
			}
		}
	}

	.delivery-location-details {
		&:nth-child(2n) {
			@include break-min($large1) {
				padding-top: 100px;
			}
		}
	}

	.btn-round {
		&--white {
			margin: 40px auto 0;
			width: 100%;

			@include break-min($large1) {
				margin: 55px auto 0;
				width: auto;
			}
		}
	}
}
