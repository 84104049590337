.membership-pending-order {
	position: relative;
	background: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
	min-height: 125px;
	padding: 20px 20px 20px 95px;
	margin: 20px auto;
	max-width: 540px;
	text-align: left;

	h6 {
		@include s3();
		text-transform: uppercase;
		margin: 0;
		padding: 0 0 5px;
		color: $vis-blue;
	}

	p {
		@include p1();
		color: $vis-grey;
		padding: 10px 0 1px;
		margin: 0;
		display: block;
	}

	span {
		@include p2();
		color: $vis-grey;
		font-weight: $medium-weight;
		padding: 5px 0 0;
		margin: 0;
		display: block;
	}

	&-icon {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		padding: 20px 10px;
		text-align: center;
		width: 95px;
	}

	&-svg {
		background-color: $iron;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		margin: 0 auto;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		svg {
			width: 100%;
			height: auto;
			display: block;
		}

		&--truck {
			background-image: url("img/cp-icons/icon-cp_truck.svg");
		}

		&--pickup {
			background-image: url("img/cp-icons/icon-cp_pickup.svg");
		}
	}
}
