.location-title-header {
	align-items: center;

	h1 {
		@include font-size(2.8);
		color: $blue;
		font-weight: bold;
		letter-spacing: -0.01px;
		line-height: 1;
		margin: 0 0 10px;

		@include break-min($large1) {
			@include font-size(2.6);
			line-height: 48px;
			margin: 0 0 5px;
			letter-spacing: -1px;
		}

		@include break-min($large3) {
			@include font-size(3.6);
			line-height: 58px;
		}
	}

	.heart-icon {
		@include background-size-cover();
		background-image: url("img/icon_heart-gray.svg");
		margin-right: 7px;
		width: 32px;
		height: 32px;

		@media screen and (max-width: $mobile) {
			margin-right: 5px;
		}
	}

	.heart-icon-red {
		@include background-size-cover();
		background-image: url("img/icon_heart-red.svg");
		margin-right: 7px;
		height: 32px;
		width: 32px;

		@media screen and (max-width: $mobile) {
			margin-right: 5px;
		}
	}
}
