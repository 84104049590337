.cfaOne-program {
	.flippable-cards-callout {
		background: $cool-white;

		.classic-callout {
			margin: 0;

			@include break-min($huge3) {
				margin: auto;
				padding-left: 0;
			}
		}

		.card-classic {
			border-radius: 0 !important;
		}

		.card-classic .message-container {
			@include break-min($huge3) {
				padding-left: 0;
			}
		}

		.card-classic .message-container .title-text {
			@include break-min($large1) {
				margin-bottom: 32px;
			}
		}
	}

	.content-hub-callout .content-hub-callout-wrapper h2 {
		@include break-min($large1) {
			margin-bottom: 26px;
		}
	}

	.content-hub-callout .content-hub-callout-wrapper p {
		@include break-min($large1) {
			margin-bottom: 26px;
		}
	}

	.profile-card .content-container .title > :first-child {
		@include font-size(2.4);

		@include break-min($large1) {
			@include font-size(3.2);
		}
	}

	.profile-card.bckgrd-img .padding-wrapper {
		padding: 48px 24px 15px;

		@include break-min($large1) {
			padding: 72px 0 83px;
		}
	}
}
