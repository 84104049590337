.location-delivery-partners {
	margin-bottom: 15px;

	&.locationv2 {
		padding: 0 23px;
		margin: 0 0 68px 0;

		h3 {
			@include font-size(1.8);
			font-family: $primary;
			font-weight: $bold-weight;
			line-height: lh(22, 18);
			letter-spacing: 0.5px;
			margin: 0 0 36px 0;
			text-align: center;
		}

		ul {
			display: block;
			text-align: center;

			@include break-min($large1) {
				display: flex;
				justify-content: center;
			}

			li {
				margin: 0 0 36px 0;
				max-width: none;

				@include break-min($large1) {
					border-right: 1px solid $faint-grey;
					padding: 5px 24px 5px 0;
					margin: 0 24px 0 0;
				}

				&:last-child {
					@include break-min($large1) {
						border-right: none;
						margin: 0;
						padding: 0;
					}
				}
			}
		}
	}

	@include break-min($large1) {
		margin: 20px 0;
	}

	h3 {
		margin-top: 0;
		margin-bottom: 10px;
		text-transform: uppercase;
		color: $blue;
		@include font-size(1.1);
		@include s3();
	}

	ul {
		align-items: center;
		list-style: none;
		padding: 0;
		justify-content: space-between;
		flex-wrap: wrap;
		@include flexbox();

		li {
			max-width: 100px;
			margin-bottom: 20px;

			&.partner-ubereats {
				height: 20px;

				img {
					height: 15px;
				}
			}

			@include break-min($large1) {
				max-width: 120px;
			}

			@include break-min($large4) {
				max-width: 130px;
			}
		}
	}
}
