.pdp-location-banner {
	background: $cool-white;
	color: $blue;
	display: flex;
	height: 54px;
	padding: 0 24px;
	position: fixed;
	width: 100%;
	z-index: 1000;

	&:focus {
		@include focus($red);
	}

	@include break-min($large1) {
		align-items: center;
		background: transparent;
		margin: 21px 0 0 95px;
		position: unset;
	}

	.pdp-location-icon {
		margin: auto 5px auto 0;
		height: 33px;
		position: relative;
		width: 33px;

		@include break-min($large1) {
			background: $alto;
			border-radius: 50%;
			height: 38px;
			margin: 0 10px 0 0;
			width: 38px;
		}

		img {
			bottom: 0;
			left: 0;
			position: absolute;
			margin: auto;
			right: 0;
			top: 0;
			width: 33px;
		}
	}

	.view-menu-link,
	div:nth-of-type(2) {
		align-items: center;

		@include break-min($large1) {
			align-items: flex-start;
			flex-direction: column;
		}

		p {
			@include font-size(1.6);
			color: $rolling-stone;
			display: none;
			font-weight: 400;
			letter-spacing: -0.5px;
			line-height: lh(24, 16);
			margin: 0;

			@include break-min($large1) {
				display: block;
			}
		}

		h4 {
			margin: 0;

			@include break-min($large1) {
				@include font-size(1.8);
				color: $rolling-stone;
				font-weight: 700;
				letter-spacing: -0.5px;
				line-height: lh(22, 18);
			}
		}
	}
}
