.nutrients-allergens-callout {
	background: $warm-white;
	padding: 69px 24px 60px;
	text-align: center;

	@include break-min($large1) {
		align-items: center;
		height: 260px;
		margin-top: 50px;
		padding: 0 60px;
		text-align: left;
	}

	@include break-min($huge1) {
		padding: 0 144px 0 164px;
	}

	.flex-wrapper {
		justify-content: flex-start;
	}

	.img-wrapper {
		margin-bottom: 16px;

		@include break-min($large1) {
			display: block;
			margin: 0 30px 0 0;
		}
	}

	.content {
		@include break-min($large1) {
			position: relative;
			width: 100%;
		}

		.headline {
			margin-bottom: 16px;

			p {
				color: $blue;
				font-weight: $bold-weight;
				letter-spacing: 0.1px;
			}
		}

		h4 {
			color: $blue;
			margin-bottom: 47px;

			@include break-min($large1) {
				margin: 0;
				max-width: 480px;
			}

			@include break-min($large3) {
				max-width: 680px;
			}
		}

		.btn-round {
			@include break-min($large1) {
				bottom: 0;
				margin: auto;
				max-height: 50px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
}
