.ingredient-steps {
	margin: 56px auto 0;
	max-width: 500px;
	position: relative;

	@include break-min($large1) {
		margin: 60px 0 0 52px;
	}

	.flex {
		align-items: center;
		justify-content: space-between;
	}

	.title {
		p {
			@include font-size(2);
			color: $blue;
			font-weight: $bold-weight;
			line-height: lh(28, 20);
			letter-spacing: -0.2px;

			@include break-min($large1) {
				@include font-size(3.6);
				font-weight: $bold-weight;
				line-height: lh(42, 26);
				letter-spacing: -0.5px;
			}
		}
	}

	.copied-msg {
		display: none;
		position: absolute;
		right: 0;
		top: -10px;
		border: 1px solid $red;
		padding: 2px 10px;
		border-radius: 16px;

		@include break-min($large1) {
			top: 0px;
		}

		p {
			@include font-size(1.2);
			margin: 0;
			letter-spacing: -0.5px;
		}
	}

	button {
		@include font-size(1.2);
		background: transparent;
		border: 0;
		color: $red;
		padding: 0;
		margin: 0;
		letter-spacing: -0.5px;
		line-height: lh(20, 12);

		@include break-min($large1) {
			margin: 0 20px 0 0;
		}
	}

	ol {
		@include font-size(1.8);
		padding: 0;
		margin: 0;
		color: $grey;
		line-height: lh(28, 18);
		letter-spacing: -0.5px;
		margin: 0 15px;

		li {
			margin: 0 0 10px 0;
		}
	}
}
