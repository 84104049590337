.bsr-complex-quiz {
	display: flex;
	flex-flow: column;
	margin: 32px 0px 16px;
	overflow: visible;
	position: relative;

	@include break-min($medium4) {
		margin: 0 46px 16px;
	}

	@include break-min($large1) {
		margin: 0 72px 42px;
	}

	@include break-min($huge4) {
		margin-left: auto;
		margin-right: auto;
		max-width: 1512px;
		width: 100%;
	}

	&-no-results {
		opacity: 1;
		pointer-events: auto;
		display: flex;
		flex-flow: column;
		transition: opacity 0.3s linear 1s;

		&.disabled {
			opacity: 0;
			height: 0;
			pointer-events: none;
		}
	}

	&-results {
		display: flex;
		flex-flow: column;
		opacity: 1;
		pointer-events: auto;
		transition: opacity 0.3s linear 1s;
		height: auto;
		margin-bottom: 40px;

		@include break-min($large1) {
			position: relative;
			display: inline;
			margin-bottom: 98px;
		}

		&.disabled {
			opacity: 0;
			pointer-events: none;
			height: 0;
		}

		&-image {
			// width: 54%;
			position: relative;
			z-index: 2;
			pointer-events: none;

			@include break-min($large1) {
				width: 60%;
			}

			@include break-min($large3) {
				width: 60%;
			}

			@include break-min($large4) {
				width: 52%;
			}

			.bkg-image {
				object-fit: contain;
				width: 100%;

				@include break-min($large1) {
					object-position: 128% 50%;
					-o-object-fit: none;
					object-fit: none;
					width: 100%;
				}

				@include break-min($large3) {
					width: 100%;
				}

				@include break-min($large4) {
					object-position: right 50%;
					object-fit: none;
					width: 100%;
				}

				@include break-min($huge1) {
					object-position: right 50%;
					object-fit: none;
					width: 100%;
				}
			}
		}

		&-totals {
			width: auto;
			display: flex;
			flex-flow: column;
			background-color: $cool-white;
			margin-top: -50px;
			margin-left: 20px;
			margin-right: 20px;
			border-radius: 32px;
			padding: 26px;

			@include break-min($medium1) {
				margin-top: -80px;
			}

			@include break-min($medium2) {
				margin-top: -85px;
			}

			@include break-min($medium3) {
				margin-top: -90px;
			}

			@include break-min($medium4) {
				// margin: 0;
				// padding: 36px 51px;
				// transform: translateY(-50%);
			}

			@include break-min($large1) {
				width: 55%;
				float: left;
				position: absolute;
				top: 50%;
				right: 0;
				z-index: 1;
				border-radius: 16px;
				padding: 19px;
				display: block;
				margin-top: 0;
				transform: translateY(-50%);
			}

			@include break-min($large3) {
				padding: 56px 71px;
			}

			&-score {
				display: flex;
				flex-flow: row;

				&-tally {
					display: flex;
					flex-flow: column;
					justify-content: center;
					border-right: 1px solid $blue;
					padding-right: 19px;

					@include break-min($large1) {
						padding-right: 23px;
						flex: 0 0 20%;
					}

					&-label {
						color: $blue;
						font-weight: 700;
						letter-spacing: -0.5px;
						@include font-size(1.1);

						@include break-min($large1) {
							@include font-size(1.6);
						}
					}

					&-results {
						color: $blue;
						font-weight: 700;
						letter-spacing: -0.5px;
						@include font-size(3.6);
					}
				}

				&-reason {
					margin-left: 19px;
					display: flex;
					align-items: center;

					@include break-min($large1) {
						margin-left: 23px;
					}

					h2 {
						color: $blue;
						margin: 5px 0;
						font-weight: 700;
						letter-spacing: -0.5px;
						line-height: 32px;
						@include font-size(2);

						@include break-min($large1) {
							line-height: 42px;
							@include font-size(3.6);
						}
					}
				}
			}

			&-suggestion {
				margin: 21px 0;

				@include break-min($medium4) {
					margin: 32px 0;
				}

				p {
					color: $grey;
					letter-spacing: -0.5px;
					@include font-size(1.8);
				}
			}

			&-ctas {
				display: flex;
				flex-flow: column;
				row-gap: 14px;
				align-items: flex-start;

				@include break-min($medium4) {
					flex-flow: row;
				}

				.btn {
					margin-right: 18px;
					display: inline-block;
					width: auto;

					&:first-child {
						@include btn-round;
						@include btn-primary-red;
					}
				}
			}
		}
	}

	&-image {
		height: 267px;

		@include break-min($medium1) {
			height: 404px;
		}

		@include break-min($medium4) {
			height: 504px;
		}

		@include break-min($large1) {
			height: 604px;
		}

		.bkg-image {
			min-height: 172px;
			object-fit: cover;
			width: 100%;
			overflow: hidden;
			border-radius: 0;
			height: 100%;

			@include break-min($medium4) {
				border-radius: 32px;
			}
		}
	}

	.bsr-complex-quiz-questions-wrapper {
		background-color: $cool-white;
		border-radius: 16px;
		padding: 40px 19px;
		margin: 0;
		padding-top: 0;
		transform: translateY(-40px);

		@include break-min($medium4) {
			margin: 0 60px;
			border-radius: 32px;
			padding: 40px 60px;
			transform: translateY(-50px);
		}

		&-count {
			display: flex;

			ul {
				display: flex;
				flex-flow: row;
				list-style: none;
				padding: 0;

				margin-bottom: 0;
				margin-top: -26px;

				@include break-min($medium4) {
					margin-top: 12px;
					margin-bottom: 28px;
				}

				li {
					margin-right: 20px;
					background-color: $cool-white;
					border-radius: 9999px;
					color: $blue;
					border: 1px solid $blue;
					padding: 23px;
					width: 20px;
					height: 20px;
					opacity: 0.2;
					display: none;
					align-items: center;
					justify-content: center;
					font-weight: 700;
					@include font-size(2.1);

					&:not(:first-child) {
						display: none;
					}

					@include break-min($medium4) {
						display: flex;

						&:not(:first-child) {
							display: flex;
						}
					}

					&.current {
						background-color: $blue;
						color: $white;
						opacity: 1;
						display: flex;
					}

					&.completed {
						opacity: 1;
					}
				}
			}
		}
	}

	.bsr-complex-quiz-question {
		h3 {
			line-height: lh(13, 10);
			font-weight: 700;
			letter-spacing: -0.24px;
			color: $blue;
			@include font-size(2.4);
		}
	}

	.bsr-complex-quiz-questions {
		ul {
			display: flex;
			flex-flow: column;
			list-style: none;
			padding: 0;
			margin: 40px 0;

			li {
				border-radius: 32px;
				background-color: $bsr-blue;
				line-height: 22px;
				font-weight: 700;
				letter-spacing: -0.5px;
				color: $blue;
				margin-bottom: 20px;
				padding: 20px 26px;
				cursor: pointer;
				@include font-size(1.4);

				&:last-child {
					margin-bottom: 0;
				}

				@include break-min($medium4) {
					@include font-size(1.8);
				}

				&:after {
					content: "";
					background-size: cover;
					bottom: 0;
					position: absolute;
					width: 38px;
					height: 38px;
					display: flex;
					align-items: center;
					transform: translateY(-50%);
					top: 50%;
					right: 11px;
					background-repeat: no-repeat;
					background-position: center center;
				}

				&.disabled {
					pointer-events: none;
				}

				&.selected {
					background: rgba(0, 79, 113, 0.2);
					position: relative;
				}

				&.correct {
					background: rgba(0, 99, 91, 0.2);
					position: relative;

					&:after {
						content: "";
						background-image: url("img/bsr-quiz-checkmark.svg");
					}
				}

				&.error {
					background: rgba(221, 0, 49, 0.1);
					position: relative;

					&:after {
						content: "";
						background-image: url("img/bsr-quiz-error.svg");
					}
				}
			}
		}
	}

	.bsr-complex-quiz-explaination {
		margin: 20px 0;

		@include break-min($medium4) {
			margin: 40px 0;
		}

		p {
			color: $blue;

			a {
				color: inherit;
				text-decoration: underline;
			}
		}
	}

	.bsr-complex-quiz-cta {
		display: inline-block;

		a {
			@include btn-round;
			@include btn-primary-red;
		}
	}

	.submitBtn {
		display: block;

		&.disabled {
			display: none;
		}
	}

	.nextBtn {
		display: block;

		&.disabled {
			display: none;
		}
	}

	.showResultBtn {
		display: block;

		&.disabled {
			display: none;
		}
	}
}
