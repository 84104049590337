.true-inspiration-landing {
	.generic-text-panel {
		padding-bottom: 0;
		padding-top: 15px;

		@include break-min($large1) {
			padding-bottom: 57px;
			padding-top: 96px;
		}
	}

	.covid-sticky-nav-dropdown.desktopNotSticky {
		margin-top: 23px;

		@include break-min($large1) {
			background: transparent;
			max-width: $max-width;
			padding: 0 32px;
		}

		@include break-min($huge1) {
			padding: 0 142px;
		}
	}

	.dropdown {
		@include break-min($large1) {
			border-top: 1px solid $faint-grey;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			max-width: $max-width;
		}
	}

	.scroll {
		display: none;
	}

	.header-img-callout {
		margin-top: 64px;
		padding-top: 79px;

		@include break-min($large1) {
			height: auto;
			margin-top: -68px;
			padding-bottom: 87px;
			padding-top: 157px;
		}

		&.stacked {
			.content {
				display: flex;
				flex-flow: column;
				align-items: flex-start;
				align-items: center;

				@include break-min($large1) {
					align-items: flex-start;
				}

				.headline {
					margin: 0 0 11px;

					p {
						line-height: 1;
					}
				}

				h4 {
					max-width: none;
					line-height: 1;
				}

				.btn-container {
					margin-top: 16px;

					a {
						position: relative;
					}
				}
			}
		}
	}

	.giving-back-callout {
		@include break-max($large1) {
			margin-top: 0;
			padding-top: 0;
		}

		@include break-min($large1) {
			margin-left: 144px;
		}

		.content {
			h4 {
				@include font-size(2.8);
				color: $blue;

				@include break-min($large1) {
					@include font-size(4.8);
				}
			}
		}
	}

	.footer .header-img-callout {
		margin-top: 0;
	}
	&.community-scholars,
	&.remarkable-futures {
		.generic-text-panel {
			.wrapper {
				max-width: 960px;

				@include break-min($large1) {
					max-width: 1200px;
				}
			}

			h1 {
				@include font-size(4.8);
				line-height: 54px;
				color: $red;

				@include break-min($large1) {
					@include font-size(8.4);
					line-height: 84px;
				}
			}
		}

		.profile-card {
			.content-container {
				.title {
					> :first-child {
						@include font-size(2.4);
						line-height: 1;

						@include break-min($large1) {
							@include font-size(3.6);
							line-height: 1;
						}
					}
				}
			}
		}
	}
}
