.delivery-method {
	.delivery-method-callout {
		margin: 0;
		position: relative;
		padding-bottom: 35px;

		@include break-min($large1) {
			@include swap-div-order();
			align-items: center;
			height: 542px;
			justify-content: space-around;
			padding: 0 33px;
		}

		.img-wrapper {
			height: 187px;
			margin-bottom: 0;
			width: 100%;

			@include break-min($large1) {
				flex-basis: 50%;
				height: auto;
				margin-bottom: 29px;
			}

			img {
				object-fit: cover;
				width: 100%;

				@include break-min($large1) {
					width: auto;
				}
			}
		}

		.content {
			padding: 40px 24px;

			@include break-min($large1) {
				flex-basis: 50%;
				margin: auto;
				max-width: 635px;
				padding: 0 24px 0 57px;
			}

			h4 {
				@include font-size(2.8);
				color: $blue;
				letter-spacing: -1%;
				line-height: lh(34, 28);
				margin: 0 auto 16px;

				@include break-min($large1) {
					@include font-size(4.8);
					letter-spacing: -1px;
					line-height: lh(58, 48);
					margin-bottom: 24px;
				}
			}

			p {
				@include font-size(1.8);
				letter-spacing: -0.5px;
				line-height: lh(28, 18);
				margin-bottom: 40px;
			}

			a:not(.btn-round) {
				display: none;
			}
		}

		.optionalDisclaimer {
			@include break-min($large1) {
				bottom: 0;
				left: 50%;
				position: absolute;
				transform: translateX(-50%);
				width: 100%;
			}

			p {
				@include font-size(1.4);
				font-weight: $regular-weight;
				letter-spacing: -0.25px;
				line-height: lh(23, 14);
				margin: 0 auto;
				padding: 0 24px;
				text-align: center;

				@include break-min($large1) {
					margin: 0 auto 24px;
				}
			}
		}
	}
}
