.covid-sticky-nav-dropdown {
	.dropdown {
		background: $white;
		// sass-lint:disable no-vendor-prefixes
		-ms-overflow-style: none;
		// sass-lint:enable no-vendor-prefixes
		padding: 0 24px;
		position: relative;
		overflow-y: scroll;
		animation-delay: 0.6s;

		&::-webkit-scrollbar {
			display: none;
		}

		@include break-min($large1) {
			box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
			display: flex;
			margin: auto;
			max-height: 100%;
			overflow-y: hidden;
			padding: 0;
			scroll-behavior: smooth;
			z-index: 0;
		}

		.link {
			@include dropdown-transition(1, 5);
			display: none;
			opacity: 0;
			cursor: pointer;
			flex: 1 1 0px;
			padding: 15px 0 15px 20px;
			position: relative;

			@include break-min($large1) {
				@include remove-dropdown-transition(5);
				display: block;
				opacity: 1;
				min-width: 240px;
				padding: 30px 36px;
			}

			&.active {
				@include break-min($large1) {
					background: $cool-white;
					margin-left: -9px;
					padding-left: 36px;

					&:before {
						background-image: none;
					}
				}
			}

			&:before {
				@include dot-border-h();

				@include break-min($large1) {
					@include dot-border-h(bottom, right);
					bottom: 0;
					height: 50%;
					margin: auto;
					left: 0;
					right: 0;
					top: 0;
				}
			}

			&:first-child {
				@include break-min($large1) {
					padding-left: 36px;
				}
			}

			&:last-child {
				@include break-min($large1) {
					min-width: 270px;
				}

				&:before {
					background-image: none;
				}

				@include break-min($large1) {
					margin-right: 0;
					padding-right: 36px;
				}
			}

			a {
				display: flex;
				position: relative;
				transform: scale(1);
				transition: all 200ms ease-in;

				&:hover {
					transform: scale(1.05);
				}

				@include break-min($large1) {
					display: grid;
					justify-content: center;
					grid-template-columns: 75px auto;
				}
			}

			.img-wrapper {
				height: 75px;
				position: relative;
				width: 75px;
			}

			img {
				border-radius: 50%;
				bottom: 0;
				height: 100%;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
			}

			p {
				@include font-size(1.8);
				align-self: center;
				color: $blue;
				font-weight: bold;
				letter-spacing: -0.5px;
				line-height: lh(24, 18);
				margin: 0 0 0 10px;

				@include break-min($large1) {
					margin: 0 0 0 17px;
				}
			}
		}
	}
}
