.header-img-list-themes {
	&__cream {
		background: $warm-white;
	}

	&__cool-white {
		background: $cool-white;
	}

	&__blue {
		background: $blue;

		.headline {
			p {
				color: $white;
			}
		}

		.content {
			h4 {
				color: $white;
			}
		}

		.btn-round {
			@extend .btn-round--white;
		}
	}

	&__red {
		background: $red;

		.headline {
			p {
				color: $white;
			}
		}

		.content {
			h4 {
				color: $white;
			}
		}

		.btn-round {
			@extend .btn-round--white;
		}
	}

	&__alternate-row {
		@include break-min($large1) {
			.img-callout-wrapper {
				li {
					&:nth-child(2n) {
						.img-callout {
							img {
								margin: 0 0 0 50px;
								order: 2;
							}
						}
					}
				}
			}
		}
	}
}
