.covid-search-callout {
	padding: 80px 20px;
	background-color: $bkgrey;
	text-align: left;

	@include break-min($large1) {
		padding: 90px 200px;
		text-align: center;
	}

	.form-wrapper {
		@include break-min($large1) {
			margin: 0 100px;
		}

		form {
			display: flex;
			margin: 30px auto;

			@include break-min($large1) {
				margin: 40px auto;
			}

			input {
				background-color: $white;
				border-radius: 24px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border: none;
				padding: 0 32px;
				width: 100%;
			}

			.btn-round,
			.btn-search {
				margin: 0;
				font-family: $primary;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				letter-spacing: -0.5625px;
				padding: 12px 32px;
				width: auto;

				@include break-min($large1) {
					padding: 15px 48px;
				}

				&-half {
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
					flex-direction: column;
					display: flex;
					align-items: center;

					&:hover {
						transform: translateY(0px);
					}
				}
			}
		}
	}

	span {
		color: $white;
		font-family: $primary;
		letter-spacing: -0.5px;
		font-weight: bold;
		font-size: 18px;

		@include break-min($large1) {
			font-size: 14px;
		}
	}

	.covid-content {
		margin: 0 auto;

		@include break-min($large1) {
			max-width: 720px;
		}

		p {
			line-height: 23px;
			font-size: 18px;

			@include break-min($large1) {
				text-align: center;
			}

			&:last-child {
				font-size: 14px;
				text-align: center;

				@include break-min($large1) {
					margin: 0 100px;
				}
			}
		}

		h2 {
			color: $blue;
			font-family: $primary;
			font-weight: bold;
			font-size: 28px;
			margin: 0 0 24px;
			padding: 0;
			text-align: left;
			letter-spacing: -0.01px;

			@include break-min($large1) {
				font-size: 30px;
				letter-spacing: -0.8px;
				margin: 0 0 20px;
				text-align: center;
			}

			@include break-min($desktop) {
				font-size: 48px;
			}
		}
	}
}
