// VIS lightbox
// ==========================================================================

// this lightbox class has the new VIS styles (used initially on
// consolidated profile)

.vis-lightbox {
	padding: 10px;
	box-sizing: border-box;

	* {
		box-sizing: inherit;
	}

	h2 {
		@include h3();
		color: $vis-blue;
		padding: 0 0 20px;
		margin: 0 auto;
	}

	h3 {
		@include h3(mobile-only);
		color: $vis-blue;
		padding: 0 0 20px;
		margin: 0 auto;
	}

	p {
		color: $vis-grey;
		display: block;
		margin: 0 auto;
		padding: 0 0 35px;
		@include p1();

		&:last-child {
			padding: 0;
		}
	}

	&__inner {
		max-width: 700px;
		position: relative;
		background-color: $white;
		border-radius: 8px;
		padding: 60px 15px;
		margin: auto;
		box-sizing: border-box;

		&--medium {
			max-width: 940px;
		}

		&--wide {
			max-width: 1176px;
		}
	}

	&__textwrap {
		max-width: 450px;
		margin: 0 auto;
	}

	&__close {
		width: 60px;
		height: 60px;
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
		padding: 0;
		z-index: $z2;

		&::after {
			background-image: url("img/cp-icons/icon-cp_close.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 16px;
			height: 16px;
		}

		&:focus {
			outline: 1px dotted $red;
		}

		&--white {
			&::after {
				background-image: url("img/cp-icons/icon-cp_close-white.svg");
			}
		}
	}

	&.payment-override {
		text-align: center;

		.cta-modal__text {
			max-width: 700px;
		}

		.dfl-modal__location {
			display: flex;
			flex-direction: row;
			justify-content: center;
			min-height: 0;
			padding: 20px 0;

			h4 {
				text-transform: uppercase;
			}

			h4,
			p {
				@include type-p3();
				padding: 0;
				color: $grey;
			}
		}

		img {
			height: 35px;
			width: 50px;
			margin-right: 20px;
		}

		.card-details {
			margin: auto 0;
		}
	}

	@include break-min($small3) {
		padding: 20px;
	}
}
