.codemoo-carousel {
	margin: 0 auto;
	margin-top: 10px;
	margin-bottom: 60px;
	text-align: center;
	max-width: 750px;

	@include break-min($large1) {
		margin-top: 100px;
		margin-bottom: 100px;
	}

	&__header {
		padding: 0 20px;
		display: flex;
		flex-flow: column;

		h2 {
			color: $blue;
			@include font-size(2.8);
			font-family: $primary;
			font-weight: $bold-weight;

			@include break-min($large1) {
				@include font-size(4.8);
			}
		}
	}

	&__carousel {
		@include break-min($large1) {
			padding: 40px 0 0;
			max-width: 700px;
			margin: 0 auto;
		}

		.carousel-item {
			&-content {
				max-width: 408px;
				margin: 0 auto;
				margin-left: 30px;
				margin-right: 30px;

				@include break-min($large1) {
					margin: 0 auto;
				}
			}

			h5 {
				color: $blue;
				@include font-size(2.8);
				font-family: $primary;
				font-weight: $bold-weight;
				margin: 20px;

				@include break-min($large1) {
					@include font-size(4.8);
					margin: 25px;
				}
			}

			img {
				margin: 0 auto;
			}
		}
	}

	.slick-dots {
		margin-top: 30px;
		position: inherit;

		@media screen and (min-width: $mobile) {
			margin-top: 50px;
		}

		li {
			button {
				background-color: $rolling-stone;
				border: none;
				border-radius: 100%;
				display: block;
				width: 1rem;
				height: 1rem;
				padding: 0;
				text-indent: -9999px;

				&:before {
					color: $rolling-stone;
				}
			}

			&.slick-active {
				button {
					background: $red;

					&:before {
						color: $red;
					}
				}
			}
		}
	}

	// .slick-dots li button {
	// 	background-color: $rolling-stone;
	// 	border: none;
	// 	border-radius: 100%;
	// 	display: block;
	// 	width: 1rem;
	// 	height: 1rem;
	// 	padding: 0;
	// 	text-indent: -9999px;
	// }

	// .slick-dots li.slick-active button {
	// 	background: $red;
	// }

	// .slick-dots li button:before {
	// 	color: $rolling-stone;
	// }

	// .slick-dots li.slick-active button:before {
	// 	color: $red;
	// }
}
