.care-title {
	padding: 58px 24px;

	@include break-min($large1) {
		text-align: center;
		padding: 82px 0 48px;
		max-width: 610px;
		margin: 0 auto;
	}

	h1 {
		@include type-h1();
		color: $red;
		font-weight: $bold-weight;
		line-height: lh(54, 48);
		letter-spacing: -1px;
		margin: 0 0 32px 0;

		@include break-min($large1) {
			@include font-size(7.8);
			margin: 0 0 48px 0;
		}
	}

	p {
		@include type-p1(desktopOnly);
		color: $grey;
		font-weight: 400;
		margin: 0;
		line-height: lh(28, 18);
		letter-spacing: -0.5px;
	}
}
