.delivery-lookup {
	background: $white;
	text-align: center;
	padding-bottom: 50px;

	@include break-min($large1) {
		margin: auto;
		max-width: 1400px;
		padding: 30px 100px 96px;
	}

	h1,
	h4 {
		@include type-h2();
		color: $blue;
		padding: 0 0 52px;
		margin-top: 0;
		margin-bottom: 0;
		line-height: 42px;
		font-size: 36px;

		@include break-min($large1) {
			@include font-size(3.6);
			padding: 0 0 45px;
		}
	}

	p {
		@include type-p1();
		@include font-size(1.8);
		font-weight: normal;
		margin: 0 auto 15px;
		padding: 5px 0;

		@include break-min($large1) {
			padding: 0;
		}

		&.price-disclosure {
			@include font-size(1.2);
			color: $rolling-stone;
			margin-top: 30px;
		}
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;

		.half {
			text-align: center;
			width: $col12;

			@include break-min($large1) {
				text-align: left;
				width: $col6;
			}

			&:first-child {
				padding-right: 20px;
			}

			&:last-child {
				padding-left: 20px;
				padding-top: 30px;

				@include break-min($large1) {
					padding-top: 0;
				}
			}
		}
	}

	ul {
		@extend .delivery-btn-border-shadow;
		padding: 0 24px;

		@include break-min($large1) {
			justify-content: center;
			margin: 15px auto 0;
			max-width: 500px;
		}

		@include break-min($large4) {
			max-width: none;
		}

		li {
			@include break-max($large1) {
				max-width: none;
			}

			@include break-min($large1) {
				max-width: none;
				margin-right: 20px;
			}
		}

		a {
			img {
				padding: 50px;
				width: auto;

				@include break-min($large1) {
					padding: 20px;
				}
			}
		}
	}
}
