.gifting-explain-note {
	display: none;

	@include break-min($large1) {
		display: block;
		grid-column: 2;
		margin-top: 40px;
		max-width: $col9;
	}

	p {
		@include font-size(1.4);
		letter-spacing: -0.25px;
		line-height: calc(23 / 14);
	}
}
