@import "../common/join-email-module/join-email-footer-copy";
// sass-lint:disable no-ids
// sass-lint:disable id-name-format
.footer {
	background: $white;
	position: relative;
	z-index: 30;

	&.join-email-grid-display {
		.copyright {
			display: block;
			margin: 28px 0 48px;
			text-align: center;

			p {
				@include font-size(1.2);
				color: $rolling-stone;
				font-weight: 500;
				line-height: lh(20, 12);
				margin: 0;
			}
		}

		@include break-min($large1) {
			padding: 48px 48px 36px 24px;

			.footer-modules {
				display: grid;
				padding: 0;
			}

			.footer-nav {
				text-align: left;

				li {
					margin: 0 25px 25px 0;
				}

				.sub-links {
					display: grid;
					grid-gap: 8px;
					grid-template-columns: 50% 50%;
					margin: 7px 0 0;
					max-width: 317px;

					li {
						margin: 0;

						a {
							color: $grey;
							font-weight: 400;
							line-height: lh(20, 12);
						}
					}
				}
			}

			.copyright {
				margin: 0;
				text-align: left;
			}

			.join-email-module {
				grid-column: 2 / 2;
				grid-row: 1;
				text-align: left;
			}

			.social {
				grid-column: 2 / 2;
				grid-row: 2;
				text-align: left;

				.social-icons {
					li {
						&:not(:first-child) {
							margin-left: 14px;
						}
					}
				}
			}
		}
	}

	.background-image img {
		min-height: 475px;
		width: 100%;
	}

	.social-icons {
		@media only screen and (max-width: $mobile) {
			padding-bottom: 10px;
			padding-top: 0;

			li {
				margin: 0 5px;
			}
		}
	}
}

.lrg-app-banner {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 510px;
	position: relative;

	@media only screen and (max-width: $mobile) {
		background-repeat: no-repeat;
		background-size: cover;
		height: 1000px;
	}

	@media only screen and (min-width: $lrg-mobile) and (max-width: $mobile) {
		height: 1150px;
	}

	.account-offers {
		bottom: 12%;
		font-weight: normal;
		max-height: 500px;
		margin: auto;
		position: absolute;
		text-align: center;

		@media screen and (min-width: 550px) {
			bottom: 2%;
		}

		@media screen and (min-width: $mobile) {
			bottom: 0;
			right: 0;
			width: 625px;
		}

		@media screen and (min-width: $sml-desktop) {
			bottom: -4px;
			right: 2%;
		}

		@media screen and (min-width: $lrg-desktop) {
			right: 8%;
		}

		img {
			height: 64px;
		}

		.tagline {
			@include font-size(2.4);
			color: $grey;
			margin: 30px auto 0;
			width: $col10;

			@media only screen and (max-width: $sml-mobile) {
				@include h5();
			}

			@media only screen and (min-width: $sml-desktop) {
				margin: 32px auto 0;
			}
		}

		.disclaimer-txt {
			@include p2();
			margin-bottom: 0;
			padding-top: 10px;
			width: 100%;
		}

		.app-stores {
			margin-top: 30px;

			@media only screen and (min-width: $sml-desktop) {
				margin-top: 25px;
			}

			.apple {
				@media only screen and (min-width: 500px) {
					margin-right: 10px;
				}
			}
		}

		.signin-wrapper {
			display: flex;
			justify-content: center;

			p {
				@include p2();
				font-size: 18px;
			}

			button {
				@include btn-to-link-conversion;
				font-size: 18px;
				margin-top: 3px;
			}
		}

		.btn--vis {
			margin: 37px auto 28px;
			width: 78%;

			// quick fix. not sure why hover is causing red color from buttons.scss
			&:hover {
				color: $white;
			}

			@media only screen and (min-width: $mobile) {
				display: inline-block;
				width: auto;
			}

			@media only screen and (min-width: $sml-desktop) {
				margin: 32px auto 25px;
			}
		}
	}
}

.footer-modules {
	@include justify-content(space-between);
	background-color: $white;
	display: flex;
	padding: 40px 30px 25px;
	max-width: none;

	@media only screen and (max-width: $sml-desktop) {
		display: block;
		padding: 30px 10px;
		text-align: center;
	}
}

.footer-nav {
	@media only screen and (max-width: $sml-desktop) {
		text-align: center;
	}

	ul {
		margin: 0;
		padding: 0;
		position: relative;
	}

	li {
		@include link-underline();
		display: inline-block;
		margin-right: 25px;
		font-weight: $medium-weight;

		&:hover {
			text-decoration: none;
		}

		@media only screen and (max-width: $mobile) {
			display: block;
			margin: 0;
			padding: 10px 0;

			a {
				@include link-underline();
				display: block;
			}
		}

		ul {
			@include font-size(1.2);
			left: 0;
			line-height: 20px;
			margin-top: 8.65px;
			position: absolute;
			width: 100%;

			@media only screen and (max-width: $sml-desktop) {
				margin: 20px 0 30px;
				position: relative;
			}

			@media only screen and (max-width: $mobile) {
				margin-bottom: 20px;
				margin-top: -15px;
			}

			li {
				margin-right: 15px;
				cursor: auto;
				font-weight: $regular-weight;
				white-space: nowrap;

				@media only screen and (max-width: $mobile) {
					margin-right: 0;
				}

				@include break-min($large1) {
					margin-bottom: 4px;
				}

				&:hover {
					text-decoration: none;
				}
			}

			a {
				@include link-underline();
				color: $grey;
				font-weight: 600;

				&:hover {
					border-color: $grey;

					@media only screen and (max-width: $mobile) {
						border-color: transparent;
					}
				}
			}
		}
	}

	[class^="icon-arrow-"],
	[class*=" icon-arrow-"] {
		@include font-size(1.2);
		line-height: 20px;
		margin-left: 5px;
	}

	.active {
		[class^="icon-arrow-"],
		[class*=" icon-arrow-"] {
			&:before {
				@include transform(rotate(180deg));
				display: inline-block;
			}
		}
	}
}

.join-email-module {
	@extend .join-email-footer-copy;
}

// sass-lint:disable no-qualifying-elements
// pixel fix
iframe#braintreeDataFrame {
	position: absolute;
	left: 0;
	bottom: 0;
}
