.signature-drop-img-tile {
	border: 1px solid $iron;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	margin: 0 0 48px 0;
	padding: 22px 33px 32px;

	@include break-min($large1) {
		align-self: flex-start;
		padding: 24px 40px 40px;
		margin-right: 100px;
	}

	img {
		margin: auto;
		max-height: 500px;

		@include break-min($large1) {
			margin-bottom: 15px;
		}
	}

	p {
		@include font-size(1.8);
		color: $blue;
		font-weight: $bold-weight;
		margin: 0;
		max-width: 500px;
		text-align: center;
	}
}
