.pwo-modal-last-ordered {
	align-items: center;
	background-color: $cool-white;
	border-radius: 0px 0px 20px 20px;
	color: $cool-white;
	font-size: 2rem;
	justify-content: space-between;
	margin-top: 34px;
	padding: 26px 24px;

	@include break-min($large1) {
		display: flex;
		padding: 29px 47px 35px;
	}

	.wrapper {
		margin: 0;
		padding: 0;

		@include break-min($large1) {
			flex-basis: 60%;
		}
	}

	.location-name {
		align-items: center;
		display: flex;

		h3 {
			@include font-size(2);
			color: $blue;
			font-weight: $bold-weight;
			line-height: lh(28, 20);
			margin: 0 20px 0 0;

			@include break-min($large1) {
				@include font-size(2.4);
			}
		}

		p {
			@include font-size(1.6);
			color: $dkgrey;
			font-weight: 400;
			line-height: lh(25, 16);
			margin: 0;
		}
	}

	p {
		@include font-size(1.6);
		color: $rolling-stone;
		font-weight: $bold-weight;
		margin-bottom: 10px;

		&:first-child {
			@include break-min($large1) {
				@include font-size(1.8);
				margin-bottom: 14px;
			}
		}
	}

	.address {
		color: $dkgrey;
		font-weight: 400;
		line-height: lh(25, 16);
	}

	.hours {
		display: flex;
		margin-bottom: 30px;

		@include break-min($large1) {
			margin-bottom: 0;
		}

		img {
			margin-right: 8px;
		}

		p {
			color: $vis-green;
			font-weight: 400;
			margin: 0;
		}
	}

	.btn-round {
		@include break-min($large1) {
			margin-top: -45px;
		}
	}
}
