.customer-support-search-header__variation {
	.customer-support-search-header {
		padding: 24px;

		@include break-min($large1) {
			padding: 32px 0 40px;
		}

		p {
			display: none;

			@include break-min($large1) {
				display: block;
				margin-bottom: 24px;
			}
		}

		h1 {
			display: none;
		}
	}
}
