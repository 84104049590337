.membership-nav {
	&__list {
		margin: 0;
		padding: 0;

		li {
			position: relative;
			list-style-type: none;
			margin: 0;
			padding: 0;
			text-align: center;
			border-bottom: 3px solid $gallery;

			> a {
				@include font-size(2.3);
				font-family: $primary;
				font-weight: $medium-weight;
				line-height: 1.4;
				color: $vis-grey;
				display: block;
				vertical-align: middle;
				text-align: left;
				padding: 16px 35px 16px 18px;
				position: relative;

				&:hover {
					background-color: $white;
					color: $vis-blue;
				}

				&:after {
					content: "";
					background-image: url("img/cp-icons/icon-cp_arrow-right.svg");
					width: 24px;
					height: 24px;
					background-size: cover;
					position: absolute;
					top: 0;
					right: 15px;
					bottom: 0;
					margin: auto;
					pointer-events: none;
				}
			}

			span {
				@include font-size(1.2);
				background-color: $red;
				border-radius: 12px;
				color: $white;
				display: inline-block;
				font-family: $primary;
				font-weight: $regular-weight;
				height: 24px;
				line-height: 2;
				margin: -2px -34px 0 10px;
				min-width: 24px;
				padding: 0;
				text-align: center;
				vertical-align: middle;
			}

			&.active {
				background-color: $white;

				> a {
					color: $vis-blue;
				}
			}
		}
	}

	&__accord {
		&-toggle {
			&:focus {
				font-weight: $medium-weight;
			}

			&:after {
				content: "";
				transition: transform 0.2s ease;
				background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
			}
		}

		&-content {
			padding: 0 0 10px 0;
			display: none;

			li {
				border: 0;

				a {
					@include font-size(1.8);
					color: $vis-grey;
					padding: 8px 18px;

					&:after {
						content: none;
					}

					&:hover {
						color: $red;
					}
				}

				&.active {
					a {
						color: $red;
					}
				}
			}
		}

		&.expanded {
			.membership-nav__accord-toggle {
				&:after {
					transform: rotate3d(1, 0, 0, 180deg);
				}
			}

			.membership-nav__accord-content {
				display: block;
			}
		}
	}

	@include break-min($large1) {
		position: relative;
		z-index: $z3;
		display: block;
		min-width: 360px;
		@include flex(0 0 auto);

		&__list {
			li {
				border: 0;

				a {
					padding: 16px 0;
					text-align: center;

					&:after {
						content: none;
					}
				}
			}

			&--sub {
				li {
					a {
						padding: 8px 0;
					}
				}
			}
		}

		&__accord {
			&-toggle {
				visibility: visible;

				&:after {
					content: "";
					background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
				}
			}
		}
	}
}
