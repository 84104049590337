.customer-support-category-list {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0 auto 72px;
	padding: 0;

	@include break-min($large1) {
		column-gap: 2%;
		flex-direction: row;
		justify-content: flex-start;
		margin-bottom: 112px;
		max-width: 1104px;
	}

	li {
		border: 1px solid $iron;
		border-radius: 4px;
		margin-bottom: 24px;
		padding: 28px 24px 16px 28px;

		@include break-min($large1) {
			flex-basis: 32%;
		}
	}

	.wrapper {
		align-items: center;
		display: flex;
		margin-bottom: 26px;
		padding: 0;

		@include break-min($large1) {
			display: block;
		}
	}

	.wrapper-icon {
		height: 35px;
		margin-right: 16px;
		width: 35px;

		@include break-min($large1) {
			height: 60px;
			margin-bottom: 18px;
			margin-right: 0;
			width: 60px;
		}

		img {
			height: 100%;
			margin-right: 20px;
			width: auto;

			@include break-min($large1) {
				margin: 0 0 18px 0;
			}
		}
	}

	h3 {
		@include font-size(2);
		display: inline-block;
		font-weight: $bold-weight;
		line-height: lh(28, 20);
		margin: 0;
		padding-right: 27px;
		position: relative;

		@include break-min($large1) {
			@include font-size(2.4);
			letter-spacing: -0.5px;
			line-height: lh(34, 24);
			padding-right: 31px;
		}

		&:after {
			content: "";
			background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
			background-size: cover;
			bottom: 0;
			height: 15px;
			margin: auto 0;
			position: absolute;
			right: 0;
			top: 0;
			transform: rotate(270deg);
			width: 15px;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.nav-links {
		li {
			@include font-size(1.8);
			border: none;
			border-radius: 0;
			margin: 0 0 16px 0;
			letter-spacing: -0.5px;
			padding: 0;

			@include break-min($large1) {
				width: 100%;
			}

			a {
				color: inherit;
			}
		}
	}
}
