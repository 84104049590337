.covid-latest-update {
	margin: 47px auto 84px;
	max-width: 1050px;
	padding: 0 24px;

	@include break-min($large1) {
		margin-bottom: 200px;
	}

	&:focus {
		font-weight: initial;
	}

	@include break-min($large1) {
		margin-top: 63px;
	}

	&.mobileExpanded {
		li {
			&:not(:first-child) {
				display: block;
				max-height: 5000px;
				opacity: 1;
				overflow: initial;
			}
		}

		.btn-round {
			display: none;
		}
	}

	&.desktopExpanded {
		li {
			&:first-child {
				box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
				padding: 55px 71px 43px;
			}

			&:not(:first-child) {
				display: block;
				max-height: 1500px;
				opacity: 1;
				overflow: initial;
			}
		}

		.btn-round {
			display: none;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		@include dropdown-transition(1, 3);

		&:first-child {
			@include break-min($large1) {
				box-shadow: 0;
				padding: 55px 71px 0;
			}

			.title {
				@include break-min($large1) {
					display: flex;
				}

				p:nth-of-type(1) {
					@include break-min($large1) {
						margin-top: -3px;
					}
				}

				p:nth-of-type(2) {
					top: 28px;

					@include break-min($large1) {
						bottom: 0;
						margin: auto;
						top: 0;
					}
				}
			}
		}

		&:nth-child(2) {
			@include break-min($large1) {
				margin: 57px 0 0;
			}
		}

		&:not(:first-child) {
			display: none;
			opacity: 0;
			max-height: none;
			overflow: hidden;

			@include break-min($large1) {
				margin-bottom: 22px;
				max-height: none;
				padding: 0 71px;
			}

			span {
				display: none;
			}
		}
	}

	.title {
		border-bottom: 1px solid $faint-grey;
		padding-bottom: 22px;
		position: relative;

		@include break-min($large1) {
			padding-bottom: 16px;
		}

		span {
			@include type-label2();
			@include font-size(1.2);
			align-self: center;
			color: $red;
			display: inline-block;
			font-weight: $bold-weight;
			letter-spacing: 2px;
			margin-bottom: 7px;

			@include break-min($large1) {
				@include font-size(1.8);
				letter-spacing: 4px;
				margin: 0 23px 0 0;
			}
		}

		p:nth-of-type(1) {
			@include type-p2(desktopOnly);
			color: $blue;
			margin: 0;
		}

		p:nth-of-type(2) {
			color: $rolling-stone;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	.body {
		padding-top: 32px;

		p {
			@include type-p2(desktopOnly);
			margin-bottom: 22px;

			:last-child {
				margin-bottom: 0;
			}
		}

		a {
			@include link-underline();
		}
	}

	.btn-round {
		margin-top: 32px;
		width: 100%;

		@include break-min($large1) {
			margin: 16px 71px 0;
			width: auto;
		}
	}
}
