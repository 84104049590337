// GLOBAL Boxes
// ==========================================================================

.g-carousel-grid {
	background: $white;
	margin: 40px 0 80px;
	text-align: center;

	@include break-min($small4) {
		margin: 70px 0 90px;
	}

	&__inner {
		max-width: 1024px;
		margin: 0 auto;
		padding: 0 16px;

		@include break-min($small4) {
			padding: 0 24px;
		}

		&.full-width {
			@include break-min($large1) {
				max-width: 1400px;
			}

			.g-carousel-grid__slide-body {
				p {
					font-weight: $bold-weight;
				}
			}
		}

		&.column-reverse {
			display: flex;
			flex-direction: column-reverse;
		}

		.btn {
			margin-top: 40px;
			width: 100%;

			@include break-min($large1) {
				width: auto;
			}
		}

		.btn-round {
			margin: 30px 0 0;
		}
	}

	&__header {
		text-align: center;
		max-width: 900px;
		width: 100%;
		margin: 0 auto -30px;

		@include break-min($large1) {
			//margin: 50px auto auto;
			margin: auto;
		}

		p:last-child {
			margin-bottom: -7px;
			@include break-min($large1) {
				margin-bottom: 0;
			}
		}
	}

	h1 {
		@include type-h1();
		color: $vis-blue;
		display: block;
		margin: 0 auto;
		padding: 0 0 25px;
		width: 100%;
	}

	&__h2 {
		@include type-h2();
		color: $vis-blue;
		display: block;
		margin: 0 auto;
		padding: 0 0 25px;
		width: 100%;

		@include break-min($large1) {
			padding: 0 0 25px;
		}
	}

	&__p1 {
		@include type-p1();
	}

	// the top slider
	&__showcase {
		margin: 32px 0 0;

		@include break-min($small4) {
			margin: 50px 0 0;
		}
	}

	// fix for IE - disabled transform to prevent inactive slides from
	// being visible
	.slick-slider .slick-track,
	.slick-slider .slick-list {
		transform: none;
	}

	// the slides in the top slider
	&__slide {
		position: relative;
		z-index: 1;

		.g-video__wrap {
			padding: 0;
		}

		&-body {
			position: relative;
			text-align: center;
			pointer-events: none;
			transition: opacity 0.3s ease;

			@include break-min($small4) {
				color: $white;
				text-align: left;
				max-width: 630px;
				position: absolute;
				padding: 40px;
				bottom: 0;
				left: 0;
				opacity: 1;
			}
		}

		h3 {
			@include type-h5();
			font-weight: bold;
			margin: 16px 0 6px;

			@include break-min($small4) {
				margin: 0 0 10px;
			}
		}

		p {
			@include type-p2();
			margin: 0;
		}
	}

	// the bottom "slider"
	&__grid {
		margin: 18px -10px 0;

		@include break-min($large1) {
			margin: 28px -34px 0;
		}

		.slick {
			&-track {
				@include flexbox();
				@include align-items(flex-start);
				@include align-content(flex-start);
				@include flex-wrap(wrap);

				&::before,
				&::after {
					content: none;
				}
			}

			&-slide {
				float: none;

				height: fit-content !important; // sass-lint:disable-line no-important
			}
		}
	}

	// the slides in the bottom "slider"
	&__item {
		@include flex(0 0 50%);
		max-width: 50%;

		@include break-min($small4) {
			@include flex(0 0 33.3333%);
			max-width: 33.3333%;
		}

		h3 {
			@include type-p1();
			font-weight: bold;
			margin: 16px 0 0;
		}

		p {
			@include p2();
			margin: 0;

			@include break-min($small4) {
				max-width: 80%;
			}
		}

		&-inner {
			cursor: pointer;
			@include focus($red);
			margin: 20px 10px 0;
			position: relative;

			@include break-min($large1) {
				margin: 20px 34px 0;
			}
		}

		&-body {
			width: 100%;
			position: relative;

			@include break-min($small4) {
				position: absolute;
				color: $white;
				text-align: left;
				padding: 12px 15px;
				bottom: 0;
				left: 0;
			}
		}

		&-img {
			&-wrap {
				position: relative;
			}

			&-overlay {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(10, 10, 10, 0.5);
				pointer-events: none;
			}
		}

		&-active {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			color: $white;
			padding: 8px;
			border: 2px solid red;

			@include break-min($small4) {
				border: 5px solid red;
			}
		}

		&-dur {
			@include p2();
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 8px;
			color: $white;
		}

		&.slick-current {
			.g-carousel-grid__item-active {
				display: block;
			}

			.g-carousel-grid__item-img-overlay {
				background: rgba(10, 10, 10, 0.75);
			}
		}
	}

	&__h3 {
		@include h3();
		color: $vis-blue;
		padding: 0 0 20px;
		margin: 0 auto;
		display: block;
		width: 100%;
		text-align: center;

		sup {
			@include font-size(2.4);
		}
	}

	&__nav {
		z-index: 10;
		height: 24px;
		width: 24px;
		display: block;
		position: absolute;
		top: 22%;
		@include focus($red);

		&--next {
			right: 0;
		}

		&--prev {
			left: 0;
		}

		&:hover {
			cursor: pointer;
		}

		&.slick-disabled {
			display: none;
		}

		@include break-min($small2) {
			top: 26%;
		}

		@include break-min($small3) {
			top: 32%;
		}

		@include break-min($small4) {
			top: 45%;

			&--next {
				right: -24px;
			}

			&--prev {
				left: -24px;
			}
		}

		@include break-min($large3) {
			&--next {
				right: -42px;
			}

			&--prev {
				left: -42px;
			}
		}
	}

	&--new-fonts {
		margin: 70px 0 80px;

		@include break-min($large1) {
			margin: 70px 0 62px;
		}

		& .g-carousel-grid__header {
			margin-bottom: 0;

			h3 {
				@include type-h2();
				color: $vis-blue;
				display: block;
				margin: 0 auto;
				padding: 0 0 40px;
				width: 100%;
			}

			p {
				@include type-p2();
				margin-bottom: 30px;

				&:last-child {
					margin-bottom: 8px;

					@include break-min($large1) {
						margin-bottom: -8px;
					}
				}
			}
		}
	}

	&--short {
		margin: 32px 0 55px;

		@include break-min($small4) {
			margin: 54px 0 58px;

			.g-carousel-grid__grid {
				margin: 43px -34px 0;
			}
		}
	}

	&--no-margin-bottom {
		margin-bottom: 0;
	}

	.btn-round {
		margin: 30px auto 0;
	}

	&--embedded-video-margin {
		margin: -100px 0 90px;

		@include break-min($large1) {
			margin: -85px 0 90px;
		}
	}

	&--cream-bckgrd {
		background: $warm-white;
	}
}
