.icon-container-callout {
	align-items: center;
	margin: 0 0 8px 0;

	@include break-min($large1) {
		margin: 0 0 18px 0;
	}

	img {
		height: 32px;
		width: 32px;
		margin: 0 8px 0 0;
	}

	p {
		@include type-e1-refresh();
		color: $red;
		margin: 0;
	}
}
