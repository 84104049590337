.nav-content-carousel {
	@include background-color();
	padding: 64px 0 38px;
	position: relative;

	@include break-min($large2) {
		padding: 80px 0 88px;
	}

	@include break-min($huge2) {
		padding: 80px 0 88px;
	}

	.title {
		@include font-size(2.8);
		color: $blue;
		line-height: lh(34, 28);
		margin: 0 auto 48px;
		max-width: 800px;
		text-align: center;

		@include break-min($large2) {
			margin-bottom: 76px;
		}

		> *:first-child {
			@include font-size(2.8);
			font-weight: $bold-weight;
			margin-bottom: 16px;
			margin-top: 0;

			@include break-min($large2) {
				@include font-size(4.8);
			}
		}

		p {
			@include font-size(1.8);
			color: $grey;
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
			margin: 0 24px;
		}
	}

	.desktop-wrapper {
		max-width: 700px;
		margin: auto;

		@include break-min($large2) {
			display: flex;
			padding: 0 49px 0 114px;
			max-width: none;
		}

		@include break-min($huge2) {
			padding: 0 260px 0 165px;
		}
	}

	.tab-navigation {
		display: flex;
		justify-content: space-between;
		list-style-type: none;
		margin: 0;
		overflow: hidden;
		padding: 0;
		text-align: center;
		white-space: nowrap;
		width: 100%;

		&::-webkit-scrollbar {
			display: none;
		}

		@include break-min($large2) {
			display: inline-block;
			flex: 50%;
			padding-top: 10px;
		}

		li {
			border-bottom: 5px solid transparent;
			min-width: 33vw;
			transition: all 300ms ease-in;

			@include break-min($medium2) {
				min-width: auto;
			}

			@include break-min($large2) {
				border-bottom: 0;
				border-left: 5px solid $iron;
			}

			a {
				@include font-size(1.6);
				color: $rolling-stone;
				line-height: lh(25, 16);

				span {
					text-wrap: wrap;
				}
			}

			&.active {
				border-bottom: 5px solid $red;
				font-weight: $bold-weight;

				@include break-min($large2) {
					border-bottom: 0;
					border-left: 5px solid $red;
				}

				a {
					color: $red;
				}
			}

			&.remove {
				display: none;
			}

			span {
				padding: 0 25px;

				@include break-min($large2) {
					@include font-size(2.4);
					display: inline-block;
					padding: 25px;
				}
			}
		}

		@include break-max($large2) {
			display: none;
		}
	}

	.nav-content-wrapper {
		position: relative;

		@include break-min($large2) {
			width: 100%;
		}
	}

	.navigation-arrow-handlers {
		display: flex;
		position: absolute;
		justify-content: flex-end;
		right: 46px;
		top: 185px;
		width: 100%;
		z-index: 100;

		@include break-min($large2) {
			display: none;
		}

		img {
			@include absolute-center();
			height: 15px;
			margin: auto;
			width: 15px;
		}

		.left-toggle,
		.right-toggle {
			background: $white;
			border: 2px solid $red !important; // sass-lint:disable-line no-important
			border-radius: 50%;
			height: 37px;
			position: relative;
			width: 37px;

			&.disabled {
				opacity: 0.5;
				cursor: default;
			}
		}

		.left-toggle {
			margin-right: 7px;
		}
	}

	.content-card {
		display: none;
		transition: all 300ms ease;

		@include break-min($large2) {
			opacity: 0;
		}

		&.active {
			display: block;
			opacity: 1;
		}

		.content-card-wrapper {
			position: relative;

			.img-container {
				height: 210px;
				width: 100%;
				overflow: hidden;
				padding: 0 10px;

				@include break-min($large2) {
					height: 280px;
					position: relative;
					width: auto;
				}

				img {
					object-fit: cover;
					width: 100%;
					border-radius: 16px;
					height: 100%;

					@include break-min($large2) {
						@include absolute-center();
						max-width: 100%;
						max-height: 100%;
					}
				}

				@include break-max($medium2) {
					padding: 0;

					img {
						border-radius: 0;
					}
				}
			}
		}

		.content {
			background-color: $white;
			margin: -50px 24px 0;
			padding: 32px 20px;
			position: relative;
			z-index: 100;

			@include break-min($large1) {
				padding: 32px 20px 48px 80px;
			}

			@include break-min($large2) {
				background: transparent;
				margin: 33px 0 0;
				padding: 0;
			}
		}

		.header {
			@include font-size(2);
			color: $dkgrey;
			line-height: lh(28, 20);
			margin-bottom: 32px;

			p {
				font-weight: $bold-weight;
				margin: 0;

				@include break-min($large2) {
					@include font-size(2.4);
				}
			}

			@include break-max($medium2) {
				padding-right: 95px;
			}
		}

		.social-icons {
			display: none;
		}

		.content-links {
			@include break-min($large2) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-column-gap: 48px;
				grid-row-gap: 32px;
			}

			a {
				color: inherit;
			}

			ul {
				list-style-type: none;
				margin-bottom: 32px;
				padding: 0;

				li:nth-child(1) {
					@include font-size(1.6);
					color: $red;
					font-weight: $bold-weight;
					letter-spacing: -0.5px;
					line-height: lh(22, 16);
				}

				li:nth-child(2),
				li:nth-child(3) {
					@include font-size(1.4);
					letter-spacing: -0.25px;
					line-height: lh(23, 14);
				}

				li:nth-child(3) {
					color: $blue;
					font-weight: $regular-weight;
					line-height: lh(20, 14);
				}
			}
		}
	}

	&.regional-breakdown {
		.desktop-wrapper {
			max-width: 1015px;
			padding: 0;

			@include break-min($large2) {
				.tab-navigation {
					width: 256px;
					margin-right: 135px;
					text-align: left;

					li {
						margin-bottom: 4px;

						span {
							padding: 20px 26px;
						}
					}
				}
			}
		}
	}
}
