.four-column-with-headline {
	padding: 56px 0;

	@include break-min($large1) {
		padding: 96px 0;
	}

	&.coolwhite-bckgrd {
		background: $cool-white;
	}
	&-headline {
		&-wrapper {
			text-align: center;
			padding: 0 42px 32px;
			position: relative;
			width: 100%;

			@include break-min($medium3) {
				padding: 0 42px 42px;
			}

			h2 {
				color: $blue;
				font-family: $primary;
				font-weight: 700;
				@include font-size(2.8);
				line-height: 1;
				margin-bottom: 29px;
				letter-spacing: -0.1px;
				font-weight: 700;
				margin-top: 0;

				@include break-min($medium3) {
					@include font-size(4);
					line-height: lh(20, 18);
					margin-bottom: 16px;
				}

				@include break-min($huge1) {
					@include font-size(4.8);
				}
			}

			h3 {
				margin: 0;
				@include font-size(2);

				@include break-min($medium3) {
					@include font-size(2.4);
					max-width: 480px;
					margin: 0 auto;
				}
			}
		}
	}
	.multi-column-component {
		margin-bottom: 0 !important;

		&[data-has-multiple="true"] {
			.card-classic {
				.message-container {
					padding: 0;
				}
			}
		}
	}
}
