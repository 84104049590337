.share-banner {
	align-items: center;
	background: $red;
	border-bottom-left-radius: 50px;
	border-top-left-radius: 50px;
	color: $white;
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 14px;
	position: fixed;
	width: fit-content;
	overflow: hidden;
	top: 575px;
	transition: 500ms all ease-in-out;
	z-index: 10000;
	opacity: 0;
	visibility: hidden;

	&.display {
		opacity: 1;
		visibility: visible;
	}

	@include break-min($large1) {
		padding: 27px 10px 27px 18px;
		top: calc(100% - 194px);
	}

	&.expanded {
		li {
			&:nth-child(2) {
				opacity: 0;
				display: none;
			}
		}
	}

	li {
		margin: 0 8px 0 0;

		&:first-child {
			display: none;

			@include break-min($large1) {
				display: block;
			}
		}

		&:not(:first-child) {
			background: $white;
			border-radius: 50%;
			cursor: pointer;
			padding: 10px;
			position: relative;
			max-height: 40px;
			min-height: 40px;
			max-width: 40px;
			min-width: 40px;
			transition: 300ms all ease-in-out;

			&:hover {
				transform: scale(1.1);
			}
		}

		// close btn
		&:last-child {
			background: transparent;
			border: 1px solid $white;
		}

		p {
			margin: 0;
		}

		img {
			@include absolute-center();
			margin: auto;
		}
	}
}
