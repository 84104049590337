.block_img_header_txt {
	align-items: center;
	margin-top: 0;
	padding: 100px 86px;
	// background-color: $warm-white;
	// color: $blue;

	.content {
		h4 {
			line-height: 46px;
			letter-spacing: -0.8px;
			@include font-size(3.6);

			@include break-min($large1) {
				letter-spacing: -1px;
				@include font-size(4.8);
			}
		}

		p {
			color: $grey;
		}
	}

	// &.bkg-blue {
	// 	background-color: $blue;
	// 	color: $white;

	// 	p {
	// 		color: $white;
	// 	}
	// }
}
