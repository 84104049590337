// sass-lint:disable max-file-line-count, no-qualifying-elements

// Redesigned menu stuff
// ==========================================================================

// TODO: separate out components once we get a feel for how these will work
// together on other pages
.menu-page {
	background-color: $warm-white;
	padding: 0 0 50px;

	@include break-min($large1) {
		padding: 0 0 70px;
	}

	.tooltip-block {
		position: relative;

		a {
			@include link-underline();
			padding: 0 17px;

			@media screen and (min-width: $mobile) {
				padding: 0 26px;
			}

			&:hover + .txt-wrapper {
				display: block;
			}
		}

		.txt-wrapper {
			background: $white;
			box-shadow: 3px 4px 29px 0 rgba(0, 0, 0, 0.11);
			border-radius: 10px;
			display: none;
			bottom: 38px;
			left: 45px;
			padding: 15px;
			position: absolute;
			width: 200px;
			z-index: 100;

			@media screen and (min-width: $sml-mobile) {
				left: 62px;
				bottom: 34px;
			}

			@media screen and (min-width: $sml-desktop) {
				left: 55px;
				bottom: 41px;
			}

			&.display {
				display: block;
			}
		}

		.arrow_box:after,
		.arrow_box:before {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		.arrow_box:after {
			border-width: 36px;
			margin-left: -30px;
		}

		.arrow_box:before {
			border-top-color: $white;
			border-width: 11px;
			margin-left: -14px;
		}

		.tooltip-txt {
			@include p2();
			margin-bottom: 0;
		}
	}
}

// menu cards / grid
// ==========================================================================
.menu-card {
	overflow: hidden;
	@include flex(0 0 50%);

	@include break-min($small5) {
		@include flex(0 0 33.33%);
	}

	@include break-min($large3) {
		@include flex(0 0 25%);
	}

	a {
		margin: 0;
		padding: 0;
		display: block;
	}

	// added a tag for specificity to override existing link styles
	a.mc-linkoverlay {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		display: block;
		z-index: $z3;
		border-radius: 8px;
		border: 1px dotted $white;

		&:focus {
			outline: 0;
			border-color: $red;
		}
	}

	.mc-pad {
		position: relative;
		overflow: hidden;
		padding: 8px;
		height: 100%;

		@include break-min($large3) {
			padding: 12px;
		}
	}

	.mc-inner {
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		height: 100%;
		margin: 0;
		padding: 54px 10px 10px 10px;
		background-color: $white;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
		@include flexbox();

		@include flex-direction(column);
		@include justify-content(flex-end);
		@include align-items(stretch);
		@include flex-wrap(nowrap);

		&:hover {
			h3 {
				text-decoration: underline;
			}

			sup {
				text-decoration: none;
			}
		}

		@include break-min($medium3) {
			padding: 54px 16px 16px 16px;
		}
	}

	.mc-serves {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 54px;
		padding: 10px 10px 10px 50px;
		margin: 0;
		color: $light-grey;
		@include p2();

		@include flexbox();
		@include justify-content(stretch);
		@include align-items(center);

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 54px;
			height: 46px;
			margin: auto;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: url("img/icon-people.svg");
		}

		span {
			font-family: inherit;
			color: inherit;
			display: block;
			@include flex(1 1 auto);
			line-height: 1.2;
		}
	}

	.mc-text {
		margin: 0;
		min-height: 135px;
		overflow: hidden;
		padding: 20px 0 0;
		position: relative;
		z-index: $z1;
		@include flex(0 0 auto);

		@include flexbox();
		@include justify-content(stretch);
		@include align-items(flex-start);
		@include flex-wrap(wrap);

		h3 {
			margin: 0;
			padding: 0 0 10px;
			color: $vis-blue;
			@include font-size(1.8);
			font-family: $primary;
			font-weight: $bold-weight;
			line-height: lh(24px, 18px);
			// sass-lint:disable no-vendor-prefixes
			-moz-osx-font-smoothing: grayscale;
			// sass-lint:enable no-vendor-prefixes

			@include flex(1 1 100%);

			sup {
				display: inline-block;
			}

			@include break-min($medium3) {
				@include font-size(2.2);
				line-height: lh(28px, 22px);
			}
		}
	}

	.mc-meta {
		margin: auto 0 0 0;
		@include flex(1 1 100%);
		@include align-self(flex-end);

		@include flexbox();
		@include justify-content(space-between);
		@include flex-wrap(wrap);
		@include align-items(center);

		span {
			@include p2();
			display: inline-block;
			color: $vis-grey;
			line-height: 1.2;
			padding: 3px 0 0 0;
			@include flex(0 1 auto);
			text-align: left;

			&:first-child {
				padding: 3px 5px 0 0;
				font-weight: $bold-weight;
				@include flex(1 1 auto);
				// @include align-self(flex-start);
			}
		}
	}

	.mc-img {
		display: block;
		@include flex(0 0 auto);
		position: relative;
		z-index: $z1;
	}

	img {
		margin: auto 0 0 0;
		display: block;
		height: auto;
		width: 100%;
	}
}

.menu-grid {
	padding: 8px;

	@include break-min($small5) {
		padding: 16px;
	}

	@include break-min($large3) {
		padding: 12px;
	}
}

.menu-grid-inner {
	padding: 0;

	@include flexbox();
	@include justify-content(stretch);
	@include align-items(stretch);
	@include flex-wrap(wrap);
	flex: 1;
}

.menu-grid-heading {
	margin: 20px 0 0;
	padding: 16px 8px;
	color: $vis-blue;
	@include font-size(1.8);
	font-family: $primary;
	font-weight: $bold-weight;
	line-height: lh(24px, 18px);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@include flex(1 1 100%);

	&:first-child {
		margin: 0;
	}

	@include break-min($medium3) {
		@include font-size(2.2);
		line-height: lh(28px, 22px);
	}

	@include break-min($large3) {
		padding: 16px 12px;
	}
}

// menu hero
// ==========================================================================
.menu-hero {
	background-color: $white;
	overflow: hidden;
	min-height: 235px;
	position: relative;

	&.cta {
		&:after {
			content: " ";
			position: absolute;
			display: block;
			top: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(
				90deg,
				rgba(0, 0, 0, 0.4) -48.12%,
				rgba(0, 0, 0, 0.4) 61.7%
			);
			right: 0;
			z-index: 200;
		}
	}

	@include break-min($large1) {
		min-height: 320px;
	}
}

.menu-hero-img {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: $z1;
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	&.img-large {
		display: none;
	}

	@include break-min($small4) {
		&.img-small {
			display: none;
		}

		&.img-large {
			display: block;
		}
	}
}

.menu-hero-text {
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 0 20px;
	z-index: 201;

	@include flexbox();
	@include justify-content(center);
	@include align-items(center);
	@include flex-direction(column);
	@include flex-wrap(norap);

	@include break-min($large1) {
		max-width: 1080px;
		padding-left: 50px;
		width: 100%;
		margin: auto;
		text-align: left;
		@include align-items(flex-start);
	}

	p {
		@include font-size(1.2);
		color: $white;
		font-family: $secondary;
		font-weight: $bold-weight;
		line-height: 1;
		letter-spacing: 2px;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
		padding: 0;
		margin: 0;
		max-width: 500px;
		text-transform: uppercase;
		width: 100%;

		@include flex(0 1 auto);
	}

	h1,
	h2 {
		@include font-size(3.5);
		color: $white;
		font-family: $primary;
		font-weight: $bold-weight;
		letter-spacing: -0.7px;
		line-height: lh(44px, 35px);
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
		padding: 15px 0 20px;
		max-width: 500px;
		margin: 0;
		width: 100%;

		@include flex(0 1 auto);

		@include break-min($large1) {
			@include font-size(6.2);
			max-width: 620px;
			letter-spacing: -1.5px;
			line-height: lh(64px, 62px);
			margin: 0 0 0 -3px;
		}
	}

	p:last-child {
		@include font-size(1.4);
		color: $white;
		font-family: $primary;
		font-weight: $bold-weight;
		margin-top: 18px;
		letter-spacing: 0.2px;
		line-height: 16px;
		text-align: center;
		text-transform: none;

		@include break-min($large1) {
			margin-top: 12px;
			text-align: left;
		}
	}

	.menu-hero-edit {
		@include font-size(1.2);
		color: $white;
		font-family: $primary;
		font-weight: $medium-weight;
		line-height: lh(16px, 12px);
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
		padding: 0 0 0 22px;
		position: relative;
		margin: 0;
		max-width: 620px;
		display: inline-block;
		transition: none;

		@include flex(0 1 auto);

		&::after {
			content: "";
			display: block;
			position: absolute;
			top: -1px;
			left: 0;
			bottom: 0;
			width: 12px;
			height: 12px;
			margin: auto;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: url("img/icon_edit-pencil.svg");
		}

		&:focus {
			outline-offset: 2px;
		}

		&:hover,
		&:focus {
			color: $red;
			text-decoration: underline;

			&::after {
				background-image: url("img/icon_edit-pencil-red.svg");
			}
		}

		@include break-min($large1) {
			text-decoration: underline;
		}
	}

	&--blue {
		p,
		h1,
		h2 {
			color: $vis-blue;
			text-shadow: none;
		}

		.menu-hero-edit {
			color: $red;
			text-shadow: none;

			&::after {
				background-image: url("img/icon_edit-pencil-red.svg");
			}
		}
	}

	&.text-blue {
		p,
		h1,
		h2,
		h3,
		a {
			color: $vis-blue;
			text-shadow: none;
		}
	}

	&.text-top-mobile {
		justify-content: flex-start;
		margin-top: 15px;

		@include break-min($medium2) {
			justify-content: center;
			margin-top: unset;
		}

		h1,
		h2,
		h3 {
			padding-top: 0;

			@include break-min($medium2) {
				padding-top: unset;
			}
		}
	}
}

// menu filters
// ==========================================================================
.menu-filter {
	padding: 10px 0 0;
	background-color: $white;

	.mf-header {
		margin: 0;
		padding: 10px 16px 0 16px;
		display: block;
		@include flexbox();

		@include justify-content(flex-start);
		@include align-items(baseline);
		@include flex-wrap(wrap);

		@include break-min($small5) {
			padding: 10px 24px 0 24px;
		}
	}

	.mf-text {
		@include p2();
		color: $vis-grey;
		font-weight: $medium-weight;
		max-width: 820px;
		margin: 0;
		padding: 10px 16px 14px 16px;
		display: none;
		backface-visibility: hidden;

		@include break-min($small5) {
			padding: 10px 24px 14px 24px;
		}

		span {
			padding: 0 0 10px;
			margin: 0;
			display: block;
			font-family: $primary;
			font-weight: $regular-weight;
			line-height: 1.4;
			// sass-lint:disable no-vendor-prefixes
			-moz-osx-font-smoothing: grayscale;
			// sass-lint:enable no-vendor-prefixes
			@include font-size(1);
		}
	}

	a {
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	h4 {
		@include font-size(1.6);
		font-family: $primary;
		font-weight: $medium-weight;
		line-height: lh(20px, 16px);
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		position: relative;
		padding: 6px 15px 6px 0;
		margin: 0;
		display: inline-block;

		@include flex(0 1 auto);

		@include break-min($small2) {
			padding: 6px 15px 6px 30px;

			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 2px;
				width: 20px;
				height: 20px;
				margin: auto;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				background-image: url("img/icon_filter-toggle.svg");
			}
		}
	}

	.mf-tag-wrap {
		padding: 10px 16px 24px 16px;
		overflow: hidden;
		overflow-x: auto;
		white-space: nowrap;

		@include break-min($small5) {
			padding: 10px 24px 24px 24px;
		}
	}

	.mf-tag {
		@include p2();
		@include appearance(none);
		height: 32px;
		padding: 0 13px;
		margin: 0 4px 0 0;
		color: $grey;
		border: 2px solid $light-grey;
		background: $white;
		border-radius: 32px;
		text-align: center;
		transition: border-color 0.3s ease;

		&--active {
			background: $red;
			color: $white;
			border-color: $red;
		}

		span {
			display: block;
			font-family: inherit;
			color: inherit;
			line-height: inherit;
			position: relative;
			overflow: hidden;
			transition: color 0.3s ease;

			&::after {
				backface-visibility: hidden;
				background-color: transparent;
				bottom: 1px;
				content: "";
				height: 2px;
				left: 0;
				position: absolute;
				right: 0;
				transition: background-color 0.3s ease;
			}
		}

		&:focus {
			outline: 1px dotted $red;
			outline-offset: 2px;
		}

		&:not(.mf-tag--active):hover {
			border-color: $red;
			color: $red;

			span {
				&::after {
					background-color: $red;
				}
			}
		}
	}

	.mf-tooltip {
		padding: 0;
		margin: 0;
		width: 16px;
		height: 16px;
		color: $grey;
		display: inline-block;
		cursor: pointer;

		&:focus {
			outline: 1px dotted $red;
			outline-offset: 2px;
		}

		&::before {
			font-weight: $medium-weight;
		}

		&:hover {
			color: $red;

			.mf-tooltip-text {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@media screen and (max-width: 350px) {
			display: inline;
		}
	}

	.mf-tooltip-text {
		left: 0;
		margin: -10px 0 0 0;
		max-width: 310px;
		opacity: 0;
		padding: 15px 0 0 0;
		position: absolute;
		right: 0;
		top: 100%;
		transform: translateX(-200%);
		width: 100%;
		z-index: $z3;

		&:hover {
			opacity: 1;
			transform: translateX(0);
		}

		span {
			background-color: $white;
			border: 1px solid $light-grey;
			border-radius: 3px;
			padding: 6px 6px 7px 6px;
			margin: 0;
			display: block;
			font-family: $primary;
			font-weight: $regular-weight;
			line-height: 1.4;
			// sass-lint:disable no-vendor-prefixes
			-moz-osx-font-smoothing: grayscale;
			// sass-lint:enable no-vendor-prefixes
			@include font-size(1);
		}
	}

	.mf-clear {
		@include font-size(1.2);
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		color: $red;
		cursor: pointer;
		display: inline-block;
		font-family: $primary;
		font-weight: $bold-weight;
		letter-spacing: -0.25px;
		line-height: 1;
		margin: 0;
		padding: 6px 0;

		@include flex(0 1 auto);

		&--hidden {
			display: none;
		}

		&:focus {
			outline-offset: 2px;
			outline: 1px dotted $red;
			text-decoration: underline;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

// menu sticky navigation
// ==========================================================================
.menu-sticky {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: $z5;
	padding: 0;
	margin: 0;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	background-color: $white;
	backface-visibility: hidden;
	will-change: position;

	&--active {
		position: fixed;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	}

	.ms-inner {
		padding: 0;
		margin: 0;
		width: 100%;
		height: 64px;
		overflow: hidden;
		overflow-x: auto;
		@include flexbox();

		@include justify-content(flex-start);
		@include align-items(center);
		@include flex-wrap(nowrap);
	}

	.ms-inner-padding {
		padding-left: 14px;
	}

	.ms-icon {
		height: 100%;
		width: 84px;
		padding: 10px 24px;
		position: relative;
		margin: 0 20px 0 0;
		@include flex(0 0 84px);

		&::after {
			content: "";
			height: 48px;
			width: 1px;
			background-color: $mdgrey;
			position: absolute;
			top: 0;
			right: -1px;
			bottom: 0;
			margin: auto;
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 50px;
			height: 50px;
			margin: auto;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
		}

		&--personal {
			&::before {
				background-image: url("img/icon_personal-menu.svg");
			}
		}

		&--catering {
			&::before {
				background-image: url("img/icon_catering-menu.svg");
			}
		}
	}

	a {
		@include font-size(1.6);
		border: 0;
		display: inline-block;
		padding: 10px;
		margin: 0 10px 0 0;
		font-family: $primary;
		color: $red;
		font-weight: $medium-weight;
		line-height: 1.2;
		letter-spacing: -0.5px;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes

		@include flex(0 0 auto);

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.ms-link.mslink--active {
		text-decoration: underline;
	}

	.ms-toggle {
		padding: 10px 24px 10px 60px;
		margin: 0 0 0 auto;
		position: relative;

		&::after {
			content: "";
			height: 48px;
			width: 1px;
			background-color: $mdgrey;
			position: absolute;
			top: 0;
			left: -1px;
			bottom: 0;
			margin: auto;
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 14px;
			bottom: 0;
			width: 46px;
			height: 46px;
			margin: auto;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
		}

		&--personal {
			&::before {
				background-image: url("img/icon_personal-menu.svg");
			}
		}

		&--catering {
			&::before {
				background-image: url("img/icon_catering-menu.svg");
			}
		}
	}
}

.menu-sticky-wrap {
	background-color: $white;
	height: 64px;
	position: relative;
}

// catering specific styles
// =======================================================================

.catering {
	.frame {
		padding-top: $nav-base-height;
	}
}
