.long-form-overlay-view {
	border-radius: 16px;
	margin-left: 32px;
	margin-right: 32px;
	position: relative;

	@include break-min($huge4) {
		margin-left: auto;
		margin-right: auto;
		width: 1252px;
	}

	.content {
		background: none;
		bottom: 24px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		// width: fit-content;
		z-index: 10;

		@include break-min($medium4) {
			grid-column: none;
			height: fit-content;
			left: unset;
			right: 16px;
			bottom: 0;
			top: 0;
			margin: auto;
		}

		@include break-min($large4) {
			right: 20px;
		}

		@include break-min($huge4) {
			right: -110px;
		}

		.content-wrapper {
			background: $white;
			border-radius: 16px;
			display: flex;
			flex-direction: column;
			margin: 0 24px;
			max-width: none;
			padding: 24px;

			@include break-min($medium4) {
				margin: 0;
				width: 321px;
			}

			.title {
				order: 2;
			}

			button {
				order: 1;
				margin: 0 auto 8px;
			}

			@include break-min($medium4) {
				padding: 40px 25px;
			}

			@include break-min($large1) {
				padding: 40px 28px 23px;
			}
		}

		.title {
			@include break-min($medium4) {
				margin: auto;
				max-width: 350px;
			}
		}

		.btn {
			display: block;
			margin: 16px auto 0;
			order: 3;
			min-width: 144px;
			width: fit-content;
		}
	}

	.video-container {
		border-radius: 32px;
		height: 500px;
		grid-row: 1 / span 2;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large1) {
			grid-column: col-start 1 / span 12;
			margin: 0;
		}

		@include break-min($huge4) {
			height: 523px;
		}

		.overlay {
			display: none;
		}
	}
}
