.covid-service-community {
	.covid-text-panel:not(.callout-view) {
		background: $cool-white;
		margin-bottom: 80px;

		@include break-min($large1) {
			margin-bottom: 140px;
		}
	}

	.accordion {
		@extend .delivery-accordion;
		background: $cool-white;
		margin-bottom: 0;
		padding: 65px 24px;

		@include break-min($large1) {
			background-color: $cool-white;
			margin: 100px 0 0;
			padding: 95px 0 130px;
		}

		h1 {
			margin-bottom: 30px;
		}
	}

	.g-boxes {
		padding: 0 0 64px;

		@include break-min($large1) {
			padding: 0 0 196px;
		}

		&__footer {
			padding-top: 10px;
		}
	}

	.callout-view {
		padding-top: 0;

		@include break-min($large1) {
			padding-top: 33px;
		}

		@include break-min($huge1) {
			padding-left: 260px;
		}

		.tile-img {
			@include break-min($large1) {
				top: -41px;
			}
		}
	}
}
