.global-shadow-box-list {
	list-style-type: none;
	max-width: $col10;

	@media screen and (max-width: $mobile) {
		max-width: 100%;
	}

	li {
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		padding: 30px 20px;
		margin-bottom: 10px;
		position: relative;

		@include break-min($large1) {
			padding: 30px 40px;
		}

		p {
			@include p2();
			font-weight: 900;

			&:focus {
				box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
			}
		}

		h4 {
			@include h4();
			color: $blue;
			margin: 0;

			a {
				@include link-underline();
				color: inherit;
			}
		}

		.btn {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__container {
		overflow: hidden;
		padding: 40px 0 0;
		position: relative;
		z-index: $z1;

		@include break-min($large1) {
			padding: 70px 0 0px;
		}

		&.cream-bckgrd {
			background: $warm-white;

			.global-shadow-box-list {
				li {
					background-color: $white;
				}
			}
		}

		&.pad-bottom {
			padding-bottom: 40px;

			@include break-min($large1) {
				padding-bottom: 70px;
			}
		}

		.header {
			margin: auto auto 60px auto;
			max-width: 900px;
			padding: 0 16px;
			position: relative;
			text-align: center;
			width: 100%;
			z-index: $z2;

			@include break-min($large1) {
				padding: 0 24px;
			}

			h1,
			h2,
			h3 {
				@include type-h2();
				color: $vis-blue;
				display: block;
				margin: 0 auto;
				padding: 0 0 25px;
				width: 100%;

				@include break-min($large1) {
					padding: 0 0 25px;
				}
			}

			p {
				@include type-p2();
				color: $vis-grey;
				margin: 0 auto;
				padding: 0;

				+ p {
					padding: 15px 0 0;
				}
			}
		}
	}
}
