.join-email-module {
	&.hasSubmittedSuccess {
		form {
			display: none;
		}
	}

	.title {
		opacity: 1;

		&.preAnimation {
			opacity: 0;
		}
	}

	&.body-copy {
		background: $cool-white;
		padding: 80px 24px 72px;

		@include break-min($large1) {
			padding: 112px 0px 96px;
			margin: auto;
			text-align: center;
		}

		* {
			@include break-min($large1) {
				max-width: 800px;
				margin: 0 auto;
			}
		}

		.title {
			> *:first-child {
				@include font-size(2.8);
				color: $blue;
				font-weight: $bold-weight;
				letter-spacing: -0.01em;
				line-height: lh(34, 28);
				margin: 0 0 24px 0;

				@include break-min($large1) {
					@include font-size(4.8);
					letter-spacing: -1px;
					line-height: lh(58, 48);
				}
			}

			p {
				@include font-size(1.8);
				letter-spacing: -0.5px;
				line-height: lh(28, 18);
				margin: 0 0 40px 0;

				@include break-min($large1) {
					margin: 0 0 64px 0;
				}
			}
		}

		form {
			.form-wrapper {
				@include break-min($large1) {
					max-width: 560px;
				}
			}

			.error-container {
				@include break-min($large1) {
					max-width: 560px;
				}
			}

			.disclaimer-txt {
				@include break-min($large1) {
					max-width: 680px;
				}

				p {
					margin: 48px 0 0 0;
				}
			}
		}
	}

	&.footer-copy {
		margin: 0 auto 104px;
		max-width: 500px;

		@include break-min($large1) {
			margin: 0 auto 40px;
			max-width: 405px;
		}

		.title {
			> *:first-child {
				@include font-size(2);
				color: $blue;
				font-weight: $bold-weight;
				letter-spacing: -0.01em;
				line-height: lh(28, 20);
				margin: 0 0 20px 0;
			}
		}

		input[name="email"] {
			background: $concrete;
		}

		.form-wrapper {
			button[type="submit"] {
				width: 124px;
			}
		}
	}

	form {
		.error-container {
			@include font-size(1.2);
			color: $red;
			letter-spacing: -0.5px;
			line-height: lh(20, 12);
			margin: 6px 0 0 20px;
			text-align: left;

			@include break-min($large1) {
				margin: auto;
			}
		}

		.hasError {
			@extend .global-form-error;

			input {
				border: 1px solid $red;
			}
		}

		.form-wrapper {
			@extend .global-form-input-capsule;

			button {
				height: 52px;
			}
		}

		.disclaimer-txt {
			p {
				@include font-size(1.4);
				letter-spacing: -0.25px;
				line-height: lh(23, 14);
				margin: 40px 0 0 0;

				@include break-min($large1) {
					@include font-size(1.2);
					letter-spacing: -0.5px;
					line-height: lh(20, 12);
					margin: 16px 0 0 0;
				}
			}
		}
	}
}
