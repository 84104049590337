.img-text-card {
	color: $white;
	text-align: center;
	padding: 0 0 0 17px;
	overflow: hidden;
	// margin: 0 0 0 52px;

	@include break-min($medium4) {
		// margin: 0 0 52px;
	}

	@include break-min($large1) {
		// margin-bottom: 96px;
	}

	.slick-slide {
		margin: 0 1% !important;
		width: 320px !important;
	}

	ul {
		display: block;
		margin: 0 0 0 -50px;
		padding: 0;
		gap: 19px;

		@include break-min($medium4) {
			@include grid-margin();
			display: grid;
			justify-content: center;
			margin: 0;
		}

		li {
			display: flex;
			flex-direction: column;
			border-radius: 31px;
			list-style: none;
			// margin: 0 19px;
			overflow: hidden;
			grid-column: auto / span 4;

			@include break-max($medium4) {
				width: 320px !important;
				display: inline-block;
			}
		}

		li:nth-child(even) {
			.text-container {
				@include break-min($large4) {
					order: 2;
				}
			}
		}

		.text-container {
			background: $red;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 224px;
			padding: 24px;

			p {
				&:first-of-type {
					@include font-size(2.4);
					font-weight: $bold-weight;
					line-height: lh(32, 24);
					letter-spacing: -0.5px;

					@include break-min($large1) {
						@include font-size(3);
						line-height: lh(42, 30);
					}
				}
			}

			a {
				@include font-size(1.6);
				color: $white;
				line-height: lh(24, 16);
				letter-spacing: -0.5625px;
			}
		}

		.img-container {
			height: 224px;
			overflow: hidden;
			position: relative;
			width: 100%;

			img {
				@include absolute-center;
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}
	}

	.btn-round {
		margin: 20px auto 0;
		width: 135px;

		@include break-min($large4) {
			margin: 41px auto 4px;
		}
	}
}
