.team-member-landing-callout {
	background: $cool-white;

	&.red {
		background: $red;
	}

	&.blue {
		background: $blue;
	}

	&.cool-white {
		background: $cool-white;
	}

	&.white {
		background: $white;
	}

	.content {
		h4 {
			@include break-min($large1) {
				max-width: 340px;
			}
		}
	}
}
