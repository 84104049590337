.paragraph-field {
	@include add-margin();
	@include add-padding();
	@include text-alignment();
	@include text-color-options();

	&.p1-type {
		p {
			@include type-p1-refresh();
		}
	}

	&.p2-type {
		p {
			@include type-p2-refresh();
		}
	}
}
