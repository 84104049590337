.membership-tab-content {
	@include grid-margin();
	display: grid;
	justify-content: center;
	padding: 40px 0 48px;

	@include break-min($medium4) {
		gap: 24px;
		padding: 80px 0 100px;
	}

	.grid-wrapper {
		grid-column: col-start 1 / span 4;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 12;
		}
	}

	.title {
		margin: 0 auto;
		max-width: 930px;

		> *:first-child {
			@include type-h1-refresh();
			color: $blue;
			margin: 0 0 18px;
		}

		p {
			@include type-p1-refresh();
			margin: 0 0 18px;

			@include break-min($medium4) {
				margin: 0 0 57px;
			}
		}

		@include break-min($medium4) {
			text-align: center;
		}
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.navigation > li,
	.content > li {
		display: none;
		opacity: 0;
		transition: ease-in 300ms;
		visibility: hidden;

		&.active {
			display: block;
			opacity: 1;
			visibility: visible;
		}
	}

	.navigation > li {
		@include break-min($medium4) {
			display: block;
			opacity: 1;
			visibility: visible;
		}
	}

	.navigation {
		display: flex;
		flex-wrap: nowrap;
		position: relative;

		@include break-min($medium4) {
			gap: 25px;
		}

		li {
			position: relative;
			text-align: center;
			width: 100%;

			@include break-min($medium4) {
				cursor: pointer;
			}

			span {
				@include type-h3-refresh();
				color: $grey-p;
				display: block;
				font-weight: $regular-weight;
				width: fit-content;
				margin: 0 auto;
				padding: 0 50px 22px;

				@include break-min($medium4) {
					padding: 0 0 30px;
				}
			}

			.border-bottom {
				border-bottom: 5px solid transparent;
				border-radius: 20px;
				bottom: 0;
				margin: auto;
				position: absolute;
				left: 0;
				max-width: 132px;
				right: 0;
				transition: all 0.3s ease;

				@include break-min($medium4) {
					max-width: none;
					width: 92%;
				}
			}

			&.active {
				span {
					color: $red;
					font-weight: $bold-weight;
				}

				.border-bottom {
					border-bottom: 5px solid $red;
				}

				&::after,
				&::before {
					content: "";
					position: absolute;
					bottom: -5px; // Adjust as needed
					width: 10px; // Adjust as needed
					height: 10px; // Adjust as needed
					border-bottom: 5px solid $red;
				}

				&::after {
					left: 51%;
					transform: translateX(-50%) rotate(45deg);
				}

				&::before {
					right: 50%;
					transform: translateX(50%) rotate(-45deg);
				}
			}

			&.hidden {
				display: none;
			}
		}
	}

	.content {
		background: $cool-white;
		border-radius: 32px;
		overflow: hidden;
		padding: 45px 22px;

		@include break-min($large4) {
			padding: 71px 125px;
		}

		> li {
			ul {
				display: flex;
				flex-direction: column;
				gap: 18px;
				transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
				visibility: hidden;

				@include break-min($medium4) {
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: center;
				}

				li {
					align-items: center;
					display: flex;

					@include break-min($medium4) {
						margin: inherit;
					}
				}
			}

			.icon-container {
				margin: 0 4px 0 0;

				@include break-min($medium4) {
					margin: 0 24px 0 0;
				}

				img {
					height: 40px;
					width: 40px;

					@include break-min($medium4) {
						height: 80px;
						width: 80px;
					}
				}
			}

			.blurb {
				display: flex;

				@include break-min($medium4) {
					align-items: center;
					display: flex;
					flex: 1;
					max-width: 232px;
				}

				p {
					@include font-size(1.4);
					align-items: center;
					display: flex;
					letter-spacing: -0.25px;
					line-height: lh(20px, 14px);
					margin: 0;

					@include break-min($medium4) {
						@include font-size(1.8);
						line-height: lh(28, 18);
						letter-spacing: -0.5px;
					}
				}
			}

			&.active {
				ul {
					opacity: 1;
					transition: opacity 0.3s ease-in-out;
					visibility: visible;

					@include break-min($medium4) {
						display: grid;
						grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
						gap: 32px;
						list-style-type: none;
						padding: 0;
						margin: 0;
					}
				}
			}
		}
	}

	.arrow-wrapper {
		position: relative;
	}

	.arrow-container {
		display: flex;
		height: 100%;
		justify-content: space-between;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 100;

		@include break-min($medium4) {
			display: none;
		}

		img {
			cursor: pointer;
			height: 20px;
			width: 20px;
		}
	}
}
