@import "variations/covid/covid-serving-safely-modal";
@import "variations/operator-sites/index";
@import "variations/leaving-cfa-modal";
@import "variations/video-popout";

.base-modal {
	background: $white;
	margin: 0 auto;
	position: relative;

	&:focus {
		font-weight: inherit;
	}

	.inner {
		position: relative;
	}

	.mfp-close {
		@include lightbox-close-btn();
		opacity: 1;
	}
}
