.covid-landing {
	.generic-text-panel {
		padding-bottom: 0;

		@include break-min($large1) {
			padding-bottom: 57px;
		}
	}

	.covid-sticky-nav-dropdown {
		@extend .covid-sticky-nav-dropdown-variation-body;

		&.mobileSlickSlider {
			background: linear-gradient(
				to bottom,
				$cool-white 0% 40%,
				$cream 40% 100%
			);
		}
	}

	.covid-text-panel:not(.callout-view) {
		background: $cool-white;

		.wrapper {
			p:last-of-type {
				margin-bottom: 55px;
			}
		}
	}

	.block_img_header_txt {
		@extend .covid-block_img_header_txt;
		padding-top: 200px;

		@include break-min($large1) {
			align-items: center;
			margin-top: -80px;
			padding: 165px 86px 100px;
		}

		.img-wrapper {
			@include break-min($large1) {
				width: 50%;
			}

			img {
				width: 72px;

				@include break-min($large1) {
					width: 100%;
				}
			}
		}

		.content {
			@include break-min($large1) {
				margin-left: 60px;
			}
		}
	}

	.covid-latest-update {
		@include break-min($large1) {
			margin-bottom: 10px;
		}
	}
}
