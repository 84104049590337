.card-overlay-container {
	@extend .grid-gap;
	justify-content: center;
	margin: 0 0 60px 0;

	&[data-child-count="1"] {
		@include break-min($huge4) {
			grid-template-columns: repeat(12, [col-start] 104px);
			grid-column: col-start 1 / span 12;
		}

		.overlay-full-callout {
			@extend .card-overlay;

			@include break-min($huge4) {
				margin: 0 auto !important; // sass-lint:disable-line no-important
			}
		}

		.card-overlay {
			.message-container {
				.icon-container,
				.title-text {
					@include break-min($large4) {
						margin-bottom: 16px;
					}
				}
			}
		}
	}

	&[data-has-multiple="true"] {
		@extend .callout-margins;

		.overlay-full-callout {
			grid-column: col-start 1 / span 4;
			height: auto;
			margin: 0;
			padding: 146px 16px 16px;
			width: 100%;

			.message-container {
				@include card-overlay-container-heights();
				position: relative;

				.btn {
					@include break-min($medium4) {
						bottom: 25px;
						margin: auto;
						position: absolute;
					}
				}
			}
		}
	}

	&[data-child-count="2"] {
		&.proportion30-70 {
			@extend .proportion30-70;
		}

		&.proportion70-30 {
			@extend .proportion70-30;
		}

		.overlay-full-callout {
			width: 100%;

			@include break-min($medium4) {
				grid-column: auto / span 4;
				padding: 278px 16px 16px;
			}

			@include break-min($large4) {
				grid-column: auto / span 6;
				padding: 24px;
			}

			.message-container {
				padding: 24px;

				@include break-min($medium4) {
					grid-column: col-start 1 / span 8;
					min-height: 342px;
				}

				@include break-min($huge4) {
					grid-column: col-start 1 / span 4;
				}

				.btn {
					@include break-min($medium4) {
						left: 24px;
						width: fit-content;
					}
				}
			}
		}
	}

	&[data-child-count="3"] {
		@include break-min($medium4) {
			display: flex;
		}

		@include break-min($huge4) {
			display: grid;
		}

		&.proportion25-25-50,
		&.proportion50-25-25 {
			.overlay-full-callout {
				@include break-min($medium4) {
					padding-top: 137px;
				}
			}
		}

		&.proportion25-25-50 {
			@extend .proportion25-25-50;

			> :last-child {
				@include proport50-25-25OverlayFinalElem();
			}
		}

		&.proportion50-25-25 {
			@extend .proportion50-25-25;

			> :first-child {
				@include proport50-25-25OverlayFinalElem();
			}
		}

		.overlay-full-callout {
			@include break-min($medium4) {
				padding: 118px 16px 16px 16px;
			}

			@include break-min($huge4) {
				grid-template-columns: none;
				grid-column: auto / span 4;
				padding-top: 280px;
			}

			.message-container {
				@include break-min($medium4) {
					grid-column: col-start 1 / span 12;
					padding: 24px;
				}

				@include break-min($huge4) {
					grid-column: col-start 1 / span 4;
				}

				.btn {
					@include break-min($medium4) {
						left: 0;
						right: 0;
						width: 94%;
					}
				}
			}
		}
	}
}
