.covid-chickwire-callout {
	height: 575px;
	position: relative;
	z-index: 0;

	@include break-min($large1) {
		height: 450px;
	}

	.callout {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		padding: 0 24px;
		position: absolute;
		z-index: 1000;

		@include break-min($large1) {
			display: grid;
			grid-template-columns: 0.5fr 2fr;
			padding: 75px 72px;
		}

		@include break-min($large4) {
			padding: 150px 142px;
		}

		img {
			margin: 0 0 12px -5px;
			width: 148px;

			@include break-min($large1) {
				margin: auto;
			}
		}

		h2 {
			@include type-h2();
			@include font-size(3.6);
			color: $white;
			letter-spacing: -0.8px;
			line-height: lh(46, 36);
			max-width: 310px;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

			@include break-min($large1) {
				@include font-size(6.4);
				align-self: center;
				letter-spacing: -1.5px;
				line-height: lh(74, 64);
				margin: 0 0 0 -15px;
				max-width: 870px;
			}
		}
	}

	.bckgrd-img {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 100;
		overflow: hidden;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		&:after {
			content: "";
			background: rgba(0, 0, 0, 0.31);
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 105;
		}

		&.mobile {
			@include break-min($large1) {
				display: none;
			}
		}

		&.desktop {
			display: none;

			@include break-min($large1) {
				display: inline-block;
			}
		}
	}
}
