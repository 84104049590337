.three-column-cards {
	padding: 104px 0;

	@include break-min($large1) {
		padding: 124px 0;
	}

	.header {
		margin: auto;
		width: $col10;
		text-align: center;

		h2 {
			@include font-size(2.4);
			color: $blue;
			font-family: $primary;
			font-weight: 700;
			letter-spacing: -0.5px;
			margin: 2rem 0;
			line-height: 32px;

			@include break-min($medium3) {
				@include font-size(2.4);
				line-height: 28px;
			}
		}

		P {
			line-height: 28px;
		}

		p {
			@include p1();
			margin-bottom: 50px;
		}
	}

	&__grid {
		margin-left: -10px;
		margin-right: -10px;
		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(stretch);
		@include flex-wrap(wrap);
	}

	&__btn-wrap {
		text-align: center;
		padding: 35px 0 0;

		a {
			margin: 0 auto;
		}
	}
}
