.header-landing {
	background-color: $red;
	margin-bottom: 122px;
	margin-top: 64px;
	padding: 29px 0 28px;
	text-align: center;
	width: 100%;

	@include break-min($large1) {
		margin-top: 0;
		padding: 48px 0;
	}

	img {
		max-height: 200px;
		height: auto;
		width: auto;
	}
}
