.store-amenities {
	ul {
		list-style-type: none;
		margin-top: 0;
		padding-left: 0;

		&.amenities-list {
			li {
				display: inline;
				@include font-size(1.6);
				line-height: 24px;
			}
		}
	}
}
