.covid-serving-safely {
	.accordion {
		@extend .delivery-accordion;

		@include break-min($large1) {
			background-color: $cool-white;
			margin: 100px 0 0;
			padding: 95px 0 130px;
		}

		h1 {
			margin-bottom: 30px;
		}
	}

	.block_img_header_txt {
		@extend .covid-block_img_header_txt;
		display: flex;
		flex-direction: column;
		padding-top: 70px;

		@include break-min($large1) {
			flex-direction: row;
		}

		.img-wrapper {
			margin: auto;
			width: 90%;

			@include break-min($large1) {
				margin: auto 0;
			}
		}
	}

	.facts {
		@extend .covid-facts;
	}
}
