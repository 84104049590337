.grandOpening-container {
	background: $warm-white;
	margin: 0;
	padding: 16px;

	h2 {
		@include font-size(2.8);
		font-weight: bold;
		color: $blue;
		text-align: center;

		@include break-min($large1) {
			@include font-size(4);
		}
	}

	.grand-opening-list {
		@include flexbox();
		flex-wrap: wrap;
		padding: 0;
		margin: 0;

		li {
			background: $white;
			border-radius: 5px;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
			list-style-type: none;
			flex: 1 0 32%;
			margin: 0 7px 15px 7px;
			max-width: calc(50% - 15px);
			padding: 20px 15px;

			@media screen and (min-width: $mobile) {
				max-width: calc(33.3333% - 15px);
			}

			&:focus {
				@include focus($red);
			}

			p {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}

				@include break-min($large1) {
					margin-bottom: 5px;
				}
			}
		}

		h3 {
			@include font-size(2.2);
			color: $red;
			font-weight: 800;
			margin: 0px 0 15px;
			//margin-bottom: 15px;
		}

		p {
			@include type-p2();
			@include font-size(1.3);
		}

		.opening {
			color: $dark-orange;
		}
	}

	.additional-info {
		@include p2();
		padding-top: 20px;
	}
}
