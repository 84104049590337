.map-modal {
	max-width: 1156px;

	.inner {
		// sass-lint:disable no-important
		padding: 0 0 25px 0 !important;

		@include break-min($large1) {
			margin: 0;
		}

		.button-grouping {
			justify-content: center;

			a {
				margin: 0 10px;
			}

			button {
				margin: 0 10px;
			}
		}

		button:first-of-type {
			&.mfp-close {
				background-color: $white;
				border-radius: 50%;
				border: 1px solid $grey;
				top: 20px;
				right: 20px;
				height: 48px;
				width: 48px;
			}
		}

		h3 {
			color: $blue;
			font-weight: $bold-weight;
			margin-bottom: 5px;
			@include font-size(2);
		}

		p {
			color: $blue;
			@include font-size(1.2);
		}

		iframe {
			width: 100%;
			height: 700px;
		}

		.btn-round--red {
			&.icon-opportunity {
				&:after {
					content: "";
					background-image: url("img/openwindowiconwhite.svg");
					background-repeat: no-repeat;
					width: 17px;
					height: 20px;
					background-size: contain;
					float: right;
					margin-left: 10px;
				}
			}
		}
	}
}
