.gifting-title {
	margin: 0 0 24px; //for gifting-text area accom
	text-align: center;

	@include break-min($large1) {
		grid-column: 2;
		margin: 60px 0 24px;
		padding: $right-aligned-column-gap;
		text-align: left;
	}

	@include ie-grid-fix();

	.icon {
		//from _reward-card.scss file
		@extend .reward-icon;
		height: 96px;
		margin: 0 auto 24px;
		width: 96px;

		@include break-min($large1) {
			height: 80px;
			margin: 0 0 40px;
			width: 80px;
		}
	}

	h1,
	h3 {
		@include type-h2();
		color: $blue;
		margin: 0 0 10px;

		@include break-min($large1) {
			line-height: calc(42 / 36);
			margin: 0 0 16px;
			padding: 0;
		}
	}

	p {
		@include type-p1();
		@include font-size(1.8);
		line-height: 1.55555555556;
		margin: 0;
		font-weight: normal;

		@include break-min($large1) {
			@include type-p2();
			line-height: 1.5625;
		}
	}
}
