.search-form {
	margin: 0 auto;
	position: relative;
	border: 2px solid transparent;
	justify-content: space-between;

	@include break-min($large1) {
		margin: 0;
		max-width: 700px;
		padding: 0;
	}

	&.error-state {
		border: 2px solid $red;
		border-radius: 27px;

		.search-form-error-label {
			display: block;
			text-align: left;
		}
	}

	&-error-label {
		position: absolute;
		color: $red;
		top: -32px;
		display: none;
		left: 0;
		right: 0;
	}

	input {
		border-radius: 24px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border: none;
		background-color: $concrete;
		padding: 0 32px;
		width: 100%;
	}

	.btn-round,
	.btn-search {
		margin: 0;
		font-family: $primary;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.5625px;
		padding: 12px 32px;
		width: auto;

		@include break-min($large1) {
			padding: 15px 48px;
		}

		&-half {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			flex-direction: column;
			display: flex;
			align-items: center;

			&:hover {
				transform: translateY(0px);
			}
		}
	}
}
