// ToDo: rmv id selectors. Justification: squeezed timeline
// sass-lint:disable no-ids
.remove-use-location {
	.pwo-modal {
		padding-bottom: 100px;

		.pwo-modal-search {
			.use-location {
				display: none;
			}

			&:not(.favorited-restaurants) {
				.btn-round {
					bottom: -120px;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					width: calc(100% - 15px);

					@include break-min($large1) {
						max-width: 150px;
						min-width: 144px;
						width: auto;
					}
				}
			}
		}

		&.favorited-restaurants-yext,
		&#favorited-restaurants-yext {
			padding: 0;

			.content {
				padding: 64px 0 0;
				position: relative;

				h2 {
					@include break-min($large1) {
						padding: 0 42px;
					}
				}
			}

			.secondary {
				padding-bottom: 44px;
				position: relative;
			}

			.use-location-form {
				background: $cool-white;
				bottom: 22px;
				margin-left: 17px;
				padding: 0;
				position: absolute;

				@include break-min($large1) {
					padding: 0;
				}
			}
		}

		&.last-ordered-yext {
			padding: 0;

			.content {
				padding: 64px 0 0;
			}

			h2,
			.pwo-modal-search {
				padding: 0 15px;

				@include break-min($large1) {
					padding: 0 42px;
				}
			}

			.use-location {
				margin-left: 15px;

				@include break-min($large1) {
					margin-left: 44px;
				}
			}

			.pwo-modal-last-ordered {
				margin-top: 117px;
			}
		}

		.use-location-form {
			padding: 0 15px;
		}
	}
}
