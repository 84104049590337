.overlay-address {
	background-color: $white;
	margin: 0 auto;
	padding: 20px 50px 10px 20px;
	position: relative;
	text-align: center;
	width: $col3;

	@media only screen and (max-width: $mobile) {
		width: $col10;

		iframe {
			border: 0;
			width: $col12;
		}
	}

	p {
		@include font-size(1.6);
		font-family: $primary;
		line-height: 24px;
	}

	.options {
		@include align-items(center);
		@include justify-content(space-between);
		display: flex;
		font-family: $primary;
		margin: 30px auto;
		width: $col10;
	}

	&.payment-modal {
		padding: 20px 20px 10px 20px;
	}

	&.greater-width {
		width: $col11;
		padding: 20px 0 10px 0;

		@include break-min($large1) {
			padding: 20px 50px 10px 20px;
		}

		@media screen and (min-width: $mobile) {
			width: $col5;
		}
	}
}
