.location-menu-hero {
	display: flex;
	flex-direction: column;
	font-family: $primary;
	margin-left: auto;
	color: $grey;
	background-color: $cool-white;

	@include break-min($large1) {
		background-color: $white;
		flex-direction: row;
		margin-left: auto;
		padding-left: 40px;
	}

	@include break-min($large4) {
		padding-left: 142px;
	}

	&[data-temp-closed="true"] {
		.hours {
			display: none;
		}
	}

	h1,
	p {
		margin: 0;
	}

	a {
		text-decoration: none;
	}

	li {
		list-style: none;
	}

	.location-details {
		flex-basis: 57.2%;
		padding-top: 30px;
		margin-bottom: 29px;
		text-align: center;

		@include break-min($large1) {
			margin: auto;
			padding: 30px 40px 30px 0;
			text-align: left;
		}
	}

	.icon {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-bottom: 8px;

		@include break-min($large1) {
			width: inherit;
			margin-bottom: 3px;
		}

		img {
			background-color: $iron;
			border-radius: 50%;
			height: 80px;
			width: 80px;
		}

		p {
			display: none;

			@include break-min($large1) {
				display: block;
				font-size: 14px;
				line-height: 23px;
				text-align: center;
				letter-spacing: -0.25px;
			}
		}
	}

	.location-name {
		margin-bottom: 16px;

		@include break-min($large1) {
			align-items: center;
			display: flex;
			margin-bottom: 25px;
		}

		h1 {
			@include font-size(2.8);
			text-align: center;
			font-weight: $bold-weight;
			line-height: 34px;
			letter-spacing: -0.01em;

			@include break-min($large1) {
				font-style: normal;
				font-weight: bold;
				text-align: left;
				font-size: 48px;
				line-height: 58px;
				margin-right: 18px;
			}
		}

		.heart-icon {
			@include background-size-cover();
			display: none;
			background-image: url("img/icon_heart-gray.svg");
			margin-right: 7px;
			width: 32px;
			height: 32px;

			@media screen and (max-width: $mobile) {
				margin-right: 5px;
			}

			@include break-min($large1) {
				display: block;
			}
		}

		.red-heart-icon {
			@include background-size-cover();
			background-image: url("img/icon_heart-red.svg");
			margin-right: 7px;
			height: 32px;
			width: 32px;

			@media screen and (max-width: $mobile) {
				margin-right: 5px;
			}
		}
	}

	.location-address {
		display: flex;
		justify-content: center;
		margin-bottom: 8px;
		font-size: 18px;
		line-height: 28px;
		letter-spacing: -0.5px;

		@include break-min($large1) {
			justify-content: flex-start;
		}

		> p {
			margin: 0px;

			@include break-min($large1) {
				text-align: left;
				margin-right: 5%;
			}

			&:last-child {
				display: none;

				@include break-min($large1) {
					display: block;
					margin-right: 0;
				}
			}
		}
	}

	.hours {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $vis-green;
		letter-spacing: -0.5px;

		@include break-min($large1) {
			justify-content: flex-start;
		}

		.clock-icon {
			display: none;
		}

		p {
			color: $green;
			font-size: 14px;
			font-weight: $bold-weight;
			line-height: 28px;
			margin: 0;
			padding-left: 27px;
			position: relative;

			&::before {
				@include background-size-cover();
				content: "";
				background-image: url("img/icon_clock-green.svg");
				height: 20px;
				position: absolute;
				top: 2px;
				left: 0;
				width: 20px;
			}

			&[data-is-open="false"] {
				color: $red;

				&::before {
					background-image: url("img/icon_clock-red.svg");
				}
			}
		}
	}

	.order-cta {
		background-color: $cool-white;
		flex-basis: 51.8%;
		text-align: center;

		@include break-min($large1) {
			padding: 93px 119px 94px;
		}

		&.disabled {
			li a {
				pointer-events: none;

				&:hover {
					box-shadow: none;
					transform: none;
				}

				p {
					color: $faint-grey;
				}
			}
		}

		h2 {
			display: none;

			@include break-min($large1) {
				display: block;
				max-width: 80%;
				margin: 0 auto 24px;
				font-weight: 700;
				font-size: 24px;
				line-height: 34px;
				text-align: center;
				letter-spacing: -0.5px;
			}
		}

		ul,
		li,
		a {
			display: flex;
		}

		ul {
			padding-left: 0;
			margin-bottom: 28px;
			margin-top: 0;
			justify-content: center;

			li {
				align-items: flex-end;
				flex-basis: 27%;
				max-width: 106px;
				justify-content: center;
				border-radius: 16px;
				margin-right: 8px;
				background-color: $white;
				box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);

				@include break-min($large1) {
					max-width: 120px;
				}

				a {
					@include btn-hover-effect();
					flex-direction: column;
					align-items: center;
					padding: 14px 40px;
					font-weight: 700;
					font-size: 14px;
					line-height: 17px;
					letter-spacing: -0.5px;
					color: $red;

					&:hover {
						border-radius: 16px;
					}

					@include break-min($large1) {
						padding: 16px 40px;
					}

					img {
						margin-bottom: 12px;
						max-width: 37px;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		> p {
			display: none;

			@include break-min($large1) {
				display: block;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 20px;
				letter-spacing: -0.5px;
			}
		}
	}

	.temp-closed {
		background: $warm-white;
		margin: 0 auto 44px;
		max-width: 300px;
		text-align: center;

		@include break-min($large1) {
			margin: 0;
			height: 450px;
			flex-basis: 51.8%;
			max-width: none;
		}

		p {
			@include font-size(1.2);
			color: $red;
			font-family: $secondary;
			font-weight: $bold-weight;
			letter-spacing: 2px;
			line-height: lh(14, 12);
			margin: 0 auto;
			max-width: 350px;

			@include break-min($large1) {
				@include font-size(1.8);
				align-items: center;
				display: flex;
				height: 100%;
				justify-content: center;
				letter-spacing: 4px;
				line-height: lh(20, 18);
			}
		}
	}

	&[data-cta-count="1"] {
		.order-cta {
			h2,
			ul,
			p {
				@include break-min($large1) {
					max-width: 325px;
				}
			}

			ul {
				padding: 0 24px;

				@include break-min($large1) {
					margin: 0 auto 28px;
					padding: 0;
				}

				li {
					flex-basis: 100%;
					justify-content: flex-start;
					max-width: none;
					width: 100%;

					@include break-min($large1) {
						justify-content: center;
					}

					a {
						flex-direction: row;
						justify-content: flex-start;
						padding: 30px 35px;
						position: relative;
						width: 100%;

						p {
							margin: 0;

							@include break-min($large1) {
								position: relative;
							}
						}

						img {
							margin: 0 35px 0 0;
							max-width: 59px;
						}
					}
				}
			}

			p {
				@include break-min($large1) {
					margin: 0 auto;
				}
			}
		}
	}
}
