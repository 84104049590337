.reward-card-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media only screen and (max-width: $sml-mobile) {
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

.reward-card {
	border-left: 5px solid $red;
	border-right: 5px solid $red;
	border-radius: 8px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 16px 0;
	max-width: 360px;
	padding: 10px 15px 85px;
	position: relative;
	width: $col6;

	@media only screen and (max-width: $sml-mobile) {
		margin: 16px auto;
		width: $col12;
	}

	&.star {
		border-color: $keppel;
	}

	&.cow {
		border-color: $cannon-pink;
	}

	&.recovery {
		border-color: $apricot-peach;
	}

	&.ribbon {
		border-color: $red;
	}

	&.operator,
	&.has-image {
		border-color: $salmon;
	}

	&.gift {
		border-color: $red;
	}

	.btn--vis {
		margin: 0 0 15px 0;

		&:last-child {
			margin: 0;
		}

		@include break-min($small3) {
			margin: 5px;
		}
	}

	.slick-prev,
	.slick-next {
		z-index: $z3;

		&:before {
			font-size: 24px;
			opacity: 1;
			color: $red;
			font-family: $icon;
		}
	}

	.slick-next {
		transform: rotate(270deg);
		right: -5px;

		&:before {
			content: "\e902";
		}
	}

	.slick-prev {
		transform: rotate(90deg);
		left: -5px;

		&:before {
			content: "\e902";
		}
	}

	.slick-dots {
		bottom: -10px;

		li {
			width: 12px;

			button {
				width: 12px;
				position: relative;

				&::before {
					content: none;
				}

				&::after {
					content: "";
					width: 6px;
					height: 6px;
					background-color: $alto;
					transition: background-color 0.2s ease;
					border-radius: 6px;
					margin: auto;
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					bottom: 0;
				}
			}

			&.slick-active {
				button {
					&::after {
						background-color: $vis-grey;
					}
				}
			}
		}
	}

	.slick-slider {
		> div {
			display: none;

			&:first-child {
				display: block;
			}
		}

		&.slick-initialized {
			> div {
				display: block;
			}
		}
	}
}

.reward-header {
	align-items: flex-start;
	display: flex;
	position: relative;

	.header-content {
		padding-left: 11px;
		width: $col10;
	}

	h4 {
		@include h5();
		color: $vis-blue;
		margin: 0;
		padding: 0;
	}

	p {
		@include p2();
		margin: 0;
		padding: 0;
	}
}

.reward-icon {
	background-color: $red;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 50%;
	margin-top: 5px;
	height: 48px;
	width: 48px;

	img,
	svg {
		border-radius: 50px;
		display: block;
		height: 48px;
		width: 48px;
	}

	.cfa & {
		background-color: $white;
		background-image: url("img/cp-icons/icon-cp_cfa-circle.svg");
	}

	.star & {
		background-color: $aqua-island;
		background-image: url("img/cp-icons/icon-cp_star.svg");
	}

	.cow & {
		background-color: $vanilla-ice;
		background-image: url("img/cp-icons/icon-cp_cow.svg");
	}

	.recovery & {
		background-color: $apricot-peach;
		background-image: url("img/cp-icons/icon-cp_recovery.svg");
	}

	.ribbon & {
		background-color: $apricot-peach;
		background-image: url("img/cp-icons/icon-cp_ribbon.svg");
	}

	.operator &,
	.has-image & {
		background-color: lighten($salmon, 15%);
		background-image: url("img/cp-icons/icon-cp_operator.svg");
	}

	.has-image & {
		background-image: none;
	}

	.gift & {
		background-color: $cupid;
		background-image: url("img/cp-icons/icon-cp_gift.svg");
	}

	.clock & {
		background-color: $white;
		background-image: url("img/cp-icons/icon-cp_clock.svg");
	}

	.error & {
		background-color: $white;
		background-image: url("img/cp-icons/icon-cp_error-icon.svg");
	}
}

.reward-body {
	padding-top: 30px;
	text-align: center;

	h5 {
		@include p1();
		margin: 0;
		padding: 0 0 15px;
	}

	img {
		display: inline-block;
		margin: 0 auto;
		max-height: 250px; //test on staging to confirm new value is good
	}

	.btn-round:nth-of-type(2) {
		margin-top: 12px;
		@include break-min($large1) {
			margin: 0 0 0 8px;
		}
	}

	.share-link-cta {
		color: inherit;
	}

	.lb-modal {
		&.btn-round {
			margin-top: 12px;
			@include break-min($large1) {
				margin: 0 0 0 8px;
			}
		}
	}
}

.reward-footer {
	@include p2();
	background-image: url("img/cp-icons/icon-cp_dot-gray.svg");
	background-size: 12px 6px;
	background-repeat: repeat-x;
	bottom: 10px;
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
	position: absolute;
	width: $col11;
}
