// Top-level page-selectors for minor adjustments to global components

.careers-franchise {
	.g-hero {
		@extend .g-hero--gradient;
		min-height: 600px;
	}
}

.franchise-emerging-markets {
	.g-hero {
		@extend .g-hero--gradient;

		.btn-container {
			display: none;
		}
	}
}

.franchise-operator-events {
	.breadcrumbs-nav {
		.page-nav {
			margin-bottom: 15px;
		}

		.btn-container {
			margin-top: 0;

			.btn-round {
				height: 100%;
				padding: 15px 19px;
			}
		}
	}

	.g-hero {
		@extend .g-hero--gradient;

		// .btn-container {
		// 	display: none;
		// }
	}

	.franchise-oper-events {
		margin: 60px auto 50px;

		@include break-min($large1) {
			margin: 65px auto 130px;
		}

		&.left-align-txt {
			.content {
				@include break-min($large1) {
					margin: auto 50px auto 0;
				}
			}
		}

		&.franchise-oper-events:nth-child(even) {
			margin-bottom: 60px;
		}

		.img-wrapper {
			@include flex(0 0 $col4);
		}

		.content {
			margin-top: auto;
			margin-bottom: auto;
			@include flex(0 0 $col6);
			@include break-max($large1) {
				text-align: left;
			}

			.btn {
				margin-top: 20px;
				text-align: center;
				@extend .btn-primary;
			}
		}
	}

	.global-shadow-box-list__container {
		.header {
			margin-bottom: 38px;
		}

		.global-shadow-box-list {
			li {
				padding: 25px 20px;

				@include break-min($large1) {
					padding: 35px 40px 50px;
				}
			}
		}
	}
}

.franchise-selection {
	.g-carousel-grid {
		margin-bottom: 70px;

		&__slide {
			@include break-min($large1) {
				height: 380px;
				max-width: 675px;
			}
		}
	}
}
