.delivery-subscription {
	height: 500px;
	position: relative;
	width: 100%;

	@include break-min($large1) {
		height: 446px;
	}

	.hero-img {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;

			&.img-desktop {
				display: none;

				@include break-min($large1) {
					display: block;
				}
			}

			&.img-mobile {
				@include break-min($large1) {
					display: none;
				}
			}
		}
	}

	.hero-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-end;
		margin: auto;
		max-width: 1000px;
		padding: 24px 24px 14px;
		position: relative;
		text-align: center;

		@include break-min($large1) {
			justify-content: center;
			padding: 0;
			text-align: left;
		}

		> *:first-child {
			@include font-size(3.6);
			color: $white;
			font-family: "apercu";
			font-weight: $bold-weight;
			letter-spacing: -0.8px;
			line-height: 46px;
			margin-bottom: 23px;

			@include break-min($large1) {
				@include font-size(6.4);
				letter-spacing: -1.5px;
				line-height: 74px;
				margin-bottom: 36px;
				max-width: 660px;
			}
		}

		.btn-round {
			@include font-size(1.6);
			display: block;
			font-weight: $regular-weight;
			letter-spacing: -0.56px;
			line-height: 24x;
			padding: 12px 40px;

			@include break-min($large1) {
				display: inline;
				width: 144px;
			}
		}

		p:last-child {
			@include font-size(1.1);
			color: $white;
			font-family: $primary;
			font-weight: $regular-weight;
			line-height: 13px;
			margin-bottom: 0;
			margin-top: 12px;
			text-align: center;

			@include break-min($large1) {
				margin-top: 19px;
				max-width: 327px;
				text-align: left;
			}
		}
	}
}
