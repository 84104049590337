.delivery-lookup-partners {
	background-color: $cool-white;
	padding: 48px 25px 35px;

	@include break-min($large1) {
		padding: 36px 25px 41px;
	}

	.wrapper {
		padding: 0;
	}

	p {
		margin: 0 auto;
		max-width: 577px;

		@include break-min($large1) {
			max-width: none;
		}
	}

	.price-disclosure {
		@include font-size(1.4);
		color: $rolling-stone;
		font-weight: $regular-weight;
		letter-spacing: -0.25px;
		line-height: 23px;
		margin-bottom: 0;
		text-align: center;
	}

	.lookup-partners {
		padding: 0;
		position: relative;
		text-align: center;
		width: 100%;

		@include break-min($large1) {
			margin-bottom: 32px;
		}

		p:first-child {
			@include font-size(1.8);
			color: $grey;
			font-weight: $regular-weight;
			letter-spacing: -0.5px;
			line-height: 28px;
		}

		ul {
			@extend .delivery-btn-border-shadow;
			margin-bottom: 48px;
			padding: 0 15px;

			@include break-min($large1) {
				justify-content: center;
				margin: 16px auto 0;
				padding: 0;
			}

			li {
				height: 58px;
				width: 100%;

				@include break-min($medium2) {
					width: 297px;
				}

				@include break-min($large1) {
					margin-right: 16px;
					width: 200px;
				}

				&:nth-last-of-type(2) img {
					height: 24px;
				}

				&:last-of-type {
					margin-bottom: 0;

					@include break-min($large1) {
						margin: 0;
					}

					img {
						height: 29px;
					}
				}

				img {
					height: 19px;
					width: auto;
				}
			}
		}
	}
}
