.card-classic {
	@extend .callout-margins;
	border-radius: 16px;
	grid-column: col-start 1 / span 4;
	overflow: hidden;

	@include break-min($medium4) {
		border-radius: 32px;
		grid-column: col-start 1 / span 8;
	}

	@include break-min($large4) {
		grid-column: col-start 1 / span 12;
	}

	@include break-min($huge4) {
		margin-left: auto;
		margin-right: auto;
	}

	&.swap-order .message-container {
		@include break-min($huge4) {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			margin-left: -24px;
			overflow: hidden;
		}
	}

	&.no-gap {
		.video-container,
		.img-container {
			@include break-min($huge4) {
				margin-left: -24px;
			}
		}
	}

	.video-container,
	.img-container {
		@include break-min($huge4) {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			margin-left: 0;
			overflow: hidden;
		}
	}

	.message-container {
		@include break-min($medium4) {
			.title-text {
				margin-bottom: 18px;
			}
		}

		@include break-min($huge4) {
			border-bottom-left-radius: 32px;
			border-top-left-radius: 32px;
			overflow: hidden;
		}
	}
}
