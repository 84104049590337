.gifting-share-link {
	border: 2px solid transparent;
	display: flex;
	position: relative;
	margin: -8px 0 16px;

	@include break-min($large1) {
		grid-column: 2;
		margin: 0px 0 25px;
		max-width: 700px;
		padding: 0 40px 0 60px;

		@include ie-only() {
			margin-left: 350px;
		}
	}

	@include ie-grid-fix();

	input {
		border-radius: 24px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border: none;
		background-color: $concrete;
		padding: 0 32px;
		text-align: center;
		width: 100%;

		@include break-min($large1) {
			max-width: 392px;
		}
	}

	.btn-round,
	.btn-search {
		margin: 0;
		font-family: $primary;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.5625px;
		padding: 28px 52px;
		position: relative;
		width: auto;

		@include break-min($large1) {
			padding: 28px 75px;
		}

		&-half {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;

			&:hover {
				transform: translateY(0px);
			}
		}

		span {
			@include absolute-center();
			top: 17px;
			left: -1px;
			position: absolute;
		}
	}
}
