// sass-lint:disable max-file-line-count

.returned-results {
	@include font-size(3.6);
	letter-spacing: -0.5px;
	line-height: 140%;
	padding-bottom: 20px;
	padding-top: 45px;
	text-align: center;

	@media only screen and (max-width: $mobile) {
		@include font-size(2.4);
		letter-spacing: -0.5px;
		line-height: 30px;
		text-align: left;
	}

	h1 {
		font-size: 1em;
		margin: 0 0 15px 0;
	}
}

.has-results {
	.story-img {
		display: block;
		margin-bottom: 20px;

		@media only screen and (min-width: $lrg-tablet) {
			height: 200px;
		}

		@media only screen and (max-width: $mobile) {
			min-height: 250px;
		}
	}
}

.search-group {
	padding: 75px 30px;

	.menu-items,
	.story-items,
	.restaurant-items {
		background-color: transparent;

		.flex {
			@include justify-content(flex-start);

			@media only screen and (max-width: $mobile) {
				padding-left: 0;
			}
		}

		ul {
			list-style-type: none;
		}
	}

	.restaurant-items {
		.address {
			display: block;
		}
	}

	&:nth-child(even) {
		background-color: $bkgrey;
	}

	@media only screen and (max-width: $mobile) {
		padding: 30px 10px;
	}

	h2 {
		@include align-items(flex-end);
		@include justify-content(space-between);
		@include font-size(2.9);
		background: url("img/border-grey.png") transparent bottom left repeat-x;
		display: flex;
		font-weight: 400;
		margin: 0 0 10px 0;
		padding: 0 0 15px 0;

		span {
			@include font-size(1);
			font-family: $primary;
			letter-spacing: 2px;
			line-height: 20px;
			text-transform: uppercase;
		}
	}

	.item {
		margin-left: 2 * $col-margin;
		text-align: left;

		@media only screen and (max-width: $mobile) {
			margin: 0 0 30px 0;
		}

		a {
			@include link-underline();

			&:hover {
				border-color: $red;
			}
		}

		&:hover {
			.story-details {
				color: $red;
			}
		}
	}

	.menu-items,
	.story-items {
		padding: 50px 30px 40px;

		@media only screen and (max-width: $mobile) {
			padding: 0 15px;
		}
	}
}

.search-cfa {
	background-color: $white-smoke;
	border-radius: 5px;
	display: block;
	margin: 0 auto 50px;
	max-width: 475px;
	position: relative;
	width: 55%;

	@media only screen and (max-width: $lrg-tablet) {
		width: 60%;
	}

	@media only screen and (max-width: $mobile) {
		margin: 0 auto 50px;
		width: 100%;
	}

	label {
		@include visuallyhidden();
	}

	button {
		background-color: transparent;
		border: 0;
		color: $red;
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);

		.ipad & {
			height: 26px;
		}

		span {
			@include visuallyhidden();
		}

		@media only screen and (max-width: $mobile) {
			.iphone & {
				margin: 3px 0px 0 0;
				right: -10px;
				top: 5px;
			}
		}
	}

	input {
		&[type="text"] {
			@include font-size(1.6);
			background-color: transparent;
			border: 0;
			box-sizing: border-box;
			font-family: $primary;
			line-height: 28px;
			padding: 20px;
			vertical-align: middle;
			width: $col12;

			@media only screen and (max-width: $lrg-tablet) {
				._404 & {
					@include font-size(1.2);
					padding: 10px;
					width: 85%;
				}
			}

			@include placeholder() {
				// sass-lint:disable variable-for-property
				color: #bcb9b6;
				// sass-lint:enable variable-for-property
			}

			@media only screen and (max-width: $mobile) {
				@include font-size(1.2);
				width: $col12;

				._404 & {
					line-height: 20px;
					padding-top: 5px;
				}
			}
		}
	}

	.icon-search-off,
	.icon-search-on {
		&:before {
			@include font-size(1.8);
			vertical-align: middle;
		}
	}
}

.show-more {
	font-weight: 600;
	padding-top: 30px;
	text-align: center;

	@media only screen and (max-width: $mobile) {
		padding: 20px 15px;
		text-align: left;
	}
}

.story-items {
	background-color: transparent;

	.item {
		img {
			margin-bottom: 40px;

			@media only screen and (max-width: $mobile) {
				margin-bottom: 0;
			}
		}

		span {
			&.story-details {
				@include item-details();
				margin-top: 10px;

				&:hover {
					.date {
						color: $grey;
						cursor: default;
						pointer-events: none;
					}
				}

				.date {
					border: 0;
				}

				.nowrap {
					color: inherit;
					transition: none;
				}
			}
		}

		a {
			> span {
				color: $red;
			}

			&:hover {
				> span {
					color: $dkred;
				}
			}
		}
	}
}

.restaurant-items {
	padding: 50px 30px 40px;

	@media only screen and (max-width: $mobile) {
		padding: 0 15px 0;
	}

	img {
		margin-bottom: 20px;
	}

	.address {
		@include font-size(1.6);
		line-height: 28px;
	}
}

.no-results-top {
	@include font-size(2.2);
	// sass-lint:disable variable-for-property
	background-color: #e2163d;
	// sass-lint:enable variable-for-property
	color: $white;
	font-weight: 600;
	line-height: 34px;
	padding: 100px 0 70px;
	position: relative;

	.search-cfa {
		margin: 0;
		padding: 0;
		position: relative;
		width: 93%;

		input {
			&[type="text"] {
				padding-bottom: 8px;
				padding-top: 12px;

				@media only screen and (max-width: $mobile) {
					width: $col12;
				}
			}
		}

		@media only screen and (max-width: $lrg-tablet) {
			width: 100%;
		}

		button {
			position: absolute;
			right: 8px;
			top: 8px;

			@media only screen and (max-width: $mobile) {
				top: 4px;

				.iphone & {
					top: 8px;
				}
			}
		}
	}

	@media only screen and (max-width: $mobile) {
		padding: 50px 0;

		p {
			font-weight: 600;
			margin: 0;
		}
	}

	.none-returned {
		@include font-size(3.6);
		font-weight: 400;
		letter-spacing: -0.5px;
		line-height: 140%;
		margin: 10px 0 50px;

		@media only screen and (max-width: $mobile) {
			@include font-size(2.4);
			font-weight: 400;
			letter-spacing: -0.5px;
			line-height: 30px;
		}
	}

	.wrapper {
		box-sizing: border-box;
		padding: 0 60px;

		@media only screen and (max-width: $mobile) {
			padding: 0 20px;
		}
	}

	.content {
		width: $col8;

		@media only screen and (max-width: $mobile) {
			width: $col12;
		}
	}

	.cow {
		bottom: -117px;
		overflow: hidden;
		position: absolute;
		right: 0px;
		width: $col4;

		@media only screen and (max-width: $mobile) {
			display: none;
		}

		img {
			max-height: none;
			max-width: none;
			width: auto;
		}
	}
}

.no-results-sitemap {
	background-color: $cyan;
	padding: 50px 0 50px;

	@media only screen and (max-width: $mobile) {
		padding: 5px 0 0;
	}

	.wrapper {
		box-sizing: border-box;
		padding: 0 60px;

		@media only screen and (max-width: $mobile) {
			padding: 0;
		}
	}

	.title {
		@include font-size(3.6);
		display: inline-block;
		letter-spacing: -0.5px;
		line-height: 140%;
		max-width: $col8;

		@media only screen and (max-width: $mobile) {
			display: none;
		}
	}

	.flex {
		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}

	ul {
		@include font-size(1.8);
		list-style: none;
		width: $col4;

		li {
			a {
				@include link-underline();
			}

			&:first-child {
				font-weight: 600;

				@media only screen and (max-width: $mobile) {
					font-weight: 400;
				}
			}
		}

		@media only screen and (max-width: $mobile) {
			border-bottom: 1px solid #f4ebc1;
			margin: 0;
			padding: 0;
			width: auto;

			&.active {
				li {
					display: block;

					.icon-arrow-down {
						&:before {
							content: "\e903";
						}
					}
				}
			}
		}
	}

	li {
		margin: 20px 0;

		@media only screen and (max-width: $mobile) {
			display: none;
			margin: 0 0 20px 0;
			padding: 10px 20px;
		}

		&:first-child {
			margin-bottom: 40px;

			@media only screen and (max-width: $mobile) {
				@include font-size(2.8);
				display: block;
				letter-spacing: 0;
				line-height: 150%;
				margin: 10px 0;
			}
		}
	}

	.icon-arrow-down {
		@include font-size(1.6);
		color: $red;
		display: none;
		float: right;
		line-height: 28px;
		margin-right: 3px;

		@media only screen and (max-width: $mobile) {
			display: inline-block;
		}
	}
}

._404 {
	.no-results-top {
		.content {
			@include font-size(2.2);
			font-weight: 400;
			line-height: 34px;
			position: relative;
			text-align: center;
			width: 100%;
		}

		.search-cfa {
			margin: 0 auto;
			position: relative;
			width: $col6;

			@media only screen and (max-width: $mobile) {
				text-align: left;
				width: $col12;
			}
		}

		p {
			font-weight: 400;
			margin: 75px 0 20px;

			@media only screen and (max-width: $mobile) {
				margin-top: 100px;
			}

			&:first-child {
				margin-top: 50px;
			}
		}

		.large {
			@include font-size(15);
			font-weight: 600;
			margin-bottom: 150px;

			& + p {
				line-height: 1.5;
			}

			@media only screen and (max-width: $sml-mobile) {
				margin-bottom: 50px;
			}
		}
	}
}

.sticky-note {
	@include fir("img/note-404.png", 442px, 425px);
	display: none;
	left: 0;
	position: absolute;
	right: 0;
	margin: 0 auto;
	top: -80px;

	@media only screen and (max-width: $mobile) {
		background-size: 100%;
		height: 312px;
		width: 300px;
	}
}

.hoof {
	@include fir("img/hoof.png", 375px, 713px);
	display: none;
	position: absolute;
	top: -130px;
}
