.location-map {
	width: 100vw;
	height: 242px;
	background-color: $cool-white;
	position: relative;

	@include break-min($large1) {
		height: 308px;
	}

	iframe {
		border: 0;
		height: 100%;
		position: absolute;
		width: 100%;
	}
}
