.deactivate-account {
	h1 {
		color: $blue;
		font-weight: 900;
		margin-bottom: 50px;
	}

	.succ-msg {
		@include p1();
		color: $red;
		text-align: center;
	}

	.btn:not(.white) {
		margin-top: 35px;

		@media screen and (min-width: $mobile) {
			margin-top: 45px;
		}
	}
}
