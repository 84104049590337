.little-blue-menu-page {
	@include break-min($large1) {
		display: flex;
		flex: 0 1 auto;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.frame {
		width: 100%;
		display: flex;
		flex-flow: column;
	}

	.headline {
		padding: 0;
	}

	.wrapper:not(.footer-modules):not(.account-offers):not(.headline):not(.show-more) {
		max-width: 1380px;
		display: flex;
		flex-flow: column;
		width: 100%;
		padding: 0;

		@include break-min($large1) {
			padding: 0 30px;
		}

		.btn-round--red {
			&.icon-opportunity {
				display: block;
				float: left;

				&:after {
					content: "";
					background-image: url("img/openwindowiconwhite.svg");
					background-repeat: no-repeat;
					width: 17px;
					height: 20px;
					background-size: contain;
					float: right;
					margin-left: 10px;
				}
			}
		}
	}

	p {
		@include type-p2();
	}

	.little-blue-menu-callout {
		@include break-min($large1) {
			.flex-wrapper {
				position: relative;

				&:after {
					content: "";
					border-bottom: 1px solid $iron;
					width: 100%;
					display: block;
					bottom: 0;
					position: absolute;
				}
			}

			&:last-child {
				.flex-wrapper {
					&:after {
						content: none;
					}
				}
			}
		}
	}

	.short-bottom-icon-hero {
		margin-bottom: 0;
	}
}
