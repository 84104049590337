.image-text-carousel {
	@include background-color();
	@include text-color-options();
	background: $blue;
	color: $white;
	padding: 0 0 115px;

	@include break-min($large1) {
		padding: 118px 149px;
	}

	&.quote-module {
		padding-top: 58px;

		@include break-min($large4) {
			padding-top: 81px;
		}

		.content {
			p:first-of-type {
				margin-bottom: 49px;
			}

			p:nth-of-type(2) {
				@include type-p1-refresh();
				font-weight: $bold-weight;

				@include break-min($large4) {
					text-align: right;
				}
			}
		}
	}

	&.title-color {
		&__blue {
			.title > *:first-child {
				color: $blue;
			}
		}

		&__cool-white {
			.title > *:first-child {
				color: $cool-white;
			}
		}

		&__red {
			.title > *:first-child {
				color: $red;
			}
		}

		&__white {
			.title > *:first-child {
				color: $white;
			}
		}
	}

	&.subtitle-color {
		&__blue {
			.title p {
				color: $blue;
			}
		}

		&__cool-white {
			.title p {
				color: $cool-white;
			}
		}

		&__grey {
			.title p {
				color: $grey;
			}
		}

		&__red {
			.title p {
				color: $red;
			}
		}

		&__white {
			.title p {
				color: $white;
			}
		}
	}

	&.content-color {
		&__blue {
			p:first-of-type,
			p:nth-of-type(2) {
				color: $blue;
			}
		}

		&__red {
			p:first-of-type,
			p:nth-of-type(2) {
				color: $red;
			}
		}

		&__white {
			p:first-of-type,
			p:nth-of-type(2) {
				color: $white;
			}
		}
	}

	.title {
		margin: 0 auto 30px;
		text-align: center;
		max-width: 1000px;
		padding: 0 24px;

		@include break-min($large1) {
			margin: 0 auto 80px;
			padding: 0;
		}

		> *:first-child {
			@include type-h1-refresh();
			margin: 0 0 39px 0;

			@include break-min($large1) {
				margin: 0 0 16px 0;
			}
		}

		p {
			@include type-p1-refresh();
		}
	}

	.flex-wrapper {
		@include break-min($large1) {
			align-items: center;
			display: flex;
			max-width: 1465px;
			margin: 0 auto;
		}
	}

	.slide-controls-wrapper {
		position: relative;

		@include break-min($large1) {
			flex: 50%;
			overflow: hidden;
		}
	}

	.slider-container {
		margin: 0 0 94px 0;
		overflow: hidden;

		@include break-min($large1) {
			border-radius: 24px;
			margin: 0;
			max-width: 700px;
		}
	}

	.slider-tile {
		@include swap-img();

		&.video {
			@include remove-bc-controls();
			height: 100%;
			margin: 0;

			.video-container {
				height: 100%;
				position: relative;

				&.isPlaying {
					.overlay {
						display: none;
					}
				}

				.overlay {
					@include absolute-center();
					cursor: pointer;
					z-index: 10;

					&__img-wrapper {
						align-items: center;
						display: flex;
						height: 100%;
						justify-content: center;
						width: 100%;

						img {
							height: 77px;
							width: 77px;
						}
					}
				}

				.player-wrapper {
					@include absolute-center();
					margin: 0;
					transition: opacity 0.3s ease;
				}

				.video-js {
					@include absolute-center();
					height: 100%;
					margin: auto;
					width: 100%;
				}

				.video-js .vjs-tech {
					object-fit: cover;
				}
			}
		}

		img {
			width: 100%;
		}
	}

	.slider-btn-container {
		align-items: center;
		background: $white;
		border-radius: 32px;
		bottom: 22px;
		display: flex;
		justify-content: center;
		left: 0;
		margin: auto;
		padding: 12px 14px;
		position: absolute;
		right: 0;
		width: fit-content;

		button {
			background-color: transparent;
			border: 2px solid $red;
			border-radius: 50%;
			height: 40px;
			padding: 0;
			position: relative;
			width: 40px;

			&[disabled] {
				border-color: $mdgrey;

				img {
					&.active {
						display: none;
					}

					&.inactive {
						display: block;
					}
				}
			}

			img {
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);

				&.active {
					display: block;
				}

				&.inactive {
					display: none;
				}
			}
		}

		[data-carousel-count] {
			@include type-h4-refresh();
			color: $red;
			letter-spacing: -0.5px;
			margin: 0 12px;
		}
	}

	.content {
		padding: 0 35px;

		@include break-min($large1) {
			flex: 50%;
			margin: 0 0 0 119px;
			padding: 0;
		}
		p {
			@include font-size(2.8);
			font-weight: $bold-weight;
			letter-spacing: -1px;
			line-height: lh(36, 28);

			@include break-min($large1) {
				@include type-h2-refresh();
			}
		}
	}
}
