.operator-about {
	background-color: $white;
	padding: 0;

	@include break-min($large1) {
		background-color: $cool-white;
		padding: 24px;
	}

	&-wrapper {
		width: 100%;

		.operator-about-profile {
			flex-flow: column;
			align-items: center;
			@include flex-flow(column);

			@include break-min($large1) {
				align-items: flex-end;
				@include flex-flow(row);
			}

			&-name {
				text-align: center;
				margin-left: 0;
				margin-top: 25px;
				display: flex;
				flex-flow: column-reverse;

				p {
					margin-bottom: 5px;
				}

				@include break-min($large1) {
					margin-left: 20px;
					margin-top: 0;
					text-align: left;

					p {
						margin-bottom: 5px;
					}
				}

				h3 {
					margin-top: 0;
					margin-bottom: 10px;
					text-transform: uppercase;
					color: $blue;
					@include font-size(1.1);
					@include s3();
				}
			}
		}

		.operator-about-description {
			margin: 40px 0;
		}
	}
}
