.tiled-text {
	background: $cool-white;
	margin-top: -75px;
	padding: 100px 0 45px;

	@include break-min($large1) {
		padding: 173px 118px 88px;
	}

	@include break-min($large4) {
		margin: -75px auto 0;
		padding: 173px 144px 88px;
	}

	.content-wrapper {
		@include grid-margin();

		@include break-min($large1) {
			display: flex;
			gap: 24px;
		}

		@include break-min($large4) {
			margin: auto;
			gap: 0;
		}

		@include break-min($max-width-margin-break) {
			max-width: 1252px;
			margin-left: auto;
			margin-right: auto;
		}

		@include break-min($huge4) {
			max-width: 1100px;
		}
	}

	.title {
		@include break-min($medium4) {
			// margin: 0 134px 0 0;
			flex: 50%;
		}

		> *:first-child {
			@include type-h1-refresh();
			color: $blue;
			font-weight: $bold-weight;
			line-height: lh(34, 28);
			margin: 0 0 30px;
		}
	}

	.content {
		@include break-min($large1) {
			flex: 50%;
		}

		p {
			@include type-p1-refresh();
			color: $grey-p;
			margin: 0 0 22px;

			@include break-min($large1) {
				margin: 0 0 40px;
			}
		}

		a {
			min-width: 300px;
		}
	}
}
