.little-things-form {
	.input {
		&.recaptcha {
			display: none;

			&.display {
				display: block;
			}
		}

		.input__wrapper {
			&.hasError {
				@extend .global-form-error;
			}
		}
	}
}
