.curbside-pickup {
	margin: 0 auto;
	margin-bottom: 50px;
	margin-top: 50px;
	padding: 16px;
	text-align: center;

	@include break-min($large1) {
		max-width: 500px;
	}

	.order-headline {
		@include font-size(2.8);
		color: $vis-blue;
		display: block;
		font-family: $primary;
		font-weight: $bold-weight;
		letter-spacing: -1px;
		line-height: 34px;
		margin: 0 auto;
		margin-bottom: 25px;
		padding: 0;

		@include break-min($medium1) {
			@include flex(1 1 auto);
			@include font-size(4.8);
			letter-spacing: -0.01px;
			line-height: 58px;
			margin-bottom: 30px;
			padding: 0 0 10px;
		}
	}

	img {
		margin-bottom: 25px;
		width: 100%;

		@include break-min($large1) {
			width: auto;
		}
	}

	.btn-round {
		width: 100%;

		@include break-min($large1) {
			width: auto;
		}
	}

	.app-stores {
		margin: 0 auto 35px;
	}
}
