.ingredient-steps-list {
	@include grid-margin();
	margin: 18px 24px 56px;

	@include break-min($large1) {
		display: flex;
		justify-content: center;
		margin-bottom: 64px;
		margin-top: -40px;
		padding: 0 25px;
	}
}
