.covid-text-panel {
	margin-top: -122px;
	padding: 122px 0 64px;

	@include break-min($large1) {
		padding-bottom: 100px;
	}

	&.cool-white-bckgrd {
		background: $cool-white;
	}

	&.callout-view {
		@extend .covid-new-ordering-callout;
	}

	.wrapper {
		margin: auto;
		max-width: 800px;
		padding: 0 24px;
		text-align: center;
	}

	p {
		@include type-p1();
		@include font-size(1.8);
		line-height: lh(28, 18);
		margin-bottom: 16px;
		font-weight: normal;

		&:first-child {
			@include break-min($large1) {
				@include font-size(1.4);
				margin-bottom: 11px;
			}
		}
	}

	h1 {
		@include type-h1();
		color: $blue;
		line-height: lh(54, 48);
		margin: 0 0 22px;

		@include break-min($large1) {
			@include font-size(7.8);
		}

		&:after {
			content: "...";
			@include font-size(3.2);
			display: block;
			letter-spacing: 3px;
			margin: 10px 0 0;
		}
	}

	.btn-container {
		margin: 40px auto 0;

		&:empty {
			margin: 0;
		}

		@include break-min($large1) {
			display: flex;
			margin: 40px 0 0;
			max-width: 650px;
		}

		a {
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			border-radius: 16px;
			display: flex;
			padding: 45px 35px;
			position: relative;

			&:hover {
				@include btn-hover-effect();
			}

			@include break-min($large1) {
				padding: 36px 30px;
				width: 50%;
			}

			&:first-child {
				margin-bottom: 24px;

				@include break-min($large1) {
					margin: 0 18px 0 0;
				}
			}

			p {
				@include absolute-center;
				align-self: center;
				bottom: inherit;
				font-weight: bold;
				margin: 0;
				top: inherit;
			}
		}
	}
}

// Adding here for now b/c there was some mismatch in classNames
.generic-text-panel {
	&.franchise-header {
		margin: 58px 24px;
		padding: 0;

		@include break-min($large1) {
			margin: 98px 24px;
		}

		h1 {
			@include type-h1-refresh();
			@include font-size(4.8);
			line-height: lh(54, 48);
			margin: 0;
			padding-bottom: 13px;

			@include break-min($large1) {
				@include font-size(7.8);
				line-height: lh(84, 78);
				letter-spacing: -2px;
			}
		}

		p {
			margin-top: 10px;
		}
	}
}
