.image-cta-carousel {
	@include background-color();
	background: $red;
	overflow: hidden;
	padding: 57px 27px 46px;
	text-align: center;

	@include break-min($large1) {
		padding: 80px 0 69px;
	}

	&.no-cta {
		.cta-card {
			.card {
				display: none;
			}
		}
	}

	&.cool-white {
		.title {
			> *:first-child {
				color: $blue;
			}

			p {
				color: $dkgrey;
			}
		}

		.subtext {
			p {
				color: $blue;
			}
		}

		.slider-btn-container {
			button {
				border: 2px solid $red;
			}
		}
	}

	.title {
		margin: 0 0 52px 0;

		@include break-min($large1) {
			padding: 0;
		}

		> *:first-child {
			@include type-h1-refresh();
			color: $white;
			margin: 0 0 16px 0;
		}

		p {
			@include type-p1-refresh();
			color: $white;
			margin: auto;
			max-width: 423px;

			@include break-min($large1) {
				max-width: 900px;
			}
		}
	}

	.slider-btn-container {
		.slick-disabled {
			border-color: $mdgrey;
			pointer-events: none;
			cursor: not-allowed;

			img {
				&.active {
					display: none;
				}

				&.inactive {
					display: block;
				}
			}
		}

		button {
			background-color: transparent;
			border: 2px solid $white;
			border-radius: 50%;
			height: 40px;
			padding: 0;
			position: relative;
			width: 40px;
			cursor: pointer;

			&:last-of-type {
				margin: 0 16px 0 8px;
			}

			@include break-min($medium2) {
				&:last-of-type {
					margin-right: 48px;
				}
			}

			&[disabled] {
				border-color: $mdgrey;

				img {
					&.active {
						display: none;
					}

					&.inactive {
						display: block;
					}
				}
			}

			img {
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);

				&.active {
					display: block;
				}

				&.inactive {
					display: none;
				}
			}
		}
	}

	.text-slider-wrapper {
		@include break-min($large1) {
			margin: 0 0 0 142px;
		}
	}

	.subtext {
		display: flex;
		justify-content: space-between;
		margin: 0 0 20px 0;

		@include break-min($large1) {
			padding: 0;
		}

		p {
			@include type-h3-refresh();
			color: $white;
			margin: 0;
		}
	}

	.slider {
		display: block;
		justify-content: flex-start;
		flex-wrap: wrap;
		list-style-type: none;
		padding: 0;

		@include break-min($large1) {
			flex-wrap: nowrap;
			margin: 0;
		}

		.slick-list {
			padding: 0 !important; // sass-lint:disable-line no-important

			.slick-track {
				.slick-slide {
					&:not(:last-child) {
						margin-right: 8px;
					}

					@include break-min($medium2) {
						margin-right: 12px;
					}

					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}
	}

	.cta-card {
		border-radius: 24px;
		overflow: hidden;
		height: 385px;
		max-width: 304px;
		position: relative;

		@include break-min($large1) {
			height: auto;
			max-width: none;
		}

		.card {
			background: $cool-white;
			border-radius: 24px;
			padding: 24px;
			//@at-root
			position: absolute;
			width: 85%;
			bottom: 25px;
			left: 0;
			right: 0;
			margin: auto;
			text-align: center;

			.card-title {
				> *:first-child {
					@include type-h3-refresh();
					color: $blue;
					margin: 0 0 8px 0;

					@include break-min($large1) {
						margin: 0 0 16px 0;
					}
				}
			}

			.btn-container {
				@include break-min($large1) {
					display: flex;
					justify-content: center;
				}

				a {
					margin: 0 auto 0;

					&:last-child {
						@include btn-secondary-white-sm;
						margin: 0 auto 0;
						width: 50%;
					}

					&:first-of-type {
						@include btn-round;
						@include btn-primary-red-sm;
						width: 50%;

						@include break-min($large1) {
							margin: 0 6px 0 0;
						}
					}
				}
			}
		}

		.img-container {
			@include swap-img();
			height: 420px;
			position: relative;
			width: 420px;

			img {
				@include absolute-center();
				height: 100%;
				width: 100%;
			}
		}
	}
}
