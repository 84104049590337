.job-opportunities-modal {
	padding: 0;

	.inner {
		padding: 80px 25px;

		@include break-min($large1) {
			padding: 80px 53px;
		}

		.button-grouping {
			justify-content: center;

			a {
				margin: 0 10px;
			}

			button {
				margin: 0 10px;
			}
		}

		button:first-of-type {
			&.mfp-close {
				@include lightbox-close-btn();
				opacity: 1;
			}
		}

		.btn-action {
			@include break-min($large1) {
				margin: 0 10px;
				min-width: 150px;
			}
		}
	}
}
