@mixin background-color() {
	&.red {
		background: $red;
	}

	&.blue {
		background: $blue;
	}

	&.cool-white {
		background: $cool-white;
	}

	&.white {
		background: $white;
	}
}
