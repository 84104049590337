//modifier class
@import "variations/covid-related-stories";
@import "variations/static";
@import "variations/scholarship";
@import "modifiers/team-member";
@import "modifiers/rounded-images";

.g-boxes {
	padding: 55px 0;

	@include break-min($small4) {
		padding: 80px 0 90px;
	}

	&__header {
		text-align: center;
		padding: 0 16px 16px;
		max-width: 900px;
		width: 100%;
		margin: auto;

		@include break-min($large1) {
			padding: 0 24px 24px;
		}
	}

	p {
		@include type-p2();
		color: $vis-grey;
		padding: 15px 0 0;
		margin: 0;
	}

	&__h2 {
		@include type-h2();
		color: $vis-blue;
		display: block;
		margin: 0 auto;
		padding: 0;
		width: 100%;
	}

	&__h3 {
		@include type-h3();
		color: $vis-blue;
		padding: 0;
		margin: 0 auto;
		display: block;
		width: 100%;
	}

	&__footer {
		text-align: center;
		padding: 40px 16px 0;

		@include break-min($small4) {
			padding: 50px 0 0;
		}
	}

	&__inner {
		@include break-min($small4) {
			@include flexbox();
			@include justify-content(stretch);
			@include align-items(stretch);
			@include flex-wrap(wrap);
		}
	}

	&__box {
		background-color: $white;
		position: relative;
		border-top: 1px solid $alto;

		@include break-min($small4) {
			border-right: 1px solid transparent;

			&::after {
				content: "";
				position: absolute;
				background-color: $alto;
				right: -1px;
				top: -1px;
				bottom: -1px;
				width: 1px;
			}
		}

		&::before {
			content: "";
			position: absolute;
			background-color: $alto;
			bottom: -1px;
			left: 0;
			right: 0;
			height: 1px;
		}

		&-link {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 50px;
			left: 0;
			z-index: $z1;
			@include focus($red);
		}

		&-content {
			padding: 24px 16px;

			@include break-min($small4) {
				height: 100%;
				padding: 16px;
				@include flexbox();
				@include justify-content(flex-start);
				@include align-items(stretch);
				@include align-content(stretch);
				@include flex-wrap(nowrap);
				@include flex-direction(column);
			}

			@include break-min($large1) {
				padding: 24px;
			}

			> span {
				@include type-label3();
				@include flex(0 1 auto);
				margin: auto 0 0;
				display: block;
				padding: 30px 0 0;
				color: $vis-grey;

				mark {
					pointer-events: hover;
					color: $red;
					background-color: transparent;
				}

				a {
					color: $red;
					display: inline-block;
				}

				&:hover {
					a,
					mark {
						text-decoration: underline;
					}
				}
			}
		}

		h4,
		&-title {
			@include type-h4();
			@include focus($red);
			font-weight: $bold-weight;
			margin: 0;
			padding: 15px 0 0;
			@include flex(0 1 auto);
		}

		p {
			@include flex(0 1 auto);
			@include focus($red);
			padding: 15px 0 0;
			margin: 0 auto;
		}

		.img-wrapper {
			height: 190px;
			position: relative;

			@include break-min($large1) {
				height: 212px;
			}
		}

		img {
			@include object-fit(cover);
			@include absolute-center();
			height: 100%;
			width: 100%;
		}

		@include break-min($small4) {
			@include flex(0 0 50%);
			max-width: 50%;
		}

		@include break-min($large1) {
			@include flex(0 0 33.3333%);
			max-width: 33.3333%;
		}
	}

	&__vert-sep {
		display: block;
		clear: both;
		content: " ";
		margin-bottom: 20px;

		@include break-min($small4) {
			margin-bottom: 64px;
		}
	}

	&--covid {
		//Class can be found in sass/covid
		@extend .covid-related-stories;
	}

	&--static {
		@extend .static-related-stories;
	}
}
