.covid-ways-to-order {
	.accordion {
		@extend .delivery-accordion;
		margin-top: 64px;

		@include break-min($large1) {
			margin: 0;
			padding: 95px 0 130px;
		}

		h1 {
			margin-bottom: 30px;
		}
	}
}
