.delivery-hero-static-component {
	position: relative;

	.delivery-hero-static-wrapper {
		position: relative;
		width: 100%;

		.hero-background {
			display: flex;
			flex-flow: column-reverse;
			margin: 0 auto;
			width: 100%;

			@include break-min($large1) {
				flex-flow: row;
			}

			.col-content {
				position: relative;
			}

			.gradient-effect {
				background-image: none;
				bottom: 0;
				height: 100%;
				left: 0;
				margin: 0;
				padding: 30px 0;
				position: absolute;
				text-align: center;
				width: 140px;

				@include break-min($large1) {
					background-image: linear-gradient(
						270deg,
						rgba(255, 255, 255, 0),
						$white
					);
					margin-left: -5px;
				}
			}

			.hero-image {
				object-fit: cover;
				height: 185px;
				width: 100%;

				@include break-min($large1) {
					height: auto;
				}
			}

			.delivery-hero-static-content {
				align-items: center;
				display: flex;
				flex-flow: column;
				height: 100%;
				justify-content: center;
				padding: 48px 24px 58px;
				text-align: center;

				@include break-min($large1) {
					align-items: flex-start;
					margin: auto;
					max-width: 635px;
					padding: 0;
					padding-left: 57px;
					text-align: left;
				}

				p {
					@include font-size(1.8);
					font-weight: $regular-weight;
					letter-spacing: -0.5px;
					line-height: 28px;
					margin: 24px 0;
					max-width: 577px;
				}

				h1 {
					@include type-h1();
					@include font-size(3.6);
					color: $blue;
					font-family: $primary;
					letter-spacing: -0.8px;
					line-height: 46px;
					margin: 0;

					@include break-min($large1) {
						@include font-size(4.8);
						line-height: 58px;
					}
				}

				a {
					align-items: center;
					border-radius: 12px;
					display: flex;
					flex-flow: row;
					justify-content: center;
					padding: 20px 35px;
					text-align: left;

					img {
						flex: 0;
						margin-right: 30px;

						@include break-min($large1) {
							flex: 1;
						}
					}
				}
			}
		}
	}
}
