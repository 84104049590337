.delivery-hours {
	.wrapper {
		background: $cool-white;
		padding: 40px 30px 0;

		@include break-min($large1) {
			max-width: none;
			padding: 40px 65px 0;
			position: relative;
		}
	}

	h3 {
		@include type-h2();
		color: $blue;
		margin: 0 0 8px;

		@include break-min($large1) {
			@include font-size(2.4);
		}
	}

	.flex-desktop {
		@include break-min($large1) {
			column-count: 3;
			display: grid;
			grid-column-gap: 50px;
			grid-template-columns: 40% 25% auto;
		}

		.btn-round:nth-of-type(2) {
			display: none;
		}
	}

	.subtitle {
		@include type-p1();
		@include font-size(1.8);
		font-weight: 500;
		line-height: 1.6;

		@include break-min($large1) {
			@include type-p2();
			grid-column: 1;
			grid-row: 1;
			margin-bottom: 0;
			margin-right: 50px;
		}
	}

	.time {
		@include break-min($large1) {
			grid-column: 2;
			grid-row: 1;
			justify-content: left;
		}

		div {
			display: flex;
			justify-content: center;

			@include break-min($large1) {
				justify-content: flex-start;
			}
		}

		.icon-wrapper {
			height: 22px;
			margin: 3px 10px 0 0;
			position: relative;
			width: 22px;

			@include break-min($large1) {
				margin: 6px 8px 0 0;
			}

			img {
				@include absolute-center();
			}
		}

		p {
			@include type-p1();
			@include font-size(1.8);
			color: $green;
			font-weight: bold;
			margin-bottom: 3px;
		}

		.deliv-min {
			@include type-p1();
			color: $grey;
			font-weight: normal;
			margin-bottom: 12px;

			@include break-min($large1) {
				margin: -5px 0 0 29px;
			}
		}
	}

	.btn-round {
		@include break-min($large1) {
			align-self: start;
			grid-column: 3;
			grid-row: 1;
			justify-self: flex-start;
			margin-top: -10px;
			width: auto;

			@include break-min($large1) {
				@include ie-only() {
					margin-left: 50px;
				}
			}
		}
	}

	hr {
		margin: 32px 0 0;
	}
}
