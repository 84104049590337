.location-additional-content {
	> ul {
		padding: 0;
		margin: 0;

		> li {
			&:nth-child(odd) {
				.img-txt-carousal {
					background-color: $cream;

					.carousal-item {
						flex-direction: row;

						.img-container {
							flex: 1 0 50%;
						}
					}
				}
			}

			.img-txt-carousal {
				padding: 50px 25px;

				@include break-min($large1) {
					padding-bottom: 100px;
				}

				.slider {
					@include break-min($large1) {
						padding: 0 30px;
					}
				}

				.carousal-item {
					flex-direction: row;

					@include break-min($large1) {
						flex-direction: row-reverse;
					}

					.img-container {
						flex: 1 0 50%;

						img {
							width: 100%;
						}

						@include break-min($large1) {
							img {
								width: auto;
							}
						}
					}

					.content {
						margin: auto 100px auto 0;
					}
				}
			}
		}
	}
}
