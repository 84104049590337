.menu-order-ctas {
	background-color: $cool-white;
	padding: 29px 0 32px;
	text-align: center;

	@include break-min($large1) {
		display: none;
	}

	h2 {
		@include font-size(2.4);
		font-weight: $bold-weight;
		letter-spacing: -1px;
		line-height: lh(32, 24);
		margin: 0 0 30px 0;
	}

	ul,
	li,
	a {
		display: flex;
	}

	ul {
		padding-left: 0;
		margin: 0;
		justify-content: center;

		li {
			flex-basis: 27%;
			max-width: 106px;
			justify-content: center;
			border-radius: 16px;
			margin-right: 8px;
			background-color: $white;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			list-style-type: none;

			@include break-min($large1) {
				max-width: 120px;
			}

			a:not(.btn-round) {
				@include btn-hover-effect();
				flex-direction: column;
				align-items: center;
				display: flex;
				justify-content: center;
				padding: 16px 35px;
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: -0.5px;
				color: $red;

				@include break-min($large1) {
					padding: 16px 40px;
				}

				img {
					margin-bottom: 12px;
					max-width: 37px;
				}

				p {
					margin: 0;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&.catering {
		h2 {
			@include font-size(1.8);
			margin: 0 0 16px 0;
		}

		li {
			background-color: inherit;
			box-shadow: none;
			flex: 1;
			max-width: none;
		}

		.btn-round {
			justify-content: center;
			max-width: 276px;
			text-align: center;
		}
	}
}
