.global-form-input-capsule {
	display: flex;
	margin: 0 auto;
	position: relative;
	border: 2px solid transparent;
	justify-content: space-between;

	input {
		border-radius: 24px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border: none;
		padding: 0 32px;
		width: 100%;
	}

	button[type="submit"] {
		margin: 0;
		font-family: $primary;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.5625px;
		padding: 12px 32px;
		width: auto;

		@include break-min($large1) {
			padding: 15px 48px;
		}

		&.half {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			flex-direction: column;
			display: flex;
			align-items: center;

			&:hover {
				transform: translateY(0px);
			}
		}
	}
}
