.placeholderClass {
	.frame {
		.wrapper:not(.footer-modules) {
			@include break-min($large1) {
				max-width: none;
				padding: 0;
			}

			.col-lg-6:first-of-type {
				@include break-min($large1) {
					flex-basis: 40%;
					max-width: 40%;
				}
			}

			.col-lg-6:nth-of-type(2) {
				@include break-min($large1) {
					flex-basis: 60%;
					max-width: 60%;
				}
			}
		}

		.justify-between {
			justify-content: space-between;
			align-items: center;
		}

		.baseline {
			align-items: baseline;
		}

		.social-icons {
			text-align: left;

			@include break-max($large1) {
				padding: 0;
			}
		}
	}
}
