.content-hub-featured-article {
	margin: 0 32px 32px !important;
	grid-row-gap: 22px;

	@include break-min($medium4) {
		height: 279px;
		margin: 0 46px 28px !important;
	}

	@include break-min($large3) {
		margin: 0 72px 28px !important;
		height: 418px;
	}

	.card-classic {
		@include break-min($medium3) {
			grid-column: auto/span 12;
		}
	}

	&:last-child {
		.card-classic {
			margin: 0;
		}
	}

	&[data-child-count="1"] {
		@include break-min($medium4) {
			height: 370px;
		}

		@include break-min($large3) {
			height: 632px;
		}

		.article {
			margin: 0;

			&-content {
				width: 100%;
				border-top-right-radius: 0;

				@include break-min($medium4) {
					width: 33.33333%;
					border-top-right-radius: 36px;
				}
			}

			.bkg-image {
				min-height: 172px;
				object-fit: cover;

				@include break-min($medium4) {
					height: 100%;
				}
			}
		}
	}

	&[data-child-count="3"] {
		.bkg-image {
			min-height: 172px;
			object-fit: cover;

			@include break-min($medium4) {
				height: 100%;
			}
		}
	}

	.article {
		position: relative;
		display: grid;
		flex-flow: column;

		@include break-min($medium4) {
			display: block;
		}

		.video-tile {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			width: 53px;
			height: auto;
			margin: auto;
			background: transparent;
			border: none;

			button {
				background: transparent;
				border: none;
				height: 100%;
				padding: 0;
				width: 100%;

				.icon-container {
					margin: auto;

					.play-icon {
						width: 53px;
						height: 53px;
					}
				}
			}
		}

		.article-content {
			display: flex;
			justify-content: space-between;
			background-color: $red;
			bottom: 0;
			padding: 22px;
			align-items: center;
			@include background-color();

			@include break-min($medium4) {
				position: absolute;
			}

			@include break-min($large3) {
				padding: 36px;
			}

			// background colors
			&[data-text-background-color] {
				background: $red;
			}

			&[data-text-background-color="cool-white"] {
				background: $cool-white;
			}

			&[data-text-background-color="blue"] {
				background: $blue;
			}

			&[data-text-background-color="white"] {
				background: $white;
			}

			&[data-text-background-color="red"] {
				background: $red;
			}

			&[data-text-background-color="warm-white"] {
				background: $warm-white;
			}

			// text colors
			&[data-text-color="text-white"] {
				svg {
					@include border-color-options($white);
				}

				p {
					color: $white;
				}
			}

			&[data-text-color="text-blue"] {
				svg {
					@include border-color-options($blue);
				}

				p {
					color: $blue;
				}
			}

			&[data-text-color="text-red"] {
				svg {
					@include border-color-options($red);
				}

				p {
					color: $red;
				}
			}

			&[data-text-color="text-peach"] {
				svg {
					@include border-color-options($peach);
				}

				p {
					color: $peach;
				}
			}

			&[data-text-color="text-cool-white"] {
				svg {
					@include border-color-options($cool-white);
				}

				p {
					color: $cool-white;
				}
			}

			&[data-text-color="text-warm-white"] {
				svg {
					@include border-color-options($warm-white);
				}

				p {
					color: $warm-white;
				}
			}

			svg {
				max-width: 25px;
			}

			p {
				@include font-size(1.8);
				line-height: lh(13, 10);
				font-weight: 700;
				letter-spacing: -0.5px;
				color: $white;
				@include text-color-options();
				margin: 0;
				margin-right: 22px;

				@include break-min($medium4) {
					@include font-size(2);
					line-height: lh(30, 22);
					margin-right: 36px;
				}

				@include break-min($large3) {
					@include font-size(2.4);
					line-height: lh(34, 22);
				}
			}
		}
	}

	// if video, the colors are fixed
	&.video-container {
		.article {
			.article-content {
				&[data-text-background-color] {
					@include break-max($medium4) {
						background: $cool-white;
					}
				}

				&[data-text-color] {
					@include break-max($medium4) {
						svg {
							@include border-color-options($blue);
						}

						p {
							color: $blue;
						}
					}
				}
			}
		}
	}

	&.proportion33-66 {
		> :first-child {
			.bkg-image {
				min-height: 171px;
				object-fit: cover;
			}

			.article {
				&-content {
					width: 100%;
					border-radius: 0;
				}
			}
		}

		> :last-child {
			.bkg-image {
				min-height: 171px;
				object-fit: cover;

				@include break-min($medium4) {
					height: 100%;
				}
			}

			.article {
				&-content {
					width: 100%;
					border-top-right-radius: 0;

					@include break-min($medium4) {
						width: 50%;
						border-top-right-radius: 36px;
					}
				}
			}
		}
	}

	&.proportion66-33 {
		> :first-child {
			.bkg-image {
				min-height: 171px;
				object-fit: cover;

				@include break-min($medium4) {
					height: 100%;
				}
			}

			.article {
				&-content {
					width: 100%;
					border-top-right-radius: 0;

					@include break-min($medium4) {
						width: 50%;
						border-top-right-radius: 36px;
					}
				}
			}
		}

		> :last-child {
			.bkg-image {
				min-height: 171px;
				object-fit: cover;
			}

			.article {
				&-content {
					width: 100%;
					border-radius: 0;
				}
			}
		}
	}

	&.proportion50-50 {
		.bkg-image {
			min-height: 171px;
			object-fit: cover;

			@include break-min($medium4) {
				min-height: 418px;
				height: 100%;
			}
		}

		.article {
			&-content {
				width: 100%;
				border-top-right-radius: 0;

				@include break-min($medium4) {
					width: 70%;
					border-top-right-radius: 36px;
				}
			}
		}
	}
}
