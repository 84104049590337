.team-member-careers {
	.short-bottom-icon-hero {
		margin-bottom: 0;
	}

	.header-columns-text {
		.testimonials {
			dt {
				@include break-min($large1) {
					@include type-h2-refresh();
				}
			}
		}
	}

	.image-text-carousel {
		padding-top: 58px;

		@include break-min($large1) {
			padding-top: 81px;
		}

		.title {
			> *:first-child {
				color: $blue;
			}

			p {
				color: $grey;
			}
		}
	}

	.accordion {
		@extend .delivery-accordion;
		background-color: $cool-white;
		margin-bottom: 0;
		padding: 64px 24px 224px;
		position: relative;

		@include break-min($large1) {
			padding: 112px 0 224px;
		}

		.cta-btn {
			bottom: 100px;
			left: 0;
			margin: 0 24px;
			position: absolute;
			right: 0;
			top: unset;

			@include break-min($large1) {
				bottom: -138px;
			}

			a {
				@extend .btn-round--white;
			}
		}
	}
}
