.rewards-section-no-img {
	text-align: center;

	.img-wrapper {
		display: none;
	}

	.content {
		@include break-min($large1) {
			margin: 0;
			max-width: 750px;
		}

		.headline p {
			@include break-min($large1) {
				margin-bottom: 0;
			}
		}

		h4 {
			@include break-min($large1) {
				margin-bottom: 16px;
			}
		}

		p:not(.headline p) {
			@include break-min($large1) {
				margin-bottom: 40px;
			}
		}
	}
}
