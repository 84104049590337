.cp-filter {
	border: 1px solid $iron;
	margin: 30px 0 5px 0;
	padding: 15px;
	border-radius: 8px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	&--active {
		.cp-filter {
			&__toggle {
				&::after {
					background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
					transform: rotate(180deg);
					width: 24px;
					height: 24px;
				}
			}

			&__bar {
				p {
					display: block;
				}
			}

			&__form {
				display: block;
			}
		}
	}

	&__toggle {
		height: 56px;
		cursor: pointer;
		position: absolute;
		top: -10px;
		right: -15px;
		left: -15px;

		&::after {
			background-image: url("img/cp-icons/icon-cp_filters.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			content: "";
			position: absolute;
			right: 16px;
			top: 12px;
			bottom: 0;
			width: 24px;
			height: 24px;
		}

		&:focus {
			outline-offset: 3px;
			outline: 1px dotted $red;
		}
	}

	&__bar {
		position: relative;

		h4 {
			@include font-size(2.2);
			color: $blue;
			font-weight: 600;
			letter-spacing: -1px;
			line-height: lh(27, 22);
			margin: 0;
			padding: 0;
			display: block;
		}

		p {
			@include font-size(1.6);
			color: $grey;
			display: none;
			line-height: lh(25, 16);
			margin: 0;
			padding: 19px 0 0;
			z-index: $z2;
		}
	}

	&__form {
		display: none;
		position: relative;
	}

	&__flex-wrap {
		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(flex-start);
		@include flex-wrap(wrap);

		.cp-checkbox {
			padding: 18px 26px 0 0;

			@include flexbox();
			@include align-items(flex-start);

			label {
				@include font-size(1.4);
				color: $blue;
				font-weight: $regular-weight;
				letter-spacing: -0.25px;
				line-height: 23px;

				@include break-min($large1) {
					@include font-size(1.6);
					font-weight: $bold-weight;
					letter-spacing: -0.27px;
					line-height: 20px;
				}
			}
		}
	}

	.cp-form__select {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.cp-form__sort-title {
		p {
			@include font-size(1.8);
			margin: 25px 0 0 0;
		}
	}

	.cp-form__radio {
		label {
			@include font-size(1.4);
			color: $blue;
			font-weight: $regular-weight;
			letter-spacing: -0.25px;
			line-height: 23px;
			margin: 0 0 18px 0;
			padding: 0 0 0 30px;

			@include break-min($large1) {
				@include font-size(1.6);
				font-weight: $bold-weight;
				letter-spacing: -0.27px;
				line-height: 20px;
			}
		}
	}

	.cp-content__radio {
		color: $blue;
		margin: 0 24px 0 0;
	}

	@include break-min($small4) {
		padding: 22px;

		&__form {
			padding: 0;
			margin: 0;

			.cp-checkbox {
				label {
					@include font-size(1.6);
					color: $blue;
					font-weight: $bold-weight;
					letter-spacing: -0.25px;
					line-height: lh(19, 16);
				}
			}
		}

		.cp-form__select {
			right: auto;
			bottom: auto;
			left: auto;
			min-width: 200px;
			max-width: 300px;
			position: relative;
			margin: 8px 0 0;
			@include flex(0 0 auto);
		}

		.cp-form__radio {
			label {
				@include font-size(1.6);
				color: $blue;
				font-weight: $bold-weight;
				letter-spacing: -0.27px;
				line-height: lh(20, 16);
				padding: 0 0 0 30px;
			}
		}

		.cp-form__radio-wrapper {
			display: flex;
		}

		&__bar {
			padding: 0 200px 0 0;

			h4 {
				@include font-size(2.4);
				color: $blue;
				display: inline-block;
				font-weight: $bold-weight;
				letter-spacing: -1px;
				line-height: lh(29, 24);
				padding: 0 15px 0 0;
			}

			p {
				@include font-size(1.8);
				color: $grey;
				padding: 0;
				letter-spacing: -0.3px;
				line-height: lh(22, 18);
				margin: 16px 0 0 0;
			}
		}

		&__flex-wrap {
			@include flex(1 1 auto);
			// .cp-checkbox {
			//     padding: 12px 15px 0 0;
			// }
		}
	}
}
