.prize-card {
	background-color: white;
	height: auto;
	max-width: none;
	border-radius: 24px;
	overflow: hidden;

	&__content {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		width: 306px;
		height: 299px;

		img {
			width: 68px;
		}

		span {
			@include font-size(2.4);
			@include title-text-color($blue);
		}
	}
}
