.code-moo-hero-callout {
	background: $red;
	padding: 45px 33px;
	border-radius: 16px;
	margin: 0 32px 60px;

	@include break-min($large1) {
		margin: 0;
		border-radius: 0;
		padding: 0 33px;
	}

	@include break-min($huge4) {
		margin-top: 60px;
	}

	.img-wrapper {
		margin-bottom: 40px;

		@include break-min($large1) {
			margin-bottom: 0;
		}
	}

	.content {
		color: $white;
		max-width: 645px;
		text-align: left;

		h4 {
			@include font-size(3.6);
			margin-bottom: 30px;
		}

		.headline {
			display: flex;

			.headline-icon {
				margin-right: 10px;
				width: 13px;

				@include break-min($large1) {
					width: 18px;
				}
			}

			p {
				@include font-size(1.4);

				@include break-min($large1) {
					@include font-size(1.8);
				}
			}
		}
	}
}
