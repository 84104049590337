@mixin component-align($direction, $flex-direction) {
	> * {
		display: flex;
		flex-direction: column;
		justify-content: $direction;
		text-align: $direction;

		> * {
			align-self: $flex-direction;
			justify-content: $flex-direction;
		}
	}

	.classic-callout {
		> * {
			display: flex;
			flex-direction: column;
			justify-content: $direction;
			text-align: $direction;

			> * {
				align-self: $flex-direction;
				justify-content: $flex-direction;
			}
		}
	}
}

.align {
	&__center {
		@include component-align(center, center);
	}

	&__left {
		@include component-align(left, flex-start);
	}

	&__right {
		@include component-align(right, flex-end);
	}
}
