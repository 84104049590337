.location-minimum-order-label {
	margin: 24px auto 32px;

	@include break-min($large1) {
		margin: 16px auto 23px;
	}

	p {
		@include font-size(1.1);
		color: $rolling-stone;
		line-height: 13px;
		margin: 0;
		text-align: center;
	}
}
