$height: 15px;

.progress-bar-anim {
	margin-top: 15px;

	p:first-child {
		margin-bottom: 15px;
	}

	.bar {
		background-image: url("/img/nge-progress-bar-bckgrd.svg");
		height: $height;
		margin: 0;
		position: relative;
		width: 87%;

		@media screen and (min-width: $mobile) {
			margin: auto;
			width: 300px;
		}
	}

	.progress {
		background: $cadet-blue;
		height: $height;
		left: 0;
		position: absolute;
		transition: 1s all ease-in-out;
		width: 0;

		&.red-bar {
			background: $red;
		}

		&.silver-bar {
			background: #8f969b;
		}

		.accumulated {
			@include type-p2();
			color: $white;
			font-weight: bold;
			position: absolute;
			right: 4px;
			top: -1px;

			@media screen and (min-width: $mobile) {
				top: -3px;
			}
		}
	}

	.total {
		bottom: -20px;
		position: absolute;
		right: -45px;
	}
}
