// sass-lint:disable variable-for-property

// Colors Shared Across .COM and Canada. Add new colors here

$blue: #004f71; //diff val in Canada
$vis-blue: $blue;
$blue-hover: #0070a0;
$cadet-blue: #5fafc4;
$light-blue: #ceebf3;
$bsr-blue: #e8f0f2;
$teal: #3eb1c8;
$light-teal: #5ecade;
$little-blue-menu: #37aaff;

$membership-blue: #004e73;
$membership-red: #f8c1b8;
$membership-red-border: #e12243;
$membership-signature: #ceebf3;
$membership-silver: #8f969b;
$membership-gray: #8e959c;
$membership-teal: #39b0c7;

$red: #dd0031;
$dkred: #af272f; //diff val in Canada
$vis-dkred: $dkred;
$light-red: #f2676c;
$lred: #ffe8ed;
$validation-red: #fef7f9;
$remy-red: #f7e1dc;

$pink: #fbe0db;
$grey: #485259; //diff val in Canada
$vis-grey: #485259;
$medium-light-grey: #f7f7f7;
$light-grey: #8e969c;
$faint-grey: #ccc;
$search-grey: #686059;
$dim-gray: #69605a;
$rolling-stone: #6d787e;
$dark-grey: #393d41;
$shuttle-grey: #5b6770;
$spice: #f5e3cc;
$white: #fff;
$white-smoke: #f5f5f5;
$warm-white: #fcf9f4;
$cool-white: #f4f8f9;
$gray-orange: #f0ece5;
$cyan: #f5f9fa;
$alto: #d8d8d8;
$grey-p: #67737c;
$orange-yellow: #ca7e00;
$dark-orange: #a66700;
$yellow-orange: #ffb549;

$gold: #bea777;
$dark-gold: #bb965b;
$bronze: #916b19;

$vis-green: #00635b;
$green: $vis-green; //diff val in Canada
$lime-green: #9dae88;

$purple: #9778d3;

//CP Profile
$iron: #dbe0e2;
$concrete: #f2f2f2;
$gallery: #ebebeb;
$cupid: #fcc5c6;
$keppel: #37b49d;
$aqua-island: #99d9ce;
$salmon: #fa9370;
$cannon-pink: #994878;
$vanilla-ice: #ecc1dc;
$apricot-peach: #f8c1b8;
$peach: #f1693b;
$colonial-white: #ffe9b8;

//Evergreen
$ev-dark-gold: #b69e77;
$ev-night-cloud: #283d4f;

// Old Colors
$dkgrey: #485259;
$mdgrey: #ccc;
$bkgrey: #f4f8f9;
$oldblue: #7ba5de;
$yellow: #fcf2cb;
$cream: #fcf9f4;
$pampas: #f2efe9;
$orange: #ea7200;
$black: #000;
$black-bronze: #111;

$cta-gradient: linear-gradient(
	180deg,
	rgba(254, 67, 108, 0.987036) 0%,
	rgba(221, 0, 49, 0.99343) 49.32%,
	#c20726 100%
);
