.franchise-apply-now {
	@extend .base-modal;
	border-radius: 8px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
	margin: 0 auto;
	max-width: 650px;
	padding: 58px 24px 24px;
	text-align: center;

	@include break-min($large1) {
		padding: 86px 48px 76px;
	}

	&.gridView {
		.btn-container {
			@include break-min($large1) {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 auto -24px;
				max-width: 500px;
			}

			a {
				@include break-min($large1) {
					margin: 0 24px 24px 0;
					width: 200px;
				}
			}
		}
	}

	h1 {
		@include font-size(2.8);
		color: $blue;
		font-weight: $bold-weight;
		letter-spacing: -0.01em;
		line-height: lh(34, 28);
		margin-bottom: 16px;

		@include break-min($large1) {
			@include font-size(4.8);
			letter-spacing: -1px;
			line-height: lh(58, 48);
			margin-bottom: 24px;
		}
	}

	p {
		@include type-p1();
		color: $blue;
		font-weight: $regular-weight;
		letter-spacing: -0.5px;
		line-height: lh(28, 18);
		margin-bottom: 24px;

		@include break-min($large1) {
			margin-bottom: 36px;
		}
	}

	.btn-container {
		a {
			width: 100%;

			&:not(:last-child) {
				@include break-max($large1) {
					margin-bottom: 12px;
				}
			}

			@include break-min($large1) {
				margin-right: 24px;
				width: 144px;
			}
		}
	}
}
