.covid-questions-filter {
	@extend .delivery-accordion;
	background: $cool-white;
	margin-bottom: 0;
	padding: 78px 24px;

	@include break-min($large1) {
		padding: 104px 142px 109px;
	}

	@include break-min($large1) {
		text-align: center;
	}

	.wrapper {
		padding: 0;
	}

	img {
		display: flex;
		margin: auto;

		@include break-min($large1) {
			display: none;
		}
	}

	h2 {
		color: $blue;
		@include font-size(3.6);
		font-weight: $bold-weight;
		letter-spacing: -0.5px;
		line-height: lh(42, 36);
		text-align: center;

		@include break-min($large1) {
			margin: 0 0 32px;
			text-align: left;
		}
	}

	.filters {
		text-align: left;

		@include break-min($small4) {
			display: flex;
			margin-bottom: 40px;
		}

		p {
			@include type-p2(desktopOnly);
			margin: 0 0 8px 0;

			@include break-min($small4) {
				margin: 0 24px 0 0;
			}
		}

		ul {
			display: block;
			margin-top: 0;
			padding: 0;

			@include break-min($small2) {
				display: grid;
				grid-column-gap: 6px;
				grid-row-gap: 10px;
				grid-template-columns: 1fr 1fr 1fr;
			}

			@include break-min($small4) {
				display: flex;
				flex-wrap: wrap;
				margin: 0;
			}
		}

		li {
			list-style-type: none;
			margin-bottom: 15px;

			@include break-min($small4) {
				margin: 0;
				margin-right: 24px;
			}

			@include break-min($large4) {
				margin-right: 44px;
			}
		}

		input[type="checkbox"] {
			appearance: none;
			background-color: $white;
			border: 1px solid #ddd;
			border-radius: 3px;
			height: 18px;
			margin: 0;
			position: relative;
			vertical-align: middle;
			width: 18px;

			@include break-min($large1) {
				margin: -2px 3px 0 0;
			}

			&:checked {
				background-color: $red;
				border-color: $red;
				color: $white;

				&:after {
					@include absolute-center();
					@include checkmark();
					content: "";
				}
			}
		}

		label {
			@include break-min($large1) {
				margin-top: 2px;
			}
		}
	}

	.accordion-body {
		border-bottom: 1px solid $faint-grey;
		margin-top: 0;
		text-align: left;

		.toggle {
			border-top: 1px solid $faint-grey;
			letter-spacing: -0.01em;
			padding: 21px 36px 17px 16px;

			@include break-min($large1) {
				padding: 32px 30px 32px 0;
			}

			&:after {
				font-size: 1.4rem;

				@include break-min($large1) {
					@include font-size(1.8);
				}
			}
		}
	}

	.btn-round {
		width: 100%;
		@include break-min($large1) {
			margin-top: 65px;
			width: auto;
		}
	}
}
