.press-room {
	&.btn-container {
		margin: 56px auto;

		@include break-min($large1) {
			margin: 98px auto;
		}

		a {
			min-width: 135px;
		}
	}
}
