.pdp-pwo-cta {
	text-align: center;

	&.wideView {
		width: 100%;
	}

	@include break-min($large1) {
		margin: 45px 0 18px;
		text-align: left;
		width: fit-content;
	}

	.btn-container {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
		padding: 0 16px;

		@include break-min($large1) {
			justify-content: flex-start;
			padding: 0;
		}

		&[data-child-count="1"] {
			padding: 0 10px;

			@include break-min($large1) {
				padding: 0;
			}

			a {
				align-items: center;
				flex-direction: row;
				max-width: none;
				width: 100%;

				@include break-min($large1) {
					max-width: 300px;
				}

				&:first-child {
					margin: 0;
				}

				img {
					flex: 0;
					margin: 0 20px 0 0;
					width: 59px;
				}
			}
		}

		a {
			@include btn-hover-effect();
			@include font-size(1.4);
			background: $white;
			border-radius: 16px;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			display: flex;
			flex-direction: column;
			font-weight: $bold-weight;
			height: 85px;
			justify-content: center;
			letter-spacing: -0.5px;
			line-height: lh(17, 14);
			max-width: 200px;
			padding: 15px 0;
			text-align: center;
			width: 100%;

			@include break-min($large1) {
				width: 150px;
			}

			&:first-child {
				margin-right: 8px;
			}

			img {
				height: 35px;
				margin: auto;
				width: 35px;
			}
		}
	}
}
