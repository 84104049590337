.header-img-list-callout {
	.title {
		text-align: center;

		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);
			margin-bottom: 48px;

			@include break-min($large2) {
				@include font-size(4.8);
				letter-spacing: -1px;
				line-height: lh(58, 48);
				margin-bottom: 0;
			}
		}
	}

	.img-callout-wrapper {
		list-style-type: none;
		padding: 0;

		li {
			position: relative;

			@include break-min($large1) {
				margin: 0 105px;
			}

			@include break-min($large2) {
				margin: 0 48px;
			}

			@include break-min($huge2) {
				margin: 0 113px;
			}

			&:before {
				@include break-min($large2) {
					@include dot-border-h(bottom, left);
				}
			}

			&:last-child {
				&:before {
					@include break-min($large2) {
						background-image: none;
					}
				}
			}
		}
	}

	.img-callout {
		margin-bottom: 68px;
		position: relative;

		&:nth-child(3) {
			background: orange;
		}

		@include break-min($large2) {
			display: flex;
			justify-content: center;
			margin: auto;
			padding: 88px 0 88px;
		}

		@include break-min($huge2) {
			max-width: 1600px;
		}

		img {
			margin-bottom: 48px;

			@include break-min($large2) {
				margin: 0 50px 0 0;
			}
		}

		.mobile-img {
			width: 100%;

			@include break-min($large2) {
				display: none;
			}
		}

		.desktop-img {
			display: none;

			@include break-min($large2) {
				align-self: center;
				display: block;
				width: 52%;
			}

			@include break-min($huge2) {
				width: 47%;
			}
		}

		.content {
			padding: 0 24px;

			@include break-min($large1) {
				padding: 0;
			}

			@include break-min($large2) {
				margin: auto;
				width: 48%;
			}

			@include break-min($huge2) {
				width: 53%;
			}

			p {
				&:first-of-type {
					@include font-size(2.4);
					color: $grey;
					font-weight: $bold-weight;
					letter-spacing: -1%;
					line-height: lh(32, 24);
					margin-bottom: 24px;
				}

				&:nth-of-type(2) {
					@include font-size(1.8);
					letter-spacing: -0.5px;
					line-height: lh(28, 18);
				}
			}

			ul {
				padding: 0;
				list-style-type: none;

				li {
					margin-bottom: 16px;

					@include break-min($large1) {
						margin: 0 0 16px 0;
					}

					&:before {
						background-image: none;
					}

					span {
						@include font-size(1.6);
						display: block;
						font-weight: $bold-weight;
						letter-spacing: -1%;
						line-height: lh(20, 16px);
					}
				}
			}
		}
	}
}
