.membership-points-status {
	padding: 0;

	.membership-points-toggle {
		border-bottom: 1px solid #e2e2e2;
		padding: 15px 0;
		@include flexbox();
		@include flex-wrap(nowrap);
		@include justify-content(space-between);

		a {
			color: $rolling-stone;
			display: inline;
			@include font-size(1.6);
			font-weight: 500;
			letter-spacing: -0.5px;
		}

		.g-tabs__toggle {
			&--active {
				color: $red;
				@include font-size(1.8);
			}
		}

		.status-years {
			a {
				@include font-size(1.8);
				font-weight: $medium-weight;
				margin-right: 30px;
			}
		}

		.status-information {
			display: none;

			@include break-min($large1) {
				display: block;
			}

			a {
				color: $red;
			}
		}
	}

	h5 {
		@include s3();
		color: $vis-blue;
		display: block;
		padding: 0;
		text-transform: uppercase;
	}

	.membership-points-status-container {
		@include flexbox();
		@include flex-flow(column);
		@include justify-content(space-between);

		@include break-min($large1) {
			@include flex-flow(row);
		}

		.membership-points-status__block {
			width: 100%;

			@include break-min($large1) {
				width: 50%;
				margin-right: 20px;
				max-width: 371px;

				&:last-child {
					margin-left: 40px;
					margin-right: 0;
					max-width: 100%;
				}
			}

			.membership-points-status-wrap {
				background-color: $cool-white;
				border-radius: 16px;
				padding: 70px 20px 40px;
				text-align: center;
				margin: 60px 0 10px;
				position: relative;

				@include break-min($large1) {
					padding: 70px 50px 40px;
					margin: 60px 0;
				}

				p {
					color: $blue;
					@include font-size(1.4);
					margin: 0;
				}

				span {
					color: $blue;
					@include font-size(1.2);
				}

				.member-category {
					@include font-size(1.8);
					margin: 5px 0;
				}

				.status-label {
					margin-top: 10px;
					margin-bottom: 10px;
					@include font-size(1.1);
				}

				.member-label {
					@include font-size(1.1);
					margin-bottom: 5px;
					margin-top: 30px;
				}

				.membership-badge {
					border-radius: 50%;
					border: 7px solid $white;
					width: 88px;
					height: 88px;
					margin: 0px auto;
					padding: 15px;
					position: absolute;
					top: -33px;
					left: 0;
					right: 0;
					justify-content: center;
					@include flexbox();

					img {
						justify-content: center;
						align-items: center;
					}
				}

				.progress-bar {
					margin: 10px 0;

					.bar {
						background-color: $iron;
						border-radius: 30px;
						height: 32px;

						p {
							font-weight: 700;
						}

						.progress {
							border-radius: 30px;
							height: 100%;
							color: $white;
							width: 100px;
							@include flexbox();
							@include justify-content(center);
							align-items: center;
						}

						.total {
							text-align: right;
							margin-top: 5px;
						}
					}
				}
			}

			.membership-points-status__details {
				margin: 30px 0;

				@include break-min($large1) {
					margin: 60px 0;
				}

				h5 {
					margin: 0;
					@include font-size(1.1);
				}

				.points {
					align-items: center;
					border-bottom: 1px solid #e2e2e2;
					padding-bottom: 30px;
					@include flexbox();
					@include justify-content(space-between);

					.points-count {
						h2 {
							color: $grey;
							margin: 10px 0;
							font-weight: 700;
							@include font-size(3.6);
						}

						span {
							@include font-size(1.3);
							color: $grey;
							font-weight: 700;
						}
					}
				}

				.membership {
					padding-top: 0;
					@include flexbox();
					@include flex-flow(column);
					@include justify-content(space-between);

					@include break-min($large1) {
						padding-top: 30px;
						@include flex-flow(row);
					}

					span {
						@include font-size(1.1);
						color: $grey;
						margin-top: 10px;
						font-weight: $medium-weight;
					}

					.membership-item {
						border-bottom: 1px solid #e2e2e2;
						padding: 15px 0;

						@include break-min($large1) {
							padding: 0;
							border: none;
						}
					}
				}

				.status-information {
					display: block;
					text-align: center;
					padding: 20px 0;

					@include break-min($large1) {
						display: none;
					}

					a {
						color: $red;
					}
				}
			}
		}
	}
}
