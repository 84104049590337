.service-level-offerings {
	margin-bottom: 4px;

	&:focus {
		font-weight: normal;
	}

	h3 {
		@include s2(mobileOnly);
		color: $blue;
		margin-bottom: 9px;
		text-transform: uppercase;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		padding: 0;
		justify-content: left;
		margin: -10px 0 10px;

		@include break-min($large1) {
			justify-content: flex-start;
		}

		li {
			@include font-size(1.4);
			margin: 10px 16px 0 0;

			&:before {
				content: url("img/checkmark-green.svg");
				margin-right: 5px;
			}
		}

		.info-icon {
			margin: 5px -6px 0;

			&:before {
				content: "";
				margin: 0;
			}
		}
	}

	p {
		@include type-p1(mobileOnly);
		color: $light-grey;
		font-weight: normal;
	}
}
