.gifting-msg-details {
	margin-bottom: 28px;
	padding: 30px 0 13px;
	position: relative;
	text-align: center;

	@include break-min($large1) {
		display: flex;
		flex-direction: column;
		grid-column: 2;
		padding: $right-aligned-column-gap;
		text-align: left;
	}

	@include ie-grid-fix();

	&:before {
		@include break-max($large1) {
			@include dot-border-h(top);
		}
	}

	&:after {
		@include break-max($large1) {
			@include dot-border-h(bottom);
		}
	}

	&:focus {
		font-weight: normal;
	}

	.module {
		margin-bottom: 20px;
		position: relative;

		@include break-min($large1) {
			grid-row: 1;
			margin: 0;
		}

		&:first-of-type {
			@include break-min($large1) {
				order: 2;
			}
		}

		&:nth-of-type(2) {
			@include break-min($large1) {
				margin-bottom: 15px;
				order: 1;
			}
		}

		p {
			padding: 0;

			&:first-child {
				@include font-size(1.1);
				color: $blue;
				font-family: $secondary;
				font-weight: bold;
				letter-spacing: 2px;
				line-height: 1;
				margin-bottom: 12px;
				text-transform: uppercase;
			}

			&:nth-child(2) {
				@include font-size(1.6);
				line-height: 1.5;
				margin-bottom: 0;
			}
		}
	}
}
