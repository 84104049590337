// sass-lint:disable no-ids
// sass-lint:disable max-file-line-count
// sass-lint:disable indentation

// z-Index Scale
$z1: 100;
$z2: 200;
$z3: 300;
$z4: 400;
$z5: 500;
$z6: 600;
$z7: 700;
$z8: 800;
$z9: 900;
$z10: 1000;

.visually-hidden {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.skip-link {
	&:focus {
		@include p1();
		clip: auto;
		width: auto;
		height: auto;
		margin: 0;
		color: $white;
		background-color: $red;
		border-radius: 40px;
		padding: 5px 15px;
		opacity: 1;
		z-index: 10000;
		transition: none;
		outline-offset: 3px;
		outline: 1px dotted $red;
	}
}

.cprofile {
	.frame {
		z-index: initial;
		box-sizing: border-box;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	.paypal-wrapper {
		margin-top: 25px;

		#braintree-paypal-button {
			border-radius: 24px;
			transition: transform 0.2s ease, box-shadow 0.2s ease;

			&:hover {
				transform: translateY(-1px);
				box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
			}

			&:focus {
				outline: 1px dotted $red;
				outline-offset: 3px;
			}
		}
	}

	.bold {
		font-weight: $bold-weight;
	}

	.btn.btn--vis {
		padding: 15px 19px;
		line-height: 1;
		font-weight: $medium-weight;
		font-family: $primary;
		letter-spacing: -0.5px;
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		@include font-size(1.8);

		&.btn--inline-spaced {
			width: 100%;
			margin: 0 0 24px 0;

			&:last-child {
				margin: 0;
			}
		}

		&.btn--white {
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
			background: $white;
			color: $red;

			&:hover {
				box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
			}
		}

		&.btn--clear {
			box-shadow: none;
			background: transparent;
			color: $red;

			&:hover {
				text-decoration: underline;
				transform: none;
				color: $vis-dkred;
			}
		}

		&:disabled,
		&.btn--disabled {
			background: $mdgrey;
			cursor: not-allowed;

			&:hover {
				box-shadow: none;
				transform: none;
			}
		}

		&.btn--google {
			min-width: 194px;
			background: #4285f4;
			padding: 15px 19px;

			span {
				position: relative;
				display: inline-block;
				padding: 0 0 0 45px;

				&::after {
					background-image: url("img/cp-icons/icon-cp_google.svg");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 30px;
					height: 30px;
				}
			}
		}

		&.btn--fb {
			min-width: 194px;
			background: #3d5998;
			padding: 15px 19px;

			span {
				position: relative;
				display: inline-block;
				padding: 0 0 0 45px;

				&::after {
					background-image: url("img/cp-icons/icon-cp_facebook.svg");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 28px;
					height: 28px;
				}
			}
		}

		&:hover {
			text-decoration: none;
		}

		&.btn--hidden {
			display: none;
		}

		&:focus {
			outline: 1px dotted $red;
			outline-offset: 3px;
		}
	}

	// todo: cleanup
	&--onboarding {
		.cp-content {
			display: none;
		}

		.cp-nav {
			display: block;
		}
	}

	@include break-min($small3) {
		.btn.btn--vis {
			display: inline-block;
			width: auto;

			&.btn--inline-spaced {
				width: auto;
				margin: 0 20px 0 0;
			}
		}
	}

	@include break-min($large1) {
		.frame {
			padding: 64px 0 0;
		}

		&--onboarding {
			.cp-content {
				display: block;
			}
		}
	}

	// specificity to override nesting
	.ob-modal {
		// sass-lint:disable-block max-line-length
		backface-visibility: hidden;
		background-color: $yellow-orange;
		border-radius: 8px;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
		display: none;
		margin: auto;
		max-width: 340px;
		opacity: 0;
		padding: 0 5px;
		text-align: center;
		transform: translateY(-20px);
		transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
			transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		width: 100%;
		will-change: opacity, transform;

		&--visible {
			opacity: 1;
			transform: translateY(0);

			.ob-modal__icon-circle {
				&::before {
					opacity: 1;
					transform: scale(1);
				}
			}
		}

		&--absolute {
			// sass-lint:disable-block no-duplicate-properties
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 100%;
			top: calc(100% + 5px);
			z-index: $z9;
		}

		p {
			@include p1();
			max-width: 294px;
			margin: 0 auto;
			padding: 0 0 20px;
		}

		&__icon {
			&-bar {
				height: 40px;
				position: relative;

				&::after {
					content: "";
					width: 0;
					height: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
					top: -9px;
					border-top: 7px solid transparent;
					border-bottom: 7px solid transparent;
					border-right: 7px solid $yellow-orange;
					position: absolute;
					transform: rotateZ(90deg);
					z-index: 300;
				}
			}

			&-circle {
				border-radius: 50%;
				background-color: $colonial-white;
				width: 50px;
				height: 50px;
				left: 0;
				right: 0;
				bottom: -25px;
				margin: 0 auto;
				position: absolute;
				z-index: $z2;

				&::before {
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					bottom: 0;
					content: "";
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					opacity: 0;
					transform: scale(0);
					transition: opacity 0.2s ease 0.4s, transform 0.2s ease 0.4s;
				}

				&--megaphone {
					&::before {
						background-image: url("img/cp-icons/icon-cp_megaphone.svg");
					}
				}

				&--heart {
					&::before {
						background-image: url("img/cp-icons/icon-cp_heart.svg");
					}
				}

				&--card {
					&::before {
						background-image: url("img/cp-icons/icon-cp_card-colored.svg");
					}
				}

				&--pickup {
					&::before {
						background-image: url("img/cp-icons/icon-cp_pickup-colored.svg");
					}
				}

				&--qr {
					&::before {
						background-image: url("img/cp-icons/icon-cp_scan.svg");
					}
				}
			}
		}

		&__content {
			position: relative;
			background-color: $white;
			padding: 45px 10px 15px 10px;
			overflow: hidden;
		}

		&__count {
			@include p2();
			position: absolute;
			top: 1px;
			right: 5px;
			margin: 0;
			padding: 0;
			color: $light-grey;
		}

		.btn.btn--vis {
			display: block;
			color: $white;
			max-width: 256px;
			margin: 0 auto 10px auto;

			&.btn--clear {
				color: $red;
				margin: 0 auto;
			}
		}
	}

	@include break-min($large1) {
		.ob-modal {
			margin: 0 0 0 20px;
			width: 340px;

			&--absolute {
				left: 100%;
				top: 0;
				right: auto;
			}

			&__icon-bar {
				&::after {
					top: 0;
					bottom: 0;
					margin: auto;
					left: -12px;
					right: auto;
					transform: rotateZ(0deg);
				}
			}
		}

		.cp-nav {
			.ob-modal {
				&--absolute {
					top: 12px;
				}
			}
		}
	}
}

.cp-page {
	min-height: calc(100vh - 64px);
	position: relative;
	box-sizing: border-box;

	&::after {
		content: "";
		opacity: 0;
		transition: opacity 0.2s ease;
		pointer-events: none;
	}

	* {
		box-sizing: inherit;
	}

	.mobile-only {
		@media only screen and (min-width: $lrg-tablet) {
			display: none;
		}
	}

	.desktop-only {
		@media only screen and (max-width: $mobile) {
			display: none;
		}
	}

	@include break-min($large1) {
		&--skrim {
			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				z-index: $z2;
				// sass-lint:disable variable-for-property
				background-color: rgba(0, 0, 0, 0.5);
				// sass-lint:enable variable-for-property
				opacity: 1;
			}
		}

		&--flex {
			@include flexbox();
			@include justify-content(stretch);
			@include align-items(stretch);
			@include flex-wrap(nowrap);
		}
	}
}

.cp-nav {
	display: none;
	padding: 40px 0;
	background-color: $cream;

	&__details {
		padding: 20px;
		text-align: center;

		> span {
			@include s3();
			text-transform: uppercase;
			color: $vis-blue;
			display: block;

			&.cp-nav__rewards {
				@include s3();
				text-transform: uppercase;
				color: $red;
				padding: 0 0 15px;
				margin: 0;
			}
		}

		> h4 {
			@include h4();
			color: $vis-blue;
			padding: 0 0 10px;
			margin: 0;
		}

		> p {
			@include p2();
			color: $vis-grey;
			padding: 0 0 10px;
			margin: 0;
		}
	}

	&__profile-icon {
		width: 48px;
		height: 48px;
		margin: 15px auto 10px;
		padding: 0;
		background-image: url("img/cp-icons/icon-cp_profile.svg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	&__card {
		position: relative;
		background-color: $white;
		border-radius: 8px;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
		min-height: 125px;
		padding: 20px 20px 20px 95px;
		margin: 20px auto;
		max-width: 540px;
		text-align: left;

		h6 {
			@include s3();
			text-transform: uppercase;
			margin: 0;
			padding: 0 0 5px;
			color: $vis-blue;
		}

		p {
			@include p1();
			color: $vis-grey;
			padding: 10px 0 1px;
			margin: 0;
			display: block;
		}

		span {
			@include p2();
			color: $vis-grey;
			font-weight: $medium-weight;
			padding: 5px 0 0;
			margin: 0;
			display: block;
		}

		&-close {
			width: 40px;
			height: 40px;
			cursor: pointer;
			padding: 12px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;

			svg {
				width: 100%;
				height: auto;
			}
		}

		&-icon {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			padding: 20px 10px;
			text-align: center;
			width: 95px;
		}

		&-svg {
			background-color: $iron;
			border-radius: 50%;
			width: 60px;
			height: 60px;
			margin: 0 auto;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;

			svg {
				width: 100%;
				height: auto;
				display: block;
			}

			&--truck {
				background-image: url("img/cp-icons/icon-cp_truck.svg");
			}

			&--pickup {
				background-image: url("img/cp-icons/icon-cp_pickup.svg");
			}
		}
	}

	&__list {
		margin: 0;
		padding: 0;

		li {
			position: relative;
			list-style-type: none;
			margin: 0;
			padding: 0;
			text-align: center;
			border-bottom: 3px solid $gallery;

			> a {
				@include font-size(2.3);
				font-family: $primary;
				font-weight: $medium-weight;
				line-height: 1.4;
				color: $vis-grey;
				display: block;
				vertical-align: middle;
				text-align: left;
				padding: 16px 35px 16px 18px;
				position: relative;

				&:hover {
					background-color: $white;
					color: $vis-blue;
				}

				&::after {
					content: "";
					background-image: url("img/cp-icons/icon-cp_arrow-right.svg");
					width: 24px;
					height: 24px;
					background-size: cover;
					position: absolute;
					top: 0;
					right: 15px;
					bottom: 0;
					margin: auto;
					pointer-events: none;
				}
			}

			span {
				@include font-size(1.2);
				background-color: $red;
				border-radius: 12px;
				color: $white;
				display: inline-block;
				font-family: $primary;
				font-weight: $regular-weight;
				height: 24px;
				line-height: 2;
				margin: -2px -34px 0 10px;
				min-width: 24px;
				padding: 0;
				text-align: center;
				vertical-align: middle;
			}

			&.active {
				background-color: $white;

				> a {
					color: $vis-blue;
				}
			}
		}
	}

	.cp-nav__accord {
		&-toggle {
			&:focus {
				font-weight: $medium-weight;
			}

			&::after {
				transition: transform 0.2s ease;
				background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
			}
		}

		&-content {
			padding: 0 0 10px 0;
			display: none;

			li {
				border: 0;

				a {
					@include font-size(1.8);
					color: $vis-grey;
					padding: 8px 18px;

					&::after {
						content: none;
					}

					&:hover {
						color: $red;
					}
				}

				&.active {
					a {
						color: $red;
					}
				}
			}
		}

		&.expanded {
			.cp-nav__accord-toggle {
				&::after {
					transform: rotate3d(1, 0, 0, 180deg);
				}
			}

			.cp-nav__accord-content {
				display: block;
			}
		}
	}

	@include break-min($large1) {
		position: relative;
		z-index: $z3;
		display: block;
		min-width: 360px;
		@include flex(0 0 auto);

		&__list {
			li {
				border: 0;

				a {
					padding: 16px 0;
					text-align: center;

					&::after {
						content: none;
					}
				}
			}

			&--sub {
				li {
					a {
						padding: 8px 0;
					}
				}
			}
		}

		.cp-nav__accord {
			&-toggle {
				&::after {
					content: "";
					background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
				}
			}
		}
	}
}

.cp-content {
	padding: 35px 0 70px;

	&--nopad {
		&-bot {
			padding-bottom: 0;
		}
	}

	&__messaging {
		span {
			@include p2();
			display: block;
			color: $red;
			font-weight: $medium-weight;
			padding: 20px 0 0;
			margin: 0;
		}

		p {
			@include p2();
			text-align: center;
			background-color: $lred;
			border-radius: 8px;
			color: $vis-grey;
			padding: 62px 20px 20px 20px;
			font-weight: $medium-weight;
			margin: 35px 0 0;
			position: relative;

			&::before {
				display: block;
				position: absolute;
				background-image: url("img/cp-icons/icon-cp_warning.svg");
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				top: 16px;
				left: 0;
				right: 0;
				margin: auto;
				width: 36px;
				height: 36px;
				content: "";
			}

			@include break-min($small4) {
				text-align: left;
				padding: 20px 20px 20px 95px;

				&::before {
					top: 0;
					left: 35px;
					bottom: 0;
					right: auto;
				}
			}
		}
	}

	&__inner {
		margin: 0 auto;
		width: 100%;
		max-width: 880px;
		padding: 0 15px;

		> p {
			@include p1();
			margin: 0;
			padding: 0;
			color: $vis-grey;

			a {
				font-weight: $medium-weight;
			}
		}
	}

	&__header {
		h1,
		h2 {
			@include h3();
			color: $vis-blue;
			padding: 0 0 10px;
			margin: 0;
		}

		> h5 {
			@include p2();
			margin: 0;
			padding: 15px 0 0;
			color: $vis-grey;
		}

		p {
			@include p1();
			margin: 0;
			padding: 0;
			color: $vis-grey;

			a {
				font-weight: $medium-weight;
			}

			+ p {
				padding: 20px 0 0;
			}
		}

		.btns-wrapper {
			position: relative;
			width: 100%;

			@include break-min($small3) {
				display: inline-block;
				width: auto;
			}
		}

		.btn {
			margin: 25px 0 0;
		}

		.success-msg {
			color: $red;
		}
	}

	&__cards {
		padding: 0 0 15px;

		.cp-content__radio {
			min-height: 42px;
			@include flexbox;
			@include align-items(center);
			@include justify-content(space-between);
			@include flex-wrap(nowrap);

			label {
				@include flex(1 1 auto);
			}
		}
	}

	fieldset {
		border: 0;
		padding: 0;
		margin: 0;

		legend {
			color: $vis-grey;
			display: block;
			margin: 0;
			padding: 0 0 35px;
			@include p1();
		}
	}

	&__radio {
		margin: 0 0 20px;
		position: relative;

		&--disabled {
			img {
				filter: grayscale(100%);
				opacity: 0.3;
			}
		}

		label {
			@include p2();
			color: $vis-grey;
			margin: 0;
			padding: 0 0 0 80px;
			position: relative;
			cursor: pointer;
			display: inline-block;
			z-index: $z2;

			&::before {
				content: "";
				margin: auto;
				display: block;
				width: 18px;
				height: 18px;
				background-color: $white;
				border: 1px solid $light-grey;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				border-radius: 50%;
			}
		}

		input {
			border: 0;
			padding: 0;
			margin: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
			pointer-events: none;

			&:hover {
				+ label {
					&::before {
						background-color: lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:focus {
				+ label {
					&::before {
						box-shadow: 0 0 0 3px lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:checked {
				+ label {
					&::before {
						background-color: $white;
						border-color: $red;
					}

					&::after {
						content: "";
						position: absolute;
						background-color: $red;
						left: 3px;
						top: 0;
						bottom: 0;
						margin: auto;
						width: 14px;
						height: 14px;
						border-radius: 50%;
					}
				}
			}

			&:disabled {
				+ label {
					cursor: not-allowed;

					&::before {
						opacity: 0.5;
						background-color: $white;
						border-color: $light-grey;
						box-shadow: none;
					}
				}
			}
		}

		p {
			@include p2();
			font-weight: $medium-weight;
			text-align: right;
			color: $light-grey;
			display: inline-block;
			float: right;
			margin: 0;
			padding: 0;

			span {
				@include p2();
				font-weight: $medium-weight;
				color: $red;
				cursor: pointer;
				display: inline-block;
				padding: 9px 5px;

				&:hover {
					color: $dkred;
				}
			}
		}

		img {
			display: block;
			width: 35px;
			height: auto;
			// border-radius: 3px;
			position: absolute;
			top: 0;
			left: 33px;
			bottom: 0;
			margin: auto;
			z-index: $z1;
		}
	}

	&__show-nav {
		@include p1();
		font-weight: $medium-weight;
		color: $red;
		display: inline-block;
		padding: 5px 0 5px 35px;
		margin: 0 0 20px;
		position: relative;
		cursor: pointer;

		&::after {
			content: "";
			background-image: url("img/cp-icons/icon-cp_arrow-left.svg");
			width: 24px;
			height: 24px;
			background-size: cover;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto;
		}

		&:focus {
			outline-offset: 3px;
			outline: 1px dotted $red;
		}
	}

	&__manage-card {
		@include type-p2();
		@include link-underline();
		margin: 34px 0;
		padding: 5px 0 5px 22px;

		&::after {
			height: 15px;
			width: 15px;
		}
	}

	&__row {
		position: relative;
		padding: 30px 0 40px;

		+ .cp-content__row {
			&::after {
				@include dot-border-h(top);
			}
		}

		h3 {
			@include h5();
			color: $vis-grey;
			padding: 10px 0 15px;
			margin: 0;
		}

		ul {
			padding: 0 0 0 25px;
			margin: 0;
		}

		li {
			@include p1();
			background-image: url("img/bullet.svg");
			background-position: 0 13px;
			background-repeat: no-repeat;
			color: $vis-grey;
			list-style-image: none;
			list-style-type: none;
			margin: 0;
			padding: 0 0 0 15px;
			text-indent: 0;
		}
	}

	.load-more-transactions {
		display: flex;
		margin: 25px auto 0;

		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}

	@include break-min($small2) {
		&__radio {
			label {
				padding: 0 0 0 120px;
			}

			img {
				width: 50px;
				left: 45px;
			}
		}
	}

	@include break-min($small4) {
		&__radio {
			p {
				@include p1();
				font-weight: $medium-weight;

				span {
					@include p1();
					font-weight: $medium-weight;
					padding: 3px 5px;
				}
			}
		}

		&__inner {
			padding: 0 30px;
		}
	}

	@include break-min($large1) {
		@include flex(1 1 auto);
		box-shadow: inset 5px 0 4px -5px rgba(0, 0, 0, 0.2);
		padding: 55px 0 100px;
		max-width: 1090px;

		&__show-nav {
			display: none;
		}

		&__manage-card {
			display: block;
		}

		&__header {
			h1,
			h2 {
				padding: 0 0 20px;
			}
		}
	}
	// @include break-min($huge1) {
	// @include break-min(1500px) {
	//     // max-width: 977px;
	//     &__inner {
	//         // padding: 0 10.2%;
	//         // max-width: 1089px;
	//         margin: 0 0 0 8%;
	//     }
	// }
	// @include break-min(1500px) {
	//   max-width: 977px;
	//   &__inner {
	//       padding: 0 10.2%;
	//       max-width: 1089px;
	//       margin: 0;
	//   }
	// }
}

.cp-form {
	text-align: left;
	padding: 30px 0;

	&--no-pad {
		padding: 0;
	}

	fieldset {
		border: 0;
		padding: 0;
		margin: 0;

		legend {
			color: $vis-grey;
			display: block;
			margin: 0;
			padding: 0 0 35px;
			@include p1();
		}
	}

	section {
		position: relative;
		padding: 30px 0 40px;

		&::after {
			@include dot-border-h(bottom);
		}
	}

	p {
		@include p2();
		color: $light-grey;
		padding: 10px 0 0;
		margin: 0;
		font-weight: $medium-weight;
		max-width: 340px;
		display: block;

		&.error {
			color: $red;
		}
	}

	h3 {
		color: $vis-grey;
		display: block;
		margin: 0;
		padding: 0;
		@include h5();
	}

	h5 {
		display: block;
		margin: 0;
		padding: 40px 0 0;
		max-width: 100%;
		color: $vis-grey;
		@include p1();
	}

	label {
		@include p2();
		font-weight: $medium-weight;
		color: $light-grey;
		pointer-events: none;
	}

	input {
		&[type="password"],
		&[type="text"],
		&[type="number"],
		&[type="date"],
		&[type="email"] {
			@include p1();
			@include appearance(none);
			color: $vis-grey;
			padding: 22px 15px 0 15px;
			border: 3px solid $vis-grey;
			border-radius: 6px;
			display: block;
			height: 68px;
			width: 100%;

			&:focus {
				border-color: $vis-blue;
				outline: 1px dotted $red;
				outline-offset: 3px;
				// + label {
				//     color: $vis-blue;
				// }
			}

			&:disabled {
				background-color: $concrete;
				border-color: $mdgrey;

				&::placeholder {
					color: $vis-grey;
				}
			}

			&::placeholder {
				color: $light-grey;
			}
		}

		&[type="number"],
		&[type="date"] {
			@include appearance(textfield);

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				@include appearance(none);
				margin: 0;
			}
		}
	}

	textarea {
		@include p1();
		@include appearance(none);
		color: $vis-grey;
		padding: 13px 15px 0 15px;
		border: 3px solid $vis-grey;
		border-radius: 6px;
		display: block;
		min-height: 96px;
		max-height: 200px;
		width: 100%;

		&:focus {
			border-color: $vis-blue;
			outline: 1px dotted $red;
			outline-offset: 3px;
		}

		&:disabled {
			background-color: $concrete;
			border-color: $mdgrey;
		}
	}

	&__spacer {
		height: 0;
		padding: 40px 0 0;

		&--dotted {
			position: relative;
			padding: 45px 0 0;
			margin: 0 0 45px;

			&::after {
				@include dot-border-h();
			}
		}
	}

	&__group {
		padding: 0;
		margin: 40px 0 0;
		background-color: $white;
		position: relative;

		&:first-child {
			margin: 0;
		}

		> span {
			@include s3();
			text-transform: uppercase;
			color: $vis-grey;
			position: absolute;
			right: 0;
			left: 0;
			bottom: 100%;
			padding: 0 0 10px;
			display: block;
		}

		&--input {
			max-width: 340px;

			label {
				position: absolute;
				top: 10px;
				left: 18px;
			}
		}

		&--margin {
			&-less {
				margin: 30px 0 0;
			}
		}

		&--editable {
			padding: 0 80px 0 0;
			max-width: 420px;

			button,
			a {
				position: absolute;
				top: 17px;
				right: 0;
			}
		}

		&--inline {
			width: 100%;
			display: block;
		}

		&--small {
			width: 100%;
			max-width: 150px;
		}

		&--disabled {
			label {
				color: $vis-grey;
			}
		}

		&--date {
			&::before {
				content: "";
				background-image: url("img/cp-icons/icon-cp_calendar.svg");
				width: 25px;
				height: 25px;
				background-size: cover;
				position: absolute;
				top: 22px;
				right: 17px;
				margin: auto;
				pointer-events: none;
			}
		}

		&.cp-form__group--error {
			label {
				color: $red;
			}

			.cp-form__small-label {
				color: $light-grey;
			}

			select {
				color: $red;
			}

			input,
			select,
			textarea {
				border-color: $red;

				&::placeholder {
					color: $red;
				}
			}
		}
	}

	&__select {
		position: relative;

		label {
			position: absolute;
			top: 10px;
			left: 18px;
			@include p2();
			font-weight: $medium-weight;
			color: $light-grey;
			pointer-events: none;
		}

		select {
			@include p1();
			@include appearance(none);
			color: $vis-grey;
			padding: 22px 60px 0 15px;
			display: block;
			height: 68px;
			width: 100%;
			cursor: pointer;
			background-color: transparent;
			background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
			background-size: 24px 24px;
			background-position: bottom 18px right 19px;
			background-repeat: no-repeat;
			border: 3px solid $vis-grey;
			border-radius: 6px;

			&:focus {
				border-color: $vis-blue;
				outline: 1px dotted $red;
				outline-offset: 3px;
			}

			&:disabled {
				background-color: $concrete;
				border-color: $mdgrey;
				cursor: not-allowed;
			}
		}

		&--disabled {
			label {
				color: $vis-grey;
			}
		}
	}

	&__inline-btn {
		@include p1();
		font-weight: $medium-weight;
		display: inline-block;
		color: $red;
		cursor: pointer;
		position: relative;
		padding: 0;
		appearance: none;
		border: 0;
		background: transparent;

		&--add {
			padding: 0 0 0 20px;

			&::after {
				content: "";
				background-image: url("img/cp-icons/icon-cp_plus.svg");
				width: 14px;
				height: 14px;
				background-size: cover;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				margin: auto;
			}
		}

		&:hover {
			color: $dkred;
			text-decoration: underline;
		}

		&:focus {
			outline: 1px dotted $red;
			outline-offset: 3px;
		}
	}

	@include break-min($small4) {
		&__group {
			&--margin {
				&-left {
					margin: 0 0 0 20px;
				}
			}

			&--inline {
				display: inline-block;
			}

			&--editable {
				button,
				a {
					left: calc(100% - 65px);
					right: auto;

					&:focus {
						font-weight: $medium-weight;
					}
				}
			}
		}
	}
}

.cp-block {
	position: relative;
	padding: 40px 0;
	color: $vis-grey;

	h4 {
		@include h5();
		margin: 0;
		padding: 0;
	}

	span {
		@include p2();
		margin: 0;
		padding: 12px 0 0;
		display: block;
	}

	p {
		@include p1();
		margin: 0;
		padding: 0;
		max-width: 470px;
	}

	a {
		&:not(.btn) {
			@include p2();
			display: inline-block;
			font-weight: $medium-weight;
		}
	}

	&__wrap {
		padding: 40px 0 0;
	}

	&__header {
		position: relative;
		margin: 0;
		padding: 0 0 0 65px;

		+ .cp-block__content {
			margin: 20px 0 0;
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 50px;
		height: 50px;
		margin: 0 auto;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		&--cow {
			background-image: url("img/cp-icons/icon-cp_cow-blue.svg");
		}

		&--star {
			background-image: url("img/cp-icons/icon-cp_star-blue.svg");
		}

		&--card {
			background-image: url("img/cp-icons/icon-cp_card.svg");
		}

		&--gift {
			background-image: url("img/cp-icons/icon-cp_gift-blue.svg");
		}

		&--scan {
			background-image: url("img/cp-icons/icon-cp_scan.svg");
		}

		&--mobile {
			background-image: url("img/cp-icons/icon-cp_mobile.svg");
		}

		&--catering {
			background-image: url("img/cp-icons/icon-cp_catering.svg");
		}

		&--delivery,
		&--truck {
			background-image: url("img/cp-icons/icon-cp_truck.svg");
		}
	}

	&__info-bar {
		padding: 0 0 10px;

		@include flexbox;
		@include align-items(flex-start);
		@include justify-content(stretch);
		@include flex-wrap(nowrap);

		span {
			@include s3();
			display: block;
			color: $vis-blue;
			margin: 0;
			padding: 6px 0 0;
			@include flex(1 1 auto);
		}

		h6 {
			@include flex(1 1 auto);
			@include p2();
			white-space: nowrap;
			text-align: right;
			margin: 0;
			padding: 0 0 0 10px;
		}
	}

	&__flex {
		&-bar {
			padding: 15px 0 0;
		}

		&-item {
			* {
				display: inline-block;
			}

			p {
				padding: 0 10px 0 0;
			}

			span {
				color: $vis-blue;
				padding: 20px 0 0;
				display: block;
			}

			.btn {
				white-space: nowrap;
				margin: 20px 0 0;

				&.btn--clear {
					padding: 5px;
				}
			}
		}
	}

	&__content {
		p > span {
			display: block;
			padding: 0 0 0 11px;

			&:last-child {
				margin: 0 0 5px;
			}
		}
	}

	&::after {
		@include dot-border-h(bottom);
	}

	&:first-child {
		&::before {
			@include dot-border-h(top);
		}
	}

	@include break-min($small3) {
		&__header,
		&__content {
			padding: 0 0 0 90px;
		}

		&__icon {
			width: 70px;
			height: 70px;
		}

		&__flex {
			&-bar {
				padding: 0;

				@include flexbox;
				@include align-items(flex-end);
				@include justify-content(stretch);
				@include flex-wrap(wrap);
			}

			&-item {
				padding: 15px 0 10px;
				@include flex(0 0 auto);

				&:first-child {
					padding: 15px 20px 0 0;
					@include flex(1 1 auto);
				}

				.btn {
					margin: 0 10px 0 0;

					&:last-child {
						margin: 0;
					}
				}
			}
		}
	}
}

.cp-preferred {
	position: relative;
	padding: 30px 0 40px;

	&:after {
		@include dot-border-h(bottom);
	}

	&:last-child {
		&::after {
			content: none;
		}
	}

	&__wrapper {
		padding: 0 0 40px;

		.cp-preferred:last-child {
			padding-bottom: 0;
		}
	}

	h2 {
		@include h5();
		color: $vis-blue;
		margin: 0;
		padding: 0 0 15px;

		+ .cp-preferred__block {
			padding-top: 0;
		}
	}

	h4 {
		@include h4();
		color: $red;
		margin: -4px 0 0;
		padding: 0 0 15px;
	}

	p {
		@include p1();
		color: $vis-grey;
		padding: 0;
		margin: 0;
		max-width: 200px;

		@include break-min($small5) {
			max-width: none;
		}

		&.cp-preferred__hours {
			color: $vis-green;
			padding: 0 0 12px;
		}
	}

	span {
		@include p2();
		color: $vis-grey;
		display: inline-block;
		margin: 0;
		padding: 0 15px 0 0;
	}

	&__icon {
		background-color: $iron;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin: 0 auto;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		&--location {
			background-image: url("img/cp-icons/icon-cp_location.svg");
		}

		&-wrap {
			position: absolute;
			top: 9px;
			left: 0;
			padding: 0;
			text-align: center;

			span {
				display: none;
			}
		}
	}

	&__hours {
		color: $vis-green;
	}

	&__content {
		position: relative;

		.edit-location {
			position: absolute;
			right: 67px;
			top: 23px;
		}

		.heart-icon {
			cursor: pointer;
			position: absolute;
			right: 0;
			top: 9px;
			height: 50px;
			width: 50px;
		}
	}

	&__header {
		padding: 0 0 0 55px;
		position: relative;
	}

	&.margin-bottom {
		margin-bottom: 40px;
	}

	.btn.btn--vis {
		margin: 30px 0 0;
		display: block;
	}

	@include break-min($small3) {
		&__header {
			padding: 0 0 0 55px;
		}

		&__content {
			padding: 0 110px 0 0;
		}

		.btn.btn--vis {
			position: absolute;
			top: 5px;
			right: 0;
			margin: 0;
		}

		&--location {
			.btn.btn--vis {
				top: auto;
				bottom: 10px;
			}

			.cp-preferred__content {
				padding-left: 0;
			}
		}
	}

	@include break-min($medium1) {
		&__content {
			padding: 0 110px 0 120px;
		}

		&__header {
			position: static;
			padding: 0;
		}

		&__icon {
			width: 60px;
			height: 60px;

			&-wrap {
				span {
					display: block;
					padding: 5px 0 0;
					white-space: nowrap;
					text-align: center;
				}
			}
		}
	}
}

.cp-empty-msg {
	text-align: center;
	margin: 20px 0;

	&__icon {
		margin: 0 auto;
		width: 101px;
		height: 101px;
		background-image: url("img/cp-icons/icon-cp_frowny.svg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	h5 {
		@include h5();
		margin: 0;
		padding: 30px 0 0 0;
	}

	.btn.btn--vis {
		position: relative;
		top: auto;
		right: auto;
		margin: 35px 0 0;
		display: inline-block;
	}
}

.cp-modal {
	@include flex(0 0 100%);
	max-width: 935px;
	//margin: auto;
	padding: 0;

	@include flexbox;
	@include align-items(center);
	@include justify-content(center);

	.cp-form {
		max-width: 660px;
		margin: 0 auto;
		padding: 0;
		text-align: center;

		legend {
			padding: 0;
			width: 100%;
		}

		p {
			text-align: left;
		}

		&__group {
			margin: 40px auto 0 auto;

			&:first-child {
				margin: auto;
			}
		}

		section {
			padding: 0;

			&::after {
				content: none;
			}
		}
	}

	h3 {
		@include h3(mobile-only);
		margin: 0;
		color: $vis-blue;
		padding: 0 0 15px;
	}

	&__wrap {
		// sass-lint:disable-block no-misspelled-properties
		background-color: $white;
		bottom: 0;
		display: none;
		left: 0;
		overflow: scroll;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 4000;

		&--visible {
			display: block;
		}
	}

	&__inner {
		position: relative;
		min-height: 300px;
		background-color: $white;
		text-align: center;
		padding: 50px 15px 60px;
		@include flex(1 0 100%);
	}

	&__close {
		@include lightbox-close-btn();
	}

	@include break-min($small5) {
		padding: 20px;

		&__wrap {
			// sass-lint:disable variable-for-property
			background-color: rgba(0, 0, 0, 0.5);
			// sass-lint:enable variable-for-property

			&--visible {
				@include flexbox;
				@include align-items(center);
				@include justify-content(center);
			}
		}

		&__inner {
			border-radius: 8px;
		}
	}
}

.cp-lightbox {
	padding: 10px;
	box-sizing: border-box;

	* {
		box-sizing: inherit;
	}

	h3 {
		@include h3(mobile-only);
		color: $vis-blue;
		padding: 0 0 20px;
		margin: 0 auto;
	}

	h2 {
		@include h3();
		color: $vis-blue;
		padding: 0 0 20px;
		margin: 0 auto;
	}

	p {
		color: $vis-grey;
		display: block;
		margin: 0 auto;
		padding: 0 0 35px;
		@include p1();

		&:last-child {
			padding: 0;
		}
	}

	iframe {
		padding: 0;
		margin: 0 auto;
		border: 0;
		max-width: 470px;
		width: 100%;
	}

	.btn.btn--vis {
		opacity: 1;
		height: auto;
		padding: 15px 19px;
		line-height: 1;
		font-weight: $medium-weight;
		font-family: $primary;
		letter-spacing: -0.5px;
		position: relative;
		display: block;
		width: 100%;
		margin: 0 0 24px 0;
		text-align: center;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		@include font-size(1.8);

		&:last-child {
			margin: 0;
		}

		&.btn--white {
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
			background: $white;
			color: $red;

			&:hover {
				box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
			}
		}

		&.btn--clear {
			box-shadow: none;
			background: transparent;
			color: $red;

			&:hover {
				text-decoration: underline;
				transform: none;
				color: $vis-dkred;
			}
		}

		&.btn--min-width {
			min-width: 175px;
		}

		&:focus {
			outline: 1px dotted $red;
			outline-offset: 3px;
		}
	}

	.cp-lightbox__inner {
		max-width: 700px;
		position: relative;
		background-color: $white;
		text-align: center;
		border-radius: 8px;
		padding: 60px 15px;
		margin: auto;
		box-sizing: border-box;
	}

	&__textwrap {
		max-width: 450px;
		margin: 0 auto;
	}

	&__close {
		width: 60px;
		height: 60px;
		cursor: pointer;
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0;
		opacity: 1;
		padding: 0;
		z-index: $z2;

		&::after {
			background-image: url("img/cp-icons/icon-cp_close.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 16px;
			height: 16px;
		}

		&:focus {
			outline: 1px dotted $red;
		}

		&--white {
			&::after {
				background-image: url("img/cp-icons/icon-cp_close-white.svg");
			}
		}
	}

	@include break-min($small3) {
		padding: 20px;

		.btn.btn--vis {
			display: inline-block;
			width: auto;
			margin: 0 20px 0 0;
		}
	}
}

.cp-checkbox {
	padding: 20px 0 0 0;
	margin: 0;
	position: relative;

	label {
		@include p2();
		color: $vis-grey;
		margin: 0;
		padding: 0 0 0 30px;
		position: relative;
		cursor: pointer;
		display: inline-block;
		pointer-events: auto;
		z-index: $z2;

		&::before {
			content: "";
			margin: 0;
			display: block;
			width: 16px;
			height: 16px;
			background-color: $white;
			border: 2px solid $light-grey;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			border-radius: 4px;
		}

		&::after {
			content: "";
			position: absolute;
			width: 12px;
			height: 12px;
			top: 4px;
			left: 4px;
		}
	}

	input {
		border: 0;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
		pointer-events: none;

		&:hover {
			+ label {
				&::before {
					background-color: lighten($red, 50%);
					border-color: $red;
				}
			}
		}

		&:focus {
			+ label {
				&::before {
					box-shadow: 0 0 0 3px lighten($red, 50%);
					border-color: $red;
				}
			}
		}

		&:checked {
			+ label {
				&::before {
					background-color: $red;
					border-color: $red;
				}

				&::after {
					@include checkmark();
				}
			}
		}

		&:disabled {
			+ label {
				opacity: 0.5;
				color: $vis-grey;
				cursor: not-allowed;

				&::before {
					box-shadow: none;
					background-color: $white;
					border-color: $light-grey;
				}
			}
		}
	}

	&--med-text {
		padding: 15px 0 0 0;

		label {
			padding: 0 0 0 35px;
			@include p1();

			&::before {
				top: 5px;
			}

			&::after {
				top: 9px;
			}
		}

		p {
			@include p2();
			color: $light-grey;
			max-width: 560px;
			padding: 8px 0 10px 35px;
		}
	}
}

.cp-f2s {
	padding: 65px 0;

	&--success {
		padding: 50px 0 20px;

		.cp-f2s__half {
			display: none;
		}
	}

	&__message {
		&-box {
			text-align: center;
			width: 100%;
			background-color: $cream;
			padding: 30px 15px;
			border-radius: 8px;

			h3 {
				@include h3();
				color: $vis-blue;
				padding: 15px 0;
				margin: 0;
			}

			p {
				padding: 0 0 20px;
				max-width: 575px;
				margin: 0 auto;
			}
		}

		&-icon {
			width: 66px;
			height: 66px;
			position: relative;
			margin: 0 auto;

			&::after {
				content: "";
				background-image: url("img/cp-icons/icon-cp_disco-ball.svg");
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
			}
		}
	}

	&__receipt {
		width: 100%;
		margin: 0;
		position: relative;
		padding: 30px 20px;
		background-color: $concrete;
		border-radius: 5px;
		box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);

		p {
			text-align: center;
			position: relative;
			z-index: 1;
		}

		p,
		td,
		span {
			line-height: 1.4;
			font-family: "franklin-gothic", sans-serif;
			color: $black;
			@include font-size(1.6);
		}

		&-label {
			@include s3();
			text-align: center;
			text-transform: uppercase;
			color: $vis-blue;
			display: block;
			position: absolute;
			right: 0;
			left: 0;
			bottom: 100%;
			padding: 0 0 18px;
			margin: 0;
		}

		.highlight {
			display: inline-block;
			position: relative;
			transition: color 0.3s ease;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 100%;
				left: -4px;
				bottom: -2px;
				will-change: right, background-color;
				transition: right 0.2s ease, background 0.2s ease;
				background-color: $concrete;
				z-index: -1;
				border-radius: 6px;
			}

			&--active {
				color: $white;

				&::after {
					right: -4px;
					background-color: $vis-blue;
				}
			}
		}

		td + td {
			text-align: right;
		}

		table {
			width: 100%;
			position: relative;
			z-index: 1;
		}

		.nested {
			padding: 0 0 0 40px;
		}

		.calculation {
			padding: 0 0 0 22px;
		}
	}

	.cp-form {
		padding: 65px 0 0;

		&__group {
			max-width: 100%;
		}
	}

	@include break-min($small3) {
		.cp-form {
			&__group {
				max-width: 340px;
			}
		}

		&__receipt {
			max-width: 340px;
		}
	}

	@include break-min($small4) {
		@include flexbox();
		@include justify-content(stretch);
		@include align-items(stretch);

		&__message {
			&-box {
				padding: 55px 30px;
			}
		}

		&__half {
			@include flex(0 0 50%);
		}

		.cp-form {
			max-width: 370px;
			margin: 0 0 0 auto;
			padding: 15px 0 0 30px;
		}
	}
}

.cp-faq {
	padding: 30px 15px 60px;
	margin: 0 -15px;
	background-color: $cream;

	h2 {
		@include h4();
		margin: 0;
		padding: 0 0 20px;
		color: $vis-blue;
	}

	p {
		@include p1();
		color: $vis-grey;
		padding: 20px 0 0;
		margin: 0;
		display: block;
	}

	&__answer {
		padding: 0 15px 20px;
	}

	&__block {
		background-color: $white;
		margin: 0 -15px;
		padding: 0;
		border-bottom: 4px solid $cream;

		h3 {
			@include h5();
			margin: 0;
			padding: 15px 60px 18px 15px;
			position: relative;
			color: $red;
			cursor: pointer;
			display: block;

			&::after {
				content: "";
				background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
				position: absolute;
				top: 22px;
				right: 15px;
				bottom: 0;
				width: 24px;
				height: 24px;
			}

			&:focus {
				font-weight: $medium-weight;
			}
		}

		p {
			padding: 10px 0 0;
		}
	}

	@include break-min($small4) {
		padding: 30px 30px 60px;
		margin: 0 -30px;

		&__answer {
			padding: 0 30px 20px;
		}

		&__block {
			margin: 0 -30px;

			h3 {
				padding: 15px 75px 18px 30px;

				&::after {
					right: 30px;
				}
			}
		}
	}

	@include break-min($large1) {
		background-color: $white;
		padding: 55px 0 30px;
		margin: 0;

		h2 {
			border-bottom: 1px solid $mdgrey;
			margin: 0 0 10px;
			padding: 0 0 30px;
		}

		p {
			padding: 50px 0 0;
		}

		&__block {
			padding: 15px 0 35px;
			margin: 0 0 5px 0;
			border-bottom: 1px solid $mdgrey;

			h3 {
				color: $vis-blue;
				cursor: default;
				pointer-events: none;
				padding: 10px 0;

				&::after {
					content: none;
				}
			}

			p {
				padding: 10px 0 0;
			}
		}

		.cp-faq__answer {
			display: block;
			padding: 0;
		}
	}
}

.cp-accordion {
	&__content {
		display: none;
	}

	&__toggle {
		&:focus {
			outline-offset: 3px;
			outline: 1px dotted $red;
		}
	}

	&--active {
		.cp-accordion__toggle {
			&::after {
				transform: rotate3d(1, 0, 0, 180deg);
			}
		}

		.cp-accordion__content {
			display: block;
		}
	}
}

.border-dotted {
	position: relative;
	margin-top: 12px;
	min-height: 9px;
}

.cp-notify {
	position: relative;

	&--pad-top {
		padding: 25px 0 0;
	}

	&.cp-notify--no-border {
		&::after {
			content: none;
		}
	}

	h3,
	h4 {
		@include h5();
		margin: 0;
		padding: 0;
	}

	> p {
		@include p1();
		margin: 0;
		padding: 12px 0 0;
		color: $vis-grey;
		max-width: 100%;
	}

	&__options {
		padding: 7px 0 0;
	}
}

// NGE Specific Components

.nge-points {
	position: relative;
	@include flexbox;
	@include align-items(flex-start);
	@include justify-content(stretch);
	@include flex-wrap(wrap);

	&::after {
		@include dot-border-h(bottom, left, blue);
		bottom: -6px;
		left: -2px;
	}

	&__simple-btn {
		@include p2();
		color: $red;
		display: inline-block;
		padding: 5px;
		margin: 15px 0 0;
		text-align: center;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}

		&:focus {
			font-weight: $medium-weight;
			outline-offset: 3px;
			outline: 1px dotted $red;
		}
	}

	h5 {
		@include s3();
		color: $vis-blue;
		display: block;
		padding: 0 0 5px;
		margin: 0;
		text-transform: uppercase;
	}

	.valid-until {
		@include p2();
		text-align: left;

		@include break-min($small5) {
			position: absolute;
		}

		strong {
			color: $cadet-blue;
		}
	}

	&__label {
		@include p2();
		padding: 0;
		margin: 0;
		display: block;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		padding: 0;

		h1,
		h2 {
			@include h3();
			color: $vis-blue;
			padding: 0 0 5px;
			margin: 0;
		}

		span {
			display: none;
			font-size: 12px;
		}
	}

	&__trophy {
		background: $cadet-blue;
		margin-bottom: 10px;
		padding: 10px 14px;
		position: relative;

		&:after {
			@include dot-border-h(bottom, left, blue);
			left: -2px;
			bottom: -7px;
		}

		img {
			height: 40px;
			margin-right: 15px;
			width: 40px;
		}

		p {
			margin: auto 0;
		}

		&__member-tier {
			color: $white;
			margin: auto 0;

			p:first-child {
				@include type-p3();
			}

			p:nth-child(2) {
				@include s3();
				margin-top: 2px;
				text-transform: uppercase;
			}
		}

		&__status-until {
			color: $white;
			position: absolute;
			right: 14px;
			text-align: right;
		}
	}

	&__number {
		@include font-size(4.7);
		line-height: lh(60px, 47px);
		font-family: $primary;
		font-weight: $bold-weight;
		letter-spacing: -1px;
		color: $vis-grey;
		margin: 10px 0 0;
		padding: 0 0 0 40px;
		text-align: right;
		display: block;
		position: relative;

		&::after {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 36px;
			height: 36px;
		}

		&--total {
			&::after {
				background-image: url("img/cp-icons/icon-cp_points-total.svg");
			}
		}

		&--until {
			&::after {
				background-image: url("img/cp-icons/icon-cp_trophy.svg");
			}
		}
	}

	&__giant {
		@include nge-font();
		color: $red;
		white-space: nowrap;
		display: block;
		padding: 20px 0 30px;
		margin: 0;
		overflow: hidden;
		transition: opacity 0.3s ease;
		opacity: 0;

		&-wrap {
			text-align: center;
			padding: 20px 0;

			h1 {
				@include type-h1();
				@include font-size(8.2);
				margin: 0;

				@include break-min($medium1) {
					@include font-size(11.2);
				}
			}

			p:first-child {
				@include type-p4();
				margin-bottom: 5px;
			}

			.btn {
				width: 100%;
			}
		}

		&--dark-red {
			color: $dkred;
		}

		&--red {
			color: $red;
		}

		&--silver {
			color: $light-grey;
		}

		&--1,
		&--2,
		&--3 {
			opacity: 1;
			@include font-size(12);
		}

		&--4 {
			opacity: 1;
			@include font-size(8);
		}

		&--5,
		&--6 {
			opacity: 1;
			@include font-size(6);
		}

		&--7 {
			opacity: 1;
			@include font-size(4);
		}

		@include break-min($small2) {
			&--1,
			&--2,
			&--3 {
				@include font-size(14);
			}

			&--7 {
				opacity: 1;
				@include font-size(6);
			}
		}

		@include break-min($small3) {
			&--1,
			&--2,
			&--3 {
				@include font-size(16);
			}
		}

		@include break-min($medium2) {
			&--1,
			&--2,
			&--3 {
				@include font-size(16);
			}

			&--4 {
				@include font-size(13);
			}

			&--5 {
				@include font-size(9);
			}

			&--6 {
				@include font-size(8);
			}

			&--7 {
				@include font-size(6);
			}
		}
		// sidebar break
		@include break-min($large1) {
			&--1,
			&--2,
			&--3 {
				@include font-size(12);
			}

			&--4 {
				@include font-size(8);
			}

			&--5 {
				opacity: 1;
				@include font-size(7);
			}

			&--6 {
				opacity: 1;
				@include font-size(5);
			}

			&--7 {
				@include font-size(4);
			}

			.btn {
				width: auto;
			}
		}

		@include break-min($large2) {
			&--1,
			&--2,
			&--3 {
				@include font-size(14);
			}

			&--4 {
				@include font-size(11);
			}

			&--5 {
				opacity: 1;
				@include font-size(8.5);
			}

			&--6 {
				opacity: 1;
				@include font-size(7);
			}

			&--7 {
				@include font-size(5);
			}
		}

		@include break-min(1100px) {
			&--1,
			&--2,
			&--3 {
				@include font-size(16);
			}

			&--4 {
				@include font-size(12);
			}

			&--7 {
				@include font-size(6.6);
			}
		}
	}

	&__block {
		position: relative;
		@include flex(1 0 100%);

		.cfaOne-link {
			@include type-p2();
			bottom: -65px;
			position: absolute;
		}
	}

	&__row {
		position: relative;
		padding: 25px 0 20px;

		&--flex {
			@include flexbox;
			@include align-items(stretch);
			@include justify-content(stretch);
		}

		&::after {
			@include dot-border-h(bottom);
			width: auto;
		}
	}

	&__item {
		position: relative;
		padding: 0 25px 0 0;
		@include flex(1 1 50%);

		&:last-child {
			padding: 0 0 0 25px;

			&::after {
				content: "";
				position: absolute;
				top: -7px;
				bottom: -5px;
				left: -6px;
				width: 12px;
				background-image: url("img/cp-icons/icon-cp_dot-gray-alt.svg");
				background-size: 12px 12px;
				background-repeat: repeat-y;
				background-position: center top;
			}
		}
	}

	&__details {
		padding: 0;

		a {
			@include type-p2();
			display: block;
			margin: 25px 0;
			text-align: center;
		}
	}

	@include break-min($small5) {
		padding: 10px 0 50px;
		@include flex-wrap(nowrap);

		&__details {
			@include flex(0 3 325px);
		}

		&__block {
			@include flex(1 0 150px);

			@include flexbox;
			@include align-items(stretch);
			@include justify-content(flex-start);

			&:first-child {
				min-width: 400px;
				padding: 0 0 0 5px;

				&::after {
					content: "";
					position: absolute;
					height: 99%;
					top: 13px;
					bottom: 16px;
					right: 15px;
					width: 12px;
					background-image: url("img/cp-icons/icon-cp_dot-gray-alt.svg");
					background-size: 12px 12px;
					background-repeat: repeat-y;
					background-position: center bottom;
				}
			}

			&:last-child {
				margin: auto 0 auto 14px;
			}
		}

		&__row {
			&::after {
				left: -4px;
			}
		}

		&__giant {
			&-wrap {
				padding: 25px 55px 15px 0;
				width: 100%;
			}
		}
	}

	@include break-min($medium1) {
		&__header {
			span {
				display: flex;
			}
		}
	}

	@include break-min($large1) {
		&__header {
			position: relative;
			padding: 0;
			margin: 0 0 12px;

			h1,
			h2 {
				padding: 0;
			}
		}

		&__details {
			a {
				margin: 10px 0 0 0;
				text-align: left;
			}
		}

		&__label {
			&--right {
				position: absolute;
				right: 0;
				bottom: 8px;
			}
		}
	}
}

.nge-statbar {
	text-align: center;
	padding: 25px 0;
	position: relative;

	&__s3 {
		@include s3();
		text-transform: uppercase;
		color: $vis-blue;
		display: block;
	}

	p {
		@include font-size(4.7);
		line-height: lh(60px, 47px);
		font-family: $primary;
		font-weight: $bold-weight;
		letter-spacing: -1px;
		color: $vis-grey;
		margin: 0;
		padding: 9px 0 6px;
		display: block;
	}

	&__label {
		@include p2();
		padding: 0;
		margin: 0;
		display: block;
	}

	.btn.btn--vis {
		margin: 20px 0 0 0;
	}

	@include break-min($large1) {
		text-align: left;
		padding: 0 250px 20px 0;

		p {
			padding: 5px 0 0;
		}

		.btn.btn--vis {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}

.nge-text {
	// sass-lint:disable-block max-line-length
	position: relative;
	padding: 40px 0;
	text-align: center;

	&--no-pad {
		&-bot {
			padding-bottom: 0;
		}
	}

	.btn.btn--vis {
		margin: 25px 0 0;
	}

	h3 {
		max-width: 470px;
		margin: 0 auto;
		padding: 0 0 15px;
		display: block;
		@include h5();
	}

	h1,
	h2 {
		@include h3();
		color: $vis-blue;
		padding: 0 0 10px;
		margin: 0;
	}

	p {
		@include p1();
		max-width: 470px;
		margin: 0 auto;
		padding: 0;
		display: block;
		color: $vis-grey;

		a {
			font-weight: $medium-weight;
		}

		+ p {
			padding: 20px 0 0;
		}
	}

	&__flex-wrap {
		@include flexbox();
		@include justify-content(stretch);
		@include align-items(stretch);
		@include flex-wrap(wrap);
	}

	&__block {
		@include flex(1 0 100%);

		&--small {
			@include flex(1 0 auto);
		}
	}

	@media screen and (min-width: $medium3) and (max-width: $large1),
		screen and (min-width: $large4) {
		text-align: left;

		p,
		h3 {
			margin: 0;
		}

		&__block {
			@include flex(1 0 470px);

			&--small {
				@include flex(1 0 auto);

				@include flexbox();
				@include justify-content(flex-end);
				@include align-items(center);
			}
		}
	}

	&--align-left {
		text-align: left;

		p {
			margin: 0;
		}
	}
}

.nge-card {
	@include flex(1 0 100%);
	min-width: 170px;
	cursor: pointer;

	&--disabled {
		opacity: 0.6;

		.nge-card {
			&__title {
				padding-bottom: 5px;

				@include break-min($large1) {
					padding-bottom: 8px;
				}
			}
		}
	}

	+ .nge-pointcards__section-title {
		padding-top: 30px;
	}

	&__grid {
		margin: 0 -8px;

		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(stretch);
		@include flex-wrap(wrap);
	}

	&__pad {
		padding: 8px;
		height: 100%;
	}

	&__content {
		background-color: $white;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		height: 100%;
		position: relative;

		@include flexbox();
		@include justify-content(stretch);
		@include align-items(stretch);
		@include flex-wrap(wrap);
		@include flex-direction(column);
	}

	&__icon-img {
		position: absolute;
		right: 0;
		top: 10px;
	}

	&__img {
		display: block;
		height: auto;
		width: 100%;

		&-wrap {
			padding: 10px 30px 10px;
			width: 100%;
			height: auto;
			@include flex(1 1 auto);
		}
	}

	&__title {
		text-align: center;
		display: block;
		margin: 0;
		padding: 0 16px;
		color: $vis-blue;
		@include nge-card-title();
		@include flex(1 1 auto);
	}

	&__bar {
		width: 100%;
		padding: 5px 12px 15px 12px;
		@include flex(0 0 auto);

		@include flexbox();
		@include justify-content(center);
		@include align-items(stretch);
		@include flex-wrap(wrap);
		@include align-self(flex-end);

		&-item {
			@include flex(1 1 auto);
			padding: 8px 4px;
		}
	}

	&__btn {
		cursor: pointer;
		margin: 0;
		background-color: $white;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
		border-radius: 15px;
		display: block;
		width: 100%;
		padding: 2px 7px;
		text-align: center;
		white-space: nowrap;
		color: $red;
		transition: transform 0.2s ease, box-shadow 0.2s ease;
		@include p2();

		&:focus {
			outline-offset: 3px;
			outline: 1px dotted $red;
			font-weight: $medium-weight;
		}

		&:hover {
			transform: translateY(-1px);
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
		}
	}

	&__points {
		z-index: $z3;
		position: relative;
		color: $vis-grey;
		padding: 3px 5px;
		font-family: $primary;
		font-weight: $medium-weight;
		line-height: 1.7;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		@include font-size(1.4);

		span {
			margin-left: 5px;
		}

		p {
			font-family: $primary;
			font-weight: $medium-weight;
			line-height: 1.7;
			padding: 0;
			margin: 0;
			@include font-size(1.4);
		}

		&-ada-label {
			opacity: 0;
			font-size: 1px;
			position: absolute;
		}
	}

	@include break-min(374px) {
		@include flex(0 0 50%);
	}

	@include break-min($small5) {
		@include flex(0 0 33.3333%);
		@include flex(0 0 calc(100% / 3));

		&__pad {
			padding: 12px;
		}

		&__grid {
			margin: 0 -12px;
		}
	}

	@include break-min($large1) {
		@include flex(0 0 50%);

		&__pad {
			padding: 8px;
		}

		&__grid {
			margin: 0 -8px;
		}
	}

	@include break-min($large2) {
		@include flex(0 0 33.3333%);
		@include flex(0 0 calc(100% / 3));

		&__pad {
			padding: 12px;
		}

		&__grid {
			margin: 0 -12px;
		}
	}
}

.nge-message {
	background-color: $warm-white;
	border-radius: 8px;
	padding: 30px 20px;
	margin: 15px 0 30px;
	text-align: center;

	&__icon {
		background-image: url("img/cp-icons/icon-cp_warning.svg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		margin: auto;
		width: 48px;
		height: 48px;
	}

	&__inner {
		max-width: 576px;
		margin: 0 auto;
	}

	h4 {
		@include h4();
		margin: 0;
		padding: 20px 0 0;
		color: $vis-blue;
	}

	p {
		@include p1();
		padding: 20px 0 0;
		margin: 0;
	}

	@include break-min($medium1) {
		padding: 45px 20px;
	}
}

.nge-reward {
	border-radius: 8px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
	padding: 0 5px;
	margin: 0;
	overflow: hidden;
	background-color: $red;

	// grid and wrapper styles
	&__wrap {
		position: relative;
		padding: 40px 0;

		&-heading {
			position: relative;
			padding: 0 0 30px;

			h1,
			h2 {
				@include h3();
				color: $vis-blue;
				padding: 0;
				margin: 0;
			}

			.cp-form__select {
				margin: 25px 0 0;
				min-width: 215px;
			}

			@include break-min($small3) {
				min-height: 98px;

				@include flexbox();
				@include justify-content(space-between);
				@include align-items(flex-end);
				@include flex-wrap(wrap);

				> * {
					@include flex(0 0 auto);
				}

				h1,
				h2 {
					padding: 0 20px 0 0;
					margin: 0 0 -12px;
				}

				.cp-form__select {
					margin: 0;
				}
			}
		}

		&-footer {
			padding: 35px 0 0;
			text-align: center;
		}

		&--past {
			height: 0;
			overflow: hidden;
			padding: 0;
		}
	}

	&__grid {
		margin: 0 -8px;

		@include flexbox();
		@include justify-content(center);
		@include align-items(center);
		@include flex-wrap(wrap);

		&-item {
			padding: 8px;
			max-width: 390px;
			@include flex(0 0 100%);
		}

		@include break-min($small5) {
			@include justify-content(space-between);

			&-item {
				padding: 16px 8px;
				max-width: 376px;
				@include flex(0 0 50%);
			}
		}

		@include break-min($large1) {
			&-item {
				@include flex(0 0 100%);
			}
		}

		@include break-min($large2) {
			&-item {
				@include flex(0 0 50%);
			}
		}
	}

	// card styles
	&__inner {
		background-color: $white;
		padding: 10px 15px;
	}

	&__header {
		position: relative;
		padding: 0 0 0 60px;
	}

	&__title {
		@include h5();
		padding: 0;
		margin: 0;
		color: $vis-blue;
		display: block;
	}

	&__subtitle {
		@include p2();
		padding: 0;
		margin: 0;
		display: block;
	}

	&__icon {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: -2px;
		background-color: $red;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		img,
		svg {
			width: 100%;
			height: auto;
			display: block;
		}

		img {
			border-radius: 50%;
			height: 48px;
		}
	}

	&__img {
		display: block;
		height: auto;
		width: 100%;
		max-width: 350px;
		margin: 0 auto;

		&-wrap {
			padding: 10px 0;
		}
	}

	&__details {
		text-align: center;
		padding: 0 0 35px 0;

		.btn.btn--vis {
			display: inline-block;
			width: auto;
		}
	}

	&__footer {
		padding: 20px 0 10px;
		position: relative;
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);
		@include flex-wrap(wrap);

		&::after {
			@include dot-border-h(top, center);
		}

		&-item,
		> * {
			@include flex(0 0 auto);
		}

		p,
		a {
			@include p2();
			padding: 0;
			margin: 0;
			display: inline-block;
		}

		a {
			@include p2();
			font-weight: $medium-weight;
			color: $red;

			&:hover {
				text-decoration: underline;
			}

			&:focus {
				font-weight: $medium-weight;
				outline-offset: 3px;
				outline: 1px dotted $red;
			}
		}

		p {
			margin: 0 0 0 auto;
		}
	}

	&__product-title {
		@include p1();
		padding: 0 0 15px;
		margin: 0;
	}

	// color modifiers & icons
	&--cfa {
		background-color: $red;

		.nge-reward__icon {
			background-color: $white;
			background-image: url("img/cp-icons/icon-cp_cfa-circle.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}

	&--gift {
		background-color: $red;

		.nge-reward__icon {
			background-color: $cupid;
			background-image: url("img/cp-icons/icon-cp_gift.svg");
		}
	}

	&--star {
		background-color: $keppel;

		.nge-reward__icon {
			background-color: $aqua-island;
			background-image: url("img/cp-icons/icon-cp_star.svg");
		}
	}

	&--operator {
		background-color: $salmon;

		.nge-reward__icon {
			background-color: lighten($salmon, 15%);
			background-image: url("img/cp-icons/icon-cp_operator.svg");
		}
	}

	&--cow {
		background-color: $cannon-pink;

		.nge-reward__icon {
			background-color: $vanilla-ice;
			background-image: url("img/cp-icons/icon-cp_cow.svg");
		}
	}

	&--recovery {
		background-color: $apricot-peach;

		.nge-reward__icon {
			background-color: $apricot-peach;
			background-image: url("img/cp-icons/icon-cp_recovery.svg");
		}
	}
}

// News cards
@import "news-card";

// modal specific classes/components
.nge-tiers {
	&__block {
		max-width: 460px;
		margin: 0 auto;
		padding: 20px 0;
	}

	&__circle {
		border-radius: 100%;
		width: 124px;
		height: 124px;
		margin: 0 auto;

		&--darkblue {
			// background-color: $dkred;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			// sass-lint:disable max-line-length
			background-image: url("img/olo/membership-tier-logos/signature-tier-logo.png");
		}

		&--dark-red {
			// background-color: $dkred;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url("img/olo/membership-tier-logos/red-tier-logo.png");
		}

		&--red {
			// background-color: $red;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url("img/olo/membership-tier-logos/one-tier-logo.png");
		}

		&--silver {
			// background-color: $light-grey;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url("img/olo/membership-tier-logos/silver-tier-logo.png");
		}
	}

	h4 {
		@include h5();
		color: $vis-grey;
		padding: 22px 0 15px 0;
		margin: 0;
		display: block;
	}

	p {
		@include p2();
	}
}

.nge-qr {
	.cp-lightbox__inner {
		max-width: 600px;
		padding: 60px 42px 80px;
	}

	.close-btn {
		@include lightbox-close-btn();
		top: 10px;
		right: 10px;
	}

	p {
		line-height: calc(28 / 18);
		padding: 0 0 25px;
	}

	&__wrap {
		max-width: 700px;
		margin: 0 auto;
		padding: 0 5px 0;
	}

	h2 {
		@include font-size(3.6);
		letter-spacing: -0.5px;
		line-height: calc(42 / 36);
		margin-top: 15px;
	}

	&__type {
		@include s2();
		min-height: 50px;
		color: $vis-blue;
		text-transform: uppercase;
		display: inline-block;
		margin: 0 auto;
		position: relative;
		padding: 20px 0 20px 55px;
		text-align: left;

		&::before {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			content: "";
			position: absolute;
			left: 0;
			top: -2px;
			bottom: 0;
			margin: auto;
			width: 36px;
			height: 36px;
			border-radius: 50%;
		}

		&--cfa {
			&::before {
				background-image: url("img/cp-icons/icon-cp_cfa-circle.svg");
			}
		}

		&--gift {
			&::before {
				background-color: $cupid;
				background-image: url("img/cp-icons/icon-cp_gift.svg");
			}
		}

		&--cow {
			&::before {
				background-color: $vanilla-ice;
				background-image: url("img/cp-icons/icon-cp_cow.svg");
			}
		}

		&--recovery {
			&::before {
				background-color: $apricot-peach;
				background-image: url("img/cp-icons/icon-cp_recovery.svg");
			}
		}

		&--star {
			&::before {
				background-color: $aqua-island;
				background-image: url("img/cp-icons/icon-cp_star.svg");
			}
		}

		&--operator {
			&::before {
				background-color: $salmon;
				background-image: url("img/cp-icons/icon-cp_operator.svg");
			}
		}
	}

	&__img {
		display: block;
		height: auto;
		width: 100%;

		&-wrap {
			max-width: 300px;
			margin: 0 auto;
			padding: 10px 30px 20px 30px;
		}
	}

	&__number {
		@include font-size(2.4);
		display: block;
		color: $red;
		font-weight: bold;
		padding: 5px 0;
		margin: 0 0 16px;
		letter-spacing: -0.01em;
		line-height: calc(32 / 24);
	}

	.btn.btn--vis {
		margin: 10px 0 30px;
	}

	.btn-container {
		.btn-round {
			&:nth-child(2) {
				@extend .btn-round--white;
				margin-left: 16px;
			}
		}
	}

	@include break-min($medium1) {
		&__type {
			padding: 20px 0 20px 70px;

			&::before {
				width: 50px;
				height: 50px;
			}
		}

		&__img {
			&-wrap {
				padding: 0 30px 20px;
			}
		}
	}
}

.nge-redeem {
	.cp-lightbox__inner {
		max-width: 1176px;
	}

	h2 {
		padding: 0;
	}

	p {
		padding: 20px 0 0;
	}

	&__wrap {
		max-width: 920px;
		margin: 0 auto;
	}

	&__img-wrap {
		position: relative;
		padding: 10px 60px;

		@include break-min($medium1) {
			padding: 10px 0;
		}
	}

	&__img {
		position: relative;
		z-index: $z1;
		display: block;
		height: auto;
		width: 100%;
	}

	&__reward {
		max-width: 440px;
		margin: 0 auto;
		padding: 0 0 20px;

		&-title {
			font-size: 28px;
			line-height: 24px;
			font-weight: 700;
			color: $vis-blue;
			margin: 0;
			padding: 10px;
		}

		p {
			visibility: visible;
			@include h5();
		}
	}

	&__ctas {
		@include flexbox();
		@include align-items(center);
		@include flex-wrap(wrap);
		@include flex-direction(column);
		max-width: 280px;
		margin: 0 auto;

		.btn {
			&.btn--vis {
				width: 100%;
				margin: 10px 0;
			}
		}

		.btn-no-background {
			height: auto;
			padding: 15px 19px;
			line-height: 1;
			font-weight: 600;
		}
	}

	&--success {
		.cp-lightbox__inner {
			max-width: 936px;
		}

		.nge-redeem {
			&__wrap {
				max-width: 696px;
			}

			&__img-wrap {
				&::after {
					background-image: url("img/cp-icons/icon-cp_checkmark.svg");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 53px;
					height: 53px;
					z-index: $z3;
				}
			}
		}
	}
}

.nge-colormodal {
	.cp-lightbox {
		&__inner {
			max-width: 940px;
			background-color: $salmon;
			padding: 52px 5px 0;
			position: relative;
		}

		&__close {
			height: 52px;

			&:focus {
				outline: 1px dotted white;
			}
		}
	}

	img {
		display: block;
		height: auto;
		width: 100%;
	}

	&--red {
		.cp-lightbox__inner {
			background-color: $red;
		}
	}

	&--green {
		.cp-lightbox__inner {
			background-color: $keppel;
		}
	}

	&--peach {
		.cp-lightbox__inner {
			background-color: $salmon;
		}
	}

	&--purple {
		.cp-lightbox__inner {
			background-color: $cannon-pink;
		}
	}

	&--pink {
		.cp-lightbox__inner {
			background-color: $apricot-peach;
		}
	}

	&--gift {
		.cp-lightbox__inner {
			background-color: $red;
		}
	}

	&__label {
		position: absolute;
		top: 0;
		right: 60px;
		left: 60px;
		height: 52px;
		@include flexbox();
		@include justify-content(center);
		@include align-items(center);

		&-text {
			text-transform: uppercase;
			color: $white;
			display: inline-block;
			padding: 3px 0 0;
			@include s3();
			@include flex(1 1 auto);
		}
	}

	&__content {
		position: relative;
		background-color: $white;
		padding: 70px 12px 75px;
	}

	&__textwrap {
		max-width: 700px;
		margin: 0 auto;
	}

	&__circle {
		width: 51px;
		height: 51px;
		overflow: hidden;
		border-radius: 50%;
		position: absolute;
		top: -6px;
		right: 0;
		left: 0;
		bottom: 0;
		margin: 0 auto;

		&--gift {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: $cupid;
			background-image: url("img/cp-icons/icon-cp_gift.svg");
		}
	}

	@include break-min($medium3) {
		.cp-lightbox {
			&__inner {
				padding: 70px 5px 0;
			}

			&__close {
				height: 60px;
				top: 5px;
			}
		}

		&__label {
			height: 70px;
		}

		&__content {
			padding: 100px 10px 90px;
		}

		&__circle {
			width: 92px;
			height: 92px;
			top: -16px;
		}
	}
}

.nge-gifting {
	max-width: 700px;
	margin: 0 auto;

	h5 {
		margin: 0;
		padding: 0 0 10px;
		display: block;
		color: $vis-grey;
		@include p1();
	}

	&--success {
		.nge-gifting {
			&__img-wrap {
				&::after {
					background-image: url("img/cp-icons/icon-cp_checkmark.svg");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 53px;
					height: 53px;
					z-index: $z3;
				}
			}

			&__title {
				padding-bottom: 35px;
			}
		}
	}

	.btn.btn--vis {
		&.mfp-close {
			background-color: $red;
		}
	}

	&__img {
		position: relative;
		z-index: $z1;
		display: block;
		height: auto;
		width: 100%;
		max-width: 320px;
		margin: 0 auto;

		&-wrap {
			position: relative;
			padding: 10px 30px;
		}
	}

	&__title {
		@include h5();
		color: $vis-blue;
		margin: 0;
		padding: 10px 0 0;
	}

	&__block {
		position: relative;
		padding: 45px 0;

		&--bordered {
			&::after {
				@include dot-border-h(bottom, center);
			}

			&::before {
				@include dot-border-h(top, center);
			}
		}

		&--contain {
			max-width: 460px;
			margin: 0 auto;
		}

		&--nopad-top {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}

	&__gifter {
		text-align: center;
		position: relative;
		padding: 0 0 30px;
		margin: 0 auto;
		max-width: 460px;

		&-circle {
			margin: 10px auto;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
			}
		}

		h4 {
			@include h5();
			color: $vis-blue;
			margin: 0;
			padding: 0;
		}
	}

	&__review {
		text-align: left;
		max-width: 460px;
		margin: 0 auto;
		padding: 10px 0 0;
		position: relative;

		&-edit {
			position: absolute;
			right: 0;
			top: 3px;
			color: $red;
			cursor: pointer;
			@include p2();

			&:hover {
				color: $dkred;
				text-decoration: underline;
			}

			&:focus {
				outline: 1px dotted $red;
				outline-offset: 3px;
				font-weight: $medium-weight;
			}
		}

		h6 {
			padding: 38px 0 15px 0;
			margin: 0;
			color: $vis-blue;
			text-transform: uppercase;
			@include s3();

			&:first-child {
				padding-top: 0;
			}
		}

		p {
			padding: 0;
		}
	}

	// gifting form
	.cp-form {
		padding: 0;
		max-width: 460px;
		margin: 0 auto;

		p {
			@include p2();
			color: $light-grey;
			padding: 10px 0 0;
			margin: 0;
			font-weight: $medium-weight;
			max-width: 340px;
			display: block;

			&.error {
				color: $red;
			}
		}

		&__group {
			margin: 0 0 25px;

			&:last-child {
				margin: 0;
			}

			&--input {
				max-width: 100%;
			}

			&--error {
				p {
					color: $red;
				}
			}
		}
	}
}

// utils
.mobile-detect {
	position: relative;
	z-index: $z1;

	@include break-min($large1) {
		z-index: $z2;
	}
}

.dot-border {
	&-bot {
		&::after {
			@include dot-border-h(bottom);
		}
	}

	&-top {
		&::before {
			@include dot-border-h(top);
		}
	}
}

// Browser stuff

.ie {
	.cp-block {
		&__flex {
			&-item {
				@include flex(1 1 100%);
			}
		}
	}

	.nge-card {
		&__img-wrap {
			@include flex(0 0 auto);
		}
	}

	.nge-points__block {
		&:first-child {
			@include flex(0 0 auto);
		}
	}
}
