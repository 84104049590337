.status-card {
	.progress {
		img {
			margin-right: 10px;
		}
	}

	&.basic-member {
		.progress {
			background-color: $membership-silver;
		}
	}

	&.silver-member {
		.progress {
			background-color: $red;
		}
	}

	&.red-member {
		.progress {
			background-color: $blue;
		}
	}

	&.signature-member {
		.progress {
			background-color: $blue;
		}
	}
}
