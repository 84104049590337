.nge-reward {
	background-color: $red;
	border-radius: 8px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
	margin: 0;
	overflow: hidden;
	padding: 0 5px;

	// grid and wrapper styles
	&__wrap {
		position: relative;

		&-heading {
			position: relative;
			padding: 0 0 30px;

			h1,
			h2 {
				@include h3();
				color: $vis-blue;
				padding: 00;
				margin: 0;
			}

			.cp-form__select {
				margin: 25px 0 0;
				min-width: 215px;
			}

			@include break-min($small3) {
				min-height: 98px;

				@include flexbox();
				@include justify-content(space-between);
				@include align-items(flex-end);
				@include flex-wrap(wrap);

				> * {
					@include flex(0 0 auto);
				}

				h1,
				h2 {
					padding: 0 20px 0 0;
					margin: 0 0 -12px;
				}

				.cp-form__select {
					margin: 0;
				}
			}
		}

		&-footer {
			padding: 35px 0 0;
			text-align: center;
		}
	}

	&__grid {
		margin: 0 -8px;

		@include flexbox();
		@include justify-content(center);
		@include align-items(center);
		@include flex-wrap(wrap);

		&-item {
			padding: 8px;
			max-width: 390px;
			@include flex(0 0 100%);
		}

		@include break-min($small5) {
			@include justify-content(space-between);

			&-item {
				padding: 16px 8px;
				max-width: 376px;
				@include flex(0 0 50%);
			}
		}

		@include break-min($large1) {
			&-item {
				@include flex(0 0 100%);
			}
		}

		@include break-min($large2) {
			&-item {
				@include flex(0 0 50%);
			}
		}
	}

	// card styles
	&__inner {
		background-color: $white;
		padding: 10px 15px;
	}

	&__header {
		position: relative;
		padding: 0 0 0 60px;
	}

	&__title {
		@include h5();
		padding: 0;
		margin: 0;
		color: $vis-blue;
		display: block;
	}

	&__subtitle {
		@include p2();
		padding: 0;
		margin: 0;
		display: block;
	}

	&__icon {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: -2px;
		background-color: $red;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		img,
		svg {
			width: 100%;
			height: auto;
			display: block;
		}
	}

	&__img {
		display: block;
		height: auto;
		width: 100%;
		max-width: 350px;
		margin: 0 auto;

		&-wrap {
			padding: 10px 0;
		}
	}

	&__details {
		text-align: center;
		padding: 0 0 35px 0;

		.btn.btn--vis {
			display: inline-block;
			width: auto;
		}
	}

	&__footer {
		padding: 20px 0 10px;
		position: relative;
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);
		@include flex-wrap(wrap);

		&::after {
			@include dot-border-h(top, center);
		}

		&-item,
		> * {
			@include flex(0 0 auto);
		}

		p,
		a {
			@include p2();
			padding: 0;
			margin: 0;
			display: inline-block;
		}

		a {
			@include p2();
			font-weight: $medium-weight;
			color: $red;

			&:hover {
				text-decoration: underline;
			}

			&:focus {
				font-weight: $medium-weight;
				outline-offset: 3px;
				outline: 1px dotted $red;
			}
		}

		p {
			margin: 0 0 0 auto;
		}
	}

	&__product-title {
		@include p1();
		padding: 0 0 15px;
		margin: 0;
	}

	// color modifiers & icons
	&--cfa {
		background-color: $red;

		.nge-reward__icon {
			background-color: $white;
			background-image: url("img/cp-icons/icon-cp_cfa-circle.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}

	&--gift {
		background-color: $red;

		.nge-reward__icon {
			background-color: $cupid;
			background-image: url("img/cp-icons/icon-cp_gift.svg");
		}
	}

	&--star {
		background-color: $keppel;

		.nge-reward__icon {
			background-color: $aqua-island;
			background-image: url("img/cp-icons/icon-cp_star.svg");
		}
	}

	&--operator {
		background-color: $salmon;

		.nge-reward__icon {
			background-color: lighten($salmon, 15%);
		}
	}

	&--cow {
		background-color: $cannon-pink;

		.nge-reward__icon {
			background-color: $vanilla-ice;
			background-image: url("img/cp-icons/icon-cp_cow.svg");
		}
	}

	&--recovery {
		background-color: $apricot-peach;

		.nge-reward__icon {
			background-color: $apricot-peach;
			background-image: url("img/cp-icons/icon-cp_recovery.svg");
		}
	}
}
