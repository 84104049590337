// GLOBAL Boxes
// ==========================================================================

.g-video {
	&__intrinsic {
		position: relative;
		overflow: hidden;
		padding: 56.25% 0 0;
	}

	&__wrap {
		padding: 0 26px;

		@include break-min($small4) {
			padding: 0;
		}
	}

	&__inner {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 0;
		transition: opacity 0.3s ease;

		.video-js {
			width: 100%;
			height: 100%;

			.vjs-big-play-button {
				display: none;
			}
		}
	}

	&__play-btn {
		display: block;
		opacity: 1;
		transition: opacity 0.3s ease;
		z-index: 1;
		fill: $white;
		pointer-events: all;
		cursor: pointer;
		width: 23px;
		height: 32px;

		@include break-min($small4) {
			width: 72px;
			height: 96px;
		}

		&:hover {
			fill: $red;
		}

		@include focus($red);

		&-wrap {
			position: relative;
			height: 100%;
			width: 100%;

			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
		}

		&-absol-center-align {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
		}
	}

	&__overlayIcon {
		bottom: 25px;
		left: 49%;
		position: absolute;
		transform: translateX(-50%);
		width: 120px;

		@include break-min($large1) {
			bottom: 50px;
			width: 220px;
		}
	}

	&__overlay {
		opacity: 1;
		pointer-events: none;
		transition: opacity 0.3s ease;
		background-image: linear-gradient(178deg, rgba(0, 0, 0, 0), #000);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&--playing {
		.g-video__overlay {
			opacity: 0;
		}

		.g-video__play-btn {
			opacity: 0;
			pointer-events: none;
		}

		@include break-min($small4) {
			.g-carousel-grid__slide-body {
				opacity: 0;
				pointer-events: none;
			}
		}
	}

	&--4-3 {
		padding: 75% 0 0;
	}
}
