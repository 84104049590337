.header-img-callout {
	&.flush-img {
		@include break-min($large1) {
			height: auto;
			justify-content: space-between;
			padding: 0 0 0 50px;
		}

		@include break-min($huge2) {
			padding: 0 0 0 250px;
		}
	}
}
