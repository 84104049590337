.three-column-with-headline {
	text-align: center;
	padding: 100px 0;

	@include break-min($medium3) {
		padding: 30px 0;
	}

	.three-column-headline {
		margin: 0 auto;
		margin-bottom: 70px;

		@include break-min($medium3) {
			width: 40%;
			margin-bottom: 0;
		}

		h2 {
			@include font-size(2.8);
			color: $blue;
			font-family: $primary;
			font-weight: 700;
			letter-spacing: -0.5px;
			margin: 2rem 0;
			line-height: 34px;

			@include break-min($medium3) {
				@include font-size(3.6);
				line-height: 42px;
			}
		}

		P {
			line-height: 28px;
		}
	}

	ul {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 0;

		@include break-min($medium3) {
			margin: 80px 0;
		}

		li {
			width: 100%;
			padding: 0;
			padding-bottom: 30px;

			&:last-child {
				padding-bottom: 0;
			}

			@include break-min($large1) {
				width: 33.3333%;
				padding: 0 5%;
			}

			span {
				color: $white;
				background-color: $light-teal;
				border-radius: 50%;
				font-size: 24px;
				font-weight: 700;
				padding: 9px 19px;
				line-height: 0;
			}

			h4 {
				@include font-size(2.4);
				color: $blue;
				font-weight: 700;
			}

			P {
				line-height: 28px;
			}
		}
	}
}
