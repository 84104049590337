.headliner {
	.headliner-top-hero,
	.headline {
		a {
			color: $blue;

			&:hover {
				color: lighten($blue, 10%);
			}
		}
	}

	.invisible-h1 {
		display: none;
	}
}

.headliner-top-hero {
	position: relative;

	img {
		width: 100%;
	}

	.cs {
		left: 25px;
		position: absolute;
		top: 25px;
		max-width: 200px;
		width: $col12;

		@media only screen and (max-width: $mobile) {
			left: 5px;
			top: 15px;
			width: 20%;
		}
	}

	.play {
		left: 50%;
		max-width: 96px;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: auto;

		@media only screen and (max-width: $mobile) {
			width: 20%;
		}
	}

	@include animated;
}

.headline-cards {
	display: flex;
	//font-weight: 600;
	margin-bottom: -2px;

	@media only screen and (max-width: $mobile) {
		flex-direction: column;
	}

	.card {
		border: 2px solid #ededed;
		border-right-color: transparent;
		font-family: $primary;
		line-height: 1.5;
		padding: 30px;
		width: $col4;

		@media only screen and (max-width: $mobile) {
			width: auto;
		}

		a {
			color: $blue;
		}

		&.has-link {
			&:hover {
				cursor: pointer;
			}
		}

		&.image {
			background-position: center center;
			background-size: cover;
			position: relative;

			picture {
				opacity: 0;
			}
		}

		&:last-child {
			border-right-color: #ededed;
		}

		h3 {
			font-weight: 600;
		}

		.image-headline {
			@include font-size(2.2);
			font-weight: 600;
			line-height: 34px;
			margin: 15px 0 10px;
		}

		&.tag {
			font-weight: 600;
		}

		&.text-only {
			align-items: center;
			display: flex;

			.content {
				width: $col12;
			}
		}

		&.centered {
			text-align: center;
		}

		&:first-child:nth-last-child(2),
		&:first-child:nth-last-child(2) ~ .card {
			width: $col6;

			@media only screen and (max-width: $mobile) {
				width: auto;
			}
		}

		img {
			width: 100%;
		}

		@include animated;

		.play-button {
			height: 0;
			padding-bottom: 56.25%;
			overflow: hidden;
		}
	}

	.textbox-headline {
		@include font-size(4.7);
		color: $blue;
		letter-spacing: -1px;
		line-height: 1.276595745;
	}

	.play {
		left: 50%;
		max-width: 96px;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: auto;

		@media only screen and (max-width: $mobile) {
			width: 20%;
		}
	}

	.play-button {
		position: relative;

		.play {
			width: 20%;
		}
	}
}

.headline {
	@include font-size(2.3);
	@extend .wrapper;
	font-family: $primary;
	font-weight: 600;
	max-width: $col8;
	padding: 40px 0;

	&.centered {
		text-align: center;
	}

	@media only screen and (max-width: $mobile) {
		max-width: none;
		padding: 40px 30px;
		width: auto;
	}
}

.headline-lead {
	@include font-size(7.8);
	color: $blue;
	font-weight: 600;
	margin: 0 0 20px;

	@media only screen and (max-width: $mobile) {
		@include font-size(4);
	}
}

.sr-text {
	@include visuallyhidden();
}

.animated {
	.paused-image {
		display: none;
	}

	&.paused {
		.paused-image {
			display: block;
		}

		.animated-image {
			display: none;
		}
	}
}
