$right-aligned-column-gap: 0 40px 0 60px;

@mixin ie-grid-fix {
	@include break-min($large1) {
		@include ie-only() {
			margin-left: 350px;
		}
	}
}

.gift-container {
	--row-number: 4;
	background: $white;
	border-left: 8px solid $red;
	border-right: 8px solid $red;
	padding: 24px 16px 0px;

	@include break-min($large1) {
		border-left: 12px solid $red;
		border-right: 12px solid $red;
		display: grid;
		grid-template-columns: 40% 60%;
		max-width: 1000px;
		padding: 0;

		//Fix b/c of Grid Layout on IE
		@include ie-only() {
			display: inline-block;
			position: relative;
		}
	}

	&.star {
		border-color: $keppel;
	}

	&.cow {
		border-color: $cannon-pink;
	}

	&.recovery {
		border-color: $apricot-peach;
	}

	&.operator,
	&.has-image {
		border-color: $salmon;
	}

	&.gift {
		border-color: $red;
	}

	&.clock {
		border-color: $light-red;
	}
}
