.codemoo-video-callout {
	&-header {
		text-align: center;
		max-width: 750px;
		margin: 0 auto;
		padding: 30px 0 26px;

		@include break-min($large1) {
			padding: 90px 0 50px;
		}

		h2 {
			color: $blue;
			@include font-size(2.4);
			font-family: $primary;
			font-weight: $bold-weight;

			@include break-min($large1) {
				@include font-size(4.8);
			}
		}
	}
	.long-form-video {
		// margin: 0;
		border-radius: 0;

		.video-container {
			border-radius: 0;

			@include break-min($large1) {
				border-radius: 16px;
			}
		}

		@include break-min($large1) {
			border-radius: 16px;
			// margin-left: 32px;
			// margin-right: 32px;
		}

		&.overlay-view {
			@include break-max($small4) {
				margin: 0;
			}
			.video-container {
				height: 610px;
			}
		}
	}
	.video-container {
		.player-wrapper {
			.overlay {
				display: block;
			}
		}

		&.isPlaying {
			.player-wrapper {
				.overlay {
					display: none;
				}
			}
		}
	}
}
