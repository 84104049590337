.store-menu {
	margin: 0;
	display: none;
	width: 100%;

	a {
		margin-bottom: 15px;
		width: 100%;
	}

	@include break-min($large1) {
		margin-right: 10px;
		width: auto;

		a {
			margin-bottom: 0;
			width: auto;
		}
	}

	&.active {
		display: block;
	}
}
