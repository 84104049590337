.signature-drop-headline-text {
	margin: 52px 0 48px 0;
	padding: 0 24px;
	text-align: center;

	h1 {
		@include font-size(2.8);
		color: $blue;
		font-weight: $bold-weight;
		letter-spacing: -1%;
		line-height: lh(34, 28);
		margin: 64px 0 24px 0;

		@include break-min($large1) {
			@include font-size(4.8);
			letter-spacing: -1px;
			line-height: lh(58, 48);
			margin: 0 auto 24px;
			max-width: 715px;
		}
	}

	p {
		@include font-size(1.8);
		letter-spacing: -0.5px;
		line-height: lh(28, 18);

		span {
			color: $red;
		}

		@include break-min($large1) {
			margin: 0 auto 0;
			max-width: 648px;
		}
	}
}
