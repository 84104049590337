.store-connect {
	a {
		text-decoration-color: $blue;
		color: $blue;
		text-decoration: none;
		display: block;
	}

	.social-icons {
		margin-top: 15px;

		li {
			margin: 5px 10px 0 0;

			a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
