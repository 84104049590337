// .p-details component
// ..........................................................................
.p-details {
	padding: 30px 0;

	&__head {
		padding: 0 0 5px;

		@include break-min($medium1) {
			@include flexbox();
			@include justify-content(space-between);
			@include align-items(baseline);
			@include flex-wrap(nowrap);
		}

		@include break-min($large1) {
			padding: 10px 0 5px;
		}
	}

	&__h1 {
		@include font-size(2.8);
		display: block;
		margin: 0;
		padding: 0 0 10px;
		font-family: $primary;
		font-weight: $bold-weight;
		line-height: lh(34px, 28px);
		letter-spacing: -1px;
		color: $vis-blue;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes

		@include break-min($medium1) {
			@include flex(1 1 auto);
			@include font-size(3.9);
			letter-spacing: -1px;
			padding: 0;
			// line-height: lh(40px, 39px);
		}
	}

	&__price {
		@include p1();
		line-height: 1.2;
		color: $grey;
		margin: 0;
		padding: 0;
		font-weight: $bold-weight;

		@include break-min($medium1) {
			@include flex(0 0 auto);
			padding: 0 0 0 10px;
			@include font-size(2.2);
		}
	}

	&__text {
		max-width: 570px;

		p {
			padding: 15px 0 0;
			margin: 0;
			color: $vis-grey;
			@include pdp-p();
		}

		+ .p-details__options {
			padding: 25px 0 0;
		}
	}

	&__options {
		span {
			@include p1();
			font-weight: $bold-weight;
			padding: 0;
			margin: 0;
		}
	}

	.start-catering-order {
		margin-top: 37px;

		p {
			@include font-size(1.1);
			color: $grey;
			font-family: $primary;
			font-weight: $regular-weight;
			margin-top: 16px;
			letter-spacing: 0.2px;
			line-height: 16px;
			text-align: center;

			@include break-min($large1) {
				text-align: left;
			}
		}
	}

	&__radio {
		padding: 13px 0 0 0;
		margin: 0;
		position: relative;

		&.signedInFavorited {
			border-top: 1px solid $iron;
			margin-top: 19px;
			padding-top: 23px;

			@include break-min($large1) {
				padding-top: 14px;
			}

			p {
				@include font-size(1.5);
				font-weight: $regular-weight;
			}
		}

		label {
			color: $vis-grey;
			@include pdp-p();
			font-weight: $bold-weight;
			margin: 0;
			padding: 0 0 0 30px;
			position: relative;
			cursor: pointer;
			pointer-events: initial;
			display: inline-block;
			z-index: $z2;

			&::before {
				content: "";
				margin: auto;
				display: block;
				width: 18px;
				height: 18px;
				background-color: transparent;
				border: 1px solid $light-grey;
				position: absolute;
				top: 6px;
				left: 0;
				border-radius: 50%;
			}
		}

		input {
			border: 0;
			padding: 0;
			margin: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
			pointer-events: none;

			&:hover {
				+ label {
					&::before {
						background-color: lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:focus {
				+ label {
					outline-offset: 3px;
					outline: 1px dotted $red;

					&::before {
						box-shadow: 0 0 0 3px lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:checked {
				+ label {
					&::before {
						background-color: transparent;
						border-color: $red;
					}

					&::after {
						content: "";
						position: absolute;
						background-color: $red;
						left: 3px;
						top: 9px;
						margin: auto;
						width: 14px;
						height: 14px;
						border-radius: 50%;
					}
				}
			}

			&:disabled {
				+ label {
					cursor: not-allowed;

					&::before {
						opacity: 0.5;
						background-color: transparent;
						border-color: $light-grey;
						box-shadow: none;
					}
				}
			}
		}

		span {
			display: inline-block;
			font-weight: $regular-weight;
			margin: 0;
			padding: 0 10px 0 0;

			@include break-min($medium1) {
				padding: 0 20px 0 0;
			}
		}
	}

	.pdp-pwo-cta {
		@include break-max($large1) {
			display: none;
		}
	}
}
