.store-closed {
	background-color: $warm-white;
	padding: 24px;
	margin: 55px 0;
	justify-content: center;

	@include break-min($large1) {
		padding: 36px;
	}

	h3 {
		color: $red;
		text-transform: uppercase;
		margin: 0;
		text-align: center;
		@include s3();
		line-height: 24px;
		@include font-size(1.6);

		@include break-min($large1) {
			@include font-size(2.2);
		}
	}
}
