.content-hub-header-hero-icon {
	background-color: $white;
	color: $blue;
	margin-bottom: 50px;

	.sub-title {
		margin-top: 21px;
	}

	.blurb {
		max-width: 646px;
		text-align: left;
		margin: 0 32px;

		@include break-min($large1) {
			text-align: center;
			margin: 0 auto;
		}

		p {
			color: $grey;
			@include font-size(1.8);
		}
	}
}
