.delivery-hero-component {
	position: relative;

	.lottie {
		position: absolute;
		z-index: 999999;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		overflow: hidden;
		height: 350px;
		margin-top: auto;
		margin-bottom: auto;

		@include break-min($medium2) {
			height: 500px;
		}
	}

	.g-hero {
		&.g-hero-delivery {
			display: flex;
			min-height: auto;
			background-color: $white;
			flex-flow: column;
			text-align: center;
			margin-bottom: 30px;

			@include break-min($small1) {
				height: 100%;
				max-height: 100%;
				margin: 30px 0;
			}

			@include break-min($large1) {
				height: 624px;
				text-align: left;
				margin-top: 100px;
			}

			@include break-min($large2) {
				height: 624px;
			}

			.component-wrapper {
				visibility: hidden;
				opacity: 0;
				@include transition(all 0.6s ease-in-out);

				&.active {
					visibility: visible;
					opacity: 1;

					.g-hero__img {
						transform: translateX(0);

						&--mobile {
							transform: translateY(0);
						}
					}

					.g-hero-delivery-inner {
						transform: translateY(0);

						@include break-min($medium2) {
							transform: translateY(0);
						}

						@include break-min($large1) {
							transform: translateY(0);
						}
					}
				}
			}

			.g-hero__img {
				background-position: 50% 100%;
				background-size: contain;
				position: relative;
				transform: translateX(40px);
				@include transition(all 0.6s ease-in-out);

				&--mobile {
					position: relative;
					transform: translateY(20px);
					@include transition(all 0.6s ease-in-out);
				}
			}

			.g-hero-delivery-inner {
				z-index: 200;
				max-width: 1080px;
				width: 100%;
				margin: auto;
				display: flex;
				justify-content: stretch;
				flex-direction: row;
				flex-wrap: wrap;
				position: relative;
				padding: 0;
				transform: translateY(20px);
				@include transition(all 0.6s ease-in-out);

				@include break-min($large1) {
					transform: translateY(30px);
					max-width: 1200px;
					padding: 0 24px;
				}

				.hero-half {
					width: $col12;

					@include break-min($large1) {
						width: $col6;
					}
				}

				.g-hero__logo {
					margin: 0 auto;
					width: 80px;

					@include break-min($large1) {
						margin: 0;
						width: 100px;
					}
				}

				&__text {
					z-index: 200;
					margin: 0;
					padding-top: 10px;
					color: $white;
					flex: 1 1 100%;

					@include break-min($large1) {
						padding-top: 46px;
						padding-bottom: 50px;
					}

					form {
						margin: 0 24px;

						@include break-min($large1) {
							margin: 0;
						}
					}
				}

				h1,
				h2 {
					@include type-h1();
					color: $red;
					padding: 0 0 32px;
					margin: 0 auto;
					max-width: 620px;
					display: block;
					width: 100%;

					@include break-min($large1) {
						margin: -12px 0 0 -3px;
						max-width: 700px;
					}
				}
			}

			.g-hero-mobile__img {
				padding-top: 30px;
				max-height: 540px;
			}

			.g-hero-delivery-cta {
				width: 100%;
				z-index: 111;
				position: relative;
				padding: 0 24px;

				@include break-min($large1) {
					max-width: 1200px;
					margin: 80px auto;
					padding: 0;

					a {
						margin-right: 24px;
					}
				}
			}

			.tagline {
				font-family: $secondary;
				font-weight: 800;
				font-size: 18px;
				line-height: 20px;
				letter-spacing: 4px;
				text-transform: uppercase;
				color: $red;
				margin: 0 0 32px;
				display: none;

				@include break-min($medium2) {
					font-size: 16px;
					max-width: 700px;
					margin: 0 auto;
					display: block;
				}

				@include break-min($large1) {
					font-size: 18px;
					margin: 0 0 32px;
				}
			}

			.btn-information {
				@include break-min($large1) {
					position: absolute;
					right: 0;
				}
			}
		}
	}
}
