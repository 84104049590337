// Array of options
$heightOptions: (
	"100": 100px,
	"200": 200px,
	"300": 300px,
	"400": 400px,
	"500": 500px,
	"600": 600px,
	"700": 700px,
	"900": 900px,
	"1000": 1000px
);

.pwo-hero-delivery {
	height: 100vh;
	padding: 43px 0 64px;
	position: relative;
	overflow: hidden;

	@include break-min($large1) {
		padding: 200px 0 100px;
		height: 812px;
		text-align: left;
		margin-top: 0;
	}

	@each $key, $val in $heightOptions {
		&.h-mobile-#{$key} {
      .video-container {
        @include break-max($large1) {
          height: $val;
        }
      }
		}

		&.h-desk-#{$key} {
			@include break-min($large1) {
				height: $val;
			}
		}
	}

	&.mobile-img-lrg {
		.video-container {
			@include break-max($large1) {
				height: 340px;
			}
		}
	}

	&.pwo-hero-with-promo {
		@include break-min($large1) {
			margin-top: 0;
			margin-bottom: -69px;
		}

		&.featured-product-video-hero .video-container .player-wrapper {
			@include break-min($large1) {
				z-index: -1;
			}
		}
	}

	&.pwo-hero-spacing {
		@include break-min($large1) {
			margin-top: -108px;
			margin-bottom: -69px;
		}
	}

	.background-image {
		display: none;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&.background {
		.background-image {
			display: block;

			&.mobile {
				@include break-min($large1) {
					display: none;
				}
			}

			&.desktop {
				@include break-max($large1) {
					display: none;
				}
			}
		}

		h1 {
			color: $white;
		}

		.tiled-img {
			display: none;
		}
	}

	&.cool-white-bckgrd {
		background: $cool-white;
	}

	&.callout-view {
		@extend .covid-new-ordering-callout !optional;
	}

	.wrapper {
		margin: auto;
		max-width: 800px;
		padding: 0 24px;
		text-align: center;

		@include break-min($large1) {
			margin: 0 auto 96px;
			max-width: 1400px;
			padding: 0 100px;
		}
	}

	.tiled-img {
		bottom: 0;
		position: relative;
		right: 0;
		top: 0px;

		@include break-min($large1) {
			right: -100px;
			position: absolute;
			top: 100px;
		}

		.mobile-img {
			@include break-min($large1) {
				display: none;
			}
		}

		.desktop-img {
			display: none;

			@include break-min($large1) {
				display: block;
			}
		}
	}

	p {
		@include type-p1();
		@include font-size(1.8);
		line-height: lh(28, 18);
		margin-bottom: 16px;
		font-weight: normal;

		@include break-min($large1) {
			@include font-size(2.2);
		}

		&:first-child {
			@include break-min($large1) {
				@include font-size(1.4);
				margin-bottom: 11px;
			}
		}
	}

	h1 {
		@include type-h1();
		color: $red;
		margin: 0 0 22px;
		text-align: center;
		@include font-size(3.6);
		line-height: 46px;
		z-index: 1;
		position: relative;

		@include break-min($large1) {
			@include font-size(6.4);
			line-height: 74px;
			text-align: left;
			margin-bottom: 50px;
		}
	}

	.btn-container {
		margin: 40px auto 0;
		flex-flow: column;

		&:empty {
			margin: 0;
		}

		@include break-min($large1) {
			display: flex;
			margin: 40px 0 0;
			max-width: 650px;
		}

		a {
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			background-color: $white;
			border-radius: 16px;
			display: flex;
			justify-content: center;
			padding: 45px 35px;
			position: relative;

			& > * {
				pointer-events: none;
			}

			&:hover {
				@include btn-hover-effect();
			}

			@include break-min($large1) {
				padding: 36px 30px;
				width: 50%;
			}

			&:first-child {
				margin-bottom: 24px;

				@include break-min($large1) {
					margin: 0 20px 20px 0;
				}
			}

			img {
				margin-right: 15px;
			}

			p {
				align-self: center;
				bottom: inherit;
				font-weight: bold;
				margin: 0;
				top: inherit;
			}
		}
	}
}
