.delivery-location-details {
	padding-top: 40px;
	position: relative;
	margin-bottom: 38px;

	&:before {
		@include break-max($large1) {
			@include dot-border-h(top);
		}
	}

	@include break-min($large1) {
		position: relative;
		display: flex;
	}

	.icon-wrapper {
		border-radius: 50%;
		height: 74px;
		left: 0px;
		margin: auto;
		text-align: center;
		width: 74px;

		@include break-min($large1) {
			margin: 0;
		}

		img {
			background: $iron;
			border-radius: 50%;
			padding: 6px;
			width: 75px;
		}
	}

	.distance {
		@include type-p1();
		color: $grey;
		font-weight: normal;
		margin: 8px 0 0 0;

		&.mobile {
			@include break-min($large1) {
				display: none;
			}
		}

		&.desktop {
			display: none;

			@include break-min($large1) {
				display: block;
				margin-top: 0;
			}
		}
	}

	.location-details-wrapper {
		@include break-min($large1) {
			margin-left: 30px;
		}
	}

	h2,
	h3 {
		@include type-h2();
		color: $blue;
		font-weight: bold;
		letter-spacing: -0.01em;
		margin: 16px 0;

		@include break-min($large1) {
			@include font-size(3.6);
			margin-bottom: 11px;
		}
	}

	.address-details {
		@include type-p1();
		font-weight: normal;

		@include break-min($large1) {
			display: flex;
			flex-direction: column;
		}

		p {
			margin-bottom: 2px;

			@include break-min($large1) {
				margin-bottom: -5px;
			}
		}

		.phone {
			color: $red;
		}
	}
}
