.footer-module-callout {
	@extend .header-img-callout;
	background: $red;
	color: $white;
	padding: 104px 24px;
	position: relative;
	text-align: center;

	@include break-min($large1) {
		padding: 79px 50px;
		justify-content: flex-start;
		text-align: left;
	}

	@include break-min($large4) {
		padding: 79px 150px;
	}

	.content {
		z-index: 1000;

		@include break-min($large1) {
			max-width: 700px;
		}

		.headline {
			margin-bottom: 20px;

			p {
				font-weight: $bold-weight;
				line-height: lh(28, 18);
			}
		}

		h4 {
			margin-bottom: 44px;

			@include break-min($large1) {
				margin-bottom: 0;
			}
		}
	}

	.img-wrapper {
		display: none;
	}

	.btn-round {
		@extend .btn-round--white;

		@include break-min($large1) {
			bottom: 0;
			margin: auto;
			max-height: 50px;
			position: absolute;
			right: 50px;
			top: 0;
		}

		@include break-min($large4) {
			right: 150px;
		}
	}

	.bckgrd-img {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 100;
		overflow: hidden;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;

		.mobile-img {
			@include break-min($large1) {
				display: none;
			}
		}

		.desktop-img {
			display: none;

			@include break-min($large1) {
				display: none;
			}
		}
	}
}
