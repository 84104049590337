.order-selection {
	padding: 0 16px 64px;
	margin: 0 auto;

	@include break-min($large1) {
		width: $col6;
	}

	.order-headline {
		@include font-size(2.8);
		font-weight: $bold-weight;
		line-height: 34px;
		letter-spacing: -1px;
		color: $vis-blue;
		margin: 50px auto 16px;
		text-align: center;

		@include break-min($medium1) {
			@include flex(1 1 auto);
			@include font-size(4.8);
			letter-spacing: -0.01px;
			margin-bottom: 22px;
			padding: 0;
			line-height: 58px;
		}
	}

	p {
		margin: 0;
		text-align: center;
		@include font-size(1.8);
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 30px 0 0 0;

		@include break-min($large1) {
			padding: 0 40px;
			margin: 36px auto 0;
			max-width: 500px;
		}

		li {
			@include btn-hover-effect();
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			background-color: $white;
			border-radius: 16px;
			display: flex;
			justify-content: center;
			margin: 16px 0;

			a {
				display: flex;
				@include font-size(1.8);
				font-family: $primary;
				font-weight: $bold-weight;
				justify-content: center;
				letter-spacing: -0.5px;
				line-height: 34px;
				padding: 44px 16px;
				position: relative;
				text-align: center;
				width: 100%;

				@include break-min($large1) {
					@include font-size(2.2);
				}

				img {
					bottom: 0;
					left: 40px;
					margin: auto 20px auto 0;
					top: 0;
				}

				div {
					align-self: center;
					text-align: left;
				}

				p {
					@include font-size(1.2);
					color: $grey;
					font-weight: $regular-weight;
					letter-spacing: -0.5px;
					line-height: lh(15, 12);
					text-align: left;
					margin: 4px 0 0 1px;
					width: 200px;
				}
			}
		}
	}
}
