.content-hub-reverse-callout {
	grid-template-areas: "a a a a a a a b b b b b b";
	padding-bottom: 52%;
	position: relative;
	margin-bottom: 185px;
	display: flex !important;

	@include break-min($medium4) {
		display: grid !important;
		padding-bottom: 0;
		margin-bottom: auto;
	}

	@include break-min($large4) {
		padding: 48px 72px;
		border-radius: 32px;
	}

	.message-container {
		justify-self: flex-end;
		grid-area: a;
		left: 35px;
		right: 35px;
		bottom: -180px;
		text-align: center;
		display: flex;
		flex-flow: column;
		align-items: center;
		position: absolute;
		border-radius: 16px;
		padding: 35px 24px;
		background-color: $cool-white;

		@include break-min($medium4) {
			grid-area: b;
			display: grid;
			position: relative;
			text-align: left;
			left: auto;
			right: auto;
			top: auto;
			bottom: auto;
			border-radius: 32px;
		}

		.body-text {
			display: none;

			@include break-min($medium4) {
				display: block;
			}
		}

		.btn {
			margin: 0 auto;
			@include btn-round;
			@include btn-primary-red;
			@include font-size(1.6);

			@include break-min($medium4) {
				margin-top: 32px;
			}
		}
	}
}
