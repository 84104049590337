.full-bleed-image {
	text-align: center;

	.lb-header-content {
		p {
			@include font-size(1.8);
		}

		h2 {
			color: $blue;
			font-family: $primary;
			font-weight: bold;
			margin: 0 0 24px;
			padding: 0;
			text-align: center;
			letter-spacing: -0.01px;
			@include font-size(2.4);

			@include break-min($large1) {
				@include font-size(3.6);
			}
		}

		@include break-min($large1) {
			padding: 0 200px;
		}

		@include break-min($large4) {
			padding: 0 270px;
		}

		@include break-min($huge2) {
			padding: 0 340px;
		}
	}

	.lb-component-content {
		margin: 75px 0;
	}

	a {
		@include font-size(1.8);
	}

	&.full-width {
		.lb-component-content {
			picture {
				img {
					width: 100%;
				}
			}
		}
	}
}
