// Base class - only include global styles here
// Place component-specific styles in associated stylesheets

.header-img-callout {
	.title {
		> *:first-child {
			@include type-h1-refresh();
			margin: 0 0 23px 24px;
			text-align: left;

			@include break-min($large1) {
				margin: 0 0 88px 0;
				text-align: center;
			}
		}
	}

	.flex-wrapper {
		@media screen and (min-width: $mobile) {
			align-items: center;
			display: flex;
			justify-content: center;
			height: 100%;
			text-align: left;
		}
	}

	&.with-video {
		// TODO: find a way to share these styles - taken from bc-video-container
		.video-container {
			// @include remove-bc-controls();
			position: relative;
			width: 100%;
			overflow: hidden;

			@include break-min($large1) {
				margin: 0;
				height: auto;
			}

			&.isPlaying {
				.overlay {
					display: none;
				}
			}

			.overlay {
				@include absolute-center();
				cursor: pointer;
				z-index: 10;

				&__img-wrapper {
					align-items: center;
					display: flex;
					height: 100%;
					justify-content: center;
					width: 100%;

					img {
						height: 77px;
						width: 77px;
					}
				}
			}

			.player-wrapper {
				@include absolute-center();
				margin: 0;
				transition: opacity 0.3s ease;
			}

			.video-js {
				@include absolute-center();
				height: 100%;
				margin: auto;
				width: 100%;
			}

			.video-js .vjs-tech {
				object-fit: cover;
			}
		}
		// component-specific styles added here
		.video-container {
			height: 209px;

			@include break-min($large1) {
				height: 391px;
				flex: 66%;
			}
		}

		.content {
			@include break-min($large1) {
				flex: 33%;
			}
		}
	}

	.img-wrapper {
		display: flex;
		justify-content: center;
		position: relative;

		.mobile-img {
			@include break-min($large1) {
				display: none;
			}
		}

		.desktop-img {
			display: none;

			@include break-min($large1) {
				display: block;
			}
		}
	}

	.content {
		.headline {
			padding: 0;

			@include break-min($large1) {
				max-width: none;
			}

			p {
				@include font-size(1.8);
				line-height: lh(28, 18);
				margin: 0;

				@include break-min($large1) {
					@include font-size(1.4);
					font-weight: $bold-weight;
				}
			}
		}

		h4 {
			@include font-size(3.6);
			font-weight: $bold-weight;
			letter-spacing: -0.8px;
			line-height: lh(46, 36);
			margin: 0;

			@include break-min($large1) {
				@include font-size(4.8);
				font-weight: $bold-weight;
				letter-spacing: -1px;
				line-height: lh(58, 48);
			}
		}

		p {
			@include font-size(1.8);
			font-weight: 400;
			letter-spacing: -0.5px;
			line-height: lh(28, 14);
			margin-bottom: 40px;

			@include break-min($large1) {
				@include font-size(1.6);
				line-height: lh(25, 16);
			}
		}
	}

	.btn-round {
		min-width: 144px;
		width: 100%;

		@include break-min($large1) {
			width: auto;
		}
	}

	.btn-container {
		a:nth-of-type(2) {
			border-bottom: 1px solid white;
			color: $white;
			margin-left: 12px;
		}
	}
}
