.delivery-directions-sig-drop {
	margin: 0 auto 0;
	padding: 22px 0 72px;

	@include break-min($large1) {
		padding-bottom: 96px;
	}

	h1 {
		@include font-size(2.4);
		margin-bottom: 32px;
		padding-bottom: 0;

		@include break-min($large1) {
			@include font-size(3.6);
			margin-bottom: 56px;
		}
	}

	.card {
		p {
			height: auto;
			line-height: lh(28, 18);
			margin-top: 44px;
		}
	}
}
