.service-level-offerings {
	visibility: visible;

	ul {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		padding: 0;
		margin: -10px 0 10px;

		@include break-min($large1) {
			justify-content: flex-start;
		}

		li {
			@include font-size(1.4);
			@include flexbox();
			margin: 10px 16px 0 0;
			align-items: center;

			&.delivery {
				&:before {
					content: "";
					background-image: url("img/delivery-icon-small.svg");
					margin-right: 5px;
					width: 27px;
					height: 18px;
					background-repeat: no-repeat;
				}
			}

			&.carryout {
				&:before {
					content: "";
					background-image: url("img/carryout-icon-small.svg");
					margin-right: 5px;
					width: 19px;
					height: 18px;
					background-repeat: no-repeat;
				}
			}

			&.drivethru {
				&:before {
					content: "";
					background-image: url("img/drivethru-icon-small.svg");
					margin-right: 5px;
					width: 25px;
					height: 24px;
					background-repeat: no-repeat;
				}
			}

			&.curbside {
				&:before {
					content: "";
					background-image: url("img/curbside-icon-small.svg");
					margin-right: 5px;
					width: 33px;
					height: 37px;
					background-repeat: no-repeat;
					background-size: 33px;
				}
			}

			&.full-dine-in {
				&:before {
					content: "";
					background-image: url("img/dinein-icon-small.svg");
					margin-right: 5px;
					width: 36px;
					height: 36px;
					background-repeat: no-repeat;
					background-size: 34px;
				}
			}

			&.limited-dine-in {
				&:before {
					content: "";
					background-image: url("img/dinein-icon-small.svg");
					margin-right: 5px;
					width: 36px;
					height: 36px;
					background-repeat: no-repeat;
					background-size: 34px;
				}

				&:after {
					content: none;
				}
			}

			&:after {
				content: "";
				width: 5px;
				height: 5px;
				background-color: $rolling-stone;
				border-radius: 50%;
				margin-left: 10px;
			}

			&:last-child {
				&:after {
					content: none;
				}
			}

			&:last-child:is(.info-icon) {
				&:after {
					content: none;
				}
			}
		}
	}
}
