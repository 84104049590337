.alert-callout {
	background: $blue;
	border-radius: 5px;
	color: $white;
	cursor: pointer;
	padding: 4px 19px 4px 50px;
	line-height: 1;
	font-family: $primary;
	letter-spacing: -0.5px;
	position: relative;
	box-sizing: border-box;
	border: 6px solid $blue;
	width: 100%;
	display: flex;
	flex-flow: column;
	text-align: left;
	align-items: flex-start;
	min-height: 56px;
	justify-content: center;
	height: 100%;
	@include font-size(1.2);

	@include break-min($large1) {
		width: 346px;
	}

	span {
		margin: 3px 0;
	}

	&::before,
	&:after {
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
	}

	&:before {
		content: "";
		background-image: url("img/info-icon.svg");
		width: 24px;
		height: 24px;
		left: 16px;
	}

	&:after {
		content: "";
		background-image: url("img/arrow-right.svg");
		width: 13px;
		height: 20px;
		right: 16px;
	}

	&:hover {
		background: $blue-hover;
		border: 6px solid $blue-hover;
		color: $white;
	}

	&:focus {
		border: 6px solid rgb(100, 142, 160);
	}
}
