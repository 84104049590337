.callout-margins {
	margin: 0 32px 60px;

	@include break-min($medium4) {
		margin: 0 46px 60px;
	}

	@include break-min($large4) {
		margin: 0 72px 60px;
	}

	@include break-min($huge4) {
		margin: 0 auto 60px;
	}
}
