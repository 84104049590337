.breadcrumbs-left-nav {
	ul {
		display: flex;
		flex-direction: row;
		margin: 24px;
		padding-left: 0;

		@include break-min($large1) {
			margin: 24px 48px;
		}

		li {
			align-items: center;
			display: flex;
			flex-direction: row;
			position: relative;

			&:not(:nth-last-of-type(2)) {
				@include break-max($large1) {
					display: none;
				}
			}

			&:nth-last-of-type(2) {
				img:first-of-type {
					margin-right: 15px;
					transform: rotate(180deg);

					@include break-min($large1) {
						display: none;
					}
				}
			}

			&:not(:first-of-type) {
				@include break-min($large1) {
					display: flex;
					padding-left: 14px;
				}
			}

			&:not(:last-of-type) {
				img:last-of-type {
					display: none;

					@include break-min($large1) {
						display: block;
						margin-left: 15px;
					}
				}
			}

			&:last-of-type {
				a {
					display: none;

					@include break-min($large1) {
						color: $red;
						display: block;
						font-weight: $bold-weight;
					}
				}

				img {
					display: none;
				}
			}

			img {
				height: 16px;
				width: auto;

				&:first-of-type {
					@include break-min($large1) {
						display: none;
					}
				}
			}

			a {
				@include font-size(1.6);
				color: $grey;
				font-weight: $bold-weight;
				line-height: lh(25, 16);

				@include break-min($large1) {
					font-weight: $regular-weight;
				}
			}
		}
	}
}
