// sass-lint:disable no-qualifying-elements

.img-txt-carousal {
	background: $white;
	padding: 50px 16px;
	text-align: center;

	&.cream-bckgrd {
		background: $warm-white;
	}

	.slider {
		max-width: 1380px;
		margin: 0 auto;
		width: 100%;
	}

	@media screen and (min-width: $mobile) {
		padding: 50px 0;
	}

	.carousal-item {
		padding-top: 0;

		@media screen and (min-width: $mobile) {
			display: flex;
		}
	}

	.img-container {
		flex: 1 0 50%;
	}

	.content {
		text-align: left;

		@media screen and (min-width: $mobile) {
			margin: auto 50px auto 75px;
		}

		h3 {
			@include font-size(2.2);
			font-weight: bold;

			@media screen and (min-width: $mobile) {
				@include font-size(2.8);
			}

			color: $blue;
		}

		p {
			@include p2();
		}

		a:not(.btn) {
			@include link-underline();
		}

		.btn {
			@media screen and (max-width: $mobile) {
				display: none;
			}
		}
	}

	.slick-dots {
		margin-top: 30px;
		position: inherit;

		@media screen and (min-width: $mobile) {
			margin-top: 50px;
		}
	}

	.slick-dots li button {
		// sass-lint:disable variable-for-property
		background-color: grey;
		// sass-lint:enable variable-for-property
		border: none;
		border-radius: 100%;
		display: block;
		height: 1.1rem;
		padding: 0;
		text-indent: -9999px;
		width: 1.1rem;

		&:focus {
			outline: 1px dotted blue;
		}
	}

	.slick-dots li.slick-active button {
		background: $red;
	}

	.slick-dots li button:before {
		// sass-lint:disable variable-for-property
		color: grey;
		// sass-lint:enable variable-for-property
	}

	.slick-dots li.slick-active button:before {
		color: $red;
	}
}
