.infographic-subcomponent {
	margin: 0 auto;
	max-width: 192rem;
	padding: 0 24px 71px;

	@include break-min($large1) {
		padding: 0 46px 82px;
	}

	@include break-min($large2) {
		padding: 0 24px 85px;
	}

	@include break-min($huge2) {
		padding: 0 68px 85px;
	}

	.header {
		> *:first-child {
			@include font-size(2.4);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(32, 24);
			margin-bottom: 64px;
			text-align: center;

			@include break-min($large2) {
				@include font-size(3.6);
				line-height: lh(42, 36);
				margin-bottom: 56px;
			}
		}
	}

	.container--circle-block {
		align-items: center;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-left: 20px;
		padding-right: 20px;

		@include break-min($large2) {
			align-items: baseline;
			flex-direction: row;
			margin: auto;
			max-width: 650px;
		}

		@include break-min($huge2) {
			max-width: 100%;
		}

		.container--column {
			display: flex;
			justify-content: center;

			&:not(:last-of-type) {
				margin-bottom: 80px;
			}

			@include break-min($large2) {
				&:nth-child(3),
				&:nth-child(4) {
					margin-top: 48px;
				}

				&:not(:last-of-type) {
					margin: 0;
				}
			}

			@include break-min($huge2) {
				&:nth-child(3),
				&:nth-child(4) {
					margin-top: 0;
				}
			}

			p {
				@include font-size(1.8);
				color: $grey;
				display: flex;
				flex-direction: column;
				font-weight: $regular-weight;
				justify-content: center;
				letter-spacing: -0.5px;
				line-height: lh(28, 18);
				margin-bottom: 0;
				max-width: 290px;
				padding: 0 5px;
				text-align: center;

				@include break-min($large2) {
					background-color: $cool-white;
					border-radius: 50%;
					height: 290px;
					width: 290px;

					&:nth-child(3),
					&:nth-child(4) {
						margin-top: 48px;
					}

					&:not(:last-of-type) {
						margin: 0;
					}
				}

				span {
					@include font-size(3.6);
					color: $red;
					display: block;
					font-weight: $bold-weight;
					letter-spacing: -0.8px;
					line-height: lh(46, 36);
					margin-bottom: 8px;

					@include break-min($large2) {
						@include font-size(6.4);
						letter-spacing: -1.5px;
						line-height: lh(74, 64);
					}
				}
			}
		}
	}
}
