.commitment-care-callout {
	&.classic-callout {
		grid-template-columns: repeat(4, [col-start] 1fr);

		@include break-min($medium1) {
			grid-template-columns: repeat(4, [col-start] 1fr);
		}

		@include break-min($huge4) {
			grid-template-columns: repeat(12, [col-start] 104px);
		}

		> * {
			> * {
				@include break-min($medium1) {
					align-self: auto;
				}
			}
		}
	}

	.message-container {
		width: 100%;
		text-align: left;
		border-radius: 16px;

		@include break-min($large1) {
			text-align: center;
			grid-column: col-start 1 / span 12;
			border-radius: 32px;
		}

		.body-text {
			@include break-min($large1) {
				max-width: 600px;
				margin: 0 auto;
			}
		}
	}
}
