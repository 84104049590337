.operator-sites-modal {
	border-radius: 8px;
	max-width: 644px;
	text-align: center;

	.inner {
		padding: 80px 25px;

		@include break-min($large1) {
			padding: 80px 53px;
		}

		button:first-of-type {
			&.mfp-close {
				@include lightbox-close-btn();
				opacity: 1;
			}
		}

		.btn-action {
			@include break-min($large1) {
				margin: 0 10px;
				min-width: 150px;
			}
		}
	}

	h4 {
		@include font-size(2.4);
		color: $blue;
		font-weight: bold;
		letter-spacing: -0.5px;
		margin: 0 0 12px;

		@include break-min($large1) {
			@include font-size(3.6);
		}
	}

	p {
		@include type-p2(desktopOnly);
		margin-bottom: 35px;
	}
}
