.daypart-hero {
	margin: 0 32px;
	display: flex;
	flex-direction: column;

	@include break-min($small1) {
		flex-direction: column;
	}

	@include break-min($medium4) {
		margin: 0 0 0 46px;
	}

	@include break-min($large1) {
		display: inherit;
	}

	@include break-min($large4) {
		column-gap: 24px;
	}

	@include break-min($large4) {
		margin: 0 72px;
	}

	@include break-min($huge4) {
		margin: 0 auto;
	}

	&.video-option {
		.video-container {
			order: 2;

			@include break-max($medium1) {
				order: 1;
			}
		}
	}

	.video-container {
		display: flex;
		justify-content: center;
		align-items: center;
		grid-column: col-start 1 / span 6;

		@include break-min($medium4) {
			order: 2;
			width: 100%;
			grid-column: col-start 5 / span 8;
		}

		@include break-min($large4) {
			width: 100%;
			grid-column: col-start 7 / span 6;
		}
	}

	.image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		grid-column: col-start 1 / span 6;

		@include break-min($medium4) {
			width: 100%;
			grid-column: col-start 5 / span 8;
		}

		@include break-min($large4) {
			width: 100%;
			grid-column: col-start 7 / span 6;
		}

		&.reverse-order-desktop {
			@include break-min($medium4) {
				order: 2;
			}
		}

		.desktop-img {
			object-position: 0em;

			@include break-min($medium1) {
				// object-position: 25ch 0em;
			}

			@include break-min($medium4) {
				object-position: 19ch 0em;
				max-width: none;
			}

			@include break-min($large4) {
				object-position: -1.5ch 0;
				width: 592px;
			}

			@include break-min($huge1) {
				object-position: -2ch 0;
				width: 683px;
			}

			@include break-min($huge2) {
				// object-position: -2ch 0;
				// width: 652px;
			}

			@include break-min($huge3) {
				object-position: 0ch 0;
				width: 700px;
			}

			@include break-max($large1) {
				display: none;
			}
		}

		.mobile-img {
			@include break-min($large1) {
				display: none;
			}
		}
	}

	.wrapper {
		padding: 40px 10px 42px 0;
		grid-column: col-start 1 / span 4;

		@include break-min($medium1) {
			grid-column: col-start 1 / span 4;
		}

		@include break-min($large1) {
			visibility: visible;
		}

		@include break-min($large4) {
			padding: 40px 24px 42px 0;
			grid-column: col-start 1 / span 6;
		}
	}

	.location-order-selection {
		margin-top: 15px;

		ul {
			margin-top: 0px;
			grid-gap: 0;
			margin: 0 auto;

			@include break-min($large1) {
				grid-gap: 10px;
				max-width: 410px;
				margin: 0 auto;
			}

			@include break-min($large2) {
				grid-gap: 15px;
			}

			li {
				max-width: 110px;
				min-height: 95px;
				margin: 0 auto;

				@include break-min($large1) {
					max-width: 120px;
					margin: 0;
				}

				img {
					width: 33px;
				}

				&:first-child {
					img {
						width: 25px;
					}
				}

				a {
					padding: 10px 30px;
					transform: translateY(-50%);
					top: 50%;

					@include break-min($small3) {
						padding: 10px 30px;
					}

					.selection-info {
						margin-top: 5px;

						span {
							font-size: 1.4rem;
							margin-top: 5px;
							line-height: 18px;
						}
					}
				}
			}
		}
	}

	.align-center {
		h1 {
			text-align: center;
		}

		.daypart-animated-headline {
			justify-content: center;
		}

		.btn-container {
			justify-content: center;
		}
	}

	h1 {
		font-size: 42px;
		left: -2px;

		@include break-min($large3) {
			font-size: 48px;
		}

		@include break-min($huge2) {
			font-size: 64px;
		}
	}

	.animated-sequence {
		align-items: baseline;
		transition: all 0.3s ease;
		will-change: opacity;
		opacity: 0;
		animation: FadeFromBottom 0.3s ease;
		animation-fill-mode: forwards;
	}

	.delay-0 {
		animation-delay: 500ms;
	}

	.delay-50 {
		animation-delay: 550ms;
	}

	.delay-100 {
		animation-delay: 600ms;
	}

	.delay-150 {
		animation-delay: 650ms;
	}

	.delay-200 {
		animation-delay: 700ms;
	}
}
