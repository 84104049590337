.header-columns-text {
	padding: 50px 0;
	margin: 50px auto;
	text-align: center;

	&.cream-bckgrd {
		background: $warm-white;
	}

	&.coolwhite-bckgrd {
		background: $cool-white;
	}

	&.no-vertical-margins {
		margin-bottom: 0;
		margin-top: 0;
	}

	&.no-pad-bottom {
		padding-bottom: 0;
	}

	&.no-subsid-text {
		.header {
			margin: 0 auto 40px;

			@include break-min($large1) {
				margin: 0 auto 35px;
			}
		}
	}

	&.left {
		h2 {
			@media screen and (max-width: $mobile) {
				margin-left: 28px;
				text-align: left;
			}
		}

		dl {
			text-align: left;
		}
	}

	&.dt-h2-type {
		.testimonials {
			dt {
				@include break-min($large1) {
					@include type-h2-refresh();
				}
			}
		}
	}

	&__inner {
		margin: auto;
		max-width: 900px;
		padding: 0 16px;
		position: relative;
		width: 100%;
		z-index: $z2;

		@include break-min($large1) {
			padding: 0 24px;
		}

		p {
			@include break-min($large1) {
				margin-top: 32px;
			}
		}
	}

	.header {
		margin: 0 auto 65px;
		padding: 0 20px;
		text-align: center;

		@media screen and (min-width: $mobile) {
			margin: auto;
			padding: 0;
			width: $col6;
		}

		p {
			@include type-p2();
			margin: 30px 0 22px;
		}
	}

	h2 {
		@include type-h2();
		color: $vis-blue;
		margin: 0;
		text-align: center;

		@media only screen and (max-width: $mobile) {
			margin: 0 0 30px 0;
		}
	}

	.flex {
		@extend .wrapper;

		@media only screen and (max-width: $mobile) {
			flex-direction: column;
		}
	}

	dl {
		margin: 50px $col-margin;
		text-align: center;
		width: $col4;

		@media only screen and (max-width: $mobile) {
			margin: 0 0 30px;
			padding: 0 10px;
			width: auto;
		}

		a {
			@include font-size(1.6);
			@include link-underline();
		}
	}

	dt {
		@include p1();
		font-weight: 800;
		line-height: 28px;
	}

	dd {
		@include font-size(1.6);
		font-family: $primary;
		line-height: 24px;
		margin: 20px 0;
		position: relative;

		@media screen and (max-width: $mobile) {
			padding-bottom: 50px;

			&:after {
				@include dot-border-h(bottom, left);
			}
		}

		&.no-dotted {
			&:after {
				display: none;
			}
		}
	}

	ul {
		&:first-child {
			padding-left: 0;
		}
	}

	.slick-dots {
		position: inherit;

		@media screen and (min-width: $mobile) {
			margin-top: 50px;
		}

		li {
			margin-right: 15px;

			button {
				// sass-lint:disable variable-for-property
				background-color: grey;
				// sass-lint:enable variable-for-property
				border: none;
				border-radius: 100%;
				display: block;
				height: 1.5rem;
				padding: 0;
				text-indent: -9999px;
				width: 1.5rem;

				&:focus {
					outline: 1px dotted blue;
				}

				&:before {
					// sass-lint:disable variable-for-property
					color: grey;
					// sass-lint:enable variable-for-property
				}
			}

			&.slick-active button {
				background: $red;
			}

			&.slick-active button:before {
				color: $red;
			}
		}
	}

	.callout {
		position: relative;

		@media screen and (max-width: $mobile) {
			text-align: center;
		}

		&.top-dotted-border {
			&:before {
				@include dot-border-h(top, left);
				margin: auto;
				top: 0;
				max-width: 1380px;
				width: 95%;
			}
		}

		h2 {
			margin-top: 50px;
			padding-top: 50px;
		}

		h4 {
			@include type-h4();
			color: $vis-blue;
			margin-top: 50px;
			padding-top: 50px;
		}

		.btn {
			@media screen and (max-width: $mobile) {
				margin-bottom: 30px;
			}
		}
	}

	//Styles for white circle icon images
	.white-circle-wrapper {
		width: 102px;
		height: 102px;
		border-radius: 50%;
		margin: 0 auto 30px;
		position: relative;
		text-align: center;

		.icon {
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;

			img {
				bottom: 0;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	.testimonials {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: auto;
		max-width: 750px;
		padding: 10px;

		@media screen and (min-width: $mobile) {
			margin: auto;
			max-width: 1250px;
			flex-wrap: nowrap;
		}

		&.quote-bold {
			dt {
				position: relative;
				margin: 0 0 15px 15px;
				@include type-h4();
				@include font-size(1.6);

				@include break-min($large1) {
					@include font-size(1.8);
				}

				&:before {
					position: absolute;
					left: -12px;
					content: open-quote;
				}

				&:after {
					content: close-quote;
				}
			}

			dd {
				margin: 18px 0 18px 15px;
			}
		}

		&.p2-font {
			dl {
				@include type-p2();
			}
		}

		dl {
			text-align: left;
			width: 50%;

			@media screen and (min-width: $mobile) {
				@include flex(1 1 0);
				min-width: 0;
				margin: 0px 10px;
				text-align: left;
				width: auto;
				//IE flex bug fix for testimonial images
				flex-basis: 25%;
			}
		}

		dd {
			padding-bottom: 0;

			&:after {
				content: "";
				display: none;
			}
		}

		.img-wrapper {
			min-height: 180px;
			max-width: 350px;
			position: relative;
			margin: 0 auto 25px;
			overflow: hidden;

			@include break-min($small3) {
				min-height: 330px;
			}

			img {
				@include object-fit(cover);
				bottom: 0;
				height: 100%;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
			}
		}

		// Styles for icons testimonial section dl
		&.center {
			dt {
				color: $vis-blue;
				font-weight: 700;
			}

			dl {
				text-align: center;
				width: auto;
				flex-basis: auto;
			}
		}
	}

	.btn-round {
		@include break-max($small4) {
			&.full-width {
				margin-left: 16px;
				margin-right: 16px;
				width: calc(100% - 32px);
			}
		}
	}
}

.cfa-one-header-columns-text {
	dd {
		padding-bottom: 0;
	}
}
