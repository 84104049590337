.overlay-compliment {
	//@extend .wrapper;
	background-color: $white;
	border-radius: 15px;
	font-weight: normal;
	margin: 0 auto;
	padding: 20px 18px 10px;
	position: relative;
	width: $col7;

	@include breakpoint(mobileOnly) {
		margin-top: 25px;
		width: 75%;
	}

	section {
		text-align: center;

		h1 {
			color: $blue;
		}
	}

	.other-item-input {
		@include break-min($large1) {
			margin: auto;
			width: $col9;
		}

		h2 {
			@include h4();
			color: $blue;
			font-weight: bold;
			line-height: 1.25;
			margin-bottom: 0;

			@include break-min($large1) {
				line-height: 1.25;
			}
		}

		.g-form {
			padding: 20px 0;

			.error {
				padding-bottom: 10px;
			}

			&__group {
				position: relative;

				@include break-min($large1) {
					margin: auto;
					width: $col7;
				}

				label {
					font-weight: bold;
					left: 18px;
					position: absolute;
					top: 7px;
				}

				&--error {
					label {
						top: 47px;
					}
				}
			}
		}
	}

	.other-item-secondary-header {
		h2 {
			@include h5();
			font-weight: bold;
		}

		p {
			@include p1();
		}
	}

	ul {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		list-style-type: none;
		margin: auto;
		margin-top: 27px;
		padding-left: 20px;
		width: 100%;

		@include break-min($large2) {
			margin-top: 40px;
			width: $col10;
		}

		li {
			@include focus($red);
			border-radius: 5px;
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
			color: $red;
			cursor: pointer;
			display: inline-flex;
			font-weight: normal;
			justify-content: space-between;
			margin: 0 auto 20px;
			//margin: auto 0 10px;
			padding: 35px 15px 35px 25px;
			width: 100%;

			@include break-min($large1) {
				margin: 0 20px 20px 0;
				width: 45%;
			}

			.image-wrapper {
				position: relative;
				width: 30px;
			}

			// Created for icon images which are now abandoned.
			span {
				font-size: 25px;
			}
		}

		.selected {
			background: $red;
			color: $warm-white;
		}
	}

	.btn {
		margin: 20px auto 40px;
		@extend .btn-primary;
		display: block;
		width: fit-content;

		@include breakpoint(mobileOnly) {
			margin-top: 47px auto 6px;
			width: $col11;
		}
	}

	.mfp-close {
		color: $red;
		//right: -146px;
		//top: -40px;
	}
}
