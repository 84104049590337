.story-tags {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 56px 39px;

	@include break-min($large1) {
		margin: 125px 0 103px;
	}

	.label {
		margin: 0 12px 0 0;
		min-width: 70px;

		p {
			@include font-size(1.4);
			margin: 0;
			color: $grey;
			line-height: lh(23, 14); /* 164.286% */
			letter-spacing: -0.25px;
		}
	}

	ul {
		display: flex;
		list-style-type: none;
		padding: 0;
		margin: 0;
		flex-wrap: wrap;

		li {
			color: $red;
			border: 1px solid $red;
			border-radius: 50px;
			margin: 0 6px 10px 0;
			padding: 1px 15px;
			text-align: center;
			width: fit-content;

			a {
				@include font-size(1.4);
				color: $red;
				line-height: lh(23, 14);
				letter-spacing: -0.25px;
			}
		}
	}
}
