.illustration-carousel {
	@include grid-margin();

	@include break-min($large1) {
		justify-content: center;
	}

	.ic-wrapper {
		background: $cool-white;
		border-radius: 16px;
		display: block;
		grid-column: col-start 1 / span 4;
		padding: 87px 37px 34px;
		position: relative;
		text-align: center;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large1) {
			align-items: center;
			display: flex;
			padding: 0 37px 0;
			max-height: 508px;
			text-align: left;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 12;
		}
	}

	.carousel-items {
		@include break-min($large1) {
			margin: 0 auto;
			width: 100%;
		}
	}

	.sl-wrapper {
		@include break-min($large1) {
			align-items: center;
			display: flex !important;
			justify-content: center;
			padding: 0 50px 0 0;
		}
	}

	.g-img-container {
		margin: 0 auto 15px;
		min-height: 200px;
		position: relative;
		width: 100%;
		height: 100%;
		max-height: 800px;
		max-width: 550px;

		@include break-min($large1) {
			display: flex;
			flex: 1 0 50%;
			margin: 0 42px 0 0;
			width: auto;
		}

		img {
			margin: auto;
		}
	}

	.content {
		@include break-min($large1) {
			flex: 1;
			max-width: 500px;
			padding: 85px 0;
		}

		.title {
			margin: 0 0 12px;

			> :first-child {
				@include type-h3-refresh();
				color: $blue;
				margin: 0;
			}
		}

		.text {
			margin: 0 0 25px;

			> :first-child {
				@include type-p1-refresh();
				margin: 0;
			}
		}

		.btn {
			min-width: 186px;
		}
	}

	.navigation-arrow-handlers {
		align-items: center;
		position: absolute;
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: fit-content;
		padding: 0 35px;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		z-index: 100;

		a {
			cursor: pointer;
			display: block;
			margin: 0 -25px;
			height: 25px;
			width: 25px;

			@include break-min($large1) {
				height: 39px;
				width: 39px;
				margin: 0 -10px;
			}
		}
	}

	.slick-dots {
		@extend .slick-slider-dots-grey;
		display: none;
		height: fit-content;
		top: -60px;

		@include break-min($large1) {
			bottom: 0;
			margin: auto;
			left: 105px;
			top: -360px;
		}

		li.slick-active {
			button {
				background: $red;

				&:before {
					color: $red;
				}
			}
		}
	}

	&.disable-prev-arrow {
		[data-prev-arrow] {
			cursor: not-allowed;
			opacity: 0.1;
			pointer-events: none;
		}
	}

	&.disable-next-arrow {
		[data-next-arrow] {
			cursor: not-allowed;
			opacity: 0.1;
			pointer-events: none;
		}
	}
}
