.half-banner-img-txt {
	padding: 72px 0;

	@include break-min($large1) {
		padding: 100px 0;
	}

	.banner-item {
		@include flexbox;
		@include flex-direction(column-reverse);

		@include break-min($large1) {
			@include flex-direction(row);
		}

		.img-container {
			flex: 1 0 50%;
		}

		.content {
			margin-bottom: 25px;
			text-align: left;

			@include break-min($large1) {
				margin-right: 100px;
			}

			h2 {
				@include font-size(2.8);
				color: $blue;
				font-family: $primary;
				font-weight: 700;
				letter-spacing: -0.5px;
				margin: 2rem 0;
				line-height: 34px;
				text-align: center;

				@include break-min($large1) {
					@include font-size(3.6);
					line-height: 42px;
					text-align: left;
				}
			}

			P {
				line-height: 28px;
			}
		}
	}
}
