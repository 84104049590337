.testimonial {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@include break-min($large2) {
		align-items: center;
		flex-direction: row-reverse;
		justify-content: center;
		padding: 96px 0;
	}

	@include break-min($huge2) {
		padding: 72px 0;
	}

	.img-container {
		height: auto;
		width: 100%;

		@include break-min($large2) {
			width: 41%;
		}

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;

			@include break-min($medium2) {
				height: auto;
			}
		}
	}

	.img-desktop {
		display: none;

		@include break-min($large2) {
			display: block;
			margin-right: -7%;
		}
	}

	.img-mobile {
		display: block;
		height: 310px;

		@include break-min($medium2) {
			overflow: hidden;
		}

		@include break-min($large2) {
			display: none;
		}
	}

	.quote {
		margin: auto;
		padding-top: 100px;
		padding-bottom: 70px;
		width: 75%;

		@include break-min($large1) {
			margin: auto;
			padding: 110px 0 78px;
			width: 63%;
		}

		@include break-min($large2) {
			padding: 0 39px 0 6%;
			width: 59%;
		}

		@include break-min($huge2) {
			padding: 0 64px 0 8%;
			width: 63%;
		}

		p {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(36, 28);
			margin-bottom: 0;
			position: relative;
			text-align: left;

			@include break-min($large1) {
				line-height: lh(34, 28);
			}

			@include break-min($large2) {
				@include font-size(3.6);
				line-height: lh(42, 36);
			}

			span {
				@include font-size(1.8);
				display: block;
				font-weight: $bold-weight;
				letter-spacing: -0.5%;
				line-height: lh(22, 18);
				margin-top: 32px;
				text-align: right;
			}

			&:before {
				content: "\0022";
				left: -10px;
				position: absolute;
				top: -15px;

				@include break-min($large2) {
					left: -15px;
					top: -20px;
				}
			}
		}
	}
}
