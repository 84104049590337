.gift-container {
	$ie-margin-left: 750px;
	$ie-max-width: 800px;

	&.full-bleed {
		@include break-min($large1) {
			border-left: none;
			border-right: none;
			grid-template-rows: 0.6fr 0.1fr 0.1fr auto;
			height: 100vh;
			max-width: none;
			position: relative;

			@include ie-only() {
				grid-template-rows: 0.2fr 0.3fr 0.3fr auto;
			}
		}

		.gifting-title {
			@include break-min($large1) {
				align-self: flex-end;
				margin: 0;
				max-width: $col9;
				padding: 0;

				@include ie-only() {
					grid-row: 1;
					margin-left: $ie-margin-left;
					margin-top: 200px;
					max-width: $ie-max-width;
				}
			}

			h3 {
				@include break-min($large1) {
					@include font-size(4.8);
					letter-spacing: -1px;
					line-height: calc(58 / 48);
					margin-bottom: 24px;
				}
			}

			p {
				@include break-min($large1) {
					display: none;
				}
			}
		}

		.gifting-img-tile {
			@include break-min($large1) {
				height: 100%;
				margin: 0 140px 0 0;
				padding: 0;

				@include ie-only() {
					grid-row: 1 / span 4;
					max-width: 600px;
				}

				img {
					@include break-min($large1) {
						max-height: none;
					}
				}

				h4 {
					display: none;
				}
			}
		}

		.gifting-msg-details {
			@include break-min($large1) {
				align-self: flex-start;
				grid-column: 2;
				margin: 0;
				max-width: $col9;
				padding: 0;

				@include ie-only() {
					grid-row: 2;
					margin-left: $ie-margin-left;
					max-width: $ie-max-width;
				}

				.module:first-child {
					p:first-child {
						@include font-size(1.8);
						letter-spacing: 4px;
						line-height: calc(20 / 18);
						text-transform: uppercase;
					}

					p:nth-child(2) {
						@include font-size(1.8);
					}
				}

				.module:nth-of-type(2) {
					@include break-min($large1) {
						display: none;
					}
				}
			}
		}

		.gifting-explain-note {
			@include break-min($large1) {
				@include ie-only() {
					grid-row: 3;
					margin-left: $ie-margin-left;
					max-width: $ie-max-width;
				}
			}
		}

		.gifting-btn-container {
			@include break-min($large1) {
				align-self: flex-start;
				grid-column: 2;
				margin: 40px 0 0;
				max-width: $col9;
				padding: 0;

				@include ie-only() {
					grid-row: 4;
					margin-left: $ie-margin-left;
					max-width: $ie-max-width;
				}
			}
		}
	}
}
