.codemoo-hero {
	padding: 60px 0;
	margin: 0 31px;
	position: relative;

	@include break-min($large1) {
		padding: 100px 0 70px;
	}

	&.hero-launch {
		visibility: visible;
		padding-top: 30px;

		@include break-min($large1) {
			padding: 100px 130px 70px;
		}

		@include break-min($huge4) {
			padding: 100px 150px 70px;
		}

		.codemoo-hero-header {
			display: flex;
			justify-content: center;
			flex-flow: column;
			align-items: center;
			width: 100%;
			max-width: none;
			position: relative;
			margin: 0 auto 30px;
			padding-top: 45px;

			@include break-min($large1) {
				padding-top: 45px;
				margin: 0 auto 55px;
			}

			&__hero {
				background-size: cover;
				background-position: center center;
				margin: 0 72px 60px;
				width: 100%;
				height: 402px;
				position: absolute;
				top: 0;
				z-index: -1;
				border-radius: 22px;

				@include break-min($large1) {
					height: 454px;
				}

				.cloud-left {
					position: absolute;
					top: 30px;
					left: -44px;
					width: 100px;

					@include break-min($large1) {
						top: 50px;
						left: -75px;
						width: auto;
					}
				}

				.cloud-right {
					right: -47px;
					position: absolute;
					top: 70px;
					width: 120px;

					@include break-min($large1) {
						right: -69px;
						top: 80px;
						width: auto;
					}
				}
			}

			.logo-picture {
				width: 194px;

				@include break-min($large1) {
					width: 277px;
				}
			}

			&-content {
				display: flex;
				flex-flow: column;
				align-items: center;
				margin-top: 110px;
				max-width: 715px;
				text-align: center;

				@include break-min($large1) {
					margin-top: 40px;
				}

				p {
					margin: 0;
				}

				span {
					margin: 84px 0 40px;
					font-weight: $bold-weight;
					color: $red;
					letter-spacing: 8px;
					text-transform: uppercase;
					@include font-size(2.4);
				}

				picture {
					margin-bottom: 24px;
					width: 263px;

					@include break-min($large1) {
						width: auto;
					}
				}

				.btn {
					width: 70%;
					margin-top: 24px;
					@extend .btn-primary;

					@include break-min($large1) {
						width: auto;
					}
				}
			}
		}

		.parallax-section {
			.spot {
				&-left {
					left: -42px;
					top: 540px;
					width: 35px;

					@include break-min($large1) {
						left: -62px;
						width: auto;
						top: 340px;
					}
				}

				&-top {
					top: 528px;
					left: 25px;
					width: 20px;
				}

				&-right {
					right: -32px;
					top: 668px;
					width: 30px;

					@include break-min($large1) {
						right: -32px;
						top: 640px;
						width: auto;
					}
				}

				&-bottom {
					left: 0;
					right: 0;
					top: 1417px;
					margin-left: auto;
					margin-right: auto;
					z-index: -1;
					position: absolute;

					@include break-min($large1) {
						top: 990px;
					}
				}
			}
		}
	}

	&.hero-post-launch {
		.parallax-section {
			.spot {
				position: absolute;

				&-top {
					top: 136px;
					left: 35px;
					width: 20px;

					@include break-min($large1) {
						top: 100px;
						width: auto;
						left: 0;
					}
				}

				&-left {
					left: -36px;
					top: 193px;
					width: 35px;

					@include break-min($large1) {
						left: -62px;
						width: auto;
						top: 200px;
					}
				}

				&-right {
					right: -40px;
					top: 578px;
					width: 38px;

					@include break-min($large1) {
						right: -32px;
						top: 640px;
						width: auto;
					}
				}

				&-bottom {
					left: 0;
					right: 0;
					top: 690px;
					margin-left: auto;
					margin-right: auto;
					z-index: -1;
					position: absolute;

					@include break-min($large1) {
						top: 1025px;
					}
				}
			}
		}

		.codemoo-hero-header {
			position: relative;

			> picture {
				width: 220px;
			}

			@include break-min($large1) {
				margin-bottom: 80px;

				> picture {
					width: 580px;
				}
			}

			&-thumbs {
				position: absolute;
				top: 0;
				width: 100%;

				.thumb {
					position: absolute;
					border-radius: 8px;

					@include break-min($large1) {
						border-radius: 22px;
					}

					&.thumb-top-left {
						left: -52px;
						top: -40px;
						width: 76px;

						@include break-min($large1) {
							left: -150px;
							top: -50px;
							width: 176px;
						}
					}

					&.thumb-top-right {
						right: -40px;
						top: -10px;
						width: 56px;

						@include break-min($large1) {
							right: -50px;
							top: -10px;
							width: 114px;
						}
					}

					&.thumb-bottom-left {
						left: -6px;
						top: 150px;
						width: 75px;

						@include break-min($large1) {
							left: -60px;
							top: 290px;
							width: 160px;
						}
					}

					&.thumb-bottom-right {
						right: -40px;
						top: 104px;
						width: 91px;

						@include break-min($large1) {
							right: -250px;
							top: 162px;
							width: 264px;
						}
					}
				}
			}

			.codemoo-hero-header-comingsoon {
				picture {
					@include break-min($large1) {
						margin-bottom: 44px;
					}
				}
			}
		}
	}

	.parallax-section {
		.spot {
			position: absolute;

			&-top {
				top: 216px;
				left: -15px;
				width: 20px;

				@include break-min($large1) {
					top: 100px;
					width: auto;
					left: 0;
				}
			}

			&-left {
				left: -42px;
				top: 340px;
				width: 35px;

				@include break-min($large1) {
					left: -62px;
					width: auto;
					top: 200px;
				}
			}

			&-right {
				right: -32px;
				top: 808px;
				width: 30px;

				@include break-min($large1) {
					right: -32px;
					top: 640px;
					width: auto;
				}
			}

			&-bottom {
				left: 0;
				right: 0;
				top: 1917px;
				margin-left: auto;
				margin-right: auto;
				z-index: -1;
				position: absolute;

				@include break-min($large1) {
					top: 1480px;
				}
			}
		}
	}

	&-header {
		display: flex;
		justify-content: center;
		flex-flow: column;
		align-items: center;
		max-width: 800px;
		margin: 0 auto;

		&-subheadline {
			h5 {
				text-transform: uppercase;
				letter-spacing: -0.5px;
				font-weight: 500;
			}
		}

		&-comingsoon {
			display: flex;
			flex-flow: column;
			align-items: center;
			text-align: center;

			p {
				margin: 0;
			}

			span {
				margin: 84px 0 40px;
				font-weight: $bold-weight;
				color: $red;
				letter-spacing: 8px;
				text-transform: uppercase;
				@include font-size(2.4);
			}

			picture {
				margin-bottom: 24px;
			}

			.btn {
				width: 70%;
				margin-top: 24px;
				@extend .btn-primary;

				@include break-min($large1) {
					width: auto;
				}
			}
		}
	}
}
