.membership-landing {
	visibility: visible;

	.frame {
		@include flexbox();
		@include flex-wrap(nowrap);
		@include justify-content(stretch);
	}

	.membership-navigation {
		position: relative;
		z-index: 300;
		min-width: 360px;
		flex: 0 0 auto;
		padding: 40px 0;
		background-color: $warm-white;
		display: none;

		@include break-min($large1) {
			@include flexbox();
			@include flex-flow(column);
		}
	}

	.membership-content {
		flex: 1 1 auto;
		padding: 55px 0 100px;
		@include flexbox();
		@include flex-flow(column);

		@include break-min($large1) {
			max-width: 1090px;
		}

		&-wrapper {
			padding: 0 15px;
			margin: 0 auto;
			width: 100vw;

			@include break-min($large1) {
				max-width: 880px;
			}
		}
	}

	.membership-tiers {
		.member-nav {
			@include break-min($large1) {
				max-width: 880px;
			}
		}
	}
}

.membership-modal {
	max-width: 750px;

	.cp-lightbox__inner {
		@include break-min($large1) {
			padding: 20px 15px;
		}
	}

	&.nge-redeem {
		.cp-lightbox__inner {
			padding: 10px 25px;

			@include break-min($large1) {
				padding: 20px 15px;
			}
		}

		.nge-redeem__reward {
			.nge-redeem__img-wrap {
				@include break-min($large1) {
					padding: 10px 60px;
				}

				img {
					width: auto;
					max-height: 300px;
					margin: 0 auto;
				}
			}

			h4 {
				font-weight: 700;
				padding: 5px;
				@include font-size(2.2);

				@include break-min($large1) {
					padding: 10px;
					@include font-size(2.8);
				}
			}
		}

		.nge-redeem__ctas {
			max-width: 100%;

			@include break-min($large1) {
				max-width: 50%;
			}

			.btn {
				padding: 20px 19px;
			}
		}
	}
}
