.cfa-impact {
	&.infographic {
		display: none;
	}

	&.infographic-subcomponent {
		.header {
			@include font-size(2.8);
			line-height: lh(34, 28);
			margin-bottom: 40px;

			@include break-min($large2) {
				@include font-size(4.8);
				line-height: lh(58, 48);
				margin-bottom: 16px;
			}
		}

		.container--circle-block {
			.container--column {
				@include break-min($large2) {
					padding-top: 48px;
				}

				@include break-min($huge2) {
					flex: 1 0 21%;
				}
			}
		}
	}
}
