//Justification: working
//within legacy sheet here.
//Parent wrapper div
//needs to remain unfortunately

//sass-lint:disable max-file-line-count

.lll {
	background: $cream;
	padding: 20px 0 0;
	position: relative;

	.btn-container {
		margin-top: 23px;
		padding: 0 24px;
		width: 100%;

		@include break-min($large1) {
			display: flex;
			justify-content: center;
			margin-bottom: 130px;
			padding: 0;
			width: auto;
		}

		.btn {
			@extend .btn-primary;
			margin: 0 5px;

			&-white {
				@extend .btn-secondary;
			}
		}
	}

	.spinner-container {
		position: fixed;
		transform: translateX(-54%);
		left: 50%;
		top: 54%;
	}

	.lll-input__tas-fraud .lll-cards-animation {
		margin-top: 38px;
		margin-bottom: 24px;

		@include break-min($large1) {
			margin-top: 64px;
			margin-bottom: 40px;
		}
	}

	.lll-cards-animation {
		top: -50px;
		opacity: 0;
	}

	@include breakpoint(tablet) {
		padding: 30px 0 0;
	}

	&--full {
		min-height: 100vh;
	}

	li {
		list-style-type: none;
	}

	.footer {
		margin: 65px 0 0 0;

		@include breakpoint(tablet) {
			margin: 100px 0 0 0;
		}
	}

	.input {
		display: inline-block;
		position: relative;
		height: auto;
		max-width: 100%;
		min-height: 68px;
		max-height: 68px;
		overflow: hidden;

		@include breakpoint(tablet) {
			max-width: 336px;
		}

		&--full {
			max-width: 100%;
			width: 100%;
		}

		&__wrapper {
			.input__wrapper {
				position: relative;
				display: block;
			}
		}

		&__label {
			@include font-size(1.2);
			color: $grey;
			font-family: $primary;
			font-weight: normal;
			left: 20px;
			letter-spacing: 0;
			position: absolute;
			top: 11px;
			z-index: 100;
		}

		&.error {
			input {
				border: 3px solid $red;
				color: $red;

				&::placeholder {
					color: $red;
				}
			}

			label {
				color: $red;
			}
		}

		.message {
			@include font-size(1.2);
			color: $vis-grey;
			display: inline-block;
			font-family: $primary;
			margin-bottom: 10px;
		}

		input {
			@include font-size(1.8);
			border-radius: 5px;
			border: 3px solid $vis-grey;
			bottom: 0;
			color: $vis-grey;
			font-family: $primary;
			height: calc(100% - 16px);
			left: 0;
			letter-spacing: -0.5px;
			line-height: 32px;
			min-height: 68px;
			padding: 25px 17px 4px 16px;
			position: relative;
			transition: border 0.5s ease-in-out;
			width: 100%;

			&:focus {
				border: 3px solid $vis-blue;
			}

			&.orig-border {
				border: 3px solid $faint-grey;
			}

			&.error {
				border: 3px solid $red;
				color: $red;
			}
		}

		::placeholder {
			// Chrome, Firefox, Opera, Safari 10.1+
			color: $light-grey;
			opacity: 1; // Firefox
		}
	}

	.date-selector {
		margin-bottom: 0;

		input {
			position: relative;

			&::placeholder {
				color: $light-grey;
				opacity: 1;
				text-transform: uppercase;
			}
		}

		.datepicker {
			&::placeholder {
				color: $light-grey;
				text-transform: uppercase;
			}
		}
	}

	.label {
		color: $vis-grey;
		font-family: $secondary;
		font-size: 23px;
		font-weight: 800;
		line-height: 3px;
		letter-spacing: 6px;
		text-transform: uppercase;

		&--medium {
			font-size: 17px;
			line-height: 17px;
			letter-spacing: 4px;
		}

		&--small {
			font-size: 11px;
			line-height: 11px;
			letter-spacing: 2px;
		}

		&--blue {
			color: $vis-blue;
		}
	}

	.selectlist {
		background-color: $white;
		border: 3px solid $vis-grey;
		border-radius: 5px;
		color: $vis-grey;
		cursor: pointer;
		display: inline-block;
		margin: 0;
		min-height: 68px;
		overflow: hidden;
		padding: 0;
		position: relative;
		text-align: left;
		outline: none;
		transition: all 0.5s ease-in-out;

		@include breakpoint(tablet) {
			max-width: 336px;
		}

		&.error {
			border: 3px solid $red;
			color: $red;

			label,
			option {
				color: $red;
			}
		}

		.select-label {
			@include font-size(1.2);
			font-weight: normal;
			left: 19px;
			line-height: 8px;
			position: absolute;
			top: 11px;
			z-index: 100;
		}

		.label {
			@include font-size(1.8);
			color: $light-grey;
			font-family: $primary;
			letter-spacing: 0;
			line-height: 32px;
			padding: 20px 20px 10px 16px;
			transition: all 0.5s ease-in-out;
			text-transform: none;
		}

		&:hover {
			border: 3px solid $vis-blue;

			.label {
				color: $vis-grey;
			}

			.options {
				a {
					color: $vis-grey;
				}
			}
		}
	}

	.option-select {
		@include breakpoint(tablet) {
			max-width: 336px;
		}

		select {
			line-height: 68px;
			min-height: 58px;
			padding: 8px 10px 3px 7px;
		}

		option {
			@include font-size(1.8);
			color: $light-grey;
			font-family: $primary;
			letter-spacing: 0;
			text-transform: none;
		}

		&--full {
			max-width: 100%;
			width: 100%;

			.selectlist {
				max-width: 100%;
			}
		}
	}

	.options {
		@include font-size(1.8);
		color: $vis-grey;
		cursor: pointer;
		display: none;
		font-family: $primary;
		font-weight: normal;
		letter-spacing: -0.5px;
		line-height: 32px;
		position: relative;
		// sass-lint:disable no-vendor-prefixes
		-webkit-font-smoothing: antialiased;
		// sass-lint:enable no-vendor-prefixes
		transition: color 0.5s ease-in-out;

		a {
			color: $light-grey;
			display: block;
			padding: 17px 20px 15px 16px;

			&:hover {
				background: $cool-white;
			}
		}
	}

	.icon-arrow-down {
		right: 24px;
		top: 24px;

		&:before {
			@include font-size(2.4);
		}
	}

	.btn--vis {
		font-weight: 600;
		transition: all 0.3s ease;
		@include font-size(2.4);

		@include break-min($large1) {
			@include font-size(1.8);
		}

		&--white {
			border: none;
			padding: 31px 15px;

			@include break-min($large1) {
				padding: 55px 15px 55px;
			}
		}

		&--has-icon {
			font-family: $primary;
			font-weight: 800;
			font-size: 23px;
			line-height: 36px;
			letter-spacing: -0.5px;
			position: relative;
			text-align: left;

			&__img {
				bottom: 0;
				opacity: 1;
				position: absolute;
				right: 14px;
				top: 50%;
				transform: translate(0, -50%);
				transition: opacity 0.4s ease-in-out, top 0.6s ease-in-out;
				width: 30px;

				&--selected {
					opacity: 0;
					top: 35px;
				}
			}

			@include break-min($large3) {
				padding-right: 22px 45px 20px 15px;
			}
		}

		&:hover {
			background: $white;
			color: $red;
		}

		&.active {
			background: $red;
			color: $white;

			.btn--vis--has-icon__img {
				opacity: 0;
				top: 35px;

				&--selected {
					opacity: 1;
					top: 50%;
				}
			}
		}
	}
}
