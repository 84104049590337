.site-down-wrapper {
	position: relative;
	overflow: hidden;
	height: 100vh;

	.plate {
		position: absolute;
		background-repeat: no-repeat;
		background-size: contain;

		&.plate-top-left {
			background-image: url("img/plate2.png");
			z-index: 3;
			width: 400px;
			height: 400px;
			transform: translateY(-50%);
			background-position-x: -165px;

			@include break-min($medium2) {
				width: 600px;
				height: 600px;
				transform: translateY(-52%);
				background-position-x: -184px;
			}

			@include break-min($large2) {
				width: 800px;
				height: 800px;
				transform: translateY(-58%);
				background-position-x: -264px;
			}

			@include break-min($huge2) {
				transform: translateY(-58%);
				background-position-x: -180px;
			}
		}

		&.plate-top-right {
			background-image: url("img/plate1.png");
			width: 600px;
			height: 600px;
			transform: translateY(-50%);
			right: 10%;
			display: none;

			@include break-min($medium2) {
				width: 600px;
				height: 600px;
				transform: translateY(-52%);
				background-position-x: -184px;
			}

			@include break-min($large2) {
				display: block;
				width: 560px;
				height: 560px;
				transform: translateY(-54%);
				background-position-x: 56px;
				right: 0;
			}

			@include break-min($huge2) {
				transform: translateY(-54%);
				background-position-x: -54px;
				right: 220px;
			}
		}

		&.plate-bottom-right {
			background-image: url("img/plate3.png");
			width: 260px;
			height: 260px;
			transform: translateY(3%);
			right: 0;
			background-position-x: 91px;

			@include break-min($medium2) {
				width: 500px;
				height: 500px;
				transform: translateY(-8%);
				background-position-x: 228px;
			}

			@include break-min($large2) {
				width: 700px;
				height: 700px;
				transform: translateY(22%);
				background-position-x: 316px;
			}

			@include break-min($huge2) {
				background-position-x: 96px;
			}
		}
	}

	.wrapper {
		padding-top: 230px;

		@include break-min($medium2) {
			padding-top: 300px;
		}

		@include break-min($large2) {
			padding-top: 310px;
		}

		@include break-min($huge2) {
			padding-top: 320px;
		}

		.wrapper-content {
			width: 100%;
			text-align: center;

			.btn-round {
				width: 100%;

				@include break-min($medium2) {
					width: auto;
				}
			}

			@include break-min($medium2) {
				text-align: left;
				width: 80%;
			}

			@include break-min($large2) {
				width: 50%;
			}

			h1 {
				@include font-size(3.6);
				color: $blue;
				font-weight: bold;
				letter-spacing: -0.5px;
				line-height: 1.16667;
			}

			p {
				margin-bottom: 30px;
			}

			.error-code {
				p {
					margin-top: 30px;
					color: $rolling-stone;
				}
			}
		}
	}
}
