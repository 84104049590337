.food-tile-callout {
	background: $cool-white;
	padding: 34px 0;

	.bg-wrapper {
		background: $white;
		border-radius: 32px;
		padding: 15px 15px 36px;

		@include break-min($large1) {
			@include desktop-grid();
			@include desktop-grid-max-width();
			column-gap: 24px;
			padding: 0 15px 0 0;
		}

		.img-container {
			position: relative;
			height: 216px;
			margin: 0 0 24px 0;
			width: 100%;

			@include break-min($large1) {
				grid-column: col-start 1 / span 4;
				height: 300px;
				margin: 0;
			}

			img {
				@include absolute-center();
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}

		.content {
			text-align: center;

			@include break-min($large1) {
				grid-column: col-start 5 / span 7;
				margin: auto;
				text-align: left;
			}

			.label p {
				@include font-size(1.8);
				color: $blue;
				font-weight: $medium-weight;
				line-height: lh(28, 18);
				letter-spacing: -0.5px;
				margin: 0 0 8px 0;
			}

			.title {
				> *:first-child {
					@include font-size(3.6);
					color: $blue;
					font-weight: $bold-weight;
					line-height: lh(46, 36);
					letter-spacing: -0.8px;
					margin: 0 0 24px 0;

					@include break-min($large1) {
						@include font-size(4.8);
						line-height: lh(58, 48);
						letter-spacing: -1px;
						margin: 0 0 16px 0;
					}
				}
			}

			.subtext p {
				@include font-size(1.8);
				line-height: lh(28, 18);
				letter-spacing: -0.5px;
				margin: 0 0 24px 0;

				@include break-min($large1) {
					margin: 0 0 16px 0;
				}
			}

			.btn-container {
				.btn-round {
					margin: auto;
					width: fit-content;
				}
			}
		}
	}
}
