// News cards
.news-card {
	text-align: center;
	margin: 0;
	overflow: hidden;
	background-color: transparent;
	padding: 8px;
	max-width: 390px;
	@include flex(0 0 100%);

	@include break-min($small5) {
		padding: 16px 8px;
		max-width: 376px;
		@include flex(0 0 50%);
	}

	@include break-min($large1) {
		@include flex(0 0 100%);
	}

	@include break-min($large2) {
		@include flex(0 0 50%);
	}

	// grid and wrapper styles
	&__wrap {
		position: relative;
		padding: 0 0 40px;

		&-heading {
			position: relative;
			padding: 0 0 30px;

			h1,
			h2 {
				@include h3();
				color: $vis-blue;
				padding: 00;
				margin: 0;
			}

			@include break-min($small3) {
				min-height: 98px;

				@include flexbox();
				@include justify-content(space-between);
				@include align-items(flex-end);
				@include flex-wrap(wrap);

				> * {
					@include flex(0 0 auto);
				}

				h1,
				h2 {
					padding: 0 20px 0 0;
					margin: 0 0 -12px;
				}
			}
		}
	}

	&__grid {
		margin: 0 -8px;

		@include flexbox();
		@include justify-content(center);
		@include align-items(stretch);
		@include flex-wrap(wrap);

		@include break-min($small5) {
			@include justify-content(space-between);
		}
	}

	// card styles
	&__border {
		height: 100%;
		border-right: 4px solid $yellow-orange;
		border-left: 4px solid $yellow-orange;
		overflow: hidden;
		border-radius: 8px;
		padding: 19px 0 0 0;
		background-color: $yellow-orange;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
	}

	&__overlay {
		padding: 18px 15px;
		background-color: $white;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: $z3;
		// @include flexbox();
		display: none;
		@include justify-content(center);
		@include align-items(stretch);
		@include flex-direction(column);

		&--visible {
			@include flexbox();
		}

		> h5 {
			@include font-size(3.2);
			line-height: lh(48px, 32px);
			letter-spacing: -0.7px;
			font-weight: $bold-weight;

			padding: 0 0 8px 0;
			margin: 0;
			color: $vis-blue;
			display: block;
		}
	}

	&__checkmark {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		width: 48px;
		height: 48px;
		margin: 10px auto;
		background-image: url("img/cp-icons/icon-cp_checkmark.svg");
	}

	&__inner {
		position: relative;
		height: 100%;
		min-height: 200px;
		background-color: $white;
		padding: 18px 15px 24px 15px;
		border-radius: 8px 8px 0 0;

		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(stretch);
		@include flex-direction(column);

		> h5 {
			@include s3();
			line-height: lh(15px, 11px);
			color: $vis-blue;
			text-transform: uppercase;
			margin: 0;
			padding: 0 0 10px 2px; // account for letter-spacing shift

			+ p {
				padding: 15px 0;
			}
		}

		> p {
			@include p2();
			color: $vis-grey;
			max-width: 290px;
			margin: 0 auto;
			padding: 10px 0 15px;

			&.news-card__red-text {
				color: $red;
				font-weight: $bold-weight;
			}
		}

		> .btn-round {
			align-self: center;
			margin: 10px auto;
		}

		@include break-min($large1) {
			min-height: 481px;
		}
	}

	&__title {
		@include font-size(3.2);
		line-height: lh(48px, 32px);
		letter-spacing: -0.7px;
		font-weight: $bold-weight;
		padding: 0 0 10px 0;
		margin: 0 0 15px;
		color: $vis-blue;
		display: block;
		position: relative;

		&::after,
		&::before {
			content: "";
			width: 4px;
			height: 4px;
			background-color: $vis-blue;
			display: block;
			border-radius: 50%;
			position: absolute;
			bottom: 1px;
			left: 0;
			right: 0;
			margin: auto;
		}

		&::after {
			box-shadow: 10px 0 0 0 $vis-blue;
		}

		&::before {
			left: -20px;
		}
	}

	&__img {
		display: block;
		height: auto;
		width: 100%;
		max-width: 175px;
		margin: 0 auto;

		&-wrap {
			padding: 20px 0;
		}
	}

	&__footer {
		padding: 18px 0 0;
		position: relative;
		margin: auto 0 -4px 0;

		&-bar {
			padding: 25px 0 0;

			@include flexbox();
			@include justify-content(stretch);
			@include align-items(center);
			@include flex-wrap(wrap);

			p,
			a {
				@include p2();
				padding: 0;
				margin: 0;
				display: block;
				text-align: center;

				@include flex(1 1 50%);

				&:first-child {
					text-align: left;
				}

				&:last-child {
					text-align: right;
				}

				&:only-child {
					text-align: center;
				}
			}

			a {
				display: block;
				font-weight: $bold-weight;

				&:focus {
					outline-offset: 3px;
					outline: 1px dotted $red;
				}
			}
		}
	}

	&__error {
		@include p2();
		text-align: center;
		background-color: $lred;
		border-radius: 8px;
		color: $vis-grey;
		padding: 62px 15px 15px 15px;
		font-weight: $medium-weight;
		margin: 0;
		position: relative;

		+ .btn-round {
			margin-top: 15px;
		}

		&::before {
			display: block;
			position: absolute;
			background-image: url("img/cp-icons/icon-cp_warning.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			top: 16px;
			left: 0;
			right: 0;
			margin: auto;
			width: 36px;
			height: 36px;
			content: "";
		}
	}

	.btn-round {
		width: auto;
		margin: auto;

		+ .news-card__error {
			margin: 15px 0 0;
		}
	}
}
