.customer-support-search-header {
	background: $blue;
	color: $white;
	padding: 80px 24px 121px;
	text-align: center;

	@include break-min($large1) {
		padding: 104px 0 135px;
	}

	p {
		@include font-size(1.6);
		letter-spacing: 2px;
		line-height: lh(20, 16);
		margin: 0 0 16px 0;
		text-transform: uppercase;
	}

	h1 {
		@include font-size(4.8);
		font-weight: $bold-weight;
		letter-spacing: -1px;
		line-height: lh(54, 48);
		margin: 0 0 48px 0;

		@include break-min($large1) {
			@include font-size(7.8);
			letter-spacing: -2px;
			line-height: lh(84, 78);
		}
	}
}
