.homepage-menu-callout {
	padding: 0 0 64px;

	@include break-min($large1) {
		align-items: center;
		height: 500px;
		padding: 0;
	}

	.flex-wrapper {
		justify-content: space-between;
	}

	.img-wrapper {
		@include break-min($large1) {
			order: 2;
		}
	}

	.content {
		padding: 0 24px;

		@include break-min($large1) {
			margin: 0;
			max-width: 700px;
			padding: 0 0 0 50px;
		}

		@include break-min($huge2) {
			order: 1;
			padding: 0 0 0 168px;
		}

		h4,
		p {
			text-align: left;
		}

		h4 {
			@include font-size(2.8);
			color: $blue;
			letter-spacing: -1px;
			line-height: lh(34, 28);
			margin-bottom: 18px;

			@include break-min($large1) {
				@include font-size(4.8);
				margin-bottom: 24px;
			}
		}

		p {
			@include font-size(1.8);
			font-weight: 400;
			letter-spacing: -0.5px;
			line-height: lh(28, 14);
			margin-bottom: 40px;

			@include break-min($large1) {
				@include font-size(1.6);
				line-height: lh(25, 16);
			}
		}
	}
}
