.two-column-with-cta {
	background-color: $warm-white;

	ul {
		list-style: none;
	}

	.component-wrapper {
		padding-top: 0;
		padding-bottom: 42px;

		@include break-min($large1) {
			padding-top: 100px;
			padding-bottom: 100px;
		}

		li {
			padding: 0 0 45px;

			&:last-child {
				padding: 0;
			}

			@include break-min($large1) {
				padding: 0 20px;
			}

			.img-container {
				margin-left: -15px;
				margin-right: -15px;

				@include break-min($large1) {
					margin-left: 0;
					margin-right: 0;
				}

				img {
					object-fit: cover;
					width: 100%;
					max-height: 520px;
				}
			}

			.content {
				justify-content: center;
				align-items: center;
				text-align: center;
				margin-top: 37px;
				padding: 0;
				@include flexbox();
				@include flex-flow(column);

				@include break-min($large1) {
					margin-top: 54px;
					padding: 0 20px;
				}

				p {
					margin: 0;
					line-height: 28px;
					@include font-size(1.8);
				}

				h2 {
					@include type-h1();
					color: $blue;
					line-height: lh(54, 48);
					margin: 0 0 22px;
					@include font-size(3.6);

					@include break-min($large1) {
						@include font-size(4.8);
					}
				}
			}

			.cta-container {
				justify-content: center;
				margin-top: 40px;
				align-items: center;
				@include flexbox();
				@include flex-flow(column);

				@include break-min($large1) {
					margin-top: 60px;
					@include flex-flow(row);
				}

				a {
					margin: 10px 5px;

					&:first-child {
						margin: 0 5px 10px;
					}

					@include break-min($large1) {
						margin: 0 5px;

						&:first-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
