.store-hours {
	flex-flow: column;

	.flex {
		justify-content: space-between;
	}

	p {
		@include font-size(1.6);
		letter-spacing: -0.5px;
		line-height: lh(24, 16);
		margin-bottom: 3px;
	}

	&-disclaimer {
		margin-top: 20px;
	}
}
