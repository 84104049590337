$props: (
	"position-top": "top",
	"position-right": "right",
	"position-left": "left",
	"position-initial": "initial",
	"position-bottom": "bottom",
	"position-center": "center"
);

.short-bottom-icon-hero {
	height: 370px;
	overflow: visible;
	background-position: center;
	position: relative;

	@include break-min($large1) {
		height: 340px;

		&.big-oval-icon {
			.oval-icon {
				width: 255px;
				bottom: -122px;
			}
		}
	}

	@each $key, $val in $props {
		&.#{$key}-mobile {
			@include break-max($large1) {
				background-position: center;
			}
		}

		&.#{$key}-desktop {
			@include break-min($large1) {
				background-position: center;
			}
		}
	}

	&.position-top {
		background-position: top;
	}

	&.position-right {
		background-position: right;
	}

	&.position-left {
		background-position: left;
	}

	&.position-initial {
		background-position: initial;
	}

	&.position-bottom {
		background-position: bottom;
	}

	&.position-center {
		background-position: center;
	}

	.oval-icon {
		bottom: -92px;
		left: 0;
		margin: 0 auto;
		position: absolute;
		right: 0;
		display: block;
		z-index: 1000;

		&.no-icon {
			display: none;
		}
	}

	.center-icon {
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
	}

	&.full-size-img {
		background: none !important;

		.promo-frame {
			padding: 0;
			height: 100%;
			overflow: hidden;
			max-width: 100%;

			picture {
				display: block !important;
				height: 100%;
			}

			img {
				width: 100%;
				object-fit: cover;
				height: 100%;
			}
		}
	}
}
