body {
	padding-top: 0;
}

.main-nav-logo-link {
	border-bottom: 1px solid $search-grey;
	display: flex;
	justify-content: space-between;
	padding: 8px 20px 10px;
	position: relative;
	width: 100%;

	@media screen and (min-width: $mobile) {
		padding: 8px 30px 10px; //taken from main-nav header
	}

	.align-left {
		text-align: left;
	}

	.main-nav {
		padding-top: 10px;
	}

	.logo {
		position: inherit;
		left: 0;
		top: 0;
	}

	.return {
		@include link-underline();
		cursor: pointer;
		margin: auto 20px auto 0;

		&::after {
			content: "";
			background-image: url("img/cp-icons/icon-cp_arrow-right.svg");
			width: 24px;
			height: 24px;
			background-size: cover;
			position: absolute;
			top: 0;
			right: 15px;
			bottom: 0;
			margin: auto;
			//pointer-events: none;
		}
	}
}

.breadcrumbs {
	display: flex;
	list-style-type: none;
	position: relative;
	padding-left: 5%;

	@media screen and (min-width: $mobile) {
		padding-left: 23%;
	}

	.selected {
		color: $red;

		a {
			@include link-underline();
		}
	}

	.no-display {
		display: none;
	}

	li:not(:last-child) {
		&:after {
			content: ">";
			padding: 0 5px;
		}
	}
}
