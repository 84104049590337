.community-request {
	margin: 70px 0;
	justify-content: center;
	text-align: center;

	&-wrapper {
		h3 {
			margin-top: 0;
			margin-bottom: 5px;
			text-transform: uppercase;
			color: $blue;
			@include font-size(1.1);
			@include s3();
		}

		p {
			margin-bottom: 20px;
			@include font-size(1.4);
		}
	}
}
