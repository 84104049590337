.first-100 {
	header {
		max-width: 1000px;
		width: 100%;
		margin: 50px auto;
		text-align: center;

		@include break-max($large1) {
			padding: 0 25px;
		}
	}

	h1 {
		@include font-size(4.8);
		color: $blue;
		font-weight: $bold-weight;
		letter-spacing: -0.01em;
		line-height: lh(34, 28);
		margin: 0 0 16px 0;

		@include break-min($large1) {
			grid-row: 1;
			grid-column: col-start 3 / span 8;
		}

		@include break-min($large1) {
			line-height: lh(58, 48);
			letter-spacing: -1px;
		}

		span {
			display: block;
		}
	}

	h2 {
		@include font-size(3.6);
		color: $blue;
		font-weight: $bold-weight;
		letter-spacing: -0.01em;
		line-height: lh(34, 28);
		margin: 56px 0 16px 0;
	}

	.info p {
		margin: 0;
	}

	.questions {
		background: $cool-white;
		max-width: 1297px;
		width: 90%;
		margin: 0 auto 64px;
		padding: 64px 72px;
		border-radius: 32px;
		text-align: center;

		h2 {
			margin-top: 0;
		}
	}

	&--error {
		h1,
		p {
			color: $red;
		}
	}
}

.first-100-form {
	max-width: 448px;
	a {
		text-decoration: underline;
	}

	.input {
		margin-bottom: 40px;

		&.g-checkbox {
			padding: 0;
			margin-bottom: 15px;
			input {
				display: none;
			}
		}
	}

	.acknowledge-wrapper {
		padding-top: 40px;
		p {
			@include font-size(1.2);
			line-height: 166%;
		}
	}
}
