.platform-hero {
	background-color: $cream;
	margin-bottom: 64px;

	.container {
		grid-column: col-start 1 / span 12;
		min-height: 637px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 32px;
		position: relative;
		align-items: center;
		padding: 0 72px;

		.text-content {
			margin-top: 0;

			.eyebrow {
				display: flex;
				align-items: center;
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 8px;

				img {
					margin-right: 8px;
				}
			}

			h1 {
				font-family: $primary;
				color: $red;
				font-weight: 700;
				@include font-size(8);
				margin: 0 0 12px;
			}

			p {
				color: $grey-p;
				line-height: 155%;
				@include font-size(1.6);
				margin-bottom: 24px;
			}

			a {
				margin-right: 15px;
			}

			.video-container {
				width: 100%;
				height: 100%;
				align-items: center;
				display: flex;
			}
		}

		.img-content {
			position: relative;
			height: 100%;

			img {
				width: 100%;
				position: absolute;
				top: 0;
			}

			.eyebrow-image {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 1;
				width: auto;
			}
		}

		.video-container {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			position: relative;

			@include break-min($large1) {
				width: 100%;
			}

			@include break-min($large4) {
				width: 100%;
			}

			.player-wrapper {
				position: relative;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: 0;
				transition: opacity 0.3s ease;
				width: 100%;

				@include break-min($large1) {
					position: absolute;
				}

				.video-js {
					height: auto;
					width: 100%;
					display: block;
					position: relative;

					@include break-min($large1) {
						transform: translateY(-50%);
						top: 50%;
					}

					video {
						position: relative;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		@include break-max($medium1) {
			grid-template-columns: repeat(1, 1fr);
			padding: 36px 24px;
			align-items: start;
			min-height: auto;

			.text-content {
				margin-bottom: 24px;

				h1 {
					@include font-size(4);
				}

				a {
					margin: 0 0 14px;
				}
			}

			.img-content {
				img {
					width: 100%;
					position: unset;
				}
			}
		}
	}

	&.cfa-one {
		background: $white;
		margin: 0;

		.grid-custom-layout {
			max-width: none;

			@include break-min($huge4) {
				grid-template-columns: repeat(12, [col-start] 1fr);
			}

			@include break-min($huge5) {
				max-width: 1762px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.container {
			padding: 36px 0 0;

			@include break-min($medium4) {
				grid-column-gap: 0;
				padding: 0;
			}

			.text-content {
				padding: 0 24px;

				@include break-min($medium4) {
					padding: 59px 52px 59px 72px;
					margin: auto;
				}

				h1 {
					@include type-b1();
					margin: 0 0 32px;

					@include break-min($medium4) {
						height: 100%;
					}
				}

				p {
					@include type-p1-refresh();
					margin: 0 0 24px;

					@include break-min($medium4) {
						margin: 0 0 32px;
					}
				}
			}

			.ctas {
				a:first-of-type {
					margin: 0 0 36px;
					width: 100%;

					@include break-min($medium4) {
						margin: 0;
						min-width: 240px;
						width: auto;
					}
				}
			}

			.img-content {
				height: 290px;
				overflow: hidden;

				@include break-min($medium1) {
					height: 100%;
				}

				img {
					height: 100%;
					object-fit: cover;

					@include break-min($medium1) {
						height: 100%;
					}
				}
			}
		}
	}

	&.reverse-order {
		padding: 0;

		.container {
			padding: 0;

			.text-content {
				@include break-max($large1) {
					grid-row: 2;
					padding-top: 36px;
				}
			}
		}
	}
}
