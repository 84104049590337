.gift-cards {
	.g-hero {
		height: 380px;

		@media screen and (min-width: $mobile) {
			height: 340px;
		}

		&__inner {
			@media screen and (min-width: $mobile) {
				margin-left: 125px;
			}
		}

		&__text {
			color: $blue;

			@media screen and (max-width: $mobile) {
				bottom: 25px;
				left: 50%;
				padding: 0 16px;
				position: absolute;
				transform: translateX(-50%);
				width: 100%;
			}

			h1 {
				text-shadow: none;
			}

			p {
				@include p1();
				color: $grey;
				text-shadow: none;

				@media screen and (min-width: $mobile) {
					max-width: 45%;
				}
			}
		}
	}

	.block_img_header_txt {
		margin-bottom: 0px;
		padding: 16px;

		@media screen and (min-width: $mobile) {
			display: flex;
			justify-content: flex-start;
			//padding-top: 100px;
			padding: 100px 24px 0;
		}

		&.last {
			padding-bottom: 100px;
		}

		.img-wrapper {
			flex-basis: 55%;
			max-width: none;
		}

		img {
			width: 100%;
			height: auto;

			@media screen and (max-width: $mobile) {
				width: 100%;
			}
		}

		.content {
			flex-basis: 33.33%;
			text-align: left;

			@media screen and (min-width: $mobile) {
				margin-left: 40px;
			}

			h3 {
				@include h3();
				margin: 0 0 20px;
			}

			ol {
				padding-left: 18px;
			}

			ul li {
				list-style-image: url("img/bullet.svg");
				padding-bottom: 16px;
			}

			ol,
			p {
				@include p1();
			}
		}
	}

	.accord-toggle-component {
		margin-top: 50px;

		.accord-toggle-btn {
			border-bottom: 1px solid $grey;
			display: flex;
			font-family: $secondary;
			font-weight: 800;
			letter-spacing: 1px;
			margin-bottom: 20px;
			padding-bottom: 5px;
			text-transform: uppercase;
		}

		.accord-toggle-content {
			display: none;
			padding: 0;

			li {
				display: block;
			}

			.split-list {
				column-count: 2;
				padding: 0;

				li {
					padding: 0;
				}

				li:before {
					content: "-";
				}
			}
		}
	}

	.parent-wrapper {
		background: $cream;
		margin-top: 75px;
		padding-bottom: 125px;

		@media screen and (min-width: $mobile) {
			padding-bottom: 50px;
		}
	}

	.header-columns-text {
		background: $warm-white;
		margin: 0px auto;
		max-width: none;

		@media screen and (max-width: $mobile) {
			margin: 0 auto;
		}

		h2 {
			margin-left: 20px;
			text-align: left;

			@media screen and (min-width: $mobile) {
				margin-left: 0;
				text-align: center;
			}
		}

		.flex {
			@media screen and (max-width: $mobile) {
				flex-direction: row;
				flex-wrap: nowrap;
				overflow-x: auto;
				scroll-snap-type: x mandatory;
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}

		dl {
			text-align: left;

			@media screen and (max-width: $mobile) {
				flex: 0 0 auto;
				margin: 0px 0 30px -15px;
				scroll-snap-align: center;
				width: 100vw;
			}

			a {
				@include font-size(1.6);
				@include link-underline();
			}
		}

		dt {
			font-weight: 900;

			@media screen and (max-width: $mobile) {
				padding-left: 15px;
			}
		}

		dd {
			@media screen and (max-width: $mobile) {
				margin-bottom: -15px;
				padding-left: 15px;
			}

			&:after {
				display: none;
			}
		}

		.btn {
			bottom: 70px;
			left: 50%;
			position: absolute;
			text-align: center;
			transform: translateX(-50%);
			//width: 100%;
			@media screen and (min-width: $mobile) {
				bottom: 50px;
				width: auto;
			}
		}
	}

	.nav-progress-circles {
		list-style-type: none;
		flex-direction: row;
		justify-content: center;

		@media screen and (min-width: $mobile) {
			display: none;
		}

		.dot {
			// sass-lint:disable variable-for-property
			background-color: #bbb;
			// sass-lint:enable variable-for-property
			border-radius: 50%;
			display: inline-block;
			height: 20px;
			margin: 0 15px;
			width: 20px;

			&.fill {
				background-color: $red;
			}
		}
	}
}
