// GLOBAL Boxes
// ==========================================================================

.g-round-card {
	color: $grey;
	height: 100%;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
	overflow: hidden;
	background: $white;

	&__inner {
		position: relative;
		height: 100%;

		@include flexbox();
		@include justify-content(flex-start);
		@include flex-direction(column);
		@include align-items(stretch);
		@include align-content(stretch);
	}

	&__content {
		position: relative;

		@include flexbox();
		@include justify-content(flex-start);
		@include flex-direction(column);
		@include align-items(stretch);
		@include align-content(stretch);
	}

	&__intrinsic {
		display: block;
		position: relative;
		overflow: hidden;
		padding: 66.25% 0 0;
	}

	&__img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 0;
	}

	&__foot {
		@include flex(0 1 auto);
		@include type-p4();
		margin: auto 0 0;
		padding: 24px 10px 12px 14px;
		font-size: 12px;
		font-weight: $medium-weight;

		@include break-min($medium2) {
			padding: 32px 10px 8px 14px;
		}

		a {
			font-size: 16px;
		}
	}

	&__heading {
		color: $vis-blue;
		font-size: 18px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: -0.5px;
		pointer-events: all;
		margin: 18px 16px 8px;

		&:hover {
			color: $blue;
		}

		@include break-min($medium2) {
			margin: 22px 22px 12px;
			font-size: 22px;
			line-height: 1.27;
			letter-spacing: -0.5px;
		}
	}

	p {
		margin: 0 16px 12px;

		@include break-min($medium2) {
			margin: 0 22px;
		}
	}

	a {
		@include link-underline();
		pointer-events: all;
	}

	&--overlayed-image {
		@include break-min($medium2) {
			.g-round-card {
				&__content {
					max-width: 510px;
					position: absolute;
					bottom: 0;
					left: 0;
					height: auto;
					pointer-events: none;
					cursor: pointer;
					color: $white;

					a {
						color: $white;
					}
				}

				&__intrinsic {
					&::after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: rgba(10, 10, 10, 0.5);
					}
				}
			}
		}
	}

	&--large-heading {
		.g-round-card__heading {
			font-size: 22px;
			line-height: 1.27;
			margin: 34px 16px 15px;

			@include break-min($medium2) {
				margin: 30px 22px 19px;
				font-size: 28px;
				line-height: 1.21;
				letter-spacing: -0.5px;
			}
		}
	}

	&--location {
		box-shadow: 0px 0px 6px $faint-grey;
		border-radius: 24px;

		.g-round-card__map-wrapper {
			height: 200px;
			@include break-min($small3) {
				height: 160px;
			}
		}

		.g-round-card__img {
			max-height: 200px;
			@include break-min($small3) {
				max-height: 160px;
			}
		}

		.g-round-card__heading {
			margin: 28px 14px 23px 30px;

			@include break-min($large1) {
				margin: 33px 0 15px 0px;
			}
		}

		.g-round-card__heading,
		p {
			@include break-min($large1) {
				padding-left: 30px;
			}

			@include break-min($huge2) {
				padding-left: 48px;
			}
		}

		p {
			@include font-size(1.6);
			color: $dkgrey;
			margin: 0 14px 0 30px;

			@include break-min($large1) {
				margin: 0;
			}
		}

		p:last-of-type {
			color: $vis-green;
			font-weight: $bold-weight;
		}

		p[data-is-open="false"] {
			color: $red;
		}

		.g-round-card__foot {
			display: block;
			padding-top: 28px;

			@include break-min($huge2) {
				display: inherit;
				padding-left: 48px;
			}

			@include break-max($medium2) {
				padding-bottom: 8px;
			}
		}

		.btn-round {
			width: 100%;

			@include break-min($large1) {
				width: auto;
			}

			@include break-max($medium2) {
				.mobile-hide {
					display: none;
				}
			}
		}
	}
}
