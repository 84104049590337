.lll-input {
	@include flex-rules();
	margin: 43px auto 0;
	padding: 0 16px;

	@include breakpoint(tablet) {
		margin: 58px auto 0;
		padding: 0;
	}

	.err-msg {
		color: $red;
		margin: -16px 0 25px;
		padding-left: 35px;
		max-width: none;
		width: 100%;
	}

	&--text-area {
		flex-basis: $col12;
		max-width: $col12;

		@include breakpoint(tablet) {
			flex-basis: $col8;
			max-width: $col8;
		}

		div {
			@include column-rules();
			flex-basis: 100%;
			margin: 0 auto 33px;
			max-width: 100%;
			position: relative;
			width: 100%;

			@include breakpoint(tablet) {
				margin: 0 auto 30px;
			}

			p {
				@include font-size(1.8);
				font-family: $primary;
				font-weight: normal;
				line-height: 32px;
				letter-spacing: -0.5px;
				font-variant-ligatures: none;
				// sass-lint:disable no-vendor-prefixes
				-webkit-font-variant-ligatures: none;
				// sass-lint:enable no-vendor-prefixes
				position: relative;
				text-align: right;

				@include breakpoint(tablet) {
					margin: 1px 0 0;
					position: absolute;
					right: 0;
				}
			}
		}

		.btn {
			max-width: 336px;
			margin: initial !important;

			@include breakpoint(tablet) {
				bottom: -50px;
				left: 0;
			}
		}

		.input {
			input {
				border: 3px solid $faint-grey;
			}
		}

		.input-msg {
			margin-bottom: 15px;
			position: inherit;
			text-align: left;
		}

		.serial-err-msg {
			color: $red;
			margin: -27px 0 15px 0;
			max-width: none;
			position: inherit;
			text-align: left;
			width: 100%;
		}
	}

	&__signup-form {
		@include flex-rules();
		flex-basis: $col12;
		margin: 24px auto 0;
		max-width: $col12;
		padding: 0 16px;

		@include breakpoint(tablet) {
			flex-basis: $col10;
			margin: 58px auto 0;
			max-width: $col10;
			padding: 0 24px;
		}

		@include breakpoint(desktop) {
			flex-basis: $col9;
			max-width: 1000px;
			padding: 0;
		}

		.col--25 {
			width: 25%;
		}

		.col--75 {
			max-width: 100%;
			width: 75%;
		}

		.input {
			margin: 24px auto 0;

			&:first-of-type {
				margin: 0 auto;
			}

			&.col--offset {
				padding-left: 16px;

				.input__label {
					left: 34px;
				}
			}
		}

		.option-select {
			margin: 24px auto 0;
		}

		.btn {
			margin: 48px auto 0;
			@extend .btn-primary;

			@include breakpoint(mobileOnly) {
				width: 100%;
			}
		}

		p {
			margin: 25px auto 0;
			width: 100%;

			+ .btn {
				margin: 32px auto 0;
				@extend .btn-primary;

				@include break-min($large1) {
					margin: 32px 0;
				}
			}
		}

		.error-message {
			color: $red;
			margin-top: 10px;
		}
	}

	&__tas-form {
		@include flex-rules();
		flex-basis: $col12;
		margin: 24px auto 0;
		max-width: $col12;
		padding: 0 16px;
		flex-direction: column;

		@include breakpoint(tablet) {
			flex-basis: 336px;
			margin: 58px auto 0;
			max-width: 336px;
			width: 336px;
			padding: 0 24px;
		}

		@include breakpoint(desktop) {
			// flex-basis: 336px;
			// max-width: 336px;
			// width: 336px;
			padding: 0;
		}

		.col--25 {
			width: 25%;
		}

		.col--75 {
			max-width: 100%;
			width: 75%;
		}

		.input {
			width: 100%;
			margin: 24px auto 0;

			&.col--offset {
				padding-left: 16px;

				.input__label {
					left: 34px;
				}
			}
		}

		.date-selector {
			width: 100%;
			max-width: 100%;
			margin-top: 10px;

			.input__wrapper {
				margin: 0;
				margin-bottom: 15px;

				.datepicker-label {
					font-size: 1.2rem;
					color: $vis-grey;
					font-weight: 400;
					left: 19px;
					position: absolute;
					top: 11px;
					z-index: 100;
				}

				// sass-lint:disable no-vendor-prefixes
				@media not all and (min-resolution: 0.001dpcm) {
					@supports (-webkit-appearance: none) {
						.datepicker.datepicker-last-time-of-visit:after {
							content: "\e930";
							cursor: pointer;
							font-family: $icon;
							font-size: 30px;
							position: absolute;
							right: 25px;
							top: 20px;
						}
					}
				}
				// sass-lint:enable no-vendor-prefixes
			}
		}

		.option-select {
			margin: 24px auto 0;
		}

		.btn {
			margin: 35px 0;
			@extend .btn-primary;

			@include breakpoint(mobileOnly) {
				width: 100%;
			}
		}

		p {
			margin: 25px auto 0;
			width: 100%;

			+ .btn {
				margin: 32px auto 0;
				@extend .btn-primary;

				@include break-min($large1) {
					margin: 32px 0;
				}
			}
		}

		.error-message {
			color: $red;
			margin-top: 10px;
		}
	}

	&__tas-fraud {
		@include flex-rules();
		flex-basis: $col12;
		margin: 24px auto 0;
		max-width: $col12;
		padding: 0 16px;
		flex-flow: column;
		justify-content: center;
		text-align: center;

		@include breakpoint(tablet) {
			margin: 20px auto 0;
			padding: 0 24px;
		}

		@include breakpoint(desktop) {
			padding: 0;
		}

		.g-form {
			display: flex;
			justify-content: center;
			padding: 10px 0;
			margin-bottom: 30px;

			&__radio {
				margin: 0px 15px;

				&:last-child {
					margin: 0px 15px;
				}
			}
		}

		.g-form_email {
			max-width: 336px;
			margin: 0 auto;
			margin-bottom: 30px;

			p {
				text-align: left;
			}

			.input {
				width: 336px;
			}
		}

		.btn {
			margin: 0 auto;
			@extend .btn-primary;
		}

		.points-area {
			text-align: left;

			textarea {
				width: 100%;
			}

			.serial-err-msg {
				text-align: left;
			}

			.textarea-count {
				text-align: right;
				margin-top: 10px;
			}

			.btn {
				margin-left: 0;
				margin-top: 30px;
			}
		}

		.payment-area {
			max-width: 336px;
			margin: 0 auto;
			width: 100%;

			p {
				text-align: left;
			}

			.btn {
				margin-left: 0;
				margin-top: 30px;
			}

			.option-select {
				margin-bottom: 24px;
			}

			.input {
				width: 336px;
				margin-bottom: 24px;
			}
		}
	}

	textarea {
		@include font-size(1.8);
		border-radius: 8px;
		bottom: 0;
		font-family: $primary;
		left: 0;
		letter-spacing: -0.5px;
		line-height: 32px;
		max-height: 224px;
		position: relative;
		padding: 22px 20px 10px 16px;
		border: 3px solid $mdgrey;
		width: 100%;
	}

	.btn {
		text-align: center;
		margin: 0 auto;
		@extend .btn-primary;

		@include breakpoint(mobileOnly) {
			width: 100%;
		}
	}
}
