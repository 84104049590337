.codemoo-textblock {
	background-color: $cool-white;

	.g-textblock__inner {
		max-width: 1100px;

		p {
			@include font-size(1.1);
		}
	}
}
