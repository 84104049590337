.language-toggle {
	box-shadow: 5px 5px 18px -6px rgba(0, 0, 0, 0.63);
	position: absolute;
	background-color: $white;
	padding: 15px;
	border-radius: 5px;
	cursor: pointer;

	&:hover {
		.toggle-cta {
			span {
				transform: rotate(-180deg);
			}
		}

		ul {
			padding: 15px 0 0;
			height: 65px;
		}
	}

	.toggle-cta {
		display: flex;
		align-items: center;
		gap: 0 32px;

		span {
			color: $red;
			transition: all 0.3s ease;
		}
	}

	ul {
		height: 0;
		overflow: hidden;
		padding: 0;
		margin: 0;
		transition: all 0.3s ease;

		li {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.pos-top-left {
		top: 10px;
		left: 10px;
	}

	&.pos-top-right {
		top: 10px;
		right: 10px;
	}

	&.pos-bot-left {
		bottom: 10px;
		left: 10px;
	}

	&.pos-bot-right {
		bottom: 10px;
		right: 10px;
	}
}
