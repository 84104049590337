.anchor-link {
	color: $rolling-stone;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	p {
		@include type-p3-refresh();
		margin: 0 0 15px 0;
	}

	img {
		margin: auto;
		height: 25px;
		width: 25px;
	}

	&.care-link {
		cursor: pointer;
		margin: 0 0 20px;

		@include break-min($large1) {
			display: none;
			padding: 0 0 8px;
		}
	}
}
