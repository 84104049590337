.question-tiles {
	background-color: $white;
	padding: 16px 24px 72px;

	@include break-min($large1) {
		padding: 40px 82px 80px;
	}

	.header {
		margin-bottom: 40px;

		@include break-min($large1) {
			margin-bottom: 64px;
		}

		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);
			text-align: center;

			@include break-min($large1) {
				@include font-size(4.8);
				line-height: lh(58, 48);
			}

			sup {
				@include font-size(1.4);
				color: $blue;
				font-weight: $bold-weight;
				letter-spacing: -1px;
				line-height: lh(16, 14);
				vertical-align: super;
			}
		}
	}

	.question-tiles__categories {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin: 0 auto;
		max-width: 192rem;

		@include break-min($large1) {
			column-gap: 5%;
			flex-direction: row;
			justify-content: flex-start;
		}

		.question-tiles__category-box {
			border: 1px solid $iron;
			margin: auto;
			width: 100%;
			padding: 32px;
			padding-right: 25px;

			&:not(:first-of-type) {
				margin-top: 24px;
			}

			@include break-min($large1) {
				display: flex;
				flex-basis: 30%;
				flex-direction: column;
				margin: 0 0 48px;
				max-width: none;
				padding-right: 38px;

				&:not(:first-of-type) {
					margin-top: 0;
				}
			}

			.question-tiles__category-title {
				margin-bottom: 32px;

				a {
					@include font-size(2);
					color: $red;
					font-weight: $bold-weight;
					letter-spacing: -1%;
					line-height: lh(28, 20);
					text-align: left;

					@include break-min($large1) {
						@include font-size(2.4);
						letter-spacing: -0.5px;
						line-height: lh(34, 24);
					}
				}
			}

			.question-tiles__category-topics {
				@include break-min($large1) {
					display: flex;
					flex-direction: column;
					flex-grow: 1;
				}

				.question-tiles__category-topic {
					position: relative;
					margin-bottom: 32px;

					a {
						height: 100%;
						position: absolute;
						width: 100%;
						z-index: 10;
					}

					.question-tiles__category-topic-title {
						position: relative;

						p {
							@include font-size(1.8);
							color: $grey;
							font-weight: $regular-weight;
							letter-spacing: -0.5px;
							line-height: lh(28, 18);
							margin-bottom: 0;
							text-align: left;
							width: 80%;

							@include break-min($large1) {
								width: 86%;
							}
						}

						img {
							height: 23px;
							position: absolute;
							right: 12px;
							top: 50%;
							transform: translateY(-50%);
							width: auto;

							@include break-min($large1) {
								right: 0;
							}
						}
					}
				}

				.question-tiles__category-all {
					@include break-min($large1) {
						display: flex;
						flex-direction: column;
						flex-grow: 1;
						justify-content: flex-end;
						margin-top: 8px;
					}

					a {
						@include font-size(1.2);
						color: $red;
						font-weight: $bold-weight;
						letter-spacing: 2px;
						line-height: lh(20, 12);
						text-align: left;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}
