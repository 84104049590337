.yext-search-form {
	// sasslint:disable next line

	&.body-copy {
		@include break-min($large1) {
			margin: auto;
			width: 475px;
		}

		.yxt-AutoComplete-wrapper {
			.yxt-AutoComplete {
				padding: 0;
				text-align: left;

				&:before {
					background-color: transparent;
				}
			}
		}

		button:not(.js-yxt-SearchBar-clear) {
			display: block;
			position: initial;
		}

		&.yxt-Answers-component {
			&.yxt-SearchBar-wrapper .yxt-SearchBar-input-wrapper {
				height: 42px;

				button {
					&.js-yext-submit.yxt-SearchBar-button {
						background-color: transparent;
						border: 0;
						color: $red;
						font-family: $icon;
						font-weight: $regular-weight;
						line-height: 1;

						&:before {
							content: "\e90a";
							font-size: 23px;
							vertical-align: middle;
						}

						.yxt-SearchBar-AnimatedIcon.yxt-Answers-component {
							&.js-yxt-AnimatedReverse {
								display: none;
							}

							&.js-yxt-AnimatedForward {
								display: none;
							}
						}
					}

					&.js-yxt-SearchBar-clear.yxt-SearchBar-clear.yxt-Answers-component {
						.Icon--close {
							svg {
								font-size: 29px;

								path {
									stroke: #dd0031;
								}
							}
						}
					}
				}
			}
		}
	}

	.yxt-SearchBar {
		height: 49px;
	}

	.yxt-SearchBar-container {
		// background-color: inherit;

		&:hover {
			box-shadow: none;
		}
	}

	button:not(.js-yxt-SearchBar-clear) {
		background: transparent;
		position: absolute;
		right: 0;
		top: 3px;
	}

	.yxt-SearchBar-input {
		@include font-size(1.6);
		color: $light-grey;
		font-family: $primary;

		&:focus {
			border-color: transparent;
		}
	}

	.yxt-AutoComplete-wrapper {
		@include font-size(1.5);
		border-radius: 0 0 5px 5px;
		color: $search-grey;
		display: block;
		font-family: $primary;
		font-weight: 600;
		line-height: 1.2;
		top: 100%;
		background: rgba(255, 255, 255, 0.9);

		.yxt-AutoComplete {
			padding: 20px 35px;

			&:before {
				background-color: transparent;
			}
		}

		.yxt-AutoComplete-results {
			ul {
				display: block;
				position: initial;
			}

			li {
				color: $search-grey;
				display: block;
				margin-bottom: 20px;
			}

			.yxt-AutoComplete-option--item:hover {
				background-color: transparent;
				text-decoration: underline;
			}
		}

		.Icon--close {
			color: $red;
			right: 25px;

			@include break-min($large1) {
				top: 16px;
			}
		}
	}
}
