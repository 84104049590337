// GLOBAL Hero
// ==========================================================================

.g-hero {
	// sass-lint:disable-block no-duplicate-properties
	background-color: $bkgrey;
	height: 100vh;
	height: calc(100vh - #{$nav-base-height});
	max-height: calc(100vh - #{$nav-base-height});
	overflow: hidden;
	position: relative;
	z-index: $z1;

	@include break-min($large1) {
		min-height: 530px;
		height: 56vw;
	}

	&__inner {
		position: relative;
		z-index: $z2;
		padding: 0 16px;
		height: 100%;
		max-width: 1080px;
		width: 100%;
		margin: auto;
		//@include flexbox();
		display: flex;
		@include justify-content(stretch);
		@include align-items(center);
		@include flex-direction(row);
		@include flex-wrap(wrap);

		@include break-min($large1) {
			padding: 0 24px;
		}
	}

	&__text {
		z-index: $z2;
		text-align: center;
		margin: 0;
		padding: 0;
		color: $white;
		@include flex(1 1 100%);

		@include break-min($large1) {
			text-align: left;
		}

		p {
			@include type-p1();
			color: inherit;
			text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
			padding: 0;
			margin: 0 auto;
			max-width: 600px;
			width: 100%;

			@include break-min($large1) {
				margin: 0;
			}
		}

		h1,
		h2 {
			@include type-h1();
			color: inherit;
			text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
			padding: 0 0 15px;
			margin: 0 auto;
			max-width: 620px;
			display: block;
			width: 100%;

			@include break-min($large1) {
				margin: -12px 0 0 -3px;
			}
		}

		// This is not BEM but a good example of when we need to break out of BEM
		.btn,
		.btn-round {
			margin: 30px 0 0;

			&--fullwidth-mobile {
				width: 100%;

				@include break-min($large1) {
					width: auto;
				}
			}
		}

		// Container to apply left margin to additional buttons on desktop
		.btn-container {
			margin-top: 20px;

			@include break-min($large1) {
				margin-top: 25px;
			}

			.btn-round {
				margin-top: 15px;
				width: 100%;

				@include break-min($large1) {
					margin-top: 0;
					width: auto;

					&:not(:first-child) {
						margin-left: 17px;
					}
				}
			}
		}

		// blue modifier for lighter backgrounds
		&--blue {
			color: $vis-blue;

			p,
			h1,
			h2 {
				text-shadow: none;
			}
		}
	}

	&__img {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: $z1;
		overflow: hidden;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		&--desktop {
			display: none;
		}

		@include break-min($large1) {
			&--mobile {
				display: none;
			}

			&--desktop {
				display: block;
			}
		}
	}

	&--short {
		height: 380px;

		@media screen and (min-width: $mobile) {
			height: 340px;
			min-height: 340px;
		}
	}

	&--content-height {
		height: auto;
		min-height: auto;

		.g-hero {
			&__inner {
				height: auto;
			}
		}
	}

	&--centered {
		.g-hero {
			&__text {
				text-align: center;
				margin: 0 auto;

				@include break-min($large1) {
					text-align: center;
				}

				h1 {
					text-align: center;
					margin: 0 auto;
				}
			}
		}
	}

	&--left-align {
		.g-hero {
			&__inner {
				@include break-min($large1) {
					margin-left: 130px;
					max-width: none;
				}
			}
		}
	}

	&--large-pad {
		.g-hero__inner {
			padding: 140px 16px;

			@include break-min($large1) {
				padding: 140px 200px;
			}
		}

		h1 {
			padding: 0;
			@include type-h2();
		}
	}

	&--small-pad {
		.g-hero__inner {
			padding: 42px 16px 45px;

			h1 {
				@include h3();
			}

			p {
				@include type-p1();
			}

			@include break-min($large1) {
				padding: 100px 0;
				max-width: 608px;
				margin: 0 auto;
			}
		}
	}

	// Bottom padding for above fold content on mobile
	&--above-fold-pad {
		// sass-lint:disable-block no-duplicate-properties
		height: 80vh;
		height: calc(80vh - #{$nav-base-height});
		max-height: calc(80vh - #{$nav-base-height});

		.g-hero__inner {
			max-width: 1200px;
			padding-bottom: 79px;
		}
	}

	// Flex box to split text on devices
	&--split-text {
		.g-hero__text {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			padding-top: 49px;

			@include break-min($large1) {
				justify-content: center;
				padding-top: 0;
			}
		}
	}

	&--new-fonts {
		h1 {
			line-height: lh(48px, 47px);
			@include font-size(4.7);

			@include break-min($large1) {
				line-height: lh(64px, 64px);
				@include font-size(6.4);
			}
		}

		.btn-round {
			@include font-size(1.6);
		}
	}

	// Paragraph margins for g-hero components
	&--p-margin {
		p {
			margin-bottom: 30px;
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	&--gradient {
		.g-hero {
			&__inner {
				@include break-min($large1) {
					margin-left: 0;
					padding-left: 130px;
				}

				&:after {
					// sass-lint:disable indentation
					// sass-lint:disable max-line-length
					// justifcation: more readable
					background: linear-gradient(90deg, $black -500%, transparent 100%),
						linear-gradient(90deg, $black -500%, transparent 100%),
						linear-gradient(90deg, $black -40%, transparent 100%);
					// sass-lint:enable indentation
					// sass-lint:enable max-line-length

					content: "";
					display: block;
					height: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: 0;
					z-index: 200;
				}
			}

			&__text {
				z-index: 201;
			}
		}
	}
}
