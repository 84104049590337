.signature-drop-already-claimed {
	.signature-drop-headline-text {
		@extend .sig-drop-headline-base-margin;
		margin: 100px 0 120px;

		@include break-min($large1) {
			margin: 120px 0;
		}
	}

	.delivery-directions {
		@extend .delivery-directions-sig-drop;

		.slick-slide {
			&:last-of-type {
				.icon-wrapper {
					background: $cool-white;
					border: 3px solid $teal;
				}
			}
		}

		.card {
			@include break-min($large1) {
				&:last-of-type {
					.icon-wrapper {
						background: $cool-white;
						border: 3px solid $teal;
					}
				}
			}
		}
	}
}
