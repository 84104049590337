.related-questions {
	background-color: $warm-white;
	padding: 72px 24px;

	@include break-min($large1) {
		padding: 96px 11px;
	}

	.header {
		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);
			margin-bottom: 48px;
			text-align: left;

			@include break-min($large1) {
				@include font-size(4.8);
				letter-spacing: -1px;
				line-height: lh(58, 48);
				margin-bottom: 64px;
				text-align: center;
			}
		}
	}

	.question__boxes {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		max-width: 192rem;

		@include break-min($large1) {
			flex-direction: row;
		}
	}

	.question__box {
		position: relative;

		&:not(:first-of-type) {
			margin-top: 88px;
		}

		@include break-min($large1) {
			width: calc(100% / 3);

			&:not(:first-of-type) {
				border-left: 1px solid $faint-grey;
				margin-top: 0;
			}
		}

		a {
			height: 100%;
			position: absolute;
			width: 100%;
		}

		.question__box-contents {
			display: flex;
			flex-direction: column;
			height: 100%;

			@include break-min($large1) {
				margin: 0 auto;
				width: 70%;
			}

			.question__box-category {
				align-items: center;
				display: flex;
				flex-direction: row;
				margin-bottom: 15px;

				@include break-min($large1) {
					margin-bottom: 26px;
				}

				img {
					max-height: 40px;
					max-width: 35px;
				}

				p {
					@include font-size(1.6);
					color: $grey;
					font-weight: $regular-weight;
					line-height: lh(25, 16);
					margin-bottom: 0;
					margin-left: 16px;
				}
			}

			.question__box-title {
				p {
					@include font-size(2);
					color: $grey;
					font-weight: $bold-weight;
					letter-spacing: -1%;
					line-height: lh(28, 20);
					margin-bottom: 0;
					text-align: left;

					@include break-min($large1) {
						@include font-size(2.4);
						letter-spacing: -0.5px;
						line-height: lh(34, 24);
						max-width: 330px;
					}
				}
			}
		}
	}
}
