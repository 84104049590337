.points-card {
	flex: 0 0 100%;
	min-width: 170px;
	cursor: pointer;

	@include break-min($large1) {
		flex: 0 0 33.33333%;
	}

	&__pad {
		padding: 12px;
		height: 100%;
	}

	&__content {
		background-color: $white;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		height: 100%;
		position: relative;
		padding: 20px;
		text-align: center;

		@include flexbox();
		@include justify-content(stretch);
		@include align-items(stretch);
		@include flex-wrap(wrap);
		@include flex-direction(column);

		h4 {
			@include font-size(1.4);
			color: $blue;
			font-family: $primary;
			font-weight: 600;
			letter-spacing: -0.5px;
			line-height: 22px;
			margin: 0 0 10px;

			@include break-min($medium3) {
				@include font-size(1.8);
			}
		}
	}

	&__img-wrap {
		padding: 0;
		width: 100%;
		height: auto;

		img {
			display: block;
			height: auto;
			width: 100%;
		}
	}

	&__bar {
		width: 100%;
		padding: 5px 12px 15px;
		@include flexbox();
		@include justify-content(stretch);
		@include align-items(stretch);
	}

	&__points {
		padding: 3px 0;
		margin: 0 auto;

		p {
			@include font-size(1.2);
			font-weight: 600;
			padding: 0;
			margin: 0;
			color: $grey;

			span {
				margin-left: 5px;
			}

			@include break-min($medium3) {
				@include font-size(1.4);
			}
		}
	}
}
