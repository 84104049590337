.store-icon {
	text-align: center;
	margin-bottom: 10px;
	z-index: 1;
	align-items: center;
	@include flexbox();

	@include break-min($large1) {
		margin-bottom: 24px;
	}

	.icon-wrapper {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		overflow: hidden;
		display: none;

		&.display-override {
			display: block;
			border: 2px solid $white;
		}

		&.lbm {
			width: 50px;
			height: 50px;

			@include break-min($large1) {
				width: 80px;
				height: 79px;
			}
		}

		@include break-min($large1) {
			display: block;
		}

		img {
			width: 100%;
			background: $faint-grey;
			// border-radius: 50%;

			&.little-blue-menu {
				background: $little-blue-menu;
				padding: 6px;

				@include break-min($large1) {
					padding: 12px;
				}
			}
		}
	}

	h3 {
		color: $rolling-stone;
		text-transform: uppercase;
		margin: 0;
		@include s3();
		@include font-size(1.1);

		@include break-min($large1) {
			margin: 0 0 0 16px;
			@include font-size(1.4);
		}
	}

	p {
		margin: 0 0 0 10px;

		@include break-min($large1) {
			@include font-size(1.4);
		}
	}
}
