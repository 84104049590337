.cfa-play-hero {
	position: relative;
	margin-bottom: 50px;

	.container {
		width: 100%;
		max-width: $huge3;
		margin: 0 auto;
		overflow: hidden;
		padding: 50px 0;

		.row {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 32px;

			.col {
				padding-left: 100px;

				figure {
					text-align: center;
					margin: 0 0 40px;

					img {
						margin-bottom: 20px;
					}

					figcaption {
						font-weight: 700;
						color: $red;
						@include font-size(1.2);
						text-transform: uppercase;
						letter-spacing: 4px;
						white-space: nowrap;
					}
				}

				p {
					@include font-size(1.8);
					color: $grey;
					line-height: 155%;
					letter-spacing: -0.5px;
					margin-bottom: 50px;
				}

				.links {
					display: flex;
					align-items: center;
					gap: 12px;

					@include break-max($medium5) {
						display: grid;
						grid-template-columns: 1fr 1fr;
						align-items: center;
						justify-content: center;
						text-align: center;

						a {

							&:nth-child(1) {
								text-align: right;
							}

							&:nth-child(2) {
								text-align: left;
							}

							&:last-child {
								grid-column: 1 / 3;
							}
						}
					}
				}
			}
		}
	}

	.img-container {
		position: absolute;
		top: 0;
		right: 0;
		width: 45%;
		overflow: hidden;
		height: 100%;


		picture {
			display: block;
			width: 100%;
			max-width: 820px;
			height: 100%;
		}

		img {
			object-fit: cover;
			object-position: center left;
			height: 100%;
		}
	}

	&.red-text {

		h2 {
			@include font-size(4.2);
			color: $red;
			font-weight: 700;
			line-height: 125%;
		}

		figure {
			width: 150px;
		}
	}

	&.wooden-separator {
		&:after {
			content: "";
			width: 100%;
			height: 52px;
			background: url("/img/cfacom/wood-sep.jpg") repeat-x center center transparent;
			display: block;
			position: relative;
			z-index: 10;

			@include break-max($medium2) {
				margin-top: 50px;
			}
		}
	}

	@include break-max($medium2) {
		margin-bottom: 50px;
		.container {
			padding: 0 15px 30px;

			.row {
				display: unset;

				.col {
					padding-left: 0;

					figure {
						width: 100%;
						margin-bottom: 20px;

						img {
							width: 200px;
						}
					}
				}
			}
		}
		.img-container {
			width: 100%;
			position: unset;
			margin-bottom: 30px;
			height: auto;

			img {
				height: auto;
				width: 100%;
			}
		}
	}
}
