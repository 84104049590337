.bc-video-container {
	.video-container {
		// @include remove-bc-controls();
		position: relative;
		width: 100%;
		overflow: hidden;

		@include break-min($large1) {
			margin: 0;
			height: auto;
		}

		&.isPlaying {
			.overlay {
				display: none;
			}
		}

		.overlay {
			@include absolute-center();
			cursor: pointer;
			z-index: 10;

			&__img-wrapper {
				align-items: center;
				display: flex;
				height: 100%;
				justify-content: center;
				width: 100%;

				img {
					height: 77px;
					width: 77px;
				}
			}
		}

		.player-wrapper {
			@include absolute-center();
			height: 100%;
			margin: 0;
			transition: opacity 0.3s ease;
		}

		.video-js {
			@include absolute-center();
			height: 100%;
			margin: auto;
			width: 100%;
		}

		.video-js .vjs-tech {
			object-fit: cover;
		}

		.vjs-poster {
			background-color: transparent;
			background-size: cover;
			display: none;
		}
	}
}
