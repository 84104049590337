.search-not-found-banner {
	background-color: $cream;
	margin-bottom: 64px;

	.container {
		grid-column: col-start 1 / span 12;
		width: 100%;
		padding: 36px 72px;
		text-align: center;

		h1 {
			font-family: $primary;
			color: $blue;
			font-weight: 700;
			@include font-size(4);
			margin: 0 0 12px;
		}

		p {
			color: $grey-p;
			line-height: 155%;
			@include font-size(1.6);
			margin-bottom: 24px;
		}

		a {
			margin-right: 15px;
		}

		@include break-max($medium1) {
			grid-template-columns: repeat(1, 1fr);
			padding: 36px 24px;
			align-items: start;
			min-height: auto;

			.text-content {
				margin-bottom: 24px;

				h1 {
					@include font-size(4);
				}

				a {
					margin: 0 0 14px;
				}
			}

			.img-content {
				img {
					width: 100%;
					position: unset;
				}
			}
		}
	}
}
