//Default Btn class to use
.btn-round {
	@include btn-round;
	@include btn-primary-red;

	&--red {
		@include btn-primary-red;
	}

	&--red-sm {
		@include btn-primary-red-sm;
	}

	&--white {
		@include btn-secondary-white;
	}

	&--white-sm {
		@include btn-secondary-white-sm;
	}
}

.btn-primary {
	@include btn-round;
	@include btn-primary-red;
}

.btn-primary-small {
	@include btn-round;
	@include btn-primary-red-sm;
}

.btn-secondary {
	@include btn-round;
	@include btn-secondary-white;
}

//Keep for now until notif from design
.btn--vis--white {
	background: $white;
	border-radius: 8px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
	color: $red;
	letter-spacing: -0.5px;
}

.btn-search-half {
	@include btn-round;

	&.btn-round--red {
		@include btn-primary-search-red;
	}
}

.btn-link-underline-white {
	@include link-underline();
	text-decoration-color: $white;
}
