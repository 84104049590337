.cfa-one-footer {
	@include add-padding();
	grid-auto-rows: 1fr;

	@include break-min($huge4) {
		grid-template-columns: repeat(12, [col-start] 1fr) !important;
	}

	&.full-width {
		@include break-min($huge4) {
			grid-template-columns: repeat(12, [col-start] 1fr);
		}
	}

	@include break-min($large1) {
		gap: 24px;
	}

	@include break-min($huge4) {
		justify-content: center;
	}

	&.overlay-view {
		@extend .footer-overlay-view;
	}

	.background-img {
		display: none;
	}

	.img-container {
		@include swap-img();
		position: relative;
		grid-column: col-start 1 / span 4;
		grid-row: 1;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 4;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 6;
		}

		img {
			@include absolute-center();
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	.content {
		background: $cool-white;
		grid-column: col-start 1 / span 4;
		grid-row: 2;
		padding: 41px 15px 35px;
		text-align: center;

		@include break-min($medium4) {
			align-items: center;
			grid-column: col-start 5 / span 8;
			grid-row: 1;
			padding: 122px 25px;
		}

		@include break-min($large4) {
			grid-column: col-start 7 / span 6;
			margin: 0 0 0 -24px;
			padding: 166px;
		}

		.icon-container {
			align-items: center;
			display: flex;
			justify-content: center;
			margin: 0 0 12px 0;

			@include break-min($medium4) {
				margin: 0 0 20px 0;
			}

			img {
				height: 34px;
				margin: 0 10px 0 0;
			}

			p {
				@include type-e1-refresh();
				color: $red;
				margin: 0;
			}
		}

		.title {
			margin: 0 0 16px 0;

			@include break-min($medium4) {
				margin: 0 0 32px 0;
			}

			> *:first-child {
				@include type-h2-refresh();
				color: $blue;
				margin: 0 0 8px 0;

				@include break-min($medium4) {
					margin: 0 0 16px 0;
				}
			}

			p {
				@include type-p1-refresh();
				color: $grey;
			}
		}

		.btn-container {
			margin: auto;
			max-width: 295px;

			a {
				// @include btn-hover-effect();
				// align-items: center;
				// background: $white;
				// box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
				// border-radius: 24px;
				@include btn-round;
				@include btn-secondary-white;
				justify-content: center;
				align-items: center;
				display: flex;

				@include break-min($medium4) {
					//padding: 12px 30px;
				}

				&:first-child {
					margin: 0 0 24px 0;
				}

				img {
					height: 20px;
					margin: 0 8px 0 0;
				}

				p {
					margin: 0;
				}
			}
		}
	}
}
