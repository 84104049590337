.video-panel-image-overlay {
	background: $white;
	margin: 0 30px;
	padding-bottom: 30px;

	.video-panel-container {
		cursor: pointer;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;
	}

	iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	}

	.title {
		color: $blue;
		font-weight: 600;
		margin: 20px auto;
		text-align: center;
		width: 100%;
	}

	.play {
		bottom: 31%;
		cursor: pointer;
		position: absolute;
		right: 44%;
		z-index: 100;
	}

	.image-overlay {
		position: absolute;
		width: 100%;
	}

	&.full-bleed {
		margin: 0;
	}

	&.mealtime-margin {
		margin-top: 50px;

		@media screen and (min-width: $mobile) {
			margin: 100px 150px 0px;
		}
	}
}
