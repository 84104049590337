.unsubscribe-header-block {
	margin: 14px auto 338px;
	padding: 0 20px;
	text-align: center;

	@include break-min($large1) {
		padding: 0;
		width: $col5;
	}

	@include break-min($large4) {
		width: $col4;
	}

	.header-text {
		margin: auto;
		width: $col10;

		h2 {
			@include type-h2();
			color: $red;
			margin-bottom: 32px;
		}

		p {
			@include type-p2();
		}
	}

	.g-form {
		margin: 68px 0 32px;
		padding: 0;
		position: relative;
		text-align: center;

		&__group {
			margin: 0 0 32px;

			&--input {
				max-width: none;

				label {
					@include type-p4();
					color: $vis-grey;
					padding-left: 4px;
					top: 11px;
				}

				input {
					@include type-p1();
					color: $light-grey;
					font-weight: normal;
					padding: 30px 43px 10px 19px;
				}
			}

			&.g-form__group--error {
				.error-msg {
					@include type-label1();
					font-family: $secondary;
					font-weight: 800;
					left: 2px;
					letter-spacing: 2px;
					max-width: none;
					position: absolute;
					text-align: left;
					top: -36px;
					z-index: 1;

					@include break-min($large1) {
						@include font-size(1.1);
					}
				}

				label {
					color: $red;
				}
			}
		}

		button {
			margin: 0 auto;
			min-width: 160px;
		}
	}
}
