.question-subcategory-list {
	background-color: $white;
	padding: 16px 24px 72px;

	@include break-min($large1) {
		padding: 40px 0 112px;
	}

	.header {
		margin-bottom: 40px;

		@include break-min($large1) {
			margin-bottom: 64px;
		}

		> *:first-child {
			@include font-size(2.4);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(32, 24);
			text-align: center;

			@include break-min($large1) {
				@include font-size(4.8);
				line-height: lh(58, 48);
			}
		}
	}

	.question-subcategory-lists {
		@include break-min($large1) {
			margin: 0 auto;
			max-width: 74.31%;
		}

		.question__item {
			border-top: 1px solid $faint-grey;
			padding: 32px 0;
			position: relative;

			&:last-of-type {
				border-bottom: 1px solid $faint-grey;
			}

			.question__item-link {
				bottom: 0;
				height: 100%;
				position: absolute;
				width: 100%;
				z-index: 10;
			}

			.question__item-title {
				position: relative;

				p {
					@include font-size(2);
					color: $grey;
					font-weight: $regular-weight;
					letter-spacing: -0.5px;
					line-height: lh(34, 20);
					margin-bottom: 0;
					text-align: left;
					width: 87%;

					@include break-min($large1) {
						@include font-size(2.4);
						line-height: lh(34, 24);
						width: 90%;
					}
				}

				.question__item-arrow {
					height: 23px;
					position: absolute;
					right: 17px;
					top: 50%;
					transform: translateY(-50%);
					width: auto;

					@include break-min($large1) {
						right: 29px;
					}
				}
			}
		}
	}
}
