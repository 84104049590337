.pwo-modals-restaurant-label {
	align-items: flex-start;
	display: flex;
	margin: 28px 0 32px;
	padding: 0 15px;
	position: relative;

	@include break-min($large1) {
		margin: 8px 0 25px;
		padding: 0 42px;
	}

	&[data-is-open="true"] {
		.restaurant-information .hours p {
			color: $vis-green;
		}
	}

	&[data-is-open="false"] {
		.restaurant-information .hours p {
			color: $red;
		}
	}

	.label-img {
		margin-right: 10px;
		height: 28px;
		width: 28px;
	}

	.restaurant-information {
		.location-name {
			margin-top: -5px;

			h3 {
				@include font-size(1.6);
				color: $grey;
				font-weight: $bold-weight;
				letter-spacing: -0.5px;
				line-height: lh(22, 16);
				margin: 0 5px 0 0;
			}
		}

		.address {
			@include font-size(1.2);
			letter-spacing: -0.5px;
			line-height: lh(20, 12);
			margin-bottom: 2px;
			max-width: 250px;
		}

		.hours {
			@include font-size(1.1);
			font-weight: $bold-weight;
			line-height: lh(13, 11);
		}
	}

	.mobile-cta {
		position: absolute;
		right: 15px;

		&.disabled {
			pointer-events: none;
		}

		img {
			width: 25px;
		}

		@include break-min($large1) {
			display: none;
		}
	}

	.btn-round {
		display: none;

		@include break-min($large1) {
			display: block;
			position: absolute;
			right: 42px;
			top: 0;
		}
	}

	.single-label-data {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.single-label-info {
			display: flex;
			flex-direction: column;
			font-size: 1.5rem;
		}

		.label-btn {
			.btn-round {
				background: $white;
				//border: 1px solid $btn-border;
				color: $red;
				display: none;

				&:hover {
					background: $mdgrey;
					color: $white;
				}
			}

			img {
				max-width: 30px;
			}
		}
	}
	@include break-min($medium) {
		.single-label-data {
			.label-btn {
				.btn-round {
					display: block;
				}
			}

			.label-btn {
				img {
					display: none;
				}
			}
		}
	}
}
