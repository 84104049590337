.lll-title-header {
	margin: 0 auto;
	padding: 0 16px;
	position: relative;
	text-align: center;

	@include breakpoint(desktop) {
		padding: 0 24px;
	}

	.icon-orders-restaurant-feedback {
		font-size: 30px;
		color: $blue;
	}

	img {
		margin: 0 auto;
		width: 48px;
	}

	h1 {
		@include font-size(2.8);
		display: block;
		margin: 0 auto;
		padding: 0 0 10px;
		font-family: $primary;
		font-weight: $bold-weight;
		line-height: lh(34px, 28px);
		letter-spacing: -1px;
		color: $vis-blue;

		@include break-min($medium1) {
			@include flex(1 1 auto);
			@include font-size(3.9);
			letter-spacing: -1px;
			padding: 0;
		}
	}

	h2 {
		@include font-size(1.1);
		color: $vis-blue;
		font-family: $secondary;
		font-weight: 800;
		line-height: 11px;
		letter-spacing: 2px;
		margin: 21px auto 0;
		text-transform: uppercase;

		@include breakpoint(desktop) {
			margin: 26px auto 0;
		}
	}

	&__progress-indicator {
		@include flex-rules();
		align-items: flex-start;
		counter-reset: section;
		height: 100%;
		justify-content: center;
		margin: 22px auto 0;
		padding: 0;
		text-align: center;

		li {
			@include font-size(1.2);
			background: $mdgrey;
			border-radius: 100px;
			color: $white;
			cursor: pointer;
			font-family: $primary;
			font-weight: 800;
			letter-spacing: 0;
			line-height: 24px;
			margin: 0 23px 20px;
			height: 16px;
			transition: all 0.2s ease-in-out;
			width: 16px;

			@include breakpoint(desktop) {
				margin: 0 38px 10px;
			}

			&:after {
				counter-increment: section;
				content: counter(section);
				opacity: 0;
			}

			&:hover {
				cursor: default;
			}

			&.current {
				background: $red;
				height: 24px;
				margin: 0 19px 12px;
				width: 24px;

				@include breakpoint(desktop) {
					margin: 0 34px 2px;
				}

				&:first-of-type {
					margin: 0 19px 12px;

					@include breakpoint(desktop) {
						margin: 0 34px 2px;
					}
				}

				&:hover {
					cursor: pointer;
				}

				&:after {
					opacity: 1;
				}
			}

			&:first-of-type {
				margin: 0 23px 20px;

				@include breakpoint(desktop) {
					margin: 0 38px 10px;
				}
			}
		}
	}

	p {
		@include font-size(1.8);
		font-family: $primary;
		font-weight: $regular-weight;
		line-height: 1.35;
		letter-spacing: -0.5px;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		margin: 0 auto;
		padding: 8px 0 0;

		@include break-min($large1) {
			padding: 16px 0 0;
		}
	}

	.btn {
		margin: 43px auto 0;
		max-width: 336px;
		@extend .btn-primary;

		@include breakpoint(tablet) {
			margin: 59px auto 0;
		}
	}
}
