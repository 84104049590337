.header-icon-hero {
	@include background-color();
	@include text-color-options();
	background: $blue;
	color: $white;
	padding: 39px 32px 123px;
	margin-bottom: -60px;
	min-height: 239px;
	text-align: center;

	@include break-min($large1) {
		padding: 73px 0 136px;
	}

	&.unoverlap {
		margin-bottom: 0;
		padding: 42px 32px;

		@include break-min($large1) {
			padding: 98px 0;
		}
	}

	.img-container {
		height: 78px;
		width: 78px;
		margin: 0 auto;
		position: relative;

		@include break-min($large1) {
			height: 98px;
			width: 98px;
			margin: 0 auto;
		}

		img {
			@include absolute-center();
			height: 100%;
			object-fit: cover;
		}
	}

	.title {
		> *:first-child {
			@include type-h1-refresh();
			margin: 20px auto 0;
			max-width: 960px;

			@include break-min($large1) {
				margin: 30px auto 0;
				padding: 0 200px;
			}
		}
	}

	.blurb {
		margin: 21px 0 0;

		p {
			@include type-p3-refresh();
			margin: 0;
		}
	}

	ul {
		display: flex;
		justify-content: center;
		list-style-type: none;
		margin: 22px 0 0;
		padding: 0;

		li {
			display: flex;

			&:not(:last-child) {
				border-right: 1px solid;
				margin: 0 12px 0 0;
				padding: 0 12px 0 0;
			}

			p {
				@include font-size(1.4);
				margin: 0;
				line-height: lh(23, 14);
				letter-spacing: -0.25px;

				&:first-of-type {
					font-weight: $bold-weight;
					margin: 0 5px 0;
				}
			}
		}
	}

	.description {
		margin: 22px auto 0;
		max-width: 650px;
	}
}
