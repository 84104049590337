// sass-lint:disable no-ids
// sass-lint:disable max-file-line-count

body {
	@include font-size(1.6);
	color: $grey;
	font-variant-ligatures: none;
	// sass-lint:disable no-vendor-prefixes
	-webkit-font-variant-ligatures: none;
	-webkit-font-smoothing: antialiased;
	// sass-lint:enable no-vendor-prefixes

	@media only screen and (max-width: $mobile) {
		padding-top: $nav-base-height;
	}

	&.nav-shown {
		// sass-lint:disable-block no-misspelled-properties
		height: 100%;
		overflow: hidden;
		overflow-scrolling: none;
		position: fixed;
		width: 100%;
	}

	&.overlay-shown {
		// sass-lint:disable-block no-misspelled-properties
		overflow-scrolling: none;
		position: fixed;
		width: 100%;
	}

	&.iphone,
	&.ipad {
		.android-item {
			@include visuallyhidden();
		}
	}

	&.android {
		.ios-item {
			@include visuallyhidden();
		}
	}
}

main {
	&.fixed-footer {
		// when the footer is fixed
		overflow: auto;
		margin-bottom: 120px;

		.content-wrap {
			width: auto;
		}
	}
}

a {
	@include hoverlines();
}

.frame {
	padding-top: $nav-base-height;
	position: relative;
	z-index: 300;

	&.content-hide {
		display: none;
	}

	.home & {
		// sass-lint:disable-block no-duplicate-properties
		padding-top: 114px;
		padding-top: calc(#{$nav-base-height});

		.snav-visible {
			padding-top: 0;
		}
	}
}

.wrapper {
	margin: 0 auto;
	max-width: $main;
	padding: 0 30px;
	width: auto;

	@media only screen and (max-width: $mobile) {
		padding: 0 15px;
	}
}

.content-wrap {
	width: 92.87%;
	margin: auto;
}

.content-wrap-full {
	width: 74.435%;
	margin: auto;
}

.flex {
	display: flex;
}

img {
	height: auto;
	max-height: none;
	max-width: 100%;
}

// sass-lint:disable id-name-format
#mfPreviewBarShow,
#mfPreviewBar {
	display: none;
}
// sass-lint:enable id-name-format

.mobile-only {
	@media only screen and (min-width: $lrg-tablet) {
		display: none;
	}
}

.desktop-only {
	@media only screen and (max-width: $mobile) {
		display: none;
	}
}

.mobile-visibility-hidden {
	@media only screen and (max-width: $mobile) {
		visibility: hidden;
	}
}

.need-content {
	color: $red;
	font-family: $primary;
	font-weight: 600;
	padding: 10px;
	text-align: center;
}

article,
aside {
	line-height: 1.65;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 400;
		line-height: 1.25;
	}

	h1 {
		@include font-size(4.8);
		font-family: $primary;
		letter-spacing: -0.5px;
		line-height: 130%;
		margin: 0 0 20px 0;
	}

	h2 {
		@include font-size(2.8);
		letter-spacing: 0;
		line-height: 150%;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: $primary;
}

.std-dl {
	margin: 0;

	dt {
		@include font-size(1);
		font-family: $primary;
		font-weight: 500;
		letter-spacing: 2px;
		line-height: 20px;
		text-transform: uppercase;
	}

	dd {
		margin: 11px 0 20px 0;
		padding: 0;

		a {
			@include link-underline();
		}
	}

	.small {
		@include font-size(1.2);
		display: block;
		font-family: $primary;
		line-height: 20px;
	}

	.social-icons {
		margin: 0;
		padding: 0;
		text-align: left;

		li {
			margin: 0 5px 0 0;
		}
	}

	.executive-bio & {
		dd {
			margin-top: 5px;
		}
	}
}

aside {
	.module {
		margin-bottom: 60px;

		a:not(.btn) {
			@include link-underline();
		}
	}
}

// Section Colors
.food,
.lifestyle,
.cfa-stories,
.news {
	.tag {
		color: $red;
	}
}

.sticky {
	// sass-lint:disable-block no-duplicate-properties
	position: sticky;
	top: 0;
}

article {
	.lists {
		@include justify-content(space-between);
		margin: 0 -25px;

		@media only screen and (max-width: $mobile) {
			margin: 20px 0;
			display: block;
		}

		.list {
			width: $col6;

			@media only screen and (max-width: $mobile) {
				width: auto;
			}

			h3 {
				@include font-size(2.4);
				font-weight: 800;
				letter-spacing: -0.5px;
				line-height: 30px;
				text-align: center;

				@media only screen and (max-width: $mobile) {
					text-align: left;
				}
			}

			&:first-child {
				margin-right: 2 * $col-margin;

				@media only screen and (max-width: $mobile) {
					margin: 0;
				}
			}

			ul {
				margin: 0 0 10px 30px;
			}

			li {
				margin: 0 0 10px 0;
				text-indent: -21px;

				&:before {
					margin-right: 10px;
				}
			}
		}

		dl {
			margin: 10px 25px;
		}

		dd {
			margin: 10px 0 25px 0;
		}

		dt {
			font-weight: 600;
		}
	}
}

.breadcrumb {
	@include font-size(1);
	background-color: $bkgrey;
	font-family: $primary;
	letter-spacing: 2px;
	line-height: 20px;
	padding: 20px 0;
	text-transform: uppercase;

	@media only screen and (max-width: $mobile) {
		padding-top: 30px;
	}

	&.alt {
		background-color: transparent;
		margin-bottom: -40px;
		padding: 40px 0 0;

		a {
			color: $white;
		}
	}

	a {
		color: $dkgrey;
	}

	.icon-arrow-down {
		vertical-align: middle;

		&:before {
			@include transform(rotate(90deg));
			display: inline-block;
		}
	}

	.wrapper {
		@media only screen and (max-width: $desktop) {
			max-width: $bp1;
		}

		@media only screen and (max-width: $sml-desktop) {
			max-width: $bp2;
		}
	}
}

.teasers {
	margin: 50px 0;

	.flex {
		margin-bottom: 30px;

		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}

	h3 {
		@include font-size(2.2);
		font-family: $primary;
		line-height: 34px;
		margin: 0 0 10px 0;
	}

	.img {
		margin-right: 2 * $col-margin;
		width: $col5;

		@media only screen and (max-width: $mobile) {
			margin: 0;
			width: auto;

			img {
				width: 100%;
			}
		}
	}

	.content {
		@include font-size(1.2);
		font-family: $primary;
		line-height: 20px;
		width: $col-article-content;

		// Overinclusive?
		h3 {
			a {
				@include link-underline();
			}
		}

		@media only screen and (max-width: $mobile) {
			margin-top: 25px;
			width: auto;

			p {
				display: none;
			}
		}
	}

	.btn {
		margin-top: 10px;
	}
}

.sidebar-story {
	margin: 50px 0 25px;

	img {
		@media only screen and (max-width: $mobile) {
			width: 100%;
		}
	}
}

.top-hero {
	@include align-items(center);
	display: flex;
	position: relative;

	&:before {
		@include scrim($scrim);
	}
}

.load-more {
	padding: 50px 0;
	text-align: center;
}

.expandable-toggle {
	cursor: pointer;

	@media only screen and (min-width: $mobile) {
		display: none;
	}

	.hide {
		display: none;
	}

	&.active {
		.show {
			display: none;
		}

		.hide {
			display: inline-block;
		}
	}
}

.video-wrapper {
	padding-bottom: 56.25%;
	padding-top: 25px;
	position: relative;
	height: 0;

	iframe {
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.print-image,
.print-footer {
	display: none;
}

.checkmark {
	@include fir("img/checkmark.png", 84px, 112px);
	background-position: center;
	background-size: 50%;
}

.nowrap {
	white-space: nowrap;
}

.server-error {
	.error-header {
		color: $red;
		padding: 40px 0;
		text-align: center;
	}

	.icon-logo-desktop {
		@include font-size(4.8);

		.name {
			display: none;
		}
	}

	.frame {
		padding-top: 0;
	}

	.error-message {
		@include font-size(2.2);
		line-height: 34px;
		margin: 0 auto;
		max-width: 650px;
		padding: 50px 30px;
	}

	.title {
		@include font-size(4.8);
		line-height: 130%;
		letter-spacing: -0.5px;
	}
}

input[type="radio"],
input[type="checkbox"] {
	margin: 15px 7px 0px 10px;
}

.vh {
	@include visuallyhidden();
}

.visually-hidden {
	// sass-lint:disable-block no-duplicate-properties
	border: 0;
	clip: rect(1px 1px 1px 1px); // for Internet Explorer
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.warm-white-bckgrd {
	background: $warm-white;
	padding: 100px 20px 20px;
}

.view-all-container {
	display: flex;
	justify-content: center;
}
