// GLOBAL Textblock
// ==========================================================================

.g-textblock {
	padding: 60px 0 70px;
	text-align: center;
	overflow: hidden;
	position: relative;
	z-index: $z1;

	&.cream-bckgrd {
		background: $warm-white;
	}

	&__inner {
		position: relative;
		z-index: $z2;
		padding: 0 16px;
		max-width: 900px;
		width: 100%;
		margin: auto;

		@include break-min($large1) {
			padding: 0 24px;
		}
	}

	&__h1,
	&__h2 {
		@include type-h2();
		color: $vis-blue;
		padding: 0 0 25px;
		margin: 0 auto;
		display: block;
		width: 100%;

		@include break-min($large1) {
			padding: 0 0 25px;
		}

		&.red {
			color: $red;
			line-height: normal;
		}
	}

	&__h3 {
		@include type-h3();
		color: $vis-blue;
		padding: 0 0 15px;
		margin: 0 auto;
		display: block;
		width: 100%;

		@include break-min($large1) {
			padding: 0 0 25px;
		}
	}

	p {
		@include type-p2();
		color: $vis-grey;
		padding: 0;
		margin: 0 auto;

		+ p {
			padding: 15px 0 0;
		}
	}

	.btn-round {
		margin: 30px 0 0;

		&.full-width {
			@include break-max($small4) {
				margin-left: 16px;
				margin-right: 16px;
				width: calc(100% - 32px);
			}

			@include break-min($large1) {
				width: auto;
			}
		}
	}

	h1 {
		@include type-h1();
		color: $vis-blue;
		display: block;
		margin: 0 auto;
		padding: 0 0 25px;
		width: 100%;
	}

	&--max-width {
		.g-textblock__inner {
			max-width: 608px;
			margin: 0 auto;
		}
	}

	&--cream-bg {
		background: $cream;
	}

	&--dot-sep-top {
		&:before {
			@include dot-border-h(top, center);
			height: 10px;
			margin: 0 20px;
			max-width: calc(100% - 40px);

			@include break-min($large1) {
				margin: 0 128px;
				max-width: calc(100% - 256px);
			}
		}
	}

	&--mobile-text-left {
		@include break-max($large1) {
			text-align: left;
		}
	}

	&--less-margin {
		.g-textblock {
			&__h2 {
				padding-bottom: 25px;
			}

			&__inner {
				.btn-round {
					margin: 0;
				}
			}
		}
	}

	&--less-padding-top {
		padding: 30px 0;
	}

	&--no-padding-bottom {
		padding-bottom: 0;
	}

	// Styles for textblock with large image
	&--large-img {
		padding-bottom: 0;

		img {
			flex: 0 0 auto;
			height: auto;
			margin-top: 40px;
			max-width: $large2;

			@include break-max($small4) {
				max-width: calc(100% - 32px);
			}
		}
	}

	& .g-carousel-grid {
		margin: 30px auto 0;

		&__slide-body {
			h3,
			p {
				color: $white;
			}

			p {
				@include type-p1();
				font-weight: $bold-weight;
			}

			@media only screen and (max-width: $mobile) {
				bottom: 0;
				left: 0;
				padding: 16px;
				position: absolute;
				text-align: left;

				h3 {
					display: none;
				}

				p {
					@include font-size(1.4);
				}
			}
		}
	}

	.btn-container {
		margin-top: 30px;

		.btn-round {
			margin-top: 10px;
			width: 100%;

			@include break-min($large1) {
				width: auto;

				&:not(:first-child) {
					margin-left: 17px;
				}
			}
		}
	}

	.callout {
		padding-top: 30px;

		.btn-round {
			margin-top: 0;
		}
	}

	&--h3 {
		.g-textblock {
			&__h2 {
				@include type-h3();
				padding-bottom: 10px;

				@include break-min($large1) {
					margin-bottom: -15px;
					padding-bottom: 0;
				}
			}
		}
	}
}
