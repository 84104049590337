.menu-mobile-img-container {
	position: relative;

	@include break-max($medium4) {
		border-radius: 32px;
		height: 164px;
		margin: 0;
		overflow: hidden;
	}

	picture {
		display: block;
		height: 100%;
		width: 100%;
	}

	img {
		@include absolute-center();
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}
