.content-hub-quiz {
	display: flex;
	flex-flow: row;
	margin: 0 0px 16px;
	overflow: visible;
	position: relative;
	height: 246px;

	@include break-min($medium1) {
		height: 306px;
	}

	@include break-min($medium4) {
		height: 405px;
		margin: 0 46px 16px;
	}

	@include break-min($large1) {
		height: 500px;
		margin: 0 72px 42px;
	}

	@include break-min($large2) {
		height: 550px;
		visibility: visible;
	}

	@include break-min($large3) {
		height: 609px;
	}

	@include break-min($huge4) {
		margin-left: auto;
		margin-right: auto;
		max-width: 1512px;
		width: 100%;
	}

	.message-container {
		background-color: $cool-white;
		border-top-left-radius: 16px;
		border-bottom-left-radius: 16px;
		display: flex;
		flex-flow: column;
		padding: 30px 40px 30px 20px;
		width: 50%;
		justify-content: center;

		position: absolute;
		display: flex;
		justify-content: center;
		z-index: 99;
		transform: translateY(-50%);
		width: 300px;
		top: 50%;

		@include break-min($medium1) {
			padding: 30px 0px 30px 60px;
		}

		@include break-min($medium4) {
			border-top-left-radius: 36px;
			border-bottom-left-radius: 36px;
			position: absolute;
			display: flex;
			justify-content: center;
			z-index: 99;
			transform: translateY(-50%);
			width: 400px;
			top: 50%;
		}

		@include break-min($large1) {
			position: absolute;
			display: flex;
			justify-content: center;
			z-index: 99;
			transform: translateY(-50%);
			width: 400px;
			top: 50%;
		}

		@include break-min($large3) {
			position: absolute;
			display: flex;
			justify-content: center;
			z-index: 99;
			transform: translateY(-50%);
			width: 460px;
			top: 50%;
		}

		.btn {
			width: 138px;
			@include btn-round;
			@include btn-primary-red;

			@include break-min($large1) {
				width: inherit;
			}

			@include break-min($medium4) {
				width: 179px;
			}
		}

		.title-text {
			@include break-min($large1) {
				margin-right: 0;
			}

			h2 {
				line-height: lh(25, 22);
				letter-spacing: -1px;
				margin-bottom: 10px;
				text-align: left;
				color: $red;
				font-weight: 700;
				@include font-size(2.4);

				@include break-min($medium4) {
					@include font-size(3.4);
				}

				@include break-min($large1) {
					@include font-size(3.9);
				}

				@include break-min($large4) {
					@include font-size(4.8);
					line-height: lh(58, 48);
				}
			}
		}
	}

	.img-container {
		background-color: $cool-white;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		position: relative;
		width: 100%;
		height: auto;

		@include break-min($medium1) {
			border-top-right-radius: 16px;
			border-bottom-right-radius: 16px;
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;
		}
		@include break-min($medium4) {
			border-top-right-radius: 36px;
			border-bottom-right-radius: 36px;
			border-top-left-radius: 36px;
			border-bottom-left-radius: 36px;
		}

		@include break-min($large3) {
			border-top-left-radius: 36px;
			border-bottom-left-radius: 36px;
			overflow: visible;
		}

		img {
			position: absolute;
			display: block;
			height: 260px;
			width: auto;
			top: -15px;
			right: 0;
			z-index: 999;

			@include break-min($medium1) {
				height: 320px;
			}

			@include break-min($medium4) {
				top: -24px;
				right: -36px;
				height: 427px;
				object-fit: cover;
				z-index: 999;
			}

			@include break-min($large1) {
				height: 520px;
				top: -21px;
				right: -41px;
				object-fit: fill;
				z-index: 999;
			}

			@include break-min($large2) {
				top: -24px;
				right: -43px;
				height: 573px;
				-o-object-fit: cover;
				object-fit: fill;
			}

			@include break-min($large3) {
				top: -24px;
				right: -52px;
				height: 633px;
				-o-object-fit: cover;
				object-fit: cover;
			}

			&.mobile-only {
				display: block;

				@include break-min($medium4) {
					display: none;
				}
			}

			&.desktop-only {
				display: none;

				@include break-min($medium4) {
					display: block;
				}
			}
		}
	}
}
