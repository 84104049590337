.mode-moo-post-launch {
	overflow: hidden;

	.codemoo-video-callout-header {
		display: none;
	}

	.codemoo-hero {
		.codemoo-hero-header-comingsoon {
			margin-top: 87px;

			@include break-min($large1) {
				margin-top: 75px;
			}
		}
	}

	.codemoo-header-img-list-callout {
		ul {
			li {
				.img-callout {
					.content {
						h2 {
							@include font-size(2.4);
							color: $blue;

							@include break-min($large1) {
								@include font-size(3.6);
								line-height: 42px;
								font-family: $primary;
								font-weight: $bold-weight;
							}
						}

						p {
							@include font-size(1.8);
							font-weight: normal;
						}
					}
				}
				&:nth-child(even) {
					.img-callout {
						.desktop-img {
							order: 1;
						}
					}
				}
			}
		}
	}
}
