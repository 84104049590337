.generic-header-text {
	padding: 32px 0;

	.container {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		text-align: center;
		position: relative;

		picture {
			display: none;
			position: absolute;
			width: 100px;
			height: 100px;
			background: transparent;
			border-radius: 100%;
			padding: 20px;
			left: calc(50% - 50px);
			top: -115px;

			img {
				height: 100%;
			}
		}

		h1 {
			@include font-size(4.8);
			color: $blue;
			margin: 0 0 20px;
			font-weight: 700;
		}

		hr {
			border: none;
			display: none;
			height: auto;
			margin: 0 0 20px;
			line-height: 100%;

			&:before {
				content: "• • •";
				color: $blue;
				@include font-size(3.2);
				font-weight: bold;
			}
		}
	}

	&.mod-2-col {
		.container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 0 64px;
			align-items: center;

			h1 {
				text-align: right;
			}

			p {
				text-align: left;
			}
		}
	}

	&.mod-red-title {
		h1 {
			color: $dkred;
		}

		hr {
			&::before {
				color: $dkred;
			}
		}
	}

	&.mod-show-separator {
		hr {
			display: block;
		}
	}

	&.mod-cool-white {
		background-color: $cool-white;

		picture {
			background-color: $cool-white;
		}
	}

	&.mod-top-icon {
		padding-top: 64px;
		picture {
			display: block;
		}
	}
}
