.location-event {
	position: relative;
	padding-top: 45px;

	@include break-min($large1) {
		margin-top: 0;
	}

	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		height: 6px;
		background-image: url("img/cp-icons/icon-cp_dot-gray.svg");
		background-size: 12px 6px;
		background-repeat: repeat-x;
		top: 0;
		background-position: 50%;
	}

	&-image {
		img {
			width: 100%;
		}
	}

	.flex {
		display: block;

		@include break-min($large1) {
			display: flex;
		}
	}

	.module {
		width: 100%;
		margin-bottom: 42px;

		h2,
		h3 {
			@include s3();
			margin-top: 0;
			margin-bottom: 10px;
			text-transform: uppercase;
			color: $blue;
			@include font-size(1.1);
		}
	}

	.location-event-form {
		margin-bottom: 15px;
		flex-flow: column;

		input {
			border: 1px solid $iron;
			border-radius: 10px;
			padding: 15px;
			margin: 15px 0 5px;
			width: 100%;
			@include font-size(1.4);

			@include break-min($large1) {
				width: 50%;
			}
		}

		.btn {
			@include break-min($large1) {
				max-width: 150px;
			}
		}

		.toggle-msg-display {
			margin-bottom: 22px;

			.success-msg,
			.fail-msg {
				display: none;

				&.display {
					display: block;
				}

				p {
					@include type-p2();
					margin-bottom: 0;
				}
			}

			.success-msg {
				p {
					&::before {
						content: url("img/checkmark-green.svg");
						margin-right: 5px;
					}
				}
			}

			a {
				@include font-size(1.2);
				@include link-underline();
			}
		}

		&-legal {
			margin: 25px 0px;

			p {
				color: $rolling-stone;
				@include font-size(1.1);
			}
		}
	}
}
