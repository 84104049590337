.popular-questions {
	background-color: $warm-white;
	padding: 72px 24px;

	@include break-min($large1) {
		padding: 96px 11px 112px;
	}

	.header {
		margin-bottom: 48px;

		@include break-min($large1) {
			margin-bottom: 80px;
		}

		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);
			text-align: left;

			@include break-min($large1) {
				@include font-size(4.8);
				line-height: lh(58, 48);
				margin-bottom: 80px;
				text-align: center;
			}
		}
	}

	.question__boxes {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		max-width: 192rem;

		@include break-min($large1) {
			flex-direction: row;
		}
	}

	.question__box {
		position: relative;

		&:not(:first-of-type) {
			margin-top: 64px;
		}

		@include break-min($large1) {
			&:not(:first-of-type) {
				border-left: 1px solid $faint-grey;
				margin-top: 0;
			}
		}

		.question__box-link {
			height: 100%;
			position: absolute;
			width: 100%;
		}

		.question__box-contents {
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: space-between;

			@include break-min($large1) {
				margin: 0 auto;
				width: 70%;

				&:not(:first-of-type) {
					border-left: 1px solid $faint-grey;
					margin-top: 0;
				}
			}
		}

		.question__box-title {
			p {
				@include font-size(2);
				color: $grey;
				font-weight: $bold-weight;
				letter-spacing: -1%;
				line-height: lh(28, 20);
				text-align: left;

				@include break-min($large1) {
					@include font-size(2.4);
					line-height: lh(34, 24);
				}
			}
		}

		.question__box-description {
			margin-bottom: 24px;

			@include break-min($large1) {
				margin-bottom: 20px;
			}

			p {
				@include font-size(1.6);
				color: $grey;
				font-weight: $regular-weight;
				line-height: lh(25, 16);
				margin-bottom: 0;
				text-align: left;
			}
		}

		.question__box-read-more {
			p {
				@include font-size(1.2);
				color: $red;
				font-weight: $bold-weight;
				letter-spacing: 2;
				line-height: lh(20, 12);
				margin-bottom: 0;
				text-align: left;
				text-transform: uppercase;
			}
		}
	}
}
