@import "covid-facts";
@import "sunshine";

.facts {
	padding: 50px 0;
	text-align: center;

	h3 {
		& + p {
			@include font-size(1.6);
			font-family: $primary;
			line-height: 24px;
			margin: 20px auto 0;
			text-align: center;
			width: $col6;

			@media only screen and (max-width: $mobile) {
				width: $col10;
			}
		}
	}

	.stats {
		@extend .wrapper;
		display: flex;
		padding-top: 50px;
		justify-content: space-between;
		text-align: center;

		@media only screen and (max-width: $mobile) {
			flex-direction: column;
		}

		.fact {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
			width: $col3;

			@media only screen and (max-width: $mobile) {
				margin: 40px 0;
				width: auto;
			}

			a:focus {
				outline: 2px dotted $red;
			}
		}

		.highlight {
			@include font-size(2.8);
			color: $red;
			font-weight: 600;
			display: block;
			line-height: 150%;
			margin: 20px 0;
		}
	}
}
