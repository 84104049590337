.nge-pointcards {
	margin: 0;
	padding: 40px 0;
	position: relative;

	&__btn-wrap {
		text-align: center;
		padding: 35px 0 0;
	}

	&__head {
		h3 {
			margin: 0;
			padding: 0 0 15px;
			display: block;
			@include h5();
		}
	}

	&__section-title {
		@include h5();
		margin: 0;
		padding: 5px 8px 10px;
		color: $vis-blue;
		display: block;

		@include flex(1 0 100%);
	}

	&__bar {
		padding: 50px 8px 0;
		margin: 0;
		position: relative;

		@include flex(1 0 100%);

		&-inner {
			padding: 35px 0;
			position: relative;

			> *:first-child {
				@include font-size(2);
				align-items: center;
				background-color: $warm-white;
				border-radius: 8px;
				color: $vis-blue;
				display: flex;
				height: 56px;
				justify-content: center;
				letter-spacing: -1%;
				line-height: 28px;
				margin: 0;
				text-align: center;

				@include break-min($large1) {
					height: 91px;
				}
			}
		}

		&:first-child {
			padding-top: 0;
		}

		h2 {
			@include h4();
			color: $vis-blue;
			padding: 14px 10px;
			margin: 0;
			border-radius: 8px;
			background-color: $warm-white;
			display: block;
			text-align: center;

			@include break-min($medium1) {
				padding: 8px 10px;
			}
		}
	}

	@include break-min($large1) {
		padding: 55px 0;
	}

	@include break-min($small5) {
		&__section-title,
		&__bar {
			padding-right: 12px;
			padding-left: 12px;
		}
	}

	@include break-min($large1) {
		&__section-title,
		&__bar {
			padding-right: 8px;
			padding-left: 8px;
		}
	}

	@include break-min($large2) {
		&__section-title,
		&__bar {
			padding-right: 12px;
			padding-left: 12px;
		}
	}

	&--pad {
		&-top {
			padding: 35px 0 0;
		}

		&-bot {
			padding: 0 0 35px;
		}
	}

	&--filtered {
		.nge-pointcards__bar,
		.nge-pointcards__section-title {
			display: none;
		}
	}

	.nge-card__grid {
		.nge-pointcards__section-title {
			display: none;
		}

		.nge-card {
			&__icon-img {
				height: 22px;
				position: absolute;
				right: 10px;
				top: 10px;
				width: auto;

				@include break-min($large1) {
					height: 41px;
				}
			}

			&__img {
				margin: auto;
				max-height: 53px;
				width: auto;

				@include break-min($large1) {
					max-height: 138px;
				}
			}
		}
	}
}
