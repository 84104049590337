.video-banner.video-banner-theme {
	&__cool-white {
		background-color: $cool-white;
	}

	&__warm-white {
		background-color: $warm-white;
	}

	&__social-icons {
		.container_article {
			.article-location,
			.article-info {
				display: none;
			}

			.article-social-icons {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				margin: 24px 0 0;
				padding-left: 0;

				li {
					list-style: none;
					margin-right: 8px;

					a {
						@include font-size(3.5);
						color: $red;
						font-weight: $regular-weight;
						height: 36px;
						line-height: lh(35, 35);
						width: 36px;

						span {
							clip: rect(0 0 0 0);
							height: 1px;
							margin: -1px;
							overflow: hidden;
							padding: 0;
							position: absolute;
							width: 1px;
						}
					}
				}
			}
		}
	}

	&__cta-button {
		.container_article {
			.article-location,
			.article-info {
				display: none;
			}

			.article-cta {
				background: linear-gradient(
					180deg,
					rgba(254, 67, 108, 0.987036) 0%,
					rgba(221, 0, 49, 0.99343) 49.32%,
					#c20726 100%
				);
				border: none;
				border-radius: 24px;
				display: block;
				height: 48px;
				margin-top: 24px;
				width: 144px;

				a {
					@include font-size(1.6);
					color: $white;
					font-family: "apercu", "sans-serif";
					font-weight: $regular-weight;
					letter-spacing: -0.56px;
					line-height: lh(24, 16);
					margin-bottom: 0;
				}
			}
		}
	}

	&__no-social-cta {
		.container_article {
			.article-location,
			.article-info {
				display: none;
			}
		}
	}

	&__no-header {
		padding-top: 0;

		@include break-min($large2) {
			padding-top: 115px;
		}

		.header {
			display: none;
		}

		.container_article {
			.article-location,
			.article-info {
				display: none;
			}
		}
	}

	&__cool-white__social-icons {
		@extend .video-banner.video-banner-theme__cool-white;
		@extend .video-banner.video-banner-theme__social-icons;
	}

	&__cool-white__cta-button {
		@extend .video-banner.video-banner-theme__cool-white;
		@extend .video-banner.video-banner-theme__cta-button;
	}

	&__warm-white__no-social-cta {
		@extend .video-banner.video-banner-theme__warm-white;
		@extend .video-banner.video-banner-theme__no-social-cta;
	}

	&__warm-white__no-header {
		@extend .video-banner.video-banner-theme__warm-white;
		@extend .video-banner.video-banner-theme__no-header;
	}
}
