.operator-callout {
	background: $cool-white;

	.bg-wrapper {
		padding: 0 24px 38px;

		@include break-min($large1) {
			@include desktop-grid();
			@include desktop-grid-max-width();
			column-gap: 24px;
			padding: 64px 15px 64px 0;
		}

		// handle no profile pic
		&[data-child-count="1"] {
			.content {
				@include break-min($large1) {
					grid-column: col-start 4 / span 6;
					text-align: center;
				}
			}
		}
	}

	.img-container {
		border-radius: 50%;
		position: relative;
		height: 170px;
		margin: 0 auto 24px;
		overflow: hidden;
		width: 170px;

		@include break-min($large1) {
			grid-column: col-start 2 / span 3;
			height: 170px;
			margin: 0;
			width: 170px;
		}

		img {
			@include absolute-center();
			object-fit: cover;
			width: 100%;
		}
	}

	.content {
		text-align: center;

		@include break-min($large1) {
			grid-column: col-start 5 / span 7;
			margin: auto;
			text-align: left;
		}

		.label p {
			@include font-size(1.8);
			color: $blue;
			font-weight: $medium-weight;
			line-height: lh(28, 18);
			letter-spacing: -0.5px;
			margin: 0 0 8px 0;
		}

		.title {
			> *:first-child {
				@include font-size(3.6);
				color: $blue;
				font-weight: $bold-weight;
				line-height: lh(46, 36);
				letter-spacing: -0.8px;
				margin: 0 0 24px 0;

				@include break-min($large1) {
					@include font-size(4.8);
					line-height: lh(58, 48);
					letter-spacing: -1px;
					margin: 0 0 16px 0;
				}
			}
		}

		.subtext p {
			@include font-size(1.8);
			line-height: lh(28, 18);
			letter-spacing: -0.5px;
			margin: 0 0 24px 0;

			@include break-min($large1) {
				margin: 0 0 16px 0;
			}
		}
	}
}
