.feature-category {
	@include grid-margin();
	justify-content: center;
	gap: 24px;
	margin-bottom: 32px;
	margin-top: 54px;

	@include break-min($medium4) {
		// margin: auto;
		margin-top: 64px;
		margin-bottom: 80px;
		max-width: none;
	}

	&.reverse-order {
		.grid-wrapper {
			> a {
				order: 2;
			}
		}
	}

	.grid-wrapper {
		grid-column: col-start 1 / span 4;
		overflow: hidden;

		@include break-min($medium4) {
			border-radius: 32px;
			display: flex;
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 12;
		}

		> a,
		> .content-container {
			flex: 50%;
		}

		.g-img-container {
			@extend .menu-mobile-img-container;


			@include break-min($medium4) {
				border-radius: 0;
				min-height: 682px;
				height: 100%;
			}

			@include break-max($large1) {
				&.featured-menu-title {
					height: auto;
					border-radius: 0;

					picture {
						display: block;
						height: 164px;
						overflow: hidden;
						border-radius: 32px;

						img {
							position: unset;
						}
					}

					.link-container {
						display: flex;
						justify-content: space-between;
						align-items: center;
						gap: 16px;

						h1 {
							@include font-size(2);
						}

						.icon-arrow-down {
							transform: rotate(-90deg);
						}
					}
				}
			}
		}

		.content-click {
			@include break-min($large1) {
				.menu-title {
					display: none;
				}
			}

			@include break-max($large1) {
				.menu-title {
					display: flex;
					padding: 11px 15px;
					align-items: center;
					justify-content: space-between;

					h1 {
						font-size: 20px;
						color: #dd0031;
						font-weight: 700;
						letter-spacing: -.5px;
						line-height: 1.4;
						margin: 0;
					}

					span {
						transform: rotate(-90deg);
					}
				}
			}
		}
	}

	.content-container {
		@include break-min($medium4) {
			background: $cool-white;
			padding: 50px 72px;
		}

		.title {
			padding: 0 16px;

			@include break-min($medium4) {
				padding: 0 0 36px;
			}

			.mobile-toggle {
				@extend .menu-mobile-toggle;

				@include break-max($large1) {
					display: none !important;
				}
			}

			@include break-min($medium4) {
				h1 {
					color: $blue;
					a {
						color: $blue;
					}
				}
			}

			p {
				@extend .menu-mobile-text;

				@include break-min($medium4) {
					@include font-size(1.8);
					letter-spacing: -0.5px;
					line-height: lh(28, 18);
					margin: 0 0 16px;
				}

				a {
					@extend .menu-mobile-text;
				}
			}

			.btn-round {
				display: none;

				@include break-min($medium4) {
					display: block;
					width: fit-content;
				}
			}
		}

		.featured-items-desktop {
			border-top: 1px solid $faint-grey;
			display: none;
			gap: 24px;
			flex-direction: column;
			padding: 16px 0 0;
			list-style-type: none;
			margin: 0;

			@include break-min($medium4) {
				display: block;
			}

			li {
				display: flex;

				.img-container {
					position: relative;
					height: 150px;
					width: 200px;

					img {
						@include absolute-center;
						height: 100%;
						object-fit: cover;
						width: 100%;
					}
				}

				.content {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 0 0 0 36px;

					a > :first-child {
						@include font-size(1.8);
						color: $blue;
						font-weight: $bold-weight;
						line-height: lh(28, 18);
						letter-spacing: -0.5px;
						margin: 0 0 10px;
					}

					.btn-round {
						align-self: self-start;
					}
				}
			}
		}
	}

	.mobile-menu-items {
		display: none;
		margin: 32px 0 0;
	}

	.featured-items-desktop {
		display: none;

		@include break-min($medium4) {
			display: block;
		}
	}
}
