.module-wrapper {
	padding: 26px 0;

	@include break-min($medium4) {
		padding: 46px 0;
	}

	@include break-min($large3) {
	}

	@include break-min($large1) {
	}

	&.padding-top-0 {
		padding-top: 0;
	}

	.classic-callout {
		margin-bottom: 0;
	}

	h1 {
		font-size: 2.8rem !important;

		@include break-min($medium4) {
			font-size: 4.8rem !important;
		}
	}
}
