.gifting-img-tile {
	margin-bottom: 16px;

	@include break-min($large1) {
		background: $cool-white;
		display: flex;
		flex-direction: column;
		grid-column: 1;
		grid-row: 1 / span var(--row-number);
		height: 100%;
		justify-content: center;
		margin: 0;

		@include ie-only() {
			position: absolute;
			top: 0;
			height: 100%;
		}
	}

	img {
		@include object-fit(contain, center);
		height: 100%;
		max-height: 260px;
		width: 100%;

		@include break-min($large1) {
			max-height: 340px;
		}
	}

	h4 {
		@include type-h4();
		@include font-size(2.4);
		align-self: center;
		color: $blue;
		line-height: 1.3333;
		margin: 0;
		text-align: center;

		@include break-min($large1) {
			line-height: 1.41666666667;
			margin: 0 auto;
			max-width: 225px;
		}
	}
}
