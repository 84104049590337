.team-member-landing {
	.header-columns-text {
		.testimonials {
			dt {
				@include break-min($large1) {
					@include type-h2-refresh();
				}
			}
		}
	}

	.three-column-with-cta {
		.three-column-with-cta-headline-container {
			max-width: 1200px;
		}
	}
}
