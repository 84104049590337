// Grid layout as defined in HomeRefresh v2
// WARNING CHANGING will alter - Classic Callouts
.grid-layout {
	display: grid;
	grid-template-columns: repeat(4, [col-start] 1fr);
	// 860px
	@include break-min($medium4) {
		grid-template-columns: repeat(8, [col-start] 1fr);
	}
	// 1200px
	@include break-min($large4) {
		grid-template-columns: repeat(12, [col-start] 1fr);
	}
	// 1728px
	@include break-min($huge4) {
		grid-template-columns: repeat(12, [col-start] 104px);
		margin-left: auto;
		margin-right: auto;
		// Grid-gap not incl. b/c gap adds dividing line
		// Total taken from grid-column: 104px * 12
		// max-width: 1348px;

		&.full-width {
			grid-template-columns: repeat(12, [col-start] 1fr);
			gap: 0;
		}
	}
}
