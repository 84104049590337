.content-hub-hero-animated {
	z-index: 99999;
	position: relative;
	overflow: visible;
	margin-bottom: 0;
	max-width: 1512px;
	width: auto;
	padding: 0 32px 0 !important;

	@include break-min($medium4) {
		height: 279px;
		margin: 0 46px 16px !important;
	}

	@include break-min($large3) {
		height: 370px;
		margin: 0 72px 0 !important;
	}

	@include break-min($large4) {
		height: auto;
		padding: 0 62px 0 !important;
	}

	@include break-min($huge4) {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	&-wrapper {
		position: relative;
		margin-top: 150px;

		@include break-min($small2) {
			margin-top: 190px;
		}

		@include break-min($small3) {
			margin-top: 245px;
		}

		@include break-min($small4) {
			margin-top: 290px;
		}

		@include break-min($medium1) {
			margin-top: 320px;
		}

		@include break-min($medium2) {
			max-height: 309px;
			margin-top: 0;
		}

		@include break-min($large1) {
			max-height: 369px;
			margin-top: 50px;
		}
		@include break-min($large4) {
			max-height: none;
			margin-top: -100px;
		}

		.image-container {
			top: 0;
			bottom: -64px;
			width: 100%;
			z-index: 99999;

			@include break-min($medium2) {
				bottom: -104px;
			}

			@include break-min($medium4) {
				bottom: -70px;
			}

			@include break-min($large1) {
				bottom: -134px;
			}

			img {
				height: 100%;
				margin: 0 auto;
				display: flex;
				overflow: visible;
				position: absolute;
				height: auto;
				bottom: -15px;

				@include break-min($small3) {
					bottom: -19px;
				}

				@include break-min($small4) {
					bottom: -26px;
				}

				@include break-min($medium1) {
					bottom: -30px;
				}

				@include break-min($medium2) {
					width: 670px;
					bottom: 26px;
					position: relative;
				}

				@include break-min($medium4) {
					bottom: 33px;
				}

				@include break-min($large1) {
					position: relative;
					bottom: 97px;
					width: 805px;
				}

				@include break-min($large3) {
					width: 900px;
					bottom: 69px;
				}

				@include break-min($large4) {
					width: 100%;
					bottom: -50px;
				}
			}
		}
	}
}
