.ecommerce-callout {
	background: $red;
	padding: 0 24px 64px;

	@include break-min($large1) {
		height: 487px;
		padding: 0 24px 0 0;
	}

	.flex-wrapper {
		@include break-min($large1) {
			align-items: center;
			justify-content: flex-start;
		}
	}

	.img-wrapper {
		margin-bottom: 62px;
		padding-top: 30px;
		height: 100%;

		@include break-min($large1) {
			justify-content: flex-start;
			margin: 0 50px 0 50px;
			max-width: 605px;
		}

		.desktop-img {
			padding: 30px 0;
		}
	}

	.content {
		color: $white;
		text-align: center;

		@include break-min($large1) {
			max-width: 550px;
			text-align: left;
		}

		.headline {
			margin-bottom: 16px;
		}

		h4 {
			margin-bottom: 40px;
		}

		.btn-round {
			@extend .btn-round--white;
		}
	}
}
