.author-bio {
	.frame {
		padding-top: 100px;

		@media only screen and (max-width: $mobile) {
			padding-top: 20px;
		}
	}

	.related {
		&.wrapper {
			margin: 0;
			max-width: none;
			padding: 0;
		}
	}

	.bio {
		background-color: $bkgrey;

		.wrapper {
			display: flex;
			padding: 20px;
			width: $col8;

			@media only screen and (max-width: $mobile) {
				flex-direction: column;
				width: auto;
			}
		}
	}

	.author-pic {
		width: $col4;

		img {
			width: 100%;
		}

		@media only screen and (max-width: $mobile) {
			width: $col12;
		}
	}

	.author-bio {
		font-family: $primary;
		line-height: 1.5;
		margin-left: $col-margin;
		width: $col8;

		@media only screen and (max-width: $mobile) {
			width: $col12;
		}

		h1 {
			font-family: $primary;
		}
	}
}
