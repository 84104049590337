.delivery-modal {
	background: $white;
	border-radius: 8px;
	margin: 0 auto;
	max-width: 644px;
	position: relative;
	text-align: center;

	.inner {
		padding: 80px 25px;

		@include break-min($large1) {
			padding: 80px 53px;
		}

		button:nth-of-type(2) {
			&.mfp-close {
				@include break-min($large1) {
					padding: 0;
				}
			}
		}
	}

	h4 {
		@include font-size(2.4);
		color: $blue;
		font-weight: bold;
		letter-spacing: -0.5px;
		margin: 0 0 12px;

		@include break-min($large1) {
			margin-bottom: 70px;
			@include font-size(3.6);
		}
	}

	.delivery-modal-callout {
		text-align: center;

		@include break-min($large1) {
			text-align: left;
		}

		h3 {
			@include type-h2();
			color: $blue;
			margin-bottom: 16px;
			text-align: left;
			@include font-size(2.8);

			@include break-min($large1) {
				@include type-h4();
				@include font-size(2.4);
			}
		}

		.wrapper {
			background: $cool-white;
			padding: 40px 24px;

			.app-download {
				display: flex;
				align-items: center;
				flex-flow: column;

				@include break-min($large1) {
					flex-flow: row;

					&-cta {
						margin-top: 0;
					}
				}

				&-icon-wrapper {
					width: 40%;
					text-align: center;

					img {
						height: 80px;
						width: auto;
					}
				}

				&-cta {
					margin-top: 10px;

					p {
						margin-bottom: 10px;
					}
				}
			}
		}

		.delivery-button {
			align-content: center;
			background: $white;
			border-radius: 48px;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			column-gap: 10px;
			display: flex;
			flex-direction: row;
			height: 58px;
			justify-content: center;
			margin: 30px auto 0;
			max-width: 280px;
			min-width: 180px;
			transition: all 200ms ease-in-out;

			&:hover {
				box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
				transform: translateY(-1px);
			}

			img {
				align-self: center;
				height: 19px;
				width: auto;
			}
		}

		.delivery-button-text {
			a {
				color: $blue;

				img {
					height: 85%;
				}

				span {
					align-self: center;
					font-weight: $bold-weight;
				}
			}
		}
	}

	.delivery-type-partners-callout {
		margin: 40px 0 0;
		position: relative;

		h3 {
			@include type-h2();
			color: $blue;
			margin-bottom: 16px;
			text-align: left;

			@include break-min($large1) {
				@include type-h4();
				@include font-size(2.4);
			}
		}

		.wrapper {
			background: $cool-white;
			padding: 40px 24px 16px;
			position: relative;
			text-align: center;

			p:first-of-type {
				margin: 0 auto 24px;
				width: $col11;

				@include break-min($large1) {
					width: $col9;
				}
			}
		}

		ul {
			list-style-type: none;
			padding: 0;

			li {
				border-radius: 48px;
				height: 58px;
				box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
				margin: 0 auto 24px;
				background-color: $white;

				@include break-min($large1) {
					max-width: 280px;
					width: 100%;
				}
			}

			a {
				display: flex;
				height: 100%;
				justify-content: center;
				position: relative;

				img {
					bottom: 0;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					top: 0;
				}
			}
		}
	}
}
