@import "../common/accordion";

.accordion {
	article {
		font-family: $primary;

		@include break-min($large1) {
			position: relative;
		}

		h3 {
			font-weight: 800;
		}

		a {
			@include link-underline();
		}
	}

	.cta-btn {
		margin: auto;
		text-align: center;

		@include break-min($large1) {
			bottom: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
		}

		a {
			@extend .btn-round;
			width: 100%;

			@include break-min($large1) {
				width: auto;
			}
		}
	}

	section {
		overflow-y: auto;

		&:focus {
			p,
			ul,
			li {
				font-weight: $regular-weight;
			}
		}
	}

	&.remarkable-futures {
		padding: 0;

		h1 {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			line-height: lh(34, 28);
			letter-spacing: -0.01em;
			text-align: center;

			@include break-min($large1) {
				@include font-size(4.8);
				font-weight: $bold-weight;
				line-height: lh(58, 48);
				letter-spacing: -1px;
			}
		}

		article {
			padding: 32px 24px 43px;

			h3 {
				margin: 0;
			}
		}

		.accordion-body {
			.toggle {
				@include font-size(2.4);
				color: $blue;
				font-weight: $bold-weight;
				line-height: lh(32, 24);
				letter-spacing: -0.01em;
				padding: 28px 15px;

				@include break-min($large1) {
					@include type-h2-refresh();
					padding: 40px 0;
				}

				&:after {
					top: unset;
				}
			}

			section {
				ul {
					margin: 0;
					padding: 0;

					li::marker {
						color: $grey;
					}
				}

				p {
					@include type-p1-refresh();
				}
			}
		}
	}
}
