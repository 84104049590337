.prizes {
	background-color: $white;
	padding: 0 24px 34px;

	@include break-min($large1) {
		padding: 34px 0;
	}

	.prizes-grid {
		align-items: stretch;
		display: flex;
		flex-wrap: wrap;
		flex-grow: 3;
		flex-shrink: 0;
		flex-basis: 0;
		justify-content: center;
		list-style: none;
		padding: 0;
		margin: auto;
		max-width: 1000px;

		.prize {
			flex-shrink: 0;
			text-align: center;
			width: 100%;
			margin-bottom: 30px;
			align-items: start;
			display: flex;
			justify-content: flex-end;
			flex-direction: row;

			@include break-min($large1) {
				width: 33.33339%;
				flex-direction: column;
				align-items: center;
				margin-bottom: 50px;
			}

			.img {
				img {
					overflow: hidden;
					border-radius: 8px;
					width: 140px;

					@include break-min($large1) {
						border-radius: 22px;
						width: 160px;
					}
				}
			}

			.prize-content {
				display: flex;
				flex-flow: column;
				align-items: flex-start;
				padding-left: 24px;

				@include break-min($large1) {
					align-items: center;
					padding: 0;
					margin-top: 10px;
				}
			}

			p {
				text-align: left;

				@include break-min($large1) {
					padding: 0 4rem;
					text-align: center;
				}
			}

			span {
				&.highlight {
					line-height: 34px;
					color: $blue;
					font-weight: $bold-weight;
					margin-bottom: 10px;
					@include font-size(2.8);

					@include break-min($large1) {
						margin: 15px 0;
						@include font-size(3.6);
					}
				}

				&:not(.highlight) {
					line-height: 25px;
					@include font-size(1.6);
				}
			}
		}
	}
}
