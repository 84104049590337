.location-detail-header {
	// Chng to parent div later
	margin: 35px 0;

	@include break-min($large1) {
		margin-top: 48px;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}

	@include break-min($huge1) {
		padding: 0;
	}

	.gridView {
		@include desktop-grid-max-width();
		@include grid-padding();

		@include break-min($large1) {
			@include desktop-grid();
		}
	}

	.location-detail-container {
		margin: 0 0 36px 0;

		@include break-min($large1) {
			grid-column: col-start 1 / span 5;
		}

		@include break-min($huge4) {
			grid-column: col-start 1 / span 4;
		}
	}

	.icon {
		align-items: center;

		img {
			margin: 0 4px 0 0;
		}

		p {
			margin: 0;
		}
	}

	.location-icon {
		display: none;

		@include break-min($large1) {
			border: 1px solid $iron;
			border-radius: 50%;
			display: block;
			height: 115px;
			margin: 0 0 17px 0;
			padding: 17.5px;
			width: 115px;
		}
	}

	.favorite-restaurant-link {
		.heart {
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			margin-right: 7px;
			width: 20px;
			height: 20px;

			@media screen and (max-width: $mobile) {
				margin-right: 5px;
			}
		}

		.heart-icon {
			background-image: url("img/heart-icon.svg");
		}

		.heart-icon-red {
			background-image: url("img/heart-icon-red.svg");
		}
	}

	.favorite-location {
		margin: 0 0 8px 0;

		img {
			margin-right: 8px;
		}

		p {
			@include font-size(1.4);
			letter-spacing: -0.5px;
			line-height: lh(16px, 14px);
			margin: 0;
		}
	}

	.location-name {
		> *:first-child {
			@include type-b1();
			color: $blue;
			margin: 0 0 24px 0;
		}
	}

	.location-details {
		margin: 0 0 36px 0;

		p {
			@include font-size(1.4);
			color: $grey;
		}

		.hours-wrapper {
			position: relative;
		}

		.phone {
			p a {
				color: $grey;
			}
		}

		.need-help {
			align-items: center;
			position: absolute;
			right: -15px;
			bottom: 0;

			@include break-max($small3) {
				bottom: -25px;
				left: 0;
			}

			@include break-min($large1) {
				position: relative;
			}

			img {
				margin: 0 8px 0 0;

				@include break-min($small3) {
					margin: 0 8px 0 20px;
				}
			}

			p {
				@include font-size(1.4);
				color: $red;
				line-height: lh(16, 14);
				letter-spacing: -0.5px;
				margin: 0;
				text-align: left;
			}
		}
	}

	.address {
		margin: 0 0 11px 0;
	}

	.hours {
		margin: 0 16px 0 0;
	}

	.order-selection-container {
		p {
			@include font-size(1.1);
			color: $grey;
			line-height: lh(13px, 11px);
		}

		.license-location {
			margin: 24px 0 0;

			h3 {
				@include font-size(1.4);
				color: $grey;
				margin: 0;
				font-family: $secondary;
				font-weight: $bold-weight;
				letter-spacing: 2.5px;
				text-transform: uppercase;
			}
		}

		.temp-closed {
			p {
				@include font-size(2.4);
				color: $red;
				font-weight: $bold-weight;
				line-height: lh(34, 24);
				letter-spacing: -0.5px;
				margin: 24px 0 0;

				@include break-min($large1) {
					margin: 36px 0 0;
				}
			}
		}
	}

	.location-order-selection {
		margin: 0 0 8px 0;

		ul {
			li {
				margin: 0 8px 0 0;

				a {
					padding: 20px 4px;

					img {
						@include break-min($huge2) {
							width: 35px;
						}
					}

					span {
						@include break-min($huge2) {
							@include font-size(1.4);
						}
					}
				}
			}
			.selection-info {
				margin-top: 8px;

				span {
					@include break-min($large1) {
						margin-top: 0;
					}
				}
			}
		}
	}

	.location-detail-image {
		@include break-min($large1) {
			grid-column: col-start 7 / span 6;
			margin: auto;
			width: 100%;
		}

		@include break-min($huge4) {
			grid-column: col-start 6 / span 7;
		}

		p {
			@include font-size(1.1);
			color: $grey;
			line-height: lh(13px, 11px);
			margin: 0;

			@include break-min($large1) {
				text-align: center;
			}
		}
	}

	.image-wrapper {
		margin: 0 0 7px 0;
		position: relative;
	}

	.image-container {
		@include swap-img();
		border-radius: 24px;
		position: relative;
		height: 200px;
		overflow: hidden;
		width: 100%;

		@include break-min($large1) {
			min-height: 450px;
		}

		img {
			@include absolute-center();
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.map-directions-container {
		align-items: center;
		background: $white;
		border-radius: 24px;
		bottom: 17px;
		display: flex;
		left: 0;
		right: 0;
		margin: auto;
		padding: 6px 24px;
		position: absolute;
		width: fit-content;

		@include break-min($large1) {
			bottom: 24px;
			left: unset;
			right: 24px;
		}

		p {
			@include font-size(1.4);
			color: $red;
			font-weight: 600;
			letter-spacing: -0.5px;
			margin: 0;
		}

		img {
			margin: 0 8px 0 0;
		}
	}
}
