.header-search {
	// padding: 80px 0;
	background-color: $red;
	background-image: url("img/pattern-job-page.svg");
	background-size: cover;
	background-repeat: repeat;
	background-position: center center;
	height: 370px;

	&.short {
		height: 143px;

		@include break-min($large1) {
			height: 176px;
		}

		h1 {
			margin-bottom: 10px;
			line-height: 22px;

			@include font-size(1.8);

			@include break-min($large1) {
				@include font-size(2.4);
			}
		}
	}

	&__wrapper {
		flex-flow: column;
		height: 100%;
		justify-content: center;
		display: flex;
		align-items: center;
		padding: 0 25px;

		h1 {
			@include type-h1();
			color: $white;
			text-align: center;
			margin: 0 auto 38px;
			line-height: 48px;
			letter-spacing: -0.8px;
			@include font-size(3.6);

			@include break-min($large1) {
				max-width: 770px;
				letter-spacing: -1px;
				line-height: 58px;
				@include font-size(4.8);
			}
		}

		form {
			width: 100%;
		}

		input {
			@include font-size(1.6);
			border-radius: 24px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border: none;
			background-color: $white;
			color: $rolling-stone;
			letter-spacing: -0.5px;
			padding: 0 10px 0 16px;
			width: 100%;
			@include font-size(1.2);

			@include break-min($large1) {
				@include font-size(1.4);
			}
		}

		.input-wrapper {
			margin: 0 auto;
			max-width: 562px;
		}

		.btn-round,
		.btn-search {
			margin: 0;
			font-family: $primary;
			font-weight: 500;
			line-height: 24px;
			text-align: center;
			letter-spacing: -0.5625px;
			padding: 17px 8px 14px;
			width: 25%;
			@include font-size(1.6);

			@include break-min($large1) {
				flex: 65%;
				width: 100%;
			}

			&-half {
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
				flex-direction: column;
				display: flex;
				align-items: center;

				&:hover {
					transform: translateY(0px);
				}
			}
		}
	}
}
