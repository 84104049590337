.delivery-directions {
	@include background-color();
	background: $warm-white;
	margin-bottom: 64px;
	padding-bottom: 64px;
	text-align: center;

	@include break-min($large1) {
		margin: 0 auto;
		padding: 0px 24px 96px;
	}

	&.no-dots {
		visibility: visible;

		.icon-row-txt {
			.card:not(:last-of-type) {
				@include break-max($large1) {
					&:before {
						content: "";
						height: 0;
						width: 0;
					}
				}
			}

			.card:not(:first-of-type) {
				@include break-min($large1) {
					&:before {
						content: "";
						height: 0;
						width: 0;
					}
				}
			}
		}
	}

	&.team-member {
		h1 {
			@include font-size(2.8);
			font-weight: $bold-weight;
			letter-spacing: -1px;
			line-height: lh(34, 28);

			@include break-min($large1) {
				@include font-size(4.8);
				font-weight: $bold-weight;
				letter-spacing: -1px;
				line-height: lh(58, 48);
			}
		}

		.title {
			p {
				width: auto;
			}
		}

		.icon-row-txt {
			display: block;

			@include break-min($large1) {
				display: flex;
				max-width: 1400px;
				margin: auto;
			}

			.card {
				padding: 0 36px;

				@include break-min($large1) {
					padding: 0;
				}
			}
		}
	}

	h1 {
		@include type-h2();
		@include font-size(2.4);
		color: $blue;
		letter-spacing: -1%;
		line-height: 32px;
		margin-bottom: 35px;
		padding-top: 72px;

		@include break-min($large1) {
			@include font-size(3.6);
			letter-spacing: -0.5px;
			line-height: 42px;
			margin-bottom: 0;
			padding: 96px 0 56px;
		}
	}

	.flex {
		justify-content: center;
	}

	.icon-row-txt {
		display: flex;
		margin: 25px auto 0;
		//sass-lint:disable no-vendor-prefixes
		-ms-overflow-style: none;
		//sass-lint:enable no-vendor-prefixes
		overflow-y: hidden;
		padding-left: 0;
		position: relative;
		text-align: center;

		@include break-min($large1) {
			justify-content: center;
			margin: auto;
			overflow-y: unset;
		}

		.card {
			list-style: none;
			margin: 0 0 32px;
			padding: 0;
			position: relative;
			text-indent: 0;

			@include break-min($large1) {
				margin: 0 30px;
			}

			.title {
				> *:first-child {
					@include font-size(2.4);
					color: $blue;
					font-weight: $bold-weight;
					letter-spacing: -0.5px;
					line-height: lh(34, 24);
					margin: 30px 0 15px;

					@include break-min($large1) {
						@include font-size(3.6);
						line-height: 42px;
					}
				}
			}

			p {
				margin: 0;
			}
		}

		.card:not(:last-of-type) {
			@include break-max($large1) {
				&:before {
					content: url("img/delivery-dotted-divider.svg");
					bottom: 0;
					height: 50px;
					left: 59%;
					position: absolute;
					top: 24%;
					width: 100%;
				}
			}
		}

		.card:not(:first-of-type) {
			@include break-min($large1) {
				&:before {
					content: url("img/delivery-dotted-divider-long.svg");
					bottom: 0;
					height: 50px;
					left: 60%;
					position: absolute;
					top: 19%;
					width: 100%;

					@include break-min($large1) {
						left: -63%;
					}
				}
			}
		}

		.icon-wrapper {
			background: $white;
			border-radius: 100%;
			height: 156px;
			margin: auto;
			position: relative;
			width: 156px;
			z-index: 10;

			img {
				bottom: 0;
				height: auto;
				left: 0;
				margin: auto;
				max-height: 78px;
				object-fit: contain;
				position: absolute;
				right: 0;
				top: 0;
				width: 75px;

				&.margin-right {
					margin-right: 5px;
				}
			}
		}

		p {
			@include type-p1();
			@include font-size(1.8);
			font-weight: $regular-weight;
			letter-spacing: -0.5px;
			line-height: 28px;
			margin: 44px auto 0;
			padding: 0 10px;
			width: 230px;

			@include break-min($large1) {
				height: auto;
				margin: 44px auto 0;
			}
		}

		a {
			@include link-underline();
		}

		.slick-list {
			margin-bottom: 10px;
		}

		.slick-dots {
			@extend .slick-slider-dots-grey;
		}
	}
}
