.pwo-modals-form-error {
	animation: 1s animateText forwards;
	display: none;
	position: absolute;
	top: -20px;
	z-index: 100;

	&.display {
		display: block;
	}

	p {
		@include font-size(1.3);
		color: $red;
		font-weight: $medium-weight;
	}
}

@keyframes animateText {
	from {
		left: -10px;
		opacity: 0;
	}

	to {
		left: 4px;
		opacity: 1;
	}
}
