.location-order-now-modal {
	@extend .base-modal;
	border-radius: 8px;
	max-width: 352px;

	@include break-min($large1) {
		max-width: 502px;
	}

	.content {
		padding: 82px 26px 48px;
		text-align: center;

		@include break-min($large1) {
			padding: 62px 35px 72px;
		}

		.header {
			h3 {
				@include font-size(2.4);
				color: $blue;
				font-weight: 700;
				line-height: lh(34, 24);
				text-align: center;
				letter-spacing: -0.5px;
				margin: 0 8px 15px;

				@include break-min($large1) {
					@include font-size(3.6);
					line-height: lh(42, 36);
					margin-bottom: 18px;
				}
			}

			p {
				@include font-size(1.6);
				margin: 0 0 36px 0;
			}
		}

		a {
			@include btn-hover-effect();
			display: flex;
			align-items: center;
			padding: 44px 37px 46px;
			color: $red;
			font-weight: 700;
			font-size: 22px;
			line-height: 28px;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			text-decoration: none;

			@include break-min($large1) {
				max-width: 338px;
				margin: 0 auto;
				padding: 44px 59px 46px;
			}

			img {
				width: 51px;
				margin-right: 27px;
			}

			p {
				position: absolute;
				margin: 0 0 0 65px;

				@include break-min($large1) {
					display: block;
				}
			}
		}

		.pickup-btn {
			margin-bottom: 24px;
		}
	}
}
