.media-resources-list {
	@include grid-margin();
	margin-bottom: 68px;

	@include break-min($large1) {
		margin-bottom: 116px;
	}

	@include break-min($huge4) {
		display: flex;
		justify-content: center;
	}

	.flex-wrapper {
		position: relative;

		.filter-modal {
			margin-bottom: 30px;

			.link-wrapper {
				margin: 0;
				top: 4px;

				@include break-min($large1) {
					top: 12px;
				}
			}
		}
	}

	.title {
		margin: 0 0 32px 0;

		> *:first-child {
			@include type-h2-refresh();
			color: $blue;
			margin: 0;
		}
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		gap: 32px;

		@include break-min($medium4) {
			gap: 24px;
		}
	}

	// item
	li {
		text-align: center;
		grid-column: col-start 1 / span 4;

		@include break-min($medium4) {
			grid-column: auto / span 4;
		}

		.img-container {
			border-radius: 16px;
			box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
			height: 220px;
			margin: 0 0 12px 0;
			overflow: hidden;
			padding: 42px 19px;
			position: relative;
			width: 100%;

			@include break-min($large1) {
				height: 262px;
			}

			img {
				@include absolute-center();
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}

		a {
			@include font-size(1.4);
			font-weight: $bold-weight;
			line-height: lh(48, 14);
			letter-spacing: -0.562px;
		}
	}

	&.no-shadow {
		li {
			box-shadow: none;
		}
	}

	&__base-img {
		margin-bottom: 36px;

		@include break-min($large1) {
			margin-bottom: 52px;
		}

		li {
			.img-container img {
				object-fit: none;
			}
		}
	}
}
