.member-benefits {
	background: $cool-white;
	padding: 48px 24px;
	margin-bottom: 64px;

	@include break-min($medium4) {
		@include grid-margin();
		border-radius: 36px;
		gap: 24px;
		margin-bottom: 96px;
		padding: 64px;
	}

	@include break-min($large1) {
		justify-content: center;
	}

	@include break-min($huge4) {
		width: fit-content;
	}

	.grid-wrapper {
		grid-column: col-start 1 / span 4;
		text-align: center;

		@include break-min($medium4) {
			align-items: center;
			display: flex;
			gap: 64px;
			grid-column: col-start 1 / span 8;
			justify-content: center;
			text-align: left;
		}

		@include break-min($large1) {
			grid-column: col-start 1 / span 12;
		}
	}

	.img-container {
		background: $white;
		border-radius: 100px;
		height: 200px;
		margin: 0 auto 24px;
		position: relative;
		width: 200px;

		@include break-min($medium4) {
			border-radius: 132px;
			height: 264px;
			margin: 0;
			min-width: 264px;
			width: 264px;
		}

		img {
			@include absolute-center();
			margin: auto;
		}
	}

	.content {
		max-width: 746px;

		.label {
			margin: 0 0 8px;

			p {
				@include type-p1-refresh();
				color: $red;
				font-weight: 500;
				margin: 0;
			}
		}

		.headline-container {
			margin: 0 0 24px;

			> :first-child {
				@include type-b2();
				color: $blue;
				margin: 0 0 24px;

				@include break-min($medium4) {
					@include font-size(4.8);
					line-height: lh(58, 64);
					letter-spacing: -1px;
				}
			}

			p {
				@include type-p1-refresh();
				color: $grey;
				margin: 0;
			}
		}

		.btn-container {
			display: flex;
			flex-direction: column;
			padding: 0 25px;
			gap: 16px;

			@include break-min($medium4) {
				padding: 0;
			}

			@include break-min($large1) {
				flex-direction: row;
			}

			a {
				margin: auto;
				max-width: 500px;
				width: 100%;

				@include break-min($medium4) {
					margin: initial;
					min-width: 276px;
					width: auto;
				}

				&.copy-code {
					align-items: center;
					display: flex;
					justify-content: center;

					img {
						bottom: 0;
						position: absolute;
						height: 32px;
						top: 0;
						width: 32px;
						margin: auto;
					}

					span {
						margin-left: 41px;
					}
				}
			}
		}
	}
}
