.operator-sites-sign-in-modal {
	padding: 0;

	.inner {
		padding: 80px 25px;

		@include break-min($large1) {
			padding: 80px 53px;
		}
	}
}
