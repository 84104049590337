.backstage-tour {
	.accordion {
		@extend .delivery-accordion;
		background-color: $cool-white;

		@include break-min($medium4) {
			padding-bottom: 96px;
			padding-top: 96px;
		}
	}
}
