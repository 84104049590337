.msg-position {
	&__right {
		&[data-has-multiple="true"] {
			.classic-callout .message-container,
			.message-container {
				@include break-min($medium4) {
					grid-column: col-start 5 / span 4;
				}

				@include break-min($large4) {
					grid-column: col-start 7 / span 6;
				}

				@include break-min($huge4) {
					grid-column: col-start 4 / span 3;
				}
			}
		}
		&[data-has-multiple="false"] {
			.card-overlay .message-container {
				@include break-min($medium4) {
					grid-column: col-start 5 / span 4;
				}

				@include break-min($large4) {
					grid-column: col-start 8 / span 5;
				}

				@include break-min($huge4) {
					grid-column: col-start 8 / span 4;
				}
			}
		}

		&.overlay-full-callout {
			.message-container {
				@include break-min($medium4) {
					grid-column: col-start 5 / span 4;
				}

				@include break-min($large4) {
					grid-column: col-start 7 / span 6;
					margin-right: 30px;
				}

				@include break-min($huge4) {
					grid-column: col-start 8 / span 6;
				}
			}
		}
	}

	&__center {
		&[data-has-multiple="true"] {
			.card-overlay .message-container {
				@include break-min($huge4) {
					grid-column: col-start 2 / span 4;
					margin-top: 211px;
				}
			}
		}

		.card-overlay .message-container {
			@include break-min($medium4) {
				grid-column: col-start 3 / span 4;
				margin-top: 158px;
			}

			@include break-min($large4) {
				grid-column: col-start 4 / span 6;
				margin-top: 211px;
			}
		}
	}
}
