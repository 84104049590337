// GLOBAL Boxes
// ==========================================================================

.g-card-grid {
	color: $grey;
	background-color: $warm-white;
	padding: 34px 0 96px;

	@include break-min($medium2) {
		padding: 60px 0 80px;
	}

	&__inner {
		h1 {
			@include type-h2();
			color: $vis-blue;
			margin-bottom: 0;
			text-align: left;
			@include break-min($medium2) {
				@include type-h1();
				text-align: center;
			}
		}

		p {
			@include type-p2();
		}
	}

	&__header {
		max-width: 608px;
		text-align: center;
		margin: 0 auto;
		padding: 0 12px 48px;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: -0.5px;

		@include break-min($medium2) {
			font-size: 18px;
			line-height: 1.56;
			padding: 0 12px 64px;
		}
	}

	&__grid {
		@include flexbox();
		@include justify-content(stretch);
		@include align-items(stretch);
		@include flex-wrap(wrap);
		margin: 0 11px;

		@include break-min($medium2) {
			margin: 0 12px;
		}

		& > div {
			padding: 5px;
			width: 100%;

			@include break-min($medium2) {
				@include flex(0 0 33.3333%);
				max-width: 33.3333%;
				padding: 12px;
			}
		}

		&.gridView {
			.g-round-card__wrap {
				@include break-min($large1) {
					flex: 0 0 33%;
					max-width: none;
				}

				.g-round-card__foot {
					@include break-min($large1) {
						display: block;
						margin-bottom: 15px;
					}

					@include break-min(1340px) {
						align-items: flex-start;
						column-gap: 16px;
						display: flex;
						flex: 50%;
						flex-wrap: wrap;
					}
				}

				.btn-round {
					margin-bottom: 16px;

					@include break-min($large1) {
						margin: 0 0 16px;
						width: 100%;
					}

					@include break-min(1340px) {
						width: auto;
					}
				}
			}
		}
	}

	&--overlap {
		padding: 0 0 32px;

		@include break-min($medium2) {
			padding: 0 0 36px;
		}

		.g-card-grid__grid {
			position: relative;
			top: -32px;
			z-index: 100;

			@include break-min($medium2) {
				top: -24px;
			}
		}
	}

	&--2-wide {
		.g-card-grid__grid {
			& > div {
				@include flex(0 0 100%);
				max-width: 100%;
				padding: 5px;

				@include break-min($medium2) {
					@include flex(0 0 50%);
					max-width: 50%;
					padding: 12px;
				}
			}

			.g-round-card__wrap--fullscreen {
				@include break-min($medium2) {
					@include flex(0 0 100%);
					max-width: 100%;
					padding: 12px;
				}
			}
		}
	}

	&--locations {
		padding-top: 0;

		.g-card-grid__grid {
			margin: 0 24px;

			@include break-min($large1) {
				margin: 0 12px;
			}

			& > div {
				@include break-max($medium2) {
					margin-bottom: 30px;
				}
			}
		}

		.g-round-card__wrap {
			@include break-min($large1) {
				flex: 50%;
				max-width: 50%;
			}
		}

		.g-card-grid__header {
			padding-bottom: 0;
		}

		.g-card-grid__inner {
			h1 {
				@include font-size(4.8);
				font-weight: $bold-weight;
				letter-spacing: -1px;
				line-height: lh(54, 28);
				margin: 0;
				padding: 64px 61px;
				text-align: center;
			}
		}
	}
}
