.layout-footer-links-simple {
	margin: 0 0 8px 0;

	@include break-min($large1) {
		margin: 0 36px 20px 0;
	}

	ul {
		@include font-size(1.1);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 0;

		@include break-min($large1) {
			justify-content: flex-end;
			margin-bottom: 5px;
		}

		li:not(:last-child) {
			&:after {
				color: $rolling-stone;
				content: "|";
				padding: 0 5px;
			}
		}
	}

	.trademark {
		text-align: center;

		@include break-min($large1) {
			text-align: right;
		}

		p {
			@include font-size(1.1);
			color: $rolling-stone;
			margin: 0;
		}
	}
}
