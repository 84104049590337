.nav-toggle {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: 0;
	max-height: 64px;
	width: 64px;
	z-index: 300;

	&.left-align {
		left: 18px;
		width: 0;
	}

	.icon-nav-toggle-off,
	.icon-close {
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
		border: 0;

		&::before {
			content: none;
		}

		// burger
		&::after {
			content: "";
			background-image: url("img/icon_burger.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 24px;
			height: 24px;
			transition: transform 0.3s ease;
		}

		&:focus {
			outline: 0;
		}
	}

	.icon-close {
		// close
		&::after {
			background-image: url("img/icon_close.svg");
			width: 16px;
			height: 16px;
		}
	}

	@include break-min($large1) {
		pointer-events: none;
		transition: opacity 0.3s ease;
		width: 100%;
		z-index: 10;
		max-width: 410px;
		background-color: $white;
		overflow: hidden;
		opacity: 0;
		padding: 0;
		border: 0;
		height: 0;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: 0 auto;
		visibility: hidden;

		.icon-nav-toggle-off {
			border: 0;
			color: transparent;
			display: block;
			height: 20px;
			margin: auto;
			width: 20px;
			text-align: center;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;

			&::after {
				width: 100%;
				height: 100%;
			}

			&:focus {
				outline: 0;
			}
		}

		&.active {
			visibility: visible;
			pointer-events: initial;
			height: auto;
			opacity: 1;

			a {
				color: $red;
				transition: all 0.5s ease;
			}
		}
	}

	span {
		@include visuallyhidden;
	}
}
