.lll-overlay-question-text {
	background-color: $white;
	border-radius: 15px;
	margin: 0 auto;
	padding: 20px 30px 10px 30px;
	position: relative;
	text-align: center;
	width: $col5;

	@media only screen and (max-width: $mobile) {
		width: $col10;

		iframe {
			border: 0;
			width: $col12;
		}
	}

	.header-text {
		color: $blue;
		font-weight: 600;
		margin-top: 50px;

		@media only screen and (min-width: $lrg-tablet) {
			margin-top: 30px;
		}
	}

	.mfp-close {
		color: $red;
	}

	p {
		@include font-size(1.6);
		line-height: 24px;
		margin-bottom: 50px;

		@media only screen and (min-width: $lrg-tablet) {
			margin-bottom: 20px;
		}
	}

	.buttons-container {
		@media only screen and (min-width: $lrg-tablet) {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
		}

		a {
			margin: 0 auto 20px;
			text-align: center;
			width: $col10;

			@media only screen and (min-width: $lrg-tablet) {
				margin: 0 10px 25px 0;
				width: auto;
			}
		}

		.white-color {
			@extend .btn-secondary;
		}

		// buttons not universal - grrrr. Thus, individually styled till we get
		// time to universalize things.
		.cancel-btn {
			@extend .btn-primary;
		}
	}
}
