.executive-bio {
	main {
		background-color: $bkgrey;
		padding-top: 80px;
		max-width: none;

		@media only screen and (max-width: $mobile) {
			display: block;
			padding-top: 40px;
		}
	}

	.bio-img {
		height: auto;
		width: 100%;
	}

	.bio-wrapper {
		margin: 0 auto;
		max-width: 1380px;
		padding-bottom: 110px;
		width: $col12;

		@media only screen and (max-width: $mobile) {
			display: block;
			padding-bottom: 0;
		}
	}

	article {
		max-width: $col10;

		@media only screen and (max-width: $mobile) {
			max-width: none;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 0 100px 10px 50px;

			@media only screen and (max-width: $mobile) {
				margin: 0 0 20px 0;
			}
		}

		h1 {
			@media only screen and (max-width: $mobile) {
				@include font-size(3.6);
				line-height: 140%;
				letter-spacing: -0.5px;
			}
		}

		h2 {
			@include font-size(2.4);
			font-weight: 800;
			letter-spacing: -0.5px;
			line-height: 30px;
			margin-top: 75px;
		}

		.bio-lead {
			@include font-size(2.2);
			color: $blue;
			font-family: $primary;
			line-height: 34px;
			margin-bottom: 60px;
		}

		figure {
			margin-bottom: 70px;
			margin-top: 70px;

			&.right,
			&.left {
				margin-top: 0;
				margin-bottom: 25px;

				@media only screen and (max-width: $mobile) {
					figcaption {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}

		figcaption {
			margin: 20px 0 0;

			@media only screen and (max-width: $mobile) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.content {
			max-width: none;
			margin-left: $col-margin;
			width: $col11;

			@media only screen and (max-width: $mobile) {
				margin-left: 0;
				width: auto;
			}
		}
	}

	aside {
		max-width: $col3;

		@media only screen and (max-width: $mobile) {
			background-color: $white;
			margin: 0 -15px;
			max-width: none;
			padding: 20px;

			.module {
				text-align: center;

				.social-icons {
					text-align: center;
				}
			}
		}

		p {
			margin: 20px 0;

			@media only screen and (max-width: $mobile) {
				margin: 0 0 20px 0;
			}
		}

		.cs {
			line-height: 28px;

			@media only screen and (max-width: $mobile) {
				img {
					display: block;
					margin: 20px auto;
				}
			}
		}
	}

	.top-hero {
		display: none;
		overflow: hidden;

		&.bio {
			position: relative;

			.wrapper {
				@media only screen and (max-width: $mobile) {
					padding: 0;
				}
			}

			.blurb {
				margin-top: 100px;
				position: absolute;
				text-align: center;
				width: 100%;

				@media only screen and (max-width: $mobile) {
					background-color: $blue;
					margin: 0;
					padding: 30px 15px;
					position: relative;
					width: auto;

					img {
						max-width: $col2;
					}
				}
			}

			.saying {
				@include font-size(4.8);
				letter-spacing: -0.5px;
				line-height: 130%;
				margin-bottom: 40px;

				@media only screen and (max-width: $mobile) {
					@include font-size(2.2);
					line-height: 34px;
					margin-bottom: 10px;
				}
			}

			.tagline {
				@include font-size(1);
				font-family: $primary;
				letter-spacing: 2px;
				line-height: 20px;
				margin: 15px 0;
				text-transform: uppercase;
			}

			@media only screen and (max-width: $mobile) {
				picture {
					img {
						width: 100%;
					}
				}
			}
		}
	}

	.next-section {
		@include font-size(1);
		letter-spacing: 2px;
		line-height: 20px;

		.signature {
			max-height: none;
			height: auto;
			max-width: $col2;
		}

		.next {
			margin: 20px 0;
		}
	}
}

.board-officers {
	.top-hero {
		@include align-items(center);
		display: flex;
		height: 333px;

		h1 {
			@media only screen and (max-width: $mobile) {
				line-height: 1;
			}
		}
	}

	.intro {
		@include font-size(1.6);
		font-family: $primary;
		line-height: 28px;
		padding: 0;
		margin-bottom: 0;
		width: $col7;

		@media only screen and (max-width: $mobile) {
			padding: 25px 30px 50px;
			text-align: left;
			width: auto;
		}

		h2,
		.blurb {
			font-family: $primary;
		}

		h2 {
			@include font-size(4.8);
			letter-spacing: -0.5px;
			line-height: 130%;
			margin: 0 0 26px 0;
		}

		.blurb {
			@include font-size(2.2);
			color: $blue;
			line-height: 34px;
		}
	}

	section {
		padding: 90px 0 35px;

		&:nth-child(even) {
			background-color: $bkgrey;
		}

		@media only screen and (max-width: $mobile) {
			padding: 27px 0 7px 0;
		}
	}
}

.exec-group {
	padding: 20px 30px 50px;

	@media only screen and (max-width: $mobile) {
		padding: 0;
	}
}

.execs {
	padding: 50px 30px 10px;

	@media only screen and (max-width: $mobile) {
		padding: 0 30px;
	}

	.flex {
		@include justify-content(flex-start);
		max-width: 1380px;
		margin: 0 auto;
		width: 100%;

		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}
}

.exec {
	@include font-size(1.2);
	font-family: $primary;
	line-height: 20px;
	margin-left: 2 * $col-margin;
	width: $col4;

	&:first-child {
		margin-left: 0;
	}

	@media only screen and (max-width: $mobile) {
		margin: 0 0 50px 0;
		max-width: none;
		width: auto;
	}

	h3,
	a {
		font-family: $primary;
	}

	h3 {
		@include font-size(2.8);
		letter-spacing: 0;
		line-height: 150%;
		margin: 20px 0 10px;
	}

	a {
		@include font-size(1.6);
		@include link-underline();
		line-height: 28px;
	}

	p {
		margin: 0 0 10px 0;
	}

	img {
		width: 100%;
	}
}
