$max-width: none;

.covid-sticky-nav-dropdown-variation-body {
	@include break-max($large1) {
		top: 63px;
	}

	@include break-min($large1) {
		background: transparent;
		max-width: $max-width;
		padding: 0 32px;
	}

	@include break-min($huge1) {
		padding: 0 142px;
	}

	&.desktopNotSticky {
		margin-top: 23px;

		.dropdown {
			.link {
				&:nth-child(4) {
					&:before {
						display: none;
					}
				}
			}
		}
	}

	&.desktopSticky {
		max-width: none;
		padding: 0;
		width: 100%;

		.dropdown {
			max-width: none;

			.link.active {
				display: block;
			}
		}
	}

	.dropdown {
		@include break-min($large1) {
			border-top: 1px solid $faint-grey;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			max-width: $max-width;
		}

		.link.active {
			@include break-min($large1) {
				display: none;
			}
		}
	}
}
