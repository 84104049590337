.scholarship {
	.g-boxes {
		&__header {
			padding: 0 16px 25px;

			@include break-min($large1) {
				padding: 0 24px 34px;
			}
		}

		&__box {
			border: none;

			&:not(:first-child) {
				display: block !important;
				opacity: 1 !important;
			}

			&-icon {
				img {
					position: relative;
					max-width: 55px;
				}
			}

			h4,
			&-title {
				color: $blue;
			}

			p {
				padding-top: 20px;
			}

			&::after {
				background-color: transparent;
			}

			&::before {
				background-color: transparent;
			}

			&.center-content {
				text-align: center;
			}

			&.right-content {
				text-align: end;
			}
		}
	}
}
