.listicle-module {
	display: flex;
	flex-direction: column;

	@include break-min($large1) {
		align-items: center;
		gap: 97px;
		flex-direction: row;
	}

	.text-module {
		display: flex;
		flex-direction: column;
		gap: 16px;
		order: 2;
		padding-top: 64px;

		@include break-min($large1) {
			flex: 1;
		}

		.header {
			> *:first-child {
				@include font-size(2.4);
				color: $blue;
				font-weight: $bold-weight;
				line-height: lh(32, 24);
				letter-spacing: -0.01em;
				margin: 0;

				@include break-min($large1) {
					@include font-size(3.6);
					font-weight: $bold-weight;
					line-height: lh(42, 36);
					letter-spacing: -0.5px;
				}
			}
		}

		p {
			@include font-size(1.8);
			line-height: lh(28, 18);
			letter-spacing: -0.5px;
			margin: 0;
		}

		.btn {
			width: fit-content;
		}
	}

	.img-module {
		border-radius: 32px;
		height: 213px;
		margin: 0 0 24px;
		overflow: hidden;
		order: 1;
		position: relative;
		width: 100%;

		@include break-min($large1) {
			height: 416px;
			flex: 1;
			margin: 0;
		}

		img {
			@include absolute-center();
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	&[data-child-count="1"] {
		max-width: 636px;
		margin: 0 auto;

		.text-module {
			@include break-min($large1) {
				gap: 34px;
			}

			.btn {
				display: none;
			}
		}
	}
}
