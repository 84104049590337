.customer-support-category-wrapper {
	margin: 64px 24px 0;

	@include break-min($large1) {
		margin-top: 104px;
	}

	> *:first-child {
		@include font-size(2.8);
		color: $blue;
		font-weight: $bold-weight;
		letter-spacing: -1px;
		line-height: lh(34, 28);
		margin: 0 0 40px 0;
		text-align: center;

		@include break-min($large1) {
			@include font-size(4.8);
			line-height: lh(58, 48);
			margin: 0 0 64px 0;
		}
	}
}
