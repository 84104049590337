.listicle-carousel {
	@include grid-margin();
	color: $blue;
	position: relative;
	text-align: center;
	justify-content: center;
	margin: 0;

	@include break-min($large1) {
		gap: 24px;
	}

	.static-img {
		position: relative;
		height: 260px;
		width: 100%;

		@include break-min($large1) {
			border-radius: 32px;
			flex: 1;
			height: 536px;
			margin: 0 -35px 0 0;
			overflow: hidden;
		}

		img {
			@include absolute-center();
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	.item {
		@include break-min($large1) {
			align-items: center;
			display: flex !important;
			flex-direction: row;
			justify-content: center;
			text-align: left;
		}

		.img-container {
			position: relative;
			height: 260px;
			width: 100%;

			@include break-min($large1) {
				border-radius: 32px;
				flex: 1;
				height: 536px;
				max-width: 641px;
				overflow: hidden;
			}

			img {
				@include absolute-center();
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}
	}

	.content {
		background: $cool-white;
		border-radius: 16px;
		padding: 82px 45px 32px;
		margin: -19px 24px 0;
		overflow: hidden;
		position: relative;
		z-index: 100;

		@include break-min($large1) {
			border-radius: 32px;
			flex: 1;
			height: fit-content;
			margin: 0 0 0 -35px;
			max-width: 641px;
			padding: 51px 100px 47px;
		}

		.header,
		.text {
			p {
				@include font-size(1.8);
				line-height: lh(28, 18);
				letter-spacing: -0.5px;
			}
		}

		.header p {
			@include break-min($large1) {
				@include font-size(3.6);
				line-height: lh(42, 36);
				letter-spacing: -0.5px;
			}
		}
	}

	.listicle-items {
		position: relative;
		grid-column: col-start 1 / span 4;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 12;
		}
	}

	.navigation-arrow-handlers {
		align-items: center;
		position: absolute;
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: fit-content;
		padding: 0 35px;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		z-index: 100;

		a {
			cursor: pointer;
			display: block;
			margin: 0 -25px;
			height: 17px;
			width: 17px;

			@include break-min($large1) {
				height: 39px;
				width: 39px;
				margin: 0 -10px;
			}
		}
	}

	.slick-dots {
		@extend .slick-slider-dots-grey;
		height: fit-content;
		top: 260px;

		@include break-min($large1) {
			display: none !important;
		}

		li.slick-active {
			button {
				background: $red;

				&:before {
					color: $red;
				}
			}
		}
	}

	&.static-img-view {
		align-items: center;
		gap: 0;
		width: 100%;

		@include break-min($large1) {
			display: flex;
		}

		.static-img {
			flex-grow: 1;
		}

		.listicle-items {
			width: 100%;
		}

		.content {
			flex-grow: 1;

			@include break-min($large1) {
				margin: 0;
			}
		}
	}

	&.disable-prev-arrow {
		[data-prev-arrow] {
			cursor: not-allowed;
			opacity: 0.1;
			pointer-events: none;
		}
	}

	&.disable-next-arrow {
		[data-next-arrow] {
			cursor: not-allowed;
			opacity: 0.1;
			pointer-events: none;
		}
	}
}
