.hundred {
	.details {
		display: flex;

		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}

	.opening {
		border-bottom: 2px solid $faint-grey;
		padding-top: 25px;

		p {
			margin: 0 0 15px 0;
		}

		.img {
			margin-right: 2 * $col-margin;
			width: $col4;

			@media only screen and (max-width: $mobile) {
				margin-bottom: 25px;
				width: auto;
			}

			img {
				width: 100%;
			}
		}

		.opening-content {
			@include font-size(1.8);
			margin-left: 0;
			padding-bottom: 15px;
			width: $col7;

			@media only screen and (max-width: $mobile) {
				width: auto;
			}
		}

		h2 {
			@include font-size(2.4);
			font-weight: 800;
			letter-spacing: -0.5px;
			line-height: 30px;
			margin: 0 0 5px 0;
		}

		.date {
			@include font-size(1.5);
		}
	}

	.eligible {
		padding: 5px 0;

		&:hover {
			background-color: $bkgrey;
			cursor: pointer;
		}

		.toggle {
			color: $red;
			display: flex;

			span {
				width: $col7;

				@media only screen and (max-width: $mobile) {
					padding-top: 5px;
					margin: 0 $col-margin;
					width: $col12;
				}
			}

			&:before {
				content: " ";
				margin-right: 2 * $col-margin;
				width: $col4;

				@media only screen and (max-width: $mobile) {
					display: none;
				}
			}

			&:after {
				@include font-size(1.8);
				content: "\e902";
				font-family: $icon;
				float: right;
				margin: 3px;

				@media only screen and (max-width: $mobile) {
					margin-right: 20px;
				}
			}
		}

		&.active {
			background-color: $bkgrey;

			&:hover {
				cursor: default;
			}

			.zip-listing {
				@include unhidden();
				margin: 0 auto;
				padding: 20px 0;
				width: $col11;
			}

			.toggle {
				&:after {
					@include transform(rotate(180deg));
					margin-top: 8px;
				}
			}
		}
	}
}

.zip-listing {
	@include visuallyhidden();
	margin: 0 auto;
	padding: 20px 0;
	width: $col11;
}
