.restaurant-nav-callout {
	overflow: hidden;
	margin: 0 0 63px 0;

	@include break-min($large1) {
		@include desktop-grid();
		grid-template-rows: 1fr 1fr;
		gap: 24px;
	}

	&.white-bg {
		.nav-callout-card {
			.content {
				background: $white;
			}
		}
	}

	.nav-callout-card {
		border-radius: 16px;
		margin: 0 0 40px 0;
		overflow: hidden;

		@include break-min($large1) {
			display: flex;
		}

		.img-container {
			@include swap-img();
			height: 231px;
			position: relative;
			overflow: hidden;

			@include break-min($large1) {
				flex: 1;
				height: auto;
				order: 2;
			}

			img {
				@include absolute-center();
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}

		.content {
			background: $cool-white;
			padding: 32px;

			@include break-min($large1) {
				flex: 1;
			}

			.title {
				margin: 0 0 8px 0;

				> *:first-child {
					@include font-size(2.4);
					color: $blue;
					font-weight: $bold-weight;
					line-height: lh(32, 24);
					letter-spacing: -0.01em;
					margin: 0;

					@include break-min($large1) {
						@include font-size(3.6);
						font-weight: $bold-weight;
						line-height: lh(42, 36);
						letter-spacing: -0.5px;
					}
				}
			}

			.paragraph {
				margin: 0 0 16px 0;

				@include break-min($large1) {
					margin: 0 0 24px 0;
				}

				p {
					@include font-size(1.6);
					color: $blue;
					line-height: lh(25, 16);
					margin: 0;
				}
			}

			.btn-container {
				.btn-round {
					width: fit-content;
				}
			}

			.optional-blurb {
				margin: 8px 0 0;

				p {
					@include font-size(1.2);
					color: $blue;
					line-height: lh(20px, 12px);
					letter-spacing: -0.5px;
					margin: 0;
				}
			}
		}
	}

	&[data-child-count="1"] {
		.nav-callout-card {
			@include break-min($large1) {
				grid-column: auto/span 12;
				margin: 0;
			}
		}
	}

	&[data-child-count="2"] {
		@include break-min($large1) {
			grid-template-rows: 1fr;
		}

		.nav-callout-card {
			@include break-min($large1) {
				grid-column: auto/span 6;
				margin: 0;
			}
		}
	}

	&[data-child-count="3"] {
		@include break-min($large1) {
			grid-template-columns: 2fr 0fr 1fr;
		}
		.nav-callout-card {
			@include break-min($large1) {
				grid-column: auto/span 6;
				margin: 0;
			}
		}
	}

	&[data-child-count="4"] {
		@include break-min($large1) {
			margin: 0 auto 63px;
		}

		.nav-callout-card {
			@include break-min($large1) {
				grid-column: auto/span 6;
				margin: 0;
			}
		}
	}
}
