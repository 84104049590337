.third-party-modal {
	@extend .base-modal;
	border-radius: 8px;
	padding: 30px 24px 48px;
	text-align: center;
	max-width: 650px;

	.content {
		> *:first-child {
			@include font-size(2.4);
			color: $blue;
			font-weight: bold;
			letter-spacing: -0.5px;
			margin: 0 0 8px;

			@include break-min($large1) {
				@include font-size(4.8);
				letter-spacing: -1px;
				margin: 0 0 20px;
			}
		}

		p {
			&:first-of-type {
				@include font-size(1.6);
				line-height: lh(25, 16);
				margin: 0 0 16px;

				@include break-min($large1) {
					margin: 0 0 24px;
				}
			}

			&:nth-of-type(2) {
				@include font-size(1.1);
				line-height: lh(13, 11);
				margin: 0 0 24px;

				@include break-min($large1) {
					margin: 0 0 24px;
				}
			}
		}
	}

	.btn-round {
		@include break-min($large1) {
			width: 144px;
		}

		&--red {
			margin: 0 0 12px;

			@include break-min($large1) {
				margin: 0 16px 0 0;
			}
		}
	}
}
