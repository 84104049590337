.media-contacts {
	@include grid-margin();
	gap: 22px;
	margin: 0 24px 0;
	padding: 0;
	justify-content: center;

	li {
		background: $cool-white;
		border-radius: 16px;
		list-style-type: none;
		height: 115px;
		padding: 0 22px 0 32px;
		position: relative;
		grid-column: auto / span 4;

		@include break-min($large4) {
			grid-column: auto / span 6;
			width: 100%;
		}

		.resource-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
		}

		.label-text {
			align-items: center;
			display: flex;
			margin: 0 0 8px 0;

			img {
				margin: 0 8px 0 0;
			}

			p {
				@include type-e1-refresh();
				color: $red;
				margin: 0;
			}
		}

		.title-text {
			> :first-child {
				@include type-h2-refresh();
				color: $blue;
			}
		}

		.arrow {
			position: absolute;
			bottom: 32px;
			right: 22px;
		}
	}
}
