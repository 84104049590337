$counter: 1;

.daypart-animated-headline {
	align-items: center;

	&.animated {
		transition: all 0.3s ease;
		will-change: opacity;
		opacity: 0;
		animation: FadeFromBottom 0.3s ease;
		animation-fill-mode: forwards;
	}

	&-text {
		line-height: 1;
		margin-left: 11px;
		@include type-h4;
	}

	&.mobile {
		display: flex;
		justify-content: center;

		@include break-min($large1) {
			display: none;
		}
	}

	&.desktop {
		display: none;

		@include break-min($large1) {
			display: flex;
		}
	}
}
