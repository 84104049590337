.restaurant-details-hours {
	margin: 0 0 48px 0;

	div {
		justify-content: space-between;
	}

	p {
		@include font-size(1.6);
		color: $grey;
		line-height: lh(25, 16);
	}
}
