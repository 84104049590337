.rewards-section {
	background: $warm-white;
	padding: 64px 24px;
	text-align: center;

	@include break-min($large1) {
		align-items: center;
		padding: 110px 0;
		text-align: left;
	}

	&.no-img {
		@extend .rewards-section-no-img;
	}

	.img-wrapper {
		align-items: center;
		background: white;
		border-radius: 50%;
		display: flex;
		height: 150px;
		margin: 0 auto 24px;
		padding: 30px;
		width: 150px;

		@include break-min($large1) {
			height: 218px;
			margin: 0;
			padding: 40px;
			width: 218px;
		}

		img {
			@include absolute-center();
			margin: auto;
			position: absolute;
		}
	}

	.content {
		padding: 0;

		@include break-min($large1) {
			margin-left: 60px;
			max-width: 560px;
		}

		.headline {
			margin-bottom: 16px;

			p {
				@include font-size(1.8);
				color: $grey;
				font-weight: $regular-weight;
				line-height: lh(28, 18);

				@include break-min($large1) {
					@include font-size(1.4);
					font-weight: $bold-weight;
				}
			}
		}

		h4 {
			@include font-size(3.6);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -0.8px;
			line-height: lh(46, 36);
			margin-bottom: 40px;

			@include break-min($large1) {
				@include font-size(4.8);
				letter-spacing: -1px;
				line-height: lh(58, 48);
				margin-bottom: 30px;
			}
		}

		.btn-round {
			margin-top: 0;
			min-width: 144px;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
