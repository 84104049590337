.lto-hero {
	@include grid-margin();
	border-radius: 32px;
	display: none;
	column-gap: 24px;
	grid-auto-rows: 1fr;
	grid-row: 2;
	justify-content: center;
	margin-bottom: 64px;
	margin-top: 64px;
	overflow: hidden;
	position: relative;

	@include break-max($large1) {
		display: block;
		grid-auto-rows: auto;
	}

	.ribbon {
		background: $blue;
		border-radius: 4px;
		overflow: hidden;
		margin: 0 0 16px 0;
		padding: 0 16px;
		width: fit-content;

		p {
			@include type-p3-refresh();
			color: $white;
			margin: 0;
		}
	}

	.message-container {
		background: $cool-white;
		grid-column: col-start 1 / span 4;
		grid-row: 2;
		height: fit-content;
		padding: 32px;

		@include break-min($medium4) {
			align-self: center;
			display: flex;
			flex-direction: column;
			grid-row: 1;
			height: 100%;
			padding: 72px;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 6;
			padding: 72px;
		}

		@include break-min(1728px) {
			border-bottom-left-radius: 32px;
			border-top-left-radius: 32px;
			overflow: hidden;
		}
	}

	.title-text {
		> *:first-child {
			@include type-h2-refresh();
			color: $blue;
			margin: 0 0 16px;
		}
	}

	.body-text {
		p {
			@include type-p1-refresh();
			margin: 0 0 32px;
		}
	}

	.img-container {
		grid-column: col-start 1 / span 4;
		grid-row: 1;
		height: 300px;
		position: relative;
		margin-left: -24px;

		@include break-min($medium4) {
			grid-column: col-start 5 / span 8;
			max-height: none;
			height: 100%;
			padding: 0;
		}

		@include break-min($large4) {
			grid-column: col-start 7 / span 6;
		}

		@include break-min(1728px) {
			border-bottom-right-radius: 32px;
			border-top-right-radius: 32px;
			overflow: hidden;
		}

		img {
			@include absolute-center();
			height: 100%;
			position: absolute;
			object-fit: cover;
			width: 100%;
		}
	}

	.btn-container {
		a:first-of-type {
			margin: 0 16px 0 0;
		}
	}

	&.cfa-one {
		border-radius: 0;
		margin: 36px 0 0;

		.title-text {
			> *:first-child {
				@include type-b1();
				color: $red;
				margin: 0 0 36px;
			}
		}

		.body-text {
			p {
				margin: 0 0 24px;
			}
		}

		.btn-container {
			a:first-of-type {
				margin: 0 0 36px;
				width: 100%;

				@include break-min($medium4) {
					margin: 0;
					min-width: 240px;
					width: auto;
				}
			}
		}

		.message-container {
			background: transparent;
			grid-row: 1;
			padding: 0 24px 36px;

			@include break-min($medium4) {
				padding: 59px 53px 59px 72px;
			}
		}

		.img-container {
			grid-row: 2;
			height: 290px;

			@include break-min($medium4) {
				grid-row: 1;
				height: 100%;
			}

			@include break-min(1728px) {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}
}
