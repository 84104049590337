.community-tab {
	background: $cool-white;
	border-top-left-radius: 40px;
	border-top-right-radius: 40px;
	padding: 0 0 64px;

	.gridView {
		@include desktop-grid-max-width();
		@include grid-padding();
	}

	.restaurant-nav-callout {
		margin: 0;

		@include break-min($large1) {
			margin: 0;
		}
	}

	.nav-callout-card {
		.content {
			// Justifcation: must overwrite from restaurnt-nav styles
			background: $white !important;

			.paragraph {
				color: $grey;
			}
		}
	}
}
