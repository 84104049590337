.g-inline-form {
	padding: 55px 24px 16px;
	background: $cream;
	text-align: center;

	@include break-min($small4) {
		padding: 55px 24px 75px;
	}

	&__inner {
		max-width: 816px;
		margin: 0 auto;
	}

	&__h3 {
		@include type-h3();
		margin: 0 auto 22px;
		color: $vis-blue;
		max-width: 280px;

		@include break-min($small4) {
			margin: 0 0 12px;
			max-width: none;
		}
	}

	&__p {
		@include type-p2();
		margin: 0 auto;
		max-width: 336px;

		@include break-min($small4) {
			max-width: none;
		}
	}

	&__success-msg {
		margin: 58px auto 22px;
		max-width: 280px;
		@include p1();
		font-weight: $bold-weight;

		@include break-min($small4) {
			margin: 54px auto 34px;
			max-width: none;
			letter-spacing: -0.5px;
			line-height: lh(28px, 22px);
			@include font-size(2.2);
		}
	}

	input[type="submit"],
	button[type="submit"] {
		margin-top: 20px;

		@include break-min($small4) {
			margin-top: 28px;
		}
	}

	.g-form {
		@include break-min($small4) {
			padding: 3px 0 0;
		}
	}

	.g-form__group--input {
		margin: 25px 0 0;
	}

	.g-checkbox__group {
		margin: 30px 0 15px;
	}

	&--success {
		padding: 38px 16px 78px;

		@include break-min($small4) {
			padding: 88px 24px 140px;
		}
	}

	&--side-by-side {
		text-align: left;

		.g-inline-form {
			&__h3 {
				@include type-h5();
				margin: 0 0 22px;
			}

			&__p {
				max-width: none;
				margin: 0;
			}
		}

		.g-form {
			//@include flex(0 0 50%);
			//max-width: 50%;
			padding: 24px 0 0;
		}

		@include break-min($medium2) {
			.g-inline-form {
				&__inner {
					max-width: none;
					@include flexbox();
					@include flex-direction(row);
					@include justify-content(space-between);
				}

				&__head {
					padding: 0 8.333%;
					@include flex(0 0 50%);
					max-width: 50%;
				}

				&__h3 {
					max-width: 400px;
				}

				&__p {
					margin: 0;
					max-width: 400px;
				}
			}

			.g-form {
				@include flex(0 0 50%);
				max-width: 50%;
				padding: 0 8.333%;
			}
		}
	}

	.g-recaptcha {
		margin-bottom: 15px;
	}

	&--white-bg {
		background: $white;
	}
}
