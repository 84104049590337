.location-menu {
	padding-bottom: 64px;

	@include break-min($large1) {
		padding-bottom: 100px;
		position: relative;
	}

	.menu-group {
		position: relative;

		h2 {
			@include font-size(2);
			border-bottom: 0;
			font-weight: 500;
			letter-spacing: -0.5px;
			line-height: lh(36, 20);
			margin-left: 55px;
		}

		#landing {
			display: none;
		}

		.img-container {
			position: relative;
		}

		.title-container {
			display: none;
			position: relative;
		}

		.menu-icon {
			bottom: 0;
			left: 10px;
			margin: auto;
			position: absolute;
			top: 0;
			width: 50px;

			@include break-min($large1) {
				display: none;
			}
		}

		.co-item {
			.content {
				@include break-min($large1) {
					margin-top: 50px;
				}
			}
		}
	}
}
