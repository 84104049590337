// sass-lint:disable max-file-line-count

ul {
	&.lll-cards {
		@include flex-rules();
		justify-content: center;
		text-align: center;

		li {
			box-sizing: border-box;
			flex: 0 0 auto;
			flex-basis: $col12;
			max-width: $col12;
			position: relative;

			@include breakpoint(tablet) {
				flex-basis: $col6;
				max-width: $col6;
				padding-left: 20px;

				&:nth-child(2n + 1) {
					padding: 0;
				}
			}

			@include breakpoint(desktop) {
				flex-basis: $col3;
				max-width: $col3;

				&:nth-child(2n + 1) {
					padding-left: 20px;
				}
			}
		}

		&__menu {
			justify-content: center;
			text-align: center;

			+ .btn {
				margin: 33px auto 0;
				@extend .btn-primary;
				display: block;
				width: fit-content;

				@include breakpoint(tablet) {
					margin: 40px auto 0;
				}
			}

			li {
				cursor: pointer;
				flex-basis: $col6;
				margin: 0 0 24px;
				max-width: $col6;
				padding-left: 10px;

				&:nth-child(2n + 1) {
					padding: 0 10px 0 0;
				}

				@include breakpoint(desktop) {
					flex-basis: $col3;
					max-width: $col3;

					&:nth-child(2n + 1) {
						padding-left: 20px;
					}

					&:nth-child(4n + 1) {
						padding: 0;
					}
				}

				@include breakpoint(tablet) {
					padding-left: 24px;
				}

				p {
					font-size: 18px;
				}

				.wrapper-div {
					@include column-rules();
					@include focus($red);
					background: $white;
					border-radius: 8px;
					box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
					display: flex;
					flex-direction: column;
					height: 100%;
					justify-content: flex-end;
					padding: 16px;
					position: relative;
					text-align: center;
					transition: border 0.5s ease-in-out, transform 0.5s ease-in-out;

					@include breakpoint(tablet) {
						padding: 16px 35px 20px;
					}

					.image-container {
						height: 200px;
					}

					img {
						height: 200px;
						object-fit: contain;

						+ p {
							margin: 24px auto 0;
							left: initial;
							position: relative;
							top: initial;
							transform: translate(0, 0);

							@include breakpoint(tablet) {
								margin: 44px auto 0;
							}
						}
					}

					&:hover,
					&:focus {
						transform: translateY(-4px);
					}
				}
			}
		}

		&__category {
			li {
				@include column-rules();
				//-ms-flex-preferred-size: $col6;
				flex-basis: $col6;
				margin: 0 0 16px;
				max-width: $col6;
				padding-left: 10px;
				padding-right: 10px;

				a {
					@include focus($red);
					display: inline-block;
					height: 100%;
					transform: translateY(-4px);
					width: 100%;
				}

				@include breakpoint(tablet) {
					margin: 0 0 24px;
					padding-left: 12px;
					padding-right: 12px;

					&:nth-child(2n + 1) {
						padding-left: 12px;
						padding-right: 12px;
					}
				}

				@include breakpoint(desktop) {
					flex-basis: $col3;
					margin: 0;
					max-width: $col3;
				}
			}
		}
	}

	&.lll-cards__reasons {
		li {
			padding-left: 0;

			&:nth-child(2n + 1) {
				padding: 0;
			}

			@include breakpoint(tablet) {
				padding-left: 20px;
			}

			@include breakpoint(desktop) {
				flex-basis: 25%;
				margin: 0 0 24px 12.5%;
				max-width: 25%;
				padding-left: 0;
			}

			button {
				align-items: center;
				box-sizing: border-box;
				justify-content: center;
				position: relative;
				height: 100%;
			}
		}
	}
}

.lll-cards {
	margin: 40px auto 0;
	padding: 0 3px;

	@include break-min($small3) {
		padding: 0 16px;
	}

	@include breakpoint(desktop) {
		padding: 0 24px;
	}

	&--text {
		margin: 0 0 16px;
		text-align: center;
		width: 100%;

		@include breakpoint(desktop) {
			margin: 0 0 24px;
		}
	}

	&--full {
		margin: 0;
		padding: 0;
		width: 100%;
	}

	&__reasons {
		flex-basis: $col12;
		max-width: $col12;

		@include breakpoint(desktop) {
			flex-basis: $col9;
			max-width: $col9;
		}

		li {
			flex-basis: $col12;
			margin: 0 0 16px;
			max-width: $col12;
			width: 100%;

			@include breakpoint(tablet) {
				flex-basis: $col6;
				max-width: $col6;
			}

			@include breakpoint(desktop) {
				flex-basis: $col5;
				margin: 0 0 24px calc(100% * (1 / 12));
				max-width: $col12;

				&:first-of-type {
					margin: 0 0 24px 0;
				}
			}
		}

		button {
			max-width: 100%;
			width: 100%;
		}

		+ .btn {
			margin: 33px auto 0;
			@extend .btn-primary;
			display: block;
			width: fit-content;

			@include breakpoint(tablet) {
				margin: 40px auto 0;
			}
		}
	}

	&__text-group {
		justify-content: center;
		text-align: center;

		li {
			margin: 0 0 16px;

			@include breakpoint(tablet) {
				margin: 0 0 24px;
			}
		}

		a,
		button {
			align-items: center;
			display: flex;
			box-sizing: border-box;
			flex: 0 0 auto;
			justify-content: center;
			line-height: 1.2;
			margin: 0;
			position: relative;
			height: 100%;
			text-align: center;
		}
	}

	&__reasons-group {
		@include flex-rules();
		margin: 13px auto 0;
		padding: 0 16px;
		justify-content: center;
		text-align: center;

		@include breakpoint(desktop) {
			align-items: flex-start;
			margin: 72px auto 0;
			text-align: center;
		}

		div {
			border-radius: 8px;
			box-shadow: 0;
			flex-basis: $col12;
			margin: 45px 0 0;
			max-width: $col12;
			padding: 0;
			transition: box-shadow 0.3s ease-in-out;

			@include breakpoint(tablet) {
				margin: 0;
				padding: 20px 24px;

				&:hover {
					box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
				}
			}

			@include breakpoint(desktop) {
				flex-basis: $col3;
				margin: 0 0 0 58px;
				max-width: $col3;
			}

			p {
				@include font-size(1.1);
				font-family: $secondary;
				color: $vis-blue;
				font-weight: 800;
				letter-spacing: 2px;
				line-height: 11px;
				margin: 0 auto 16px;
				text-transform: uppercase;

				@include breakpoint(tablet) {
					@include font-size(1.7);
					font-family: $secondary;
					letter-spacing: 4px;
					line-height: 17px;
					margin: 0 auto 23px;
				}
			}
		}

		ul {
			padding: 0;
		}

		li {
			margin: 0 0 16px;
			width: 100%;

			@include breakpoint(tablet) {
				margin: 0 0 24px 0;

				&:first-of-type {
					margin: 0 0 24px 0;
				}
			}
		}

		button {
			width: 100%;
		}

		+ .btn {
			margin: 33px auto 0;
			@extend .btn-primary;
			display: block;
			width: fit-content;

			@include breakpoint(tablet) {
				margin: 40px auto 0;
			}
		}
	}

	&__btn {
		margin: 33px auto 0;

		@include breakpoint(tablet) {
			margin: 40px auto 0;
		}
	}

	&__menu {
		justify-content: center;
		text-align: center;

		+ .btn {
			margin: 33px auto 0;
			@extend .btn-primary;
			display: block;
			width: fit-content;

			@include breakpoint(tablet) {
				margin: 40px auto 0;
			}
		}

		li {
			cursor: pointer;
			margin: 0 0 24px;
			padding-left: 0;

			&:first-of-type {
				padding-left: 0;
			}

			@include breakpoint(tablet) {
				padding-left: 24px;
			}

			&.active {
				div {
					color: $grey;
					transition: all 0.3s ease-in-out;
				}
			}

			.wrapper-div {
				background: $white;
				border-radius: 8px;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
				height: 100%;
				padding: 16px;
				text-align: center;
				transition: all 0.3s ease-in-out;

				@include breakpoint(tablet) {
					padding: 16px 35px 20px;
				}

				img {
					+ p {
						margin: 24px auto 0;
						left: initial;
						position: relative;
						top: initial;
						transform: translate(0, 0);

						@include breakpoint(tablet) {
							margin: 44px auto 0;
						}
					}
				}

				&:hover {
					box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
					transform: translateY(-1px);
				}
			}

			.overlay {
				background: orange;
				bottom: 0;
				border-radius: 8px;
				height: 100%;
				left: 0;
				opacity: 0.7;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;

				.icon-Checkmark {
					background: $white;
					border-radius: 100%;
					color: $red;
					font-size: 40px;
					left: 45%;
					margin: auto;
					position: absolute;
					top: 39%;
				}
			}
		}
	}

	&__category {
		justify-content: center;
		text-align: center;

		li {
			div {
				background: $white;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
				border-radius: 8px;
				height: 100%;
				padding: 24px 10px;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;

				@include break-min($small3) {
					padding: 24px 16px;
				}

				@include breakpoint(desktop) {
					padding: 24px 27px;
				}

				&:hover {
					box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
					transform: translateY(-1px);
				}

				&:active {
					box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
					transform: translateY(0px);
				}
			}
		}

		img {
			display: block;
			margin: 16px auto 0;
			max-width: 64px;
			width: 100%;
		}

		p {
			@include font-size(1.1);
			color: $vis-blue;
			font-family: $secondary;
			font-weight: 800;
			line-height: 11px;
			letter-spacing: 2px;
			text-transform: uppercase;
			margin: 0 auto;
			text-align: center;
			width: 100%;

			&:last-of-type {
				@include font-size(1.7);
				color: $red;
				font-family: $primary;
				font-weight: $medium-weight;
				line-height: 1.3;
				letter-spacing: -0.54px;
				margin: 8px auto 0;
				text-transform: none;

				@include break-min($small3) {
					@include font-size(2);
					font-weight: $bold-weight;
				}

				@include break-min($medium3) {
					@include font-size(2.5);
				}
			}
		}
	}
}
