// GLOBAL Locations Grid
// ==========================================================================

.g-locations-grid {
	background: $warm-white;
	padding: 44px 15px 14px;

	@include break-min($small4) {
		padding: 78px 0 82px;
	}

	&__head {
		text-align: center;

		h1,
		h2 {
			@include type-h5();
			color: $blue;
			margin: 0;

			@include break-min($small4) {
				margin: 0 0 22px;
			}
		}

		p {
			display: none;
			margin: 0;
			@include p1(small);

			@include break-min($small4) {
				display: block;
			}
		}
	}

	&__grid {
		justify-content: center;
		@include flexbox();
		@include align-items(flex-start);
		@include align-content(flex-start);
		@include flex-wrap(wrap);

		@include break-min($small4) {
			padding-top: 30px;
		}
	}

	&__link {
		display: block;
		@include type-p1();
		@include link-underline();
		padding: 0 0 5px 0;
	}

	&__item {
		position: relative;
		text-align: center;
		@include flex(0 0 100%);
		max-width: 100%;
		padding: 34px 0;

		&:after {
			@include dot-border-h(bottom, center);
		}

		&:last-child:after {
			height: 0;
		}

		@include break-min($small4) {
			@include flex(0 0 33.3333%);
			max-width: 33.3333%;
			padding: 34px 10px;

			&:after {
				height: 0;
			}
		}

		h1 {
			@include type-p1();
			color: $red;
			margin-top: 0;
		}

		p {
			@include type-p2();
			margin-bottom: 1px;
		}

		.btn-round {
			margin-top: 20px;

			@include break-min($small4) {
				margin-top: 35px;
			}
		}
	}
}
