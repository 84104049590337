.gifting-btn-container {
	padding-bottom: 48px;
	text-align: center;

	@include break-min($large1) {
		grid-column: 2;
		margin-bottom: 60px;
		padding: $right-aligned-column-gap;
		text-align: left;
	}

	@include ie-grid-fix();

	&.mobile-view {
		display: block;

		@include break-min($large1) {
			display: none;
		}
	}

	&.desktop-view {
		display: none;

		@include break-min($large1) {
			display: block;
		}
	}

	a,
	button {
		width: 100%;

		@include break-min($large1) {
			width: auto;
		}

		&:hover {
			text-decoration: none;
		}

		&:not(:first-child) {
			@extend .btn-round--white;
			margin-top: 12px;
			width: 100%;

			@include break-min($large1) {
				margin-top: 0;
				width: auto;
			}
		}

		&:not(:last-child) {
			@include break-min($large1) {
				margin-right: 16px;
			}
		}
	}
}
