.lll-transaction-history-content {
	@include flex-rules();
	display: none;
	flex-basis: $col12;
	margin: 50px auto 0;
	max-width: $col12;
	padding: 0 24px 47px;

	&.active {
		display: flex;
		justify-content: space-between;
	}

	@include breakpoint(tablet) {
		flex-basis: $col8;
		margin: 59px auto 0;
		max-width: 1000px;
		padding: 0 110px 47px;
	}

	.no-history-wrapper {
		text-align: center;

		&-label {
			@include font-size(2.8);
			display: block;
			margin: 0 auto;
			padding: 0 0 10px;
			font-family: $primary;
			font-weight: $bold-weight;
			line-height: lh(34px, 28px);
			letter-spacing: -1px;
			color: $vis-blue;
			// sass-lint:disable no-vendor-prefixes
			-moz-osx-font-smoothing: grayscale;
			// sass-lint:enable no-vendor-prefixes
			margin-top: 50px;
			margin-bottom: 30px;

			@include break-min($medium1) {
				@include flex(1 1 auto);
				@include font-size(3.9);
				letter-spacing: -1px;
				padding: 0;
				// line-height: lh(40px, 39px);
			}
		}
	}

	.col--25 {
		width: 25%;
	}

	.col--75 {
		max-width: 100%;
		width: 75%;
	}

	.lll-transactions {
		flex: 1 0 100%;

		ul {
			padding: 0;
			@include clearfix;

			&.transaction-list {
				width: 100%;
				display: flex;
				flex-flow: column;

				> li {
					padding: 40px 0;
					position: relative;
					height: 100%;

					&:before {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						width: 100%;
						height: 6px;
						background-image: url("img/cp-icons/icon-cp_dot-gray.svg");
						background-size: 12px 6px;
						background-repeat: repeat-x;
						background-position: 0;
						top: 0;
					}

					img {
						float: left;
						height: 48px;

						@include break-min($large1) {
							height: auto;
						}
					}

					.transaction-list-details {
						margin-left: 50px;

						@include break-min($large1) {
							width: 100%;
							margin-left: 85px;
						}

						span {
							@include font-size(1.4);
							line-height: 20px;
							letter-spacing: -0.5px;
							display: block;

							@include break-min($large1) {
								@include font-size(1.2);
							}
						}
					}

					.order-group {
						margin-left: 0;
						margin-top: 0px;

						@include break-min($large1) {
							float: left;
							margin-left: 30px;
							margin-top: 0;
						}

						.order-items {
							margin: 20px 0;
							font-size: 18px;
							line-height: 32px;
							letter-spacing: -0.5px;
						}

						.order-total {
							margin: 0;
							font-size: 18px;
							line-height: 28px;
							font-weight: bold;
							letter-spacing: -0.5px;
						}
					}

					.transaction-list-selection {
						position: relative;
						height: auto;
						width: 100%;
						padding: 25px 0;
						display: block;

						a {
							width: 100%;
						}

						@include break-min($large1) {
							position: absolute;
							right: 0;
							margin-top: auto;
							margin-bottom: auto;
							height: fit-content;
							width: auto;
							padding: 0;

							a {
								width: auto;
							}
						}
					}
				}
			}
		}
	}

	h2 {
		font-family: $primary;
		color: $vis-blue;
		font-weight: bold;
		letter-spacing: -0.5px;
		font-size: 24px;
		display: none;

		@include break-min($large1) {
			display: block;
		}
	}

	h3 {
		@include font-size(1.2);
		color: $vis-blue;
		display: block;
		font-family: $secondary;
		font-weight: 800;
		line-height: 14px;
		letter-spacing: 2px;
		margin: 0 0 10px;
		text-transform: uppercase;
		width: 100%;
	}

	h4 {
		font-family: $primary;
		font-size: 24px;
		line-height: 30px;
		margin: 5px 0;
		font-weight: 800;
		letter-spacing: -0.5px;
	}

	p {
		@include font-size(1.8);
		font-family: $primary;
		font-weight: normal;
		line-height: 32px;
		letter-spacing: -0.5px;
		font-variant-ligatures: none;
		// sass-lint:disable no-vendor-prefixes
		-webkit-font-variant-ligatures: none;
		// sass-lint:enable no-vendor-prefixes
		margin: 0 0 24px;
	}
}
