.location-results {
	width: 100%;
	height: calc(100vh * 0.8);
	overflow: hidden;
	position: relative;
	padding: 0;

	@media only screen and (max-width: $mobile) {
		overflow-y: scroll;
	}

	iframe {
		border: 0;
		height: 100%;
		position: absolute;
		width: 100%;
	}

	&.breadcrumb-margins {
		margin-top: 1px;

		@include break-min($large1) {
			margin-left: 14px;
		}
	}
}

.iPhone-fix {
	@media screen and (max-width: $mobile) {
		padding: 0;
	}

	.location-frame {
		@media screen and (max-width: $mobile) {
			width: 100%;
		}
	}

	iframe {
		@media screen and (max-width: $mobile) {
			border: 0;
			height: 200vw;
			width: 100%;
		}
	}
}
