.slick-slider-dots-grey {
	@include break-min($large1) {
		margin-top: 50px;
	}

	li {
		margin-right: 8px;
		height: 8px;
		width: 8px;

		button {
			background-color: $light-grey;
			border: none;
			border-radius: 100%;
			display: block;
			height: 8px;
			padding: 0;
			text-indent: -9999px;
			width: 8px;

			&:focus {
				outline: 1px dotted blue;
			}

			&:before {
				// sass-lint:disable variable-for-property
				color: grey;
				// sass-lint:enable variable-for-property
			}
		}

		&.slick-active {
			margin-right: 12px;

			button {
				background: $grey;
				height: 12px;
				width: 12px;

				&:before {
					color: $grey;
				}
			}
		}
	}
}
