.promo-cta {
	margin: 0;
	background-position: center bottom;
	background-size: cover;
	overflow: hidden;
	padding-top: 0;
	position: relative;
	// override JS, discuss to remove js for this
	min-height: 420px;
	height: 142vw;
	max-height: calc(100vh - 114px);

	&.promo-cta-blue {
		p {
			text-shadow: none;
			color: $vis-grey;

			&.teaser,
			&.title {
				color: $vis-blue;
			}
		}
	}

	&.tileImg {
		@extend .promo-cta-tile-img;
	}

	@media only screen and (max-width: $mobile) {
		background-position: center bottom;
		flex-direction: column;
		padding-bottom: 11px;
	}

	@include break-min($large1) {
		min-height: 530px;
		height: 56vw;
		// padding-top: 56.25%;
	}

	.promo-frame {
		text-align: center;
		height: 100%;
		padding: 30px 16px;
		position: absolute;
		margin: 0;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		z-index: 1000;

		@include break-min($large1) {
			text-align: left;
			padding: 30px;
			max-width: 1200px;
			margin: 0 auto;
		}
	}

	.promo-content {
		@include font-size(1.6);
		letter-spacing: 0;
		line-height: 28px;
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1000;

		@include flexbox();
		@include flex-direction(column);
		@include align-items(center);
		@include justify-content(flex-start);

		> * {
			@include flex(0 1 auto);
		}

		p {
			padding: 0 0 18px;
			margin: 0;
			width: 100%;
		}

		.btn,
		.btn-round {
			margin: auto 0 0 0;
		}

		@include break-min($large1) {
			max-width: 600px;
			@include align-items(flex-start);
			@include justify-content(center);

			.btn,
			.btn-round {
				margin: 15px 0 0;
			}
		}
	}

	img {
		width: auto;
	}

	.co-content {
		padding: 0 20px;
	}

	.title {
		@include font-size(4.7);
		width: 100%;
		color: $white;
		font-family: $primary;
		// sass-lint:disable no-vendor-prefixes
		-webkit-font-variant-ligatures: none;
		// sass-lint:enable no-vendor-prefixes
		font-variant-ligatures: none;
		font-weight: 800;
		letter-spacing: -0.7px;
		line-height: 1.2;
		text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);

		@include break-min($large1) {
			letter-spacing: -2px;
			position: relative;
			left: -3px;
		}

		@include break-min(960px) {
			line-height: 1.1;
			@include font-size(5.8);
		}

		@include break-min(1150px) {
			@include font-size(7.8);
		}
	}

	.teaser {
		@include font-size(1.7);
		width: 100%;
		color: $white;
		font-family: $secondary;
		font-weight: 800;
		letter-spacing: 4px;
		line-height: 1.1;
		text-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
		text-transform: uppercase;
		margin: 0 0 15px 0;
	}

	p {
		color: $white;
		text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
		@include p1();
	}

	.btn {
		margin-right: 25px;

		@media only screen and (max-width: $mobile) {
			display: inline-block;
		}
	}

	.scroller {
		@include link-underline();
		z-index: 2000;
	}

	&.white {
		&:before {
			@include scrim($scrim4);

			@media only screen and (max-width: $mobile) {
				@include background($scrim5);
			}
		}

		.scroller {
			color: $red;
		}
	}

	&.dark {
		color: $white;

		a {
			color: $white;
			font-weight: 600;
		}

		&:before {
			@include scrim($scrim2);
		}

		.scroller {
			color: $white;
		}
	}

	&.left {
		.co-tagline {
			@include justify-content(flex-start);
			text-align: left;
		}
	}

	&.lrgimg {
		.co-content {
			@include align-items(flex-start);
			@include justify-content(flex-start);
		}
	}

	&.b1Font {
		.promo-content {
			.title {
				@include type-b1();
			}
		}
	}

	&.b2Font {
		.promo-content {
			.title {
				@include type-b2();
			}
		}
	}

	&.b3Font {
		.promo-content {
			.title {
				@include font-size(4.8);
				letter-spacing: -1px;
				line-height: lh(58, 48);

				@include break-min($large1) {
					@include font-size(2.8);
					line-height: lh(34, 28);
				}
			}
		}
	}
}
