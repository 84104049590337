.product-featured-banner {
	margin-bottom: 20px;

	.container {
		width: 100%;
		grid-column: col-start 1 / span 12;
		min-height: 260px;

		.banner-content {
			background: linear-gradient(180deg, #ffeed1 0%, #fbe0db 100%);
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: center;
			padding: 64px;
			position: relative;
			overflow: visible;

			.img-content {
				position: relative;
				width: 40%;
			}

			img {
				height: auto;
				width: 100%;
			}

			.text-content {
				width: 40%;

				h1 {
					color: $blue;
					font-family: $primary;
					font-weight: 700;
					@include font-size(6.4);
					margin: 0 0 12px;
				}

				p {
					margin: 0 0 24px;
					@include font-size(1.8);
				}

				.btn {
					@include font-size(1.4);
					margin: 0;
					font-weight: normal;
				}

				.btn-secondary {
					@include font-size(1.4);
					@include btn-articles();
					margin-right: 16px;
				}
			}

			@include break-max($medium1) {
				padding: 32px;
				display: inherit;

				.img-content {
					width: 100%;
					height: auto;
					margin-bottom: 32px;

					img {
						margin: 0;
					}
				}

				.text-content {
					text-align: center;
					width: 100%;

					h1 {
						@include font-size(3);
					}

					.btn-secondary {
						width: 100%;
						display: block;
						margin: 0 0 16px;
					}
				}
			}
		}
	}

	&.pop-image {
		.container {
			height: auto;
			padding-top: 40px;

			.banner-content {
				.img-content {
					height: 260px;
					display: flex;
					align-items: center;
				}
			}
		}

		@include break-max($medium1) {
			.container {
				.banner-content {
					.img-content {
						display: block;
						height: auto;
						width: calc(100% + 100px);
						margin: -50px 0 24px -50px;
					}
				}
			}
		}
	}
}
