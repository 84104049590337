.true-inspiration-winners {
	.generic-text-panel {
		h1:first-of-type,
		h2:first-of-type,
		h3:first-of-type {
			@include font-size(4.8);
			line-height: lh(54, 48);
			margin: 0;

			@include break-min($large1) {
				@include font-size(6.4);
				line-height: lh(84, 64);
			}
		}
	}

	.video-banner {
		@extend .video-banner-theme__cool-white;
	}

	.g-boxes {
		&.g-boxes--covid {
			@extend .g-boxes--covid;
		}
	}

	.scholarship {
		.g-boxes {
			margin: 0 auto;
			max-width: 192rem;
		}
	}

	.rewards-section {
		.content {
			@include break-min($large1) {
				max-width: 656px;
			}
		}

		.btn-container {
			a:nth-of-type(2) {
				display: none;
			}
		}
	}

	// First instance
	.frame > .rewards-section {
		@extend .header-img-list-themes__blue;
	}

	// Second instance
	.frame > .rewards-section ~ .rewards-section {
		@extend .header-img-list-themes__red;
	}

	.nutrients-allergens-callout {
		@include break-min($large2) {
			padding: 80px 230px 80px 240px;
		}

		.img-wrapper {
			display: none;
		}

		h4 {
			@include break-min($large1) {
				max-width: 400px;
			}

			@include break-min($large2) {
				max-width: none;
			}
		}

		// Can remove once on staging:
		.btn-container {
			a:nth-of-type(2) {
				display: none;
			}
		}
	}
}
