.scholarships {
	&.infographic {
		.container--icon-block {
			@include break-min($large2) {
				justify-content: center;
			}

			.container--column {
				@include break-min($large2) {
					&:not(:last-of-type) {
						margin-right: 12%;
					}
				}
			}
		}
	}

	&.infographic-subcomponent {
		.container--circle-block {
			@include break-min($large2) {
				justify-content: center;
				max-width: 100%;
			}

			.container--column {
				@include break-min($large2) {
					&:not(:last-of-type) {
						margin-right: 3%;
					}
				}
			}
		}
	}
}
