.listicle-list {
	@include grid-margin();
	background: $cool-white;
	border-radius: 32px;
	color: $blue;
	padding: 38px 38px;
	overflow: hidden;

	@include break-min($large1) {
		padding: 40px 70px 37px;
	}

	@include break-min($huge4) {
		margin-left: auto;
		margin-right: auto;
		max-width: 1512px;
		width: 100%;
	}

	.title {
		> *:first-child {
			@include font-size(2);
			color: $blue;
			font-weight: $bold-weight;
			line-height: lh(28, 20);
			letter-spacing: -0.01em;
			margin: 0 0 6px;

			@include break-min($large1) {
				@include font-size(3.6);
				font-weight: $bold-weight;
				line-height: lh(42, 26);
				letter-spacing: -0.5px;
				margin: 0 0 38px;
			}
		}
	}

	ol {
		list-style: decimal inside none;
		padding: 0;
		margin: 0;

		@include break-min($large1) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	li {
		@include font-size(1.8);
		letter-spacing: -0.5px;
		line-height: lh(28, 18);
		padding: 24px 0;

		@include break-min($large1) {
			padding: 0 0 17px;
		}

		&::marker {
			font-family: $primary;
			color: $blue;
			font-weight: $bold-weight;
		}

		&:not(:last-child) {
			border-bottom: 1px solid rgba(0, 79, 113, 0.1);

			@include break-min($large1) {
				border: none;
			}
		}
	}

	a {
		color: $blue;
		text-decoration: none;

		@include break-min($large1) {
			margin: 0 0 0 10px;
		}
	}
}
