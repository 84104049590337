@mixin content-hub-btn-base {
	background: $white;
	border-radius: 24px;
	cursor: pointer;
	font-variant-ligatures: none;
	-webkit-font-variant-ligatures: none;
	// sass-lint:enable no-vendor-prefixes
	transition: none;
	line-height: 1;
	font-weight: $regular-weight;
	font-family: $primary;
	letter-spacing: -0.5px;
}

@mixin btn-articles {
	color: $red;
	padding: 14px 45px;
	border: 1px solid $faint-grey;
	@include font-size(1.4);
	@include content-hub-btn-base;
}

@mixin btn-tertiary {
	color: $red;
	border: 1px solid $red;
	padding: 8px 15px;
	@include font-size(1.4);
	@include content-hub-btn-base;

	&:hover {
		transform: translateY(0);
	}
}
