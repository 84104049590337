.info-tiles {
	@include grid-margin();
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style-type: none;
	flex-direction: column;
	gap: 22px;
	padding: 0;
	margin: 0 24px;
	max-width: 1074px;

	@include break-min($large1) {
		flex-direction: row;
		gap: 50px;
		margin: auto;
	}

	&.overlap {
		margin-bottom: 62px;
		margin-top: 30px;

		@include break-min($large1) {
			margin-top: -150px;
			margin-bottom: 75px;
		}

		.header {
			> :first-child {
				@include type-b2(desktopOnly);
			}
		}
	}

	li {
		background: $cool-white;
		border-radius: 32px;
		grid-column: span 4;
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: center;
		min-height: 324px;
		overflow: hidden;
		padding: 48px;
		text-align: center;
		width: 100%;

		@include break-min($large1) {
			width: 29%;
		}

		p {
			margin: 0;
		}
	}

	.header {
		> *:first-child {
			@include type-b2();
			color: $red;
		}
	}

	.subtext {
		> *:first-child {
			@include font-size(1.6);
			color: $grey;
			line-height: lh(21, 16);
			letter-spacing: 0px;
		}
	}

	&[data-child-count="4"] {
		li {
			@include break-min($large1) {
				width: 46%;
			}
		}
	}
}
