// Utils:
@import "utils/pwo-modals-form-error";
@import "utils/remove-use-location";
// Subcomponents:
@import "subcomponents/pwo-modal-last-ordered";
@import "subcomponents/locations-dropdown";
@import "subcomponents/pwo-modal-search";
@import "subcomponents/pwo-modals-restaurant-label";
@import "subcomponents/use-my-location";

// Variations found at bottom to override master styles
.arke-modal {
	.pwo-modal {
		opacity: 1;
		transform: scale(1);
	}
}

@include break-max($medium2) {
	.mfp-content {
		position: absolute;
		bottom: -100%;
		left: 0;
		transition: all 0.5s ease-in-out;

		.pwo-modal {
			opacity: 1;
			transform: scale(1);
		}
	}

	.arke-modal {
		.mfp-content {
			bottom: 0;
		}
	}
}

.pwo-modal {
	@extend .base-modal;
	border-radius: 8px;
	width: 100%;
	transform: scale(0.9);
	opacity: 0;
	transition: all 0.25s ease-in-out;

	@include break-min($large1) {
		margin: 0 auto;
		padding: 0 64px;
		width: 540px;
	}

	&.signedIn {
		padding: 0;
		margin-bottom: 0;

		.content {
			padding: 64px 0 0;
		}

		h2,
		.pwo-modal-search {
			padding: 0 15px;

			@include break-min($large1) {
				padding: 0 42px 0;
			}
		}
	}

	.content {
		padding: 64px 15px 32px;

		@include break-min($large1) {
			padding: 64px 0 32px;
		}
	}

	h2 {
		@include font-size(2.4);
		color: $blue;
		font-weight: $bold-weight;
		letter-spacing: -0.8px;
		line-height: lh(34, 24);
		margin: 0 0 16px 0;
		text-align: center;
		max-width: 500px;

		@include break-min($large1) {
			@include font-size(3.6);
			line-height: lh(42, 36);
			margin-bottom: 16px;
			letter-spacing: -1.4px;
		}
	}

	.pwo-last-ordered {
		display: none;
	}

	.secondary {
		background: $cool-white;
		border-radius: 8px;
		padding: 30px 15px 21px;

		@include break-min($large1) {
			padding: 20px 36px 30px;
		}

		.pwo-modal-search {
			padding: 0;

			.user-input {
				background: $white;
			}

			.use-location {
				margin: 0 0 0 13px;
			}
		}
	}

	&#ingredient-modal {

		h2 {
			margin-bottom: 25px;
		}

		p {
			text-align: center;
			margin-bottom: 25px;
		}

		.btn-container {
			display: flex;
			justify-content: center;

			@include break-max($large1) {
				button {
					width: 100% !important;
				}

			}
		}
	}
}



// Variations
@import "variations/favorited-restaurants-yext";
@import "variations/pwo-modal-favorited-restaurants";
@import "variations/ingredient-modal";
