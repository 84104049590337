.still-have-question {
	background-color: $cool-white;
	padding: 72px 24px;

	@include break-min($medium4) {
		padding: 112px 24px 96px;
	}

	.header-wrapper {
		@include break-min($medium4) {
			text-align: center;
		}
	}

	.contact-card-wrapper {
		@include break-min($medium4) {
			display: flex;
		}
	}

	.header {
		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);
			margin-bottom: 24px;

			@include break-min($medium4) {
				@include font-size(4.8);
				line-height: lh(58, 48);
				margin-bottom: 8px;
			}
		}
	}

	&__subcopy {
		margin: auto;
		max-width: 750px;

		p {
			@include font-size(1.8);
			color: $grey;
			font-weight: $regular-weight;
			letter-spacing: -0.5px;
			line-height: lh(25, 18);
			margin-bottom: 24px;

			@include break-min($medium4) {
				line-height: lh(28, 18);
			}
		}
	}

	&__cta {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 40px;
		text-align: center;

		@include break-min($medium4) {
			flex-direction: row;
			margin-bottom: 48px;
		}

		a {
			@include font-size(1.6);
			@extend .btn-secondary;
			align-items: center;
			border-radius: 24px;
			display: inline-block;
			font-weight: $regular-weight;
			padding: 15px 0;
			justify-content: center;
			letter-spacing: -0.5px;
			text-align: center;
			width: 327px;
			line-height: 100%;

			@include break-min($medium4) {
				width: 166px;
			}

			&:last-of-type {
				@include btn-round;
				@include btn-primary-red;
				display: none;
				margin-top: 24px;

				@include break-min($medium4) {
					margin-left: 16px;
					margin-top: 0;
				}
			}
		}
	}

	&__call-info {
		display: flex;
		flex-direction: row;
		margin: 0 auto 12px;
		max-width: 291px;
		padding: 20px 35px;

		@include break-min($medium4) {
			background-color: $white;
			border-radius: 8px;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
		}

		.call-info__icon {
			align-self: center;
			margin-right: 34px;
			width: 27px;

			img {
				height: auto;
				width: 27px;
				max-width: none;
			}
		}

		.call-info__text {
			width: 100%;

			a,
			p {
				@include font-size(1.4);
				color: $grey;
				font-weight: $regular-weight;
				letter-spacing: -0.25px;
				line-height: lh(23, 14);
				margin-bottom: 2px;
				text-align: left;
				display: flex;
				flex-flow: column;
			}

			> *:first-child {
				@include font-size(1.2);
				color: $grey;
				font-family: "caecilia";
				font-weight: $bold-weight;
				letter-spacing: 2px;
				line-height: lh(14, 12);
				margin-bottom: 8px;
				text-align: left;
				text-transform: uppercase;
			}

			a {
				color: $red;
			}
		}
	}

	&.press-room {
		padding: 46px 40px 25px;
		margin-top: 23px;

		@include break-min($medium4) {
			padding: 112px 0 96px;
			margin-top: 46px;
		}

		.grid-wrapper {
			@include break-min($medium4) {
				@include grid-margin();
			}

			@include break-min($medium4) {
				align-items: center;
				display: flex;
			}

			@include break-min($huge4) {
				display: grid;
				gap: 22px;
				grid-template-columns: repeat(12, [col-start] 104px);
				justify-content: center;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.header-wrapper {
			text-align: center;

			@include break-min($medium4) {
				border-right: 1px solid $faint-grey;
				flex: 50%;
				padding-right: 25px;
				text-align: left;
			}

			@include break-min($huge4) {
				grid-column: col-start 1 / span 6;
			}
		}

		.contact-card-wrapper {
			@include break-min($medium4) {
				flex: 50%;
				flex-wrap: wrap;
				gap: 17px;
				padding-left: 50px;
			}

			@include break-min($huge4) {
				grid-column: col-start 7 / span 6;
			}
		}

		.still-have-question__cta {
			@include break-min($medium4) {
				align-items: left;
				justify-content: flex-start;
				margin-bottom: 0;
			}

			a {
				display: inline-block;
			}
		}

		.still-have-question__call-info {
			background: $white;
			border-radius: 8px;
			margin: 0 auto 12px;
			max-width: 291px;
			padding: 20px 35px;
			width: 100%;

			@include break-min($medium4) {
				align-items: center;
				margin: 0px;
				max-width: none;
			}

			@include break-min($large1) {
				width: 44%;
			}

			@include break-min($large4) {
				width: 48%;
			}
		}

		@include break-max($large3) {
			.grid-wrapper {
				display: grid;

				.header-wrapper {
					width: 50%;
					padding: 0;
					margin-bottom: 50px;
					border: none;

					@include break-max($medium2) {
						width: 100%;
						margin-bottom: 25px;
					}
				}

				.contact-card-wrapper {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					padding: 0;

					.still-have-question__call-info {
						width: 100%;
						padding: 20px;
					}

					@include break-max($medium2) {
						grid-template-columns: repeat(1, 1fr);

						.still-have-question__call-info {
							max-width: 100%;
						}
					}
				}
			}
		}
	}
}
