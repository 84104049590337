// sass-lint:disable max-line-length

// General site settings such as font family, font sizes, colors

$btn-grad: linear-gradient(
	0deg,
	rgba(202, 15, 26, 1) 0%,
	rgba(255, 3, 59, 1) 100%
);
$btn-hover-grad: linear-gradient(
	0deg,
	rgba(152, 11, 20, 1) 0%,
	rgba(191, 3, 45, 1) 100%
);
$white-grad: linear-gradient(top, rgba(#fff, 0.9) 0%, rgba(#fff, 1) 100%);
$nav-grad: linear-gradient(to bottom, #fff 90%, rgba(255, 255, 255, 0) 100%);
$nav-grad2: linear-gradient(
	to bottom,
	rgba(255, 255, 255, 0.8) 27%,
	rgba(255, 255, 255, 0) 103%
);
$nav-grad3: linear-gradient(
	to bottom,
	rgba(255, 255, 255, 0.4) -110%,
	rgba(255, 255, 255, 0) 130%
);
$scrim: linear-gradient(
	to bottom,
	rgba(0, 0, 0, 0) 0%,
	rgba(0, 0, 0, 0.25) 100%
);
$scrim2: linear-gradient(
	to bottom,
	rgba(0, 0, 0, 0) 0%,
	rgba(0, 0, 0, 0.5) 100%
);
$scrim3: linear-gradient(
	to bottom,
	rgba(0, 0, 0, 0.25) 0%,
	rgba(0, 0, 0, 0.75) 100%
);
$scrim4: linear-gradient(
	to left,
	rgba(255, 255, 255, 0.1) 46%,
	rgba(255, 255, 255, 1) 60%
);
$scrim5: linear-gradient(
	to top,
	rgba(255, 255, 255, 0.1) 46%,
	rgba(255, 255, 255, 1) 60%
);
$scrim6: linear-gradient(
	to top,
	rgba(0, 0, 0, 0) 70%,
	rgba(0, 0, 0, 0.15) 100%
);
$gray-brown-grad: linear-gradient(
	top,
	rgba(#f4f3f0, 1) 80%,
	rgba(#cdc7be, 1) 100%
);

// Font Families
$primary: "apercu", sans-serif;
$secondary: "caecilia", sans-serif;
$icon: "icomoon";
$nge-font: "CFA_One", "apercu", sans-serif;

// Widths
$main: 1380px;
$bp1: 1220px;
$bp2: 900px;

// Breakpoints
$lrg-desktop: 1600px;
$desktop: 1439px;
$sml-desktop: 1279px;
$lrg-tablet: 960px;
$mobile: 959px;
$lrg-mobile: 750px;
$sml-mobile: 650px;

// Grid
$col1: 5.835%;
$col2: 14.39%;
$col3: 22.95%;
$col4: 31.51%;
$col5: 40.07%;
$col6: 48.63%;
$col7: 57.19%;
$col8: 65.75%;
$col9: 74.31%;
$col10: 82.87%;
$col11: 91.43%;
$col12: 100%;
$col-form-input: 100%;
$col-margin: 2.725%;
$col-margin-quiz-poll: 3.58%;

// Careers
$col-aside-width: 280px;
$col-article-width: 63%;
$col-padding: 2%;
$col-article-content: 54.48%;

// nav
$nav-base-height: 64px;
