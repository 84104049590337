.cp-content__inner {
	.nge-points__trophy {
		@include break-min($small1) {
			margin-left: -15px;
			margin-right: -15px;
		}

		@include break-min($small4) {
			margin-left: -30px;
			margin-right: -30px;
		}

		@include break-min($small5) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.basic-member-theme {
		$cp-blue: $cadet-blue;

		.nge-points__trophy {
			background: $cp-blue;

			&:after {
				@include dot-border-h(bottom, left, blue);
				left: -2px;
				bottom: -20px;
			}
		}

		.nge-points__giant-wrap h1 {
			color: $membership-red;
		}

		.progress-bar-anim {
			position: relative;

			.bar {
				@include dot-progressbar-h(top, left, member);

				.progress {
					background-color: $cadet-blue;
				}
			}
		}
	}

	.silver-member-theme {
		$silver: #8f969b;

		.nge-points {
			&:after {
				@include dot-border-h(bottom, left, silver);
				bottom: -5px;
				left: -2px;
			}

			&__giant-wrap {
				h1 {
					color: $grey;
				}
			}
		}

		.nge-points__trophy {
			background: $silver;

			&:after {
				@include dot-border-h(bottom, left, silver);
				left: -2px;
				bottom: -20px;
			}
		}

		.progress-bar-anim {
			position: relative;

			.bar {
				@include dot-progressbar-h(top, left, silver);

				.progress {
					background-color: $silver;
				}
			}
		}
	}

	.red-member-theme {
		.nge-points {
			&:after {
				@include dot-border-h(bottom, left, red);
				bottom: -5px;
				left: -2px;
			}

			&__giant-wrap {
				h1 {
					color: $grey;
				}
			}
		}

		.nge-points__trophy {
			background: $membership-red;

			&:after {
				@include dot-border-h(bottom, left, red);
				left: -2px;
				bottom: -20px;
			}
		}

		.progress-bar-anim {
			position: relative;

			.bar {
				@include dot-progressbar-h(top, left, red);

				.progress {
					background-color: $membership-red;
				}
			}
		}
	}

	.signature-member-theme {
		.nge-points {
			&:after {
				@include dot-border-h(bottom, left, signature);
				bottom: -5px;
				left: -2px;
			}

			&__giant-wrap {
				h1 {
					color: $grey;
				}
			}
		}

		.nge-points__trophy {
			background: $membership-signature;

			&:after {
				@include dot-border-h(bottom, left, signature);
				left: -2px;
				bottom: -20px;
			}
		}

		.progress-bar-anim {
			position: relative;

			.bar {
				@include dot-progressbar-h(top, left, signature);

				.progress {
					background-color: $membership-signature;
				}
			}
		}
	}
}
