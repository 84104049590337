.two-column-location-description {
	padding: 40px 20px;
	background-color: $bkgrey;
	text-align: center;

	@include break-min($large1) {
		padding: 90px 200px;
	}

	@include break-min($large4) {
		padding: 90px 270px;
	}

	@include break-min($huge2) {
		padding: 90px 340px;
	}

	.lb-component-content {
		&__button {
			max-width: 145px;
			margin: 0 auto;
		}

		p {
			@include font-size(1.8);
		}

		h1 {
			color: $blue;
			font-family: $primary;
			font-weight: bold;
			margin: 0 0 24px;
			padding: 0;
			text-align: center;
			letter-spacing: -0.01px;
			line-height: 46px;
			@include font-size(3.6);

			@include break-min($large1) {
				letter-spacing: -0.8px;
				margin: 0 0 20px;
				@include font-size(4.8);
			}

			&:after {
				content: "...";
				display: block;
				letter-spacing: 3px;
				margin: 10px 0 0;
			}
		}

		&__columns {
			align-items: stretch;
			display: flex;
			flex-wrap: wrap;
			flex-grow: 2;
			flex-shrink: 0;
			flex-basis: 0;
			justify-content: center;
			list-style: none;
			padding: 0;
			margin: auto;
			max-width: 1000px;
			margin-top: 45px;

			@include break-min($large1) {
				margin-top: 75px;
			}

			&-item {
				display: flex;
				flex-flow: column;
				margin: 0 50px;
				margin-bottom: 40px;

				@include break-min($large1) {
					margin-bottom: 0;
				}

				span {
					&.highlight {
						line-height: 34px;
						color: $blue;
						font-weight: $bold-weight;
						margin: 5px 0;
						@include font-size(2);

						@include break-min($large1) {
							margin: 15px 0;
							@include font-size(2.8);
						}
					}

					&:not(.highlight) {
						line-height: 25px;
						@include font-size(1.6);
					}
				}
			}
		}
	}
}
