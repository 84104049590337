.featured-product-video-hero {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-top: 0;

	@include break-min($large1) {
		flex-direction: row;
		height: 800px;
		overflow: hidden;
		padding: 0;
	}

	@include break-min($large4) {
		justify-content: center;
	}

	&.cool-white-bckgrd {
		background: $cool-white;
	}

	&.reverse-order-mobile {
		.wrapper {
			order: 1;
		}

		.video-container {
			order: 2;
		}
	}

	&.reverse-order-desktop {
		.wrapper {
			@include break-min($large1) {
				order: 2;
			}
		}

		.video-container {
			@include break-min($large1) {
				order: 1;
			}
		}
	}

	&.video-object-fit-contain {
		.video-container {
			video-js {
				object-fit: contain;
			}
		}
	}

	.wrapper {
		padding: 40px 24px 42px;
		order: 2;

		@include break-min($medium4) {
			margin: 0 63px 0 0;
		}

		@include break-min($large1) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 0 23px 0 0;
			order: 1;
			padding: 0 0 0 86px;
		}

		@include break-min($large4) {
			margin: 0 111px 0 0;
			max-width: 650px;
			padding: 0 0 0 168px;
		}
	}

	h1 {
		@include type-h1();
		color: $red;
		font-size: font-size(4.8);
		letter-spacing: -0.01em;
		line-height: 48px;
		margin: 0 0 24px 0;
		text-align: center;

		@include break-min($large1) {
			@include font-size(6.4);
			left: -5px;
			margin-bottom: 24px;
			position: relative;
			text-align: left;
		}
	}

	p {
		color: $dkgrey;
		font-size: 18px;
		letter-spacing: -0.5px;
		line-height: 28px;
		margin-bottom: 24px;
		text-align: center;

		@include break-min($large1) {
			margin-bottom: 40px;
			max-width: 600px;
			text-align: left;
		}
	}

	.btn-container {
		align-items: center;
		display: flex;
		flex-direction: column;

		@include break-min($large1) {
			flex-direction: row;
			margin: 0;
		}

		img {
			margin-right: 22px;
		}

		a:not(.btn) {
			@include btn-hover-effect();
			align-items: center;
			background: $btn-grad;
			border-radius: 12.4838px;
			color: $white;
			display: flex;
			font-size: 14.0442px;
			font-weight: bold;
			height: 64px;
			justify-content: center;
			line-height: 17px;
			margin: 0px 0px 16px 0px;
			padding: 0;
			text-decoration: none;
			width: 100%;

			@include break-min($large1) {
				width: 243px;
			}

			&:first-child {
				margin-right: 0px;

				@include break-min($large1) {
					margin-right: 16px;
				}
			}

			p {
				@include font-size(1.4);
				color: $white;
				line-height: 17px;
				position: inherit;
				margin: 0;
				transform: none;
			}
		}
	}

	.video-container {
		height: 240px;
		position: relative;
		width: 100%;
		pointer-events: none;
		overflow: hidden;
		order: 1;
		transition: 500ms opacity ease-in;

		@include break-min($large1) {
			margin: 0;
			height: auto;
			width: 680px;
		}

		.player-wrapper {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: 0;
			transition: opacity 0.3s ease;
			width: 100%;
			height: 100%;
		}

		.video-js {
			height: 100%;
			width: 100%;
		}

		.vjs-loading-spinner,
		.vjs-control-bar,
		.vjs-big-play-button {
			display: none;
		}

		.vjs-poster {
			background-color: transparent;
			background-size: cover;
			display: none;
		}

		.vjs-player-info-modal {
			display: none;
		}

		video {
			background: $white;
			background-color: $white;

			@include break-max($large1) {
				object-fit: cover;
			}
		}
	}
}
