.articles-grid-module {
	@include grid-margin();
	margin-top: 48px;

	@include break-min($large1) {
		margin-top: 98px;
	}

	@include break-min($huge4) {
		gap: 24px;
		justify-content: center;
	}

	.title-wrapper {
		@include break-min($large1) {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}
	}

	.grid-title {
		margin: 0 0 24px 0;

		@include break-min($large1) {
			margin: 0 0 20px 0;
		}

		> :first-child {
			@include font-size(2.4);
			color: $blue;
			margin: 0;
			font-weight: $bold-weight;
			line-height: lh(32, 24);
			letter-spacing: -0.24px;

			@include break-min($large1) {
				@include font-size(3.6);
				font-weight: $bold-weight;
				line-height: lh(42, 36); /* 116.667% */
				letter-spacing: -0.5px;
			}
		}
	}

	.grid-wrapper {
		grid-column: col-start 1 / span 4;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 12;
		}
	}
}
