.promo-banner {
	align-items: center;
	background: $ev-night-cloud;
	color: $white;
	display: flex;
	min-height: 88px;
	justify-content: space-between;

	&.dark-gold {
		background: $ev-dark-gold;
	}

	&.red {
		background: $red;
	}

	&.cool-white {
		background: $cool-white;
		color: $blue;

		a,
		.banner-link,
		.arrow-link a {
			color: $blue;
		}
	}

	&.inform-blue {
		background: $white;

		div {
			p:first-child {
				color: $dkgrey;
			}

			p:nth-child(2) {
				color: $blue;
			}
		}

		.arrow-link a {
			color: $red;
		}
	}

	@include break-min($large1) {
		padding: 30px 30px 30px 85px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}

	@include break-min($large3) {
		padding: 30px 90px 30px 85px;
	}

	@include break-min($large1) {
		min-height: 110px;
	}

	.banner-link {
		align-items: center;
		color: $white;
		display: flex;
		height: 100%;
		padding: 0px 27px;
		text-decoration: none;
		width: 100%;

		@include break-min($large1) {
			padding: 0;
			pointer-events: none;
			width: auto;
		}

		img {
			margin-right: 43px;
			max-width: 100px;
		}

		div {
			display: flex;
			flex-direction: column;

			@include break-min($large1) {
				max-width: 650px;
			}

			@include break-min($large3) {
				max-width: 750px;
			}

			p:first-child {
				font-size: 12px;
				letter-spacing: -0.25px;
				line-height: 20px;
				margin-bottom: 5px;

				@include break-min($large1) {
					font-size: 14px;
					line-height: 23px;
				}
			}

			p {
				font-size: 16px;
				font-weight: normal;
				line-height: 110% !important;
				margin: 0;

				@include break-min($large1) {
					font-size: 24px;
					line-height: 34px;
				}
			}
		}
	}

	.arrow-link {
		position: relative;

		&:hover {
			img {
				transform: translateX(2px);
			}
		}

		a {
			color: $white;
			display: none;
			font-size: 14px;
			letter-spacing: -0.25px;
			line-height: 23px;
			margin-right: 8px;
			text-align: right;
			text-decoration: none;

			@include break-min($large1) {
				display: inline-block;
			}
		}

		img {
			bottom: 0;
			height: 18px;
			margin: auto;
			position: absolute;
			right: 15px;
			top: 0;
			transition: all 100ms ease-in;
			width: 10px;

			@include break-min($large1) {
				height: 11px;
				position: relative;
				right: 0;
				width: 6px;
			}
		}
	}
}
