.g-boxes {
	&.rounded-images {
		.g-boxes__box {
			.img-wrapper {
				border-radius: 12px;
				overflow: hidden;
			}
		}
	}
}
