// sass-lint:disable max-line-length, no-url-protocols, no-url-domains
// sass-lint:disable no-duplicate-properties

@font-face {
	font-family: "apercu";
	src: url("https://d31iqi82ivd0ac.cloudfront.net/apercu-regular.eot");
	src: url("https://d31iqi82ivd0ac.cloudfront.net/apercu-regular.woff2") format("woff2"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-regular.woff") format("woff"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-regular.ttf") format("truetype"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-regular.svg#apercu-regular") format("svg"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-regular.eot?#iefix") format("embedded-opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "apercu";
	src: url("https://d31iqi82ivd0ac.cloudfront.net/apercu-medium.eot");
	src: url("https://d31iqi82ivd0ac.cloudfront.net/apercu-medium.woff2") format("woff2"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-medium.woff") format("woff"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-medium.ttf") format("truetype"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-medium.svg#apercu-medium") format("svg"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-medium.eot?#iefix") format("embedded-opentype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "apercu";
	src: url("https://d31iqi82ivd0ac.cloudfront.net/apercu-bold.eot");
	src: url("https://d31iqi82ivd0ac.cloudfront.net/apercu-bold.woff2") format("woff2"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-bold.woff") format("woff"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-bold.ttf") format("truetype"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-bold.svg#apercu-bold") format("svg"), url("https://d31iqi82ivd0ac.cloudfront.net/apercu-bold.eot?#iefix") format("embedded-opentype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "caecilia";
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-55Roman.eot");
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-55Roman.woff2") format("woff2"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-55Roman.woff") format("woff"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-55Roman.ttf") format("truetype"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-55Roman.svg#CaeciliaCom-55Roman") format("svg"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-55Roman.eot?#iefix") format("embedded-opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "caecilia";
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-45Light.eot");
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-45Light.woff2") format("woff2"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-45Light.woff") format("woff"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-45Light.ttf") format("truetype"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-45Light.svg#CaeciliaCom-45Light") format("svg"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-45Light.eot?#iefix") format("embedded-opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "caecilia";
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-75Bold.eot");
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-75Bold.woff2") format("woff2"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-75Bold.woff") format("woff"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-75Bold.ttf") format("truetype"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-75Bold.svg#CaeciliaCom-75Bold") format("svg"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-75Bold.eot?#iefix") format("embedded-opentype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "caecilia";
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-85Heavy.eot");
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-85Heavy.woff2") format("woff2"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-85Heavy.woff") format("woff"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-85Heavy.ttf") format("truetype"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-85Heavy.svg#CaeciliaCom-85Heavy") format("svg"), url("https://d31iqi82ivd0ac.cloudfront.net/CaeciliaCom-85Heavy.eot?#iefix") format("embedded-opentype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "franklin-gothic";
	src: url("https://d31iqi82ivd0ac.cloudfront.net/Franklin-Gothic-Book.eot");
	src: url("https://d31iqi82ivd0ac.cloudfront.net/Franklin-Gothic-Book.woff2") format("woff2"), url("https://d31iqi82ivd0ac.cloudfront.net/Franklin-Gothic-Book.woff") format("woff"), url("https://d31iqi82ivd0ac.cloudfront.net/Franklin-Gothic-Book.ttf") format("truetype"), url("https://d31iqi82ivd0ac.cloudfront.net/Franklin-Gothic-Book.svg#Franklin-Gothic-Book") format("svg"), url("https://d31iqi82ivd0ac.cloudfront.net/Franklin-Gothic-Book.eot?#iefix") format("embedded-opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "icomoon";
	src: url("fonts/icomoon.eot?c5r3y4");
	src: url("fonts/icomoon.eot?c5r3y4#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?c5r3y4") format("truetype"), url("fonts/icomoon.woff?c5r3y4") format("woff"), url("fonts/icomoon.svg?c5r3y4#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "CFA_One";
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CFA_One.eot");
	src: url("https://d31iqi82ivd0ac.cloudfront.net/CFA_One.eot?#iefix") format("embedded-opentype"), url("https://d31iqi82ivd0ac.cloudfront.net/CFA_One.woff2") format("woff2"), url("https://d31iqi82ivd0ac.cloudfront.net/CFA_One.woff") format("woff"), url("https://d31iqi82ivd0ac.cloudfront.net/CFA_One.ttf") format("truetype"), url("https://d31iqi82ivd0ac.cloudfront.net/CFA_One.svg#CFA_One") format("svg");
	font-weight: normal;
	font-style: normal;
}


[class^="icon-"],
[class*=" icon-"] {
	font-family: "icomoon";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	// Better Font Rendering ===========
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	// sass-lint:enable no-vendor-prefixes
}

.icon-plus:before {
	content: "\e922";
}

.icon-box:before {
	content: "\e921";
}

.icon-check:before {
	content: "\e920";
}

.icon-ellipses:before {
	content: "\e91d";
}

.icon-bullet:before {
	content: "\e91b";
}

.icon-servings:before {
	content: "\e915";
}

.icon-time:before {
	content: "\e916";
}

.icon-difficulty:before {
	content: "\e919";
}

.icon-replay:before {
	content: "\e911";
}

.icon-facebook-over:before {
	content: "\e901";
}

.icon-info:before {
	content: "\e910";
}

.icon-arrow-down:before {
	content: "\e902";
}

.icon-circle-left:before {
	content: "\e909";
}

.icon-arrow-up:before {
	content: "\e903";
}

.icon-circle-right:before {
	content: "\e90e";
}

.icon-circle-down:before {
	content: "\e904";
}

.icon-location-on:before {
	content: "\e906";
}

.icon-logo-mobile:before {
	content: "\e907";
}

.icon-linkedin-over:before {
	content: "\e938";
}

.icon-android:before {
	content: "\e917";
}

.icon-nav-toggle-off:before {
	content: "\e908";
}

.icon-search-off:before {
	content: "\e90a";
}

.icon-close:before {
	content: "\e90b";
}

.icon-logo-desktop:before {
	content: "\e90c";
}

.icon-facebook-up:before {
	content: "\e926";
}

.icon-instagram-over:before {
	content: "\e900";
}

.icon-instagram-up:before {
	content: "\e925";
}

.icon-email-redCircle:before {
	content: "\e92d";
}

.icon-email-circle:before {
	content: "\ea86";
}

.icon-location-off:before {
	content: "\e90d";
}

.icon-circle-up:before {
	content: "\e90f";
}

.icon-twitter-over:before {
	content: "\e912";
}

.icon-youtube-over:before {
	content: "\e913";
}

.icon-nav-toggle-on:before {
	content: "\e914";
}

.icon-tumblr-over:before {
	content: "\e905";
}

.icon-tumblr-up:before {
	content: "\e91f";
}

.icon-apple:before {
	content: "\e918";
}

.icon-twitter-up:before {
	content: "\e91a";
}

.icon-map:before {
	content: "\e91c";
}

.icon-youtube-up:before {
	content: "\e91e";
}

.icon-location:before {
	content: "\e923";
}

.icon-list:before {
	content: "\e924";
}

.icon-standalone:before {
	content: "\e927";
}

.icon-mall:before {
	content: "\e928";
}

.icon-user-inactive:before {
	content: "\e929";
}

.icon-user-active:before {
	content: "\e92a";
}

.icon-bag-inactive:before {
	content: "\e92b";
}

.icon-bag-active:before {
	content: "\e92c";
}

.icon-cold:before {
	content: "\e92e";
}

.icon-hot:before {
	content: "\e92f";
}

.icon-calendar:before {
	content: "\e930";
}

.icon-Checkmark:before {
	content: "\e931";
}

.icon-missing-items:before {
	content: "\e932";
}

.icon-orders-restaurant-feedback:before {
	content: "\e933";
}

.icon-other:before {
	content: "\e934";
}

.icon-portion-size:before {
	content: "\e935";
}

.icon-taste:before {
	content: "\e936";
}

.icon-temperature:before {
	content: "\e937";
}

.icon-wrong-items:before {
	content: "\e939";
}

body {
	font-family: $primary;
}

.x-large {
	font-family: $primary;
	font-weight: 800;
	font-size: 35px;
	line-height: 44px;
	letter-spacing: -.7px;
	font-variant-ligatures: none;
	// sass-lint:disable no-vendor-prefixes
	-webkit-font-variant-ligatures: none;
	// sass-lint:enable no-vendor-prefixes
	color: $grey;

	@media only screen and (min-width: $mobile) {
		font-size: 78px;
		line-height: 84px;
		letter-spacing: -2px;
	}

	&--white {
		color: $white;
	}

	&--blue {
		color: $blue;
	}

	&--shadow {
		text-shadow: 0 2px 10px rgba(0,0,0,0.50);
	}
}

.large {
	font-family: $primary;
	font-weight: 800;
	font-size: 44px;
	line-height: 68px;
	letter-spacing: -1.5px;
	font-variant-ligatures: none;
	// sass-lint:disable no-vendor-prefixes
	-webkit-font-variant-ligatures: none;
	// sass-lint:enable no-vendor-prefixes
	color: $grey;

	@media only screen and (min-width: $mobile) {
		font-size: 62px;
		letter-spacing: -.75px;
	}

	&--blue {
		color: $blue;
	}
}

.medium {
	font-family: $primary;
	font-weight: 800;
	font-size: 22px;
	line-height: 37px;
	letter-spacing: -.5px;
	font-variant-ligatures: none;
	// sass-lint:disable no-vendor-prefixes
	-webkit-font-variant-ligatures: none;
	// sass-lint:enable no-vendor-prefixes
	color: $grey;

	@media only screen and (min-width: $mobile) {
		font-size: 47px;
		line-height: 60px;
		letter-spacing: -1px;
	}
}

.small {
	font-family: $primary;
	font-weight: 800;
	font-size: 32px;
	line-height: 48px;
	letter-spacing: -.7px;
	font-variant-ligatures: none;
	// sass-lint:disable no-vendor-prefixes
	-webkit-font-variant-ligatures: none;
	// sass-lint:enable no-vendor-prefixes
	color: $grey;

	&--white {
		color: $white;
	}

	&--blue {
		color: $blue;
	}
}

.x-small {
	font-family: $primary;
	font-weight: 600;
	font-size: 23px;
	line-height: 36px;
	letter-spacing: -.5px;
	font-variant-ligatures: none;
	// sass-lint:disable no-vendor-prefixes
	-webkit-font-variant-ligatures: none;
	// sass-lint:enable no-vendor-prefixes
	color: $grey;

	&--blue {
		color: $blue;
	}
}

.type {
	font-family: $primary;
	font-weight: normal;
	font-size: 18px;
	line-height: 32px;
	letter-spacing: -.5px;
	font-variant-ligatures: none;
	// sass-lint:disable no-vendor-prefixes
	-webkit-font-variant-ligatures: none;
	// sass-lint:enable no-vendor-prefixes

	&--small {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.5px;
	}

	&--featured {
		font-size: 18px;
		font-weight: 800;
		letter-spacing: -0.23px;
		line-height: 21px;
		text-shadow: 0 2px 10px rgba(0,0,0,0.70);
	}

	&--white {
		color: $white;
	}
}
