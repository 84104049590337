.overlay-full-callout {
	@include add-margin();
	background: gray;
	height: fit-content;
	margin: 0 0 60px 0;
	padding: 35px 32px;
	width: auto;
	grid-column: col-start 1 / span 4;

	@include break-min($medium4) {
		grid-column: col-start 1 / span 8;
	}

	@include break-min($large4) {
		grid-column: col-start 1 / span 12;
		padding: 48px 0 48px 72px;
	}

	@include break-min($huge4) {
		margin-left: auto;
		margin-right: auto;
		max-width: 1512px;
		width: 100%;
	}

	.message-container {
		background: $white;
		border-radius: 32px;
		padding: 24px;
		grid-column: col-start 1 / span 5;

		@include break-min($medium4) {
			padding: 32px;
			margin: 46px 0;
		}

		@include break-min($large4) {
			padding: 48px;
		}

		@include break-min($huge2) {
			grid-column: col-start 1 / span 5;
		}
	}

	.icon-container {
		@extend .icon-container-callout;
		margin: 0 0 16px 0;
	}

	.title-text {
		@extend .title-text-callout;
		margin: 0 0 16px 0;
	}

	.body-text {
		p {
			@include type-p1-refresh();
			margin: 0;
		}
	}

	.btn {
		margin: 0 auto;
		margin-top: 32px;
		width: fit-content;
		@include btn-round;
		@include btn-primary-red;
	}

	&.full-width-image {
		@include desktop-grid-max-width();
		min-height: 650px;

		.message-container {
			display: none;
		}
	}

	&.press-room {
		margin: auto;
		min-height: 474px;
		max-width: none;

		.message-container {
			display: none;
		}

		@include break-max($large1) {
			display: none;
		}
	}

	&.story-hero {
		border-radius: 30px;
		margin: 0 24px;
		min-height: 226px;

		@include break-min($large1) {
			margin: 0 auto;
			min-height: 428px;
			width: 636px;
		}

		.message-container {
			display: none;
		}

		&.landscape {
			@include grid-margin();
			height: 240px;
			width: auto;

			@include break-min($large1) {
				height: 664px;
				// margin: 0 auto;
				max-width: 1276px;
			}

			@include break-min($huge1) {
				margin: 0 auto;
			}
		}

		&.portrait {
			height: 448px;
			width: 336px;
			margin: auto;

			@include break-min($large1) {
				height: 848px;
				width: 636px;
			}
		}

		&.box-shadow {
			box-shadow: 0px 4px 20px 0px $black;
			// box-shadow: 0px 4px 20px 0px $black;
		}
	}

	&.full-bleed {
		@include break-min($huge4) {
			display: flex;
			justify-content: flex-end;
			max-width: none;
		}

		.message-container {
			@include break-min($huge4) {
				margin: 0 46px 0 0;
				max-width: 500px;
			}
		}
	}

	&.maxWidth1925 {
		max-width: 1925px;
	}
}
