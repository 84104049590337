.membership-account {
	padding: 20px;
	text-align: center;

	span {
		@include s3();
		text-transform: uppercase;
		margin: 0;
		padding: 0 0 5px;
		color: $vis-blue;
	}

	> h4 {
		@include h4();
		color: $vis-blue;
		padding: 0 0 10px;
		margin: 0;
	}

	p {
		@include font-size(1.2);
		padding: 0 0 10px;
		margin: 0;
	}

	&__rewards {
		@include s3();
		color: $red;
		padding: 0 0 15px;
	}
}
