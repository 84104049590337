.flippable-cards-callout {
	.content-hub-flippable-card {
		&[data-card-count="4"] {
			padding-bottom: inherit;
			margin: 0;
			padding: 0;
			height: auto;

			@include break-min($medium4) {
				margin: 0 20px;
				padding: 0;
			}

			@include break-min($large1) {
				column-gap: 15px;
				row-gap: 15px;
			}

			@include break-min($huge1) {
				column-gap: 25px;
				row-gap: 25px;
				max-width: 550px;
			}

			.scene {
				padding: 5px;

				@include break-min($large1) {
					width: auto;
				}

				&:nth-of-type(3n + 1) {
					@include break-min($large1) {
						order: 1;
					}
				}

				&:nth-of-type(3n + 2) {
					@include break-min($large1) {
						order: 1;
					}
				}

				&:nth-of-type(3n) {
					@include break-min($large1) {
						order: 1;
					}
				}
			}

			.flippable-card {
				width: 100%;
				margin: 0;

				@include break-min($large1) {
				}
			}
		}

		.flippable-card {
			width: 100%;

			@include break-min($huge1) {
				max-width: 252px;
				height: 252px;
				padding-bottom: 0;
			}

			.card {
				justify-content: flex-end;

				@include break-min($large1) {
					padding: 55px 44px 40px;
				}
			}
		}
	}

	.classic-callout {
		display: flex;
		flex-wrap: wrap;
		max-width: 1512px;
		overflow: initial;
		padding-bottom: 32px;

		@include break-min($large1) {
			display: grid;
			grid-template-columns: repeat(12, [col-start] 1fr);
			padding-bottom: 0;
			padding: 0 30px;
		}

		@include break-min($large2) {
			padding: 0 40px;
		}

		@include break-min($huge1) {
			grid-template-columns: repeat(12, [col-start] 1fr);
		}
	}

	.placeholder-container {
		padding: 0 25px;
		order: 2;
		width: 100%;
		height: auto;

		@include break-min($medium1) {
			grid-column: col-start 5 / span 8;
		}

		@include break-min($medium4) {
			grid-column: col-start 7 / span 8;
		}

		@include break-min($large1) {
			padding: 80px 0;
			grid-column: col-start 8 / span 8;
		}

		@include break-min($huge1) {
			padding: 80px 0px;
		}

		@include break-min($large3) {
			grid-column: col-start 8 / span 6;
		}
	}

	.message-container {
		justify-content: center;
		order: 1;
		padding: 32px;
		background: transparent;

		@include break-min($medium1) {
			grid-column: col-start 1 / span 6;
		}

		@include break-min($large1) {
			padding: 15px 50px;
		}

		h2 {
			letter-spacing: -0.8px;
			line-height: 46px;
			@include font-size(3.6);

			@include break-min($large1) {
				line-height: 58px;
				@include font-size(4.8);
			}
		}

		.body-text {
			margin-bottom: 0;
		}
	}
}
