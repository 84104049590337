.cp-reset-pw,
.cp-forgot-pw {
	margin: auto;
	padding: 20px;
	text-align: center;
	margin-bottom: 200px;

	@media only screen and (min-width: $mobile) {
		width: $col6;
	}

	@media only screen and (min-width: $desktop) {
		width: $col5;
	}

	@media screen and (min-width: $lrg-desktop) {
		width: $col4;
	}

	&.full-width {
		width: 100%;
	}

	h3 {
		@include h3();
		color: $blue;
		margin-bottom: 35px;

		@media only screen and (min-width: $mobile) {
			margin-bottom: 38px;
		}
	}

	.message {
		@include p1();

		@media only screen and (min-width: $mobile) {
			margin-bottom: 24px;
		}
	}

	.sub-message {
		@include p2();

		span {
			color: $red;
		}
	}

	.cp-form {
		padding-top: 0;
		margin-bottom: -9px;

		@media only screen and (min-width: $mobile) {
			margin-bottom: 1px;
		}

		.cp-form__group--input,
		p {
			max-width: none;
		}
	}

	.input-instruction {
		&.below {
			margin-bottom: 0;
		}
	}

	.err-msg {
		@include p2();
		color: $red;
		text-align: left;
	}

	.cp-form__group {
		margin: 5px 0 34px;

		@media only screen and (min-width: $mobile) {
			margin: 23px 0 30px;
		}
	}

	.btn {
		width: 100%;

		@media only screen and (min-width: $mobile) {
			width: auto;
		}
	}

	// targeting red btn, first-child
	.btn:not(.white) {
		margin-bottom: 25px;

		@media only screen and (min-width: $mobile) {
			margin-right: 17px;
			margin-bottom: 0;
		}
	}
}
