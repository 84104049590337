.articles-grid {
	display: grid;
	gap: 32px;
	list-style-type: none;
	margin: 0 0 32px;
	padding: 0;

	@include break-min($medium4) {
		column-gap: 24px;
		row-gap: 32px;
	}

	@include break-min($huge4) {
		justify-content: center;
	}

	li {
		grid-column: col-start 1 / span 4;

		@include break-min($medium4) {
			align-items: center;
			grid-column: auto / span 4;
		}

		// @include break-min($large1) {
		// 	grid-column: col-start 1 / span 4;
		// }

		.img-tile-wrapper,
		.video-tile-wrapper {
			border-radius: 32px;
			margin: 0 auto 16px;
			height: 280px;
			overflow: hidden;
		}
	}

	.img-tile,
	.video-tile {
		.subtext {
			> :first-child {
				@include font-size(2.4);
				color: $blue;
				font-weight: $bold-weight;
				letter-spacing: -0.24px;
				line-height: lh(32, 24);
				margin: 10px 0 8px;

				@include break-min($large1) {
					@include font-size(3);
				}
			}

			p {
				color: $grey;
				@include font-size(1.8);
				margin: 0;
			}
		}
	}

	.img-tile {
		.img-tile-wrapper {
			border-radius: 32px;
			margin: 0 auto 16px;
			overflow: hidden;
			position: relative;

			img {
				@include absolute-center();
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}

		.btn-container {
			margin: 18px 0 5px;

			@include break-min($large1) {
				margin: 28px 0 0;
			}
		}
	}

	.video-tile {
		cursor: pointer;
		position: relative;

		.video-tile-wrapper {
			.icon-container {
				@include absolute-center();
				width: 50px;
				height: 50px;
				margin: auto;
				overflow: hidden;
			}

			.play-icon {
				@include absolute-center();
				margin: auto;
				height: auto;
			}

			button {
				background: transparent;
				border: none;
				height: 100%;
				padding: 0;
				width: 100%;
				position: relative;
			}

			img:not(.play-icon) {
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}
	}
}
