.profile-card {
	justify-content: center;
	margin: 0 0 58px 0;

	@include break-min($large1) {
		margin: 0 0 142px 0;
	}

	&.full-width {
		border-radius: 0;
		background-color: $cool-white;

		&.bckgrd-img {
			border-radius: 0;
		}
	}

	&.bckgrd-img {
		border-radius: 32px;

		.padding-wrapper {
			border-radius: 32px;
			margin: 0;
			overflow: hidden;
			padding: 48px 24px;

			@include break-min($large1) {
				@include grid-margin();
				padding: 72px 54px 83px;
				align-items: center;
			}

			@include break-min($huge4) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	&.reverse-order {
		.content-container {
			@include break-min($large1) {
				order: 1;
				padding: 0 69px 0 0;
			}
		}

		.g-img-container {
			@include break-min($large1) {
				order: 2;
			}
		}
	}

	.padding-wrapper {
		@include grid-margin();
		grid-column: col-start 1 / span 4;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large1) {
			display: flex;
			grid-column: col-start 1 / span 12;
		}

		@include break-min($huge4) {
			margin: 0;
		}
	}

	.g-img-container {
		border-radius: 32px;
		height: auto;
		margin: 0 0 25px 0;
		overflow: hidden;

		img {
			height: 100%;
			width: 100%;
		}

		@include break-min($large1) {
			flex: 50%;
			margin: 0;
		}
	}

	.content-container {
		display: flex;
		flex-direction: column;
		margin: 0 0 25px 0;
		gap: 25px;

		@include break-min($large1) {
			flex: 50%;
			padding: 0 0 0 69px;
		}

		.title {
			> :first-child {
				@include font-size(3.6);
				color: $blue;
				font-weight: $bold-weight;
				line-height: lh(46, 36);
				letter-spacing: -1px;
				margin: 0;
			}
		}

		.text {
			display: flex;
			flex-direction: column;
			gap: 25px;

			p {
				@include type-p1-refresh();
				margin: 0;
			}
		}

		.btn-round {
			@include break-min($large1) {
				padding-left: 44px;
				padding-right: 44px;
				min-width: 276px;
			}
		}
	}

	&.justify-center {
		.content-container {
			@include break-min($large1) {
				justify-content: center;
			}
		}
	}

	&.mod-text-centered {

		.text,
		.title,
		.btn-container {
			text-align: center;
		}
	}

	&.mod-red-title {
		h2 {
			color: $red !important;
		}
	}

	&.mod-text-style {
		text-align: center;

		.title {
			h2 {
				font-size: 48px !important;
				color: $red !important;

				@include break-max($medium5) {
					font-size: 28px !important;
					line-height: 125% !important;
					letter-spacing: -1.44px !important;
					color: $red !important;
				}
			}
		}

		.text {
			@include break-max($medium5) {
				padding: 0 80px;
			}

			p {
				color: $grey-p !important;
				@include font-size(2.4);
				font-weight: 700;
				line-height: 125%;

				@include break-max($medium5) {
					@include font-size(2.0);
					line-height: 125%;
					letter-spacing: -0.48px;
				}
			}
		}
	}

	&.mod-no-v-padding {
		.padding-wrapper {
			padding: 48px 0;

			@include break-max($medium5) {
				padding: 48px 32px !important;
			}
		}
	}
}
