.share-link-cta {
	padding: 16px 10px 0;
	position: relative;

	&:before {
		@include dot-border-h(top);
	}

	img {
		align-self: flex-start;
	}

	p {
		@include font-size(1.4);
		color: $grey;
		font-weight: normal;
		letter-spacing: -0.25px;
		line-height: calc(20 / 14);
		margin: 0 0 16px 14px;
		text-align: left;
	}

	.btn-round {
		margin: auto;
		width: auto;
	}
}
