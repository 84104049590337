.rewards-infographic {
	background: $cool-white;
	padding: 40px 24px;

	@include break-min($large1) {
		padding: 80px 48px;
		text-align: center;
	}

	.title {
		> *:first-child {
			@include type-b2();
			color: $blue;
			margin: 0 0 42px;

			@include break-min($large1) {
				margin: 0 0 62px;
			}
		}
	}

	ul {
		display: flex;
		flex-direction: column;
		gap: 32px;
		list-style-type: none;
		padding: 0;
		margin: 0 0 42px;
		text-align: center;

		@include break-min($large1) {
			flex-direction: row;
			gap: 92px;
			margin: 0 auto 62px;
			max-width: 900px;
		}

		li {
			margin: 0 auto;
			max-width: 180px;

			@include break-min($large1) {
				max-width: none;
			}

			.img-container {
				position: relative;

				// tampering w/ img dimensions assuming we are using svg here
				img {
					height: 180px;
					width: 180px;

					@include break-min($large1) {
						height: 222px;
						width: 222px;
					}
				}
			}

			.content {
				margin: 12px auto 0;
				max-width: 229px;
				// Adding extra padding here to centerTxt for numbered images
				padding: 0 0 0 19px;

				@include break-min($large1) {
					margin: 28px 0 0;
				}

				p {
					@include font-size(1.6);
					color: $blue;
					font-weight: $bold-weight;
					line-height: lh(20, 16);
					margin: 0;

					@include break-min($large1) {
						@include type-h3-refresh();
					}
				}
			}
		}
	}

	.btn-container {
		a {
			width: 100%;

			@include break-min($large1) {
				min-width: 240px;
				width: auto;
			}
		}
	}
}
