.header-img-callout {
	&.blue-bckgrd {
		background: $blue;

		.content {
			.btn-container {
				.btn-round {
					@extend .btn-round--white;
				}

				a:nth-of-type(2) {
					display: none;
				}
			}
		}
	}
}
