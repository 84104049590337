.accord-toggle-component {
	.accord-toggle-btn {
		display: flex;
		position: relative;

		&:after {
			content: "";
			background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
			background-size: cover;
			bottom: 0;
			height: 12px;
			width: 12px;
			margin: auto;
			pointer-events: none;
			position: absolute;
			right: 0px;
			top: 0;
			transition: transform 0.2s ease;
		}

		&.expanded {
			&:after {
				transform: rotate3d(1, 0, 0, 180deg);
			}
		}
	}

	.accord-toggle-content {
		display: none;

		li {
			display: flex;
		}
	}

	$border-prop: 2px solid $faint-grey;

	&.manage-payment {
		border-top: $border-prop;
		border-bottom: $border-prop;
		margin-top: 30px;

		.accord-toggle-btn {
			padding: 25px 10px;

			&:after {
				height: 25px;
				width: 25px;
				right: 10px;
			}
		}

		.accord-toggle-content {
			padding-bottom: 25px;
		}
	}
}
