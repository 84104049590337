@mixin btn-hover-effect {
}

@mixin lightbox-close-btn() {
	width: 60px;
	height: 60px;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;

	&::after {
		background-image: url("img/cp-icons/icon-cp_close.svg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 16px;
		height: 16px;
	}

	&:focus {
		outline: 1px dotted $red;
	}
}

// Primary / Secondary hover/active states
@mixin btn-primary-hover-active {
	&:hover,
	&:active {
		background: $btn-hover-grad;
		color: $white;
	}
}

@mixin btn-secondary-hover-active {
	&:hover,
	&:active {
		color: $red;
		outline: $red groove 2px !important;
		border: 1px solid transparent;
		background: $white;
	}
}

// Primary/Secondary buttons focus
@mixin btn-primary-focus {
	&:focus {
		outline: 1px solid $red;
		outline-offset: 2px;
	}
}

@mixin btn-secondary-focus {
	&:focus {
		// outline: $dkred groove 2px !important;
		// box-shadow: 0px 0px 5px 0px $dkred;

		outline: 1px solid $red;
		outline-offset: 0px;
	}
}

@mixin btn-primary-search-focus {
	&:focus {
		outline: none;
		outline-offset: 0;
	}
}
// Primary/Secondary disabled
@mixin btn-disabled($border-color, $background-color, $text-color) {
	&:disabled,
	&.disabled {
		color: $text-color;
		background: $background-color;
		cursor: not-allowed;
		pointer-events: none;

		&:focus {
			outline: none;
		}

		&:hover {
			background: $background-color;
			box-shadow: none;
			transform: none;
		}
	}
}

@mixin btn-hidden {
	&--hidden {
		display: none;
	}
}

@mixin btn-inline-spaced {
	&--inline-spaced {
		width: 100%;
		margin: 0 0 20px 0;

		&:last-child {
			margin: 0;
		}
	}

	@include break-min($small3) {
		&--inline-spaced {
			width: auto;
			margin: 0 20px 0 0;
		}
	}
}

@mixin btn-inline-centered {
	&--inline-centered {
		width: 100%;
		margin: 0 0 20px 0;

		&:last-child {
			margin: 0;
		}
	}

	@include break-min($small3) {
		&--inline-centered {
			width: auto;
			margin: 0 7px;

			&:last-child {
				margin: 0 7px;
			}
		}
	}
}

@mixin btn-stacked {
	&--stacked {
		display: block;
		width: 100%;
		margin: 0 auto 20px auto;

		&:last-child {
			margin: 0 auto;
		}
	}

	@include break-min($small3) {
		&--stacked {
			display: block;
			width: auto;
			margin: 0 auto 20px auto;
		}
	}
}

@mixin btn-clear {
	&--clear {
		box-shadow: none;
		background: transparent;
		color: $red;

		&:hover {
			text-decoration: underline;
			transform: none;
			color: $vis-dkred;
		}
	}
}

@mixin hide-mobile {
	@include break-min($small3) {
		&--hide-mobile {
			display: none;
		}
	}

	@include break-min($large1) {
		&--hide-mobile {
			display: initial;
		}
	}
}

@mixin btn-primary-search-red {
	background: $btn-grad;
	@include btn-round;
	@include btn-primary-hover-active;
	@include btn-primary-search-focus;
	@include btn-disabled($mdgrey, $mdgrey, $white);
}

@mixin btn-primary-red {
	background: $btn-grad;
	@include btn-round;
	@include btn-primary-hover-active;
	@include btn-primary-focus;
	@include btn-disabled($mdgrey, $mdgrey, $white);
}

@mixin btn-primary-red-sm {
	@include btn-primary-red;
	@include btn-round(11px);
}

@mixin btn-secondary-white {
	background: $white;
	outline: 1px solid transparent;
	@include btn-round(16px);
	@include btn-secondary-hover-active;
	@include btn-secondary-focus;
	@include btn-disabled($mdgrey, $white, $mdgrey);
	color: $red;
	border: 1px solid $mdgrey;
}

@mixin btn-secondary-white-sm {
	@include btn-secondary-white;
	@include btn-round(11px);
	color: $red;
	border: 1px solid $mdgrey;
}

// Tracks styles from .btn-round
@mixin btn-round($paddingTop: null) {
	border-radius: 24px;
	color: $white;
	cursor: pointer;
	font-variant-ligatures: none;
	@if ($paddingTop) {
		padding: $paddingTop 19px;
	} @else {
		padding: 17px 19px;
	}
	text-decoration: none !important;
	line-height: 1;
	font-weight: $medium-weight;
	font-family: $primary;
	letter-spacing: -0.5px;
	position: relative;
	// display: block;
	// width: 100%;
	display: inline-block;
	width: auto;
	text-align: center;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:disable no-vendor-prefixes
	-webkit-font-variant-ligatures: none;
	border: 0;
	box-sizing: border-box;
	transition: none !important;
	@include font-size(1.4);
	@include btn-hidden;
	@include btn-inline-spaced;
	@include btn-inline-centered;
	@include btn-stacked;
	@include btn-clear;
	@include hide-mobile;

	* {
		box-sizing: inherit;
	}

	// @include break-min($small3) {
	// 	display: inline-block;
	// 	width: auto;
	// }
}
