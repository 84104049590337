.store-rewards {
	background-color: $light-blue;
	padding: 15px 30px;
	border-radius: 8px;

	a {
		width: 100%;
		justify-content: space-between;
		font-weight: bold;
		color: $blue;
		align-items: center;
		@include flexbox();
		@include font-size(1.2);

		@include break-min($large1) {
			@include font-size(1.3);

			img {
				&:first-child {
					margin-right: 10px;
				}
			}
		}

		@include break-min($large3) {
			@include font-size(1.7);

			img {
				&:first-child {
					margin-right: 20px;
				}
			}
		}
	}
}
