.location-details {
	&-disclaimer {
		margin-top: 0px;
		padding-bottom: 9px;
		@include font-size(1.4);

		@include break-min($large1) {
			margin-top: 32px;
		}

		a {
			&:hover {
				text-decoration: none;
			}
		}
	}
}
