.global-form-error {
	input,
	select,
	textarea {
		border-color: $red;
		color: $red;

		&::placeholder {
			color: $red;
		}
	}

	p,
	label {
		color: $red;
	}
}
