//Please use the below
//The typeStyles have:
//default setting, mobileOnly,
//and desktop settings

//
@mixin type-b1($size: default) {
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -1px;

	@if $size == default {
		@include font-size(4.8);
		line-height: lh(54, 48);

		@include break-min($large1) {
			@include font-size(7.8);
			letter-spacing: -2px;
			line-height: lh(84, 78);
		}
	}

	@if $size == mobileOnly {
		@include font-size(4.8);
		line-height: lh(54, 48);
	}

	@if $size == desktopOnly {
		@include font-size(7.8);
		letter-spacing: -2px;
		line-height: lh(84px, 78px);
	}
}

@mixin type-b2($size: default) {
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -0.8px;

	@if $size == default {
		@include font-size(3.6);
		line-height: lh(46, 36);

		@include break-min($large1) {
			@include font-size(6.4);
			letter-spacing: -1.5px;
			line-height: lh(74, 64);
		}
	}

	@if $size == mobileOnly {
		@include font-size(3.6);
		line-height: lh(46, 36);
	}

	@if $size == desktopOnly {
		@include font-size(6.4);
		letter-spacing: -1.5px;
		line-height: lh(74, 64);
	}
}

@mixin type-h1($size: default) {
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -1px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $size == default {
		@include font-size(4.7);
		line-height: lh(48px, 47px);

		@include break-min($large1) {
			letter-spacing: -2px;
			line-height: lh(84px, 78px);
			@include font-size(6.2);
		}
	}

	@if $size == mobileOnly {
		@include font-size(4.7);
		line-height: lh(48px, 47px);
	}

	@if $size == desktopOnly {
		@include font-size(6.2);
		letter-spacing: -2px;
		line-height: lh(84px, 78px);
	}
}

@mixin type-h2($size: default) {
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -1px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $size == default {
		@include font-size(2.8);
		line-height: lh(34px, 28px);

		@include break-min($large1) {
			@include font-size(3.9);
			letter-spacing: -0.5px;
			line-height: lh(40px, 39px);
		}
	}

	@if $size == mobileOnly {
		@include font-size(2.8);
		line-height: lh(34px, 28px);
	}

	@if $size == desktopOnly {
		@include font-size(3.9);
		letter-spacing: -0.5px;
		line-height: lh(40px, 39px);
	}
}

@mixin type-h3($size: default) {
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $size == default {
		@include font-size(2.2);
		line-height: 1.27;

		@include break-min($large1) {
			@include font-size(2.8);
			letter-spacing: -1px;
		}
	}

	@if $size == mobileOnly {
		@include font-size(2.2);
		line-height: 1.27;
	}

	@if $size == desktopOnly {
		@include font-size(2.8);
		letter-spacing: -1px;
	}
}

@mixin type-h4($size: default) {
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $size == default {
		@include font-size(1.8);
		line-height: 1.25;

		@include break-min($large1) {
			@include font-size(2.2);
		}
	}

	@if $size == mobileOnly {
		@include font-size(1.8);
		line-height: 1.25;
	}

	@if $size == desktopOnly {
		@include font-size(2.2);
	}
}

@mixin type-h5($size: default) {
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $size == default {
		@include font-size(2.2);
		line-height: lh(28px, 22px);

		@include break-min($large1) {
			@include font-size(2.8);
			line-height: lh(34px, 28px);
		}
	}

	@if $size == mobileOnly {
		@include font-size(2.2);
		line-height: lh(28px, 22px);
	}

	@if $size == desktopOnly {
		@include font-size(2.8);
		line-height: lh(34px, 28px);
	}
}

@mixin type-p1($size: default) {
	font-family: $primary;
	font-weight: $medium-weight;
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $size == default {
		@include font-size(1.6);
		line-height: lh(24px, 16px);

		@include break-min($medium1) {
			@include font-size(1.8);
			letter-spacing: -0.56px;
			line-height: lh(32px, 18px);
		}
	}

	@if $size == mobileOnly {
		@include font-size(1.4);
		line-height: lh(20px, 14px);
	}

	@if $size == desktopOnly {
		@include font-size(1.8);
		letter-spacing: -0.56px;
		line-height: lh(32px, 18px);
	}
}

@mixin type-p2($size: default) {
	font-family: $primary;
	font-weight: $regular-weight;
	letter-spacing: -0.25px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $size == default {
		@include font-size(1.4);
		line-height: lh(20px, 14px);

		@include break-min($medium1) {
			@include font-size(1.6);
			letter-spacing: -0.5px;
			line-height: lh(24px, 16px);
		}
	}

	@if $size == mobileOnly {
		@include font-size(1.4);
		line-height: lh(20px, 14px);
	}

	@if $size == desktopOnly {
		@include font-size(1.6);
		letter-spacing: -0.5px;
		line-height: lh(24px, 16px);
	}
}

@mixin type-p3($size: default) {
	font-family: $primary;
	font-weight: $regular-weight;
	letter-spacing: normal;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $size == default {
		@include font-size(1.2);
		line-height: lh(16px, 12px);

		@include break-min($medium1) {
			@include font-size(1.4);
			letter-spacing: -0.5px;
			line-height: lh(20px, 14px);
		}
	}

	@if $size == mobileOnly {
		@include font-size(1.2);
		line-height: lh(16px, 12px);
	}
}

@mixin type-p4($size: default) {
	@include font-size(1.2);
	font-family: $primary;
	font-weight: $regular-weight;
	letter-spacing: normal;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
	line-height: lh(16px, 12px);
}

@mixin type-label1() {
	@include font-size(1.1);
	font-family: $primary;
	font-weight: $regular-weight;
	text-transform: uppercase;
	line-height: lh(16px, 12px);
	letter-spacing: 1.55px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@include break-min($large1) {
		@include font-size(1.7);
	}
}

@mixin type-label2() {
	@include font-size(1.7);
	font-family: $secondary;
	font-weight: $regular-weight;
	letter-spacing: 1.55px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
	text-transform: uppercase;
}

@mixin type-label3() {
	@include font-size(1.2);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
	font-family: $primary;
	font-weight: $regular-weight;
	letter-spacing: 1.55px;
	line-height: lh(16px, 12px);
	text-transform: uppercase;
}

@mixin s2($size: default) {
	font-family: $secondary;
	font-weight: $bold-weight;
	letter-spacing: 2px;

	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
	@if ($size == default) {
		@include font-size(1.1);
		line-height: lh(12px, 11px);

		@include break-min($medium1) {
			@include font-size(1.7);
			letter-spacing: 4px;
			line-height: 1;
		}
	}

	@if ($size == mobileOnly) {
		@include font-size(1.1);
		line-height: lh(12px, 11px);
	}
}

@mixin s3() {
	@include font-size(1.1);
	font-family: $secondary;
	font-weight: $bold-weight;
	letter-spacing: 2px;
	line-height: lh(12px, 11px);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}
