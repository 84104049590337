.social-icon-signin {
	margin: 0 20px 20px 0;
	padding: 8px 0px;
	width: 100%;
	//width: 300px; //Kareem's
	@media screen and (max-width: $mobile) {
		width: 100%;
	}

	&.btn-facebook {
		background: #3d5998;
	}

	&.btn-google {
		background: #4285f4;

		img {
			margin-left: -14px; //For centering w/ Facebook link below
		}
	}

	//icon-image
	img {
		height: 35px;
		margin-right: 15px;
	}
}
