.store-job-opportunities {
	display: none;
	width: 100%;
	text-align: center;

	&.active {
		display: block;
	}

	@include break-min($large1) {
		width: auto;
		text-align: left;
	}

	.btn-clear {
		font-weight: 600;
		margin: 0;
		border-radius: 24px;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
		padding: 15px 19px;
		background-color: $white;
		display: block;

		@include break-min($large1) {
			background-color: transparent;
			box-shadow: none;
		}
	}
}
