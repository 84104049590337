.covid-accordion {
	@extend .delivery-accordion;

	h1 {
		margin-bottom: 17px;

		@include break-min($large1) {
			margin-bottom: 66px;
		}
	}

	.accordion-body {
		border-bottom: 0;
		margin-top: 0;

		.toggle {
			border-top: 0;
			letter-spacing: -0.01em;
			padding: 21px 36px 17px 16px;

			@include break-min($large1) {
				cursor: crosshair;
				letter-spacing: -0.5px;
				padding: 0 0 24px 0;
				pointer-events: none;
			}

			&:after {
				font-size: 1.4rem;

				@include break-min($large1) {
					display: none;
				}
			}

			&:not(:first-child) {
				border-top: 1px solid $faint-grey;

				@include break-min($large1) {
					border-top: 0;
				}
			}
		}

		p {
			@include break-min($large1) {
				@include font-size(1.8);
				color: $grey;
				font-weight: $regular-weight;
				letter-spacing: -0.5px;
				line-height: lh(28, 18);
			}
		}

		section {
			@include break-min($large1) {
				margin-bottom: 35px;
			}
		}
	}
}
