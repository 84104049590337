.signature-drop-autocomplete-menu {
	background: $white;
	z-index: 1000;

	li {
		padding: 10px;

		&:first-child {
			background: $faint-grey;
		}
	}
}
