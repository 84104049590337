.swap-order {
	@include break-min($medium4) {
		div {
			&:first-of-type {
				grid-column: col-start 1 / span 4;
			}
			&:nth-of-type(2) {
				grid-column: col-start 5 / span 4;
			}
		}
	}
	@include break-min($large4) {
		div {
			&:first-of-type {
				grid-column: col-start 1 / span 6;
			}
			&:nth-of-type(2) {
				grid-column: col-start 7 / span 6;
			}
		}
	}
}
