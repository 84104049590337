.care-card {
	background: $red;
	color: $white;
	border-radius: 16px;
	margin: 0 0 58px;
	position: relative;
	z-index: 100;

	@include break-min($large1) {
		@include grid-margin();
		border-radius: 32px;
		display: grid;
		justify-content: center;
		margin: 0 0 142px;
		width: fit-content;
	}

	@include break-min($huge4) {
		margin: 0 auto 142px;
	}

	.grid-wrapper {
		grid-column: col-start 1 / span 4;
		padding: 58px 24px;

		@include break-min($medium4) {
			align-items: center;
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large1) {
			display: flex;
			grid-column: col-start 1 / span 12;
			padding: 116px 87px 99px;
		}
	}

	.g-img-container {
		display: flex;
		height: auto;
		justify-content: center;
		margin: 0 0 46px 0;
		width: auto;

		@include break-min($large1) {
			flex: 50%;
			margin: 0 20px 0 0;
		}
	}

	.content {
		@include break-min($large1) {
			flex: 50%;
		}

		p {
			@include font-size(1.8);
			font-weight: 500;
			line-height: lh(28, 18);
			letter-spacing: -0.5px;
			margin: 0;
		}
	}
}
