.content-hub-tags {
	display: flex;
	align-items: center;
	margin: 0 32px 0;
	justify-content: center;

	&-wrapper {
		display: flex;
		align-items: baseline;
		margin: 0 20px;

		@include break-min($medium4) {
			align-items: center;
			margin: 0;
		}

		.tag-label {
			margin-right: 12px;
			min-width: 80px;
		}

		ul {
			list-style: none;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			margin: 0;

			li {
				margin: 5px;
			}
		}

		.tag-button {
			@include btn-tertiary;
		}
	}
}
