// sass-lint:disable no-ids
// sass-lint:disable max-file-line-count

// Global form elements
// ==========================================================================

.g-form {
	position: relative;
	text-align: left;
	padding: 30px 0;
	box-sizing: border-box;

	* {
		box-sizing: inherit;
	}

	&--flex {
		@include flexbox;
		@include align-items(flex-start);
		@include justify-content(stretch);
		@include flex-wrap(wrap);

		.gform__spacer {
			@include flex(0 0 10px);
			min-height: 1px;
		}

		.g-form__group {
			max-width: 100%;
			width: auto;
			@include flex(1 1 100%);

			&--inline {
				@include flex(1 1 40%);
			}

			&--small {
				@include flex(1 1 40%);
				max-width: 120px;
			}
		}
	}

	&--no-pad {
		padding: 0;
	}

	fieldset {
		border: 0;
		padding: 0;
		margin: 0;

		legend {
			color: $vis-grey;
			display: block;
			margin: 0;
			padding: 0 0 35px;
			@include p1();
		}
	}

	section {
		position: relative;
		padding: 30px 0 40px;
	}

	p {
		@include p2();
		color: $light-grey;
		padding: 10px 0 0;
		margin: 0;
		font-weight: $medium-weight;
		max-width: 340px;
		display: block;

		&.error {
			color: $red;
		}
	}

	h3 {
		color: $vis-grey;
		display: block;
		margin: 0;
		padding: 0;
		@include h5();
	}

	h5 {
		color: $vis-grey;
		display: block;
		margin: 0;
		max-width: 100%;
		padding: 40px 0 0;
		@include p1();
	}

	label {
		@include p2();
		font-weight: $medium-weight;
		color: $light-grey;
		pointer-events: none;
	}

	input {
		&[type="password"],
		&[type="text"],
		&[type="tel"],
		&[type="number"],
		&[type="date"],
		&[type="email"] {
			@include p1();
			@include font-size(1.6);
			@include appearance(none);
			color: $vis-grey;
			padding: 22px 15px 0 15px;
			border: 3px solid $vis-grey;
			border-radius: 6px;
			display: block;
			height: 68px;
			width: 100%;

			&:focus {
				border-color: $vis-blue;
				outline: 1px dotted $red;
				outline-offset: 3px;
			}

			&:disabled {
				background-color: $concrete;
				border-color: $mdgrey;

				&::placeholder {
					color: $vis-grey;
				}
			}

			&::placeholder {
				color: $light-grey;
			}
		}

		&[type="number"],
		&[type="date"] {
			@include appearance(textfield);
		}
	}

	textarea {
		@include p1();
		@include font-size(1.6);
		@include appearance(none);
		color: $vis-grey;
		padding: 13px 15px 0 15px;
		border: 3px solid $vis-grey;
		border-radius: 6px;
		display: block;
		min-height: 96px;
		max-height: 200px;
		width: 100%;

		&:focus {
			border-color: $vis-blue;
			outline: 1px dotted $red;
			outline-offset: 3px;
		}

		&:disabled {
			background-color: $concrete;
			border-color: $mdgrey;
		}
	}

	.g-form__small-label {
		color: $grey;
	}

	&__spacer {
		height: 0;
		padding: 40px 0 0;

		&--dotted {
			position: relative;
			padding: 45px 0 0;
			margin: 0 0 45px;

			&::after {
				@include dot-border-h();
			}
		}
	}

	&__group {
		padding: 0;
		margin: 40px 0 0;
		background-color: $white;
		position: relative;

		&:first-child {
			margin: 0;
		}

		> span {
			@include s3();
			text-transform: uppercase;
			color: $vis-grey;
			position: absolute;
			right: 0;
			left: 0;
			bottom: 100%;
			padding: 0 0 10px;
			display: block;
		}

		&--input {
			max-width: 340px;

			label {
				position: absolute;
				top: 10px;
				left: 18px;
			}
		}

		&--margin {
			&-less {
				margin: 30px 0 0;
			}

			&-least {
				margin: 20px 0 0;
			}
		}

		&--editable {
			padding: 0 80px 0 0;
			max-width: 420px;

			button,
			a {
				position: absolute;
				top: 17px;
				right: 0;
			}
		}

		&--inline {
			width: 100%;
			display: block;

			&-centered {
				width: 100%;
				display: block;
				text-align: center;
			}
		}

		&--small {
			width: 100%;
			max-width: 150px;
		}

		&--smallest {
			width: 100%;
			max-width: 95px;
		}

		&--disabled {
			label {
				color: $vis-grey;
			}
		}

		&--date {
			&::before {
				content: "";
				background-image: url("img/cp-icons/icon-cp_calendar.svg");
				width: 25px;
				height: 25px;
				background-size: cover;
				position: absolute;
				top: 0;
				right: 17px;
				bottom: 0;
				margin: auto;
				pointer-events: none;
			}
		}

		&--centered {
			margin-right: auto;
			margin-left: auto;
		}

		&--full-width {
			width: 100%;
			min-width: 100%;
		}

		&.g-form__group--error {
			label,
			p {
				color: $red;
			}

			.g-form__small-label {
				color: $light-grey;
			}

			select {
				color: $red;
			}

			input,
			select,
			textarea {
				border-color: $red;

				&::placeholder {
					color: $red;
				}
			}
		}
	}

	&__select {
		margin: 40px 0 0;
		position: relative;

		label {
			position: absolute;
			top: 10px;
			left: 18px;
			@include p2();
			font-weight: $medium-weight;
			color: $light-grey;
			pointer-events: none;
		}

		select {
			@include p1();
			@include appearance(none);
			color: $vis-grey;
			padding: 22px 60px 0 15px;
			display: block;
			height: 68px;
			width: 100%;
			cursor: pointer;
			background-color: transparent;
			background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
			background-size: 24px 24px;
			background-position: bottom 18px right 19px;
			background-repeat: no-repeat;
			border: 3px solid $vis-grey;
			border-radius: 6px;

			&::-ms-expand {
				display: none;
			}

			&:focus {
				border-color: $vis-blue;
				outline: 1px dotted $red;
				outline-offset: 3px;
			}

			&:disabled {
				background-color: $concrete;
				border-color: $mdgrey;
				cursor: not-allowed;
			}
		}

		&--disabled {
			label {
				color: $vis-grey;
			}
		}

		&--margin-least {
			margin: 20px 0 0;
		}
	}

	&__inline-btn {
		@include p1();
		font-weight: $medium-weight;
		display: inline-block;
		color: $red;
		cursor: pointer;
		position: relative;
		padding: 0;
		appearance: none;
		border: 0;
		background: transparent;

		&--add {
			padding: 0 0 0 20px;

			&::after {
				content: "";
				background-image: url("img/cp-icons/icon-cp_plus.svg");
				width: 14px;
				height: 14px;
				background-size: cover;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				margin: auto;
			}
		}

		&:hover {
			color: $dkred;
			text-decoration: underline;
		}

		&:focus {
			outline: 1px dotted $red;
			outline-offset: 3px;
		}
	}

	&__radio {
		margin: 0 0 20px;
		position: relative;

		&:last-child {
			margin: 0;
		}

		&-cta {
			padding: 13px 0 15px 45px;
		}

		&--disabled {
			img {
				filter: grayscale(100%);
				opacity: 0.3;
			}
		}

		label {
			@include p1();
			color: $vis-grey;
			margin: 0;
			padding: 0 0 0 45px;
			position: relative;
			cursor: pointer;
			pointer-events: initial;
			display: inline-block;
			z-index: $z2;

			&::before {
				content: "";
				margin: auto;
				display: block;
				width: 18px;
				height: 18px;
				background-color: $white;
				border: 1px solid $light-grey;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				border-radius: 50%;
			}
		}

		input {
			border: 0;
			padding: 0;
			margin: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
			pointer-events: none;

			&:hover {
				+ label {
					&::before {
						background-color: lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:focus {
				+ label {
					outline-offset: 3px;
					outline: 1px dotted $red;

					&::before {
						box-shadow: 0 0 0 3px lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:checked {
				+ label {
					&::before {
						background-color: $white;
						border-color: $red;
					}

					&::after {
						content: "";
						position: absolute;
						background-color: $red;
						left: 3px;
						top: 0;
						bottom: 0;
						margin: auto;
						width: 14px;
						height: 14px;
						border-radius: 50%;
					}
				}
			}

			&:disabled {
				+ label {
					cursor: not-allowed;

					&::before {
						opacity: 0.5;
						background-color: $white;
						border-color: $light-grey;
						box-shadow: none;
					}
				}
			}
		}

		a {
			@include p1();
			position: relative;
			padding: 0 10px 0 0;
			display: inline-block;
			font-weight: $medium-weight;

			&:hover {
				text-decoration: underline;
			}

			+ a {
				padding: 0 10px;

				&::before {
					content: "|";
					font-weight: $regular-weight;
					color: $vis-grey;
					left: -4px;
					position: absolute;
					top: 0;
					bottom: 0;
				}
			}
		}
	}

	@include break-min($small4) {
		&__group {
			&--margin {
				&-left {
					margin: 0 0 0 20px;
				}
			}

			&--inline {
				display: inline-block;
			}

			&--editable {
				button,
				a {
					left: calc(100% - 65px);
					right: auto;

					&:focus {
						font-weight: $medium-weight;
					}
				}
			}
		}
	}
}

// global error messaging
.g-messaging {
	span {
		@include p2();
		display: block;
		color: $red;
		font-weight: $medium-weight;
		padding: 20px 0 0;
		margin: 0;
	}

	p {
		@include p2();
		background-color: $lred;
		border-radius: 8px;
		color: $vis-grey;
		padding: 20px 20px 20px 95px;
		font-weight: $medium-weight;
		margin: 35px 0 0;
		position: relative;

		&::before {
			display: block;
			position: absolute;
			background-image: url("img/cp-icons/icon-cp_warning.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			top: 0;
			left: 35px;
			bottom: 0;
			margin: auto;
			width: 36px;
			height: 36px;
			content: "";
		}
	}
}

// payment options
.g-payment {
	padding: 0 0 50px;

	&__cards {
		padding: 30px 0;
		max-width: 460px;

		.g-payment__radio {
			min-height: 42px;

			label {
				@include flex(1 1 auto);
			}

			&.default-payment {
				display: flex;

				div:first-child {
					width: 30px;
				}

				.managePayRow {
					padding: 10px;
					margin-top: -20px;

					.cp-checkbox p {
						margin-left: 37px;
					}
				}
			}
		}
	}

	fieldset {
		border: 0;
		padding: 0;
		margin: 0;

		legend {
			color: $vis-grey;
			display: block;
			margin: 0;
			padding: 0 0 35px;
			@include p1();
		}
	}

	&__radio {
		margin: 0 0 20px;
		position: relative;

		&:last-child {
			margin: 0;
		}

		&--disabled {
			img {
				filter: grayscale(100%);
				opacity: 0.3;
			}
		}

		label {
			@include p2();
			color: $vis-grey;
			margin: 0;
			padding: 0 0 0 80px;
			position: relative;
			cursor: pointer;
			display: inline-block;
			pointer-events: initial;
			z-index: $z2;

			&::before {
				content: "";
				margin: auto;
				display: block;
				width: 18px;
				height: 18px;
				background-color: $white;
				border: 1px solid $light-grey;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				border-radius: 50%;
			}
		}

		input {
			border: 0;
			padding: 0;
			margin: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
			pointer-events: none;

			&:hover {
				+ label {
					&::before {
						background-color: lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:focus {
				+ label {
					outline-offset: 3px;
					outline: 1px dotted $red;

					&::before {
						box-shadow: 0 0 0 3px lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:checked {
				+ label {
					&::before {
						background-color: $white;
						border-color: $red;
					}

					&::after {
						content: "";
						position: absolute;
						background-color: $red;
						left: 3px;
						top: 0;
						bottom: 0;
						margin: auto;
						width: 14px;
						height: 14px;
						border-radius: 50%;
					}
				}
			}

			&:disabled {
				+ label {
					cursor: not-allowed;

					&::before {
						opacity: 0.5;
						background-color: $white;
						border-color: $light-grey;
						box-shadow: none;
					}
				}
			}
		}

		p {
			@include p2();
			font-weight: $medium-weight;
			text-align: right;
			color: $light-grey;
			display: inline-block;
			float: right;
			margin: 0;
			padding: 0;

			span {
				@include p2();
				font-weight: $medium-weight;
				color: $red;
				cursor: pointer;
				display: inline-block;
				padding: 9px 5px;

				&:hover {
					color: $dkred;
				}
			}
		}

		.default-pay {
			@include type-p3();
			color: $blue;
			float: none;
			font-weight: $medium-weight;
			margin-left: 33px;
			padding-top: 15px;
			text-align: left;

			@include break-min($small2) {
				margin-left: 46px;
			}
		}

		img {
			display: block;
			width: 35px;
			height: auto;
			// border-radius: 3px;
			position: absolute;
			top: 0;
			left: 33px;
			bottom: 0;
			//margin: auto;
			margin-top: 5px;
			z-index: $z1;
		}
	}

	.btns-wrapper {
		position: relative;
		width: 100%;

		@include break-min($small3) {
			display: inline-block;
			width: auto;
		}
	}

	.btn-round {
		//margin: 24px 0 0 0;
		width: 100%;
	}

	.paypal-wrapper {
		@include flexbox;
		@include align-items(center);
		@include justify-content(center);
		@include flex-wrap(wrap);
		margin: 24px 0 0 0;

		.paypal-container {
			display: block;
			margin: 0 0 15px;
			@include flex(0 0 auto);
			width: 100%;
		}

		@include break-min($small3) {
			@include justify-content(flex-start);

			.paypal-container {
				display: inline-block;
				width: 100%; //controlled by Add credit card btn auto width
				margin: 0 10px 0 0;
			}
		}

		#braintree-paypal-button {
			border-radius: 24px;
			transition: transform 0.2s ease, box-shadow 0.2s ease;

			&:hover {
				transform: translateY(-1px);
				box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
			}

			&:focus {
				outline: 1px dotted $red;
				outline-offset: 3px;
			}
		}
	}

	.paypal-link {
		@include link-underline();
		display: inline-block;
		margin: auto;

		@include break-min($small3) {
			bottom: 7px;
			left: 228px;
			margin-top: 0;
			position: absolute;
			width: 100%;
		}
	}

	@include break-min($small2) {
		&__radio {
			label {
				padding: 0 0 0 120px;
			}

			img {
				width: 50px;
				left: 45px;
			}
		}
	}

	@include break-min($small4) {
		&__radio {
			p {
				@include p1();
				font-weight: $medium-weight;

				span {
					@include p1();
					font-weight: $medium-weight;
					padding: 3px 5px;
				}
			}
		}
	}
}

// global checkboxes
.g-checkbox {
	padding: 20px 0 0 0;
	margin: 0;
	position: relative;

	label {
		@include p2();
		color: $vis-grey;
		margin: 0;
		padding: 0 0 0 30px;
		position: relative;
		cursor: pointer;
		display: inline-block;
		pointer-events: auto;
		z-index: $z2;

		&::before {
			content: "";
			margin: 0;
			display: block;
			width: 16px;
			height: 16px;
			background-color: $white;
			border: 2px solid $light-grey;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			border-radius: 4px;
		}

		&::after {
			content: "";
			position: absolute;
			width: 12px;
			height: 12px;
			top: 4px;
			left: 4px;
		}
	}

	input {
		border: 0;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
		pointer-events: none;

		&:hover {
			+ label {
				&::before {
					background-color: lighten($red, 50%);
					border-color: $red;
				}
			}
		}

		&:focus {
			+ label {
				&::before {
					box-shadow: 0 0 0 3px lighten($red, 50%);
					border-color: $red;
				}
			}
		}

		&:checked {
			+ label {
				&::before {
					background-color: $red;
					border-color: $red;
				}

				&::after {
					// sass-lint:disable-block max-line-length
					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNSAxNSI+ICAgIDx0aXRsZT5jaGVja21hcms8L3RpdGxlPiAgICA8cGF0aCBjbGFzcz0iYSIgZmlsbD0id2hpdGUiIGQ9Ik02LDEzLjI3MjVjLS4wMTMyLDAtLjAyNjQsMC0uMDQtLjAwMWExLjQ5NTgsMS40OTU4LDAsMCwxLTEuMTU0OC0uNTkyOGwtNC01LjI3MmExLjUsMS41LDAsMSwxLDIuMzktMS44MTM1TDYuMDY0LDkuMzc1bDUuNzkwNS02Ljg0MzdhMS41LDEuNSwwLDAsMSwyLjI5MSwxLjkzNzVsLTcsOC4yNzI1QTEuNDk4LDEuNDk4LDAsMCwxLDYsMTMuMjcyNVoiLz48L3N2Zz4=");
				}
			}
		}

		&:disabled {
			+ label {
				opacity: 0.5;
				color: $vis-grey;
				cursor: not-allowed;

				&::before {
					box-shadow: none;
					background-color: $white;
					border-color: $light-grey;
				}
			}
		}
	}

	&--med-text {
		padding: 15px 0 0 0;

		label {
			padding: 0 0 0 35px;
			@include p1();

			&::before {
				top: 5px;
			}

			&::after {
				top: 9px;
			}
		}

		p {
			@include p2();
			color: $light-grey;
			max-width: 560px;
			padding: 8px 0 10px 35px;
		}
	}

	&__label {
		@include type-p3();
		color: $vis-grey;

		&.top-padding {
			padding-top: 20px;
		}
	}

	&__group {
		margin: 40px 0 0;
	}
}
