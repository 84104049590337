.quote-carousel-wrapper-content {
	margin-bottom: 48px;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	max-width: 1512px;

	@include break-max($huge3) {
		padding: 0 72px;
	}

	@include break-min($huge3) {
		padding: 0px;
	}

	@include break-max($medium2) {
		padding: 0 32px;
	}

	.quote-carousel {
		background: $cool-white;
		border-radius: 32px;

		@include break-max($medium2) {
			padding: 0 16px;
		}
	}

	.carousel-item {
		height: 100%;

		.carousel-item-content {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			.quote-module {
				@extend .quote-module;
				margin: 0;

				.quote-wrapper {
					background: none;

					@include break-min($huge4) {
						grid-template-columns: repeat(12, [col-start] 1fr);
					}

					.quote-container {
						padding: 48px 58px 0;
						margin: 0;

						p {
							margin: 0 0 48px;
							&:first-of-type {
								&:after {
									content: "”";
									bottom: -30px;
									right: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.slick-dots {
		position: inherit;
		padding-bottom: 38px;
		margin-top: 0px;

		@include break-min($medium4) {
			margin-top: 0px;
		}

		li {
			margin-right: 15px;

			button {
				opacity: 0.5;
				// sass-lint:disable variable-for-property
				background-color: $blue;
				// sass-lint:enable variable-for-property
				border: none;
				border-radius: 100%;
				display: block;
				height: 1.5rem;
				padding: 0;
				text-indent: -9999px;
				width: 1.5rem;

				&:focus {
					outline: 1px dotted blue;
				}

				&:before {
					opacity: 0.5;
					// sass-lint:disable variable-for-property
					color: $blue;
					// sass-lint:enable variable-for-property
				}
			}

			&.slick-active button {
				opacity: 1;
				background: $red;
			}

			&.slick-active button:before {
				opacity: 1;
				color: $red;
			}
		}
	}

	.g-carousel-grid__nav--prev {
		left: 28px !important;
	}

	.g-carousel-grid__nav--next {
		right: 28px !important;
	}
}
