// sass-lint:disable no-ids
// sass-lint:disable max-file-line-count

@import "location-tools";

.header-frame {
	background-color: $white;
	height: 120px;
	max-height: $nav-base-height;
	max-width: none;
	overflow: visible;
	padding: 0;
	position: fixed;
	top: 0;
	transition: max-height 1.5s ease, top 0.5s ease;
	width: 100%;
	z-index: 3000;

	.home &,
	.press-landing & {
		background-color: $white;
		height: auto;
		max-height: none;
	}

	&.hovered {
		max-height: 112px;
		transition: max-height 0.5s ease;

		.home & {
			max-height: none;
		}

		.about & {
			background-color: $white;
		}
	}

	@media only screen and (max-width: $mobile) {
		height: 100%;

		.about & {
			height: 60px;
		}
	}

	&.m-lock {
		background-image: none;
		background-color: $white;
		transition: background-color 0.5s ease;

		.about & {
			background-color: $white;
			transition: background-color 0.5s ease;
		}
	}

	.about & {
		@include background($nav-grad2);
		background-color: transparent;
		transition: background-color 0.75s ease;

		&.focused {
			background-color: $white;
			background-image: none;
			transition: background-color 0.75s ease;

			.subnav {
				background-color: $white;
				background-image: none;
				transition: background-color 0.75s ease;
			}
		}

		.subnav {
			@include background($nav-grad3);
		}
	}

	@media only screen {
		&.lock {
			background-color: $white;

			.about & {
				background-image: none;
				background-color: $white;
				transition: background-color 0.75s ease;
			}
		}
	}

	.content-hide {
		display: none;
	}
}

header {
	background-color: $white;
	color: $grey;
	font-weight: 300;
	margin: 0 auto;
	padding: 0;
	width: 100%;
	z-index: 400;

	@media only screen and (max-width: $mobile) {
		background-color: $white;

		.home & {
			.home-teaser & {
				top: 0;
			}
		}
	}

	.about & {
		background-color: transparent;
		transition: background-color 0.75s ease;

		@media only screen and (max-width: $mobile) {
			padding-bottom: 5px;
		}
	}

	.nav-shown & {
		background-color: $white;
		height: 100%;
		top: 0;
	}

	.icon-close {
		@include font-size(2.1);
		@include link-underline();

		&:hover {
			cursor: pointer;
		}

		&:focus {
			outline-color: -webkit-focus-ring-color;
			outline-style: auto;
			outline-width: 5px;
		}
	}

	&.lock {
		max-width: none;
		padding-left: 0;
		padding-right: 0;
		position: fixed;
		top: 0;
		width: 100%;

		.nav-frame {
			margin: 0 auto;

			@media only screen and (min-width: $desktop) {
				max-width: $main;
				width: $main;
			}

			@media only screen and (max-width: $desktop) {
				max-width: $bp1;
				width: $bp1;
			}

			@media only screen and (max-width: $sml-desktop) {
				max-width: $bp2;
				width: $bp2;
			}
		}
	}

	@media only screen and (max-width: $mobile) {
		height: auto;
		max-width: none;
		padding: 0;
		top: 0;
		width: 100%;
		z-index: 1000;
	}

	&.expanded {
		height: 110px;
	}

	.nav-frame {
		@media only screen and (max-width: $mobile) {
			height: 100%;
			position: relative;
			transition: top 0.5s ease;
		}
	}
}

.header-components {
	@include flexbox();
	@include align-items(center);
	@include justify-content(flex-start);
	@include flex-wrap(wrap);
	height: $nav-base-height;
	flex-wrap: nowrap;
	position: relative;
	background-color: $white;
	z-index: 300;
	//padding: 0 16px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

	.order-now {
		@include font-size(1.5);
		font-weight: $bold-weight;
		line-height: 18px;
		position: absolute;
		right: 18px;
		text-align: center;
		width: 50px;

		&:not(.cta) {
			@include break-min($large1) {
				display: none;
			}
		}
	}

	@include break-min($large1) {
		padding: 0 24px;
	}

	@media only screen and (max-width: $mobile) {
		.nav-shown & {
			box-shadow: none;

			&::after {
				background-color: $white;
				bottom: 0;
				box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
				content: "";
				height: $nav-base-height;
				left: 0;
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 10;
			}
		}
	}
}

.logo {
	@include break-max($large1) {
		margin: auto;
	}

	background-color: $white;
	border: 0;
	color: $red;
	position: relative;
	text-decoration: none;
	z-index: 300;
	height: 50px;
	width: 50px;
	@include flex(0 0 50px);

	@include break-min($large1) {
		width: 68px;
		@include flex(0 0 68px);

		// line
		&::before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 1px;
			height: 48px;
			margin: auto;
			background-color: $mdgrey;
		}
	}

	&::before {
		content: "";
	}

	// logo
	&::after {
		content: "";
		background-image: url("img/icon_circle-cfa-logo.svg");
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 50px;
		transition: transform 0.3s ease;
	}

	&:hover {
		&::after {
			transform: scale(1.03);
		}
	}

	span {
		@include visuallyhidden();
	}
}

.location-search-frame {
	display: none;

	@media only screen and (max-width: $mobile) {
		background-color: $white;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
}

.location-form {
	@include flexbox();
	@include align-items(center);
	@include justify-content(stretch);
	@include flex-wrap(wrap);
	z-index: 2000;
	background-color: $white;
	margin-top: 0;
	padding: 15px 0 0;
	top: 0;
	width: 100%;

	@media only screen and (max-width: $mobile) {
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	}

	@include break-min($large1) {
		padding: 0;
	}

	.or {
		display: none;

		@include break-min($large1) {
			display: block;
			@include flex(0 0 auto);
		}
	}

	button {
		@include font-size(1.8);
		background-color: transparent;
		border: 0;
		color: $red;
		position: absolute;
		top: 0;
		right: 17px;
		bottom: 0;
		padding: 0;
		width: 43px;

		span {
			@include visuallyhidden();
		}
	}

	.left {
		text-align: center;
		@include flex(1 1 100%);

		@media only screen and (max-width: $mobile) {
			@include order(2);
		}

		@include break-min($large1) {
			@include flex(0 0 auto);
			@include flexbox();
			@include align-items(center);
			@include justify-content(stretch);
			@include flex-wrap(nowrap);
		}
	}

	.btn {
		border-radius: 24px;
		line-height: 1;
		font-weight: $medium-weight;
		font-family: $primary;
		@include font-size(1.8);
		position: relative;
		padding: 15px 19px 15px 44px;

		&:hover {
			text-decoration: none;
			transform: translateY(-1px);
			transition: all 0.3s ease-in-out;
		}

		.icon-location-off {
			position: absolute;
			top: 0;
			bottom: 0;

			&:before {
				color: $white;
			}
		}
	}

	.location-search {
		margin: 0 0 20px 0;
		padding: 0 16px;
		position: relative;
		@include flex(1 1 100%);

		@include break-min($large1) {
			@include flex(1 0 auto);
			margin: 0;
		}
	}

	#my-location-button-form {
		border: 0;
	}

	label {
		@include visuallyhidden();
	}

	input {
		&[type="text"] {
			appearance: none;
			box-shadow: none;
			border: 2px solid $cool-white;
			border-radius: 5px;
			background-color: $white;
			font-family: $primary;
			padding: 10px 45px 10px 10px;
			width: 100%;

			@include placeholder {
				font-family: $primary;
			}

			&:focus {
				outline: none;
				border-color: $mdgrey;
			}
		}
	}

	.icon-close {
		color: $red;
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		margin: 0;
		padding: 0;
		width: 64px;
		height: 64px;

		&::before {
			content: none;
		}

		// burger
		&::after {
			content: "";
			background-image: url("img/icon_close.svg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 16px;
			height: 16px;
			transition: transform 0.3s ease;
		}

		&:focus {
			outline: 1px dotted $red;
			outline-offset: 2px;
		}
	}
}

.icon-location-off,
.icon-location-on {
	&:before {
		@include font-size(1.9);
		color: $red;
		display: inline-block;
		vertical-align: text-top;
	}
}

.distance {
	@include font-size(1.2);
	display: inline-block;
	line-height: 20px;
	margin-right: 8px;
	vertical-align: middle;
}

.site-search {
	border-radius: 5px;
	display: inline-block;
	width: 24px;

	@media only screen and (max-width: $mobile) {
		position: relative;
		border: 2px solid $faint-grey;
		box-sizing: border-box;
		padding: 0;
		width: 100%;
	}

	label {
		@include visuallyhidden();
	}

	button:not(.js-yext-submit) {
		background-color: transparent;
		border: 0;
		color: $red;
		padding: 0;

		span {
			@include visuallyhidden();
		}

		@media only screen and (max-width: $mobile) {
			display: none;
			width: 48px;
			height: 48px;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			margin: auto;
		}
	}

	.yext-search-form {
		@include break-min($large1) {
			display: none;
		}
	}

	input {
		&[type="text"] {
			border: 0;
			appearance: none;
			box-shadow: none;

			@media only screen and (min-width: $lrg-tablet) {
				background-color: transparent;
				box-sizing: border-box;
				display: none;
				padding: 10px 40px 8px 20px;
				width: 100%;
			}

			@media only screen and (max-width: $mobile) {
				@include font-size(1.6);
				border: 2px solid $cool-white;
				border-radius: 5px;
				background-color: transparent;
				box-sizing: border-box;
				display: inline-block;
				font-family: $primary;
				height: auto;
				line-height: 1.2;
				margin: 0;
				padding: 0 55px 0 15px;
				position: relative;
				min-height: 48px;
				width: 100%;

				&:focus {
					border-color: $mdgrey;
					outline: none;
				}
			}
		}
	}

	.icon-search-off,
	.icon-search-on {
		&:before {
			@include font-size(1.86);
			vertical-align: middle;
		}
	}
}
