// sass-lint:disable max-file-line-count

.dbwu {
	&.dbwu-licensing {
		main {
			background-color: $white;

			article {
				@media only screen and (max-width: $mobile) {
					padding: 0;
				}
			}

			section {
				padding: 50px 0;

				@media only screen and (max-width: $mobile) {
					padding: 25px 0;
				}

				&:nth-child(even) {
					background-color: $cyan;
				}

				.wrapper {
					width: $col8;

					@media only screen and (max-width: $mobile) {
						width: auto;
					}
				}

				&.callout {
					font-family: $primary;
					text-align: center;

					@media only screen and (max-width: $mobile) {
						text-align: left;
					}

					.wrapper {
						width: $col8;

						@media only screen and (max-width: $mobile) {
							width: auto;
						}
					}

					h2 {
						@include font-size(2.2);
						color: $blue;
						line-height: 34px;
					}
				}
			}
		}
	}

	&.dbwu-form {
		main {
			background-color: $white;

			article {
				margin: 0 auto;
				width: $col8;

				@media only screen and (max-width: $mobile) {
					width: auto;
				}
			}

			.userform {
				margin: 100px auto 0;
				width: $col6;

				@media only screen and (max-width: $lrg-tablet) {
					width: $col8;
				}

				@media only screen and (max-width: $mobile) {
					margin: 50px 0;
					width: $col11;
				}
			}
		}
	}

	&.dbwu-confirmation,
	&.dbwu-re,
	&.dbwu-supplier,
	&.dbwu-unsolicited,
	&.dbwu-landing {
		p {
			a {
				&.btn {
					@extend .btn-primary;
				}
			}

			.note {
				a {
					@include link-underline();
				}
			}
		}

		main {
			background-color: $white;
		}

		article {
			margin: 0 auto;
			width: $col8;

			@media only screen and (max-width: $lrg-tablet) {
				width: $col11;
			}

			@media only screen and (max-width: $mobile) {
				padding-top: 50px;
				width: auto;
			}
		}

		.intro {
			@include font-size(1.6);
			line-height: 28px;
			text-align: left;

			h2 {
				@include font-size(2.2);
				color: $blue;
				font-family: $primary;
				line-height: 34px;
				text-align: left;
			}
		}
	}

	&.dbwu-unsolicited {
		main {
			background-color: $bkgrey;
			padding: 100px 0 45px;

			@media only screen and (max-width: $mobile) {
				background-color: $white;
				padding: 25px 0 60px 0;
			}
		}

		article {
			width: $col7;

			@media only screen and (max-width: $mobile) {
				width: auto;
			}

			h3 {
				margin-top: 60px;

				&:nth-child(1) {
					display: none;
				}

				@media only screen and (max-width: $mobile) {
					font-weight: 600;
				}
			}
		}
	}

	&.dbwu-landing {
		.intro {
			h2 {
				@include font-size(4.8);
				color: $grey;
				letter-spacing: -0.5px;
				line-height: 130%;
				text-align: center;
			}
		}
	}

	&.dbwu-re {
		.intro {
			@include font-size(2.2);
			line-height: 34px;
			margin-bottom: 40px;

			h2 {
				@include font-size(4.8);
				letter-spacing: -0.5px;
				line-height: 130%;
				color: $dkgrey;
				margin: 0 0 20px 25%;
			}
		}

		.guidelines {
			margin: 0 auto;
			width: $col9;

			@media only screen and (max-width: $mobile) {
				width: $col12;

				dd {
					margin-left: 0;
				}
			}

			& + .userform {
				margin: 0 auto;
				width: $col9;

				@media only screen and (max-width: $mobile) {
					margin-bottom: 30px;
					width: auto;
				}
			}

			dt {
				font-weight: bold;
			}
		}

		.dbwu-form {
			.intro {
				h2 {
					text-align: center;
				}
			}
		}

		.dbwu-confirmation {
			.intro {
				@include font-size(1.6);
				line-height: 28px;
				text-align: left;

				h2 {
					@include font-size(2.2);
					color: $blue;
					font-family: $primary;
					line-height: 34px;
					text-align: left;
				}
			}
		}

		.userform {
			margin: 100px auto 0;
			width: $col6;

			@media only screen and (max-width: $lrg-tablet) {
				width: $col8;
			}

			@media only screen and (max-width: $mobile) {
				margin: 50px 0;
				width: 100%;
			}
		}
	}

	&.dbwu-supplier {
		.dbwu-supplier-form {
			margin: 0 auto;
			width: $col8;

			.intro {
				@include font-size(1.8);
				line-height: 34px;
				text-align: center;

				@media only screen and (max-width: $mobile) {
					text-align: left;
				}

				h2 {
					@include font-size(3.8);
					color: $grey;
					letter-spacing: -0.5px;
					line-height: 130%;
					text-align: center;

					@media only screen and (max-width: $mobile) {
						text-align: left;
					}
				}
			}

			@media only screen and (max-width: $mobile) {
				width: auto;
			}

			.userform {
				margin: 100px auto 0;
				width: $col6;

				@media only screen and (max-width: $lrg-tablet) {
					width: $col8;
				}

				@media only screen and (max-width: $mobile) {
					margin: 50px 0;
					width: $col-form-input;
				}

				.phone-ext {
					align-items: flex-end;
				}
			}
		}

		.checkbox {
			padding-left: 40px;
		}

		.intro {
			margin-bottom: 50px;
		}

		.guidelines {
			margin: 40px auto 10px;
			width: $col9;

			@media only screen and (max-width: $mobile) {
				width: $col12;

				dd {
					margin-left: 0;
				}
			}

			& + .userform {
				margin: 0 auto;
				width: $col9;

				@media only screen and (max-width: $mobile) {
					margin-bottom: 30px;
					width: auto;
				}

				.btn {
					margin: 8px 0 40px 10px;
				}
			}

			dt {
				font-weight: bold;
			}

			.guideinfo li {
				margin: 0 0 10px 15px;
			}
		}

		p {
			a {
				&.btn {
					@extend .btn-primary;
				}
			}

			.note {
				a {
					@include link-underline();
				}
			}
		}
	}

	.top-hero {
		@include align-items(center);
		display: flex;

		&:before {
			@include scrim($scrim);
		}
	}

	main {
		background-color: $cyan;
		padding: 50px 0;

		@media only screen and (max-width: $mobile) {
			background-color: $white;
			padding: 0;
		}
	}

	p {
		&.intro {
			color: $grey;
			font-family: $primary;
			font-weight: 400;
			margin-bottom: 85px;
			text-align: left;
			width: auto;
		}
	}

	div {
		&.intro {
			@include font-size(2.2);
			font-family: $primary;
			line-height: 34px;
			max-width: none;
			width: auto;

			h2 {
				@include font-size(4.8);
				letter-spacing: -0.5px;
				line-height: 130%;
				margin: 0 0 20px 0;
			}

			@media only screen and (max-width: $mobile) {
				text-align: left;
			}
		}
	}

	.intro {
		h2 {
			text-align: center;
		}

		p {
			a {
				&.btn {
					@extend .btn-primary;
				}
			}

			.note {
				a {
					@include link-underline();
				}
			}
		}
	}

	article {
		font-family: $primary;

		.dbwu-landing & {
			max-width: none;

			@media only screen and (max-width: $mobile) {
				background-color: $white;
			}
		}

		@media only screen and (max-width: $mobile) {
			max-width: none;
			padding: 0 15px;
			width: auto;
		}

		h3 {
			@include font-size(1.6);
			font-weight: 800;
			line-height: 28px;
			margin: 25px 0 15px;
		}

		ul,
		ol {
			margin: 10px 0 30px;
			padding: 0;
		}

		.toggle {
			@include font-size(2.8);
			color: $red;
			font-family: $primary;
			letter-spacing: 0;
			line-height: 150%;
			padding-bottom: 20px;

			@media only screen and (max-width: $mobile) {
				margin: 0 -15px;
				padding: 20px 15px;
			}

			&:hover {
				cursor: pointer;
			}

			&:after {
				@include font-size(1.8);
				content: "\e902";
				font-family: $icon;
				float: right;
				margin: 3px;
			}

			&.active {
				border-bottom: 1px solid $dkgrey;

				&:after {
					@include transform(rotate(180deg));
					margin-top: 8px;
				}
			}
		}
	}

	.dbwu-sections {
		margin: 150px 0 0;

		@media only screen and (max-width: $mobile) {
			margin-top: 70px;
			background-color: $cyan;
			display: block;
			margin: 0 -15px;
			padding: 40px 0;
		}
	}

	.dbwu-section {
		margin-left: $col-margin;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 60px;
			margin-left: 0;
			padding: 0 15px;
		}

		&:first-child {
			margin-left: 0;
		}

		h3 {
			@include font-size(2.2);
			font-family: $primary;
			font-weight: 400;
			line-height: 34px;

			a {
				@include link-underline();
			}
		}

		p {
			a {
				@extend .btn-primary-small;
			}
		}

		img {
			width: 100%;
		}
	}

	.step {
		@include font-size(1.2);
		font-family: $primary;
		line-height: 20px;
		text-transform: uppercase;
		letter-spacing: 2px;

		@media only screen and (max-width: $mobile) {
			margin-top: 25px;
		}
	}
}
