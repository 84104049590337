.three-column-with-cta.product-landing.menu-details-column {
	.three-column-with-cta-headline-container {
		h2 {
			margin-bottom: 24px;
		}

		p {
			margin-bottom: 56px;
		}
	}
}
