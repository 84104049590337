@mixin h4-stc-style {
	@include h4();
	margin: 0 0 10px 0;

	&.blue {
		color: $vis-blue;
	}
}

.block_img_header_txt {
	padding-bottom: -10px;
	text-align: center;
	background-color: $warm-white;
	color: $blue;

	@media screen and (min-width: $mobile) {
		display: flex;
		justify-content: center;
		text-align: left;
	}

	&.bkg-blue {
		background-color: $blue;
		color: $white;

		p {
			color: $white;
		}
	}

	&.bkg-red {
		background-color: $red;
		color: $white;

		p {
			color: $white;
		}
	}

	.img-wrapper {
		&.one-thirds-pos {
			flex-basis: 33.33%;
		}

		&.two-fifths-pos {
			@include flex(0 0 $col4);
		}

		&.half-pos {
			flex-basis: 40%;
		}
	}

	img {
		width: 100%;
		height: auto;

		@media screen and (max-width: $mobile) {
			margin-bottom: 20px;
		}
	}

	&.left-align-txt {
		flex-direction: row-reverse;

		.content {
			@media screen and (min-width: $mobile) {
				margin-left: 0;
				margin: auto 50px;
			}
		}
	}

	&.cream-color-bckgrd {
		background: $warm-white;
		padding: 75px 20px;
	}

	&.alternating-row-order:nth-child(even) {
		@media screen and (min-width: $mobile) {
			flex-direction: row-reverse;

			> .content {
				margin: 0 40px;
			}
		}
	}

	.content {
		position: relative;

		@media screen and (min-width: $mobile) {
			margin-left: 50px;
			width: $col6;
		}

		&.one-thirds-pos {
			flex-basis: 33.33%;
		}

		&.three-fifths-pos {
			@include flex(0 0 $col6);
		}

		&.two-thirds-pos {
			flex-basis: 66.6%;
		}

		&.center-align {
			margin-top: auto;
			margin-bottom: auto;
		}

		&.mobile-text-left {
			@include break-max($large1) {
				text-align: left;
			}
		}

		.headline {
			padding: 0;

			@include break-min($large1) {
				max-width: none;
			}

			p {
				@include font-size(1.8);
				line-height: lh(28, 18);

				@include break-min($large1) {
					@include font-size(1.4);
					font-weight: $bold-weight;
				}
			}
		}

		ul li {
			padding-bottom: 16px;
		}

		h2 {
			margin-top: 0;
			@include type-h2();
		}

		h4 {
			@include h4-stc-style();
			visibility: visible;
		}

		p {
			@include type-p2();
		}

		.btn-round {
			margin-top: 15px;
			padding-left: 50px;
			padding-right: 50px;
		}

		.btn {
			@media screen and (max-width: $mobile) {
				width: 100%;
			}
		}
	}

	&__link--pad-top {
		@include break-min($large1) {
			margin-top: 34px;
		}
	}

	&.margin-top {
		margin-top: 50px;

		@include break-min($large1) {
			margin-top: 100px;
		}
	}

	&.margin-top-desktop {
		@include break-min($large1) {
			margin-top: 55px;
		}
	}

	&.pad-content {
		.content {
			p {
				@include type-p2();
			}
		}

		@include break-min($large1) {
			.content {
				padding-left: 74px;
				padding-right: 128px;
				@include flexbox();
				@include flex-direction(column);
				@include justify-content(center);
			}

			.img-wrapper {
				@include flexbox();
				@include flex-direction(column);
				@include justify-content(center);
			}

			&.left-align-txt {
				.content {
					padding-right: 74px;
					padding-left: 128px;
				}
			}
		}
	}

	&.overlap-img {
		padding: 20px 16px 40px;

		@include break-min($large1) {
			padding: 0 0 80px;

			.img-wrapper {
				margin: 0;
				@include flex(0 0 53%);
				max-width: 53%;
				padding-left: 25px;
			}

			.content {
				margin: 0;
				@include flex(0 0 47%);
				max-width: 47%;

				h2 {
					@include h4-stc-style();
				}
			}

			&.left-align-txt {
				.img-wrapper {
					padding-right: 25px;
				}
			}
		}
	}

	&.pad-top-lg {
		padding-top: 50px;

		@include break-min($large1) {
			padding-top: 100px;
		}
	}

	&.less-padding {
		margin-bottom: 0;
		padding: 16px;
	}

	&.split-columns {
		@include break-min($large1) {
			max-width: $col10;

			.img-wrapper {
				@include flex(0 0 50%);
			}

			.content {
				margin-left: 0;
			}
		}

		@include break-max($small4) {
			.content {
				margin-top: 30px;

				.btn-round {
					margin-top: 30px;
				}
			}
		}
	}

	&.vertical-margins {
		margin-top: 60px;
		margin-bottom: 60px;
		padding-bottom: 0;

		&.vertical-margins:nth-child(even) {
			@include break-min($large1) {
				margin-top: 120px;

				.content {
					padding-right: $col1;
					margin-left: 0;

					&.center-align {
						margin-bottom: auto;
						margin-top: auto;
					}
				}
			}
		}
	}

	&.blue-headline {
		h1,
		h2,
		h3,
		h4,
		h5 {
			color: $vis-blue;
		}
	}
}
