.banner-header-paragraph-simple {
	background-color: $cool-white;
	padding: 72px 0;

	@include break-min($large1) {
		padding: 128px 0;
	}

	.content {
		text-align: center;
		@include flexbox;
		@include flex-direction(column);
		@include align-items(center);
		@include justify-content(center);

		h1 {
			@include font-size(3.6);
			color: $blue;
			font-family: $primary;
			font-weight: 700;
			letter-spacing: -1px;
			margin: 2rem 0;
			line-height: 46px;

			@include break-min($large1) {
				@include font-size(4.8);
				line-height: 58px;
			}
		}

		P {
			line-height: 28px;
		}
	}
}
