.lll-search-results {
	@include flex-rules();
	flex-basis: $col12;
	margin: 10px auto 0;
	max-width: $col12;
	padding: 0 16px;

	@include breakpoint(tablet) {
		flex-basis: $col8;
		margin: 27px auto 0;
		max-width: $col8;
		padding: 0;
	}

	ul {
		@include flex-rules();
		flex-wrap: none;
		flex-basis: 100%;
		margin: 0;
		max-width: 100%;
		padding: 0;
		width: 100%;
	}

	li {
		@include flex-rules();
		flex-basis: 100%;
		margin: 40px 0 0;
		max-width: 100%;
		width: 100%;

		@include breakpoint(desktop) {
			&:first-of-type {
				margin: 29px 0 0;
			}
		}

		div {
			@include column-rules();
			flex-basis: $col9;
			max-width: $col9;
			padding: 0 0 0 24px;

			@include breakpoint(tablet) {
				flex-basis: $col12;
				max-width: $col12;
			}

			@include breakpoint(desktop) {
				flex-basis: $col7;
				max-width: $col7;
			}
		}

		img {
			max-height: 68px;
			max-width: 68px;
		}

		h4 {
			@include font-size(2.3);
			color: $vis-grey;
			font-family: $primary;
			font-weight: 600;
			line-height: 36px;
			letter-spacing: -0.5px;
			font-variant-ligatures: none;
			// sass-lint:disable no-vendor-prefixes
			-webkit-font-variant-ligatures: none;
			// sass-lint:enable no-vendor-prefixes
			margin: 0;
		}

		p {
			@include font-size(1.8);
			font-family: $primary;
			font-weight: normal;
			line-height: 32px;
			letter-spacing: -0.5px;
			font-variant-ligatures: none;
			// sass-lint:disable no-vendor-prefixes
			-webkit-font-variant-ligatures: none;
			// sass-lint:enable no-vendor-prefixes
			margin: 0;

			&.orange-text {
				color: $orange;
				font-weight: 600;
			}
		}

		.btn {
			background: $white;
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
			color: $red;
			letter-spacing: -0.5px;
			margin: 23px 0 0;
			padding: 17px 15px 16px;
			text-align: center;
			width: 100%;

			@include breakpoint(desktop) {
				// sass-lint:disable-block no-duplicate-properties
				bottom: 0;
				position: absolute;
				right: 0;
				width: fit-content;
				width: auto;
			}

			&:hover {
				background: $white;
				box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
				color: $red;
				transform: translateY(-1px);
				transition: all 0.3s ease-in-out;
			}

			&:active {
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
				transform: translateY(0px);
			}

			a {
				@include breakpoint(desktop) {
					float: right;
				}
			}
		}
	}

	h3 {
		@include font-size(3.2);
		color: $vis-blue;
		display: none;
		font-family: $primary;
		font-weight: 800;
		line-height: 48px;
		letter-spacing: -0.7px;
		font-variant-ligatures: none;
		// sass-lint:disable no-vendor-prefixes
		-webkit-font-variant-ligatures: none;
		// sass-lint:enable no-vendor-prefixes
		margin: 0;
		width: 100%;

		@include breakpoint(desktop) {
			display: block;
		}
	}
}
