.limited-dine-in-modal {
	background: $white;
	border-radius: 15px;
	margin: 0 auto;
	max-width: 450px;
	position: relative;
	text-align: center;

	.inner {
		padding: 60px 24px 24px;

		@include break-min($large1) {
			padding: 60px 42px 24px;
		}

		button:first-of-type {
			&.mfp-close {
				@include lightbox-close-btn();
				opacity: 1;
			}
		}

		button:nth-of-type(2) {
			&.mfp-close {
				@include font-size(1.6);
				background: $btn-hover-grad;
				font-family: $primary;
				display: inline-block;
				letter-spacing: -0.5625px;
				min-width: 144px;
				position: unset;
				opacity: 1;
				width: 100%;

				@include break-min($large1) {
					height: auto;
					padding: 0;
					width: auto;
				}
			}
		}
	}

	h4 {
		@include font-size(2.4);
		color: $blue;
		font-weight: bold;
		letter-spacing: -0.5px;
		margin: 0 0 12px;

		@include break-min($large1) {
			@include font-size(3.6);
		}
	}

	p {
		@include type-p2(desktopOnly);
		margin-bottom: 35px;
	}
}
