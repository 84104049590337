.three-column-restaurant-description {
	padding: 40px 33px;
	text-align: center;

	.lb-header-content {
		p {
			@include font-size(1.8);
		}

		h2 {
			color: $blue;
			font-family: $primary;
			font-weight: bold;
			margin: 0 0 24px;
			padding: 0;
			text-align: center;
			letter-spacing: -0.01px;
			@include font-size(2.4);

			@include break-min($large1) {
				@include font-size(4.2);
			}
		}

		@include break-min($large1) {
			padding: 0 200px;
		}

		@include break-min($large4) {
			padding: 0 270px;
		}

		@include break-min($huge2) {
			padding: 0 340px;
		}
	}

	&__list {
		padding: 0;
		list-style: none;
		justify-content: space-evenly;
		margin: 20px 0;
		flex-flow: column;

		@include break-min($large1) {
			margin: 50px 0;
			flex-flow: row;
		}

		&-item {
			margin: 40px 0;

			@include break-min($large1) {
				margin: 10px 0;
			}
		}
	}
}
