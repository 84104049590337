.grand-opening-page {
	.g-carousel-grid {
		background: $warm-white;
		padding: 40px 0 80px;
		text-align: center;
		margin: 0;

		@include break-min($small4) {
			padding: 50px 0;
		}

		&__inner {
			@include break-min($small4) {
				padding: 0;
			}

			@include break-min($large) {
				max-width: 1380px;
				margin: 0 auto;
				width: 100%;
				padding: 0;
			}
		}
	}

	.g-hero {
		&__inner {
			max-width: 1380px;
			margin: 0 auto;
			width: 100%;
			padding: 0 24px;
			background: rgba(0, 0, 0, 0.41);

			@include break-min($large1) {
				padding: 0;
				background: transparent;
			}
		}
	}

	.g-video {
		&__wrap {
			padding: 0;
		}
	}
}
