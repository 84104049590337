.delivery-no-results {
	margin: 32px 0 48px;
	padding: 0 24px;
	text-align: center;

	@include break-min($large1) {
		margin: 48px 0 58px;
	}

	h1 {
		@include type-h1();
		@include font-size(3.6);
		color: $blue;
		line-height: 1.22;
		margin: 24px auto;
		width: $col10;

		@include break-min($large1) {
			@include font-size(4.8);
			margin: 0 auto 50px;
		}
	}

	p {
		@include type-p1();
		@include font-size(1.8);
		font-weight: normal;
		margin: 0 auto 15px;
		width: $col11;

		&:first-of-type {
			margin-bottom: 32px;

			@include break-min($large1) {
				margin-bottom: 37px;
			}
		}

		&:last-of-type {
			margin-bottom: 24px;

			@include break-min($large1) {
				margin: 0 auto 52px;
				width: $col6;
			}

			@include break-min($large4) {
				width: $col5;
			}
		}
	}
}
