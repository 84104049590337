// sass-lint:disable max-file-line-count, no-qualifying-elements

// PDP - Product Detail Page - New
// ==========================================================================

$pdp-menu-height: $nav-base-height;
$pdp-alert-height: 60px;

@mixin pdp-p {
	@include font-size(1.6);
	font-family: $primary;
	font-weight: $regular-weight;
	line-height: lh(24px, 16px);
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

@mixin pdp-h3 {
	@include font-size(1.8);
	font-family: $primary;
	font-weight: $bold-weight;
	line-height: lh(28px, 18px);
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

@mixin pdp-label {
	@include font-size(1.4);
	font-family: $primary;
	font-weight: $regular-weight;
	line-height: lh(20px, 14px);
	letter-spacing: -0.25px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

@mixin pdp-label-s {
	@include font-size(1.2);
	font-family: $primary;
	font-weight: $medium-weight;
	line-height: 1.4;
	letter-spacing: -0.25px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

.pdp {
	.frame {
		padding-top: $nav-base-height;
	}

	&__sticky {
		//margin-top: 64px; Untoggle once ready to go live
		@include break-min($large1) {
			// sass-lint:disable-block no-duplicate-properties
			height: 80vh;
			height: calc(100vh - #{$nav-base-height});
			//margin-top: 0;
			position: sticky;
			top: $nav-base-height;

			&--has-alert {
				height: calc(100vh - #{$nav-base-height} - #{$pdp-alert-height});
			}
		}

		&.padding-bottom {
			@include break-min($large1) {
				padding-bottom: 150px;
			}
		}

		.ie & {
			img {
				height: auto;
				width: auto;
				max-height: 300px;
			}

			&-content {
				display: block;
				height: auto;
			}

			@include break-min($large1) {
				backface-visibility: hidden;
				will-change: position;
				position: fixed;
				left: 0;
				width: 50%;

				&--absolute {
					width: 100%;
					position: absolute;
					top: auto;
					bottom: 0;
				}

				&-content {
					height: 100%;
					@include flexbox();
					@include justify-content(center);
					@include align-items(center);
				}

				&-cta {
					@include flex(0 0 auto);
					max-width: 100%;
				}

				img {
					margin: 0;
					max-height: calc(70vh - #{$nav-base-height});
					@include flex(0 0 auto);
				}
			}
		}

		&-cta {
			padding: 10px 6px 20px 6px;
			@include flex(0 1 auto);

			+ .pdp__sticky-message {
				margin-top: 20px;
			}

			p {
				// sass-lint:disable variable-for-property
				background-color: rgba(255, 255, 255, 0.7);
				// sass-lint:enable variable-for-property
				margin: 20px 0 0;
				padding: 0;
			}

			&--desktop {
				display: none;
			}

			@include break-min($large1) {
				padding: 0;

				&--mobile {
					display: none;
				}

				&--desktop {
					display: block;
				}
			}
		}

		&-message {
			position: relative;
			padding: 45px 6px 0 6px;
			@include flex(0 1 auto);

			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				margin: 0 auto;
				width: 45px;
				height: 45px;
				border-radius: 50px;
				background-position: center center;
				background-repeat: no-repeat;
			}

			&--error {
				&::after {
					background-color: transparent;
					background-image: url("img/cp-icons/icon-cp_warning.svg");
					background-size: cover;
				}
			}

			&--time {
				&::after {
					background-color: $red;
					background-image: url("img/icon_clock-white.svg");
					background-size: 27px 27px;
				}
			}

			p {
				padding: 15px 0 0;
				margin: 0;
				color: $vis-grey;
				@include pdp-p();
			}

			a {
				@include link-underline();
			}
		}

		&-content {
			text-align: center;
			padding: 10px;
			height: 65vh;
			min-height: 260px;
			@include flexbox();
			@include justify-content(stretch);
			@include flex-wrap(nowrap);
			@include align-items(stretch);
			@include flex-direction(column);

			&--hide-cta {
				.pdp__sticky-cta {
					display: none;
				}

				@media only screen and (max-width: $mobile) {
					height: calc(40vh + 150px);
				}
			}

			@include break-min($large1) {
				padding: 32px 16px;
				position: relative;
				// max-width: 632px;
				margin: 0 auto;
				height: 100%;
				min-height: 0;
			}
		}

		img {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: contain;
			margin: 0 auto;
			min-height: 0;
			@include flex(1 1 auto);
		}

		.pdp-pwo-cta {
			@include break-min($large1) {
				display: none;
			}
		}
	}

	&__page {
		@include break-min($large1) {
			@include flexbox();
			@include justify-content(stretch);
			@include align-items(stretch);
			@include flex-wrap(nowrap);
		}

		&--reversed {
			@include flexbox();
			@include justify-content(flex-start);
			@include align-items(stretch);
			@include flex-wrap(wrap);
			@include flex-direction(column-reverse);

			@include break-min($large1) {
				@include flex-wrap(nowrap);
				@include justify-content(stretch);
				@include align-items(stretch);
				@include flex-direction(row-reverse);
			}
		}
	}

	&__half {
		position: relative;

		&--cream {
			background-color: $warm-white;
		}

		@include break-min($large1) {
			min-width: 0;
			@include flex(0 0 50%);
		}
	}

	&__pad {
		padding: 0 16px;

		@include break-min($large1) {
			padding: 0 24px;

			.ie & {
				min-height: calc(100vh - #{$nav-base-height});
			}
		}
	}
}

// .p-details component
// ..........................................................................
.p-details {
	padding: 30px 0;

	&__head {
		padding: 0 0 5px;

		@include break-min($medium1) {
			@include flexbox();
			@include justify-content(space-between);
			@include align-items(baseline);
			@include flex-wrap(nowrap);
		}

		@include break-min($large1) {
			padding: 10px 0 5px;
		}
	}

	&__h1 {
		@include font-size(2.8);
		display: block;
		margin: 0;
		padding: 0 0 10px;
		font-family: $primary;
		font-weight: $bold-weight;
		line-height: lh(34px, 28px);
		letter-spacing: -1px;
		color: $vis-blue;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes

		@include break-min($medium1) {
			@include flex(1 1 auto);
			@include font-size(3.9);
			letter-spacing: -1px;
			padding: 0;
			// line-height: lh(40px, 39px);
		}
	}

	&__price {
		@include p1();
		line-height: 1.2;
		color: $grey;
		margin: 0;
		padding: 0;
		font-weight: $bold-weight;

		@include break-min($medium1) {
			@include flex(0 0 auto);
			padding: 0 0 0 10px;
			@include font-size(2.2);
		}
	}

	&__text {
		max-width: 570px;

		p {
			padding: 15px 0 0;
			margin: 0;
			color: $vis-grey;
			@include pdp-p();
		}

		+ .p-details__options {
			padding: 25px 0 0;
		}
	}

	&__options {
		span {
			@include p1();
			font-weight: $bold-weight;
			padding: 0;
			margin: 0;
		}
	}

	&__radio {
		padding: 13px 0 0 0;
		margin: 0;
		position: relative;

		label {
			color: $vis-grey;
			@include pdp-p();
			font-weight: $bold-weight;
			margin: 0;
			padding: 0 0 0 30px;
			position: relative;
			cursor: pointer;
			pointer-events: initial;
			display: inline-block;
			z-index: $z2;

			&::before {
				content: "";
				margin: auto;
				display: block;
				width: 18px;
				height: 18px;
				background-color: transparent;
				border: 1px solid $light-grey;
				position: absolute;
				top: 1px;
				left: 0;
				border-radius: 50%;
			}
		}

		input {
			border: 0;
			padding: 0;
			margin: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
			pointer-events: none;

			&:hover {
				+ label {
					&::before {
						background-color: lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:focus {
				+ label {
					outline-offset: 3px;
					outline: 1px dotted $red;

					&::before {
						box-shadow: 0 0 0 3px lighten($red, 50%);
						border-color: $red;
					}
				}
			}

			&:checked {
				+ label {
					&::before {
						background-color: transparent;
						border-color: $red;
					}

					&::after {
						content: "";
						position: absolute;
						background-color: $red;
						left: 3px;
						top: 4px;
						margin: auto;
						width: 14px;
						height: 14px;
						border-radius: 50%;
					}
				}
			}

			&:disabled {
				+ label {
					cursor: not-allowed;

					&::before {
						opacity: 0.5;
						background-color: transparent;
						border-color: $light-grey;
						box-shadow: none;
					}
				}
			}
		}

		span {
			display: inline-block;
			font-weight: $regular-weight;
			margin: 0;
			padding: 0 10px 0 0;

			@include break-min($medium1) {
				padding: 0 20px 0 0;
			}
		}
	}
}

// .p-nutri component
// ..........................................................................
.p-nutri {
	position: relative;
	padding: 30px 0;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 2px;
		background-color: $gray-orange;
	}

	&--accord {
		padding: 0;

		&:last-child {
			margin: 0 0 30px;
			border-bottom: 2px solid $gray-orange;
		}
	}

	&__content {
		padding: 0 0 30px;

		> p {
			padding: 0;
			margin: 0;
			color: $vis-grey;
			@include pdp-p();

			+ p {
				padding: 15px 0 0;
			}
		}

		button {
			background: transparent;
			line-height: 32px;
			color: $red;
			border: 0;
			font-weight: bold;
			padding: 0;
			text-decoration: underline;
			@include font-size(1.6);
		}
	}

	&__block {
		color: $grey;
		margin: 0;
		@include font-size(1.4);
		font-family: $primary;
		font-weight: $medium-weight;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		line-height: 1.3;
		display: inline-block;
		padding: 0 35px 15px 0;
		white-space: nowrap;

		span {
			display: block;
			color: $vis-blue;
			font-weight: $bold-weight;
			line-height: inherit;
			padding: 0 0 5px;
			@include font-size(2.2);
		}

		@include break-min($medium1) {
			padding: 0 45px 20px 0;
			@include font-size(1.4);

			span {
				padding: 0;
			}
		}
	}

	&__link {
		@include pdp-p();
		display: inline-block;
		color: $red;
	}

	&__fyi {
		@include pdp-label-s();
		display: block;
		margin: 0;
		padding: 10px 0 0 0;
	}

	&__heading {
		@include p1();
		@include font-size(1.8);
		display: block;
		line-height: 1.2;
		color: $grey;
		margin: 0;
		padding: 28px 0;
		font-weight: $bold-weight;
		position: relative;

		@include break-min($small4) {
			@include font-size(2.2);
		}

		&--accord {
			cursor: pointer;

			&::after {
				content: "";
				background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
				width: 20px;
				height: 20px;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				transition: transform 0.3s ease;
			}

			&:focus {
				outline: 1px dotted $red;
				outline-offset: 2px;
				font-weight: $bold-weight;
			}

			&.pdp-accord--open {
				&::after {
					transform: rotate3d(1, 0, 0, 180deg);
				}
			}

			+ .p-nutri__content {
				display: none;
			}
		}
	}
}

// .p-includes component
// ..........................................................................
.p-includes {
	&:last-child {
		margin: 0 0 30px;
		border-bottom: 2px solid $gray-orange;
	}

	&__heading {
		@include p1();
		@include font-size(1.8);
		display: block;
		line-height: 1.2;
		color: $grey;
		margin: 0;
		padding: 28px 0;
		font-weight: $bold-weight;
		position: relative;

		@include break-min($small4) {
			@include font-size(2.2);
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 2px;
			background-color: $gray-orange;
		}

		&--accord {
			cursor: pointer;

			&::after {
				content: "";
				background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
				width: 20px;
				height: 20px;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				transition: transform 0.3s ease;
			}

			&:focus {
				outline: 1px dotted $red;
				outline-offset: 2px;
				font-weight: $bold-weight;
			}

			&.pdp-accord--open {
				&::after {
					transform: rotate3d(1, 0, 0, 180deg);
				}
			}

			+ .p-includes__content {
				display: none;
			}
		}
	}

	&__content {
		margin: 0 -10px;
	}

	&__block {
		text-align: center;
		padding: 15px 10px 35px;
		@include flex(0 0 50%);

		@include flexbox();
		@include justify-content(flex-end);
		@include align-items(center);
		@include flex-wrap(nowrap);
		@include flex-direction(column);

		@include break-min(321px) {
			@include flex(0 0 33.3333%);
		}

		@include break-min($small4) {
			@include flex(0 0 25%);
		}

		@include break-min($large1) {
			@include flex(0 0 33.3333%);
		}

		@include break-min($large4) {
			@include flex(0 0 25%);
		}

		&-wrap {
			overflow: hidden;
			@include flexbox();
			@include justify-content(stretch);
			@include align-items(stretch);
			@include flex-wrap(wrap);
		}

		h4 {
			color: $vis-blue;
			margin: 0;
			padding: 20px 0 0;
			@include font-size(1.4);
			font-family: $primary;
			font-weight: $bold-weight;
			// sass-lint:disable no-vendor-prefixes
			-moz-osx-font-smoothing: grayscale;
			// sass-lint:enable no-vendor-prefixes
			line-height: 1.3;
			display: block;
			max-width: 100%;
			@include flex(0 1 auto);
		}

		span {
			color: $grey;
			margin: 0;
			padding: 4px 0 0;
			@include font-size(1.4);
			font-family: $primary;
			font-weight: $medium-weight;
			// sass-lint:disable no-vendor-prefixes
			-moz-osx-font-smoothing: grayscale;
			// sass-lint:enable no-vendor-prefixes
			line-height: 1.3;
			display: block;
			@include flex(0 1 auto);
		}

		img {
			max-width: 75px;
			margin: 0 auto auto auto;
			display: block;
			height: auto;
			width: 100%;
			@include flex(0 1 auto);

			@include break-min($small3) {
				max-width: 110px;
			}
		}
	}

	.ie &__block img {
		margin: 0;
	}
}

// .p-block component
// ..........................................................................
.p-block {
	padding: 0 0 22px;

	&--text {
		border-top: 2px solid $gray-orange;
		padding: 22px 0;

		&:last-child {
			padding: 22px 0 60px;
		}

		p {
			@include pdp-p();
			padding: 18px 0 0;
			margin: 0;

			&:first-child {
				padding: 0;
			}
		}

		@media only screen and (max-width: $mobile) {
			text-align: center;

			p {
				@include font-size(1.4);
				line-height: lh(20px, 14px);
			}
		}

		@include break-min($large1) {
			&:last-child {
				padding: 22px 0 106px;
			}
		}
	}

	&--bordered {
		padding: 22px 0;
		position: relative;

		&::before,
		&::after {
			content: "";
			position: absolute;
			right: 0;
			left: 0;
			height: 2px;
			background-color: $gray-orange;
		}

		&::before {
			top: 0;
		}

		&::after {
			bottom: -2px;
		}
	}

	&__heading {
		margin: 0;
		padding: 28px 0 8px 0;
		position: relative;

		@include break-min($medium1) {
			@include flexbox();
			@include justify-content(space-between);
			@include align-items(baseline);
			@include flex-wrap(wrap);
		}

		h2 {
			@include pdp-h3();
			margin: 0;
			padding: 0 0 3px;
			color: $vis-grey;
			display: block;

			@include break-min($small4) {
				@include font-size(2.2);
				line-height: lh(28px, 22px);
			}

			@include break-min($medium1) {
				padding: 0 8px 3px 0;
				@include flex(1 1 auto);
			}
		}

		p {
			@include pdp-label();
			padding: 0 0 5px;
			margin: 0;

			@include break-min($medium1) {
				@include font-size(1.6);
				line-height: lh(24px, 16px);
				@include flex(0 0 auto);
			}
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 2px;
			background-color: $gray-orange;
		}

		&--accord {
			cursor: pointer;

			&::after {
				content: "";
				background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
				width: 20px;
				height: 20px;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				transition: transform 0.3s ease;
			}

			&:focus {
				outline: 1px dotted $red;
				outline-offset: 2px;
				font-weight: $bold-weight;
			}

			&.pdp-accord--open {
				&::after {
					transform: rotate3d(1, 0, 0, 180deg);
				}
			}

			+ .p-includes__content {
				display: none;
			}
		}
	}

	&__cta {
		padding: 44px 0 75px 0;

		&:first-child {
			padding: 0;
		}

		@include break-min($large1) {
			display: none;
		}
	}

	&__divide {
		padding: 20px 12px 8px 12px;
		max-width: 100%;
		@include flex(1 1 100%);

		&:first-child {
			padding-top: 0;
		}

		&--last {
			@include order(100);
		}

		p {
			@include pdp-p();
			padding: 0;
			margin: 0;

			@media only screen and (max-width: $mobile) {
				@include font-size(1.4);
				line-height: lh(20px, 14px);
			}
		}
	}
}

// card wrapper component - dictates widths
// ..........................................................................

.p-block__card {
	padding: 8px 12px;
	max-width: 100%;
	@include flex(0 0 100%);

	&:not(.p-block__card--full) {
		@include break-min($small4) {
			@include flex(0 0 50%);
			max-width: 50%;
		}

		@include break-min($large1) {
			@include flex(0 0 100%);
			max-width: 100%;
		}

		@include break-min($large4) {
			@include flex(0 0 50%);
			max-width: 50%;
		}
	}

	&--half {
		&:not(.p-block__card--full) {
			@include break-min($small3) {
				@include flex(0 0 50%);
				max-width: 50%;
			}

			@include break-min($large1) {
				@include flex(0 0 50%);
				max-width: 50%;
			}
		}
	}

	&--text {
		@include pdp-label-s();
		margin: 0 0 0 auto;
	}

	&--addable {
		// over 100 -- see .p-block__divide
		@include order(200);

		.p-card__cta {
			span {
				display: block;

				&:first-child {
					display: none;
				}
			}
		}
	}

	&s-wrap {
		margin: 0 -12px;
		@include flexbox();
		@include justify-content(stretch);
		@include align-items(stretch);
		@include flex-wrap(wrap);
	}
}

// radio card component
// ..........................................................................
.p-radio {
	height: 100%;
	min-height: 82px;
	margin: 0;
	position: relative;
	background-color: $white;
	border-radius: 8px;
	border: 3px solid $white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);

	svg {
		position: absolute;
		width: 30px;
		height: 30px;
		top: 0;
		left: 50px;
		bottom: 0;
		margin: auto;
		pointer-events: none;
		color: $light-grey;
	}

	&::before {
		content: "";
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		width: 30px;
		height: 30px;
		top: 0;
		left: 50px;
		bottom: 0;
		margin: auto;
	}

	&--hot {
		&::before {
			background-image: url("img/icon_fire-gray.svg");
		}
	}

	&--cold {
		&::before {
			background-image: url("img/icon_snowflake-gray.svg");
		}
	}

	&--active {
		border-color: $red;

		svg {
			color: $red;
		}
	}

	&--disabled {
		pointer-events: none;

		* {
			pointer-events: none;
		}
	}

	a {
		margin: 5px 0 0;
		display: block;

		&:hover {
			text-decoration: underline;
		}
	}

	label {
		@include pdp-label();
		height: 100%;
		color: $vis-grey;
		margin: 0;
		padding: 10px 16px 10px 96px;
		position: relative;
		cursor: pointer;
		pointer-events: initial;
		display: block;
		z-index: $z2;

		@include flexbox();
		@include justify-content(center);
		@include align-items(flex-start);
		@include flex-wrap(nowrap);
		@include flex-direction(column);

		&::before {
			content: "";
			margin: auto;
			display: block;
			width: 18px;
			height: 18px;
			background-color: $white;
			border: 1px solid $light-grey;
			position: absolute;
			top: 0;
			left: 17px;
			bottom: 0;
			border-radius: 50%;
		}
	}

	.ie & label {
		@include align-items(stretch);
	}

	input {
		border: 0;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
		pointer-events: none;

		&:checked {
			+ label {
				border-color: $red;

				&::before {
					background-color: $white;
					border-color: $red;
				}

				&::after {
					content: "";
					position: absolute;
					background-color: $red;
					left: 20px;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 14px;
					height: 14px;
					border-radius: 50%;
				}
			}
		}

		&:disabled {
			+ label {
				cursor: not-allowed;

				&::before {
					opacity: 0.5;
					background-color: $white;
					border-color: $light-grey;
					box-shadow: none;
				}
			}
		}

		&:focus {
			+ label {
				outline-offset: 5px;
				outline: 1px dotted $red;

				&::before {
					box-shadow: 0 0 0 3px lighten($red, 50%);
					border-color: $red;
				}
			}
		}
	}
}

// qty selector component
// ..........................................................................
.p-qty {
	display: inline-block;

	&__select {
		@include flex(0 0 auto);
		border-radius: 50px;
		height: 48px;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
		@include flexbox();
		@include justify-content(stretch);
		@include align-items(stretch);
		@include flex-wrap(nowrap);
	}

	&__btn,
	input {
		@include pdp-p();
		@include appearance(none);
		font-weight: $medium-weight;
		border: 0;
		text-align: center;
		color: $red;
		background-color: $white;
		padding: 0;
		margin: 0;
	}

	input {
		@include flex(1 1 auto);
		text-overflow: ellipsis;
		width: 64px;
		padding: 0 5px;
		z-index: $z2;
		position: relative;
		border-right: 2px solid #e6e6e6;
		border-left: 2px solid #e6e6e6;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			appearance: none;
			margin: 0;
		}

		&:focus {
			outline: 1px dotted $red;
			outline-offset: 2px;
		}
	}

	&__btn {
		@include font-size(1.8);
		@include flex(0 0 55px);
		transition: background-color 0.3s ease, border 0.3s ease;
		border: 1px dotted $white;
		width: 55px;
		position: relative;
		z-index: $z1;

		&:focus {
			outline: 0;
			border-color: $red;
		}

		&:active {
			// sass-lint:disable variable-for-property
			background-color: #f7f7f7;
			// sass-lint:enable variable-for-property
		}

		&--dec {
			border-radius: 50px 0 0 50px;
			@include order(-1);
		}

		&--inc {
			border-radius: 0 50px 50px 0;
		}

		&--disabled,
		&:disabled {
			// pointer-events: none;
			cursor: not-allowed;
			color: $light-grey;

			&:focus,
			&:active {
				border-color: $white;
				background-color: $white;
			}
		}
	}
}

// base pdp card component
// ..........................................................................
.p-card {
	height: 100%;
	cursor: pointer;
	padding: 12px 14px;
	background-color: $white;
	border-radius: 8px;
	border: 3px solid $white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);

	@include flexbox();
	@include justify-content(stretch);
	@include flex-wrap(wrap);
	@include align-items(flex-start);

	&:focus {
		outline: 1px dotted $red;
		outline-offset: 2px;
	}

	.p-qty {
		@include flex(0 0 auto);
		padding: 8px 0 0;
	}

	&--active {
		border-color: $red;
	}

	// card type -- sauce
	&--sauce {
		// sass-lint:disable-block empty-line-between-blocks, max-line-length
		cursor: default;
		@include align-items(center);

		.p-qty {
			@include flex(0 0 auto);
			padding: 0 0 8px 0;
			margin: 0;
		}
		// sass-lint:disable indentation
		@media screen and (min-width: $small4) and (max-width: $large1),
			screen and (min-width: $large4) {
			@include flex-wrap(nowrap);

			.p-qty {
				padding: 0;
				@include order(10);
			}

			.p-card {
				&__meta {
					padding: 0 16px;
					@include flex(1 1 auto);
				}

				&__thumb {
					padding: 0;
					@include flex(0 0 auto);
				}
			}
		}
	}

	// card type -- custom
	&--custom {
		padding-right: 0;
		cursor: default;
		@include flex-wrap(nowrap);
		@include align-items(center);

		.p-card {
			&__title {
				padding: 0 0 0 16px;
				@include flex(1 1 auto);
				@include flex-wrap(wrap);

				h3 {
					padding: 0;
				}

				> span {
					padding: 2px 0 0;
					max-width: 100%;
					@include flex(1 1 100%);
					font-weight: $regular-weight;
				}
			}

			&__thumb {
				padding: 0;
				@include flex(0 0 auto);

				&-img {
					@media screen and (min-width: $large4) and (max-width: $huge1) {
						width: 46px;
					}
				}
			}
		}
	}

	&__cta {
		padding: 0 0 0 2px;
		@include flex(0 0 auto);

		span {
			@include pdp-label();
			color: $red;
			display: none;
			margin: 0;
			padding: 14px;
			cursor: pointer;

			&:first-child {
				display: block;
			}

			&:hover {
				text-decoration: underline;
			}

			&:focus {
				font-weight: $regular-weight;
				outline: 1px dotted $red;
				outline-offset: 2px;
			}

			@media only screen and (max-width: $mobile) {
				font-weight: $medium-weight;
				letter-spacing: 0;
				line-height: lh(16px, 12px);
				@include font-size(1.2);

				&:focus {
					font-weight: $medium-weight;
				}
			}
		}
	}

	&__title {
		@include flex(0 0 100%);
		@include flexbox();
		@include justify-content(space-between);
		@include flex-wrap(nowrap);
		@include align-items(baseline);

		h3 {
			@include pdp-h3();
			display: inline-block;
			text-align: left;
			padding: 0 5px 0 0;
			margin: 0;
			color: $vis-blue;
			@include flex(1 1 auto);

			@media only screen and (max-width: $mobile) {
				line-height: lh(24px, 16px);
				@include font-size(1.6);
			}
		}

		span {
			@include pdp-label();
			font-weight: $bold-weight;
			display: inline-block;
			text-align: left;
			padding: 5px 0 0 0;
			margin: 0;
			color: $vis-grey;
			@include flex(0 1 auto);

			@media only screen and (max-width: $mobile) {
				line-height: lh(16px, 12px);
				@include font-size(1.2);
			}

			span {
				color: inherit;
				font-weight: $bold-weight;
			}
		}
	}

	&__info {
		margin: auto 0 0 0;
		padding: 2px 0 0;
		@include flex(0 0 100%);
		@include flexbox();
		@include justify-content(space-between);
		@include flex-wrap(wrap);
		@include align-items(baseline);

		h3 {
			margin: 0;
			padding: 14px 0 0;
			color: $vis-blue;
			@include font-size(1.8);
			font-family: $primary;
			font-weight: $bold-weight;
			line-height: lh(24px, 18px);
			// sass-lint:disable no-vendor-prefixes
			-moz-osx-font-smoothing: grayscale;
			// sass-lint:enable no-vendor-prefixes
			@include flex(1 1 100%);

			sup {
				display: inline-block;
			}

			@include break-min($medium3) {
				@include font-size(2.2);
				padding: 14px 0 10px;
				line-height: lh(28px, 22px);
			}
		}

		span,
		a {
			@include pdp-label();
			display: inline-block;
			text-align: left;
			margin: 5px 0 0;

			@media only screen and (max-width: $mobile) {
				font-weight: $medium-weight;
				letter-spacing: 0;
				line-height: lh(16px, 12px);
				@include font-size(1.2);
			}
		}

		a {
			display: inline-block;
			text-align: left;
			padding: 0;
			@include flex(0 1 auto);

			+ span {
				padding: 0 0 0 5px;
				@include flex(0 1 auto);
			}

			&:hover {
				text-decoration: underline;
			}
		}

		> span {
			padding: 0 5px 0 0;
			color: $vis-grey;
			@include flex(1 1 auto);

			span {
				color: inherit;
				font-weight: $bold-weight;
			}
		}
	}

	&__text {
		max-width: 100%;
		@include flexbox();
		@include justify-content(stretch);
		@include flex-wrap(wrap);
		@include align-items(flex-start);
		@include flex(0 0 100%);

		p {
			@include pdp-label();
			padding: 12px 0;
			margin: 0;
		}
	}

	&__meta {
		max-width: 100%;
		padding: 0;
		@include flexbox();
		@include justify-content(stretch);
		@include flex-wrap(wrap);
		@include align-items(flex-start);
		@include flex(0 0 100%);
	}

	&__img {
		padding: 0;
		max-width: 100%;
		@include align-self(flex-end);
		@include flex(1 1 100%);

		img {
			height: auto;
			width: 100%;
			display: block;
		}
	}

	&__thumb {
		padding: 0 8px 8px 0;
		@include flex(1 0 auto);

		img {
			width: 64px;
			height: auto;
			margin: 0 auto 0 0;
			display: block;
		}

		&-img {
			width: 64px;
			height: 64px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}
}

// mealtime kit card
// ..........................................................................

.p-mealcard {
	min-height: 128px;
	cursor: pointer;
	padding: 12px 14px 12px 165px;
	background-color: $white;
	border: 3px solid $white;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
	position: relative;

	@include flexbox();
	@include justify-content(stretch);
	@include flex-wrap(nowrap);
	@include align-items(center);

	&:hover {
		// border-color: $red;
		h3 {
			text-decoration: underline;
		}
	}

	&__thumb {
		position: absolute;
		top: 12px;
		left: 14px;
		bottom: 12px;
		width: 128px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}

	&__text {
		max-width: 100%;
		@include flex(1 1 100%);
	}

	> a {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: $z3;
		transition: none;

		&:focus {
			outline: 1px dotted $red;
			outline-offset: 5px;
		}
	}

	h3 {
		@include pdp-h3();
		display: inline-block;
		padding: 0 0 5px 0;
		margin: 0;
		color: $red;

		@media only screen and (max-width: $mobile) {
			line-height: lh(24px, 16px);
			@include font-size(1.6);
		}
	}

	p {
		@include pdp-label();
		padding: 0;
		margin: 0;

		+ p {
			padding: 15px 0 0;
		}
	}
}

// OLO overrides
// ..........................................................................

// category bar
.pdp--olo {
	.frame {
		padding-top: calc(#{$nav-base-height} + #{$pdp-menu-height});

		@include break-min($large1) {
			padding-top: $nav-base-height;
		}
	}

	.pdp__sticky {
		@include break-min($large1) {
			top: $nav-base-height;
			height: calc(100vh - #{$nav-base-height});

			&--has-alert {
				// subtract alertbar height
				height: calc(100vh - #{$nav-base-height} - #{$pdp-alert-height});
			}
		}
	}

	@media only screen and (max-width: $mobile) {
		&.olo .olo-menu {
			padding: 5px 15px;
		}
	}

	.category-bar .home-menu {
		padding: 11px 0 9px;
	}
}

// ie
@include break-min($large1) {
	.ie .pdp--olo {
		.pdp__sticky {
			img {
				max-height: calc(70vh - #{$nav-base-height});
			}
		}
	}
}

// PDP nav overrides
// ..........................................................................

.pdp--olo .main-nav {
	position: static;

	@include break-min($large1) {
		margin: 0;
		@include flexbox();
		@include flex-wrap(nowrap);
		@include justify-content(stretch);
		@include align-items(stretch);
		@include flex(1 1 100%);

		> ul {
			position: relative;
			right: auto;
			left: auto;
			padding: 0 50px 0 0;
			min-height: 60px;
			margin: 0 0 0 auto;
			max-width: 230px;
			@include align-content(center);
			@include flex-wrap(wrap);
			@include flex(0 1 auto);

			.order {
				position: absolute;
				right: -8px;
				top: 0;
				bottom: 0;
				margin: auto;
				height: 50px;
				padding: 0;
			}

			.user {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				text-align: right;
				padding: 0 0 2px;
				@include flex(1 0 100%);

				.icon-user-active {
					display: none;
				}
			}
		}
	}
}

// pdp category list component
.pdp-cats {
	@media only screen and (max-width: #{$large1 - 1}) {
		box-shadow: inset 0 4px 4px -4px rgba(0, 0, 0, 0.2);
		background-color: $white;
		height: 100%;
		position: absolute;
		right: 0;
		top: 100%;
		left: 0;
		@include flex(1 1 100%);

		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			height: 5px;
			background-color: $white;
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
		}
	}

	@include break-min($large1) {
		@include flex(1 0 auto);
	}

	&__inner {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0 6px;
		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(center);
		@include flex-wrap(nowrap);

		@media only screen and (max-width: #{$large1 - 1}) {
			overflow-y: hidden;
			overflow-x: auto;

			// end spacer
			&::after {
				pointer-events: none;
				content: "";
				height: 1px;
				width: 10px;
				@include flex(0 0 auto);
			}
		}

		@include break-min($large1) {
			padding: 0 0 0 10px;
			min-height: 60px;
		}
	}

	&__link {
		@include font-size(1.6);
		border: 0;
		display: inline-block;
		padding: 10px;
		margin: 0 10px 0 0;
		font-family: $primary;
		color: $red;
		font-weight: $medium-weight;
		line-height: 1.2;
		letter-spacing: -0.5px;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		@include flex(0 0 auto);

		&--active,
		&:hover,
		&:focus {
			text-decoration: underline;
		}

		@include break-min($large1) {
			@include font-size(1.4);
			margin: 0;
		}

		@include break-min($large2) {
			@include font-size(1.6);
		}

		@include break-min($large3) {
			@include font-size(1.6);
			margin: 0 10px 0 0;
		}
	}

	+ ul.flex {
		position: relative;
	}
}
