.location-opening-date {
	flex-flow: column;

	.yellow-msg {
		color: $yellow-orange;
		margin-bottom: 0;
		@include type-p1();
	}

	.find-restaurant {
		cursor: pointer;
		text-decoration-color: $red;
		text-decoration: none;
		background: transparent;
		color: $red;
		border: none;
		padding: 0 10px 0 0;
		@include type-p2();
	}
}
