.grid-custom-layout {
	display: grid;
	grid-template-columns: repeat(4, [col-start] 1fr);

	@include break-min($medium4) {
		grid-template-columns: repeat(12, [col-start] 1fr);
	}

	@include break-min($huge4) {
		margin-left: auto !important;
		margin-right: auto !important;
		grid-template-columns: repeat(12, [col-start] 104px);
		max-width: 1512px;
		width: 100%;
	}
}
