.language-nav {
	@include p2();
	align-self: center;
	background: white;
	list-style-type: none;
	margin: 0;
	padding: 0;

	&.refresh {
		border-radius: 6px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
		margin: 0 auto;

		@include break-min($large1) {
			position: absolute;
			right: 72px;
			top: 100px;
			width: 200px;
			z-index: 100;
		}

		li {
			@include font-size(1.6);
			letter-spacing: -0.5px;
			line-height: lh(20, 16);
			width: 100%;

			&:first-child {
				font-weight: $bold-weight;
				height: 100%;
				padding: 0;

				a {
					align-items: center;
					color: $blue;
					height: 100%;
					margin: 0;
					padding: 19px 24px 14px;
					text-decoration: none;

					@include break-min($large1) {
						padding: 14px 18px 14px 19px;
					}
				}
			}
		}

		.lang-accord-toggle {
			&.expanded {
				color: $red;
			}

			&:after {
				height: 20px;
				right: 24px;
				width: 20px;
			}
		}

		.accord-toggle-content {
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
			padding: 0 24px;
			right: 0;
			top: 29px;
			width: 100%;

			@include break-min($large1) {
				border-radius: 12px;
				border-top-left-radius: 0px;
				border-top-right-radius: 0px;
			}

			li {
				color: $grey;
				cursor: pointer;
				height: auto;
				padding: 10px 0;

				&.selected {
					color: $red;

					&:after {
						background-image: url("img/language-nav/selected-check.svg");
						background-size: cover;
						content: "";
						bottom: 0;
						height: 13px;
						margin: auto;
						pointer-events: none;
						position: absolute;
						right: 5px;
						top: 0;
						transition: transform 0.2s ease;
						width: 15px;

						@include break-min($large1) {
							right: 0;
						}
					}
				}

				&:not(:last-child) {
					border-bottom: 1px solid $faint-grey;
				}
			}
		}
	}

	&.little-things {
		margin: 0 0 32px 0;

		@include break-min($large1) {
			margin: 0;
		}

		a {
			&:hover {
				text-decoration: none;
			}
		}

		.lang-accord-toggle {
			@include font-size(1.8);
			color: $grey;
			font-weight: $bold-weight;
			line-height: lh(22, 18);
			letter-spacing: -0.5px;
			margin: 0 26px 0 0;
			padding-right: 26px;
			width: 100%;

			&:after {
				height: 20px;
				width: 20px;
				right: 0;
			}
		}

		.accord-toggle-content {
			box-shadow: none;
			padding: 12px 12px 6px;
			left: 0;

			li {
				margin: 0 0 12px 0;

				&.selected {
					color: $red;

					&:after {
						background-image: url("img/language-nav/selected-check.svg");
						background-size: cover;
						content: "";
						bottom: 0;
						height: 13px;
						margin: auto;
						pointer-events: none;
						position: absolute;
						right: 5px;
						top: 0;
						transition: transform 0.2s ease;
						width: 15px;

						@include break-min($large1) {
							right: 0;
						}
					}
				}
			}
		}
	}

	li {
		padding-right: 10px;
		position: relative;
	}

	a {
		@include link-underline();
	}

	.flag-wrapper {
		border-radius: 100%;
	}

	.accord-toggle-content {
		background: $white;
		border-radius: 2px;
		box-shadow: 0 0 17px grey;
		display: none;
		padding: 3px 3px;
		position: absolute;
		right: 5px;
		top: 13px;
		z-index: 1000;

		li {
			display: flex;
		}

		img {
			height: 25px;
			width: 25px;
		}
	}

	.lang-accord-toggle {
		display: flex;
		margin-right: 10px;
		position: relative;

		&:after {
			content: "";
			background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
			background-size: cover;
			bottom: 0;
			height: 9px;
			width: 9px;
			margin: auto;
			pointer-events: none;
			position: absolute;
			right: -12px;
			top: 0;
			transition: transform 0.2s ease;
		}

		&.expanded {
			&:after {
				transform: rotate3d(1, 0, 0, 180deg);
			}
		}

		img {
			height: 25px;
			width: 25px;
		}
	}
}
