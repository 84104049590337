.lll-toggle-content {
	margin: 43px auto 0;
	padding: 0 16px;

	@include breakpoint(desktop) {
		margin: 58px auto 0;
		padding: 0;
	}

	&__tabs {
		width: 100%;

		.btn--toggle {
			width: 50%;
		}
	}

	.btn--toggle {
		@include font-size(1.8);
		background: $white;
		border: 2px solid $red;
		border-radius: 5px 0 0 5px;
		color: $red;
		cursor: pointer;
		display: inline-block;
		font-family: $primary;
		font-weight: 600;
		font-variant-ligatures: none;
		// sass-lint:disable no-vendor-prefixes
		-webkit-font-variant-ligatures: none;
		// sass-lint:enable no-vendor-prefixes
		float: left;
		letter-spacing: -0.56px;
		line-height: 17px;
		padding: 17px 15px 15px;
		text-align: center;
		text-transform: none;
		transition: all 0.2s ease-in-out;
		width: 100%;

		&.active {
			background: $red;
			border-color: transparent;
			color: $white;
		}

		&:last-of-type {
			border-radius: 0 5px 5px 0;
		}
	}
}
