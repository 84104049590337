.pwo-modal-search {
	position: relative;

	&[data-autocomplete-enabled="true"] {
		padding-bottom: 24px;

		.autocompleteOpen {
			.user-input {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom: 0;
			}
		}

		.user-input {
			&:focus {
				outline: none;
			}
		}

		.use-location {
			display: none;
		}
	}

	&[data-slide-animation="true"] {
		.search-container {
			.btn-round {
				@include font-size(1.6);
				display: none;
				border-radius: 0;
				border-bottom-right-radius: 8px;
				border-top-right-radius: 8px;
				height: 100%;
				min-width: 0;
				position: absolute;
				right: 0;
				top: 0;
				width: 80px;

				&:hover {
					transform: translateY(0px);
					transition: none;
				}
			}
		}
	}

	&.btnAnimation {
		.search-container:focus + .btn-round {
			border-radius: 0;
		}

		.search-container {
			img {
				border-right: 1px solid $faint-grey;
				padding-right: 21px;
			}

			.user-input {
				padding-left: 62px;
			}

			.btn-round {
				animation: 300ms btnSlide forwards;
				display: block;
			}
		}
	}

	h3 {
		@include font-size(2.4);
		color: $blue;
		font-weight: $bold-weight;
		letter-spacing: -0.5px;
		line-height: lh(34, 24);
		margin: 0 0 24px 0;
		text-align: center;
	}

	.wrapper {
		padding: 0;
		position: relative;
	}

	.search-container {
		margin-bottom: 16px;
		position: relative;
		width: 100%;

		img {
			bottom: 0;
			margin: auto;
			left: 13px;
			position: absolute;
			top: 0;
		}

		.user-input {
			@include font-size(1.6);
			background: $cool-white;
			border: 0.5px solid $rolling-stone;
			border-radius: 8px;
			height: 50px;
			padding-left: 42px;
			width: 100%;

			&::placeholder {
				color: $rolling-stone;
			}
		}

		.ui-autocomplete {
			background: $cool-white;
			border-bottom: 1px solid $grey;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			border-left: 0.5px solid $grey;
			border-right: 0.5px solid $grey;
			border-top: 0;
			padding: 0 11px;

			.ui-menu-item {
				@include font-size(1.4);
				letter-spacing: -0.25px;
				line-height: lh(23px, 14px);
				padding: 15px 31px;
				position: relative;

				a {
					color: $blue;
				}
			}

			li {
				border-top: 1px dashed $faint-grey;
			}
		}
	}

	.use-location {
		margin: 0 0 36px 13px;

		a {
			@include font-size(1.6);
			@include link-underline();
			color: $red;
			font-weight: $medium-weight;
			letter-spacing: -0.5625px;
			line-height: lh(24, 16);
		}
	}

	.btn-round {
		width: 100%;

		@include break-min($large1) {
			display: flex;
			justify-content: center;
			margin: auto;
			min-width: 144px;
			width: auto;
		}
	}
}

@keyframes btnSlide {
	from {
		opacity: 0;
		right: -5px;
		width: 0;
	}

	to {
		right: 0;
		opacity: 1;
		width: 79px;
	}
}
