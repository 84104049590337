$logo-square-large: 300px;
$logo-square-small: 150px;

$logo-square-small-left: 25px;
$logo-square-small-top: 65px;

.story-carousel-frame {
	position: relative;
}

.story-carousel-overlay {
	left: 0;
	position: absolute;
	top: 0;
	z-index: 1;

	.story-carousel-step {
		display: none;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		transition: all 1s ease;
	}

	.story-carousel-step-logo-frame {
		box-sizing: border-box;
		display: none;
		left: 0;
		position: absolute;
		top: 0;
		z-index: 2;

		.story-carousel-step-logo {
			background: url("img/cs-logo.png");
			background-size: cover;
			position: absolute;
			z-index: 2;
		}

		.story-carousel-step-logo-slogan {
			left: 0;
			position: absolute;
			text-align: center;
			z-index: 2;
		}
	}

	.story-carousel-step-1 {
		display: block;
		background-color: $white;

		.centered {
			position: absolute;

			@media only screen and (max-width: $mobile) {
				text-align: center;
			}
		}
	}

	&.step-2-active {
		.story-carousel-step-1 {
			display: block;
			background-color: $white;
		}

		.story-carousel-step-2 {
			display: block;
		}

		.story-carousel-step-logo-frame {
			display: block;
			opacity: 0;
		}
	}

	&.step-3-active {
		.story-carousel-step-1 {
			display: none;
		}

		.story-carousel-step-3 {
			display: block;
		}
	}

	.plank {
		position: absolute;
		left: 0;
		top: 0;
		background: url("img/wood-plank-1.jpg") no-repeat;
		height: 228px; // background-image height / 2
		background-size: cover;

		&:nth-child(2) {
			top: 228px;
			background: url("img/wood-plank-2.jpg") no-repeat;
			background-size: cover;
		}

		&:nth-child(3) {
			top: 456px;
			background: url("img/wood-plank-3.jpg") no-repeat;
			background-size: cover;
		}

		&:nth-child(4) {
			top: 684px;
			background: url("img/wood-plank-4.jpg") no-repeat;
			background-size: cover;
		}
	}

	.flower {
		display: block;
		opacity: 0;
		position: absolute;

		&.flower-1 {
			background: url("img/camomile_1.png") no-repeat;
			background-size: cover;
			right: -170px;
			top: -25px;
			width: (1885px / 5);
			height: (1865px / 5);
		}

		&.flower-2 {
			background: url("img/camomile_2.png") no-repeat;
			background-size: cover;
			left: -80px;
			bottom: 40px;
			width: (280px * 0.6);
			height: (267px * 0.6);
		}
	}
}
