.content-hub-headline-links {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1510px;
	margin: 0 32px 32px;
	column-gap: 20px;
	align-items: baseline;

	@include break-min($medium4) {
		margin: 0 46px 32px;
	}

	@include break-min($large3) {
		margin: 0 72px 42px;
	}

	@include break-min($huge4) {
		margin-left: auto !important;
		margin-right: auto !important;
		padding: 0 !important;
	}

	&.product-landing {
		margin-left: auto;
		margin-right: auto;
		max-width: 1348px;
		padding-left: 24px;
		padding-right: 24px;

		@include break-min($large1) {
			padding-left: 0;
			padding-right: 0;
		}

		p {
			@include font-size(2.4);

			@include break-min($large1) {
				@include font-size(3.6);
			}
		}

		a {
			@include font-size(1.8);

			@include break-min($large1) {
				@include font-size(2.4);
			}
		}
	}

	&.margin-bottom-20 {
		margin-bottom: 20px;
	}

	h2 {
		text-align: left;
		font-weight: bold;
		max-width: 100%;
		margin: initial;
		color: $blue;

		@include font-size(2.4);

		@include break-min($large1) {
			@include font-size(3.6);
		}
	}

	h3 {
		a {
			white-space: nowrap;
			@include font-size(1.8);

			@include break-min($large1) {
				@include font-size(2.4);
			}
		}
	}

	a {
		font-weight: bold;
		text-align: right;
	}
}
