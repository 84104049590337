.one-landing {
	.block_img_header_txt {
		@extend .one-block_img_header_txt;
		background: $warm-white;
		padding: 22px 0 68px;

		@include break-min($large1) {
			align-items: center;
			margin-top: -80px;
			padding: 128px 86px 128px;
		}

		.img-wrapper {
			background-color: $white;
			border-radius: 50%;
			width: 180px;
			padding: 50px;
			margin: 0 auto;
			margin-top: 35px;
			margin-bottom: 35px;

			@include break-min($large1) {
				width: 218px;
				padding: 60px;
				margin: 0;
			}

			img {
				width: 100%;
				height: auto;
				margin-bottom: 0;
			}
		}

		.content {
			@include break-min($large1) {
				margin-left: 60px;
			}
		}
	}
}
