.title-bullet-list-callout {
	@include background-color();
	margin: 0 auto;
	max-width: 192rem;
	padding: 42px 24px;

	@include break-min($large1) {
		padding: 64px 46px;
	}

	@include break-min($large2) {
		display: flex;
		padding: 80px 104px;
	}

	@include break-min($huge2) {
		padding: 72px 128px;
	}

	@include break-max($medium2) {
		padding: 42px 24px 64px;
	}

	&.warm-white {
		background-color: $warm-white;
	}

	.title {
		text-align: center;
		margin-bottom: 40px;

		@include break-min($large2) {
			margin-top: auto;
			margin-bottom: auto;
			text-align: left;
			width: 50%;
		}

		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);
			margin: 0 0 24px;
			padding: 0;

			@include break-min($large2) {
				@include font-size(4.8);
				line-height: lh(58, 48);
				width: 77%;
			}
		}

		p {
			@include font-size(1.8);
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
			margin-bottom: 40px;

			@include break-min($large2) {
				width: 77%;
			}
		}

		p:last-of-type {
			margin-bottom: 0;
		}

		img {
			margin-bottom: 40px;

			@include break-min($large2) {
				display: none;
			}
		}

		a,
		button {
			margin-top: 20px;
		}
	}

	ul {
		margin: 0;
		padding: 0 0 0 16px;

		@include break-min($large2) {
			width: 50%;
		}

		li {
			@include font-size(1.8);
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
			margin-bottom: 36px;
			position: relative;

			@include break-min($large2) {
				margin-bottom: 26px;
			}

			&::marker {
				color: $red;
				line-height: 0;
				@include font-size(2);
			}

			&:last-child {
				margin-bottom: 0;
			}

			span {
				display: block;
				margin-left: 0;
			}
		}
	}

	&.numbers {
		counter-reset: section;

		.title {
			text-align: left;

			@include break-min($large2) {
				margin-top: 0;
			}

			> *:first-child {
				color: $red;
				font-weight: $bold-weight;
				letter-spacing: -1%;
				line-height: 54px;
				margin: 0 0 24px;
				padding: 0;
				@include font-size(4.8);

				@include break-min($large2) {
					@include font-size(7.8);
					line-height: 84px;
					width: 77%;
				}
			}
		}

		ul {
			padding: 0;

			li {
				display: flex;

				&::before {
					counter-increment: section;
					content: counter(section);
					margin-right: 20px;
					border-radius: 9999px;
					border: 2px solid $red;
					padding: 6px 10px;
					flex: 34px 0 0;
					text-align: center;
					height: fit-content;
					color: $red;
					@include font-size(2.7);
				}

				@include break-min($large1) {
					&::before {
						margin-right: 25px;
					}
				}
			}
		}
	}
}
