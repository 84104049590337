// sass-lint:disable no-important
.hero-menu-detail {
	background: $white;
	display: flex;
	flex-direction: column-reverse;
	height: auto;
	padding: 0 0 25px;

	&.red-blurb {
		background-color: $red;
	}

	&.featured-product-video-hero {
		.video-container {
			height: 340px;
			margin-top: 20px;
			order: 2;
			width: 100%;

			@include break-min($large1) {
				height: 600px;
				margin: 0 auto;
			}
		}

		.wrapper {
			margin-top: 0;
			max-width: 1077px;

			@include break-min($large1) {
				padding: 0;
			}
		}

		.tiled-image {
			display: none;
		}
	}

	@include break-min($large1) {
		padding: 0 62px 0;
	}

	.wrapper {
		margin: 50px auto 0;

		@include break-min($large1) {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 50px auto;
		}

		p:first-child {
			@include font-size(1.4);
			border-radius: 20px;
			color: $white;
			margin: 0px;
			padding: 1px 16px;
			width: fit-content;
		}

		> p:first-of-type {
			background: $blue;
			margin: 0 auto 16px;
			font-weight: $regular-weight;
		}

		h1 {
			color: $blue;
			text-align: center;

			@include break-min($large1) {
				@include font-size(6.4);
			}
		}

		.btn-container {
			margin: 0;

			a {
				height: auto;
				margin: 0;
				padding: 20px 0;

				@include break-min($large1) {
					min-width: 255px;
				}

				&:first-child {
					margin: 0 auto 16px;

					@include break-min($large1) {
						margin: 0 16px 0 0;
					}
				}
			}
		}
	}

	.tiled-image {
		order: 1;
		overflow: hidden;
	}

	.desktop-img {
		margin: auto;
		width: auto !important;
	}

	&.with-app-icons {
		.btn-container {
			a {
				background: transparent;
				border: none;
				box-shadow: none;
				min-width: fit-content;

				&:hover {
					box-shadow: none;
				}
			}

			img {
				margin: 0;
			}

			p {
				display: none;
			}
		}
	}
}
