// Seeks to standardize spacing btw components. These classes can be applied to get the spacing right.

$med: 42px;
$lrg: 98px;

$spacing-options: (
	"-40x": -160px,
	"-36x": -144px,
	"-32x": -128px,
	"-28x": -112px,
	"-24x": -96px,
	"-20x": -80px,
	"-16x": -64px,
	"-14x": -56px,
	"-12x": -48px,
	"-10x": -40px,
	"-9x": -36px,
	"-8x": -32px,
	"-7x": -28px,
	"-6x": -24px,
	"-5x": -20px,
	"-4x": -16px,
	"-3.5x": -14px,
	"-3x": -12px,
	"-2.5x": -10px,
	"-2x": -8px,
	"-1.5x": -6px,
	"-1x": -4px,
	"0x": 0px,
	"1x": 4px,
	"1.5x": 6px,
	"2x": 8px,
	"2.5x": 10px,
	"3x": 12px,
	"3.5x": 14px,
	"4x": 16px,
	"5x": 20px,
	"6x": 24px,
	"7x": 28px,
	"8x": 32px,
	"9x": 36px,
	"10x": 40px,
	"12x": 48px,
	"14x": 56px,
	"16x": 64px,
	"20x": 80px,
	"24x": 96px,
	"28x": 112px,
	"32x": 128px,
	"36x": 144px,
	"40x": 160px
);

.margin-med {
	margin-bottom: $med !important;
}

.desk-margin-lrg {
	@include break-min($large1) {
		margin-bottom: $lrg !important;
	}
}

// Create utility classes for content authors to add space as needed
@each $key, $val in $spacing-options {
	.mb-#{$key} {
		@include break-max($large1) {
			margin-bottom: $val !important;
		}
	}

	.mb-desk-#{$key} {
		@include break-min($large1) {
			margin-bottom: $val !important;
		}
	}

	.mt-#{$key} {
		@include break-max($large1) {
			margin-top: $val !important;
		}
	}

	.mt-desk-#{$key} {
		@include break-min($large1) {
			margin-top: $val !important;
		}
	}
}
