.one-hero {
	height: auto;
	position: relative;
	background-color: $white-smoke;
	padding-bottom: 100px;

	@include break-min($large1) {
		height: 456px;
		margin-bottom: 122px;
		min-height: 340px;
		overflow: visible;
		padding-bottom: 0;
	}

	a {
		padding: 15px 60px;
	}

	h1 {
		@include font-size(3.6);
		color: $blue;
		font-family: $primary;
		font-weight: 700;
		letter-spacing: -0.8px;
		margin: 0 0 2rem 0;
		line-height: 48px;

		@include break-min($medium3) {
			@include font-size(6.4);
			letter-spacing: -0.5px;
			line-height: 74px;
		}
	}

	p {
		@include font-size(1.8);
		width: 100%;
		color: $shuttle-grey;
		font-weight: 500;
		letter-spacing: -0.5px;
		margin-bottom: 25px;
	}

	.oval-icon {
		bottom: -60px;
		left: 0;
		margin: 0 auto;
		position: absolute;
		right: 0;
		border: 20px solid white;
		border-radius: 50%;
	}

	&-content {
		justify-content: space-around;
		align-items: center;
		max-width: 1380px;
		margin: 0 auto;
		width: 100%;
		flex-flow: column-reverse;
		text-align: center;
		padding: 0 15px;

		@include break-min($large1) {
			flex-flow: row;
			text-align: left;
			padding: 25px 25px 0;
		}

		.one-half {
			&:first-child {
				@include break-min($large1) {
					margin-right: 100px;
				}
			}
		}
	}
}
