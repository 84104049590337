.signature-drop-form {
	@include break-min($large1) {
		align-self: center;
		max-width: 480px;
	}

	.dropdown-selection {
		@extend .global-form-select;
		margin: 0 0 48px 0;

		label {
			color: $grey;
		}

		select {
			color: $rolling-stone;
		}

		&.hasError {
			@extend .global-form-error;
		}
	}

	.shipping-address {
		margin: 0 0 48px;

		p:first-child {
			@include font-size(1.8);
			color: $grey;
			font-weight: $bold-weight;
			letter-spacing: -0.5px;
			line-height: lh(22, 18);
			margin-bottom: 18px;
		}

		.radio-input {
			@extend .global-form-radio;

			label {
				line-height: lh(28, 18);
				margin-bottom: 5px;
				padding: 0 0 0 26px;

				&:before {
					bottom: unset;
					top: 4px;
				}
			}

			input {
				&:checked {
					+ label {
						&::after {
							top: 7px;
							bottom: unset;
						}
					}
				}
			}
		}

		.custom-shipping {
			@extend .global-form-input;
			margin: 16px 0 0;

			.additional-info {
				margin: 8px 0 0 0;

				p {
					@include font-size(1.4);
					color: $rolling-stone;
					font-weight: 400;
					letter-spacing: -0.25px;
					line-height: lh(23, 14);

					&:first-child {
						margin-bottom: 8px;
					}
				}
			}

			.input-wrapper {
				position: relative;

				&:first-of-type {
					margin-bottom: 15px;
				}

				&.hasError {
					@extend .global-form-error;
				}
			}

			.error-container {
				padding: 3px 0 0 3px;

				label {
					color: $red;
					position: unset;
				}
			}
		}

		label[for="custom"] {
			color: $red;
		}
	}

	.custom-shipping {
		display: none;
	}

	.disclaimer-text {
		p {
			@include font-size(1.4);
			letter-spacing: -0.25px;
			line-height: lh(23, 14);
		}
	}

	.error-container {
		padding: 3px 0 0 3px;

		label {
			position: unset;
		}
	}

	button[type="submit"] {
		margin-bottom: 64px;

		@include break-min($large1) {
			width: 144px;
		}

		&.disabled {
			pointer-events: none;
		}
	}
}
