.unsubscribe-header {
	background: $red;
	height: 88px;
	position: relative;

	img {
		@include absolute-center();
		height: 52px;
		margin: auto;
		width: 52px;
	}
}
