.infographic {
	margin: 0 auto;
	max-width: 192rem;
	padding: 64px 24px 0;

	@include break-min($large1) {
		padding: 64px 46px 0;
	}

	@include break-min($large2) {
		padding: 80px 24px 0;
	}

	@include break-min($huge2) {
		padding: 72px 68px 0;
	}

	.header {
		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);
			margin-bottom: 40px;
			text-align: center;

			@include break-min($large2) {
				@include font-size(4.8);
				line-height: lh(58, 48);
				margin: 0 264px 48px;
			}
		}
	}

	.container--icon-block {
		align-items: center;
		background-color: $red;
		color: $white;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 26px;
		padding: 64px 20px;

		@include break-min($large2) {
			align-items: start;
			flex-direction: row;
			justify-content: space-around;
			padding: 76px 34px;
		}

		.container--column {
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-width: 250px;

			&:not(:last-of-type) {
				margin-bottom: 32px;
			}

			@include break-min($large2) {
				max-width: 270px;

				&:not(:last-of-type) {
					margin: 0;
				}
			}

			.icon {
				height: 55px;
				margin-bottom: 25px;
				text-align: center;
				width: auto;

				@include break-min($large1) {
					margin-bottom: 35px;
				}
			}

			p {
				@include font-size(1.8);
				font-weight: $regular-weight;
				letter-spacing: -0.5px;
				line-height: lh(28, 18);
				margin-bottom: 0;
				text-align: center;

				span {
					@include font-size(6.4);
					display: block;
					font-weight: $bold-weight;
					letter-spacing: -0.8px;
					line-height: lh(45, 36);
					margin-bottom: 8px;

					@include break-min($large2) {
						letter-spacing: -1.5px;
						line-height: lh(74, 64);
					}
				}
			}
		}
	}
}
