.gifting-lightbox {
	@extend .lightbox;
	@extend .vis-lightbox;
	max-width: 1000px;
	padding: 0;
	position: relative;

	.mfp-close {
		@extend .vis-lightbox__close;
		cursor: pointer;
		right: 26px;
		top: 18px;
	}

	div {
		@include break-max($large1) {
			border-radius: 0;
		}
	}

	&.state-success {
		.state-error {
			display: none;
		}
	}

	&.state-error {
		.state-success {
			display: none;
		}
	}
}
