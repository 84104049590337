.one-survey {
	&-header {
		margin: 0 auto;
		padding: 20px 16px;
		position: relative;
		text-align: center;

		@include breakpoint(desktop) {
			padding: 30px 24px;
		}

		h1 {
			@include font-size(2.8);
			display: block;
			margin: 0 auto;
			padding: 0 0 10px;
			font-family: $primary;
			font-weight: $bold-weight;
			line-height: lh(34px, 28px);
			letter-spacing: -1px;
			color: $vis-blue;

			@include break-min($medium1) {
				@include flex(1 1 auto);
				@include font-size(3.9);
				letter-spacing: -1px;
				padding: 0;
			}
		}
	}

	&-progress {
		height: 13px;
		padding: 45px 0;
		@include flexbox();
		justify-content: space-evenly;

		.progress-bar {
			background: $iron;
			border-radius: 25px;
			height: 10px;
			margin: 10px 10px;
			position: relative;
			display: inline;
			width: 100%;

			&-status {
				background-color: $red;
				border-radius: 25px;
				display: block;
				height: 100%;
				position: relative;
				overflow: hidden;
				width: 0%;
				transition: width 1s ease;

				&.full {
					width: 100%;
				}

				&.half {
					width: 50%;
				}
			}
		}
	}

	.required-indicator {
		color: $rolling-stone;
		letter-spacing: -0.5px;
		padding: 0 20px 10px;
		display: block;
		@include font-size(1.2);

		@include break-min($large1) {
			padding: 0 0 10px;
		}
	}

	.questions {
		position: relative;
		padding: 45px 0;
		@include flexbox();
		justify-content: space-evenly;
		transition: background-color 0.5s ease;

		&:nth-child(odd) {
			background-color: $cool-white;
		}

		.required {
			color: $red;
			text-align: center;
			position: absolute;
			bottom: 0;
			display: none;
		}

		&.error {
			background-color: $validation-red;

			.l-radio {
				input {
					box-shadow: inset 0 0 0 1.5px $red;
				}
			}

			.required {
				display: block;
			}
		}

		.l-radio {
			padding: 6px 0;
			border-radius: 50px;
			display: inline-flex;
			cursor: pointer;
			transition: background 0.2s ease;
			margin: 8px 0;
			align-items: center;
			@include flex-direction(column);

			@include break-min($large1) {
				padding: 6px 6px;
			}

			input {
				vertical-align: middle;
				width: 20px;
				height: 20px;
				border-radius: 10px;
				background: $white;
				border: 0;
				box-shadow: inset 0 0 0 1.5px $grey;
				appearance: none;
				padding: 0;
				margin: 0;
				transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
				pointer-events: none;

				&:focus {
					outline: none;
				}

				&:checked {
					box-shadow: inset 0 0 0 6px $red;
				}
			}

			span {
				vertical-align: middle;
				display: inline-block;
				line-height: 13px;
				padding: 20px 8px;
				width: 90px;
				text-align: center;
				font-size: 11px;
				color: $rolling-stone;

				@include break-min($large1) {
					width: 160px;
				}
			}

			.q-indicator {
				color: $red;
				font-size: 16px;
				text-align: center;
				display: block;
				font-weight: 800;
			}
		}
	}

	.set {
		// opacity: 0;
		// visibility: hidden;
		display: none;
	}

	.questions-set-two {
		.questions {
			align-items: center;

			.age-label {
				margin-top: 50px;
				@include font-size(1.2);

				@include break-min($large1) {
					width: 120px;
					@include font-size(1.6);
				}
			}

			.questions-group {
				@include flexbox();

				span {
					width: 50px;

					@include break-min($large1) {
						width: 75px;
					}
				}
			}
		}
	}

	&-footer {
		height: 64px;
		align-items: center;
		margin-top: 50px;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
		position: fixed;
		bottom: 0;
		width: 100%;
		background-color: $white;
		@include flexbox();
		justify-content: space-evenly;

		&-navigation {
			justify-content: space-between;
			@include flexbox();
			padding: 15px;

			@include break-min($large1) {
				padding: 0;
			}
		}

		.prevBtn {
			pointer-events: none;
			opacity: 0;
		}

		.btn {
			padding: 15px 41px;
			margin: 0 5px;

			@include break-min($large1) {
				margin: 0;
			}
		}

		.btn-white {
			background: $white;
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
			color: $red;

			&:hover {
				background: $white;
				box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
				color: $red;
			}

			@include break-min($large1) {
				margin: 0 0 0 12px;
			}
		}
	}

	&-end {
		display: none;

		&-header {
			background-color: $red;
			text-align: center;
			color: $white;
			padding: 64px 45px;

			@include break-min($large1) {
				padding: 72px 0;
			}

			h1 {
				@include font-size(2.8);
				display: block;
				margin: 0 auto;
				padding: 0 0 10px;
				font-family: $primary;
				font-weight: $bold-weight;
				line-height: lh(34px, 28px);
				letter-spacing: -1px;
				color: $white;

				@include break-min($large1) {
					@include font-size(4.8);
				}
			}

			p {
				line-height: lh(48px, 28px);
			}
		}

		&-content {
			padding: 0;
			@include flexbox();
			justify-content: space-evenly;
			flex-flow: column-reverse;

			@include break-min($large1) {
				flex-flow: row;
				padding: 40px;
			}

			.col {
				width: 100%;
				position: relative;

				.center-align {
					position: relative;
					padding: 30px 25px;

					@include break-min($large1) {
						position: absolute;
						top: 50%;
						margin-left: auto;
						margin-right: auto;
						width: 460px;
						left: 0;
						right: 0;
						padding: 0;
						transform: translateY(-50%);
					}
				}

				@include break-min($large1) {
					width: 50%;
				}

				h2 {
					@include font-size(2.8);
					display: block;
					margin: 0 auto;
					padding: 0 0 10px;
					font-family: $primary;
					font-weight: $bold-weight;
					line-height: lh(34px, 28px);
					letter-spacing: -1px;
					color: $vis-blue;

					@include break-min($large1) {
						@include font-size(4.8);
					}
				}

				p {
					line-height: lh(48px, 28px);
					@include font-size(1.8);
				}

				.btn {
					margin-top: 20px;
				}
			}
		}
	}

	.survey-wrapper {
		width: 100%;
		margin: 0 auto;

		@include breakpoint(desktop) {
			max-width: 684px;
		}
	}

	.p-b-100 {
		padding-bottom: 100px;
	}
}
