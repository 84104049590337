.customer-support-quick-links {
	list-style-type: none;
	margin: -50px 24px 40px;
	padding: 0;

	@include break-min($large1) {
		display: flex;
		column-gap: 16px;
		justify-content: center;
		margin-bottom: 64px;
	}

	li {
		@include btn-hover-effect();
		background: $white;
		border-radius: 16px;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
		height: 84px;
		margin-bottom: 16px;

		@include break-min($large1) {
			height: 125px;
			margin: 0;
			padding-bottom: 16px;
			text-align: center;
			width: 264px;
		}

		a {
			align-items: center;
			display: flex;
			height: 100%;
			margin-left: 32px;

			@include break-min($large1) {
				flex-direction: column;
				height: 100%;
				justify-content: flex-end;
				margin: 0;
				width: 100%;
			}

			img {
				margin-right: 32px;
				width: 48px;

				@include break-min($large1) {
					margin: 0 0 16px 0;
					width: 59px;
				}
			}

			p {
				@include font-size(1.8);
				font-weight: $bold-weight;
				line-height: lh(22, 18);
				margin: 0;
			}
		}
	}
}
