@import "../../common/component-level-styles/background-color";
@import "../../common/component-level-styles/text-color";
// Justification - must overwrite from base - .facts.
// sass-lint:disable no-important
.sunshine {
	@include background-color();
	@include text-color();

	.stats {
		display: flex;
		flex-wrap: wrap;
		justify-content: center !important;

		.fact {
			margin: 0 0 50px 0;

			@include break-min($large1) {
				width: 33% !important;
			}

			.highlight {
				color: inherit !important;
			}

			img {
				height: 110px;
				width: 110px;
				margin-bottom: 0px;

				@include break-min($large1) {
					height: 185px;
				}
			}
		}
	}
}
