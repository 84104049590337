.location-address-map {
	margin: 25px 0;

	.split-content {
		flex: 1 0 55%;

		// @include break-max($large1) {
		// 	flex: 1;
		// }
	}

	.btn {
		@include font-size(1.1);

		img {
			margin-right: 3px;
			margin-bottom: 2px;
		}
	}

	p {
		&.address {
			margin: 0;
			@include font-size(1.6);
		}

		&.distance {
			@include break-max($large1) {
				display: none;
			}
		}
	}
}
