.sunshine {
	background: $warm-white;
	display: block;
	margin-bottom: 0px;

	@include break-min($large1) {
		height: auto;
	}

	.flex-wrapper {
		flex-direction: row-reverse;
		justify-content: space-around;

		@include break-min($large1) {
			@include desktop-grid();
		}

		.content {
			padding: 20px 33px;
			text-align: center;

			@include break-min($large1) {
				grid-column: col-start 9 / span 3;
				margin-left: 0px;
				margin-right: 0px;
				padding: 24px 0;
				width: auto;
			}

			h4 {
				color: $red;
				margin-bottom: 24px;
			}

			.headline {
				p {
					font-weight: $bold-weight;
					color: $dkgrey;
					margin-bottom: 16px;
				}
			}
		}

		.img-wrapper {
			height: 450px;
			padding-left: 0px;
			order: 2;

			@include break-min($large1) {
				height: 576px;
				grid-column: col-start 1 / span 7;
				position: relative;
			}

			img {
				@include absolute-center();
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
	}
}
