.rewards-callout {
	margin: -50px 0 64px;

	@include break-min($large1) {
		margin: -40px 0 64px;
	}

	.flex-wrapper {
		@include break-min($large1) {
			display: grid;
			grid-auto-flow: column;
			grid-template-columns: repeat(12, [col-start] 1fr);
		}
	}

	.icon {
		background: $white;
		border-radius: 50%;
		margin: 0 auto 23px;
		padding: 10px;
		text-align: center;
		width: fit-content;
	}

	.img-container {
		@include swap-img();
		border-radius: 32px;
		height: 212px;
		overflow: hidden;
		margin: 0 0 27px 0;
		position: relative;
		width: 100%;

		@include break-min($large1) {
			height: 414px;
			grid-column: col-start 7 / span 6;
		}

		img {
			@include absolute-center();
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.content {
		padding: 0 23px;

		@include break-min($large1) {
			grid-column: col-start 2 / span 4;
			margin: auto;
		}

		.content-icon {
			align-items: center;
			margin: 0 0 16px 0;

			p {
				@include font-size(1.4);
				line-height: lh(14, 14);
				margin: 0 0 0 8px;
				font-weight: $bold-weight;
				letter-spacing: -0.5px;

				@include break-min($large1) {
					@include font-size(1.8);
				}
			}
		}

		.title {
			> *:first-child {
				@include font-size(2.4);
				color: $blue;
				font-weight: $bold-weight;
				line-height: lh(32, 24);
				letter-spacing: -0.01em;
				margin: 0 0 16px 0;

				@include break-min($large1) {
					@include font-size(3.6);
					letter-spacing: -0.5px;
					line-height: lh(42, 36);
				}
			}
		}

		.subparagraph {
			@include font-size(1.8);
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
		}
	}

	.btn-container {
		@include break-min($large1) {
			display: flex;
		}

		:first-child {
			margin: 0 0 8px 0;

			@include break-min($large1) {
				margin: 0 0 0 16px;
				order: 2;
			}
		}

		.btn-round {
			min-width: 160px;
			width: fit-content;
		}
	}
}
