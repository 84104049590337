.nge-membership-tiers {
	margin-top: 45px;
	position: relative;
	text-align: center;

	@media screen and (min-width: $mobile) {
		margin-top: 35px;
	}

	&.dotted-border-bottom {
		&::after {
			@include dot-border-h(bottom, left);
		}
	}

	&.wrapper {
		@media screen and (max-width: $mobile) {
			padding: 0;
			margin: 0;
		}
	}

	.header {
		margin: auto;
		width: $col10;

		h3 {
			@include h3();
			color: $blue;
		}

		p {
			@include p1();
			margin-bottom: 50px;
		}
	}

	ul {
		margin: 0;
		padding: 0;
	}

	// .circle-border,
	.icon-wrapper {
		border-radius: 100%;
		height: 65px;
		width: 65px;
		margin: auto;
		position: relative;

		@include break-min($medium3) {
			height: 75px;
			width: 75px;
		}
	}

	.member-nav {
		background: $cadet-blue; //dynamic value via JS
		display: flex;
		justify-content: space-around;
		position: relative;
		text-align: center;

		@include break-min($small1) {
			margin-left: -15px;
			margin-right: -15px;
		}

		@include break-min($small4) {
			margin-left: -30px;
			margin-right: -30px;
		}

		@include break-min($small5) {
			margin-left: 0;
			margin-right: 0;
		}

		&.silver-bckgrd {
			background: $membership-silver;
		}

		&.red-bckgrd {
			background: $membership-red;
		}

		&.signature-bckgrd {
			background: $membership-signature;
		}

		@media screen and (min-width: $mobile) {
			justify-content: center;
		}

		li {
			padding: 22px 0 20px;
			list-style: none;
			text-indent: 0;
			margin: 0 10px;
			width: 25%;

			@include break-min($medium3) {
				padding: 22px 0 10px;
			}

			p {
				opacity: 0.6;
			}

			span {
				@include p2();
				font-family: $secondary;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 2px;
			}

			&.arrow_box {
				p {
					opacity: 1;
				}
			}
		}

		a:focus {
			outline: none;
		}

		.arrow_box {
			position: relative;

			&:after {
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(136, 183, 213, 0);
				border-top-color: $white;
				border-width: 18px;
				border-block-width: 13px;
				transform: rotate(180deg);
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				bottom: -1px;
			}
		}

		.icon-wrapper {
			border: 1px solid white;

			&.selected {
				background: $white;
			}

			img {
				display: none;
				height: 75px;
				margin: auto;
				padding: 15px;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				&.active {
					display: block;
				}
			}
		}

		a {
			color: $white;
			text-decoration: none;

			p {
				line-height: 21px;
				margin-top: 10px;
			}
		}

		.tier-descriptor {
			display: none;

			@include break-min($medium3) {
				display: block;
			}
		}
	}

	.member-tier-benefits {
		margin-top: 30px;

		.tier-descriptor {
			display: block;
			position: relative;
			margin: 0px 20px;
			padding: 12px 20px;
			text-align: center;
			color: $blue;

			&:after {
				@include dot-border-h;
			}

			p {
				font-size: 24px;
				line-height: 26px;
				letter-spacing: -0.5px;
				font-weight: 700;
			}

			span {
				@include p2();
				font-family: $secondary;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 2px;
			}

			@include break-min($medium3) {
				display: none;
			}
		}

		&.shadow-border {
			border: 1px solid lightgrey;
			border-top: 0;
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
			margin: 0px 20px;
			padding-top: 30px;

			@media screen and (min-width: $mobile) {
				margin: 0;
			}
		}

		ul {
			padding-bottom: 20px;

			@include break-min($medium3) {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				padding-bottom: 0;
			}

			&.no-display {
				display: none;
			}
		}

		li {
			margin-bottom: 10px;
			list-style: none;
			text-indent: 0;
			margin: 0 auto;
			padding: 12px 20px;

			@include break-min($medium3) {
				padding: 12px 50px;
				width: 33.3333%;
			}

			@include break-min($large2) {
				padding: 12px 5%;
			}
		}

		.icon-wrapper {
			border-radius: 100%;
			color: $blue;
			height: 75px;
			width: 75px;
			margin: auto;
			position: relative;

			img {
				padding: 13px;
				margin: auto;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}
		}

		.circle-border {
			padding: 35px 0 20px;
			white-space: nowrap;
			font-weight: 700;
			margin: 0;
		}

		p {
			@include font-size(1.3);
			font-family: $primary;
			font-weight: $medium-weight;
			line-height: lh(20px, 12px);
			// sass-lint:disable no-vendor-prefixes
			-moz-osx-font-smoothing: grayscale;
			// sass-lint:enable no-vendor-prefixes
			margin: 0;

			@include break-min($medium3) {
				margin: 10px 0;
			}
		}
	}
}
