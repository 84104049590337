.userform {
	fieldset {
		margin-bottom: 30px;
	}

	legend {
		@include font-size(1);
		font-family: $primary;
		letter-spacing: 2px;
		line-height: 20px;
		margin: 0 0 15px 0;
		text-transform: uppercase;
	}

	label {
		display: block;
		font-family: $primary;
	}

	.error {
		color: $red;

		input {
			&[type="text"],
			&[type="email"] {
				border-color: $red;
			}
		}

		.e-msg {
			display: inline-block;
		}

		.opt,
		.msg {
			& + .e-msg {
				display: block;
			}
		}
	}

	.e-msg {
		@include font-size(1.2);
		display: none;
		font-family: $primary;
		line-height: 20px;
		margin-left: 25px;

		&.ph {
			height: 18px;
		}
	}

	.opt,
	.msg {
		@include font-size(1.2);
		display: inline-block;
		float: right;
		font-family: $primary;
		line-height: 20px;
		margin: 4px 0 0;

		& + .e-msg {
			margin-left: 0;
		}
	}

	input {
		&[type="text"],
		&[type="email"],
		&[type="password"],
		&[type="number"] {
			border: 3px solid $faint-grey;
			border-radius: 5px;
			font-family: $primary;
			margin: 10px 0;
			padding: 10px 5px;
			width: 100%;

			&:focus {
				// sass-lint:disable variable-for-property
				background-color: #f2f6f5;
				// sass-lint:enable variable-for-property
			}

			@include placeholder {
				// sass-lint:disable variable-for-property
				color: #aeaba8;
				// sass-lint:enable variable-for-property

				font-family: $primary;
			}

			&.short {
				width: $col4;
			}

			@media only screen and (max-width: $mobile) {
				box-sizing: border-box;
			}
		}
	}

	textarea,
	.selectlist {
		@extend input[type="text"];
	}

	.selectlist {
		display: block;
		padding: 5px 5px 5px 0;
		position: relative;

		&.quantity {
			width: $col2;
			min-width: 60px;
		}

		&.disabled {
			background-color: $white-smoke;
			border-color: $white-smoke;

			select {
				// sass-lint:disable variable-for-property
				color: #ddd;
				// sass-lint:enable variable-for-property
			}

			.icon-arrow-down {
				color: $faint-grey;
			}
		}

		@media only screen and (max-width: $mobile) {
			box-sizing: border-box;
		}
	}

	.time-select {
		@include justify-content(space-between);
		display: flex;
		width: auto;

		.selectlist {
			margin-left: $col-margin;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.icon-arrow-down {
		color: $red;
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 100;

		&:before {
			@include font-size(1.2);
			line-height: 20px;
		}
	}

	select {
		@include appearance(none);
		background-color: transparent;
		border: 0;
		color: $grey;
		font-family: $primary;
		height: 27px;
		margin-left: 11px;
		padding: 5px 10px 3px 5px;
		position: relative;
		text-indent: 0.01px;
		text-overflow: "";
		width: 100%;
		z-index: 200;

		&:focus {
			outline: 0;
		}
	}

	select::-ms-expand {
		display: none;
	}

	.phone-ext {
		@include justify-content(space-between);
		display: flex;

		@media only screen and (max-width: $mobile) {
			display: block;
		}

		span {
			display: block;
		}

		.phone {
			width: $col8;
		}

		.ext {
			width: $col3;
		}
	}

	.checkbox {
		padding-left: 32px;

		label {
			display: inline-block;
			text-indent: -15px;
			width: auto;
			vertical-align: text-top;

			span {
				@include fir("img/check.png", 26px, 26px);
				background-size: 100%;
				margin-left: -20px;
				margin-right: 5px;
				vertical-align: middle;
			}
		}

		input {
			&[type="checkbox"] {
				@include visuallyhidden();

				&:checked + label span {
					background-position: left bottom;
				}
			}
		}
	}

	.btn {
		margin: 35px 0 75px;
		@include btn-primary-red;

		@media only screen and (max-width: $mobile) {
			margin: 35px 0 75px;
		}
	}

	.note {
		display: block;

		a {
			@include link-underline();
		}
	}

	.birthdate {
		legend {
			@include font-size(1.6);
			@extend label;
			line-height: 28px;
			text-transform: none;
			width: 100%;
		}
	}

	.month-day {
		@include justify-content(space-between);
		display: flex;

		.selectlist {
			width: $col4;

			&:first-child {
				margin-right: $col-margin;
				width: $col7;
			}
		}
	}
}

.time-select {
	width: $col5;

	@media only screen and (max-width: $mobile) {
		width: auto;
	}

	.selectlist {
		width: $col7;
	}
}

.date {
	display: flex;

	@media only screen and (max-width: $mobile) {
		display: block;
	}

	.month {
		margin-right: 4 * $col-margin;
		width: $col5;
	}

	.day {
		width: $col4;
	}
}

.radio-fir {
	margin-right: 20px;
	padding-left: 20px;
	position: relative;

	label,
	.check {
		display: inline-block;
	}

	label {
		margin-left: -20px;
		padding-left: 30px;
		position: relative;
		z-index: 1000;
	}

	.check {
		@include align-items(center);
		@include justify-content(center);
		border: 2px solid $grey;
		border-radius: 25px;
		display: flex;
		height: 12px;
		left: 0;
		position: absolute;
		top: 3px;
		width: 12px;
		z-index: 200;

		&:before {
			@include font-size(8);
			display: inline-block;
			content: " ";
			background-color: $white;
			border-radius: 10px;
			height: 8px;
			width: 8px;
		}
	}

	input[type="radio"] {
		@extend .vh;
	}

	input[type="radio"]:checked ~ .check {
		border-color: $red;

		&:before {
			background-color: $red;
		}
	}
}
