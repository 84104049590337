.site-down-header {
	background: $white;
	height: 64px;
	position: relative;

	img {
		position: absolute;
		bottom: 0;
		left: 24px;
		top: 0;
		height: 48px;
		margin: auto;
		width: 48px;
	}
}
