.lll-search-content {
	@include flex-rules();
	display: none;
	flex-basis: $col12;
	margin: 50px auto 0;
	max-width: $col12;
	padding: 0 24px 47px;

	&.active {
		display: flex;
		justify-content: space-between;
	}

	@include breakpoint(tablet) {
		flex-basis: $col8;
		margin: 59px auto 0;
		max-width: 1000px;
	}

	@include breakpoint($large1) {
		margin: 44px auto 0;
	}

	.input {
		input {
			padding: 30px 17px 4px;
		}
	}

	input[type="number"] {
		appearance: textfield;
	}

	.col--25 {
		width: 25%;
	}

	.col--75 {
		max-width: 100%;
		width: 75%;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		appearance: none;
	}

	.date-selector {
		width: 100%;

		&.input {
			max-width: none;
		}

		label {
			line-height: 15px;
			position: absolute;
		}
	}

	.option-select {
		margin-top: 24px;
	}

	&__image {
		height: 350px;
		background-size: contain;
		background-position: top;
		background-repeat: no-repeat;
		margin: 0 0 29px;
		width: 100%;

		@include breakpoint(desktop) {
			height: 750px;
			margin: 0;
			padding: 30px 0 0 24px;
			width: 50%;
		}
	}

	&__receipt {
		display: flex;
		justify-content: center;
		margin: 35px 0 40px;
		order: 1;
		width: 100%;

		@include break-min($large1) {
			margin: 35px 0 29px;
		}

		@include breakpoint(desktop) {
			display: block;
			height: 750px;
			margin: 40px 0 0;
			width: 50%;
		}

		.lll-sbr {
			&__receipt {
				background-color: $concrete;
				border-radius: 5px;
				box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
				margin: 0 auto;
				max-width: 340px;
				padding: 30px 20px;
				position: relative;
				width: 100%;

				p {
					position: relative;
					text-align: center;
					z-index: 1;
				}

				p,
				td,
				span {
					color: $black;
					font-family: "franklin-gothic", sans-serif;
					line-height: 1.4;
					@include font-size(1.6);
				}

				&-label {
					@include s3();
					bottom: 100%;
					color: $vis-blue;
					display: block;
					left: 0;
					margin: 0;
					padding: 0 0 24px;
					position: absolute;
					right: 0;
					text-align: center;
					text-transform: uppercase;

					@include break-min($large1) {
						padding: 0 0 40px;
					}
				}

				.highlight {
					display: inline-block;
					position: relative;
					transition: color 0.3s ease;

					&::after {
						background-color: $concrete;
						border-radius: 6px;
						bottom: -2px;
						content: "";
						left: -4px;
						position: absolute;
						right: 100%;
						top: 0;
						transition: right 0.2s ease, background 0.2s ease;
						will-change: right, background-color;
						z-index: -1;
					}

					&--active {
						color: $white;

						&::after {
							background-color: $vis-blue;
							right: -4px;
						}
					}
				}

				td + td {
					text-align: right;
				}

				table {
					position: relative;
					width: 100%;
					z-index: 1;
				}

				.nested {
					padding: 0 0 0 40px;
				}

				.calculation {
					padding: 0 0 0 22px;
				}
			}
		}
	}

	h3 {
		@include font-size(1.2);
		color: $vis-blue;
		display: none;
		font-family: $secondary;
		font-weight: 800;
		line-height: 14px;
		letter-spacing: 2px;
		margin: 0 0 40px;
		text-transform: uppercase;
		width: 100%;

		@include break-min($large1) {
			display: block;
		}
	}

	p {
		@include font-size(1.8);
		font-family: $primary;
		font-weight: normal;
		line-height: 32px;
		letter-spacing: -0.5px;
		font-variant-ligatures: none;
		// sass-lint:disable no-vendor-prefixes
		-webkit-font-variant-ligatures: none;
		// sass-lint:enable no-vendor-prefixes
		margin: 0 0 24px;
	}

	&__form {
		order: 2;
		width: 100%;

		@include breakpoint(desktop) {
			align-content: start;
			margin: 0 auto;
			max-width: 336px;
			order: 1;
			width: 43%;
		}

		.btn {
			padding: 17px 15px 12px;
			text-align: center;
			width: 150px;

			@include breakpoint(mobileOnly) {
				width: 100%;
			}
		}

		.col--offset {
			padding: 0 0 0 24px;

			.input__label {
				left: 42px;
			}
		}

		&__inputs {
			margin: 24px 0 0;

			&:first-of-type {
				margin: 0;
			}

			&:last-of-type {
				margin: 24px 0 30px;
			}

			&.selects {
				.option-select {
					margin: 19px 0 0;

					&:first-of-type {
						margin: 12px 0 0;
					}
				}
			}
		}
	}

	.order-number-emphasis {
		margin: 20px 0 -24px;
		width: 100%;
	}
}

.lll {
	.lll-search-content {
		.btn-container {
			margin-bottom: 48px;
			padding: 0;
			width: 100%;

			@include break-min($large1) {
				width: auto;
			}

			.btn {
				@include break-min($large1) {
					justify-content: flex-start;
					min-width: 144px;
				}
			}
		}
	}
}
