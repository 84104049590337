.deliveryPg {
	.g-hero {
		background: #f5e3cc;
		height: 340px;
		min-height: 340px;
		position: relative;

		&__inner {
			display: flex;
			flex-direction: column;
			max-width: 1480px;
		}

		&__text {
			color: $blue;

			h1 {
				@include h2();
				margin-top: 32px;
				text-shadow: none;

				@media screen and (min-width: $mobile) {
					margin-top: 90px;
				}
			}
		}

		img {
			bottom: 60px;
			left: 50%;
			position: absolute;

			@media screen and (min-width: $mobile) {
				margin: auto;
				width: auto;
			}

			&.desktop {
				display: none;

				@media screen and (min-width: $mobile) {
					display: block;
					position: unset;
					margin-left: -85px;
				}
			}

			&.mobile {
				margin-left: -115px;

				@media screen and (min-width: $mobile) {
					display: none;
				}
			}
		}
	}

	.msg {
		margin: -32px auto 50px;
		position: relative;
		text-align: center;
		z-index: 1000;

		@media screen and (min-width: $mobile) {
			margin-bottom: 100px;
			padding: 0 200px;
		}

		img {
			background: $white;
			border-radius: 100%;
			margin-bottom: 25px;
			padding: 6px;
			transform: rotate(25deg);
		}

		h4 {
			color: $blue;
			@include h4();
		}
	}

	.locations-container {
		max-width: 1080px;

		.vis-white {
			display: flex;
			justify-content: center;
			margin: 50px auto;
			max-width: 200px;
		}
	}

	.location-module {
		//display: flex;
		margin-top: 35px;
		padding-bottom: 35px;
		position: relative;

		&:after {
			@include dot-border-h(bottom, left);
		}

		&:last-child {
			margin-bottom: 50px;
		}

		@media screen and (min-width: $mobile) {
			margin-top: 0;
		}

		.top-header {
			@media screen and (min-width: $mobile) {
				flex-direction: column;
			}
		}

		.icon-wrapper {
			background: $light-grey;
			border-radius: 50%;
			height: 40px;
			//position: absolute;
			margin-right: 15px;
			text-align: center;
			top: 49px;
			width: 40px;
			z-index: 1;

			@media screen and (min-width: $mobile) {
				height: 60px;
				width: 60px;
				position: relative;
				top: 50px;
			}
		}

		img {
			//position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
		}

		span {
			@include p2();
			bottom: -27px;
			display: none;
			left: 0;
			position: absolute;
			right: 0;

			@media screen and (min-width: $mobile) {
				display: block;
			}
		}

		h4 {
			@include h4();
			color: $blue;
			margin: 0px;

			@media screen and (min-width: $mobile) {
				margin-left: 100px;
			}
		}

		.address-info {
			//margin-left: 78px;
			margin-top: 5px;

			@media screen and (min-width: $mobile) {
				margin-left: 100px;
			}
		}

		.address {
			@include p1();
		}

		.blue {
			color: $blue;
			margin-bottom: 20px;
		}

		.font-weight {
			font-weight: 900;
		}

		.phone-number {
			color: $red;
		}

		.delivery-hours {
			p:first-child {
				width: $col4;

				@media screen and (min-width: $mobile) {
					width: $col2;
				}
			}
		}

		.btn {
			margin-top: 20px;
			text-align: center;

			@media screen and (max-width: $mobile) {
				width: 100%;
			}

			@media screen and (min-width: $mobile) {
				margin-top: 0;
				position: absolute;
				right: 0;
				top: 70px;
			}
		}
	}

	.address-entry {
		margin-bottom: 50px;

		img {
			display: flex;
			margin: 0 auto 30px;
			padding-right: 50px;
		}

		h2 {
			@include h3();
			color: $blue;
			margin: auto;
			text-align: center;
			width: 100%;

			@media screen and (min-width: $mobile) {
				width: $col7;
			}
		}

		.btn {
			display: block;
			margin: auto;
			max-width: 100px;
			text-align: center;
		}
	}

	.cp-form {
		padding-bottom: 0;

		@media screen and (min-width: $mobile) {
			display: flex;
			margin: auto;
			max-width: $col5;
		}

		section {
			width: 100%;

			&:after {
				background-image: none;
			}
		}

		.address-label {
			@include p1();
			margin-bottom: 10px;
		}

		.cp-form__group {
			margin-top: 0;
		}

		.cp-form__group--input {
			margin-bottom: 15px;
			max-width: none;

			@media screen and (min-width: $mobile) {
				max-width: none;
				width: 100%;
			}
		}

		.cp-form__group--small {
			margin-left: 15px;

			@media screen and (min-width: $mobile) {
				max-width: 150px;
			}
		}
	}

	.catering-hero {
		border-radius: 7px;
		box-shadow: 0 0 17px #e3dedc;
		margin: 50px 20px;
		max-width: 1500px;
		overflow: hidden;
		padding-right: 0;
		@include flexbox();
		flex-direction: column-reverse;
		margin-bottom: 50px;

		@media screen and (min-width: $mobile) {
			flex-direction: row;
			justify-content: space-between;
			margin: 50px 40px;
		}

		@media screen and (min-width: $lrg-desktop) {
			margin: 50px auto;
		}

		img {
			@media screen and (min-width: $mobile) {
				border-radius: 50px;
			}
		}

		.hero-msg {
			margin: -25px 10px 25px 25px;
			overflow: hidden;

			@media screen and (min-width: $mobile) {
				margin: auto;
				min-width: 350px;
				padding-left: 50px;
			}

			h2 {
				@include h3();
				color: $blue;
				margin-bottom: 14px;
			}

			.btn {
				margin-top: 15px;
			}
		}
	}
}
