.codemoo-header-img-list-callout {
	margin: 60px 0;

	> ul {
		&:first-of-type {
			> li {
				&:first-child {
					.img-callout {
						padding-top: 10px;
					}
				}

				&:last-child {
					border-bottom: none;
				}

				&:before {
					content: none;
				}

				@include break-min($large1) {
					border-bottom: 1px solid $faint-grey;
				}
			}

			&.img-callout-wrapper {
				.img-callout {
					padding: 0;

					@include break-min($large1) {
						padding: 80px 0;
					}

					img {
						@include break-min($large1) {
							border-radius: 22px;
						}
					}

					.content {
						p {
							color: $grey;
							@include font-size(2);

							@include break-min($large1) {
								@include font-size(2.4);
							}
						}
						p:not(:first-of-type) {
							@include font-size(1.8);
							letter-spacing: -0.5px;
							color: $rolling-stone;

							@include break-min($large1) {
								@include font-size(2.4);
								font-weight: 700;
								line-height: 34px;
							}
						}
					}
				}
			}
		}
	}
}
