.body-text {
	&__p1 {
		.classic-callout .body-text,
		.body-text {
			p {
				@include type-p1-refresh();
			}
		}
	}

	&__p2 {
		.classic-callout .body-text,
		.body-text {
			p {
				@include type-p2-refresh();
			}
		}
	}

	&__p3 {
		.classic-callout .body-text,
		.body-text {
			p {
				@include type-p3-refresh();
			}
		}
	}

	&__p4 {
		.classic-callout .body-text,
		.body-text {
			p {
				@include type-p4-refresh();
			}
		}
	}
}
