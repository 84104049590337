.g-boxes {
	&.team-member {
		.g-boxes__box {
			.img-wrapper {
				@include break-min($large1) {
					border-radius: 12px;
					overflow: hidden;
				}
			}
		}

		.g-boxes {
			&__h2 {
				@include font-size(3.6);
				letter-spacing: -0.8px;
				line-height: lh(46, 36);
				color: $vis-blue;
				display: block;
				font-weight: $bold-weight;
				margin: 0 auto;
				padding: 0;
				width: 100%;

				@include break-min($large1) {
					@include font-size(4.8);
					letter-spacing: -1px;
					line-height: lh(58, 48);
				}
			}

			p {
				@include type-p1-refresh();
			}

			&__box {
				border-top: none;

				&:before {
					background-color: transparent;
				}
			}

			&__box-content {
				@include break-min($large1) {
					padding: 0 71px;
				}
			}

			&__box-title {
				padding: 40px 0 0;
			}
		}
	}
}
