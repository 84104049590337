@import "sub-components/delivery-location-details";
@import "sub-components/delivery-hours";
@import "sub-components/delivery-partners";

.delivery-location-module {
	position: relative;
	text-align: center;

	&:after {
		@include dot-border-h(bottom);
	}

	@include break-min($large1) {
		text-align: left;
	}
}
