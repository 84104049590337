@mixin handle70Child() {
	@include break-min($large4) {
		// justification: this rule should supersede others
		// sasslint:disable next line
		grid-column: auto / span 7 !important;
	}
}

@mixin handle30Child() {
	@include break-min($large4) {
		// justification: this rule should supersede others
		// sasslint:disable next line
		grid-column: auto / span 5 !important;
	}
}

@mixin handle66Child() {
	@include break-min($medium4) {
		// justification: this rule should supersede others
		// sasslint:disable next line
		grid-column: auto / span 8 !important;
	}
}

@mixin handle33Child() {
	@include break-min($medium4) {
		// justification: this rule should supersede others
		// sasslint:disable next line
		grid-column: auto / span 4 !important;
	}
}

@mixin handle50Child() {
	@include break-min($medium4) {
		grid-column: auto / span 6;
		grid-row: 1;
	}
}

@mixin handle25Child() {
	@include break-min($medium4) {
		grid-column: auto / span 4;
	}

	@include break-min($large4) {
		grid-column: auto / span 3;
	}
}

// Handles finalElem for cardClassicContain
@mixin proport50-25-25CardFinalElem() {
	@include break-min($medium4) {
		grid-row: auto;
	}

	@include break-min($large4) {
		grid-template-rows: auto;
	}

	.img-container {
		@include break-min($medium4) {
			height: 100%;
			grid-column: 5 / span 4;
		}

		@include break-min($large4) {
			grid-column: 7 / span 6;
		}

		@include break-min($huge4) {
			grid-column: auto/span 3;
		}
	}

	.message-container {
		@include break-min($medium4) {
			grid-column: auto / span 4;
			grid-row: 1;
			padding: 46px;
		}

		@include break-min($large4) {
			grid-column: 1 / span 6;
			justify-content: center;
		}

		@include break-min($huge4) {
			grid-column: 1 / span 3;
		}

		.icon-container,
		.title-text {
			@include break-min($medium4) {
				margin-bottom: 8px;
			}
		}

		.btn {
			@include break-min($medium4) {
				margin-top: 16px;
				position: unset;
			}
		}
	}
}

// Handles finalElem for cardOverlayContain
@mixin proport50-25-25OverlayFinalElem() {
	@include break-min($medium4) {
		padding: 24px 267px 24px 24px;
		grid-row: auto;
	}

	@include break-min($large4) {
		padding: 16px 220px 16px 16px;
	}

	.message-container {
		.btn {
			@include break-min($medium4) {
				left: 24px;
				right: unset;
				width: fit-content;
			}
		}
	}
}

@mixin proport25-25-25CardFinalElem() {
}

.proportion30-70 {
	> :first-child {
		@include handle30Child();
	}

	> :last-child {
		@include handle70Child();
	}
}

.proportion70-30 {
	> :first-child {
		@include handle70Child();
	}

	> :last-child {
		@include handle30Child();
	}
}

.proportion33-66 {
	> :first-child {
		@include handle33Child();
	}

	> :last-child {
		@include handle66Child();
	}
}

.proportion66-33 {
	> :first-child {
		@include handle66Child();
	}

	> :last-child {
		@include handle33Child();
	}
}

.proportion50-50 {
	> :first-child {
		@include handle50Child();
	}

	> :last-child {
		@include handle50Child();
	}
}

.proportion25-25-50,
.proportion50-25-25 {
	@include break-min($medium4) {
		display: grid;
		grid-gap: 24px;
	}
}

.proportion25-25-50 {
	> :first-child,
	> :nth-child(2) {
		@include handle25Child();
	}

	> :last-child {
		@include handle50Child();
	}
}

.proportion33-33-33 {
	@include handle33Child();
}

.proportion50-25-25 {
	> :nth-child(2),
	> :last-child() {
		@include handle25Child();
	}

	> :first-child {
		@include handle50Child();
	}
}
