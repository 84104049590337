.menu-availability-callout {
	margin: 50px 0;
	text-align: center;

	@include break-min($large1) {
		margin: 64px;
	}

	h1 {
		@include font-size(2.4);
		color: $blue;
		font-weight: 700;
		line-height: lh(32, 24);
		margin: 24px 0 10px;
		text-align: center;

		@include break-min($large1) {
			@include font-size(6.4);
			line-height: lh(58, 64);
			margin: 40px 0 10px;
		}

		sup {
			top: -0.25em;
		}
	}

	p {
		@include font-size(1.4);
		color: $rolling-stone;
		letter-spacing: -0.25px;
	}

	@include break-max($small3) {
		&.hide-mobile {
			display: none;
		}
	}
}
