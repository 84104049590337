.content-hub-related-articles {
	margin: 0 auto;
	max-width: 1200px;
	width: 100%;

	@include break-max($medium2) {
		padding: 0 32px;

		.content-hub-headline-wrapper {
			padding: 0;
		}
	}

	&.classic-callout {
		visibility: visible;
	}

	.related-article {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 0 24px;

		@include break-max($medium2) {
			grid-template-columns: repeat(1, 1fr);
		}

		.article {
			background-color: $white;
			border-radius: 0 !important;
			display: flex;
			flex-flow: column;

			&:not(:first-child) {
				display: none;

				@include break-min($medium4) {
					display: flex;
				}
			}

			.btn-secondary {
				@include btn-round;
				@include btn-secondary-white;
				border: 1px solid #ccc;
				position: absolute;
				bottom: 5px;
			}

			img {
				border-radius: 16px;
				height: 260px;
				object-fit: cover;

				@include break-min($medium4) {
					border-radius: 32px;
				}
			}

			.article-content {
				display: flex;
				margin-top: 24px;
				padding-bottom: 45px;
				position: relative;
				flex-grow: 1;
				justify-content: center;
				margin: 0 4px;

				@include break-min($medium4) {
					margin-top: 32px;
					justify-content: start;
				}

				p {
					line-height: lh(13, 10);
					font-weight: 700;
					letter-spacing: -0.5px;
					color: $blue;
					@include font-size(2.4);

					@include break-min($medium4) {
						@include font-size(3);
					}
				}
			}
		}
	}
}
