.gift-container {
	&.error {
		border-color: $white;
		display: grid;
		height: 100vh;
		padding-bottom: 70px;

		@include break-min($large1) {
			grid-template-rows: 0.46fr 0.2fr auto auto;
			padding-bottom: 0;
		}

		@include ie-only() {
			grid-template-rows: 0.2fr 0.3fr 0.3fr auto;
		}

		.gifting-back-link-nav {
			grid-row: 1;

			p {
				margin: auto 0 auto 28px;
			}
		}

		.gifting-title {
			grid-row: 2;

			@include break-min($large1) {
				margin-top: 0;
				max-width: 600px;

				@include ie-only() {
					margin: 0;
				}
			}

			h1 {
				@include break-min($large1) {
					margin-bottom: 24px;
				}
			}

			p {
				@include break-min($large1) {
					display: block;
					margin-bottom: 25px;
				}
			}
		}

		.gifting-img-tile {
			grid-row: 4;

			@include break-min($large1) {
				grid-row: 1 / span 4;
				height: 100%;

				@include ie-only() {
					width: 30%;
				}
			}

			img {
				@include break-min($large1) {
					max-height: none;
				}
			}

			h4 {
				display: none;
			}
		}

		.gifting-btn-container {
			display: flex;
			grid-row: 3;
			padding-bottom: 17px;
			padding: 0 5px 17px;

			@include break-min($large1) {
				grid-row: 3;
				margin: 0;
				padding: 0;
			}

			a {
				&:first-of-type {
					margin-right: 17px;
				}

				&:nth-of-type(2) {
					box-shadow: none;
					margin-top: 0;
				}
			}

			img {
				width: 100%;
			}
		}
	}
}
