.shared-table-page {
	.full-bleed-image {
		.lb-component-content {
			display: none;

			@include break-min($large1) {
				display: block;
			}
		}
	}
}
