.location-current-hours {
	.today-hours {
		display: flex;
		align-items: center;

		.clock-icon {
			display: none;
		}

		&.closed {
			color: $red;

			.clock-icon {
				background-image: url("img/icon_clock-red.svg");
			}
		}

		p {
			color: $green;
			font-weight: $bold-weight;
			margin: 0;
			padding-left: 27px;
			position: relative;

			&::before {
				@include background-size-cover();
				content: "";
				background-image: url("img/icon_clock-green.svg");
				height: 20px;
				position: absolute;
				top: 2px;
				left: 0;
				width: 20px;
			}

			&[data-is-open="false"] {
				color: $red;

				&::before {
					background-image: url("img/icon_clock-red.svg");
				}
			}
		}

		@media screen and (max-width: $mobile) {
			justify-content: center;
		}

		.yellow-msg {
			color: $yellow-orange;
		}
	}
}
