.membership-content {
	.basic-member-theme {
		.membership-badge {
			background-color: $cool-white;
		}
	}

	.silver-member-theme {
		.membership-badge {
			background-color: $iron;
		}
	}

	.red-member-theme {
		.membership-badge {
			background-color: $apricot-peach;
		}
	}

	.signature-member-theme {
		.membership-badge {
			background-color: $light-blue;
		}
	}
}
