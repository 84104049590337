.bc-thumbnail-lightbox {
	background: $cream;
	margin: 0 auto;
	padding: 50px 10px 0;
	text-align: center;

	@include break-min($large1) {
		padding: 50px 24px;
	}

	.header {
		h3 {
			@include h3();
			color: $blue;
		}

		p {
			@include p1();
		}
	}

	.grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: auto;
		max-width: 500px;

		@media screen and (min-width: $mobile) {
			margin: 0 auto 40px;
			max-width: 1250px;
		}

		a {
			background-position: center center;
			background-repeat: no-repeat;
			display: block;
			height: 140px;
			margin: 18px 9px 50px;
			min-width: 137px;
			position: relative;
			width: 44%;

			@include break-min($large1) {
				margin: 35px 20px 0;
				height: 200px;
				//width: 27%;
				flex: 0 0 27.3333%;
				max-width: 33.3333%;
			}

			@include break-min($large2) {
				height: 220px;
				margin: 35px 28px 0;
			}

			&:after {
				content: "";
			}
		}

		.img-wrapper {
			position: relative;
		}

		.g-video__play-btn {
			height: 35px;
			width: 35px;
		}

		.headline {
			@include p1();
			bottom: -35px;
			color: $grey;
			font-weight: 900;
			margin-top: 12px;
			padding: 0;
			position: absolute;
			text-align: left;

			@media screen and (min-width: $mobile) {
				color: $white;
				bottom: 10px;
				left: 20px;
			}
		}
	}
}
