.giving-back-callout {
	margin-bottom: 64px;

	@include break-min($large1) {
		height: 576px;
		padding: 0 33px;
	}

	.flex-wrapper {
		@include break-min($large1) {
			@include swap-div-order();
			align-items: center;
			justify-content: space-around;
		}
	}

	.img-wrapper {
		margin-bottom: 18px;

		@include break-min($large1) {
			margin-bottom: 0;
		}
	}

	.content {
		padding: 0 24px;

		@include break-min($large1) {
			padding: 0;
			margin-right: 22px;
			max-width: 600px;
		}

		.headline {
			margin-bottom: 16px;

			p {
				font-weight: $bold-weight;
				letter-spacing: -0.5px;
			}
		}

		h4 {
			color: $red;
			margin-bottom: 16px;
		}
	}
}
