@mixin swap-div-order() {
	div {
		&:first-child {
			order: 2;
		}

		&:nth-child(2) {
			order: 1;
		}
	}
}

@mixin absolute-center() {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}

@mixin remove-bc-controls() {
	.vjs-loading-spinner,
	.vjs-control-bar,
	.vjs-big-play-button {
		display: none;
	}

	.vjs-poster {
		background-color: transparent;
		background-size: cover;
		display: none;
	}

	.vjs-player-info-modal {
		display: none;
	}
}

@mixin swap-img {
	.mobile-img {
		display: block;

		@include break-min($large1) {
			display: none;
		}
	}

	.desktop-img {
		display: none;

		@include break-min($large1) {
			display: block;
		}
	}
}

@mixin desktop-grid {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: repeat(12, [col-start] 1fr);
}

@mixin desktop-grid-max-width {
	margin-left: auto;
	margin-right: auto;
	max-width: 1348px;
	padding-left: 0;
	padding-right: 0;
}

@mixin grid-margin() {
	margin-left: 24px;
	margin-right: 24px;

	@include break-min($medium4) {
		margin-left: 46px;
		margin-right: 46px;
	}

	@include break-min($large4) {
		margin-left: 72px;
		margin-right: 72px;
	}
}

@mixin grid-padding() {
	padding-left: 24px;
	padding-right: 24px;

	@include break-min($medium4) {
		padding-left: 46px;
		padding-right: 46px;
	}

	@include break-min($large4) {
		padding-left: 72px;
		padding-right: 72px;
	}

	@include break-min($max-width) {
		padding-left: 0;
		padding-right: 0;
	}
}

@mixin play-pause-btn-states {
	background: $red;

	.isPlaying ~ .play-btn {
		display: none;
	}

	.isPlaying ~ .pause-btn {
		background: $red;
		display: block;
	}

	.isPaused ~ .pause-btn {
		display: none;
	}

	.isPaused ~ .play-btn {
		background: $red;
		display: block;
	}

	.play-btn,
	.pause-btn {
		&:active,
		&:hover {
			background: $dkred;
		}

		&:disabled {
			background: $rolling-stone;
		}

		&:focus {
			background: $red;
			border: 1px solid $dkred;
		}
	}
}
