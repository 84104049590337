.fade-carousel {
	.g-carousel-grid__nav {
		top: 38%;

		@include break-min($large1) {
			top: 46%;
		}

		&--next {
			right: 8px;
		}

		&--prev {
			left: 8px;
		}

		&.slick-disabled {
			display: none;
		}
	}
}
