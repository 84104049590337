.careers-ourRestaurants {
	.g-hero {
		height: 650px;

		.g-hero__inner {
			display: block;

			@media screen and (min-width: $mobile) {
				display: flex;
				margin-left: 125px;
			}

			.btn {
				margin-top: 20px;

				@media screen and (max-width: $mobile) {
					bottom: 100px;
					left: 50%;
					min-width: 235px;
					transform: translateX(-50%);
					position: absolute;
				}
			}
		}

		.g-hero__text {
			@media screen and (max-width: $mobile) {
				margin-top: 50px;
			}
		}
	}

	.facts {
		.wrapper {
			padding: 0 20px;
			text-align: center;

			@media screen and (min-width: $mobile) {
				margin: auto;
				padding: 0;
				//width: $col6;
			}

			h2 {
				@include h3();
				color: $blue;
			}

			p {
				@include p1();
			}
		}

		.wrapper,
		.stats {
			max-width: none;
		}

		.stats {
			padding: 0 20px;
		}

		.fact {
			display: block;
			margin: 35px 0 10px;

			@media screen and (min-width: $mobile) {
				margin: 50px 1%;
			}
		}

		.img {
			margin-bottom: 20px;
		}

		a {
			@include link-underline();
		}

		.highlight {
			@include font-size(2.3);
			color: $grey;
			font-weight: bold;
			width: $col11;
		}
	}

	.header-columns-text {
		margin: 0;
		position: relative;

		@media screen and (min-width: $mobile) {
			margin: 50px auto;
		}

		.header {
			margin: 0 auto 75px;
			padding: 0 20px;
			text-align: center;

			@media screen and (min-width: $mobile) {
				margin: auto;
				padding: 0;
				width: $col6;
			}

			h2 {
				@include h3();
			}

			p {
				@include p1();
			}
		}

		&.wrapper,
		.flex {
			max-width: none;
		}

		h2 {
			margin-bottom: 25px;
		}

		dl {
			margin-bottom: 10px;
			position: relative;

			@media screen and (min-width: $mobile) {
				margin: 50px 1%;
			}

			&:after {
				content: "";
			}
		}

		dt,
		dd {
			padding-left: 11px;
		}

		dt {
			@include font-size(2);
			font-weight: bold;

			&:before {
				font-family: $secondary;
				content: "\201c";
				left: 0;
				position: absolute;
			}

			&:after {
				content: "\201d";
				font-family: $secondary;
			}
		}

		dd:after {
			content: "";
			display: none;
		}

		.btn {
			left: 50%;
			transform: translateX(-50%);
			min-width: 230px;
			position: absolute;
			text-align: center;
		}
	}

	.video-panel {
		background: $warm-white;
		padding: 25px;
		text-align: center;

		.content {
			margin: auto;

			@media screen and (min-width: $mobile) {
				width: $col8;
			}
		}

		h2 {
			@include h3();
			color: $blue;
			margin-bottom: 50px;
		}

		p {
			@include p1();
			margin-bottom: 50px;
		}

		img {
			margin-bottom: 50px;
		}
	}

	.blogStories {
		margin: 100px auto 50px;
		padding-bottom: 20px;
		position: relative;

		.vis-white {
			bottom: 0;
			left: 50%;
			min-width: 240px;
			position: absolute;
			text-align: center;
			transform: translateX(-50%);
		}
	}

	.block_img_header_txt {
		margin-bottom: 100px;

		@media screen and (min-width: $mobile) {
			display: flex;
			justify-content: center;
		}

		.img-wrapper {
			max-width: 500px;

			@media screen and (min-width: $mobile) {
				height: 300px;
			}
		}

		img {
			@media screen and (max-width: $mobile) {
				margin-bottom: 20px;
			}
		}

		&.left-align-txt {
			flex-direction: row-reverse;

			.content {
				@media screen and (min-width: $mobile) {
					margin-left: 0;
					margin-right: 50px;
				}
			}
		}

		.content {
			position: relative;

			@media screen and (min-width: $mobile) {
				margin-left: 50px;
				width: $col6;
			}

			h4 {
				@include h4();
				margin: 0 0 10px 0;
			}

			p {
				@include p1();
			}
		}
	}
}
