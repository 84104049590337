.img-carousel {
	@include grid-margin();
	margin: 50px 0 0;
	position: relative;

	@include break-min($large1) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 98px inherit;
	}

	.slider {
		gap: 24px;
		grid-column: col-start 1 / span 4;
		max-width: 1076px;
		width: 100%;
		margin: auto;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large1) {
			grid-column: col-start 1 / span 12;
		}
	}

	.slide-container p,
	.static-text p {
		@include font-size(1.4);
		line-height: lh(22, 14);
		letter-spacing: -0.5px;
		margin: 12px 24px 0;
		text-align: center;

		@include break-min($large1) {
			@include font-size(1.8);
			line-height: lh(28, 18);
			margin: 30px 0 0;
		}
	}

	.img-container {
		overflow: hidden;
		position: relative;
		height: 250px;
		width: 100%;
		border-radius: 32px;

		@include break-min($large1) {
			border-radius: 32px;
			height: 716px;
		}

		img {
			@include absolute-center();
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.slider-btn-container {
		@include red-arrow-btn();
		background: transparent;
		bottom: unset;
		margin: 12px auto 0;
		padding: 0;
		position: relative;

		@include break-min($large1) {
			background: $white;
			bottom: 162px;
			padding: 12px 14px;
		}

		[data-carousel-count] {
			display: none;

			@include break-min($large1) {
				display: block;
			}
		}
	}

	&.desktop-only {
		@include break-max($large1) {
			display: none;
		}
	}

	&[data-has-multiple="true"] {
		.slick-list {
			grid-column: col-start 1 / span 12;
			padding: 0 !important;
		}
	}

	&[data-child-count="1"] {
		.slide-container {
			gap: 24px;
			grid-column: col-start 1 / span 4;

			@include break-min($medium4) {
				grid-column: col-start 1 / span 8;
			}

			@include break-min($large1) {
				grid-column: col-start 1 / span 12;
			}
		}

		.slider-btn-container {
			display: none;
		}
	}
}
