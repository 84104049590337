@import "../../../cfacom/covid/utils";

.covid-related-stories {
	margin: auto;
	max-width: 450px;

	@include break-min($large1) {
		max-width: none;
	}

	&.mobileExpanded {
		.g-boxes {
			&__box {
				&:not(:first-child) {
					display: block;
				}
			}

			&__footer {
				.btn-round {
					display: none;
				}
			}
		}
	}

	&.g-boxes {
		@include break-min($small4) {
			padding: 80px 33px 90px;
		}
	}

	.g-boxes {
		&__header {
			@include break-min($large1) {
				padding-bottom: 80px;
			}

			p {
				@include font-size(1.8);
				line-height: lh(25, 18);
				padding-top: 24px;

				@include break-min($large1) {
					line-height: lh(28, 18);
					max-width: 800px;
				}
			}
		}

		&__h2 {
			max-width: 300px;

			@include break-min($large1) {
				@include font-size(4.8);
				max-width: none;
			}
		}

		&__inner {
			display: inline-block;
			margin: auto;
			width: 100%;

			@include break-min($large1) {
				display: flex;
			}
		}

		&__box {
			@include dropdown-transition(2, 3);
			border-top: 0;
			max-width: none;

			&:not(:first-child) {
				opacity: 0;
				display: none;

				@include break-min($large1) {
					display: block;
					opacity: 1;
				}
			}

			&:after {
				display: none;

				@include break-min($large1) {
					display: inline;
					position: absolute;
					background-color: $alto;
					right: -1px;
					top: -1px;
					bottom: -1px;
					width: 1px;
				}
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			&-content {
				@include break-min($large1) {
					padding: 0 30px 24px;
				}

				@include break-min($large4) {
					padding: 0 50px 24px;
				}
			}

			h4,
			&-title {
				@include font-size(2);
				display: -webkit-box;
				overflow: hidden;
				line-height: lh(34, 20);
				padding-top: 40px;
				text-overflow: ellipsis;
				// sass-lint:disable no-vendor-prefixes
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				// sass-lint:enable no-vendor-prefixes

				@include break-min($large1) {
					@include font-size(2.4);
					line-height: lh(34, 24);
				}
			}

			p {
				@include font-size(1.6);
				line-height: lh(25, 16);
				padding-top: 24px;
			}

			span {
				font-weight: $bold-weight;
				letter-spacing: 2px;
			}

			&:before {
				display: none;
			}
		}

		&__footer {
			text-align: left;

			.btn-round {
				@extend .btn-round--white;
				width: 100%;

				@include break-min($large1) {
					display: none;
				}
			}
		}

		// &.scholarship {
		// 	.g-boxes__box {
		// 		&:not(:first-child) {
		// 			display: block;
		// 			opacity: 1;
		// 		}
		// 	}
		// }
	}
}
