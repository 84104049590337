.lll-confirm-location {
	text-align: center;

	h4 {
		@include font-size(2.4);
		line-height: lh(32, 24);
		color: $blue;
		font-weight: bold;
		letter-spacing: -0.5px;
		margin: 0 auto 32px;
		max-width: 300px;
		text-align: center;

		@include break-min($large1) {
			@include font-size(3.6);
			line-height: lh(42, 36);
			max-width: none;
		}
	}

	.body {
		display: flex;
		justify-content: center;
		margin-bottom: 32px;
		position: relative;
		text-align: left;

		@include break-min($large1) {
			padding: 0 45px;
		}

		div:nth-of-type(2) {
			margin-left: 25px;
		}
	}

	.location-details {
		h5 {
			@include font-size(2.4);
			color: $red;
			font-weight: bold;
			letter-spacing: -0.5px;
			line-height: lh(34, 22);
			margin: 0 0 10px;
		}

		p {
			@include type-p1(desktopOnly);
			font-weight: normal;
			line-height: lh(28, 18);
			margin: 0;
			padding: 0;

			&:first-of-type {
				margin-bottom: 2px;
			}
		}
	}

	.location-icon {
		background: $iron;
		border-radius: 50%;
		height: 60px;
		left: 0px;
		margin: auto;
		text-align: center;
		top: 20px;
		width: 60px;
		z-index: 1;

		@include break-min($large1) {
			height: 70px;
			width: 70px;
		}

		img {
			margin-bottom: 10px;
			padding: 4px;
		}

		span {
			@include font-size(1.2);
		}
	}

	.btns-container {
		a {
			width: 100%;

			@include break-min($large1) {
				min-width: 144px;
				width: auto;
			}

			&:nth-of-type(2) {
				@extend .btn-round--white;
				margin-top: 12px;

				@include break-min($large1) {
					margin: 0 0 0 12px;
				}
			}
		}
	}
}
