.testimonial.testimonial-theme {
	&__red {
		background-color: $red;

		@include break-min($large2) {
			padding: 64px 0;
		}

		@include break-min($huge2) {
			padding: 40px 0;
		}

		.img-desktop {
			@include break-min($large2) {
				background-color: $white;
				padding: 32px;
			}
		}

		.quote {
			p {
				color: $white;
			}
		}
	}

	&__cool-white {
		background-color: $cool-white;
	}

	&__warm-white {
		background-color: $warm-white;
	}

	&__white {
		background-color: $white;
	}

	&__blue {
		background-color: $blue;

		@include break-min($large2) {
			padding: 64px 0;
		}

		@include break-min($huge2) {
			padding: 40px 0;
		}

		.img-desktop {
			@include break-min($large2) {
				background-color: $white;
				padding: 32px;
			}
		}

		.quote {
			p {
				color: $white;
			}
		}
	}

	&__left-img {
		@include break-min($large2) {
			flex-direction: row;
		}

		.img-desktop {
			@include break-min($large2) {
				margin-left: -7%;
				margin-right: 0;
			}
		}

		.quote {
			@include break-min($large2) {
				padding: 0 6% 0 39px;
			}

			@include break-min($huge2) {
				padding: 0 8% 0 64px;
			}
		}
	}

	&__text-only {
		@include break-min($large2) {
			padding: 0;
		}

		@include break-min($huge2) {
			padding: 0;
		}

		.img-desktop {
			@include break-min($large2) {
				display: none;
			}
		}

		.img-mobile {
			display: none;
		}

		.quote {
			@include break-min($large2) {
				padding: 185px 0 150px;
				width: 70%;
			}

			@include break-min($huge2) {
				padding: 185px 0 150px;
				width: 70%;
			}
		}
	}

	&__blue__left-img {
		@extend .testimonial.testimonial-theme__blue;
		@extend .testimonial.testimonial-theme__left-img;
	}

	&__white__left-img {
		@extend .testimonial.testimonial-theme__white;
		@extend .testimonial.testimonial-theme__left-img;
	}

	&__red__left-img {
		@extend .testimonial.testimonial-theme__red;
		@extend .testimonial.testimonial-theme__left-img;
	}

	&__red__text-only {
		@extend .testimonial.testimonial-theme__red;
		@extend .testimonial.testimonial-theme__text-only;
	}

	&__cool-white__text-only {
		@extend .testimonial.testimonial-theme__cool-white;
		@extend .testimonial.testimonial-theme__text-only;
	}

	&__warm-white__text-only {
		@extend .testimonial.testimonial-theme__warm-white;
		@extend .testimonial.testimonial-theme__text-only;
	}

	&__white__text-only {
		@extend .testimonial.testimonial-theme__white;
		@extend .testimonial.testimonial-theme__text-only;
	}
}
