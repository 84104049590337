.delivery-partners {
	padding-bottom: 40px;
	position: relative;

	.wrapper {
		background: $cool-white;
		padding: 0 30px 16px;

		@include break-min($large1) {
			max-width: none;
			padding: 40px 65px 40px;
			position: relative;
		}
	}

	h3 {
		@include type-h2();
		color: $blue;
		margin: 0 0 8px;
		padding-top: 40px;

		@include break-min($large1) {
			@include font-size(2.4);
			padding-top: 0;
		}
	}

	.flex-desktop {
		@include break-min($large1) {
			column-count: 2;
			display: grid;
			grid-column-gap: 50px;
			grid-template-columns: 40% 60%;
		}
	}

	.subtitle {
		@include type-p1();
		@include font-size(1.8);
		font-weight: 500;
		line-height: 1.6;
		margin-bottom: 18px;

		@include break-min($large1) {
			@include type-p2();
			grid-column: 1;
			grid-row: 1;
			margin-bottom: 0;
			margin-right: 50px;
		}
	}

	ul {
		@extend .delivery-btn-border-shadow;

		&.grid-view {
			@include break-min($large1) {
				display: grid;
				grid-column-gap: 8px;
				grid-row-gap: 13px;
				grid-template-columns: repeat(2, minmax(150px, 200px));
				margin-top: -35px;

				// IE11 does not support justify-content in this context, so
				// autoprefixer issues a warning about it. We have accepted the
				// fact that IE will not center this content.
				@supports (justify-content: center) {
					justify-content: center;
				}
			}

			li {
				@include break-min($large1) {
					margin: 0;

					@include ie-only() {
						margin: 0 8px 13px 0;

						&:first-child {
							grid-row: 1;
							grid-column: 1;
						}

						&:nth-child(2) {
							grid-row: 1;
							grid-column: 2;
						}

						&:nth-child(3) {
							grid-row: 2;
							grid-column: 1;
						}

						&:nth-child(4) {
							grid-row: 2;
							grid-column: 2s;
						}
					}
				}
			}
		}
	}
}
