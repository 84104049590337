@import "../common/location-tools";

// TODO: If these styles are appropriate to Canada, we should roll them
// into the common\_location-tools.scss file.
.location-tools {
	display: none;
	max-width: none;

	@include break-min($large1) {
		display: block;
		margin-left: 16px;
		padding: 0;
	}

	.current-location {
		align-items: center;
		display: flex;
		justify-content: center;
	}

	.icon-location-off {
		display: block;
		margin: 0;
		position: unset;
	}

	button {
		@include font-size(1.5);
		background: transparent;
		border: 0;
		color: $red;
		letter-spacing: -0.2px;
		line-height: 32px;
		padding: 0;
	}

	.change {
		button {
			@include link-underline();
			line-height: inherit;
			padding: 0 4px;
		}

		a,
		button {
			@include font-size(1.2);
			@include btn-to-link-conversion;
			background: inherit;
			border: 0;
			color: $red;
			font-weight: 600;
		}
	}

	.store-name {
		@include break-min($large1) {
			padding-right: 20px;
		}

		@include break-min($desktop) {
			padding-right: 0;
		}
	}
}
