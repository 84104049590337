// sass-lint:disable no-important no-vendor-prefixes
.content-hub-flippable-card {
	display: grid;

	@include break-min($large4) {
		margin-left: auto !important;
		margin-right: auto !important;
		grid-template-columns: repeat(12, [col-start] 104px);
		max-width: 1210px;
		width: 100%;
	}

	@include break-min($huge4) {
		max-width: 1260px;
	}

	&[data-card-count="3"] {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 0 32px 30px;

		@include break-min($large1) {
			display: grid;
			grid-template-columns: repeat(3, [col-start] 1fr);
		}

		@include break-min($medium4) {
			margin: 0 46px 30px;
		}

		@include break-min($large3) {
			margin: 0 72px 60px;
		}

		.scene {
			width: 50%;
			padding: 5px;
			transition: all 0.2s;

			@include break-min($large1) {
				width: auto;
			}

			.flippable-card {
				padding-bottom: 100%;
				width: 100%;
				margin: 0 auto;

				@include break-min($large1) {
					height: 353px;
					position: relative;
					max-width: 353px;
					width: 353px;
					padding-bottom: 0;
				}
			}
		}

		.scene:nth-child(even) {
			.flippable-card {
				@include break-min($large1) {
					margin-top: 45px;
				}
			}
		}
	}

	&[data-card-count="4"] {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 0 32px 30px;
		max-width: 840px;

		@include break-min($large1) {
			display: grid;
			grid-template-columns: repeat(2, [col-start] 1fr);
			column-gap: 40px;
		}

		@include break-min($medium4) {
			margin: 0 152px;
		}

		.scene {
			width: 50%;
			padding: 5px;

			@include break-min($large1) {
				width: auto;
			}
		}

		.flippable-card {
			padding-bottom: 100%;
			width: 100%;
			margin: 0 auto;

			@include break-min($large1) {
				margin: 0 auto 40px;
			}
		}
	}

	&[data-card-count="5"] {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 32px 30px;

		@include break-min($medium4) {
			margin: 0 46px 30px;
		}

		@include break-min($large1) {
			column-gap: 20px;
		}

		@include break-min($large4) {
			margin: 0 72px 60px;
			column-gap: 40px;
		}

		.scene {
			width: 50%;
			padding: 5px;

			@include break-min($large1) {
				width: auto;
			}
		}

		.flippable-card {
			padding-bottom: 100%;
			width: 100%;
			margin: 0 auto;

			@include break-min($large1) {
				width: 260px;
				height: 283px;
				margin: 0px auto 10px;
			}

			@include break-min($large3) {
				width: 300px;
				height: 313px;
				padding-bottom: 0;
				margin: 0 auto 40px;
			}

			@include break-min($large4) {
				width: 353px;
				height: 353px;
				padding-bottom: 0;
				margin: 0 auto 40px;
			}
		}
	}

	&[data-card-count="7"] {
		margin: 0 27px 30px;
		row-gap: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: start;
		opacity: 0;

		@include break-min($large1) {
			display: flex;
			flex-flow: column wrap;
			height: 100vh;
			max-width: 900px;
		}

		@include break-min($large3) {
			margin: 0 72px 60px;
			max-width: 1040px;
		}

		@include break-min($large4) {
			max-width: 1210px;
			width: 100%;
		}

		&:before {
			content: "";
			clear: both;
			display: table;
		}

		&:before,
		&:after {
			content: "";
			flex-basis: 100%;
			width: 0;
			order: 2;
		}

		.scene {
			display: inline-block;
			position: relative;
			padding: 5px;
			width: 50%;
			transition: all 0.2s;

			@include break-min($large1) {
				padding: 0;
				width: auto;
			}

			&:nth-of-type(3n + 1) {
				@include break-min($large1) {
					order: 1;
					top: 18%;
				}
			}

			&:nth-of-type(3n + 2) {
				@include break-min($large1) {
					order: 2;
				}
			}

			&:nth-of-type(3n) {
				@include break-min($large1) {
					order: 3;
					top: 18%;
				}
			}

			&:last-child {
				&:nth-of-type(3n + 1) {
					@include break-min($large1) {
						order: 2;
						top: 50px;
					}
				}
			}

			.flippable-card {
				text-align: center;
				width: 100%;
				margin: 0 auto;
				height: 100%;
				padding-bottom: 100%;

				@include break-min($large1) {
					width: 273px;
					height: 300px;
					margin: 0 auto 50px auto;
				}

				@include break-min($large3) {
					width: 300px;
					height: 323px;
				}

				@include break-min($large4) {
					width: 353px;
					height: 353px;
				}

				&-wrapper {
					width: 100%;
					margin: 0 auto;
				}
			}
		}
	}

	.scene {
		perspective: 600px;
		width: 50%;
		transition: all 0.2s;

		@include break-min($large1) {
			width: auto;
		}

		&:nth-of-type(3n + 1) {
			@include break-min($large1) {
				order: 1;
			}
		}

		&:nth-of-type(3n + 2) {
			@include break-min($large1) {
				order: 2;
				top: 50px;
			}
		}

		&:nth-of-type(3n) {
			@include break-min($large1) {
				order: 3;
			}
		}
	}

	.flippable-card {
		border-radius: 16px;
		margin: 0 25px;
		position: relative;
		transition: all 1s;
		transform-style: preserve-3d;
		cursor: pointer;

		@include break-min($large1) {
			width: 353px;
			max-height: 353px;
			border-radius: 32px;
		}

		&.is-flipped {
			transform: rotateY(180deg);

			.card {
				.card-title {
					font-size: 5.3vw;

					@include break-min($large1) {
						@include font-size(1.8);
					}
				}

				p {
					font-size: 3.2vw;

					@include break-min($large1) {
						@include font-size(1.6);
					}
				}
			}
		}

		&-wrapper {
			display: flex;
			align-items: center;
			flex-flow: column;
			padding: 10px;
			height: 100%;
			justify-content: center;

			.flip-image {
				img {
					width: 70%;

					@include break-min($large1) {
						width: auto;
					}
				}
			}

			h2 {
				color: $blue;
				font-family: $primary;
				font-weight: 700;
				line-height: lh(20, 18);
				letter-spacing: -0.5px;
				text-align: center;
				margin: 10px 0;
				@include font-size(1.8);

				@include break-min($large1) {
					margin: 20px auto;
					@include font-size(2.2);
				}
			}
		}

		.card {
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			flex-flow: column;
			justify-content: center;
			padding: 20px;
			border-radius: 16px;
			position: absolute;
			width: 100%;
			height: 100%;

			&-title {
				font-family: $primary;
				color: $white;
				font-weight: 700;
				margin-bottom: 10px;
				font-size: 2.3vw;

				@include break-min($large1) {
					@include font-size(1.8);
				}
			}

			@include break-min($large1) {
				border-radius: 32px;
				height: 100%;
				padding: 44px;
			}

			p {
				font-family: $primary;
				color: $white;
				margin: 0;
				font-size: 1.2vw;

				@include break-min($large1) {
					@include font-size(1.6);
				}
			}
		}

		&--front {
			background: $cool-white;

			a {
				position: absolute;
				bottom: 26px;
				text-align: center;
				color: $blue;

				&.btn-round {
					padding: 12px 9px;
				}

				@include break-max($medium4) {
					bottom: 10px;
					@include font-size(1.2);
				}
			}

			&--alternative-2 {
				h2 {
					@include font-size(3.6);

					@include break-max($medium4) {
						@include font-size(1.8);
					}
				}
			}

			&--alternative-3 {
				span,
				p {
					color: $blue;
					text-align: center;
				}

				.card-title {
					@include font-size(2.4);

					@include break-min($large1) {
						@include font-size(1.8);
					}

					@include break-max($medium4) {
						@include font-size(1.8);
					}
				}

				p {
					@include font-size(1.6);

					@include break-max($medium4) {
						@include font-size(1.6);
					}
				}
			}

			&--alternative-4 {
				background: $white;
			}
		}

		&--back {
			background-color: $red;
			transform: rotateY(180deg);
			display: flex;
			z-index: 1;

			&--alternative-2 {
				background-color: $blue;

				img .card-img {
					width: 196px;
					height: 196px;
					border-radius: 100%;
					margin: 0 auto 10px;
				}

				.item-title {
					color: $white;
					letter-spacing: -0.5px;
					font-weight: $bold-weight;
					line-height: 22px;
					margin-top: 5px;
					text-align: center;
					@include font-size(1.6);
				}

				.card-title {
					font-size: 24px !important;
					text-align: center;
					line-height: 150%;
					margin-bottom: 16px;
				}

				a {
					text-align: center;
					position: absolute;
					bottom: 26px;
					width: 100%;
					left: 0;
					color: $white;
				}
			}

			&--alternative-3 {
				h2 {
					text-align: center;
					color: $white;
					font-weight: bold;
					@include font-size(3.6);
				}
			}

			&--alternative-4 {
				background: $white;
				text-align: center;

				.large-image {
					object-fit: contain;
					height: 100%;
				}

				.item-title {
					color: $blue;
					letter-spacing: -0.5px;
					font-weight: $bold-weight;
					line-height: 22px;
					margin-top: 5px;
					text-align: center;
					@include font-size(1.6);
				}

				a {
					text-align: center;
					position: absolute;
					letter-spacing: -0.5px;
					font-weight: $bold-weight;
					bottom: 26px;
					width: 100%;
					left: 0;
					color: $blue;
					@include font-size(1.6);
				}
			}
		}

		.ribbon {
			background-color: $red;
			border-top-right-radius: 12px;
			border-bottom-right-radius: 12px;
			position: absolute;
			top: 30px;
			left: 0;
			padding: 7px 16px;

			&-blue {
				background-color: $blue;
			}

			span {
				color: $white;
				font-weight: $bold-weight;
				letter-spacing: -0.5px;
				@include font-size(1.8);
			}
		}

		.flip-icon {
			position: absolute;
			bottom: 10px;
			left: 10px;
			width: 30px;
			height: auto;

			@include break-min($large1) {
				bottom: 20px;
				left: 20px;
				width: auto;
			}
		}
	}

	&[data-edit="true"] {
		.flippable-card {
			.card {
				-webkit-backface-visibility: visible;
				backface-visibility: visible;
				position: relative;
			}

			&--back {
				transform: rotate(0deg);
			}
		}
	}
}
