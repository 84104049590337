.location-image {
	flex-flow: column;
	text-align: center;
	display: flex;

	p {
		margin-top: 10px;
		@include font-size(1.1);
	}

	img {
		max-width: 800px;
		margin: auto;
		width: 100%;
		object-fit: cover;
		height: 216px;
		display: none;

		@include break-min($large1) {
			display: block;
			height: 442px;
		}
	}
}
