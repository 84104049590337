.little-blue-menu {
	@extend .base-modal;
	background: $cool-white;

	.inner {
		padding: 47px 0 30px;

		@include break-min($large1) {
			padding: 53px 0 27px;
		}
	}

	.carousel-item {
		// sass-lint:disable no-important
		margin-bottom: 0 !important;
		// sass-lint:enable no-important

		.modal-carousel-item {
			.carousel-item-img {
				height: 240px;
				width: 100%;

				@include break-min($large1) {
					height: 217px;
				}

				img {
					height: 100%;
					object-fit: cover;
					width: auto;
				}
			}
		}

		.slick-dots {
			align-items: flex-start;
			column-gap: 7px;
			display: flex;
			justify-content: center;
			margin: 15px auto;
			position: relative;

			li {
				background: $lred;
				border-radius: 100%;
				height: 10px;
				width: 10px;

				&.slick-active {
					background: $light-red;
				}
			}
		}
	}

	.modal-content {
		padding: 0 20px;

		@include break-min($large1) {
			padding: 0 40px;
		}

		.modal-icon {
			height: auto;
			margin: 0 auto;
			width: 70px;

			img {
				height: auto;
				width: 100%;
			}
		}

		.modal-header {
			> *:first-child {
				@include font-size(2.4);
				color: $blue;
				letter-spacing: -1%;
				line-height: 32px;
				margin: 18px auto 5px;

				@include break-min($large1) {
					@include font-size(3.6);
					letter-spacing: -0.5%;
					margin-bottom: 13px;
				}
			}
		}

		.modal-description {
			p {
				@include font-size(1.6);
				font-weight: $regular-weight;
				letter-spacing: -0.1%;
				line-height: 24px;
				margin: 0 auto 10px;

				@include break-min($large1) {
					@include font-size(1.8);
					line-height: 28px;
				}
			}
		}

		.lbm-brands-list {
			align-items: center;
			column-gap: 15px;
			display: flex;
			justify-content: center;
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				img {
					height: auto;
					width: 100%;
				}
			}
		}

		.lbm-btn {
			align-items: center;
			background: linear-gradient(
				180deg,
				rgba(254, 67, 108, 0.987036) 0%,
				rgba(221, 0, 49, 0.99343) 49.32%,
				#c20726 100%
			);
			border-radius: 24px;
			display: flex;
			height: 48px;
			justify-content: center;
			margin: 25px auto;
			max-width: 280px;
			width: 100%;

			@include break-min($large1) {
				margin: 20px auto 18px;
			}

			a {
				color: $white;
			}
		}

		.modal-footnote {
			p {
				color: $grey;
				@include font-size(1.2);
				letter-spacing: -0.1%;
				line-height: 15px;
				margin: 0;
				text-align: left;
			}
		}
	}
}
