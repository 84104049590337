.about-us-header {
	@include top-borders();
	background: $cool-white;
	padding: 74px 0;

	@include break-min($large1) {
		padding: 101px 0px 39px;
	}

	.header-wrapper {
		text-align: center;

		@include break-min($large1) {
			@include desktop-grid();
			margin: 0 0 33px 0;
		}

		.title {
			margin: 0 0 16px 0;

			@include break-min($large1) {
				grid-row: 1;
				grid-column: col-start 3 / span 8;
			}

			> *:first-child {
				@include font-size(2.8);
				color: $blue;
				font-weight: $bold-weight;
				letter-spacing: -0.01em;
				line-height: lh(34, 28);
				margin: 0;

				@include break-min($large1) {
					@include font-size(4.8);
					line-height: lh(58, 48);
					letter-spacing: -1px;
				}
			}
		}

		.content {
			@include break-min($large1) {
				grid-row: 2;
				grid-column: col-start 3 / span 8;
			}

			p {
				@include font-size(1.8);
				color: $grey;
				letter-spacing: -0.5px;
				line-height: lh(28, 18);
				margin: 0 0 20px 0;
			}

			.operator-mention {
				margin: 32px 0 0;

				p {
					@include font-size(1.6);
					margin: 0;
					line-height: lh(25, 16);
					letter-spacing: 0px;

					&:first-of-type {
						@include font-size(1.8);
						color: $blue;
						font-weight: $bold-weight;
						margin: 0 0 16px 0;
						line-height: lh(22, 18);
						letter-spacing: 0.5px;
					}
				}
			}
		}

		h3 {
			@include font-size(3.6);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -0.01em;
			line-height: lh(34, 28);
			margin: 56px 0 16px 0;
		}
	}

	.lbm-partners-grid {
		display: flex;
		justify-content: center;
		flex-direction: column;
		list-style-type: none;
		padding: 0;
		margin: 0;

		@include break-min($large1) {
			flex-direction: row;
		}

		li {
			margin: 0 auto;

			@include break-min($large1) {
				margin: 0;
			}

			&:not(:last-child) {
				margin: 0 auto 50px;

				@include break-min($large1) {
					margin: 0 50px 0 0;
				}
			}
		}
	}
}
