.login-popout {
	background: $white;
	border-radius: 8px;
	box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.15);
	position: absolute;
	right: 192px;
	top: 49px;
	width: 310px;
	text-align: center;
	z-index: 300;

	@media only screen and (max-width: $mobile) {
		right: 15px;
		left: 15px;
		top: 55px;
		width: auto;
		max-width: 400px;
		margin: 0 0 0 auto;
	}

	//For arrow box css styling addition
	.arrow_box {
		padding: 20px 10px 17px;
		position: relative;
		background: #fdfffd;
		border: 4px solid #fafeff;
		border-radius: 8px;
	}

	.arrow_box:after,
	.arrow_box:before {
		bottom: 100%;
		left: 50%; //toggle to 70% on mobile
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;

		@media only screen and (max-width: $mobile) {
			left: auto;
			right: 5px;
		}
	}

	.arrow_box:after {
		border-color: rgba(253, 255, 253, 0);
		border-bottom-color: #fdfffd;
		border-width: 5px;
		margin-left: -5px;
	}

	.arrow_box:before {
		border-color: rgba(250, 254, 255, 0);
		border-bottom-color: #fafeff;
		border-width: 11px;
		margin-left: -11px;
	}

	.cp-nav__profile-icon {
		margin: -2px auto 18px;
	}

	h4 {
		@include font-size(3.2);
		color: $blue;
		font-weight: 700;
		margin: -9px 0 10px;

		@media only screen and (max-width: $mobile) {
			@include font-size(2.5);
			margin: 0 0 15px;
		}
	}

	button {
		margin: 20px auto 20px;
		width: 80%;
	}

	.dismiss-popout {
		color: $red;
		cursor: pointer;
	}
}
