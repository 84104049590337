.giftCard {
	.blogStories {
		&.wrapper {
			max-width: none;
			padding: 0;
		}
	}

	.block_img_header_txt {
		align-items: center;
		margin-top: -80px;
		padding: 64px 20px;

		@include break-min($large1) {
			padding: 100px 86px;
		}

		img {
			width: auto;
		}

		.content {
			h4 {
				line-height: 46px;
				letter-spacing: -0.8px;
				@include font-size(3.6);

				@include break-min($large1) {
					letter-spacing: -1px;
					line-height: 58px;
					@include font-size(4.8);
				}
			}
		}
	}

	.ecommerce-callout {
		.content {
			h4 {
				@include break-min($large1) {
					margin-bottom: 16px;
				}
			}

			.btn-container {
				margin-top: 30px;

				a:nth-of-type(1) {
					margin-bottom: 30px;
				}
			}
		}
	}
}
