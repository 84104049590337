// sass-lint:disable no-qualifying-elements

.feedback-form {
	.top-hero {
		@include align-items(center);
		display: flex;

		&:before {
			@include scrim($scrim);
		}
	}

	main {
		background-color: $bkgrey;

		@media only screen and (max-width: $mobile) {
			background-color: $white;
		}

		.flex {
			@media only screen and (max-width: $mobile) {
				display: block;

				&.wrapper {
					padding: 0;
				}
			}
		}

		.wrapper {
			max-width: $col7;

			@media only screen and (max-width: $sml-desktop) {
				max-width: $col8;
			}

			@media only screen and (max-width: $lrg-tablet) {
				max-width: $col10;
			}

			@media only screen and (max-width: $mobile) {
				max-width: none;
			}
		}

		form {
			padding: 50px 0;
			width: $col4;

			@media only screen and (max-width: $mobile) {
				padding: 25px 30px;
				width: auto;
			}
		}
	}

	article {
		margin-right: 2 * $col-margin;
		padding: 50px 0;
		width: $col8;

		@media only screen and (max-width: $mobile) {
			margin: 0;
			padding: 50px 30px 0;
			width: auto;
		}

		h1 {
			@include font-size(2.8);
			letter-spacing: 0;
			line-height: 150%;
		}
	}
}

.contact-support {
	.top-hero {
		@include align-items(center);
		display: flex;

		&:before {
			@include scrim($scrim);
		}

		h1 {
			font-weight: $medium-weight;
		}
	}

	.flex {
		@media only screen and (max-width: $mobile) {
			display: block;

			&.header-components {
				@include flexbox();
			}
		}
	}

	p,
	dd {
		a:not(.btn) {
			color: $vis-dkred;
		}
	}

	.listening {
		a:not(.btn) {
			@include link-underline();
			text-decoration: underline;
		}

		@media only screen and (max-width: $mobile) {
			background-color: $white;
		}

		.left {
			margin: 0 (2 * $col-margin) 0 (4 * $col-margin);
			width: $col6;

			@media only screen and (max-width: $mobile) {
				margin: 0;
				width: auto;
			}
		}

		.right {
			margin-left: 3 * $col-margin;
			width: $col3;

			@media only screen and (max-width: $mobile) {
				margin: 50px 0;
				width: auto;
			}
		}
	}

	.visit {
		padding: 50px 0;

		@media only screen and (max-width: $mobile) {
			background-color: $cyan;
		}

		.left {
			margin: 0 (2 * $col-margin) 0 (4 * $col-margin);
			width: $col6;
		}

		.right {
			width: $col6;

			@media only screen and (max-width: $mobile) {
				display: none;
			}
		}
	}

	div.faq {
		@extend .listening;

		.left {
			margin: 0 $col-margin 0 (4 * $col-margin);
			width: $col4;
		}

		.right {
			width: $col8;

			@media only screen and (max-width: $mobile) {
				margin-top: 50px;
			}
		}

		@media only screen and (max-width: $mobile) {
			h2 {
				margin: 0 15px;
			}
		}
	}

	.listening,
	.visit,
	div.faq {
		.left,
		.right {
			@media only screen and (max-width: $mobile) {
				margin-left: 0;
				margin-right: 0;
				width: auto;
			}
		}
	}
}

dl {
	&.hide {
		height: 0;
		overflow: hidden;
	}

	&.faq {
		font-family: $primary;
		line-height: 1.5;

		@media only screen and (max-width: $mobile) {
			margin: 0;
		}

		dt {
			font-weight: 600;
			margin: 25px 0 0;

			&:before {
				margin-right: 5px;
			}
		}

		dd {
			border-bottom: 1px solid $dkgrey;
			margin: 10px 0;
			padding: 0 0 15px 0;

			a {
				@include link-underline();
			}

			@media only screen and (max-width: $mobile) {
				&:last-child {
					border: 0;
				}
			}

			& > p {
				&:first-child {
					&:before {
						margin-right: 5px;
					}
				}
			}
		}

		dt,
		dd {
			@media only screen and (max-width: $mobile) {
				background-color: $cyan;
				margin: 0;
				padding: 10px 25px;
			}
		}
	}
}

div.faq {
	.wrapper {
		@media only screen and (max-width: $mobile) {
			padding: 0;
		}
	}

	h4 {
		@include font-size(2.8);
		background-color: $white;
		border-top: 1px solid $dkgrey;
		display: none;
		letter-spacing: 0;
		line-height: 150%;
		margin: 0;
		padding: 10px;

		@media only screen and (max-width: $mobile) {
			display: block;
		}

		&.toggle {
			color: $red;
			top: 46px;
			z-index: 2000;

			&:after {
				@include font-size(1.8);
				content: "\e902";
				font-family: $icon;
				float: right;
				margin: 3px;
			}

			&.active {
				box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);

				&:after {
					@include transform(rotate(180deg));
					margin-top: 8px;
				}
			}
		}
	}
}

.faq-scroller {
	h2 {
		padding-top: 30px;
	}

	&.sticky {
		top: 60px;

		@media only screen and (max-width: $mobile) {
			position: static;
			top: 0;
		}
	}
}

.support-landing {
	.userform {
		margin-top: 37px;
		width: auto;

		.selectlist {
			max-width: 345px;
		}

		@media only screen and (max-width: $mobile) {
			width: $col10;
		}
	}
}

.support-form {
	.userform {
		margin: 0 auto;
		width: $col4;

		p {
			a {
				@include link-underline();
			}
		}

		@media only screen and (max-width: $lrg-tablet) {
			width: $col6;
		}

		@media only screen and (max-width: $mobile) {
			width: $col11;
		}
	}
}

.support-confirmation {
	.intro {
		@include font-size(1.6);
		line-height: 28px;
		padding-bottom: 50px;
		padding-top: 50px;
		text-align: left;

		p {
			margin-bottom: 20px;
		}

		@media only screen and (max-width: $mobile) {
			padding: 50px 25px;
		}

		h2 {
			@include font-size(3.6);
			color: $blue;
			font-family: $primary;
			line-height: 34px;
			text-align: left;
			margin-bottom: 20px;
		}
	}
}
