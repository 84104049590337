.stories-feature {
	border-top: 1px solid $bkgrey;
	margin: 0;
	padding: 20px 0;
	width: 100%;

	.story-img {
		@media only screen and (min-width: $lrg-tablet) {
			height: 200px;
		}
	}

	&.shared {
		background-color: $white;
		position: absolute;
		top: 100%;

		.cs {
			@include fir("img/cs-logo.png", 150px, 150px);
			background-size: cover;
		}

		.flex {
			& > div {
				&:first-child {
					cursor: initial;

					a {
						@include font-size(1.6);
						color: $red;
						font-weight: 400;
						line-height: 24px;
					}
				}
			}
		}

		a {
			@include font-size(1.8);
			@include link-underline();
			color: $dkgrey;
			display: inline-block;
			font-weight: 800;
			line-height: 30px;
			margin-top: 10px;
		}

		.story-img {
			@media only screen and (min-width: $lrg-tablet) {
				height: 120px;
			}
		}
	}

	&.section {
		background-color: $white;
		left: 0;
		opacity: 0;
		position: absolute;
		transition: all 0.5s ease-in-out;
		z-index: 0;

		.flex {
			& > div {
				width: $col3;

				&:first-child {
					border: 0;
					padding: 0;
					text-align: left;
					width: $col3;
				}
			}
		}
	}

	@media only screen and (max-width: $mobile) {
		display: none;
	}

	.flex {
		& > div {
			@include font-size(1.6);
			font-family: $primary;
			line-height: 28px;
			margin-left: $col-margin;
			text-align: left;
			width: $col3;

			&:hover {
				cursor: pointer;
			}

			&:first-child {
				border-right: 1px solid $faint-grey;
				padding-right: 30px;
				text-align: center;
				width: $col3;
			}
		}
	}

	.tag {
		@include font-size(1);
		letter-spacing: 2px;
		line-height: 20px;
		text-transform: uppercase;
	}

	a {
		border: 0;
		color: $dkgrey;
	}

	img {
		margin-bottom: 10px;
		width: 100%;
	}
}
