.global-form-radio {
	position: relative;

	label {
		@include p1();
		color: $vis-grey;
		margin: 0;
		padding: 0 0 0 45px;
		position: relative;
		cursor: pointer;
		pointer-events: initial;
		display: inline-block;
		z-index: $z2;

		&::before {
			content: "";
			margin: auto;
			display: block;
			width: 18px;
			height: 18px;
			background-color: $white;
			border: 1px solid $light-grey;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			border-radius: 50%;
		}
	}

	input {
		border: 0;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
		pointer-events: none;

		&:hover {
			+ label {
				&::before {
					background-color: lighten($red, 50%);
					border-color: $red;
				}
			}
		}

		&:focus {
			+ label {
				outline-offset: 3px;
				outline: 1px dotted $red;

				&::before {
					box-shadow: 0 0 0 3px lighten($red, 50%);
					border-color: $red;
				}
			}
		}

		&:checked {
			+ label {
				&::before {
					background-color: $white;
					border-color: $red;
				}

				&::after {
					content: "";
					position: absolute;
					background-color: $red;
					left: 3px;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 14px;
					height: 14px;
					border-radius: 50%;
				}
			}
		}

		&:disabled {
			+ label {
				cursor: not-allowed;

				&::before {
					opacity: 0.5;
					background-color: $white;
					border-color: $light-grey;
					box-shadow: none;
				}
			}
		}
	}
}
