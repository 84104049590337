@mixin bg-color() {
	&.bg {
		&__blue {
			.message-container {
				background: $blue;
			}
		}
		&__cool-white {
			.message-container {
				background: $cool-white;
			}
		}
		&__membership-red {
			.message-container {
				background: $membership-red;
			}
		}
		&__red {
			.message-container {
				background: $red;
			}
		}
		&__white {
			.message-container {
				background: $white;
			}
		}
		&__spice {
			.message-container {
				background: $spice;
			}
		}
	}
}
