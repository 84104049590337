.video-banner {
	padding: 64px 0 72px;

	@include break-min($large2) {
		padding: 80px 0 90px;
	}

	@include break-min($huge2) {
		padding-bottom: 112px;
	}

	.header {
		margin: auto;
		width: 87vw;

		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);
			margin-bottom: 16px;
			text-align: center;

			@include break-min($large2) {
				@include font-size(4.8);
				line-height: lh(58, 48);
			}
		}
	}

	.sub-text {
		margin-bottom: 40px;
		padding: 0 24px;
		text-align: center;

		@include break-min($large2) {
			margin: 0 auto 64px;
			max-width: 800px;
		}

		> *:first-child {
			@include font-size(1.8);
			color: $grey;
			font-weight: $regular-weight;
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
		}
	}

	.container_media-text {
		@include break-min($large2) {
			align-items: flex-start;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: auto;
			width: 90%;
		}
	}

	.container_media {
		iframe,
		img {
			height: 56vw;
			width: 100%;

			@include break-min($large2) {
				height: 27vw;
				width: 49vw;
			}
		}

		[src=""] {
			display: none;
		}
	}

	.container_article {
		margin: auto;
		padding: 40px 0 0;
		width: 87vw;

		@include break-min($large1) {
			width: 75vw;
		}

		@include break-min($large2) {
			margin-left: 4.5vw;
			padding-top: 0;
			width: 37vw;
		}

		.article-title {
			a {
				@include font-size(2.4);
				color: $red;
				font-weight: $bold-weight;
				letter-spacing: -1%;
				line-height: lh(32, 24);
				margin-bottom: 0;

				@include break-min($large2) {
					@include font-size(3.6);
					letter-spacing: -0.5px;
					line-height: lh(42, 36);
				}
			}
		}

		.article-location {
			p {
				@include font-size(1.4);
				color: $grey;
				font-weight: $bold-weight;
				line-height: lh(23, 14);
				margin-bottom: 0;
				margin-top: 4px;

				@include break-min($large1) {
					font-weight: $regular-weight;
					letter-spacing: -0.25px;
				}
			}
		}

		.article-info {
			p {
				@include font-size(1.4);
				color: $blue;
				font-weight: $regular-weight;
				line-height: lh(20, 14);
				margin-bottom: 0;
				margin-top: 4px;
			}
		}

		.article-text {
			p {
				@include font-size(1.8);
				color: $grey;
				font-weight: $regular-weight;
				letter-spacing: -0.5px;
				line-height: lh(28, 18);
				margin-bottom: 0;
				margin-top: 32px;
			}
		}

		.article-social-icons,
		.article-cta {
			display: none;
		}
	}
}
