.managePayRow {
	padding: 20px 0 20px 23px;
	position: relative;

	&:after {
		@include dot-border-h(bottom, left);
	}

	a {
		@include flexbox;
		@include align-items(center);
		@include flex-wrap(nowrap);
		//margin-bottom: 20px;
		min-height: 42px;
	}

	.card-img {
		display: block;
		margin-right: 26px;
		position: inherit;
		width: 35px;
		height: auto;
		top: 0;
		left: 33px;
		bottom: 0;
		z-index: 100;

		@include break-min($small2) {
			width: 50px;
		}
	}

	.arrow {
		height: 16px;
		width: 16px;
		position: absolute;
		right: 10px;
	}

	p {
		@include type-p3();
		color: $vis-grey;
		display: inherit;
		float: none;
		margin: auto 0;
		padding: 0;
		text-align: left;
	}

	&.cp-profile {
		padding: 0px 0 35px 0;

		a {
			margin-bottom: 0;
			text-decoration: none;
		}

		.arrow {
			display: none;
		}
	}

	&.round-border {
		border-radius: 10px;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

		&:after {
			background-image: none;
		}
	}
}
