.card-grid-cta {
	background: $red;
	padding: 71px 15px 16px;
	text-align: center;

	@include break-min($large1) {
		padding: 80px 0 118px;
	}

	.title {
		> *:first-child {
			@include type-h1-refresh();
			color: $white;
			margin: 0 0 72px 0;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		@include break-min($large1) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 0 0 32px;
		}

		@include break-min($huge4) {
			margin: 0 auto;
			width: 1248px;
		}

		li {
			margin: 0 0 16px 0;

			@include break-min($large1) {
				margin: 0 32px 16px 0;
				width: 45%;
			}

			@include break-min($large4) {
				width: 30%;
			}
		}

		.card {
			background: $cool-white;
			border-radius: 24px;
			padding: 24px;
			width: 100%;

			@include break-min($large1) {
				width: 100%;
			}
		}

		.card-title {
			> *:first-child {
				@include type-h3-refresh();
				color: $blue;
				margin: 0 0 8px 0;

				@include break-min($large1) {
					margin: 0 0 16px 0;
				}
			}
		}

		.btn-container {
			display: flex;
			justify-content: center;

			a {
				// width: 154px;

				&:first-of-type {
					@include btn-round;
					@include btn-primary-red-sm;
					width: 50%;
					margin: 0 6px 0 0;
				}

				&:last-child {
					@include btn-secondary-white-sm;
					margin: 0 auto 0;
					width: 50%;
				}
			}
		}
	}
}
