// sass-lint:disable no-ids

// This file will contain overrides necessary to make the pages render as
// expected in Sitecore's page editor
.sc-editor {
	.mobile-only {
		@media only screen and (min-width: $mobile) {
			display: block;
		}
	}

	.desktop-only {
		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}

	.main-nav {
		margin-left: 125px;

		@media only screen and (min-width: $mobile) {
			& > ul {
				& > li {
					&:nth-child(4),
					&:nth-child(5) {
						margin-right: 28px;

						@media only screen and (max-width: $sml-desktop) {
							margin-right: 28px;
						}
					}

					&:nth-child(6) {
						margin-right: 200px;

						@media only screen and (max-width: $desktop) {
							margin-right: 100px;
						}

						@media only screen and (max-width: $sml-desktop) {
							margin-right: 41px;
						}
					}
				}
			}
		}
	}

	header {
		&.lock {
			position: static;
		}
	}

	.sunday-video {
		display: block;
		margin: 10px 0;
		position: static;

		.close {
			display: none;
		}
	}

	.post-video {
		display: block;
		margin: 10px 0;
		position: static;
	}

	#stories {
		height: 1500px;
		max-height: none;
		overflow: visible;

		&.shown {
			.closed {
				display: block;
			}
		}

		.stories {
			flex-direction: column;
		}

		.stories-toggle {
			display: none;
		}

		&.teaser {
			max-height: none;

			&:hover {
				@media only screen and (min-width: $lrg-tablet) {
					max-height: none;
				}
			}
		}

		.story1,
		.story2,
		.story3 {
			min-height: 500px;
			width: $col12;

			&:hover {
				.initial {
					height: auto;
					opacity: 1;
					visibility: visible;
				}
			}

			.hover {
				height: auto;
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.story-carousel {
		.slide {
			background-color: $red;
			min-height: 400px;
		}
	}

	.header-frame {
		position: static;

		&.locked {
			position: static;
		}
	}

	.home-menu {
		&.fixed {
			display: none;
		}
	}

	.home {
		.co-tod {
			padding-bottom: 100px;
		}
	}

	.emergency-alert {
		position: static;
		z-index: 5000;
	}

	.catering-promo {
		img {
			width: $col12;
		}
	}
}
