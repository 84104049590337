.filter-modal {
	.link-wrapper {
		@include grid-margin();
		display: flex;
		color: $blue;
		cursor: pointer;
		justify-content: flex-end;
		position: relative;
		top: 5px;
		right: 0;
		z-index: 100;

		@include break-min($large4) {
			margin: 0 0 25px 0;
			top: 10px;
		}

		img {
			margin: 0 8px 0 0;
		}

		p {
			@include font-size(1.4);
			margin: 0;
			line-height: lh(23, 14);
			letter-spacing: -0.25px;
		}
	}

	&__modal {
		@extend .base-modal;
		background: $white;
		border-radius: 4px;
		margin: 0 24px;
		padding: 59px 24px 25px;

		@include break-min($large1) {
			max-width: 500px;
			margin: 0 auto;
			padding: 48px 45px;
		}

		.radio-input {
			@extend .global-form-radio;
		}

		.form-group {
			border-bottom: 1px solid $light-grey;
			margin: 0 0 30px 0;
			padding: 0 0 32px 0;
		}

		.radio-input label {
			@include font-size(1.6);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -0.267px;
			padding: 0 0 0 44px;
		}

		.filters {
			display: flex;
			list-style-type: none;
			flex-wrap: wrap;
			padding: 0;
			margin: 0;

			li {
				margin: 0 12px 12px 0;
			}

			li label {
				background: $white;
				border: 1px solid $blue;
				border-radius: 24px;
				color: $blue;
				cursor: pointer;
				display: block;
				font-weight: $bold-weight;
				height: 100%;
				padding: 14px 18px;
			}

			.input__wrapper p,
			.filter-categories p {
				@include font-size(1.8);
				color: $grey;
				letter-spacing: -0.3px;
				margin: 0 0 20px 0;
			}

			input {
				background: $white;
				border: 1px solid $blue;
				display: none;

				&:checked {
					+ label {
						background-color: $blue;
						color: white;
					}
				}
			}
		}

		.btn-container {
			margin: 22px 0 0;

			@include break-min($large1) {
				display: flex;
			}

			button {
				width: 100%;
				@include btn-round;
				@include btn-primary-red;

				@include break-min($large1) {
					width: 198px;
				}
			}

			button:first-of-type {
				margin: 0 0 18px 0;
				@include btn-round;
				@include btn-secondary-white;

				@include break-min($large1) {
					margin: 0 8px 0 0;
					width: 198px;
				}
			}
		}
	}
}
