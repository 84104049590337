.generic-section-with-cards {
	padding: 32px 0;

	.container {
		max-width: 1200px;
		margin: 0 auto;
		width: 100%;

		@include break-max($large4) {
			max-width: 960px;
		}

		@include break-max($large2) {
			max-width: 100%;
			padding: 0 32px;

			.cards-list {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 64px;
			}
		}

		@include break-max($medium2) {
			max-width: 100%;
			padding: 0 32px;

			header {
				max-width: 100%;
			}

			.cards-list {
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				gap: 64px 0;
			}
		}
	}

	header {
		text-align: center;
		max-width: 75%;
		margin: 0 auto;

		.title {
			@include font-size(4.8);
			color: $blue;
			font-weight: bold;
			margin-top: 0;
		}

		.subtitle {
			@include font-size(2.4);
			margin-bottom: 64px;
		}
	}

	.cards-list {
		display: flex;
		justify-content: center;
		gap: 0 64px;

		.card {
			h3 {
				color: $blue;
				font-weight: bold;
				margin: 16px 0;
			}

			p {
				line-height: 125%;
				margin: 0 0 32px;
			}
		}
	}
}
