.emergency-alert {
	background-color: $red;
	color: $white;
	padding: 24px 0 10px;
	text-align: center;
	z-index: 12000;
	position: fixed;
	width: $col12;

	a,
	p {
		color: $white;
		font-weight: 900;
	}

	a {
		@include link-underline();
	}

	.icon-close {
		position: absolute;
		right: 25px;
		top: 20px;
	}
}

.emergency-notice {
	// sass-lint:disable-block no-misspelled-properties
	@include font-size(1.6);
	background-color: $white;
	display: none;
	font-family: $secondary;
	height: 90%;
	line-height: 28px;
	overflow-x: hidden;
	overflow-y: scroll;
	padding-bottom: 80px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 15000;

	.wrapper {
		width: $col10;

		@media only screen and (max-width: $mobile) {
			padding: 0 30px;
		}
	}

	h1 {
		@include font-size(3.6);
		font-family: $primary;
		font-weight: 800;
		letter-spacing: -0.5px;
		line-height: 140%;
		margin-bottom: 10px;
	}

	h2 {
		@include font-size(1.2);
		font-family: $primary;
		letter-spacing: 2px;
		line-height: 20px;
		margin-bottom: 50px;
		text-transform: uppercase;
	}

	.close {
		@include font-size(1.2);
		color: $grey;
		display: inline-block;
		font-family: $primary;
		letter-spacing: 2px;
		line-height: 24px;
		margin: 20px;
		text-transform: uppercase;
	}

	.icon-close {
		color: $red;
		display: inline-block;
		margin-right: 10px;
	}

	.btn {
		margin-bottom: 30px;
		margin-top: 55px;
	}
}
