.question-details {
	background-color: $white;
	margin-bottom: 88px;
	padding: 16px 0 0;

	@include break-min($large1) {
		margin: 0 auto 112px;
		padding: 40px 0 0;
		width: 920px;
	}

	.header {
		padding: 0 24px;

		@include break-min($large1) {
			padding: 0;
		}

		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);
			margin-bottom: 48px;
			text-align: left;

			@include break-min($large1) {
				@include font-size(4.8);
				line-height: lh(58, 48);
			}
		}
	}

	&__answer {
		margin-bottom: 48px;
		padding: 0 24px;

		@include break-min($large1) {
			margin-bottom: 64px;
			padding: 0;
		}

		p {
			@include font-size(1.8);
			color: $grey;
			font-weight: $regular-weight;
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
			margin-bottom: 24px;
			text-align: left;

			a {
				color: $red;
			}
		}
	}

	&__feedback {
		align-items: center;
		border-top: 1px solid $iron;
		// display: flex;
		display: none;
		flex-direction: column;
		padding: 24px 36px 0;

		@include break-min($large1) {
			border: 1px solid $iron;
			border-radius: 8px;
			flex-direction: row;
			justify-content: space-between;
			padding: 16px 32px;
		}

		p {
			@include font-size(1.8);
			color: $grey;
			font-weight: $regular-weight;
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
			margin-bottom: 24px;
			text-align: center;

			@include break-min($large1) {
				font-weight: $bold-weight;
				line-height: lh(22, 18);
				margin-bottom: 0;
				text-align: left;
			}
		}

		&-btns {
			display: flex;

			span {
				align-items: center;
				border-radius: 24px;
				box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
				color: $red;
				cursor: pointer;
				display: inline-flex;
				height: 48px;
				justify-content: center;
				text-align: center;
				width: 144px;

				&:last-of-type {
					margin-left: 16px;
				}
			}
		}
	}
}
