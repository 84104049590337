.lll-faq {
	// sass-lint:disable-block no-duplicate-properties
	box-sizing: border-box;
	display: flex;
	flex: 0 0 auto;
	flex-direction: row;
	flex-grow: 1;
	flex-wrap: wrap;
	flex-basis: $col12;
	margin: 0 auto;
	max-width: $col12;
	padding: 34px 0 0;
	position: relative;

	@include breakpoint(tablet) {
		flex-basis: $col9;
		max-width: $col9;
		padding: 72px 0 0;
	}

	@include breakpoint(desktop) {
		flex-basis: $col8;
		max-width: $col8;
		padding: 54px 0 0;
	}

	h3 {
		@include font-size(3.2);
		color: $vis-blue;
		font-family: $primary;
		font-weight: 800;
		line-height: 48px;
		letter-spacing: -0.7px;
		font-variant-ligatures: none;
		// sass-lint:disable no-vendor-prefixes
		-webkit-font-variant-ligatures: none;
		// sass-lint:enable no-vendor-prefixes

		margin: 0;
		padding: 0 16px 16px;
		width: 100%;
		text-align: center;

		@include breakpoint(tablet) {
			border-bottom: 1px solid $mdgrey;
			padding: 0 24px 28px;
			text-align: left;
		}

		@include breakpoint(desktop) {
			text-align: left;
		}
	}

	h4 {
		@include font-size(2.3);
		color: $vis-blue;
		font-family: $primary;
		font-weight: 600;
		line-height: 36px;
		letter-spacing: -0.5px;
		font-variant-ligatures: none;
		// sass-lint:disable no-vendor-prefixes
		-webkit-font-variant-ligatures: none;
		// sass-lint:enable no-vendor-prefixes
	}

	p {
		@include font-size(1.8);
		font-family: $primary;
		font-weight: normal;
		line-height: 32px;
		letter-spacing: -0.5px;
		font-variant-ligatures: none;
		// sass-lint:disable no-vendor-prefixes
		-webkit-font-variant-ligatures: none;
		// sass-lint:enable no-vendor-prefixes
	}

	div {
		background: $white;
		margin: 4px 0 0;
		padding: 17px 16px 19px;
		position: relative;

		&:first-of-type {
			margin: 0;
		}

		@include breakpoint(tablet) {
			background: transparent;
			border-bottom: 1px solid $mdgrey;
			margin: 0;
			padding: 29px 24px 30px;
			width: 100%;

			&:last-of-type {
				border: 0;
			}
		}

		.lll-faq__question-toggle__arrow {
			// sass-lint:disable-block max-line-length
			cursor: pointer;
			fill: transparent;
			position: absolute;
			top: 29px;
			right: 16px;
			margin: auto;
			stroke: $red;
			transform: rotate(180deg);
			transform-origin: center;
			transition: transform 0.5s ease-in-out, color 0.5s ease-in-out,
				stroke 0.5s ease-in-out;
		}

		h4 {
			cursor: pointer;
			margin: 0;
			padding: 0 32px 0 0;

			@include breakpoint(tablet) {
				cursor: default;
				padding: 0;
			}
		}

		p {
			height: 0;
			margin: 0;
			overflow: hidden;
			transition: all 0.4s ease-in-out;

			@include breakpoint(tablet) {
				margin: 16px 0 0;
			}
		}

		&.active {
			.lll-faq__question-toggle__arrow {
				transform: rotate(360deg);
			}

			p {
				height: auto;
				margin: 24px 0 0;
				transition: all 0.4s ease-in-out;
			}
		}
	}

	&__btn-container {
		margin: 32px 0 0;
		padding: 0 16px;
		width: 100%;

		@include breakpoint(tablet) {
			margin: 30px 0 0;
			padding: 0 24px;
		}

		a {
			&:hover {
				color: $white;
			}
		}

		.btn {
			margin: 16px auto 0;
			max-width: 100%;
			text-align: center;
			width: 100%;
			@extend .btn-primary;

			@include breakpoint(tablet) {
				display: inline-block;
				float: left;
				margin: 0 0 0 16px;
				max-width: 336px;
				width: auto;

				&:first-of-type {
					margin: 0;
					// @extend .btn-primary;
				}
			}

			&:last-of-type {
				@extend .btn-secondary;
			}
		}
	}
}
