.location-amenities {
	margin: 0 0 24px 0;

	@include break-min($large1) {
		margin: 0 0 137px 0;
	}

	h3 {
		@include font-size(1.8);
		color: $blue;
		font-family: $primary;
		font-weight: $bold-weight;
		line-height: lh(22, 18);
		letter-spacing: 0.5px;
		margin: 0 0 36px 0;
		text-align: center;
		text-transform: uppercase;
	}

	ul {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		list-style-type: none;
		padding: 0;
		margin: 0 15px;

		li {
			align-items: center;
			margin: 0 16px 37px 0;

			&:last-child {
				@include break-min($large1) {
					margin: 0;
				}
			}

			@include break-min($large1) {
				margin: 0 36px 0 0;
			}

			img {
				margin: 0 8px 0 0;
			}
		}
	}

	p {
		@include type-p2-refresh();
		color: $dkgrey;
		margin: 20px 0 0;
		text-align: center;
	}
}
