.progress-bar-component {
	@include align-items(center);
	@include justify-content(center);
	@include flexbox();
	margin: 20px auto 30px;
	padding: 0 23px;
	position: relative;
	width: 100%;
	max-width: 1200px;

	@include break-min($large1) {
		margin: 35px auto 42px;
	}

	p {
		@include p1();
		@include font-size(1.4);
		color: $red;
		cursor: pointer;
		font-weight: $medium-weight;
		letter-spacing: -0.25px;
		margin: 1px 16px 0 -2px;

		@include break-min($large1) {
			@include font-size(1.8);
			margin: 1px 40px 0 -20px;
		}
	}

	.icon-arrow-down {
		font-family: icomoon;
		font-size: 17px;
		transform: rotate(90deg);
		left: 0;
	}

	.progress-bar {
		background: $warm-white;
		border: 2px solid $red;
		border-radius: 25px;
		height: 10px;
		margin: 10px 0;
		position: relative;
		width: 85%;

		@include break-min($large1) {
			height: 13px;
		}

		span {
			background-color: $red;
			border-radius: 25px;
			display: block;
			height: 100%;
			position: relative;
			overflow: hidden;
		}
	}
}
