.covid-serving-safely-modal {
	.mfp-content {
		@extend .base-modal;
		border-radius: 8px;
		max-width: 990px;
		padding: 50px 14px;

		@include break-min($large1) {
			padding: 80px 96px 60px;
		}

		button {
			@extend .mfp-close;
		}

		h2 {
			@include font-size(2.4);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -0.01em;
			line-height: lh(32, 24);
			margin: 0 0 16px 0;

			@include break-min($large1) {
				@include font-size(3.6);
				letter-spacing: -0.5px;
				line-height: lh(42, 36);
				margin: 0 0 30px 0;
			}
		}

		p {
			@include type-p1(desktopOnly);
			font-weight: $regular-weight;
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
		}

		ul {
			list-style: none;
			padding: 0;

			@include break-min($large1) {
				padding-left: 15px;
			}

			li {
				@include type-p2(desktopOnly);
				margin-bottom: 8px;
				padding-left: 16px;
				position: relative;

				&:before {
					color: $red;
					content: "\2022";
					display: inline-block;
					font-weight: $bold-weight;
					left: 0;
					position: absolute;
				}
			}
		}
	}
}
