.stc {
	// landing page
	.stc__page {
		.top-hero {
			height: 340px;

			h1 {
				max-width: 900px;
			}
		}
	}
}

// stc location-detail pg
.stc-details__page {
	.g-hero {
		h1 {
			@include type-h1();
		}
	}

	.block_img_header_txt {
		text-align: left;

		h2,
		h4 {
			@include break-min($large1) {
				@include type-h2();
				margin-bottom: 40px;
			}
		}
	}
}
