.process-steps {
	margin-top: 0;
	padding: 0 0 20px;

	@include break-min($large1) {
		padding: 0 0 50px;
	}

	.header {
		margin: 0 auto 40px;

		@include break-min($large1) {
			margin: 0 auto;
			width: $col9;
		}

		@include break-min($large3) {
			width: $col6;
		}

		h2 {
			margin-bottom: 10px;

			@include break-min($large1) {
				margin-bottom: 0;
			}
		}

		p {
			@include break-min($large1) {
				margin-bottom: 0;
			}
		}
	}

	// Styles for franchise selection process page
	.application {
		@include break-min($large1) {
			margin-bottom: 45px;
		}

		& .flex {
			flex-direction: row;
			scroll-snap-type: x mandatory;
			margin-top: 0px;
			overflow-x: scroll;
			overflow-y: hidden;

			dl {
				position: relative;
				scroll-snap-align: center;
				width: 45vw;

				@include break-max($large1) {
					margin: 0 40px;
				}

				@include break-min($large1) {
					width: 50vw;
				}
			}

			dl:not(:first-child) {
				&:before {
					content: url("img/franchise-icons/dotted-divider-short.svg");
					bottom: 0;
					height: 50px;
					left: -59%;
					position: absolute;
					top: 18%;
					width: 52%;

					@include break-min($large1) {
						content: url("img/franchise-icons/dotted-divider.svg");
						left: -30%;
						top: 28%;
					}
				}
			}
		}

		// Styles for application icons
		.icon {
			height: 100%;
			position: relative;
			width: 100%;
		}

		.white-circle-wrapper {
			&.selected {
				background-color: $vis-blue;
			}
		}

		// Styles for testimonials icons sections
		dl {
			text-align: center;
			@media screen and (min-width: $mobile) {
				margin: 65px auto 0;
			}
		}

		dt {
			color: $vis-blue;
			font-weight: 700;
		}

		dd {
			padding-bottom: 0;

			@media screen and (min-width: $mobile) {
				display: none;
			}
		}

		&__callout {
			@include break-min($large1) {
				margin: 0 auto;
				width: $col9;
			}

			@include break-min($large3) {
				width: $col6;
			}

			p {
				margin: 10px 0 0;
				padding: 0 12px;

				@include break-min($large1) {
					padding: 0;
				}
			}
		}
	}

	.application__callout {
		@include type-p2();
	}
}
