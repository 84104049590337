.title-bullet-themes {
	&__blue {
		background: $blue;
		color: $white;

		.title {
			p {
				&:first-of-type {
					color: $white;
				}
			}
		}

		ul {
			li {
				&::marker {
					color: $yellow-orange;
				}
			}
		}
	}

	&__cream {
		background: $cream;
	}

	&__cool-white {
		background: $bkgrey;
	}
}
