.leaving-cfa-modal {
	@extend .base-modal;
	border-radius: 10px;
	padding: 50px 20px 25px;

	.icon-close {
		@include lightbox-close-btn();

		&:before {
			content: none;
		}
	}

	.actions {
		.btn-round {
			margin: 0;

			&:first-child {
				margin-bottom: 10px;
				@include btn-round;
				@include btn-primary-red;

				@include break-min($large1) {
					margin: 0 10px 0 0;
				}
			}
		}
	}
}
