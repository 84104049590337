.card-default {
	p {
		@include type-p2();
		padding: 0;
	}

	.cp-form {
		position: relative;

		&:after {
			@include dot-border-h(bottom, left);
		}

		.cp-form__group--input {
			margin: 10px 0 0;
			max-width: 540px;

			span {
				@include type-p4();
				margin-top: 10px;
				position: inherit;

				&.red {
					color: $red;
				}
			}
		}

		.cp-checkbox--med-text {
			padding: 17px 0 27px;
		}

		.cp-checkbox {
			label {
				@include type-p2();
			}

			p {
				@include type-p4();
				margin-left: 35px;
			}
		}
	}

	.remove-card {
		padding-top: 30px;

		p {
			margin-bottom: 20px;
		}
	}
}
