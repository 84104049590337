.card-overlay {
	border-radius: 16px;
	margin-left: 32px;
	margin-right: 32px;
	padding-bottom: 47.5px;
	padding-top: 47.5px;

	@include break-min($medium4) {
		border-radius: 32px;
		margin-left: 46px;
		margin-right: 46px;
		padding: 46px;
	}

	@include break-min($large4) {
		margin-left: 72px;
		margin-right: 72px;
	}

	.message-container {
		border-radius: 16px;
		margin: 0;

		@include break-min($medium4) {
			border-radius: 32px;
		}

		.icon-container,
		.title-text {
			@include break-min($medium4) {
				margin-bottom: 8px;
			}
		}
		.body-text {
			margin-bottom: 10px;

			@include break-min($medium4) {
				margin-bottom: 60px;
			}
		}
	}
}
