.notification-banner {
	background: $red;
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	@include flex-wrap(wrap);
	text-align: center;
	width: 100%;
	padding: 14px 16px;

	@media screen and (min-width: $mobile) {
		@include flex-wrap(nowrap);
		padding: 14px 24px;
		height: 75px;
	}

	&.fixed {
		position: fixed;
		z-index: 1000;
	}

	.msg,
	.btn {
		max-width: 100%;
		@include flex(1 0 100%);
		margin: 0;

		@media screen and (min-width: $mobile) {
			@include flex(0 0 auto);
		}
	}

	.msg {
		color: $white;
		font-weight: 800;
		margin: 0;
		padding: 0 0 15px;

		@media screen and (min-width: $mobile) {
			text-align: left;
			padding: 0 25px 0 0;
			@include flex(0 1 auto);
		}
	}

	.btn {
		max-height: 50px;
		max-width: 300px;
	}
}
