// FLEXBOX VERSION OF GRID SYSTEM

.row {
	@include flexbox;
	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;

	&--reverse {
		@include flex-reverse();

		&--col {
			@include flex-reverse-column();
		}
	}

	&--nowrap {
		@include breakpoint(tablet) {
			flex-wrap: nowrap;
			//TODO: ADD LOGIC FOR BREAKPOINT SPECIFIC WRAPPING BASED ON DESIGN NEEDS
		}
	}
}

.col {
	box-sizing: border-box;
	flex: 0 0 auto;
	position: relative;

	&--full {
		flex-grow: 1;
		flex-basis: 0;
		max-width: 100%;
	}

	&--start {
		justify-content: flex-start;
		text-align: start;
	}

	&--center {
		justify-content: center;
		text-align: center;
	}

	&--v-center {
		align-items: center;
		height: 100%;
	}

	&--end {
		justify-content: flex-end;
		text-align: end;
	}

	&--offset {
		padding-left: 12px;

		@include breakpoint(tablet) {
			padding-left: 24px;
		}

		@include breakpoint(tabletLarge) {
			padding-left: 28px;
		}

		@include breakpoint(desktop) {
			padding-left: 34px;
		}

		&:first-of-type {
			padding-left: 0;
		}

		div {
			overflow: hidden;
		}
	}

	&--offset-single {
		padding-left: 12px;

		@include breakpoint(tablet) {
			padding-left: 24px;
		}

		@include breakpoint(tabletLarge) {
			padding-left: 28px;
		}

		@include breakpoint(desktop) {
			padding-left: 34px;
		}

		div {
			overflow: hidden;
		}
	}

	&--grid-offset {
		padding: 0 6px;

		@include breakpoint(tablet) {
			padding: 0 12px;
		}

		@include breakpoint(tabletLarge) {
			padding: 0 14px;
		}

		@include breakpoint(desktop) {
			padding: 0 25px;
		}

		div {
			overflow: hidden;
		}
	}
}

.col-sm-1 {
	flex-basis: calc(100% * (1 / 4));
	max-width: calc(100% * (1 / 4));
}

.col-sm-2 {
	flex-basis: calc(100% * (2 / 4));
	max-width: calc(100% * (2 / 4));
}

.col-sm-3 {
	flex-basis: calc(100% * (3 / 4));
	max-width: calc(100% * (3 / 4));
}

.col-sm-4 {
	flex-basis: calc(100% * (4 / 4));
	max-width: calc(100% * (4 / 4));

	&.col--offset {
		padding-left: 0;

		@include breakpoint(tablet) {
			padding-left: 20px;
		}

		&:first-of-type {
			padding-left: 0;
		}
	}
}

.col-md-1 {
	@include breakpoint(tablet) {
		flex-basis: calc(100% * (1 / 8));
		max-width: calc(100% * (1 / 8));
	}
}

.col-md-2 {
	@include breakpoint(tablet) {
		flex-basis: calc(100% * (2 / 8));
		max-width: calc(100% * (2 / 8));
	}
}

.col-md-3 {
	@include breakpoint(tablet) {
		flex-basis: calc(100% * (3 / 8));
		max-width: calc(100% * (3 / 8));
	}
}

.col-md-4 {
	@include breakpoint(tablet) {
		flex-basis: calc(100% * (4 / 8));
		max-width: calc(100% * (4 / 8));
	}
}

.col-md-5 {
	@include breakpoint(tablet) {
		flex-basis: calc(100% * (5 / 8));
		max-width: calc(100% * (5 / 8));
	}
}

.col-md-6 {
	@include breakpoint(tablet) {
		flex-basis: calc(100% * (6 / 8));
		max-width: calc(100% * (6 / 8));
	}
}

.col-md-7 {
	@include breakpoint(tablet) {
		flex-basis: calc(100% * (7 / 8));
		max-width: calc(100% * (7 / 8));
	}
}

.col-md-8 {
	@include breakpoint(tablet) {
		flex-basis: calc(100% * (8 / 8));
		max-width: calc(100% * (8 / 8));

		&.col--offset {
			padding-left: 0;

			@include breakpoint(desktop) {
				padding-left: 30px;
			}

			&:first-of-type {
				padding-left: 0;
			}
		}
	}
}

.col-lg-1 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (1 / 12));
		max-width: calc(100% * (1 / 12));
	}
}

.col-lg-2 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (2 / 12));
		max-width: calc(100% * (2 / 12));
	}
}

.col-lg-3 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (3 / 12));
		max-width: calc(100% * (3 / 12));
	}
}

.col-lg-4 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (4 / 12));
		max-width: calc(100% * (4 / 12));
	}
}

.col-lg-5 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (5 / 12));
		max-width: calc(100% * (5 / 12));

		&--gutter {
			flex-basis: calc(100% * (5 / 12) - 40px);
			max-width: calc(100% * (5 / 12) - 40px);
		}
	}
}

.col-lg-6 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (6 / 12));
		max-width: calc(100% * (6 / 12));
	}
}

.col-lg-7 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (7 / 12));
		max-width: calc(100% * (7 / 12));
	}
}

.col-lg-8 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (8 / 12));
		max-width: calc(100% * (8 / 12));
	}
}

.col-lg-9 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (9 / 12));
		max-width: calc(100% * (9 / 12));
	}
}

.col-lg-10 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (10 / 12));
		max-width: calc(100% * (10 / 12));
	}
}

.col-lg-11 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (11 / 12));
		max-width: calc(100% * (11 / 12));
	}
}

.col-lg-12 {
	@include breakpoint(desktop) {
		flex-basis: calc(100% * (12 / 12));
		max-width: calc(100% * (12 / 12));
	}

	&.col--offset {
		padding-left: 0;

		&:first-of-type {
			padding-left: 0;
		}
	}
}

.site-wrapper {
	margin: 0 16px;

	@include breakpoint(tablet) {
		margin: 0 32px;
	}

	@include breakpoint(tabletLarge) {
		margin: 0 40px;
	}

	@include breakpoint(desktop) {
		margin: 0 51px;
	}

	&--padding {
		padding: 0 16px;

		@include breakpoint(tablet) {
			padding: 0 32px;
		}

		@include breakpoint(tabletLarge) {
			padding: 0 40px;
		}

		@include breakpoint(desktop) {
			padding: 0 51px;
		}
	}
}

.page-offset {
	padding-top: 71px;

	@include breakpoint(tabletLarge) {
		padding-top: 128px;
	}
}
