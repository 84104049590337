@import "../accordion/variations/delivery-accordion";

.careers-tab {
	background: $cool-white;
	border-top-left-radius: 40px;
	border-top-right-radius: 40px;
	padding: 48px 0 105px;

	@include break-min($large1) {
		padding: 101px 0 39px;
	}

	.gridView {
		width: 100%;
		max-width: fit-content;
		margin: 0 auto;
	}

	&.show-view-more {
		.show-more-btn {
			display: block;
		}
	}

	.header {
		text-align: center;

		@include break-min($large1) {
			margin: auto;
			max-width: 900px;
		}

		> *:first-child {
			@include type-h1-refresh();
			color: $blue;
			margin: 0 0 16px 0;
		}

		p {
			@include type-p1-refresh();
			margin: 0 0 16px 0;
		}

		a {
			@include type-p1-refresh();
			display: block;
			margin: 0 0 40px 0;
		}

		.btn-container {
			&[data-child-count="1"] {
				.btn-round--red {
					line-height: 1;
					margin: 0 auto;
				}
			}

			.btn-round--red {
				margin: 0 auto 24px;
				width: fit-content;

				@include break-min($large1) {
					margin: 0 24px 0 0;
				}
			}
		}

		.third-party {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 24px 0;

			img {
				margin: 0 8px 0 0;
			}

			a {
				margin: 0;
				width: fit-content;
			}
		}
	}

	// ul element
	.careers-tiles {
		list-style-type: none;
		margin: 0;
		padding: 0;
		text-align: center;

		@include break-min($large1) {
			@include desktop-grid();
			grid-auto-flow: row;
			grid-gap: 24px;
		}

		.text-tile,
		.open-position-tile {
			align-items: center;
			display: flex;
			border-radius: 40px;
			flex-direction: column;
			justify-content: center;
			margin: 0 0 16px 0;
			min-height: 241px;

			@include break-min($large1) {
				margin: 0;
				grid-column: auto / span 4;
			}
		}

		// li element
		.text-tile {
			@include font-size(3.6);
			background: $red;
			color: $white;
			font-weight: $bold-weight;
			letter-spacing: -0.8px;
			line-height: lh(46, 36);
			padding: 0 24px;
		}

		// li element
		.open-position-tile {
			background: $white;
			padding: 24px 0;

			p {
				@include type-h3-refresh();
				color: $blue;
				margin: 0 0 19px 0;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				list-style-type: none;
				margin: 0;
				padding: 0;

				li {
					@include type-p3-refresh();
					background: $cool-white;
					border-radius: 24px;
					color: $blue;
					margin: 0 16px 16px 0;
					padding: 4px 24px;
				}
			}

			.btn-round {
				margin: 3px auto 0;
				max-width: 185px;
			}
		}
	}

	.show-more-btn {
		display: flex;
		justify-content: center;
		margin: 48px auto 0;
		min-width: 220px;
		width: auto;
	}

	.accordion {
		@extend .delivery-accordion;
		margin: 96px 0;
	}

	.title-bullet-list-callout {
		background-color: $warm-white;
		margin: 0 0 0 0;
		max-width: 100%;
		padding: 64px 24px;
	}
}
