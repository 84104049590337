// sass-lint:disable-all
.sr-only {
	position: absolute;
	width: 0.0625rem;
	height: 0.0625rem;
	padding: 0;
	margin: -0.0625rem;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}

:root {
	--yxt-color-background-highlight: #fafafa;
	--yxt-color-background-dark: #a8a8a8;
	--yxt-color-brand-primary: #0f70f0;
	--yxt-color-brand-hover: #0c5ecb;
	--yxt-color-brand-white: #fff;
	--yxt-color-text-primary: #212121;
	--yxt-color-text-secondary: #757575;
	--yxt-color-text-neutral: #616161;
	--yxt-color-link-primary: var(--yxt-color-brand-primary);
	--yxt-color-borders: #dcdcdc;
	--yxt-color-error: #940000;
	--yxt-font-weight-bold: 700;
	--yxt-font-weight-semibold: 600;
	--yxt-font-weight-medium: 500;
	--yxt-font-weight-normal: 400;
	--yxt-font-weight-light: 300;
	--yxt-font-size-xs: 0.625rem;
	--yxt-font-size-sm: 0.75rem;
	--yxt-font-size-md: 0.875rem;
	--yxt-font-size-md-lg: 1rem;
	--yxt-font-size-lg: 1.125rem;
	--yxt-font-size-xlg: 1.25rem;
	--yxt-font-size-xxlg: 2.5rem;
	--yxt-line-height-xs: 1;
	--yxt-line-height-sm: 1.2;
	--yxt-line-height-md-sm: 1.33333;
	--yxt-line-height-md: 1.4;
	--yxt-line-height-lg: 1.5;
	--yxt-line-height-xlg: 1.66667;
	--yxt-line-height-xxlg: 1.7;
	--yxt-font-family: system-ui, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell,
		Helvetica Neue, sans-serif;
	--yxt-base-spacing-sm: 0.75rem;
	--yxt-base-spacing: 1rem;
	--yxt-module-footer-height: 1.5rem;
	--yxt-module-container-height: 1.25rem;
	--yxt-border-default: 0.0625rem solid var(--yxt-color-borders);
	--yxt-border-hover: 0.0625rem solid var(--yxt-color-brand-hover);
	--yxt-border-legacy: 0.0625rem solid #e9e9e9;
	--yxt-z-index-nav-more-modal: 2;
	--yxt-button-focus-border-size: 0.1875rem;
	--yxt-cards-min-width: 13.125rem;
	--yxt-container-desktop-base: 25rem;
}

.yxt-Answers-component {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.yxt-Answers-component :focus {
	outline: none;
}

.yxt-Answers-component input[type="checkbox"]:focus {
	outline: 0.0625rem solid #000;
}

:root {
	--yxt-searchbar-form-background-color: #fff;
	--yxt-searchbar-form-outline-color-base: var(--yxt-color-borders);
	--yxt-searchbar-focus-shadow-height: 0.625rem;
	--yxt-searchbar-focus-shadow: 0 0 var(--yxt-searchbar-focus-shadow-height) 0
		rgba(0, 0, 0, 0.1);
	--yxt-searchbar-form-border-radius: calc(var(--yxt-base-spacing) * 0.4);
	--yxt-searchbar-text-color: var(--yxt-color-text-primary);
	--yxt-searchbar-text-font-size: var(--yxt-font-size-md-lg);
	--yxt-searchbar-text-line-height: var(--yxt-line-height-lg);
	--yxt-searchbar-text-font-weight: var(--yxt-font-weight-normal);
	--yxt-searchbar-button-background-color-base: #fff;
	--yxt-searchbar-button-background-color-hover: #e9e9e9;
	--yxt-searchbar-button-background-color-active: var(
		--yxt-searchbar-button-background-color-base
	);
	--yxt-searchbar-button-text-color-base: var(--yxt-color-text-primary);
	--yxt-searchbar-button-text-color-active: var(
		--yxt-searchbar-button-text-color-base
	);
}

.yxt-GeoLocationFilter,
.yxt-SearchBar {
	width: 100%;
	height: 3.1875rem;
	margin-bottom: 0;
}

.yxt-GeoLocationFilter button[class*="yxt-"],
.yxt-GeoLocationFilter input[class*="yxt-"],
.yxt-SearchBar button[class*="yxt-"],
.yxt-SearchBar input[class*="yxt-"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.yxt-GeoLocationFilter-container,
.yxt-SearchBar-container {
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-radius: var(--yxt-searchbar-form-border-radius);
	border: 0.0625rem solid var(--yxt-searchbar-form-outline-color-base);
	background-color: var(--yxt-searchbar-form-background-color);
}

.yxt-GeoLocationFilter-container:focus-within,
.yxt-GeoLocationFilter-container:hover,
.yxt-SearchBar-container:focus-within,
.yxt-SearchBar-container:hover {
	-webkit-box-shadow: var(--yxt-searchbar-focus-shadow);
	box-shadow: var(--yxt-searchbar-focus-shadow);
}

.yxt-GeoLocationFilter-form,
.yxt-GeoLocationFilter-input-wrapper,
.yxt-SearchBar-form,
.yxt-SearchBar-input-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0;
}

.yxt-GeoLocationFilter-input,
.yxt-SearchBar-input {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-searchbar-text-font-size);
	line-height: var(--yxt-searchbar-text-line-height);
	font-weight: var(--yxt-searchbar-text-font-weight);
	font-style: normal;
	color: var(--yxt-searchbar-text-color);
	border: none;
	background-color: transparent;
	width: 100%;
	padding: calc(var(--yxt-base-spacing) / 2) var(--yxt-base-spacing);
}

.yxt-GeoLocationFilter-input::-ms-clear,
.yxt-SearchBar-input::-ms-clear {
	display: none;
	padding: 0;
	margin: 0;
}

.yxt-GeoLocationFilter-input:-ms-input-placeholder,
.yxt-SearchBar-input:-ms-input-placeholder {
	color: var(--yxt-color-text-secondary);
}

.yxt-GeoLocationFilter-clear,
.yxt-SearchBar-clear {
	padding-left: calc(var(--yxt-base-spacing) / 2);
	padding-right: calc(var(--yxt-base-spacing) / 2);
}

.yxt-GeoLocationFilter-button,
.yxt-SearchBar-button {
	padding-left: var(--yxt-base-spacing);
	padding-right: var(--yxt-base-spacing);
}

.yxt-GeoLocationFilter-button,
.yxt-GeoLocationFilter-clear,
.yxt-SearchBar-button,
.yxt-SearchBar-clear {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font: inherit;
	padding-top: calc(var(--yxt-base-spacing) / 2);
	padding-bottom: calc(var(--yxt-base-spacing) / 2);
	background-color: var(--yxt-searchbar-button-background-color-base);
	color: var(--yxt-color-text-secondary);
	cursor: pointer;
	border: none;
}

.yxt-GeoLocationFilter-button:focus,
.yxt-GeoLocationFilter-clear:focus,
.yxt-SearchBar-button:focus,
.yxt-SearchBar-clear:focus {
	background-color: var(--yxt-searchbar-button-background-color-hover);
}

.yxt-GeoLocationFilter--hidden,
.yxt-SearchBar--hidden {
	display: none;
}

.yxt-GeoLocationFilter-button:focus,
.yxt-GeoLocationFilter-input:focus,
.yxt-SearchBar-button:focus,
.yxt-SearchBar-input:focus {
	outline: none;
}

.yxt-GeoLocationFilter-label,
.yxt-SearchBar-label {
	position: absolute;
	width: 0.0625rem;
	height: 0.0625rem;
	padding: 0;
	margin: -0.0625rem;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.yxt-SearchBar-title {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-xlg);
	line-height: var(--yxt-line-height-md);
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: var(--yxt-color-text-primary);
	margin-bottom: var(--yxt-base-spacing);
}

@media (max-width: 47.9375rem) {
	.yxt-SearchBar-title {
		margin-left: var(--yxt-base-spacing);
	}
}

.yxt-SearchBar-AnimatedIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.js-yxt-AnimatedReverse svg {
	display: none;
}

.yxt-SearchBar-AnimatedIcon--paused svg > g {
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.yxt-GeoLocationFilter-title {
	position: absolute;
	width: 0.0625rem;
	height: 0.0625rem;
	padding: 0;
	margin: -0.0625rem;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.Icon {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.Icon svg {
	height: 1em;
	width: 1em;
	fill: currentColor;
}

.Icon--sm img,
.Icon--sm svg {
	height: 0.5em;
	width: 0.5em;
}

.Icon--lg img,
.Icon--lg svg {
	height: 2em;
	width: 2em;
}

.Icon-collapseUp svg {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}

.Icon-collapseDown svg {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.Icon-image {
	width: 1em;
	height: 1em;
	-o-object-fit: contain;
	object-fit: contain;
}

.Icon--yext_animated_reverse svg {
	color: #000;
	background: hsla(0, 0%, 100%, 0);
}

@-webkit-keyframes reverse-a0_o {
	0% {
		opacity: 0;
	}

	89.82% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes reverse-a0_o {
	0% {
		opacity: 0;
	}

	89.82% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes reverse-a1_o {
	0% {
		opacity: 0;
	}

	80.24% {
		opacity: 0;
	}

	89.82% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a1_o {
	0% {
		opacity: 0;
	}

	80.24% {
		opacity: 0;
	}

	89.82% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes reverse-a2_o {
	0% {
		opacity: 0;
	}

	70.06% {
		opacity: 0;
	}

	80.24% {
		opacity: 1;
	}

	89.82% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a2_o {
	0% {
		opacity: 0;
	}

	70.06% {
		opacity: 0;
	}

	80.24% {
		opacity: 1;
	}

	89.82% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes reverse-a3_o {
	0% {
		opacity: 0;
	}

	59.88% {
		opacity: 0;
	}

	70.06% {
		opacity: 1;
	}

	80.24% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a3_o {
	0% {
		opacity: 0;
	}

	59.88% {
		opacity: 0;
	}

	70.06% {
		opacity: 1;
	}

	80.24% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes reverse-a4_o {
	0% {
		opacity: 0;
	}

	50.299% {
		opacity: 0;
	}

	59.88% {
		opacity: 1;
	}

	70.06% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a4_o {
	0% {
		opacity: 0;
	}

	50.299% {
		opacity: 0;
	}

	59.88% {
		opacity: 1;
	}

	70.06% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes reverse-a5_o {
	0% {
		opacity: 0;
	}

	40.12% {
		opacity: 0;
	}

	50.299% {
		opacity: 1;
	}

	59.88% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a5_o {
	0% {
		opacity: 0;
	}

	40.12% {
		opacity: 0;
	}

	50.299% {
		opacity: 1;
	}

	59.88% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes reverse-a6_o {
	0% {
		opacity: 0;
	}

	29.94% {
		opacity: 0;
	}

	40.12% {
		opacity: 1;
	}

	50.299% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a6_o {
	0% {
		opacity: 0;
	}

	29.94% {
		opacity: 0;
	}

	40.12% {
		opacity: 1;
	}

	50.299% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes reverse-a7_o {
	0% {
		opacity: 0;
	}

	20.359% {
		opacity: 0;
	}

	29.94% {
		opacity: 1;
	}

	40.12% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a7_o {
	0% {
		opacity: 0;
	}

	20.359% {
		opacity: 0;
	}

	29.94% {
		opacity: 1;
	}

	40.12% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes reverse-a8_o {
	0% {
		opacity: 0;
	}

	10.18% {
		opacity: 0;
	}

	20.359% {
		opacity: 1;
	}

	29.94% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a8_o {
	0% {
		opacity: 0;
	}

	10.18% {
		opacity: 0;
	}

	20.359% {
		opacity: 1;
	}

	29.94% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes reverse-a9_o {
	0% {
		opacity: 0;
	}

	10.18% {
		opacity: 1;
	}

	20.359% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a9_o {
	0% {
		opacity: 0;
	}

	10.18% {
		opacity: 1;
	}

	20.359% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes reverse-a10_o {
	0% {
		opacity: 1;
	}

	10.18% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes reverse-a10_o {
	0% {
		opacity: 1;
	}

	10.18% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

.Icon--yext_animated_reverse svg > g {
	-webkit-animation-duration: 0.18s;
	animation-duration: 0.18s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.Icon--yext_animated_reverse svg > g:first-of-type {
	-webkit-animation-name: reverse-a0_o;
	animation-name: reverse-a0_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(2) {
	-webkit-animation-name: reverse-a1_o;
	animation-name: reverse-a1_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(3) {
	-webkit-animation-name: reverse-a2_o;
	animation-name: reverse-a2_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(4) {
	-webkit-animation-name: reverse-a3_o;
	animation-name: reverse-a3_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(5) {
	-webkit-animation-name: reverse-a4_o;
	animation-name: reverse-a4_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(6) {
	-webkit-animation-name: reverse-a5_o;
	animation-name: reverse-a5_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(7) {
	-webkit-animation-name: reverse-a6_o;
	animation-name: reverse-a6_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(8) {
	-webkit-animation-name: reverse-a7_o;
	animation-name: reverse-a7_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(9) {
	-webkit-animation-name: reverse-a8_o;
	animation-name: reverse-a8_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(10) {
	-webkit-animation-name: reverse-a9_o;
	animation-name: reverse-a9_o;
}

.Icon--yext_animated_reverse svg > g:nth-of-type(11) {
	-webkit-animation-name: reverse-a10_o;
	animation-name: reverse-a10_o;
}

.Icon--yext_animated_forward svg {
	color: #000;
	background: hsla(0, 0%, 100%, 0);
}

@-webkit-keyframes forward-a0_o {
	0% {
		opacity: 1;
	}

	9.29% {
		opacity: 1;
	}

	18.033% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a0_o {
	0% {
		opacity: 1;
	}

	9.29% {
		opacity: 1;
	}

	18.033% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a1_o {
	0% {
		opacity: 0;
	}

	9.29% {
		opacity: 0;
	}

	18.033% {
		opacity: 1;
	}

	27.322% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a1_o {
	0% {
		opacity: 0;
	}

	9.29% {
		opacity: 0;
	}

	18.033% {
		opacity: 1;
	}

	27.322% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a2_o {
	0% {
		opacity: 0;
	}

	18.033% {
		opacity: 0;
	}

	27.322% {
		opacity: 1;
	}

	36.612% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a2_o {
	0% {
		opacity: 0;
	}

	18.033% {
		opacity: 0;
	}

	27.322% {
		opacity: 1;
	}

	36.612% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a3_o {
	0% {
		opacity: 0;
	}

	27.322% {
		opacity: 0;
	}

	36.612% {
		opacity: 1;
	}

	45.355% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a3_o {
	0% {
		opacity: 0;
	}

	27.322% {
		opacity: 0;
	}

	36.612% {
		opacity: 1;
	}

	45.355% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a4_o {
	0% {
		opacity: 0;
	}

	36.612% {
		opacity: 0;
	}

	45.355% {
		opacity: 1;
	}

	54.645% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a4_o {
	0% {
		opacity: 0;
	}

	36.612% {
		opacity: 0;
	}

	45.355% {
		opacity: 1;
	}

	54.645% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a5_o {
	0% {
		opacity: 0;
	}

	45.355% {
		opacity: 0;
	}

	54.645% {
		opacity: 1;
	}

	63.934% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a5_o {
	0% {
		opacity: 0;
	}

	45.355% {
		opacity: 0;
	}

	54.645% {
		opacity: 1;
	}

	63.934% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a6_o {
	0% {
		opacity: 0;
	}

	54.645% {
		opacity: 0;
	}

	63.934% {
		opacity: 1;
	}

	72.678% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a6_o {
	0% {
		opacity: 0;
	}

	54.645% {
		opacity: 0;
	}

	63.934% {
		opacity: 1;
	}

	72.678% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a7_o {
	0% {
		opacity: 0;
	}

	63.934% {
		opacity: 0;
	}

	72.678% {
		opacity: 1;
	}

	81.967% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a7_o {
	0% {
		opacity: 0;
	}

	63.934% {
		opacity: 0;
	}

	72.678% {
		opacity: 1;
	}

	81.967% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a8_o {
	0% {
		opacity: 0;
	}

	72.678% {
		opacity: 0;
	}

	81.967% {
		opacity: 1;
	}

	91.257% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a8_o {
	0% {
		opacity: 0;
	}

	72.678% {
		opacity: 0;
	}

	81.967% {
		opacity: 1;
	}

	91.257% {
		opacity: 0;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a9_o {
	0% {
		opacity: 0;
	}

	81.967% {
		opacity: 0;
	}

	91.257% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes forward-a9_o {
	0% {
		opacity: 0;
	}

	81.967% {
		opacity: 0;
	}

	91.257% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes forward-a10_o {
	0% {
		opacity: 0;
	}

	91.257% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes forward-a10_o {
	0% {
		opacity: 0;
	}

	91.257% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.Icon--yext_animated_forward svg > g {
	-webkit-animation-duration: 0.18s;
	animation-duration: 0.18s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.Icon--yext_animated_forward svg > g:first-of-type {
	-webkit-animation-name: forward-a0_o;
	animation-name: forward-a0_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(2) {
	-webkit-animation-name: forward-a1_o;
	animation-name: forward-a1_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(3) {
	-webkit-animation-name: forward-a2_o;
	animation-name: forward-a2_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(4) {
	-webkit-animation-name: forward-a3_o;
	animation-name: forward-a3_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(5) {
	-webkit-animation-name: forward-a4_o;
	animation-name: forward-a4_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(6) {
	-webkit-animation-name: forward-a5_o;
	animation-name: forward-a5_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(7) {
	-webkit-animation-name: forward-a6_o;
	animation-name: forward-a6_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(8) {
	-webkit-animation-name: forward-a7_o;
	animation-name: forward-a7_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(9) {
	-webkit-animation-name: forward-a8_o;
	animation-name: forward-a8_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(10) {
	-webkit-animation-name: forward-a9_o;
	animation-name: forward-a9_o;
}

.Icon--yext_animated_forward svg > g:nth-of-type(11) {
	-webkit-animation-name: forward-a10_o;
	animation-name: forward-a10_o;
}

:root {
	--yxt-autocomplete-background-color: #fff;
	--yxt-autocomplete-box-shadow: 0 0.0625rem 0.375rem 0 rgba(32, 33, 36, 0.47);
	--yxt-autocomplete-selected-background-color: #f9f9f9;
	--yxt-autocomplete-option-hover-background-color: #f9f9f9;
	--yxt-autocomplete-separator-color: var(--yxt-color-borders);
	--yxt-autocomplete-text-font-size: var(--yxt-font-size-md-lg);
	--yxt-autocomplete-text-line-height: var(--yxt-line-height-md);
	--yxt-autocomplete-text-font-weight: var(--yxt-font-weight-normal);
	--yxt-autocomplete-text-color: var(--yxt-color-text-primary);
	--yxt-autocomplete-prompt-header-font-weight: var(--yxt-font-weight-light);
}

.yxt-AutoComplete {
	padding-bottom: 0.625rem;
}

@media (min-width: 48rem) {
	.yxt-AutoComplete:before {
		margin-left: var(--yxt-base-spacing);
		margin-right: var(--yxt-base-spacing);
		margin-bottom: calc(var(--yxt-base-spacing) * 0.625);
		height: 0.0625rem;
		background-color: var(--yxt-autocomplete-separator-color);
		content: "";
		display: block;
	}
}

.yxt-AutoComplete-results {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.yxt-AutoComplete-wrapper {
	background-color: var(--yxt-autocomplete-background-color);
}

.yxt-AutoComplete-option {
	padding: calc(var(--yxt-base-spacing) / 4) var(--yxt-base-spacing);
}

.yxt-AutoComplete-option--promptHeader {
	font-weight: var(--yxt-autocomplete-prompt-header-font-weight);
	font-style: italic;
}

.yxt-AutoComplete-option--item,
.yxt-AutoComplete-option--promptHeader {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-autocomplete-text-font-size);
	line-height: var(--yxt-autocomplete-text-line-height);
	color: var(--yxt-autocomplete-text-color);
}

.yxt-AutoComplete-option--item {
	cursor: pointer;
	font-weight: var(--yxt-autocomplete-text-font-weight);
	font-style: normal;
}

.yxt-AutoComplete-option--item.yxt-selected {
	background-color: var(--yxt-autocomplete-selected-background-color);
}

.yxt-AutoComplete-option--item strong {
	font-weight: var(--yxt-font-weight-semibold);
}

.yxt-AutoComplete-option--item:hover {
	background-color: var(--yxt-autocomplete-option-hover-background-color);
}
