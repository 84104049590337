.details-headline {
	padding-bottom: 40px;
	text-align: center;

	@include break-min($large1) {
		text-align: left;
	}

	h4 {
		margin: 0;
		font-weight: 700;
		@include font-size(2.4);
	}
}
