.breadcrumb-nav {
	@include text-color-options();
	@include background-color();
	background: $blue;
	border-bottom: 1px solid rgba(219, 224, 226, 0.2);
	color: $white;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 18px 20px;
	margin: 0;

	@include break-min($large1) {
		padding: 37px 10px;
		justify-content: center;
	}

	li {
		margin: 0 30px 0 0;

		&:not(:last-child) {
			&:after {
				@include font-size(1.3);
				// color: $white;
				content: "\e902";
				font-family: $icon;
				padding: 5px 10px;
				position: absolute;
				transition: 0.5s all ease;
				transform: rotate(-90deg);
				opacity: 0.4;
			}
		}

		&:last-child {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 300px;
		}
	}

	a {
		@include type-p3-refresh();
		color: $white;
		opacity: 0.4;
	}

	&.text-white {
		a {
			color: $white;
		}
	}

	&.text-blue {
		a {
			color: $blue;
		}
	}

	&.text-red {
		a {
			color: $red;
		}
	}

	&.text-peach {
		a {
			color: $peach;
		}
	}

	&.cool-white-theme {
		border-bottom: 1px solid rgba(0, 79, 113, 0.2);
		background-color: $cool-white;
		color: $blue;

		a {
			color: $blue;
			opacity: 0.8;
		}
	}
}
