// TODO: make index vals dynamic via TS layer
@mixin dropdown-transition($start-index: 0, $end-index: 5) {
	@for $i from $start-index through $end-index {
		&:nth-child(#{$i}) {
			transform-origin: top center;
			animation: slideDown 300ms ($i * 60ms) ease-in-out forwards;
		}
	}
}

@mixin remove-dropdown-transition($dropdown-list-elems: 5) {
	@for $i from 1 through $dropdown-list-elems {
		&:nth-child(#{$i}) {
			animation: none;
		}
	}
}

@keyframes slideDown {
	0% {
		opacity: 0;
		transform: translateY(-60px);
	}

	100% {
		display: block;
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes topSlidedown {
	0% {
		top: 50px;
	}

	100% {
		top: 63px;
	}
}

@mixin swap-callout-order() {
	@include break-max($large1) {
		display: flex;
		flex-direction: column;

		.img-wrapper {
			order: 2;
		}

		.content {
			margin-bottom: 25px;
			order: 1;
		}
	}
}
