.unsubscribe-footer {
	bottom: 28px;
	position: absolute;
	width: 100%;

	@include break-min($large1) {
		bottom: 21px;
	}

	p {
		@include font-size(1.2);
		color: $mdgrey;
		font-weight: 500;
		letter-spacing: -0.25px;
		line-height: lh(16, 12);
		margin: 0;
		text-align: center;
	}
}
