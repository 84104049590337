.about-us-tiles {
	@include text-color-options();
	@include background-color();
	@include add-margin();
	list-style-type: none;
	padding: 32px 0;

	@include break-min($large1) {
		padding-bottom: 42px;
		padding-top: 42px;
	}

	&.press-room {
		@include grid-margin();
		gap: 22px;
		padding: 49px 0 22px;
		max-width: 1465px;
		margin: 0;

		@include break-min($large1) {
			border-radius: 32px;
			margin-bottom: 0;
			margin-top: -80px;
			padding: 41px !important;
		}

		@include break-min($huge3) {
			margin: -80px auto 98px;
		}

		li .text-container p {
			@include type-h2-refresh();
			color: $blue;
			margin: 0 0 8px 0;
		}

		li .copy-text p {
			@include type-p2-refresh();
			text-align: center;
		}

		.img-tile {
			display: none;

			@include break-min($large1) {
				display: block;
			}
		}
	}

	&.address {
		li {
			.text-container {
				p {
					@include type-h4-refresh();
					color: $blue;
					margin: 0 0 8px 0;
				}

				span {
					color: $grey;
					@include type-p2-refresh();
				}
			}
		}
	}

	&.li-white {
		li:first-child {
			.img-text-cta-tile-wrapper {
				border: 3px solid $red;
				background: $white;
			}

			.text-tile-wrapper {
				border: 3px solid $red;
				background: $white;
			}

			.img-tile-wrapper {
				border: 3px solid $red;
				background: $white;
			}

			.video-tile-wrapper {
				border: 3px solid $red;
				background: $white;
			}
		}
	}

	&.li-items-white {
		li {
			.img-text-cta-tile-wrapper {
				background: $white;
			}

			.text-tile-wrapper {
				background: $white;
			}

			.img-tile-wrapper {
				background: $white;
			}

			.video-tile-wrapper {
				background: $white;
			}
		}
	}

	&.li-items-red {
		li {
			.img-text-cta-tile-wrapper {
				background: $red;
			}

			.text-tile-wrapper {
				background: $red;
			}

			.img-tile-wrapper {
				background: $red;
			}

			.video-tile-wrapper {
				background: $red;
			}
		}
	}

	&.li-items-blue {
		li {
			.img-text-cta-tile-wrapper {
				background: $blue;
			}

			.text-tile-wrapper {
				background: $blue;
			}

			.img-tile-wrapper {
				background: $blue;
			}

			.video-tile-wrapper {
				background: $blue;
			}
		}
	}

	&.li-items-pink {
		li {
			.img-text-cta-tile-wrapper {
				background: $pink;
			}

			.text-tile-wrapper {
				background: $pink;
			}

			.img-tile-wrapper {
				background: $pink;
			}

			.video-tile-wrapper {
				background: $pink;
			}
		}
	}

	&.li-text-white {
		li {
			p {
				color: $white !important;
			}
		}
	}

	@include break-min($large1) {
		@include desktop-grid();
		grid-auto-flow: row;
		grid-gap: 16px;
		grid-row: 2;
	}

	li {
		.text-tile-wrapper {
			border-radius: 32px;
			margin: 0 auto 16px;
			height: 280px;
			padding: 31px 42px;
			overflow: hidden;
		}

		.img-text-cta-tile-wrapper {
			border-radius: 32px;
			margin: 0 auto 16px;
			height: 280px;
			overflow: hidden;
		}

		.img-tile-wrapper {
			border-radius: 32px;
			margin: 0 auto 16px;
			height: 280px;
			padding: 31px 42px;
			overflow: hidden;
		}

		.video-tile-wrapper {
			border-radius: 32px;
			margin: 0 auto 16px;
			height: 280px;
			overflow: hidden;
		}

		@include break-min($large1) {
			grid-column: auto / span 4;
			margin: 0;
		}

		.subtext {
			> :first-child {
				@include font-size(2.4);
				color: $blue;
				font-weight: 700;
				margin: 10px 0;

				@include break-min($large1) {
					@include font-size(3);
				}
			}

			p {
				color: $grey;
				@include font-size(1.8);
				margin: 0;
			}
		}

		h2 {
			color: $blue;
			font-weight: 700;
			margin: 10px 0;
			@include font-size(2.4);

			@include break-min($large1) {
				@include font-size(3);
			}
		}

		.sub-text {
			color: $grey;
			@include font-size(1.8);
		}
	}

	.text-tile {
		.text-tile-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			word-wrap: break-word;

			p {
				@include font-size(1.8);
				line-height: lh(22, 18);

				&:first-of-type {
					margin: 0 0 22px 0;
				}
			}

			.highlighted {
				p {
					@include font-size(3.4);
					font-weight: $bold-weight;
					letter-spacing: -0.8px;
					line-height: lh(46, 36);
					margin: 0;

					@include break-max($large4) {
						@include font-size(2.8);
					}
				}
			}
		}
	}

	.img-tile {
		.img-tile-wrapper {
			position: relative;
			min-height: 280px;
			overflow: hidden;
			text-align: center;

			img {
				@include absolute-center();
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}

	.see-more-btn {
		display: flex;
		justify-content: center;
		width: 220px;
		margin: 48px auto 0;
	}

	.video-tile {
		cursor: pointer;
		position: relative;

		.video-tile-wrapper {
			.icon-container {
				@include absolute-center();
				width: 50px;
				height: 50px;
				background: $white;
				border-radius: 50%;
				margin: auto;
				overflow: hidden;
			}

			.play-icon {
				@include absolute-center();
				margin: auto;
				height: auto;
			}

			button {
				background: transparent;
				border: none;
				height: 100%;
				padding: 0;
				width: 100%;
				position: relative;
			}

			img:not(.play-icon) {
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}
	}

	.img-text-cta-tile {
		display: flex;

		&-wrapper {
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
			padding: 0;
			margin: 0;
			width: 100%;
		}

		.img-container {
			position: relative;
			height: 200px;
			width: 200px;
			margin: 0 auto;
			overflow: hidden;

			img {
				@include absolute-center();
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}

		.text-container {
			margin: 10px 0 0;
			text-align: center;

			p {
				@include font-size(2.4);
				letter-spacing: -0.5px;
				line-height: lh(32, 24);
				margin: 0;
				font-weight: 700;

				&.small-text {
					font-weight: normal;
					@include font-size(1.8);
				}
			}
		}

		.btn-container {
			display: flex;
			justify-content: center;
			flex-direction: column;

			a {
				margin: 0 auto;
				width: fit-content;
			}

			a:first-of-type {
				margin: 24px auto 8px;
			}
		}
	}

	// Full-width img rule
	&[data-child-count="1"] {
		li {
			border-radius: 0;

			@include break-min($large1) {
				grid-column: auto / span 12;
			}
		}

		.img-tile {
			border-radius: 0;
			height: 100%;
			min-height: 660px;
		}
	}

	&.product-landing {
		padding: 32px 24px;

		@include break-min($large1) {
			grid-template-rows: auto auto;
			padding: 42px 22px;
		}

		li {
			margin: 0 auto 16px;
			min-height: 300px;
			max-width: 400px;

			@include break-min($large1) {
				margin: 0;
				max-width: none;
			}
		}
	}

	&.product-view {
		grid-template-rows: 1fr;

		li {
			margin: 0 auto 16px;
			min-height: 300px;
			min-width: 300px;
			max-width: 400px;

			@include break-min($large1) {
				grid-column: 1 / span 3;
				margin: 0;
				min-width: unset;
				max-width: none;
			}
		}
	}

	&.gridLayout {
		@include desktop-grid-max-width();
		@include grid-padding();

		@include break-min($large1) {
			padding-bottom: 42px;
			padding-top: 42px;
		}
	}

	&.no-padding-bottom {
		@include break-min($large1) {
			padding: 0;
		}
	}

	&.mobile-buttons {
		@include break-max($large1) {
			display: grid;
			gap: 16px;
			grid-template-areas: "item1 item1" "item2 item3" "item4 item4" "item5 item5";
			margin: auto;
		}

		li {
			@include break-max($large1) {
				margin: 0 !important; // sass-lint:disable-line. Justification - need to overwrite style
				min-width: unset;
				max-width: none;
				width: 100%;

				&:nth-child(1) {
					grid-area: item1;
				}

				&:nth-child(2) {
					color: $red;
					grid-area: item2;

					.text-container {
						font-weight: $bold-weight;
						margin: 0;
					}

					.btn-container {
						display: none;
					}
				}

				&:nth-child(3) {
					color: $red;
					grid-area: item3;

					.text-container {
						font-weight: $bold-weight;
						margin: 0;
					}

					.btn-container {
						display: none;
					}
				}

				&:nth-child(4) {
					grid-area: item4;
				}

				&:nth-child(5) {
					grid-area: item5;
				}
			}
		}
	}

	&.justify-linear {
		@include break-min($large1) {
			grid-template-rows: 1fr;
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		}

		li {
			grid-column: auto;
		}
	}

	&.sm-img {
		li {
			.img-text-cta-tile-wrapper {
				justify-content: center;
			}

			.text-tile-wrapper {
				justify-content: center;
			}

			.img-tile-wrapper {
				justify-content: center;
			}

			.video-tile-wrapper {
				justify-content: center;
			}
		}

		.img-container {
			margin: 0 auto;
			height: 50px;
			width: 50px;
		}
	}

	&.btn-white {
		.btn-container a {
			@extend .vis-white;
		}
	}

	&.no-button {
		.btn-container {
			display: none;
		}
	}

	&.full-width {
		@include break-min($large1) {
			padding-left: 72px;
			padding-right: 72px;
		}
	}
}
