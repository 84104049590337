.gifting-back-link-nav {
	cursor: pointer;
	margin-bottom: 24px;
	position: relative;

	@include break-min($large1) {
		display: none;
	}

	a {
		@include font-size(1.4);
		color: $red;
		font-weight: 600;
		line-height: calc(20 / 14);
		margin-left: 28px;

		&:before {
			content: "";
			background-image: url("img/cp-icons/icon-cp_arrow-left.svg");
			width: 17px;
			height: 17px;
			background-size: cover;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto;
		}

		&:hover {
			@include link-underline();
			text-decoration-color: $red;
		}
	}
}
