.content-hub-carousel {
	position: relative;

	display: flex !important;
	flex-flow: column;
	margin: 0 0px 20px !important;

	@include break-min($medium2) {
		margin: 0 32px 40px !important;
	}

	@include break-min($large1) {
		display: grid !important;
		margin: 400px 46px 40px !important;
	}

	@include break-min($large3) {
		margin: 0 72px 40px !important;
	}

	.no-border-mobile {
		border-radius: 0;

		@include break-min($medium2) {
			border-radius: 32px;
		}
	}

	.carousel-floating {
		position: absolute;
		z-index: 1;
		width: 86px;
		top: 22%;
		left: 31%;
		display: none;

		@include break-min($medium4) {
			display: block;
		}

		@include break-max($large1) {
			display: none;
		}

		.carousel-item {
			@include break-min($medium4) {
				display: flex !important;
				align-items: center;
				height: 100%;
			}
		}
	}

	.carousel-wrapper {
		background-color: $white !important;

		.text-carousel {
			display: flex;
			align-items: center;
			margin-bottom: 0;

			@include break-min($large1) {
				height: 526px;
				margin-right: 50px;
			}

			@include break-min($large3) {
				height: 526px;
			}

			h5 {
				color: $blue;
				font-family: $primary;
				font-weight: 700;
				line-height: lh(22, 18);
				letter-spacing: -0.1px;
				text-align: center;
				margin: 0;
				@include font-size(2.4);

				@include break-min($medium4) {
					@include font-size(4);
				}

				@include break-min($large1) {
					text-align: left;
				}

				@include break-min($huge1) {
					@include font-size(4.8);
				}
			}

			.carousel-item {
				@include break-min($medium4) {
					display: flex !important;
					align-items: center;
					height: 100%;
				}

				&-content {
					text-align: center;
					margin: 10px auto;
					max-width: 330px;

					@include break-min($medium2) {
						max-width: 450px;
					}

					@include break-min($large1) {
						max-width: none;
					}
				}
			}
		}

		&-content {
			text-align: center;
			border-radius: 16px;
			padding: 30px 0;
			background-color: $cool-white;
			z-index: 1;
			position: relative;
			margin-left: 32px;
			margin-right: 32px;
			margin-top: -40px;

			@include break-min($medium2) {
				width: calc(100% - 160px);
				border-radius: 32px;
				padding: 40px 20px;
				margin-left: auto;
				margin-right: auto;
			}

			@include break-min($large1) {
				width: calc(100% - 130px);
			}

			@include break-min($large3) {
				width: calc(100% - 182px);
			}

			.carousel {
				padding: 0 50px;

				@include break-min($medium4) {
					padding: 0 50px;
				}

				.carousel-item-content {
					display: flex;
					flex-flow: column;

					h5 {
						color: $blue;
						font-family: $primary;
						font-weight: 700;
						@include font-size(2);
						line-height: lh(22, 18);
						margin-bottom: 20px;
						letter-spacing: -0.1px;
						font-weight: 700;
						margin-top: 0;

						@include break-min($medium3) {
							margin-bottom: 20px;
							@include font-size(3);
						}

						@include break-min($huge1) {
							@include font-size(3.6);
						}
					}

					.btn {
						width: max-content;
						margin: 25px auto 3px;
						@include btn-round;
						@include btn-primary-red;

						@include break-min($medium4) {
							width: auto;
						}
					}
				}
			}
		}

		&-content-above {
			height: auto;

			@include break-min($large1) {
				height: 526px;
			}

			.carousel-above-wrapper {
				height: 100%;

				img {
					border-radius: 0;
					object-fit: cover;

					@include break-min($medium2) {
						border-radius: 32px;
					}

					@include break-min($large1) {
						height: 526px;
					}
				}
			}
		}
	}

	.slick-slide {
		> div {
			vertical-align: middle;
			display: flex;
			align-items: center;
		}
	}
}
