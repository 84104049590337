.social-callout {
	background: $red;
	padding: 64px 24px;
	text-align: center;

	@include break-min($large1) {
		@include desktop-grid();
		align-items: center;
		text-align: left;
	}

	.content-wrapper {
		@include break-min($large1) {
			grid-column: col-start 2 / span 5;
		}

		p {
			@include font-size(1.8);
			color: $white;
			line-height: lh(28, 18);
			letter-spacing: -0.5px;
			margin: 0 16px 0 0;
		}
	}

	.title {
		> *:first-child {
			@include font-size(2.8);
			color: $white;
			font-weight: $bold-weight;
			line-height: lh(34, 28);
			letter-spacing: -0.01em;
			margin: 0 0 32px 0;

			@include break-min($large1) {
				@include font-size(4.8);
				font-weight: $bold-weight;
				line-height: lh(58, 48);
				letter-spacing: -1px;
				margin: 0;
			}
		}
	}

	ul {
		display: flex;
		list-style-type: none;
		justify-content: center;
		padding: 0;
		margin: 0;

		@include break-min($large1) {
			grid-column: col-start 10 / span 3;
		}

		li {
			margin: 0 8px 0 0;
		}
	}
}
