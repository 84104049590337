.youtube-video-panel {
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;

	iframe {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.play {
		cursor: pointer;
		position: absolute;
	}

	@include animated;

	&.animated {
		.play {
			left: 2%;
		}
	}
}
