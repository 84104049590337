// These typeStyles follow the designs from the homepage Refresh v2
@mixin type-h1-refresh() {
	@include font-size(2.8);
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -1px;
	line-height: lh(34, 28);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@include break-min($large1) {
		@include font-size(4.8);
		line-height: lh(58, 48);
	}
}

@mixin type-h2-refresh() {
	@include font-size(2.4);
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -1px;
	line-height: lh(32, 24);
	margin: 0;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@include break-min($medium1) {
		@include font-size(3);
		letter-spacing: -0.5px;
		line-height: lh(42, 30);
	}

	@include break-min($large1) {
		@include font-size(3.6);
		letter-spacing: -0.5px;
		// line-height: lh(42px, 36px);
		line-height: 42px;
	}
}

@mixin type-h3-refresh() {
	@include font-size(2);
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -1px;
	line-height: lh(28, 20);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@include break-min($medium1) {
		@include font-size(2.4);
		letter-spacing: -0.5px;
		line-height: lh(34, 24);
	}
}

@mixin type-h4-refresh() {
	@include font-size(1.8);
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -1px;
	line-height: lh(22px, 18px);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

@mixin type-p1-refresh() {
	@include font-size(1.8);
	font-family: $primary;
	font-weight: $regular-weight;
	letter-spacing: -0.5px;
	line-height: lh(28, 18);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

@mixin type-p2-refresh() {
	@include font-size(1.6);
	font-family: $primary;
	font-weight: $regular-weight;
	letter-spacing: -0.5px;
	line-height: lh(26, 16);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

@mixin type-p3-refresh() {
	@include font-size(1.4);
	font-family: $primary;
	font-weight: $regular-weight;
	letter-spacing: -0.5px;
	line-height: lh(24, 14);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

@mixin type-p4-refresh() {
	@include font-size(1.2);
	font-family: $primary;
	font-weight: $regular-weight;
	letter-spacing: -0.5px;
	line-height: lh(22, 12);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

@mixin type-e1-refresh() {
	@include font-size(1.4);
	font-family: $primary;
	font-weight: $bold-weight;
	letter-spacing: -0.5px;
	line-height: lh(14, 14);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@include break-min($small4) {
		@include font-size(1.8);
	}
}
