.codemoo-classic-container {
	.classic-callout {
		grid-auto-rows: 0fr;

		@include break-min($medium4) {
			grid-auto-rows: 1fr;
			margin: inherit;
		}

		.message-container {
			background: transparent;
			padding: 32px 0;

			@include break-min($large1) {
				padding: 32px;
			}

			p {
				@include type-p1(desktopOnly);
				font-weight: 400;
			}
		}

		.img-container {
			height: 300px;

			@include break-min($large1) {
				height: 100%;
			}

			img {
				border-radius: 32px;
			}
		}
	}
}
