.header-img-callout {
	&.rotate-img {
		.img-wrapper {
			filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
			margin: -45px 0 32px;
			transform: rotate(-7deg);

			@include break-min($large1) {
				margin: 0 84px 0 90px;
				max-width: 550px;
				top: -20px;
				transform: rotate(2deg);
			}

			.mobile-img {
				margin-top: -40px;
			}

			.desktop-img {
				margin-top: -70px;
			}
		}

		.content {
			@include break-max($large1) {
				top: -20px;
			}
		}
	}
}
