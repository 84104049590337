.delivery-subscription-learn-more {
	.promo-cta {
		height: 498px;
		min-height: 498px;

		@include break-min($large1) {
			height: 446px;
			min-height: 446px;
		}

		.promo-content .title {
			line-height: 46px;

			@include break-min($large1) {
				line-height: 74px;
			}
		}
	}

	// sass-lint:disable no-ids
	// sass-lint:disable id-name-format
	#join_chick-fil-a_one {
		// sass-lint:enable no-ids
		// sass-lint:enable id-name-format
		@include break-max($large1) {
			padding: 0 24px;
		}

		.title {
			.btn-round--red {
				font-size: 1.4rem;

				@include break-min($small2) {
					font-size: 1.8rem;
				}
			}
		}
	}

	.facts {
		padding-bottom: 0;

		.stats {
			.fact .highlight {
				color: $blue;
			}
		}
	}

	// sass-lint:disable no-ids
	// sass-lint:disable id-name-format
	#disclaimer_w_css {
		// sass-lint:enable no-ids
		// sass-lint:enable id-name-format
		.title {
			margin: 5px auto 50px;

			@include break-min($large1) {
				margin-bottom: 60px;
			}
		}
	}

	// sass-lint:disable no-ids
	// sass-lint:disable id-name-format
	#sign-up_for_free_trial {
		// sass-lint:enable no-ids
		// sass-lint:enable id-name-format
		padding: 64px 24px 56px;

		@include break-min($large1) {
			padding: 103px 30px 100px;
		}

		.title {
			margin-bottom: 0;

			.sign-up-trial-heading {
				max-width: 100%;
			}

			.btn-round {
				font-size: 1.4rem;

				@include break-min($small2) {
					font-size: 1.8rem;
				}
			}

			.sign-up-trial-disclaimer {
				padding: 30px 0 0;

				@include break-min($large1) {
					padding-top: 25px;
					margin: auto;
					max-width: 749px;
				}
			}
		}
	}

	.accordion {
		padding-bottom: 90px;
		padding-top: 61px;

		@include break-min($large1) {
			padding-bottom: 72px;
			padding-top: 95px;
		}

		article {
			> *:first-child {
				@include font-size(3.6);
				color: $blue;
				line-height: 42px;
				margin-bottom: 41px;

				@include break-min($large1) {
					margin-bottom: 34px;
				}
			}

			.accordion-body {
				.toggle {
					@include font-size(2);
					color: $grey;
					letter-spacing: -1%;
					line-height: 30px;

					@include break-min($large1) {
						@include font-size(2.6);
						letter-spacing: -0.5px;
						line-height: 34px;
						padding: 32px 75px 32px 0;
					}
				}
			}
		}
	}

	.footer footer .header-img-callout {
		display: none;
	}
}
