.breadcrumbs-nav {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	padding: 25px 24px 20px;
	top: 0;
	transition: top 300ms ease-in;

	@media screen and (min-width: $mobile) {
		display: flex;
		justify-content: space-between;
		padding: 12px 40px 8px;
	}

	&.mobileNotSticky {
		.btn-container {
			display: none;
		}
	}

	&.mobileSticky {
		background: $white;
		padding: 14px 24px;
		position: fixed;
		top: 64px;
		width: 100%;
		z-index: 1000;

		.page-nav,
		.toggle-options {
			display: none;
			opacity: 0;
		}

		.btn-container {
			display: block;
		}
	}

	&.desktopSticky {
		background: $white;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
		position: fixed;
		top: 64px;
		width: 100%;
		z-index: 1000;

		.page-nav,
		.toggle-options {
			transform: translateY(5px);
			opacity: 0;
		}
	}

	&.no-lang-nav {
		display: flex;
		justify-content: space-between;
	}

	&.lang-nav {
		display: flex;
		justify-content: space-between;
		margin: 0;
	}

	ul {
		@include p2();
		list-style-type: none;
		padding: 0;
	}

	.btn-container {
		width: 100%;

		@include break-min($large1) {
			display: flex;
			margin: auto;
			width: auto;
		}

		h4 {
			@include type-h4(mobileOnly);
			color: $blue;
			display: none;

			@include break-min($large1) {
				align-self: center;
				display: block;
				margin: 0 16px 0 0;
			}
		}

		.btn-round {
			width: 100%;

			@include break-min($large1) {
				align-self: center;
				width: auto;
			}
		}
	}

	.page-nav,
	.toggle-options {
		opacity: 1;
		transition: transform 100ms ease-in-out;
		transform: translateY(0);
	}

	.page-nav {
		align-items: center;
		margin: 0;

		@include break-min($large1) {
			margin: auto 0;
		}

		li:not(:last-child):after {
			content: ">";
			padding: 0 10px;
		}

		a {
			@include link-underline;
			font-weight: 900;
		}
	}

	.toggle-options {
		display: flex;
		justify-content: space-between;
		z-index: 1000;

		@media screen and (min-width: $mobile) {
			justify-content: center;
		}

		@media screen and (max-width: $small3) {
			flex-direction: column;
		}
	}

	.language-nav {
		align-self: center;
		margin: 0;

		li {
			padding-right: 10px;
			position: relative;
		}

		a {
			@include link-underline();
		}

		.flag-wrapper {
			border-radius: 100%;
		}
	}

	.lang-accord-toggle {
		display: flex;
		margin-right: 10px;
		position: relative;

		&:after {
			content: "";
			background-image: url("img/cp-icons/icon-cp_arrow-down.svg");
			background-size: cover;
			bottom: 0;
			height: 9px;
			width: 9px;
			margin: auto;
			pointer-events: none;
			position: absolute;
			right: -12px;
			top: 0;
			transition: transform 0.2s ease;
		}

		&.expanded {
			&:after {
				transform: rotate3d(1, 0, 0, 180deg);
			}
		}

		img {
			height: 25px;
			width: 25px;
		}
	}

	.accord-toggle-content {
		background: $white;
		border-radius: 2px;
		box-shadow: 0 0 17px grey;
		display: none;
		padding: 3px 3px;
		position: absolute;
		right: 5px;
		top: 13px;
		z-index: 1000;

		li {
			display: flex;
		}

		img {
			height: 25px;
			width: 25px;
		}
	}

	.social-icons {
		display: flex;

		li {
			margin: 5px 5px 0 0;

			@media screen and (min-width: $mobile) {
				margin-right: 10px;
			}
		}
	}
}
