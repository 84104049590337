.plp-menu-item-cards {
	margin: 0 auto;
	max-width: 1210px;
	width: 100%;
	grid-template-columns: repeat(2, 1fr);
	gap: 24px;
	display: grid;

	.card {
		padding: 48px;
		background-color: #f7f7f7;
		border-radius: 35px;

		img {
			display: block;
			margin: 0 auto;
		}

		h2 {
			@include font-size(3.6);
			font-style: normal;
			font-weight: 700;
			letter-spacing: -0.5px;
			color: $red;
			margin-bottom: 15px;
		}

		p {
			@include font-size(1.8);
			line-height: 150%;
			margin-bottom: 24px;
		}

		a {
			@include font-size(1.4);
			margin-right: 16px;

			&.btn-secondary {
				border: 1px solid $red;
				background: $white !important;
				color: $red;
			}
		}
	}

	@include break-max($medium1) {
		padding: 0 24px;
		grid-template-columns: repeat(1, 1fr);

		.card {
			padding: 24px;

			a {
				margin: 0 0 16px;
			}
		}
	}
}
