.video-bg-hero {
	padding: 0 0 55px 0;
	position: relative;

	@include break-min($large1) {
		padding: 0 0 92px 0;
	}

	&.video-object-fit-contain {
		.video-container {
			video-js {
				object-fit: contain;
			}
		}
	}

	.video-controls-wrapper {
		@include play-pause-btn-states();
		position: relative;

		.play-btn,
		.pause-btn {
			border: none;
			border-radius: 48px;
			position: absolute;
			bottom: 23px;
			height: 27px;
			margin: auto;
			right: 23px;
			z-index: 100;
			width: 40px;

			@include break-min($large1) {
				right: 79px;
			}

			img {
				@include absolute-center();
				margin: auto;
			}
		}
	}

	.video-container {
		height: 370px;

		@include break-min($large1) {
			height: 624px;
		}

		.player-wrapper {
			height: 100%;
			width: 100%;
		}

		video-js {
			height: 100%;
			width: 100%;

			video {
				object-fit: cover;

				@include break-min($huge2) {
					object-fit: contain;
				}
			}
		}
	}

	.content {
		background: $white;
		margin: -60px 0 0;
		text-align: center;

		.icon-container {
			background: $white;
			border: 1px solid $white;
			border-radius: 100px;
			height: 156px;
			margin: 0 auto;
			position: relative;
			width: 156px;

			img {
				@include absolute-center();
				margin: auto;
			}
		}

		h1 {
			@include font-size(4.8);
			color: $blue;
			font-weight: $bold-weight;
			line-height: lh(54, 48);
			margin: 0 auto 0;
			max-width: 755px;

			@include break-min($large1) {
				@include font-size(7.8);
				line-height: lh(84, 78);
			}
		}
	}

	[data-element="video-cta"] {
		position: absolute;
		bottom: 10px;
		right: 20px;
	}
}
