.team-member-callout {
	height: auto;
	padding: 64px 0 52px;

	@include break-min($large1) {
		padding: 80px 96px 113px;
	}

	.flex-wrapper {
		padding: 0;

		@include break-min($large1) {
			align-items: flex-start;
		}
	}

	.video-container {
		margin: 0 0 40px 0;

		@include break-min($large1) {
			max-width: 700px;
		}
	}

	.content {
		padding: 0 24px;

		@include break-min($large1) {
			margin: 0 0 0 96px;
			max-width: 450px;
			padding: 0;
		}

		h4 {
			@include type-h2-refresh();
			margin: 0 0 32px 0;
		}

		p {
			@include type-p1-refresh();
			margin: 0 0 24px 0;
		}
	}
}
