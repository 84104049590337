// Slider

.slick-slider {
	// sass-lint:disable-block no-misspelled-properties
	box-sizing: border-box;
	display: block;
	position: relative;
	touch-action: pan-y;
	user-select: none;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: flex;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		// sass-lint:disable-block no-duplicate-properties
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: flex;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;

	> div {
		height: 100%;
	}

	[dir="rtl"] & {
		float: right;
	}

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;

		@include break-max($medium2) {
			height: 0;

			&.slick-current {
				height: 100%;
			}
		}
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}
