.delivery-btn-border-shadow {
	list-style-type: none;
	padding: 0;

	@include break-min($large1) {
		display: flex;
		flex-wrap: wrap;
		grid-column: 2;
		grid-row: 1;
		margin: 0;
	}

	li {
		background: $white;
		border-radius: 48px;
		height: 58px;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
		margin: 0 auto 24px;
		min-width: 180px;
		transition: all 200ms ease-in-out;

		@include break-min($large1) {
			margin: 0 8px 0 0;
			width: auto;
		}

		&:hover {
			box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
			transform: translateY(-1px);
		}
	}

	a {
		display: flex;
		height: 100%;
		justify-content: center;
		position: relative;

		img {
			bottom: 0;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}
