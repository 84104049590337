.grandOpeningLightbox {
	height: auto;
	font-weight: normal;

	&.lightbox-clean {
		p {
			max-width: none;
		}
	}

	.cornflower {
		font-weight: bold;
	}

	&.lightbox {
		p {
			max-width: none;
		}
	}

	.location-frame {
		max-width: none;
		//padding: 0 !important;
	}

	.above-fold {
		//padding-left: 10px;
		text-align: left;
	}

	.location-details {
		margin: auto;
		max-width: 500px;
		padding: 0;

		@media screen and (min-width: $mobile) {
			margin-left: 75px;
		}
	}

	.location-modules {
		display: block;
		padding: 0;
		text-align: left;

		.module {
			display: block;
		}

		.social-icons {
			margin-top: 5px;

			li {
				margin: 5px 7px 0 0;
			}
		}
	}
}
