@import "../../../common/accordion/variations/covid-accordion";

.covid-faq {
	.accordion {
		// Top Questions Module Only
		&:not(.covid-questions-filter) {
			@extend .covid-accordion;
			margin: 20px 0 33px;

			@include break-min($large1) {
				margin: 100px 0 165px;
			}
		}
	}

	.block_img_header_txt {
		@extend .covid-block_img_header_txt;
		padding-top: 75px;

		@include break-min($large1) {
			padding: 107px 0;
		}

		.img-wrapper {
			margin: auto;
			width: 90%;

			@include break-min($large1) {
				margin: auto 0;
			}
		}
	}

	.callout-view {
		padding: 104px 24px 84px;

		@include break-min($large1) {
			padding: 134px 24px 200px 100px;
		}

		@include break-min($huge1) {
			padding-left: 260px;
		}
	}
}
