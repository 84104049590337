.sig-drop-headline-base-margin {
	img {
		margin-bottom: 32px;

		@include break-min($large1) {
			margin-bottom: 24px;
		}
	}

	h1 {
		margin: 0 0 16px;

		@include break-min($large1) {
			margin: 0 auto 24px;
		}
	}

	p {
		margin-bottom: 32px;

		@include break-min($large1) {
			margin-bottom: 24px;
		}
	}
}
