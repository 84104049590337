.membership-carousel {
	padding: 128px 0;

	.wrapper {
		padding: 0;

		@include break-min($large1) {
			padding: 0 15px;
		}
	}

	&-headline {
		text-align: center;
		margin-bottom: 25px;
		padding: 0 15px;

		h2 {
			@include font-size(2.4);
			color: $blue;
			font-family: $primary;
			font-weight: 700;
			letter-spacing: -0.5px;
			margin: 2rem 0;
			line-height: 32px;

			@include break-min($medium3) {
				line-height: 28px;
			}
		}

		P {
			line-height: 28px;
		}
	}

	.slider {
		max-width: 1380px;
		margin: 0 auto;
		width: 100%;
	}

	.card-carousel {
		.carousel-item {
			padding: 0;
			height: 100%;
		}
	}

	.slick-dots {
		top: 100%;
		bottom: 0;

		li {
			button {
				background-color: $rolling-stone;
				border: none;
				border-radius: 100%;
				display: block;
				height: 0.8rem;
				padding: 0;
				text-indent: -9999px;
				width: 0.8rem;
				margin: 0 auto;

				&:focus {
					outline: 1px dotted blue;
				}

				&:before {
					color: $rolling-stone;
				}
			}

			&.slick-active {
				button {
					background: $grey;
					width: 1.2rem;
					height: 1.2rem;

					&:before {
						color: $grey;
					}
				}
			}
		}
	}

	.membership-carousel__btn-wrap {
		text-align: center;
		padding: 35px 0 0;

		a {
			margin: 0 auto;
			width: 100%;

			@include break-min($medium3) {
				width: auto;
				display: inline-block;
			}
		}
	}
}
