.long-form-video {
	// @include play-pause-btn-states();
	@extend .bc-video-container;
	grid-auto-rows: 1fr;
	margin: 0 0 60px 0;
	overflow: hidden;
	position: relative;

	@include break-min($large4) {
		grid-auto-rows: auto;
	}

	&.hide-controls {
		.video-container {
			@include remove-bc-controls();
		}

		.overlay {
			display: block;
		}
	}

	&.modal-view {
		position: relative;

		.img-container {
			grid-column: col-start 1 / span 4;

			@include break-min($medium4) {
				grid-column: col-start 1 / span 8;
			}

			@include break-min($large4) {
				grid-column: col-start 1 / span 12;
			}
		}

		.vjs-big-play-button {
			display: none;
		}

		video-js {
			pointer-events: none;
		}

		.modal-cta {
			@include absolute-center();
			background: transparent;
			border: 0;
			height: 100%;
			width: 100%;
			z-index: 1000;

			.icon-container {
				@include absolute-center();
				width: 50px;
				height: 50px;
				background: $white;
				border-radius: 50%;
				margin: auto;
				overflow: hidden;
				z-index: 1000;
			}

			.play-icon {
				@include absolute-center();
				margin: auto;
				height: auto;
				z-index: 1;
			}

			button {
				background: transparent;
				border: none;
				height: 100%;
				padding: 0;
				width: 100%;
				position: relative;
			}
		}
	}

	// Quick fix - rmv this once the pause button is implemented.
	.overlay {
		display: none;
	}

	@include break-min($medium4) {
		display: flex;
	}

	@include break-min($huge4) {
		gap: 24px;
		justify-content: center;
	}

	&.overlay-view {
		@extend .long-form-overlay-view;

		.optional-icon {
			display: none;
		}
	}

	&.full-width-view {
		overflow: hidden;

		@include break-min($huge4) {
			grid-template-columns: repeat(12, [col-start] 1fr);
		}

		.video-container {
			grid-row: 1;
			overflow: hidden;

			@include break-min($medium4) {
				grid-column: col-start 1 / span 8;
			}

			@include break-min($large1) {
				grid-column: col-start 1 / span 12;
				margin: 0;
				height: auto;
			}

			.player-wrapper {
				position: unset;
			}

			.video-js {
				position: unset;
			}
		}
	}

	&.video-view {
		.content {
			display: none;
		}
	}

	&.story-hero {
		height: auto;
		margin: 0 24px;
		max-width: 1396px;
		border-radius: 32px;

		@include break-min($large1) {
			height: 664px;
			justify-content: center;
			margin: 0 auto;
		}

		&.overlay-view {
			@include grid-margin();

			@include break-min($huge4) {
				margin: auto;
			}

			@include break-min($max-width-margin-break) {
				margin-left: auto;
				margin-right: auto;
			}

			.video-container {
				height: 100%;
			}
		}
	}

	&.video-as-modal {
		height: 664px;
		position: relative;

		.btn-play-video {
			background: none;
			border: none;
			font-size: 0;
			height: 64px;
			left: 50%;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 64px;

			&::after {
				content: url("/img/cfacom/long-form-video/play-button.svg");
				display: inline-block;
				transition: transform 0.2s ease;
			}

			&:hover {
				&::after {
					transform: scale(1.3);
				}
			}
		}

		picture {
			width: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.content {
		background: $cool-white;
		grid-column: col-start 1 / span 4;
		grid-row: 1;
		text-align: center;
		position: relative;
		.optional-icon {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(50%, -50%);
			z-index: 1;
			max-width: 164px;
			max-height: 164px;
		}

		@include break-min($medium4) {
			grid-column: col-start 1 / span 3;
		}

		@include break-min($large1) {
			grid-column: col-start 1 / span 4;
		}

		.content-wrapper {
			margin: 0 auto;
			max-width: 240px;
			padding: 37px 0 27px;

			@include break-min($medium4) {
				padding: 176px 43px;
				max-width: none;
			}

			@include break-min($large1) {
				padding: 150px 60px;
			}

			@include break-min($huge1) {
				padding: 210px 112px;
			}

			.title {
				> *:first-child {
					@include type-h2-refresh();
					color: $blue;
					margin: 0 0 8px 0;

					@include break-min($medium4) {
						margin: 0 0 16px 0;
					}
				}

				p {
					@include type-p1-refresh();
					margin: 0 0 12px 0;

					@include break-min($medium4) {
						margin: 0 0 16px 0;
					}
				}
			}

			button {
				align-items: center;
				background: inherit;
				border: 0;
				display: flex;
				margin: auto;

				img {
					margin: 0 10px 0 0;
				}

				p {
					@include type-p1-refresh();
					align-items: center;
					color: $red;
					font-weight: $bold-weight;
					line-height: lh(14, 18);
					margin: 0;
				}

				@include break-max($medium2) {
					display: none;
				}
			}
		}
	}

	.img-container,
	.video-container {
		grid-column: col-start 1 / span 4;

		@include break-min($medium4) {
			grid-row: 1;
			grid-column: col-start 4 / span 5;
		}

		@include break-min($large1) {
			grid-column: col-start 5 / span 8;
			margin: 0;
			height: auto;
		}

		@include break-min($huge4) {
			margin-left: -24px;
		}

		iframe {
			border: 0;
			height: 100%;
			width: 100%;
		}
	}

	.img-container {
		position: relative;
		height: 100%;
		width: 100%;

		img {
			@include absolute-center();
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	.video-controls {
		left: 85px;
		top: 25px;

		@include break-min($huge4) {
			left: -40px;
		}
	}

	.play-btn,
	.pause-btn {
		border: none;
		border-radius: 48px;
		position: absolute;
		height: 27px;
		left: 35px;
		top: 35px;
		z-index: 100;
		width: 40px;

		@include break-min($huge4) {
			left: -100px;
		}

		img {
			@include absolute-center();
			margin: auto;
		}
	}

	&.remarkable-futures {
		max-width: 192rem;
		padding: 64px 24px 0;
		gap: 0;
		display: flex;
		flex-flow: column-reverse;

		.video-container {
			margin-left: 0;
			padding: 30%;

			@include break-min($large1) {
				padding: 0;
			}
		}

		@include break-min($large1) {
			display: grid;
			padding: 64px 46px 0;
			grid-template-columns: repeat(12, [col-start] 1fr);
		}

		@include break-min($large2) {
			padding: 80px 24px 0;
		}

		@include break-min($large4) {
			grid-template-columns: repeat(12, [col-start] 1fr);
		}

		@include break-min($huge2) {
			padding: 72px 68px 0;
		}
	}

	&.reverse-order {
		display: flex;
		flex-flow: column-reverse;

		@include break-min($large1) {
			display: grid;

			.content {
				grid-column: col-start 8 / span 12;
			}

			.img-container,
			.video-container {
				grid-column: col-start 1 / span 7;
			}
		}

		@include break-min($huge1) {
			.content {
				grid-column: col-start 9 / span 12;
			}

			.img-container,
			.video-container {
				grid-column: col-start 1 / span 8;
			}
		}
	}
}

// This class is here because it's related to the long-form-video, it's for one of the variations

.long-form-video-modal {
	position: fixed;
	width: 0;
	height: 1px;
	// background-color: rgba(0, 0, 0, 0.6);
	z-index: 999;
	display: flex;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: width 0.5s ease 0.5s, height 0.5s ease;
	align-items: center;
	justify-content: center;

	&.active {
		width: 100dvw;
		height: 100dvh;
	}

	.container {
		width: 100%;
		max-width: 1274px;
		background-color: $white;
		opacity: 0;
		transform: scale(0.5);
		transition: transform 0.5s ease 0.5s, opacity 0.5s linear;
		.player-wrapper {
			.overlay {
				display: none;
			}
		}

		&.active {
			opacity: 1;
			transform: scale(1);
		}
	}
}
