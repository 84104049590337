// sass-lint:disable no-ids
// sass-lint:disable max-file-line-count

.store-details {
	max-width: none;
	position: relative;

	@include break-min($large1) {
		padding-bottom: 50px;
	}

	@include break-min($large3) {
		margin: auto;
	}

	.location-details {
		padding-right: 0;

		&-wrapper {
			padding-right: 0;

			.location-direction {
				color: $red;
			}

			.wrapper-padding {
				padding: 0 0 40px 0;

				@include break-min($large1) {
					padding: 60px 50px;
				}

				@include break-min($large4) {
					padding: 60px 100px;
				}
			}

			.inner {
				p {
					@include font-size(1.8);
				}
			}
		}
	}

	.above-fold {
		margin-bottom: 24px;
		padding-bottom: 0;
		position: relative;

		@include break-min($large1) {
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	.location-type {
		@include font-size(1);
		font-family: $primary;
		letter-spacing: 2px;
		line-height: 20px;
		text-transform: uppercase;
	}

	.today-hours {
		color: $green;
		font-weight: 900;
		display: flex;
		align-items: center;

		.clock-icon {
			@include background-size-cover();
			background-image: url("img/icon_clock-green.svg");
			height: 20px;
			margin-right: 7px;
			width: 20px;
		}

		&.closed {
			color: $red;

			.clock-icon {
				background-image: url("img/icon_clock-red.svg");
			}
		}

		p {
			font-weight: $bold-weight;
			margin: 0;
		}

		@media screen and (max-width: $mobile) {
			justify-content: center;
		}

		.yellow-msg {
			color: $yellow-orange;
		}
	}
}
