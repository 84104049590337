.cad-2017 {
	.frame {
		& > div {
			&:nth-child(odd) {
				// sass-lint:disable variable-for-property
				background-color: #faf8f5;
				// sass-lint:enable variable-for-property
			}
		}
	}

	h2 {
		@include font-size(4.8);
		color: $red;
		letter-spacing: -1px;
		line-height: 130%;
		margin: 0;
		text-align: center;

		@media only screen and (max-width: $mobile) {
			margin: 0 0 30px 0;
		}
	}

	.show-more {
		padding-top: 0;

		@media only screen and (max-width: $mobile) {
			text-align: center;
		}
	}
}

.cad-hero {
	position: relative;

	img {
		width: 100%;
	}

	&:before {
		@include scrim($scrim6);
	}

	.content {
		@include font-size(2.4);
		color: $white;
		left: 0;
		margin: 0 auto;
		position: absolute;
		right: 0;
		text-align: center;
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
		top: 50px;
		width: $col10;
		z-index: 300;

		@media only screen and (max-width: $mobile) {
			width: auto;
		}
	}

	.cad-date {
		@include font-size(1.1);
		font-family: $primary;
		font-weight: 800;
		letter-spacing: 1.38px;
		line-height: 30px;
	}

	h1 {
		@include font-size(8);
		font-family: $primary;
		letter-spacing: -2.5px;
		line-height: 100px;
		margin: 0;

		@media only screen and (max-width: $mobile) {
			@include font-size(5.3);
			letter-spacing: -0.45px;
			line-height: 65px;
		}

		&:after {
			@include font-size(4);
			content: "...";
			display: block;
			margin-top: -40px;

			@media only screen and (max-width: $mobile) {
				margin-top: -10px;
			}
		}
	}

	.blurb {
		font-family: $primary;
		font-weight: 600;
		letter-spacing: -0.31px;
		line-height: 45px;
		margin: 0 auto;
		width: $col9;

		@media only screen and (max-width: $mobile) {
			@include font-size(2.4);
			letter-spacing: -0.31px;
			line-height: 45px;
			width: $col11;
		}
	}
}

.how-to-cow {
	padding: 50px 0;

	p:not(:first-child) {
		text-align: center;
		margin: 0 auto 30px auto;
	}

	.optional-text {
		width: 50%;
	}
}

.cow-guide {
	display: flex;
	font-family: $primary;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin: 0 auto;
	max-width: 1380px;
	padding: 40px 30px 50px;

	@media only screen and (max-width: $mobile) {
		flex-direction: column;
	}

	.instructions {
		@include font-size(1.6);
		line-height: 24px;
		margin-right: 75px;
		text-align: left;
		width: $col6;

		@media only screen and (max-width: $mobile) {
			order: 2;
			padding: 0 10px;
			margin-right: 0;
			width: auto;
		}

		p {
			color: $red;
		}
	}

	strong {
		margin: auto;
	}

	img {
		margin: 0 auto;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 30px;
		}
	}

	ol {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			align-items: center;
			counter-increment: step-counter;
			display: flex;
			margin: 40px 0;

			&:before {
				@include font-size(2.8);
				align-items: center;
				border: 4px solid $red;
				border-radius: 50%;
				color: $red;
				content: counter(step-counter);
				display: flex;
				font-weight: 600;
				height: 36px;
				letter-spacing: -0.4px;
				justify-content: center;
				margin-right: 20px;
				padding: 8px;
				text-align: center;
				width: 36px;
			}

			div {
				width: $col10;
			}
		}
	}
}

.cad-stories {
	padding: 50px 0;

	.story-img {
		display: block;
		height: 200px;

		@media only screen and (max-width: $mobile) {
			height: auto;
		}
	}

	.story-items {
		.youtube-video-overlay {
			display: none;
		}

		span {
			&.story-details {
				color: $grey;
			}
		}
	}
}

.cad-faq {
	padding: 50px 0;

	.flex {
		@extend .wrapper;

		@media only screen and (max-width: $mobile) {
			flex-direction: column;
		}
	}

	dl {
		margin: 50px $col-margin;
		width: $col4;

		@media only screen and (max-width: $mobile) {
			margin: 0 0 30px;
			padding: 0 10px;
			width: auto;
		}
	}

	dt {
		@include font-size(1.6);
		line-height: 28px;

		&:after {
			content: "...";
			display: block;
		}
	}

	dd {
		@include font-size(1.6);
		font-family: $primary;
		line-height: 24px;
		margin: 20px 0;
	}

	ul {
		&:first-child {
			padding-left: 0;
		}
	}
}

.cad-cta {
	background: $white;
	margin-top: 115px;
	opacity: 0;
	padding-bottom: 30px;
	position: relative;
	height: 100%;
	width: $col12;
	z-index: 10;

	@media only screen and (max-width: $mobile) {
		margin-top: 75px;

		.iphone & {
			padding-bottom: 80px;
			margin-top: 62px;
			padding-top: 10px;
		}
	}

	video {
		border: 0;
		height: 100%;
		margin: 0 auto;
		max-width: 100%;
		vertical-align: top;

		&.desktop-only {
			display: block;

			@media only screen and (max-width: $mobile) {
				display: none;
			}
		}

		&.mobile-only {
			display: none;

			@media only screen and (max-width: $mobile) {
				display: inline-block;
			}
		}
	}

	.content {
		@extend .wrapper;
		position: absolute;
		top: 150px;
		width: $col6;
		z-index: 5000;

		@media only screen and (max-width: $mobile) {
			text-align: center;
			width: auto;
		}

		.iphone & {
			top: 80px;
		}
	}

	h1 {
		@include thirdlevel();
		margin: 0 0 10px 0;

		@media only screen and (max-width: $mobile) {
			@include font-size(2.8);
			letter-spacing: 0;
			line-height: 150%;
		}
	}

	.icon-close {
		@include font-size(2);
		background: transparent;
		border: 0;
		color: $red;
		line-height: 1.5;
		position: absolute;
		right: 150px;
		top: 50px;

		.iphone & {
			top: 15px;
			right: 0;
		}

		&:hover {
			cursor: pointer;
		}
	}
}
