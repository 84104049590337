.covid-facts {
	background-color: $white;
	padding: 0 24px 34px;

	@include break-min($large1) {
		padding: 34px 0;
	}

	.stats {
		align-items: stretch;
		display: flex;
		flex-wrap: wrap;
		flex-grow: 3;
		flex-shrink: 0;
		flex-basis: 0;
		justify-content: center;
		list-style: none;
		padding: 0;
		margin: auto;
		max-width: 1000px;

		.fact {
			flex-shrink: 0;
			text-align: center;
			width: 100%;
			margin-bottom: 50px;

			@include break-min($large1) {
				width: 33.33339%;
			}

			span {
				&.highlight {
					line-height: 34px;
					color: $blue;
					font-weight: $bold-weight;
					margin: 15px 0;
					@include font-size(2.4);
				}

				&:not(.highlight) {
					line-height: 25px;
					@include font-size(1.6);
				}
			}

			a {
				@include link-underline();
				line-height: 24px;
				letter-spacing: -0.5px;
				font-weight: $bold-weight;
				color: $red;
				font-family: $primary;
				@include font-size(1.6);
			}
		}
	}
}
