.press-landing {
	main {
		background-color: $bkgrey;
		padding-top: 80px;
		overflow: hidden;

		@media only screen and (max-width: $mobile) {
			background-color: $white;

			& > .wrapper {
				padding: 0;
			}

			& > .flex {
				display: block;
			}
		}

		.wrapper {
			box-sizing: border-box;
			margin: 0 auto;
			max-width: 1185px;
			padding-bottom: 50px;
			width: $col12;
		}
	}

	article {
		margin-right: 2 * $col-margin;
		max-width: 720px;
		width: $col8;

		@media only screen and (max-width: $mobile) {
			margin-right: 0;
			padding: 0 13px;
			width: auto;
		}
	}

	aside {
		max-width: 290px;
		width: $col4;

		@media only screen and (max-width: $mobile) {
			background-color: $cyan;
			max-width: none;
			padding: 30px;
			width: auto;
		}

		h3 {
			@include font-size(2.8);
			line-height: 150%;
			margin: 0 0 15px 0;
		}
	}

	.module {
		margin-bottom: 75px;
	}

	.cs {
		margin: 75px 0 0 0;

		img {
			display: block;
			margin: 0 auto 30px auto;
			width: 65%;
		}

		@media only screen and (max-width: $mobile) {
			background-color: $white;
			margin: 20px -30px;
			padding: 20px;
			text-align: center;
		}

		.btn {
			margin-top: 26px;
		}
	}

	.featured {
		padding-top: 50px;

		h3 {
			@include font-size(4.1);

			a {
				@include link-underline();
			}
		}

		img {
			width: 100%;
		}
	}
}
