.store-coming-soon {
	align-items: baseline;
	margin: 0;
	@include flex-flow(column);

	@include break-min($large1) {
		margin-bottom: 10px;
		@include flex-flow(row);
	}

	&-badge {
		background-color: $blue;
		border-radius: 4px;
		padding: 10px;
		margin-right: 10px;
		margin-bottom: 10px;

		@include break-min($large1) {
			margin-bottom: 0;
		}

		h3 {
			color: $white;
			text-transform: uppercase;
			margin: 0;
			@include s3();
			@include font-size(1.3);
		}
	}
}
