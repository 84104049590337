.alert-tag {
	background-color: $lred;
	border-radius: 8px;
	color: $vis-grey;
	font-weight: $medium-weight;
	margin: 35px auto -30px;
	padding: 20px 20px 20px 95px;
	position: relative;
	width: 42%;

	p {
		@include p2();
		margin: auto;
	}

	&::before {
		display: block;
		position: absolute;
		background-image: url("img/cp-icons/icon-cp_warning.svg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		top: 0;
		left: 35px;
		bottom: 0;
		margin: auto;
		width: 36px;
		height: 36px;
		content: "";
	}

	@media only screen and (max-width: $mobile) {
		width: 90%;
	}

	@media only screen and (min-width: $lrg-desktop) {
		width: 35%;
	}
}
