.lll__select-options {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: 43px auto 0;
	padding: 0 24px;
	text-align: center;

	@include breakpoint(desktop) {
		margin: 40px auto 0;
		padding: 0;
	}

	&.credit-card {
		fieldset {
			display: none;
		}

		.option-select {
			margin: 0 0 24px;
		}

		.input {
			max-height: none;

			&:last-child {
				display: block;
			}

			&__wrapper {
				margin: 0;
			}

			input {
				margin: 0;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					appearance: none;
				}
			}
		}
	}

	input[type="text"] {
		margin: 0;
	}

	.datepicker-label {
		@include font-size(1.2);
		color: $grey;
		font-weight: normal;
		left: 19px;
		position: absolute;
		top: 11px;
		z-index: 100;
	}

	.input {
		display: inline-block;
		max-width: none;
		width: 100%;

		@include breakpoint(tablet) {
			max-width: none;
		}

		&:last-child {
			display: none;
		}
	}

	.option-select {
		display: inline-block;
		margin: 24px 0 0;
		max-width: none;
		width: 100%;

		@include breakpoint(tablet) {
			max-width: 336px;
			width: 100%;
		}
	}

	+ .btn {
		margin: 33px auto 0;

		@include breakpoint(tablet) {
			margin: 40px auto 0;
		}
	}
}
