.listicle-container {
	@include grid-margin();
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin: 65px 24px 58px;
	padding: 0;
	row-gap: 32px;

	@include break-min($large1) {
		justify-content: center;
		row-gap: 88px;
	}

	&.alternate-rows {
		li:nth-child(odd) {
			.text-module {
				@include break-min($large1) {
					order: 1;
				}
			}

			.img-module {
				@include break-min($large1) {
					order: 2;
				}
			}
		}
	}

	&.text-only {
		margin: 101px 24px 58px;
	}

	.listicle-module {
		grid-column: col-start 1 / span 4;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large4) {
			grid-column: col-start 1 / span 12;
		}
	}
}
