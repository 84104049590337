.national-menu-page,
.location-menu-page {
	.home-menu {
		&.fixed {
			margin: 0;
		}

		@include break-min($large1) {
			box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
			margin: 2px 0;
		}

		.subnav ul {
			li img {
				display: none;
			}
		}
	}

	.menu-banner-alert {
		margin: 0;
		text-align: center;
	}

	.menu-items {
		background: transparent;
		padding-bottom: 0;

		.item-title {
			color: $blue;
		}

		p span {
			&.divider:before {
				content: "|";
			}
		}
	}
}
