.location-menu-card {
	&:last-child {
		@include break-max($large1) {
			padding-bottom: 50px;
		}
	}

	button {
		background: transparent;
	}

	> a {
		&:hover {
			.item-title {
				color: $red;
			}
		}
	}

	.item-details {
		position: relative;
		text-align: center;

		> span {
			font-weight: $bold-weight;
		}

		span {
			&.food-group {
				@include item-details();
				font-family: $primary;
			}
		}

		p {
			@include font-size(1.6);
			color: $grey;
			font-family: $primary;
			line-height: lh(25, 16);

			span {
				font-family: $primary;
			}
		}
	}

	.ribbon {
		background: $blue;
		border-radius: 4px;
		overflow: hidden;
		padding: 3px 15px;
		position: absolute;
		left: 24px;
		top: 24px;

		p {
			color: $white;
			font-weight: $regular-weight;
			margin: 0;
			padding: 0;
		}
	}

	.item-title {
		color: $blue;
	}

	.favorite-item {
		@include background-size-cover();
		background-image: url("img/menu/favorited-menu-item-open.svg");
		border: 0;
		margin: 3px 0 auto 16px;
		position: absolute;
		width: 18px;
		height: 18px;
	}

	.btn {
		@extend .btn-primary;
		margin: 18px auto 0;
		width: 100%;

		@include break-min($large1) {
			width: 144px;
		}
	}

	&.menu-refresh {
		margin: 0 0 36px;
		text-align: left;
		padding: 0;

		.img-container {
			background: $medium-light-grey;
			border-radius: 32px;
			display: block;
			height: 320px;
			overflow: hidden;
			position: relative;
			margin: 0 0 16px;
			width: 100%;

			img {
				height: 100%;
			}
		}

		.desc-cta-wrapper {
			display: flex;
			padding: 0 12px;
			position: relative;

			a:first-of-type {
				margin: 0 130px 0 0;

				.item-title {
					@include font-size(2);
					color: $grey-p;
					font-weight: $bold-weight;
					line-height: lh(28, 20);
					letter-spacing: -0.01em;
				}

				p {
					@include type-p2-refresh();
					color: $grey-p;
					margin-bottom: 0;
				}
			}

			.btn-round {
				position: absolute;
				right: 12px;
				width: auto;
			}
		}
	}

	&.list-view {
		grid-column: auto/span 4;
		margin: 0;
		width: 100%;

		.img-container {
			border-radius: 35px;
			height: 324px;
			margin: 0 0 16px;
		}

		.desc-cta-wrapper {
			a:first-of-type {
				margin: 0 130px 0 0;

				.item-title {
					@include font-size(2.4);
					color: $grey-p;
					font-weight: $bold-weight;
					line-height: lh(34, 24);
					letter-spacing: -0.5px;
				}

				p {
					@include type-p2-refresh();
					color: $grey-p;
					padding: 0;
				}

				&:hover {
					.item-title {
						text-decoration: underline;
					}
				}
			}
		}

		.menu-items .item p {
			padding: 0;
		}
	}
}
