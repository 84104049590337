.img-slider-grid {
	margin: 0 auto;
	max-width: 1128px;
	padding: 64px 0 88px 24px;

	@include break-min($large1) {
		padding: 0 0 0 64px;
	}

	@include break-min($large2) {
		padding: 80px 0 88px;
	}

	.header {
		margin: 0 0 16px -24px;
		text-align: center;

		@include break-min($large2) {
			margin: 0 0 16px;
		}

		> *:first-child {
			@include font-size(2.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(34, 28);

			@include break-min($large2) {
				@include font-size(4.8);
				letter-spacing: -1px;
				line-height: lh(58, 48);
			}
		}
	}

	.sub-text {
		margin-bottom: 32px;
		margin-left: -24px;
		padding: 0 24px;
		text-align: center;

		@include break-min($large2) {
			margin: 0 auto;
			max-width: 800px;
		}

		> *:first-child {
			@include font-size(1.8);
			color: $grey;
			font-weight: $regular-weight;
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
		}
	}

	.container--grid {
		position: relative;
		width: 100%;
		margin-bottom: 80px;

		@include break-min($large1) {
			margin-top: 64px;
		}

		&[data-child-count="1"] {
			.container--slider-btn {
				bottom: -60px;
				left: -24px;
				position: absolute;
				z-index: 9;
				text-align: center;
				top: unset;

				button {
					&:first-of-type {
						margin-right: 8px;
					}

					&:last-of-type {
						margin-right: 0px;
					}

					@include break-min($large1) {
						display: none;
					}
				}
			}

			.container--grid-tile {
				@include break-min($large1) {
					height: 100%;
					gap: 8px;
					flex-wrap: wrap;
				}
			}
		}

		.category-title {
			display: block;
			margin-bottom: 24px;
			padding-bottom: 0;
			padding-top: 0;
			text-align: left;
			width: 100%;

			p {
				@include font-size(2);
				color: $blue;
				font-weight: $bold-weight;
				letter-spacing: -1%;
				line-height: lh(28, 20);

				@include break-min($large2) {
					@include font-size(2.4);
					letter-spacing: -0.5px;
					line-height: lh(34, 24);
					padding-left: 64px;
				}

				@include break-min($huge2) {
					padding-left: 0;
				}
			}
		}

		.container--slider-btn {
			position: absolute;
			right: 0;
			top: 0;

			@include break-min($large2) {
				display: none;
			}

			button {
				background-color: transparent;
				border: 2px solid $red;
				border-radius: 50%;
				height: 40px;
				padding: 0;
				position: relative;
				width: 40px;

				&:last-of-type {
					margin-right: 16px;
				}

				@include break-min($medium2) {
					&:last-of-type {
						margin-right: 48px;
					}
				}

				&[disabled] {
					border-color: $faint-grey;

					img {
						&.active {
							display: none;
						}

						&.inactive {
							display: block;
						}
					}
				}

				img {
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 18px;
					height: 18px;

					&.active {
						display: block;
					}

					&.inactive {
						display: none;
					}
				}
			}
		}

		.container--grid-tile {
			display: flex;
			flex-direction: row;

			@include break-min($large2) {
				column-gap: 8px;
				height: 300px;
			}

			@include break-min($huge2) {
				column-gap: 12px;
				height: 280px;
			}

			.slick-list {
				padding-left: 0 !important; // sass-lint:disable-line no-important

				.slick-track {
					@include break-min($medium2) {
						margin-left: -45vw;
					}

					.slick-slide {
						margin-right: 8px;

						@include break-min($medium2) {
							margin-right: 12px;
						}

						&:last-of-type {
							margin-right: 0;
						}
					}
				}
			}

			.container--tile {
				cursor: pointer;
				display: inline-block;
				height: 100%;
				position: relative;
				overflow: hidden;
				border-radius: 16px;

				@include break-min($large1) {
					height: auto;
				}

				@include break-min($large2) {
					width: calc((100%-16px) / 3);
				}

				@include break-min($huge2) {
					width: calc((100%-24px) / 3);
				}

				.thumbnail {
					height: 100%;
					position: relative;

					img {
						height: 100%;
						object-fit: cover;
						width: 100%;
					}

					p {
						@include font-size(1.6);
						background: linear-gradient(
							to top,
							rgb(0, 0, 0) 2.46%,
							rgba(0, 0, 0, 0.5) 31.04%,
							rgba(0, 0, 0, 0) 94.8%
						);
						bottom: 0;
						color: $white;
						cursor: pointer;
						font-weight: $bold-weight;
						height: auto;
						left: 0;
						letter-spacing: -0.5px;
						line-height: lh(22, 16);
						margin-bottom: 0;
						padding: 48px 0 16px 16px;
						position: absolute;
						width: 100%;
						z-index: 10;

						@include break-min($large1) {
							padding-bottom: 20px;
							padding-left: 24px;
						}
					}

					.thumbnail--arrow-right {
						bottom: 0;
						cursor: pointer;
						padding-bottom: 16px;
						padding-right: 27px;
						position: absolute;
						right: 0;
						z-index: 10;

						@include break-min($large1) {
							padding-bottom: 20px;
							padding-right: 30px;
						}

						img {
							width: 22px;
						}
					}
				}

				.thumbnail--overlay {
					background: rgba(255, 255, 255, 0.94);
					height: 100%;
					left: 0;
					opacity: 0;
					position: absolute;
					top: 0;
					transition: opacity 0.2s;
					width: 100%;
					z-index: 10;

					&.active {
						opacity: 1;
					}

					.thumbnail--overlay-container {
						display: flex;
						flex-direction: column;
						height: 100%;
						justify-content: space-between;
						padding: 19px 14px;
						position: relative;

						@include break-min($large1) {
							padding: 24px 22px;
						}
					}

					.thumbnail--text-block {
						.title-link {
							@include font-size(1.6);
							color: $red;
							font-weight: $bold-weight;
							letter-spacing: -0.5px;
							line-height: lh(22, 16);
						}

						address {
							@include font-size(1.4);
							color: $grey;
							font-style: normal;
							font-weight: $regular-weight;
							letter-spacing: -0.25px;
							line-height: lh(23, 14);
						}

						p {
							@include font-size(1.4);
							color: $blue;
							font-weight: $medium-weight;
							line-height: lh(20, 14);
							margin-bottom: 0;
						}

						.thumbnail--description {
							margin-top: 16px;

							p {
								@include font-size(1.4);
								color: $grey;
								font-weight: $regular-weight;
								letter-spacing: -0.25px;
								line-height: lh(23, 14);
							}
						}
					}

					.thumbnail--social-icons {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						margin: 0;
						padding-left: 0;

						li {
							list-style: none;
							margin-right: 8px;

							a {
								@include font-size(3.5);
								color: $red;
								font-weight: $regular-weight;
								height: 36px;
								line-height: lh(35, 35);
								width: 36px;

								span {
									clip: rect(0 0 0 0);
									height: 1px;
									margin: -1px;
									overflow: hidden;
									padding: 0;
									position: absolute;
									width: 1px;
								}
							}
						}
					}

					.thumbnail--close {
						bottom: 0;
						cursor: pointer;
						padding: 19px 23px;
						position: absolute;
						right: 0;

						@include break-min($large1) {
							padding: 24px 30px;
						}

						img {
							width: 15px;
						}
					}
				}
			}
		}
	}
}
