.location-service-hours {
	list-style-type: none;
	margin-bottom: 45px;
	padding: 0;

	&.locationv2 {
		li {
			border-bottom: 1px solid $light-grey;

			.toggle {
				align-items: center;
				margin: 18px 0;

				&:after {
					content: none;
				}

				.chevron-icon {
					position: absolute;
					margin: 0;
					right: 0;
				}

				p {
					@include font-size(1.6);
					line-height: lh(25, 16);
				}
			}

			.hours {
				li {
					border-bottom: none;
				}
			}
		}
	}

	li {
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		.toggle {
			align-items: flex-start;
			background: inherit;
			border: none;
			display: flex;
			margin: 24px 0;
			position: relative;
			width: 100%;

			&::after {
				@include dot-border-h($size: small);
				bottom: -15px;
			}

			&.active {
				.chevron-icon {
					transform: rotate(0deg);
				}
			}

			.chevron-icon {
				margin: 9px 22px 0 0;
				transform: rotate(180deg);
				transition: all 300ms ease-in;
			}

			.icon-wrapper {
				margin-right: 9px;
			}

			p {
				@include font-size(1.4);
				color: $rolling-stone;
				letter-spacing: -0.5px;
				line-height: lh(20, 14);
				margin: 0;
			}
		}
	}

	.hours {
		margin-bottom: 47px;

		ul {
			li {
				@include font-size(1.4);
				color: $grey;
				display: flex;
				justify-content: space-between;
				letter-spacing: -0.5px;
				line-height: lh(24, 14);

				p {
					margin-bottom: 5px;
				}

				&.active {
					p {
						font-weight: $bold-weight;
					}
				}
			}
		}
	}
}
