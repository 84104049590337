.join-email-footer-copy {
	margin: 0 auto 104px;
	max-width: 500px;

	@include break-min($large1) {
		margin: 0 auto 40px;
		max-width: 405px;
	}

	.title {
		> *:first-child {
			@include font-size(2);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -0.01em;
			line-height: lh(28, 20);
			margin: 0 0 20px 0;
		}
	}

	input[name="email"] {
		background: $concrete;
	}

	.form-wrapper {
		button[type="submit"] {
			width: 124px;
		}
	}
}
