.card-classic-container {
	@extend .grid-gap;
	justify-content: center;

	&.hide-controls {
		.classic-callout {
			.video-container {
				@include remove-bc-controls();
			}
			.overlay {
				display: block;
			}
		}
	}

	&[data-child-count="1"] {
		@include break-min($large4) {
			grid-column-gap: 24px;
			grid-column: col-start 1 / span 12;
		}

		@include break-min($huge4) {
			grid-template-columns: repeat(12, [col-start] 104px);
		}

		.classic-callout {
			@extend .callout-margins;

			@include break-min($large4) {
				// gap: 24px;
				grid-column: col-start 1 / span 12;
			}

			@include break-min($huge4) {
				gap: 24px;
				margin: 0 auto !important; // sass-lint:disable-line no-important
			}

			.img-container {
				@include break-min($huge4) {
					margin: 0 0 0 -24px;
				}
			}
		}
	}

	&[data-has-multiple="true"] {
		@extend .callout-margins;
		// sass-lint:disable no-misspelled-properties
		grid-column-gap: 16px;

		@include break-min($medium4) {
			// sass-lint:disable no-misspelled-properties
			grid-column-gap: 24px;
		}

		.card-classic {
			background-color: $cool-white;
			grid-column: col-start 1 / span 4;
			grid-row: auto;
			margin: 0;
			position: relative;

			@include break-min($medium4) {
				// gap: 24px;
				width: 100%;
			}

			@include break-min($huge4) {
				justify-content: unset;
			}

			.img-container {
				height: 300px;

				@include break-min($medium4) {
					grid-column: col-start 1 / span 8;
				}
			}

			.message-container {
				@include card-classic-container-heights();

				@include break-min($medium4) {
					grid-column: col-start 1 / span 8;
					grid-row: 2;
					padding: 46px;
				}

				@include break-min($huge4) {
					border-bottom-left-radius: 0px;
					border-top-left-radius: 0px;
				}

				.btn {
					@include break-min($medium4) {
						bottom: 25px;
						position: absolute;
					}
				}
			}

			.video-container,
			.img-container {
				@include break-min($huge4) {
					border-bottom-right-radius: 0px;
					border-top-right-radius: 0px;
				}
			}
		}
	}

	&[data-child-count="2"] {
		&.proportion30-70 {
			@extend .proportion30-70;

			> :nth-child(2) {
				@include break-min($huge4) {
					.message-container {
						grid-column: col-start 1 / span 4;
					}

					.img-container {
						grid-column: col-start 5 / span 3;
					}
				}
			}
		}

		&.proportion70-30 {
			@extend .proportion70-30;

			> :first-child {
				@include break-min($huge4) {
					.message-container {
						grid-column: col-start 1 / span 4;
					}

					.img-container {
						grid-column: col-start 5 / span 3;
					}
				}
			}
		}

		&.proportion50-50 {
			@extend .proportion50-50;

			> :first-child {
				@include break-min($huge4) {
					.message-container {
						grid-column: col-start 1 / span 4;
					}

					.img-container {
						grid-column: col-start 5 / span 3;
					}
				}
			}
		}

		.card-classic {
			width: 100%;

			@include break-min($medium4) {
				grid-column: auto / span 4;
			}

			@include break-min($large4) {
				grid-column: auto / span 6;
			}

			@include break-min($huge4) {
				grid-column-gap: 24px;
			}

			.img-container {
				@include break-min($medium4) {
					height: 100%;
				}

				@include break-min($large4) {
					grid-column: col-start 7 / span 6;
					height: 100%;
				}

				@include break-min($huge4) {
					grid-column: col-start 4 / span 3;
					// offset gap to keep items flush
					margin-left: -24px;
				}
			}

			.message-container {
				@include break-min($large4) {
					grid-column: col-start 1 / span 6;
					grid-row: 1;
					margin: 0;
					padding: 32px;
				}

				@include break-min($huge4) {
					grid-column: col-start 1 / span 3;
				}
			}
		}
	}

	&[data-child-count="3"] {
		&.proportion25-25-50,
		&.proportion50-25-25 {
			.card-classic {
				.img-container,
				.message-container {
					@include break-min($huge4) {
						grid-column: auto / span 3;
					}
				}

				.message-container {
					@include break-min($medium4) {
						padding: 32px;
					}
				}
			}
		}

		&.proportion25-25-50 {
			@extend .proportion25-25-50;

			> :last-child {
				@include proport50-25-25CardFinalElem();
			}
		}

		&.proportion33-33-33 {
			> div {
				@extend .proportion33-33-33;
			}
		}

		&.proportion50-25-25 {
			@extend .proportion50-25-25;

			> :first-child {
				@include proport50-25-25CardFinalElem();
			}
		}

		@include break-min($medium4) {
			display: flex;
		}

		@include break-min($large4) {
			display: grid;
		}

		.card-classic {
			grid-auto-rows: minmax(min-content, max-content);

			@include break-min($large4) {
				grid-column-gap: 24px;
				grid-column: auto/span 4;
			}

			@include break-min($huge4) {
				grid-column: auto/span 4;
			}

			.img-container,
			.message-container {
				@include break-min($large4) {
					grid-column: auto/span 12;
				}

				@include break-min($huge4) {
					grid-column: auto / span 4;
				}
			}

			.message-container {
				@include break-min($medium4) {
					border-bottom-left-radius: 32px;
					border-bottom-right-radius: 32px;
					padding: 32px;
				}

				@include break-min($large4) {
					padding: 46px;
				}
			}
		}
	}

	&[data-child-count="4"] {
		&.proportion25-25-50,
		&.proportion50-25-25 {
			.card-classic {
				.img-container,
				.message-container {
					@include break-min($huge4) {
						grid-column: auto / span 3;
					}
				}

				.message-container {
					@include break-min($medium4) {
						padding: 32px;
					}
				}
			}
		}

		&.proportion25-25-50 {
			@extend .proportion25-25-50;

			> :last-child {
				@include proport50-25-25CardFinalElem();
			}
		}

		&.proportion33-33-33 {
			> div {
				@extend .proportion33-33-33;
			}
		}

		&.proportion50-25-25 {
			@extend .proportion50-25-25;

			> :first-child {
				@include proport50-25-25CardFinalElem();
			}
		}

		@include break-min($medium4) {
			display: flex;
		}

		@include break-min($large4) {
			display: grid;
		}

		.card-classic {
			grid-auto-rows: minmax(min-content, max-content);

			@include break-min($large4) {
				grid-column-gap: 24px;
				grid-column: auto/span 3;
			}

			@include break-min($huge4) {
				grid-column: auto/span 3;
			}

			.img-container,
			.message-container {
				@include break-min($large4) {
					grid-column: auto/span 12;
				}

				@include break-min($huge4) {
					grid-column: auto / span 3;
				}
			}

			.message-container {
				@include break-min($medium4) {
					border-bottom-left-radius: 32px;
					border-bottom-right-radius: 32px;
					padding: 32px;
				}

				@include break-min($large4) {
					padding: 46px;
				}
			}
		}
	}
}
