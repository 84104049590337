.form-headline-text {
	margin: 0 auto;
	padding: 0 24px;
	position: relative;
	margin-top: 40px;

	@include break-min($large1) {
		padding: 0 16px;
		max-width: 920px;
		text-align: center;
		margin-top: 80px;
	}

	h1 {
		@include font-size(2.8);
		display: block;
		margin: 0 auto;
		padding: 0 0 10px;
		font-family: $primary;
		font-weight: $bold-weight;
		line-height: lh(34px, 28px);
		letter-spacing: -1px;
		color: $red;

		@include break-min($medium1) {
			@include flex(1 1 auto);
			@include font-size(4.8);
			letter-spacing: -1px;
			padding: 0;
		}
	}

	h5 {
		@include font-size(1.8);
		display: block;
		margin: 0 auto;
		padding: 0 0 10px;
		font-family: $primary;
		font-weight: $bold-weight;
		line-height: lh(22px, 28px);
		letter-spacing: -0.5px;
		color: $red;
		margin-bottom: 5px;

		@include break-min($medium1) {
			@include flex(1 1 auto);
			padding: 0;
			margin-bottom: 15px;
		}
	}

	p {
		margin: 40px 0;
	}
}
