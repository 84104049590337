// New VIS modals

// simple text and ctas

.cta-modal {
	text-align: center;
	padding: 20px;
	display: block;
	margin: auto;
	position: relative;

	&__text {
		padding: 0;
		display: block;
		margin: auto;
		position: relative;
		max-width: 500px;

		&--full {
			max-width: 100%;
		}

		h3 {
			@include h4();
			color: $vis-blue;
			padding: 0 0 20px;
			margin: 0 auto;
		}

		p {
			@include p1();
			margin: 0;
			padding: 0 0 20px;
			color: $vis-grey;

			a {
				font-weight: $medium-weight;
			}
		}
	}

	&__ctas {
		width: 100%;
		padding: 35px 0 0 0;
	}
}

// form overrides for lightboxes
.vis-lightbox .g-form {
	max-width: 400px;
	margin: 0 auto;

	&--flex {
		> form {
			@include flexbox;
			@include align-items(flex-start);
			@include justify-content(stretch);
			@include flex-wrap(wrap);
			@include flex(1 1 100%);
			width: 100%;
		}
	}

	&__group {
		margin: 10px 0 0;

		&:first-child {
			margin: 0;
		}

		&--error {
			label,
			p {
				color: $red;
			}
		}
	}

	p {
		@include p2();
		color: $light-grey;
		padding: 10px 0;
		margin: 0;
		font-weight: $medium-weight;
		max-width: 340px;
		display: block;

		&.error {
			color: $red;
		}
	}
}

// DFL modal

.dfl-modal {
	&__content {
		text-align: center;
		max-width: 700px;
		margin: 0 auto;
	}

	&__location {
		text-align: left;
		margin: 0;
		padding: 0 0 30px 0;
		position: relative;

		&::before,
		&::after {
			content: "";
			@include dot-border-h(top);
		}

		&::after {
			bottom: -3px;
			top: auto;
		}

		.map {
			height: 220px;
			padding: 0 0 25px;
			margin: 0 -18px;
		}

		iframe {
			border: 0;
			display: block;
			height: 0;
			margin: 0;
			max-height: 100%;
			max-width: 100%;
			min-height: 100%;
			min-width: 100%;
			padding: 0;
			width: 0;
		}

		h4 {
			@include h5();
			color: $red;
			margin: 0;
			padding: 0 0 10px;
			font-weight: $medium-weight;
		}

		p {
			padding: 0;
			margin: 0;
		}

		span {
			color: $green;
			display: block;
			margin: 0;
			padding: 0;
			font-weight: $medium-weight;
			@include p1();
		}

		a {
			font-weight: $medium-weight;
		}
	}

	&__ctas {
		padding: 15px 0 0;
	}

	.btn-round {
		margin-top: 20px;
	}

	@include break-min(560px) {
		&__location {
			padding: 30px 0 30px 200px;
			min-height: 224px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: stretch;
			flex-wrap: nowrap;
		}

		&__ctas {
			padding: 27px 0 0;
		}

		.map {
			width: 190px;
			height: 230px;
			position: absolute;
			top: 0;
			left: -5px;
			bottom: 0;
			margin: auto;
			padding: 0 0 30px;
		}
	}

	@include break-min($small4) {
		&__location {
			padding-left: 250px;
		}

		.map {
			width: 240px;
		}
	}
}

// Switch to pickup modal

.stp-modal {
	// reset to mobile styling
	// TODO make a modifier for this
	.menu-card {
		margin: 0;
		max-width: 260px;
		@include flex(0 0 100%);

		@include break-min($small5) {
			@include flex(0 0.4 220px);
		}

		.mc-text {
			min-height: 0px;

			h3 {
				@include font-size(1.8);
				line-height: lh(24px, 18px);
			}
		}

		.mc-inner {
			box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);
			padding: 54px 10px 10px 10px;
		}

		.mc-pad {
			padding: 8px;
		}

		.mc-meta span {
			white-space: initial;
			@include flex(0 1 auto);

			&:first-child {
				@include flex(1 1 auto);
			}
		}
	}

	&__card-wrap {
		padding: 25px 0 0;

		@include flexbox();
		@include justify-content(center);
		@include align-items(stretch);
		@include flex-wrap(wrap);
	}

	&__text {
		text-align: center;
		padding: 0;
		display: block;
		margin: auto;
		position: relative;
		max-width: 500px;

		h3 {
			line-height: lh(34px, 28px);
		}

		p {
			@include p1(small);
			margin: 0;
			padding: 0 0 15px;
			color: $vis-grey;

			a {
				font-weight: $medium-weight;
			}
		}
	}

	&__ctas {
		text-align: center;
		width: 100%;
		padding: 35px 0 0 0;
		margin: 0 auto;
	}

	&__location {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 60px 0 15px;
		margin: 0;
		min-height: 60px;

		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(center);
		@include flex-wrap(wrap);

		span {
			@include p1(small);
			@include flex(1 1 auto);
			padding: 0;
			margin: 0;
		}

		@include break-min($medium1) {
			padding: 0 60px 0 20px;
		}
	}
}
