// GLOBAL Tabs
// ==========================================================================

.g-tabs {
	padding: 0;

	@include break-min($large1) {
		padding: 0 24px;
	}

	&__nav {
		display: none;

		@include break-min($medium2) {
			margin: 0 -3px;
			@include flexbox();
			@include justify-content(stretch);
			@include align-items(center);
			@include flex-wrap(nowrap);
		}
	}

	&__toggle {
		position: relative;
		padding: 0;
		margin: 0;
		cursor: pointer;
		@include focus($red);
		@include flex(1 1 25%);

		@include break-min($large1) {
			padding: 0 3px;
		}

		&:focus {
			z-index: $z1;
		}

		&--mobile {
			display: block;

			@include break-min($large1) {
				display: none;
			}
		}

		&--desktop {
			display: none;

			@include break-min($large1) {
				display: block;
			}
		}

		span {
			@include type-h5(mobileOnly);
			font-weight: $medium-weight;
			text-align: left;
			margin: 0;
			padding: 33px 60px 33px 16px;
			background: $pampas;
			color: $vis-blue;
			display: block;
			position: relative;
			border-bottom: 6px solid $white;

			&::after {
				content: "";
				background-image: url("../img/icon-cp_arrow-down.svg");
				width: 22px;
				height: 22px;
				background-size: cover;
				position: absolute;
				top: 0;
				right: 30px;
				bottom: 0;
				margin: auto;
				pointer-events: none;
				transition: transform 0.2s ease;
				backface-visibility: hidden;
				will-change: transform;
			}

			@include break-min($large1) {
				border-bottom: 0;
				padding: 18px 10px;
				text-align: center;

				&::after {
					content: none;
				}
			}
		}

		&--active {
			span {
				border-color: $cream;
				background-color: $cream;

				&::after {
					transform: rotateZ(-180deg);
				}

				@include break-min($large1) {
					color: $red;
				}
			}
		}
	}

	&__content {
		display: none;
	}

	&__inner {
		background-color: $cream;
		padding: 10px 16px 16px;

		@include break-min($large1) {
			padding: 0;
			@include flexbox();
			@include justify-content(stretch);
			@include align-items(stretch);
		}
	}

	h4,
	&__h4 {
		@include type-h5();
		color: $vis-grey;
		margin: 0;
		padding: 30px 0 10px;

		&:first-child {
			padding: 0 0 10px;
		}
	}

	&__text {
		max-width: 630px;
		padding: 25px 0 30px;

		@include break-min($large1) {
			max-width: 730px;
			padding: 70px 40px;
			order: -1;
			@include flex(1 0 370px);
		}

		p {
			@include type-p2();
			color: $vis-grey;
			margin: 0;
			padding: 0;

			+ p {
				padding: 10px 0 0;
			}
		}

		.btn-round {
			margin: 30px 0 0;
		}
	}

	&__img {
		@include break-min($large1) {
			@include flex(1 1 66%);
		}

		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
}
