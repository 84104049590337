//AVOID USING - Legacy typeStyles,
//enough differences in fontSize exist,
//to where its best to keep these in the codebase until we can sunset/update
//the components that rely on them

@mixin h2($scale: all) {
	@include font-size(3.6);
	font-family: $primary;
	font-weight: $bold-weight;
	line-height: lh(48px, 36px);
	letter-spacing: -0.72px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $scale == all {
		@include break-min($medium1) {
			@include font-size(4.7);
			line-height: lh(56px, 47px);
			letter-spacing: -1px;
		}

		@include break-min($large1) {
			@include font-size(6.2);
			line-height: lh(68px, 62px);
			letter-spacing: -1.5px;
		}
	}
}

@mixin h3($scale: all) {
	@include font-size(2.8);
	font-family: $primary;
	font-weight: $bold-weight;
	line-height: lh(44px, 28px);
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $scale == all {
		@include break-min($medium1) {
			@include font-size(3.9);
			line-height: lh(52px, 39px);
			letter-spacing: -1px;
		}

		@include break-min($large1) {
			@include font-size(4.7);
			line-height: lh(60px, 47px);
		}
	}
}

@mixin h4() {
	@include font-size(2.5);
	font-family: $primary;
	font-weight: $bold-weight;
	line-height: lh(36px, 25px);
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@include break-min($medium1) {
		@include font-size(3.2);
		line-height: lh(48px, 32px);
		letter-spacing: -0.7px;
	}
}

@mixin h5() {
	@include font-size(2.3);
	font-family: $primary;
	font-weight: $medium-weight;
	line-height: lh(36px, 23px);
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}

@mixin p1($size: default) {
	font-family: $primary;
	font-weight: $regular-weight;
	letter-spacing: -0.5px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@if $size == default {
		@include font-size(1.8);
		line-height: lh(32px, 18px);
	}

	@if $size == small {
		@include font-size(1.4);
		line-height: lh(20px, 14px);

		@include break-min($medium1) {
			@include font-size(1.6);
			line-height: lh(24px, 16px);
		}
	}
}

@mixin p2() {
	@include font-size(1.2);
	font-family: $primary;
	font-weight: $medium-weight;
	line-height: lh(20px, 12px);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes
}
