.social-icons {
	list-style: none;
	margin: 0;
	padding: 0;

	@media only screen and (max-width: $mobile) {
		padding: 20px 0 20px;
		text-align: center;
	}

	li {
		display: inline-block;
		margin-left: 5px;

		@media only screen and (max-width: $mobile) {
			margin: 0 10px;
		}

		&:first-child {
			margin-left: 0;
		}

		&.second {
			display: none;
		}
	}

	span {
		@include visuallyhidden();
	}

	a {
		border: 0;

		&:hover {
			color: $dkred;
		}
	}

	[class^="icon-"],
	[class*=" icon-"] {
		@include font-size(3.5);
	}

	.icon-facebook-s1 {
		background: url("img/facebook-alt.svg") transparent top left no-repeat;
		background-size: 100%;
		display: block;
		transition: background 0.5s ease;
		width: 36px;
		height: 40px;

		&:hover {
			background: url("img/facebook-hover.svg") transparent top left no-repeat;
			background-size: 100%;
			transition: background 0.5s ease;
		}
	}

	.icon-twitter-s1 {
		background: url("img/twitter-alt.svg") transparent top left no-repeat;
		background-size: 100%;
		display: block;
		height: 36px;
		transition: background 0.5s ease;
		width: 36px;

		&:hover {
			background: url("img/twitter-hover.svg") transparent top left no-repeat;
			background-size: 100%;
			transition: background 0.5s ease;
		}
	}

	.icon-email-s2 {
		background: url("img/email.svg") transparent top left no-repeat;
		background-size: 100%;
		display: block;
		height: 40px;
		transition: background 0.5s ease;
		width: 36px;

		&:hover {
			background: url("img/email-hover.svg") transparent top left no-repeat;
			background-size: 100%;
			transition: background 0.5s ease;
		}
	}

	.icon-ellipses-s1 {
		background: url("img/ellipses.svg") transparent top left no-repeat;
		background-size: 100%;
		color: $red;
		display: block;
		height: 40px;
		transition: background 0.5s ease;
		width: 36px;

		&:hover {
			background: url("img/ellipses-hover.svg") transparent top left no-repeat;
			background-size: 100%;
			transition: background 0.5s ease;
		}
	}

	.icon-linkedin-s2 {
		background: url("img/linkedin.svg") transparent top left no-repeat;
		background-size: 100%;
		display: block;
		height: 36px;
		transition: background 0.5s ease;
		width: 36px;

		&:hover {
			background: url("img/linkedin-hover.svg") transparent top left no-repeat;
			background-size: 100%;
			transition: background 0.5s ease;
		}
	}

	.icon-pinterest-s2 {
		background: url("img/pinterest.svg") transparent top left no-repeat;
		background-size: 100%;
		display: block;
		height: 36px;
		transition: background 0.5s ease;
		width: 36px;

		&:hover {
			background: url("img/pinterest-hover.svg") transparent top left no-repeat;
			background-size: 100%;
			transition: background 0.5s ease;
		}
	}

	.icon-link-s2 {
		background: url("img/permalink.svg") transparent top left no-repeat;
		background-size: 100%;
		display: block;
		height: 36px;
		transition: background 0.5s ease;
		width: 36px;

		&:hover {
			background: url("img/permalink-hover.svg") transparent top left no-repeat;
			background-size: 100%;
			transition: background 0.5s ease;
		}
	}

	.icon-close-s2 {
		background: url("img/close.svg") transparent top left no-repeat;
		background-size: 100%;
		display: block;
		height: 40px;
		transition: background 0.5s ease;
		width: 36px;

		&:hover {
			background: url("img/close-hover.svg") transparent top left no-repeat;
			background-size: 100%;
			transition: background 0.5s ease;
		}
	}

	&.active {
		.second {
			animation: slideInRight 1s;
			display: inline-block;
		}

		.more {
			display: none;
		}

		.icon-facebook-s1 {
			background: url("img/facebook.svg") transparent top left no-repeat;

			&:hover {
				background: url("img/facebook-hover.svg") transparent top left no-repeat;
			}
		}

		.icon-twitter-s1 {
			background: url("img/twitter.svg") transparent top left no-repeat;

			&:hover {
				background: url("img/twitter-hover.svg") transparent top left no-repeat;
			}
		}
	}
}
