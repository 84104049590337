.restaurant-details {
	.bg-wrapper {
		background: $cool-white;
		border-top-left-radius: 40px;
		border-top-right-radius: 40px;
	}

	.p-wrapper {
		@include grid-padding();
		@include desktop-grid-max-width();
		padding: 48px 24px 105px;

		@include break-min($large1) {
			display: flex;
			padding-top: 102px;
			padding-bottom: 102px;
		}

		&[data-child-count="1"] {
			.hours {
				max-width: 600px;
				margin: auto;

				.title {
					text-align: center;
				}
			}
		}
	}

	.gridView {
		@include grid-padding();

		.rewards-callout,
		.restaurant-nav-callout {
			@include desktop-grid-max-width();
		}
	}

	.flex-wrapper {
		@include break-min($large1) {
			flex: 1;
		}

		&.hours {
			@include break-min($large1) {
				margin-right: 56px;
			}
		}
	}

	.title {
		> *:first-child {
			@include font-size(1.8);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: 0.5px;
			line-height: lh(22, 18);
			margin: 0 0 24px 0;
		}
	}
}
