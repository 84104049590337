.delivery-results-title {
	margin: 32px 0 40px;
	padding: 0 24px;
	text-align: center;

	@include break-min($large1) {
		margin: 32px 0 90px;
	}

	h1 {
		@include type-h1();
		@include font-size(3.6);
		color: $blue;
		line-height: 1.22;
		margin: 24px auto;
		width: $col10;

		@include break-min($large1) {
			@include font-size(4.8);
			margin: 0 auto 24px;
		}
	}

	p {
		@include type-p1();
		@include font-size(1.8);
		font-weight: normal;
		margin: 0 auto 15px;
		width: $col11;

		@include break-min($large1) {
			width: $col7;
		}

		@include break-min($large4) {
			width: $col5;
		}
	}
}
