.image-only-carousel {
	@include break-min($large1) {
		width: 800px;
		margin: 0 auto;
	}

	.content {
		display: none;
	}

	.img-container {
		flex: 1 0 50%;
		max-height: 442px;

		img {
			width: 100%;
		}
	}
}
