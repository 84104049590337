.location-rail {
	background-color: $cool-white;
	height: 100%;
	margin-left: -24px;
	margin-right: -24px;
	margin-bottom: 15px;
	padding-right: 25px;
	padding-left: 25px;
	padding-top: 0;
	padding-bottom: 42px;

	&:focus {
		font-weight: normal;
	}

	h2 {
		@include font-size(2.4);
		line-height: 32px;
		letter-spacing: -0.01px;
		margin: 0 0 42px;
		@include type-h3();
	}

	.location-rail-group {
		background-color: $white;
		margin-left: -25px;
		margin-right: -25px;
		padding-right: 25px;
		padding-left: 25px;
		margin-top: 0;
		padding-top: 30px;
		margin-bottom: 35px;
		@include flex-flow(column);

		&.pt-0 {
			@include break-max($large1) {
				padding-top: 0;
			}
		}

		&.-mt-10 {
			@include break-max($large1) {
				margin-top: -10px;
			}
		}
	}

	.location-rail-group-inverse {
		flex-direction: column-reverse;
		@include flexbox();

		@include break-min($large1) {
			flex-direction: column;
		}
	}

	.view-more-content {
		.view-more-wrapper {
			overflow: hidden;
			height: 0;

			@include break-min($large1) {
				height: auto;
			}
		}

		.view-more-btn {
			background-color: transparent;
			border: none;
			font-weight: $medium-weight;
			font-family: $primary;
			color: $red;
			align-items: center;
			margin: 0 auto;
			@include flexbox;
			@include font-size(1.8);

			&:after {
				content: "";
				background-color: $white;
				box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
				background-image: url("img/cp-icons/icon-cp_red-arrow-down.svg");
				background-repeat: no-repeat;
				background-position-x: 50%;
				background-position-y: 50%;
				border-radius: 50%;
				width: 40px;
				height: 40px;
				display: block;
				margin-left: 15px;
			}

			@include break-min($large1) {
				display: none;
			}
		}
	}

	@include break-min($large1) {
		padding: 48px 5% 70px;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 0;

		.location-rail-group {
			background-color: $cool-white;
			margin-left: 0;
			margin-right: 0;
			padding-right: 0;
			padding-left: 0;
			padding-top: 0;
			margin-top: 0;
			margin-bottom: 30px;
		}
	}

	@include break-min($large4) {
		padding-left: 15%;
		padding-right: 15%;

		h2 {
			@include font-size(2.4);
			line-height: 32px;
			letter-spacing: -0.5px;
		}
	}

	.location-image {
		margin-left: -25px;
		margin-right: -25px;
		background-color: $white;

		img {
			display: block;
		}

		@include break-min($large1) {
			display: none;
		}
	}

	.rail-module {
		width: 100%;
		margin-bottom: 42px;

		h3 {
			@include s3();
			margin-top: 0;
			margin-bottom: 10px;
			text-transform: uppercase;
			color: $blue;
			@include font-size(1.1);
		}
	}

	.g-carousel-grid__nav {
		display: none;
	}

	.slick-dots {
		margin-top: 30px;
		position: inherit;

		@media screen and (min-width: $mobile) {
			margin-top: 50px;
		}

		li {
			button {
				// sass-lint:disable variable-for-property
				background-color: grey;
				// sass-lint:enable variable-for-property
				border: none;
				border-radius: 50%;
				display: block;
				width: 10px;
				height: 10px;
				padding: 0;
				text-indent: -9999px;

				&:focus {
					outline: 1px dotted blue;
				}

				&:before {
					// sass-lint:disable variable-for-property
					color: grey;
					// sass-lint:enable variable-for-property
				}
			}

			&.slick-active {
				button {
					background: $grey;
					width: 13px;
					height: 13px;

					&:before {
						color: $grey;
					}
				}
			}
		}
	}
}
