.content-hub-callout {
	margin: 0 32px 16px !important;
	grid-template-columns: repeat(12, [col-start] 1fr);

	@include break-min($medium4) {
		margin: 0 46px 16px !important;
	}

	@include break-min($large3) {
		margin: 0 72px 28px !important;
	}

	&-wrapper {
		text-align: center;
		border-radius: 16px;
		padding: 42px 20px;
		background-color: $cool-white;
		position: relative;
		margin-bottom: 0;
		width: 100%;
		grid-column: col-start 1 / span 12;

		@include break-min($medium4) {
			border-radius: 32px;

			.links {
				display: flex;
				justify-content: center;
				gap: 0 15px;
			}
		}

		@include break-max($medium3) {
			.links a {
				margin-bottom: 15px;
			}
		}

		@include break-min($large3) {
			padding: 62px;
		}

		h2 {
			color: $blue;
			font-family: $primary;
			@include font-size(2);
			line-height: lh(22, 18);
			margin-bottom: 15px;
			letter-spacing: -0.1px;
			font-weight: $bold-weight;
			margin-top: 0;

			@include break-min($medium1) {
				display: block;
				margin-bottom: 30px;
				@include font-size(3);
			}

			@include break-min($huge1) {
				@include font-size(3.6);
			}
		}

		p {
			margin: 0 auto 30px auto;
		}

		.btn {
			margin: 0;
			@include btn-round;
			@include btn-primary-red;
		}

		a {
			margin: 0 8px;
			width: 100%;

			@include break-min($medium4) {
				min-width: 276px;
				width: auto;
			}
		}
	}

	&.theme-full {
		visibility: visible;

		.content-hub-callout-wrapper {
			h2 {
				margin-bottom: 15px;
			}

			p {
				max-width: 500px;
				margin: 0 auto 30px auto;
			}

			.btn-round {
				width: 100%;

				@include break-min($medium1) {
					width: auto;
				}
			}
		}
	}
}
