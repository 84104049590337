.title-text-callout {
	margin: 0 0 8px 0;
	word-break: break-word;

	@include break-min($large1) {
		margin: 0 0 16px 0;
	}

	> *:first-child {
		color: $blue;
		margin: 0;
	}

	h2 {
		@include type-h2-refresh();
	}

	h3 {
		@include type-h3-refresh();
	}

	h4 {
		@include type-h4-refresh();
	}
}
