.flexible-msg-callout {
	align-items: flex-start;
	background: $warm-white;
	border-radius: 8px;
	display: flex;
	margin: 0 24px;
	max-width: 1100px;
	padding: 16px 40px 16px 24px;

	@include break-min($large1) {
		align-items: center;
		margin: 0 auto;
		padding: 16px 65px 16px 40px;
	}

	.icon-container {
		height: 30px;
		margin-right: 10px;
		margin-top: 9px;
		position: relative;
		width: 100px;

		@include break-min($large1) {
			margin-top: unset;
		}

		img {
			@include absolute-center();
			height: 100%;
			width: 100%;
		}
	}

	.content {
		p {
			&:first-of-type {
				@include font-size(1.2);
				letter-spacing: -0.5px;
				line-height: lh(20, 12);
				margin-bottom: 8px;

				@include break-min($large1) {
					@include font-size(1.4);
					margin-bottom: 16px;
				}
			}
		}

		a {
			@include font-size(1.8);
			color: $blue;
			display: block;
			font-weight: $bold-weight;
			letter-spacing: -0.5px;
			line-height: lh(22, 18);
			margin-bottom: 0;
			position: relative;

			@include break-min($large1) {
				display: inline;
			}

			&:after {
				content: "";
				background-image: url("img/cp-icons/icon-cp_arrow-right-blue.svg");
				background-size: cover;
				bottom: 0;
				height: 15px;
				margin: auto;
				position: absolute;

				right: -20px;

				top: 0;
				width: 13px;
			}
		}
	}
}
