@media print {
	.story-article {
		.print-image {
			float: left;
			display: block;
			margin-right: $col-margin;
			width: $col6;
		}

		.print-footer {
			display: block;
			padding: 20px 0;
			text-align: center;

			.icon-logo-desktop {
				margin: 0 0 20px 0;

				&:before {
					@include font-size(6);
					color: $red;
				}
			}
		}

		header,
		.related,
		.co-social,
		.footer,
		.load-more,
		.tags,
		blockquote,
		.cs {
			display: none;
		}

		.frame {
			padding: 10px;
		}

		.story-top {
			position: static;

			.wrapper {
				overflow: hidden;
				position: relative;
			}

			&.locked {
				top: 0;

				.article-details {
					display: block;
				}
			}
		}

		.wrapper {
			max-width: none;
			padding: 0;
			width: auto;
		}

		.article-details {
			float: right;
			margin-top: 150px;
			width: $col5;

			.print,
			.social {
				@include visuallyhidden();
			}
		}

		.recipe-specifics {
			margin: 20px 0;
			padding: 0;
			position: absolute;
			right: 20px;

			li {
				display: block;
				margin: 0 0 20px 0;
				width: auto;
			}
		}

		.story {
			article {
				figure {
					display: none;
				}

				p,
				ul,
				ol {
					margin: 0 0 20px 0;
				}

				.content {
					margin: 0;
					max-width: none;
					padding-top: 0;
					width: auto;
				}
			}
		}
	}

	.olo.confirm-order {
		.frame {
			padding-top: 60px;
		}

		.header-frame {
			position: static;
		}

		.print-order-btn,
		.footer-modules,
		footer,
		button {
			display: none;
		}
	}
}
