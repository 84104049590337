.header-field {
	@include text-color-options();
	@include font-weight-options();
	@include add-margin();

	&.align-right {
		text-align: right;
	}

	&.align-center {
		text-align: center;
	}

	> *:first-child {
		@include type-b1();
		margin: 0;
	}

	&.mod-big-h1 {
		@include break-max($medium5) {
			h1 {
				@include font-size(3.2);
				padding: 0 40px;
			}
		}

	}
}
