.location-order-selection {
	margin-bottom: 20px;

	@include break-min($large1) {
		margin-bottom: 14px;
	}

	&.single {
		ul {
			li {
				@include break-min($large1) {
					a {
						align-items: center;
						padding: 30px 25px;
						@include flex-flow(row);

						.selection-info {
							align-items: center;
							margin-left: 20px;
							@include flexbox;

							span {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}

	.order-headline {
		@include font-size(2.8);
		padding: 0 0 10px;
		font-weight: $bold-weight;
		line-height: 34px;
		letter-spacing: -1px;
		color: $vis-blue;
		margin: 50px auto 30px;
		text-align: center;

		@include break-min($medium1) {
			@include flex(1 1 auto);
			@include font-size(4.8);
			letter-spacing: -0.01px;
			padding: 0;
			line-height: 58px;
		}
	}

	p {
		text-align: center;
		@include font-size(1.8);
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 15px 0 0 0;
		display: grid;
		grid-gap: 0;
		grid-auto-flow: column;
		// grid-template-columns: repeat(3,minmax(0,1fr));

		@include break-min($large1) {
			margin-top: 40px;
			grid-gap: 10px;
		}

		li {
			@include btn-hover-effect();
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			background-color: $white;
			border-radius: 16px;
			margin: 16px 5px 0;

			@include break-min($large1) {
				margin: 16px 0;
			}

			a {
				padding: 15px 20px;
				width: 100%;
				text-align: left;
				position: relative;
				align-items: center;
				@include flexbox();
				@include flex-flow(column);

				@include break-min($large1) {
					padding: 30px 5px;
				}

				img {
					width: 35px;

					@include break-min($huge2) {
						width: auto;
					}
				}

				.selection-info {
					margin-top: 10px;

					@include break-min($large1) {
						margin: 5px 0 0;
					}

					span {
						display: block;
						line-height: 17px;
						margin-top: 0;
						text-align: center;
						font-family: $primary;
						font-weight: $bold-weight;
						letter-spacing: -0.5px;
						@include font-size(1.4);

						@include break-min($large1) {
							line-height: 20px;
							margin-top: 12px;
							@include font-size(1.3);
						}

						@include break-min($huge2) {
							@include font-size(1.8);
						}
					}

					p {
						color: $grey;
						text-align: left;
						@include font-size(1.2);
						display: none;

						@include break-min($large1) {
							display: block;
						}
					}
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
