.team-member-image-callout {
	margin-bottom: 64px;
	padding-bottom: 60px;

	@include break-min($large1) {
		height: 576px;
		padding: 0 33px;
	}

	.flex-wrapper {
		@include break-min($large1) {
			@include swap-div-order();
			align-items: center;
			justify-content: space-around;
		}
	}

	.img-wrapper {
		margin-bottom: 18px;

		@include break-min($large1) {
			margin-bottom: 0;
		}
	}

	.content {
		padding: 0 24px;
		color: $white;
		color: $white;

		@include break-min($large1) {
			padding: 0;
			margin-right: 22px;
			max-width: 600px;
		}

		p {
			line-height: 28px;
			@include font-size(1.8);
		}

		.headline {
			margin-bottom: 16px;

			p {
				font-weight: $bold-weight;
				letter-spacing: -0.5px;
			}
		}

		h4 {
			color: $white;
			margin-bottom: 16px;
			letter-spacing: -1px;
			@include font-size(2.8);

			@include break-min($large1) {
				line-height: 34px;
				@include font-size(4.8);
			}
		}
	}
}
