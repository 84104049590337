// sass-lint:disable max-file-line-count

.membership-tiers {
	margin-top: 45px;
	position: relative;
	text-align: center;

	@media screen and (min-width: $mobile) {
		margin-top: 35px;
	}

	&.dotted-border-bottom {
		&::after {
			@include dot-border-h(bottom, left);
		}
	}

	&.wrapper {
		@media screen and (max-width: $mobile) {
			padding: 0;
			margin: 0;
		}
	}

	.header {
		margin: auto;
		width: $col10;

		h2 {
			@include font-size(2.4);
			color: $blue;
			font-family: $primary;
			font-weight: 700;
			letter-spacing: -0.5px;
			margin: 2rem 0;
			line-height: 34px;

			@include break-min($medium3) {
				line-height: 42px;
			}
		}

		P {
			line-height: 28px;
		}

		p {
			@include p1();
			margin-bottom: 50px;
		}
	}

	ul {
		margin: 0;
		padding: 0;
	}

	.icon-wrapper {
		border-radius: 100%;
		height: 70px;
		width: 70px;
		margin: auto;
		position: relative;

		@include break-min($medium3) {
			height: 75px;
			width: 75px;
		}
	}

	.member-nav {
		background: $white;
		display: flex;
		justify-content: space-around;
		text-align: center;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		border: none;
		border-bottom: none;
		padding: 0 20px;
		margin: 0;
		position: sticky;
		top: 64px;
		z-index: 1;

		@include break-min($medium3) {
			position: relative;
			border: 1px solid #e2e2e2;
			padding: 0;
			border-bottom: none;
			top: 0;
		}

		@include break-min($small1) {
			margin-left: -15px;
			margin-right: -15px;
		}

		@include break-min($small4) {
			margin-left: -30px;
			margin-right: -30px;
		}

		@include break-min($small5) {
			margin: 0 auto;
		}

		&.silver-bckgrd {
			background: $membership-silver;
		}

		&.red-bckgrd {
			background: $membership-red;
		}

		&.signature-bckgrd {
			background: $membership-signature;
		}

		@media screen and (min-width: $mobile) {
			justify-content: center;
		}

		li {
			padding: 22px 0 20px;
			list-style: none;
			text-indent: 0;
			margin: 0;
			width: 25%;
			background-color: $white;
			opacity: 0.5;

			&:first-child {
				border-top-left-radius: 20px;
				border-top-right-radius: 0;
			}

			&:last-child {
				border-top-left-radius: 0;
				border-top-right-radius: 20px;

				a {
					border-right: none;
				}
			}

			.icon-wrapper {
				border: 1px solid $white;
			}

			&[data-member-link="basic-member"] {
				.icon-wrapper {
					border: 1px solid transparent;
					border-radius: 50%;
					background-color: $cool-white;

					&.selected {
						border: 1px solid $membership-teal;
					}
				}
			}

			&[data-member-link="silver-member"] {
				.icon-wrapper {
					border: 1px solid transparent;
					border-radius: 50%;
					background: rgba($membership-silver, 0.5);

					&.selected {
						border: 1px solid $membership-gray;
					}
				}
			}

			&[data-member-link="red-member"] {
				.icon-wrapper {
					border: 1px solid transparent;
					border-radius: 50%;
					background: rgba($membership-red, 0.5);

					&.selected {
						border: 1px solid $membership-red-border;
					}
				}
			}

			&[data-member-link="signature-member"] {
				.icon-wrapper {
					border: 1px solid transparent;
					border-radius: 50%;
					background: rgba($membership-signature, 0.5);

					&.selected {
						border: 1px solid $membership-blue;
					}
				}
			}

			@include break-min($large1) {
				padding: 22px 0;
				opacity: 1;

				&[data-member-link] {
					.icon-wrapper {
						border: 1px solid transparent;
						background: transparent;

						&.selected {
							border: 1px solid transparent;
						}
					}
				}
			}

			p {
				opacity: 1;
			}

			span {
				@include p2();
				font-family: $secondary;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 2px;
				@include font-size(1.3);
			}

			&.arrow_box {
				background-color: $white;
				opacity: 1;

				.icon-wrapper {
					border-radius: 50%;
				}

				@include break-min($medium3) {
					background-color: $cool-white;
					opacity: 1;
				}

				a {
					border-right: none;
				}

				p {
					opacity: 1;
				}
			}

			a {
				display: inline-block;
				width: 100%;
			}
		}

		a:focus {
			outline: none;
		}

		.arrow_box {
			position: relative;

			&:after {
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(136, 183, 213, 0);
				border-top-color: $white;
				border-width: 18px;
				border-block-width: 13px;
				transform: rotate(180deg);
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				bottom: -1px;
				opacity: 0;
			}
		}

		.icon-wrapper {
			img {
				display: none;
				height: 100%;
				margin: auto;
				padding: 15px;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				&.active {
					display: block;
				}
			}
		}

		a {
			color: $white;
			text-decoration: none;

			p {
				line-height: 21px;
				margin-top: 10px;
			}
		}

		.tier-descriptor {
			display: none;
			color: $blue;
			@include font-size(1.2);

			@include break-min($medium3) {
				display: block;
			}
		}
	}

	.member-tier-benefits {
		background-color: $cool-white;

		.tier-descriptor {
			display: block;
			position: relative;
			margin: 0px 20px;
			padding: 9px 20px 32px;
			text-align: center;
			color: $blue;

			@include break-min($medium3) {
				padding: 12px 20px;
			}

			&:after {
				@include dot-border-h;
			}

			p {
				@include font-size(1.4);
				line-height: 26px;
				font-weight: 400;

				@include break-min($medium3) {
					@include font-size(2.4);
					font-weight: 700;
					letter-spacing: -0.5px;
				}
			}

			span {
				@include p2();
				font-family: $secondary;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 2px;
				@include font-size(2.2);

				@include break-min($medium3) {
					@include font-size(2);
				}
			}

			@include break-min($medium3) {
				display: none;
			}
		}

		&.shadow-border {
			border: 1px solid lightgrey;
			border-top: 0;
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
			margin: 0px 20px;
			padding-top: 30px;

			@media screen and (min-width: $mobile) {
				margin: 0;
			}
		}

		ul {
			margin: 0 auto;
			padding: 72px 0;

			@include break-min($medium3) {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				padding-top: 0;
				padding-bottom: 50px;
			}

			&.basic-member {
				.circle-border {
					color: $teal;
				}
			}

			&.silver-member {
				.circle-border {
					color: $membership-silver;
				}
			}

			&.red-member {
				.circle-border {
					color: $red;
				}
			}

			&.signature-member {
				.circle-border {
					color: $blue;
				}
			}

			&.no-display {
				display: none;
			}
		}

		li {
			margin-bottom: 10px;
			list-style: none;
			text-indent: 0;
			margin: 0 auto;
			padding: 12px 20px;

			@include break-min($medium3) {
				padding: 12px 50px;
				width: 33.3333%;
			}

			@include break-min($large2) {
				padding: 50px 5% 20px;
			}
		}

		.icon-wrapper {
			border-radius: 100%;
			color: $blue;
			height: 40px;
			width: 40px;
			margin: auto;
			margin-bottom: 15px;
			position: relative;

			@include break-min($medium3) {
				margin-bottom: 0;
			}

			img {
				padding: 0;
				margin: auto;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}
		}

		.circle-border {
			padding: 25px 0 5px;
			white-space: nowrap;
			font-weight: 700;
			margin: 0;
			@include font-size(1.3);

			@include break-min($medium3) {
				padding: 18px 0 0;
			}
		}

		p {
			@include font-size(1.6);
			font-family: $primary;
			font-weight: $regular-weight;
			line-height: lh(20px, 12px);
			margin: 0;

			@include break-min($medium3) {
				margin: 10px 0;
				@include font-size(1.3);
			}
		}
	}
}
