.prizes-panel {
	margin-top: 0;
	padding: 54px 0 80px;

	@include break-min($large1) {
		padding: 47px 0 139px;
	}

	h1 {
		&:after {
			content: "";
		}
	}
	.prize-header {
		img {
			overflow: hidden;
			border-radius: 22px;
		}
	}
}
