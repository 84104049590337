.restaurant-info-nav {
	background: $white;
	border-radius: 20px;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
	list-style-type: none;
	margin: 0 32.5px 48px;
	padding: 0;
	position: relative;
	text-align: center;
	z-index: 1000;

	@include break-min($large1) {
		display: flex;
		padding: 0;
		margin: 0 auto -50px;
		width: fit-content;
	}

	&.mobileOpen {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;

		.current-nav-item {
			border-bottom: 2px solid $faint-grey;

			&:after {
				transform: rotate(180deg);
			}
		}

		.dropdown {
			display: block;
			position: absolute;
			background: $white;
			width: 100%;
			z-index: 1000;
			top: 58px;
			border-bottom-right-radius: 24px;
			border-bottom-left-radius: 24px;
			box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

			.link {
				display: block;
			}

			&:after {
				transform: rotate(180deg);
			}
		}
	}

	&.desktopNotSticky {
		position: relative;
		top: 0 !important; // overwrite val from stickService
	}

	&.desktopSticky {
		animation: topSlidedown 350ms ease-in-out;
		border-radius: 0;
		max-width: 2000px;
		position: fixed;
		top: 63px;
		width: 100%;

		.dropdown {
			justify-content: center;
			max-width: none;
			width: 100%;

			.link.active {
				display: block;
				left: 0;
			}
		}
	}

	&.mobileSticky {
		animation: topSlidedown 350ms ease-in-out;
		border-radius: 0;
		margin: 0;
		position: fixed;
		width: 100%;

		.dropdown {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&.mobileNotSticky {
		position: relative;

		.dropdown {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			flex-direction: column;
			margin: 0 auto;

			.link {
				display: block;
				padding: 25px 0 25px 20px;

				.img-wrapper {
					min-width: 75px;
				}

				img {
					height: 68px;
					width: 68px;

					@include break-min($large1) {
						height: 100%;
						width: 100%;
					}
				}
			}
		}

		.scroll {
			display: none;
		}
	}

	&.desktopHidden {
		display: none;
	}

	.current-nav-item {
		border-bottom: 0;
		cursor: pointer;
		line-height: lh(24, 18);
		margin: 0 24px;
		padding: 16px 0;
		position: relative;
		text-align: left;
		width: auto;

		@include break-min($large1) {
			box-shadow: none;
			display: none;
		}

		button {
			@include font-size(1.8);
			background: transparent;
			border: 0;
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -0.5px;

			&:hover {
				color: $red;
			}

			@include break-min($large1) {
				@include font-size(1.6);
				letter-spacing: -0.5px;
			}
		}

		&:after {
			@include font-size(1.8);
			color: $red;
			content: "\e902";
			font-family: $icon;
			position: absolute;
			right: 26px;
			transition: 0.5s all ease;
		}
	}

	li:nth-child(2) {
		width: 100%;
	}

	.dropdown {
		display: none;
		list-style-type: none;
		margin: auto;
		padding: 0;

		@include break-min($large1) {
			display: flex;
			margin: 0;
			text-align: center;
			width: 100%;
		}

		li {
			border-bottom: 2px solid $faint-grey;
			margin: 0 24px;
			padding: 24px 0 24px 0;
			text-align: left;
			width: auto;

			@include break-min($large1) {
				border-bottom: 0;
				border-right: 2px solid $faint-grey;
				margin: 24px 0;
				padding: 0 48px;
				text-align: center;
				width: auto;
			}

			&:last-child {
				border-bottom: 0;

				@include break-min($large1) {
					border-right: 0;
				}
			}

			&.active {
				button {
					@include break-min($large1) {
						border-bottom: 2px solid $red;
						transition: 100ms ease-in-out;
					}
				}
			}

			&.hidden {
				display: none;
			}

			div {
				@include break-min($large1) {
					padding: 12px 0;
				}
			}
		}
	}

	button {
		@include font-size(1.8);
		background: transparent;
		border: none;
		color: $blue;
		font-family: $primary;
		font-weight: $bold-weight;
		letter-spacing: -0.5px;
		line-height: lh(24, 18);
	}
}
