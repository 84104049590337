.location-menu-callout {
	&.reverse-order {
		.flex-wrapper {
			.img-wrapper {
				order: 1;
			}

			.content {
				order: 2;
			}
		}
	}

	@include break-min($large1) {
		padding: 0 0 71px 0;
	}

	.flex-wrapper {
		@include break-min($large1) {
			height: 458px;
			padding-bottom: 70px;
			align-items: center;
			justify-content: space-between;
		}
	}

	.img-wrapper {
		margin-bottom: 36px;
		height: 100%;
		width: 100%;
		order: 2;

		@include break-min($large1) {
			margin-bottom: 0;
			padding-right: 70px;
			max-width: 700px;
		}

		.mobile-img {
			width: 100%;
		}

		.desktop-img {
			width: 100%;
			object-fit: cover;
		}
	}

	.content {
		padding: 0 24px;
		order: 1;

		@include break-min($large1) {
			padding: 0;
			margin-right: 22px;
			max-width: 600px;
		}

		p {
			line-height: 28px;
			margin-bottom: 20px;

			@include break-min($large1) {
				@include font-size(1.8);
			}
		}

		.headline {
			display: flex;
			margin-bottom: 5px;

			@include break-min($large1) {
				margin-bottom: 16px;
			}

			p {
				font-weight: $bold-weight;
				letter-spacing: -0.5px;
				color: $blue;
				@include font-size(1.4);
			}
		}

		.btn-container {
			margin-bottom: 36px;

			@include break-min($large1) {
				margin-bottom: 0;
			}

			.btn-round {
				width: auto;
				display: inline-block;
			}
		}

		h4 {
			margin-bottom: 16px;
			color: $blue;
			@include font-size(2);

			@include break-min($large1) {
				@include font-size(2.4);
			}
		}
	}

	&:nth-child(2n + 1) {
		&.v2 {
			padding-top: 32px;
			padding-bottom: 64px;

			@include break-min($large1) {
				padding: 100px 0;
			}
		}
	}

	&.v2 {
		padding: 64px 24px 32px;

		// &:nth-child(2n + 1) {
		// 	padding-top: 32px;
		// }

		@include break-min($large1) {
			padding: 100px 0;
		}

		&.reverse-order {
			.img-wrapper {
				grid-column: col-start 2 / span 5;

				@include break-min($large1) {
					margin: 0;
				}
			}

			.content {
				grid-column: col-start 7 / span 5;

				@include break-min($large1) {
					margin: 0 0 0 75px;
				}
			}
		}

		.flex-wrapper {
			height: auto;
			padding: 0;

			&:after {
				content: none;
				// justification: must overwrite base style
				border-bottom: 0 !important;
			}

			@include break-min($large1) {
				@include desktop-grid();
				@include desktop-grid-max-width();
			}
		}

		// For local/static view only
		.btn-container {
			display: none !important;
		}

		.img-wrapper {
			border-radius: 40px;
			margin: 0 0 30px 0;
			overflow: hidden;

			@include break-min($large1) {
				grid-column: col-start 7 / span 5;
				height: 300px;
				margin: 0 0 0 75px;
				padding: 0;
			}
		}

		.content {
			padding: 0;

			@include break-min($large1) {
				grid-column: col-start 2 / span 5;
			}

			h4 {
				@include font-size(2.8);
				font-weight: $bold-weight;
				line-height: lh(34, 28);
				letter-spacing: -0.5px;
				margin: 0 0 25px 0;
				text-align: left;

				@include break-min($large1) {
					@include font-size(3.6);
					font-weight: $bold-weight;
					line-height: lh(42, 36);
					letter-spacing: -0.5px;
					margin: 0 0 17px 0;
					text-align: left;
				}
			}

			p {
				@include font-size(1.6);
				line-height: lh(24, 16);
				letter-spacing: -0.5px;
				margin: 0;
				text-align: left;
			}
		}
	}

	&.blue-bckgrd {
		.content {
			color: $white;

			h4 {
				color: $white;
			}

			.headline {
				.headline-icon {
					margin-right: 10px;
				}
				p {
					color: $white;
				}
			}
		}
	}
}
