.one-block_img_header_txt {
	background: $warm-white;
	padding: 252px 24px 68px;

	@include break-min($large1) {
		padding: 150px 0 86px;
	}

	.img-wrapper {
		padding: 0 16px;

		@include break-min($large1) {
			margin: auto 0;
			max-width: 270px;
			padding: 0;
			width: 25%;
		}
	}

	.content {
		padding: 0 16px;

		@include break-min($large1) {
			max-width: 600px;
			padding: 0;
		}

		h2 {
			@include font-size(2.8);
			color: $blue;
			font-family: $primary;
			font-weight: 700;
			letter-spacing: -0.5px;
			margin: 2rem 0;
			line-height: 34px;

			@include break-min($medium3) {
				@include font-size(3.6);
				line-height: 42px;
			}
		}

		P {
			line-height: 28px;
		}

		h3,
		h4,
		h5 {
			@include font-size(3.6);
			color: $blue;
			font-weight: $bold-weight;
			line-height: lh(46, 36);

			@include break-min($large1) {
				@include font-size(4.8);
				line-height: lh(58, 48);
				margin-bottom: 30px;
			}
		}

		.btn-round {
			// @extend .btn-round--white;
			width: 100%;

			@include break-min($large1) {
				width: auto;
			}
		}
	}
}
