.community-form {
	width: 100%;
	padding: 0 24px;
	margin: 0px auto 50px;

	@include break-min($medium1) {
		flex-basis: 448px;
		margin: 0px auto 50px;
		max-width: 448px;
		width: 448px;
	}

	.input.display-none {
		display: none;
	}

	.form-group {
		margin-bottom: 30px;

		h3 {
			@include font-size(1.6);
			color: $grey;
			font-family: $primary;
			font-weight: bold;
			letter-spacing: -0.5px;
		}

		textarea {
			@include font-size(1.8);
			border-radius: 5px;
			border: 1px solid $vis-grey;
			bottom: 0;
			color: $vis-grey;
			font-family: $primary;
			height: calc(100% - 16px);
			left: 0;
			letter-spacing: -0.5px;
			line-height: 15px;
			min-height: 68px;
			padding: 25px 17px 4px 16px;
			position: relative;
			transition: border 0.5s ease-in-out;
			width: 100%;

			&:focus {
				border: 3px solid $vis-blue;
			}

			&.orig-border {
				border: 3px solid $faint-grey;
			}

			&.error {
				border: 3px solid $red;
				color: $red;
			}
		}

		.text-legal {
			p {
				@include font-size(1.2);
				color: $rolling-stone;
				letter-spacing: -0.5px;
				font-weight: normal;

				a {
					text-decoration: underline;
				}
			}
		}
	}

	.input {
		display: inline-block;
		position: relative;
		overflow: hidden;
		width: 100%;
		margin-bottom: 10px;

		&.recaptcha {
			display: none;

			&.display {
				display: block;
			}
		}

		.input__wrapper {
			&.hasError {
				@extend .global-form-error;
			}
		}

		&__label {
			@include font-size(1.2);
			color: $grey;
			font-family: $primary;
			font-weight: normal;
			left: 20px;
			letter-spacing: 0;
			position: absolute;
			top: 11px;
			z-index: 100;
		}

		input {
			@include font-size(1.8);
			border-radius: 5px;
			border: 1px solid $vis-grey;
			bottom: 0;
			color: $vis-grey;
			font-family: $primary;
			height: calc(100% - 16px);
			left: 0;
			letter-spacing: -0.5px;
			line-height: 32px;
			min-height: 68px;
			padding: 25px 17px 4px 16px;
			position: relative;
			transition: border 0.5s ease-in-out;
			width: 100%;

			&:focus {
				border: 3px solid $vis-blue;
			}

			&.orig-border {
				border: 3px solid $faint-grey;
			}

			&.error {
				border: 3px solid $red;
				color: $red;
			}
		}

		::placeholder {
			// Chrome, Firefox, Opera, Safari 10.1+
			color: $light-grey;
			opacity: 1; // Firefox
		}
	}

	.date-selector {
		margin-bottom: 0;
		margin-right: 10px;

		.input__wrapper {
			margin: 0;
			margin-bottom: 10px;

			.datepicker-label {
				font-size: 1.2rem;
				color: $vis-grey;
				font-weight: 400;
				left: 19px;
				position: absolute;
				top: 11px;
				z-index: 100;
			}

			input {
				text-transform: uppercase;
			}
		}

		input {
			position: relative;

			&::placeholder {
				color: $light-grey;
				opacity: 1;
				text-transform: uppercase;
			}
		}

		.datepicker {
			&::placeholder {
				color: $light-grey;
				text-transform: uppercase;
			}
		}
	}

	.captcha-container {
		margin-bottom: 38px;
	}
}
