.customer-support-yext-search {
	border-radius: 20px;

	.yxt-SearchBar-autocomplete {
		.yxt-AutoComplete {
			ul {
				li {
					@include font-size(1.6);
				}
			}
		}
	}
}
