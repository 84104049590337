.true-inspiration-faq {
	.accordion:nth-of-type(1) {
		@extend .covid-accordion;
		margin: 20px 0 33px;

		@include break-min($large1) {
			margin: 100px 0 165px;
		}
	}

	.accordion:nth-of-type(2) {
		@extend .delivery-accordion;
		background: $cool-white;
		margin-bottom: 0;
		padding: 64px 24px;

		@include break-min($large1) {
			margin-bottom: 0;
			padding: 112px 0;
		}

		.cta-btn {
			display: none;
		}
	}

	.header-img-callout {
		@extend .red-bckgrd;

		@include break-min($large1) {
			margin-top: 0;
		}
	}
}
