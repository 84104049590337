#pwo-modal-favorited-restaurants {
	padding: 64px 0 0 !important;

	h2 {
		padding: 0 64px !important;
		margin-bottom: 32px;
	}

	.content {
		padding: 0 !important;

		.secondary {
			padding: 32px 64px 32px !important;
		}
	}

	.pwo-modals-restaurant-label {
		padding: 0 64px !important;
		justify-content: space-between;

		.btn-round {
			position: relative;
			right: 0;
		}
	}
}
