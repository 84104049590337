@mixin red-arrow-btn {
	align-items: center;
	background: $white;
	border-radius: 32px;
	bottom: 22px;
	display: flex;
	justify-content: center;
	left: 0;
	margin: auto;
	padding: 12px 14px;
	position: absolute;
	right: 0;
	width: fit-content;

	button {
		background-color: transparent;
		border: 2px solid $red;
		border-radius: 50%;
		height: 40px;
		padding: 0;
		position: relative;
		width: 40px;

		&[disabled] {
			border-color: $mdgrey;

			img {
				&.active {
					display: none;
				}

				&.inactive {
					display: block;
				}
			}
		}

		img {
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);

			&.active {
				display: block;
			}

			&.inactive {
				display: none;
			}
		}
	}

	[data-carousel-count] {
		@include type-h4-refresh();
		color: $red;
		letter-spacing: -0.5px;
		margin: 0 12px;
	}
}
