.footer-overlay-view {
	margin: 0 32px;

	@include break-min($medium4) {
		margin: 0 46px;
	}

	@include break-min($large4) {
		margin: 0 72px;
	}

	@include break-min($huge4) {
		margin: 0 auto;
	}

	.background-img {
		border-radius: 16px;
		display: block;
		grid-column: col-start 1 / span 4;
		grid-row: 1;

		@include break-min($medium4) {
			grid-column: col-start 1 / span 8;
		}

		@include break-min($large1) {
			grid-column: col-start 1 / span 12;
		}

		&.mobile-img {
			@include break-min($large1) {
				display: none;
			}

			&.desktop-img {
				display: none;

				@include break-min($large1) {
					display: block;
				}
			}
		}
	}

	.img-container {
		display: none;
	}

	.content {
		background: $white;
		border-radius: 16px;
		grid-row: 1;
		margin: 210px 16px 16px;

		@include break-min($medium4) {
			margin: 46px;
			padding: 46px;
		}

		@include break-min($huge4) {
			padding: 35px;
		}

		.btn-container {
			@include break-min($huge4) {
				display: flex;
				justify-content: center;
				max-width: none;
			}

			a {
				@include break-min($huge4) {
					width: auto;
				}

				&:first-child {
					@include break-min($huge4) {
						margin: 0 24px 0 0;
					}
				}
			}
		}
	}
}
