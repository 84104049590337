.rep-row {
	padding: 40px 0;

	&:nth-child(odd) {
		background-color: $bkgrey;
	}

	.flex {
		justify-content: flex-start;
	}

	.item {
		text-align: left;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 15px;
		}
	}

	.story-items {
		padding: 30px 30px 40px;

		.item {
			.story-details {
				color: $dim-gray;
			}
		}
	}

	.yt-video {
		display: block;

		.story-img {
			display: flex;
		}
	}

	.story-img {
		background-repeat: no-repeat;
		display: block;
		margin-bottom: 20px;

		@media only screen and (min-width: $lrg-tablet) {
			height: 200px;
		}

		@media only screen and (max-width: $mobile) {
			min-height: auto;
		}
	}

	.story-video-text {
		a {
			&:hover {
				.story-details {
					color: $red;
					text-decoration: underline;
					transition: all 0.5s ease;
				}
			}
		}
	}

	h2 {
		@include font-size(3.6);
		margin: 0;
		text-align: center;
	}

	.full {
		padding: 30px 30px 60px 30px;
		position: relative;

		a {
			color: $white;
		}
	}

	.story {
		@include flex-grow(1);
		@include font-size(1.6);
		letter-spacing: 0px;
		line-height: 28px;
		padding: 30px 30px 60px 30px;
		position: relative;
		text-align: left;
		transition: border 0.5s ease;

		@media only screen and (max-width: $mobile) {
			padding: 30px 15px 60px;
		}

		&.full {
			background-repeat: no-repeat;
			background-size: cover;

			@media only screen and (max-width: $mobile) {
				border: 0;
				min-height: auto;
				padding: 0;
			}

			&:hover {
				cursor: pointer;
			}

			& > a {
				border: 0;
			}

			p {
				&:after {
					display: none;
				}
			}

			.lead {
				@include flex-direction(column);
				@include align-items(flex-start);
				@include justify-content(flex-end);
				@include background($scrim2);
				bottom: 0;
				color: $white;
				display: flex;
				height: 100%;
				left: 0;
				right: 0;
				position: absolute;
				padding: 0 20px;
				top: 0;

				@media only screen and (max-width: $mobile) {
					padding: 80px 20px 10px;
					position: static;
				}
			}

			h3 {
				@include font-size(2.7);
				font-family: $primary;
				font-weight: 500;
				margin: 0;
				padding: 0 10px 0 0;
				width: 100%;

				a {
					color: $white;
				}
			}

			a {
				color: $white;
			}

			.tag {
				@include font-size(1.2);
				color: $white;
				position: relative;
				text-transform: uppercase;

				span {
					color: $white;
				}
			}
		}

		img {
			width: 100%;
		}

		p {
			margin: 0 0 20px 0;
		}
	}

	.lead-story {
		margin: 0 auto;
		max-width: $main;

		@media only screen and (max-width: $mobile) {
			padding-top: 0;
		}

		.content {
			@include background($scrim2);
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 20px;
			width: 100%;
			height: 100%;
		}

		.tag {
			a {
				color: $white;
			}
		}
	}

	.show-more {
		@media only screen and (max-width: $mobile) {
			text-align: center;
		}
	}
}

.rep-manager {
	@include font-size(1.6);
	font-family: $primary;
	line-height: 24px;
	margin: 0 auto;
	max-width: $col6;
	padding: 50px 0;

	@media only screen and (max-width: $mobile) {
		max-width: none;
		padding: 20px 0;
	}

	h2 {
		@include font-size(2.2);
		color: $blue;
		font-family: $primary;
		line-height: 34px;
	}
}
