.rtr-item-container,
.rtr-rendering,
.rt-placeholder {
	display: none;
}

.sc-editor {
	.rtr-item-container,
	.rtr-rendering {
		display: block;
	}

	.rt-placeholder {
		display: block;
		border: 1px solid #b00;
		min-height: 50px;
		min-width: 75%;
	}
}
