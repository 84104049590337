.menu-banner-alert {
	background: $remy-red;
	color: $red;
	margin-top: 20px;
	padding: 24px;
	transition: 300ms all ease-in;
	width: 100%;
	z-index: 100;

	&.fixed {
		position: fixed;
	}

	p {
		margin: 0;

		&:nth-child(2) {
			margin-top: 20px;
		}
	}
}
