.location-temporarily-closed-module {
	background-color: $cream;
	padding: 37px;
	text-align: center;
	margin-top: 36px;
	margin-bottom: 25px;

	h4 {
		text-transform: uppercase;
		color: $red;
		line-height: 34px;
		letter-spacing: -0.05px;
		font-weight: $bold-weight;
		margin: 0 0 5px;
		@include font-size(2.2);
	}

	p {
		margin: 0;
	}
}
