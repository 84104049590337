.location-tabbed {
	.flex-container {
		@include flex-direction(column);
		@include flexbox();
	}

	.job-opportunities {
		margin-bottom: 30px;
	}

	.g-tabs {
		&__nav {
			background-color: $white;
			margin-left: -24px;
			margin-right: -24px;
			padding: 0 10px;
			@include flexbox();

			@include break-min($large1) {
				background-color: $white;
				margin: 0 -3px;
				padding: 0;
				@include justify-content(stretch);
				@include align-items(center);
				@include flex-wrap(nowrap);
			}
		}

		&__toggle {
			&--desktop {
				display: block;
				justify-content: center;

				@include break-min($large1) {
					display: block;
					flex: 0 1 25%;
				}
			}
		}
	}

	&-wrapper {
		padding: 0;
		@include flex-flow(column);

		.location-tabbed-nav-top {
			height: 1px;
		}

		.location-tabbed-nav {
			border-bottom: 1px solid $iron;
			position: sticky;
			top: 64px;
			z-index: 1;
			transition: all 0.2s ease;
			display: flex;
			justify-content: center;

			@include break-min($medium3) {
				position: relative;
				top: 0;
			}

			&.is-pinned {
				background-color: $cool-white;

				.g-tabs__toggle {
					&--active {
						border-bottom: 5px solid $red;
					}

					span {
						border-bottom: 5px solid $cool-white;
					}
				}
			}

			.g-tabs__toggle {
				border-bottom: 5px solid transparent;
				margin: 0 15px;
				transition: all 0.3s ease;

				span {
					background-color: transparent;
					color: $rolling-stone;
					transition: all 0.2s ease;
					letter-spacing: -0.5px;
					font-weight: 700;
					padding: 15px 10px 5px;
					text-align: center;
					border-bottom: 5px solid transparent;
					@include font-size(1.8);
				}

				@include break-min($large1) {
					margin: 0 30px;
					border-bottom: 5px solid transparent;

					span {
						padding: 0 0 15px;
						border-bottom: 5px solid $white;
						background-color: $white;
						@include font-size(2);
					}
				}

				// &.g-tabs-single {
				// 	span {
				// 		text-align: left;
				// 		padding-left: 0;
				// 	}
				// }

				&--active {
					border-bottom: 5px solid $red;

					span {
						color: $red;
					}
				}

				&:hover {
					span {
						color: $red;
					}
				}
			}

			&.single {
				justify-content: start;

				.g-tabs__toggle {
					flex: 0 1 30%;

					@include break-min($large1) {
						flex: 0 1 25%;
					}

					span {
						text-align: left;
						padding-left: 0;
					}
				}
			}
		}

		.location-tabbed-content {
			visibility: visible;

			.g-tabs__content {
				.inner {
					ul {
						padding: 0 0 0 20px;

						li {
							line-height: 1.7;
						}
					}

					.scholarship-opportunities {
						margin: 20px 0;

						.btn-opportunity {
							font-weight: 500;
							letter-spacing: -0.5px;
							@include font-size(1.8);
						}
					}

					.community-request-content {
						margin-bottom: 30px;
						flex-flow: column;
						flex-wrap: wrap;

						@include break-min($large1) {
							margin-bottom: 30px;
							flex-flow: row;
							flex-wrap: wrap;
						}

						&[data-child-count="1"] {
							.community-block {
								@include break-min($large1) {
									margin: 0 auto 50px;
								}
							}
						}

						.community-block {
							padding: 0;
							text-align: left;
							margin-bottom: 40px;

							@include break-min($large1) {
								text-align: center;
								padding: 0 20px;
								width: 50%;
								margin-bottom: 50px;
							}

							h3 {
								margin-top: 0;
								margin-bottom: 10px;
								text-transform: uppercase;
								color: $blue;
								@include font-size(1.1);
								@include s3();
							}
						}
					}

					.g-tabs__text {
						padding: 40px 0;
						max-width: 100%;

						@include break-min($large1) {
							padding: 55px 0;
						}

						.lbm-partners-grid {
							display: flex;
							list-style: none;
							padding: 0px;
							align-items: center;
							justify-content: space-between;
							margin-bottom: 30px;

							@include break-min($large1) {
								margin-bottom: 40px;
							}

							li {
								img {
									max-width: 90px;

									@include break-min($large1) {
										max-width: 132px;
									}
								}
							}
						}

						h3 {
							font-weight: $medium-weight;
							font-family: $primary;
							color: $blue;
							margin-top: 0;
							margin-bottom: 15px;
							@include flexbox;
							@include font-size(1.8);
						}

						a {
							text-decoration: underline;
						}

						p {
							margin-bottom: 20px;
						}
					}

					.g-tabs__img {
						padding: 25px 0;
					}
				}
			}
		}
	}
}
