// --------------------------------------------------------------------------
//  ACCESSIBILITY
// --------------------------------------------------------------------------

*[tabindex] {
	outline: none;

	&:focus {
		// Is this necessary?
		// font-weight: bolder;
		outline: -webkit-focus-ring-color auto 5px;
	}
}
