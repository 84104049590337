.cta-menu-card {
	border: 3px solid $red;
	border-radius: 32px;
	color: $red;
	display: flex;
	flex-direction: column;
	height: 115%;
	padding: 47px 17px;
	justify-content: center;
	text-align: center;
	grid-column: auto/span 4;
	overflow: hidden;

	@include break-min($medium4) {
		min-height: 500px;
	}

	p {
		@include font-size(1.8);
		line-height: lh(28, 18);
		margin: 0 0 8px;
	}

	.header {
		p {
			@include type-b2(mobileOnly);
			margin: 0 0 32px;
		}
	}

	a {
		margin: 0 auto;
		width: fit-content;
	}
}
