.covid-new-ordering-callout {
	background: $white;
	margin: 0;
	overflow: hidden;
	padding: 70px 20px;
	position: relative;

	@include break-min($large1) {
		display: flex;
		padding: 163px 100px;
	}

	@include break-min($huge1) {
		padding: 163px 260px;
	}

	.wrapper {
		margin: 0;
		max-width: none;
		padding: 0;
		position: relative;
		text-align: left;
		z-index: 100;

		@include break-min($large1) {
			padding: 0 15px;
		}
	}

	p {
		color: $grey;
		letter-spacing: normal;
		font-weight: normal;
		font-size: 18px;

		@include break-min($large1) {
			font-size: 14px;
			letter-spacing: -0.5px;
		}
	}

	h1,
	h2 {
		color: $red;
		letter-spacing: -1px;
		font-weight: $bold-weight;
		font-size: 36px;
		line-height: lh(58, 46);
		margin: 20px 10% 30px 0;
		max-width: 90%;
		padding-bottom: 30px;
		position: relative;

		@include break-min($sml-mobile) {
			margin: 20px 10% 30px 0;
			max-width: 50%;
		}

		@include break-min($large1) {
			font-size: 48px;
			letter-spacing: -1px;
			padding: 0 0 40px 0;
			margin: 20px 20% 50px 0;
		}

		&:after {
			content: "";
			position: absolute;
			left: 5px;
			right: 0;
			width: 100%;
			height: 10px;
			background-image: url("../img/cfacom/cp-icons/icon-three-dots.svg");
			background-repeat: no-repeat;
			background-position: 0;
			bottom: 0;
		}
	}

	.btn-container {
		margin: 30px 0 0;

		@include break-min($large1) {
			margin-top: 40px;
		}

		a {
			padding: 44px 90px;
			display: inline-block;
			width: 100%;
			text-align: center;
			font-weight: $bold-weight;
			line-height: 34px;
			letter-spacing: -0.5px;
			position: relative;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			background-color: $white;
			color: $red;
			border-radius: 16px;
			margin-bottom: 20px;
			@include font-size(1.8);

			@include break-min($large1) {
				width: auto;
				margin-right: 20px;
				margin-bottom: 0;
				@include font-size(2.2);
			}

			img {
				position: absolute;
				left: 40px;
			}

			p {
				color: $red;
				display: block;
				font-weight: $bold-weight;
				margin-left: 50px;
				position: initial;
			}
		}
	}

	.tile-img {
		display: none;
		bottom: 0;
		position: absolute;
		right: -180px;
		top: 80px;

		@include break-min($large1) {
			display: block;
		}
	}
}
