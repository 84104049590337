.covid-informative-callout {
	padding: 100px 20px;
	background-color: $red;
	text-align: center;

	@include break-min($large1) {
		height: 261px;
		padding: 80px 100px;
		text-align: left;
	}

	@include break-min($large4) {
		padding: 80px 200px;
	}

	&.warm-white-bckgrd {
		background: $warm-white;

		span {
			color: $blue;
		}

		.covid-content {
			h2 {
				color: $blue;
			}

			a {
				@extend .btn-round;
			}
		}
	}

	span {
		color: $white;
		font-family: $primary;
		letter-spacing: -0.5px;
		font-weight: bold;
		font-size: 18px;

		@include break-min($large1) {
			font-size: 14px;
		}
	}

	.covid-content {
		flex-flow: column;
		justify-content: space-between;
		align-items: center;

		@include break-min($large1) {
			flex-flow: row;
		}

		h2 {
			color: $white;
			font-family: $primary;
			letter-spacing: -1px;
			font-weight: bold;
			font-size: 36px;
			line-height: lh(46, 36);
			margin: 20px 0 50px;
			padding: 0 40px;

			@include break-min($large1) {
				font-size: 48px;
				letter-spacing: -0.8px;
				padding: 0;
				margin: 20px 0;
			}
		}

		a {
			width: 100%;

			@include break-min($large1) {
				min-width: 150px;
				width: auto;
			}
		}
	}
}
