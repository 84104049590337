.generic-text-panel {
	margin-top: -122px;
	padding: 122px 0 64px;
	background-color: $white;

	@include break-min($large1) {
		padding-bottom: 57px;
	}

	@include break-max($medium2) {
		padding: 42px 0;
	}

	&.cool-white-bckgrd {
		background: $cool-white;
	}

	&.callout-view {
		@extend .covid-new-ordering-callout;
	}

	.wrapper {
		margin: auto;
		max-width: 800px;
		padding: 0 24px;
		text-align: center;
	}

	p {
		@include type-p1();
		@include font-size(1.8);
		line-height: lh(28, 18);
		margin-bottom: 16px;
		font-weight: normal;

		&:first-child {
			@include break-min($large1) {
				@include font-size(1.4);
				margin-bottom: 11px;
			}
		}
	}

	h1 {
		@include font-size(3.6);
		color: $blue;
		font-family: $primary;
		font-weight: 700;
		letter-spacing: -1px;
		margin: 2rem 0 32px;
		line-height: 46px;

		@include break-min($large1) {
			@include font-size(4.8);
			line-height: 58px;
		}

		&:after {
			content: "...";
			@include font-size(3.2);
			display: block;
			letter-spacing: 3px;
			margin: 32px 0;
			line-height: 0;
			height: 16px;
		}
	}

	.btn-container {
		justify-content: center;
		align-items: center;
		margin-top: 46px;
		@include flexbox();
		@include flex-flow(column);

		&:empty {
			margin: 0;
		}

		@include break-min($large1) {
			@include flex-flow(row);
		}

		a {
			@extend .btn-primary;
			margin-bottom: 20px;

			&.with-img {
				display: flex;
				padding: 45px 35px;
				position: relative;
				width: 100%;

				@include break-min($large1) {
					padding: 36px 30px;
					width: 50%;
				}
			}

			&.vis-white {
				@extend .btn-secondary;
				margin-bottom: 0;
			}

			@include break-min($large1) {
				margin: 0 10px;
			}

			p {
				@include absolute-center;
				align-self: center;
				bottom: inherit;
				font-weight: bold;
				margin: 0;
				top: inherit;
			}
		}
	}
}
