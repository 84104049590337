.restaurant-info-tab {
	display: none;
	height: 0px;
	visibility: hidden;

	&.show {
		display: block;
		height: 100%;
		visibility: visible;
	}
}
