$gapOptions: (
	"14px": 14px,
	"28px": 28px,
	"56px": 56px,
	"112px": 112px
);

// Create utility classes for content authors to add gap as needed
@mixin applyGapOptions($element) {
	@each $key, $val in $gapOptions {
		&.gap-#{$key} {
			#{$element} {
				@include break-max($large1) {
					gap: $val !important;
				}
			}
		}

		&.gap-desk-#{$key} {
			#{$element} {
				@include break-min($large1) {
					gap: $val !important;
				}
			}
		}
	}
}
