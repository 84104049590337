.shared-table {
	&.infographic {
		.container--icon-block {
			background-color: $white;
			color: $grey;

			.container--column {
				span {
					color: $red;
				}
			}
		}
	}

	&.infographic-subcomponent,
	+ .infographic-subcomponent {
		display: none;
	}
}
