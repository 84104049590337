.subscription-address-verify {
	text-align: center;
	padding: 0 16px;

	@include break-min($large1) {
		margin: 0 auto;
		max-width: 1004px;
	}

	.header {
		padding: 0 0 24px 0;

		img {
			&.mobile-img {
				height: 46px;
				margin-bottom: 23px;

				@include break-min($large1) {
					display: none;
				}
			}

			&.desktop-img {
				display: none;

				@include break-min($large1) {
					display: inline-block;
					height: 83px;
					margin-bottom: 24px;
				}
			}
		}

		h1 {
			@include font-size(2.4);
			color: $blue;
			font-weight: $bold-weight;
			letter-spacing: -1%;
			line-height: lh(32, 24);
			margin: 0 0 16px 0;

			@include break-min($large1) {
				@include font-size(3.6);
				letter-spacing: -0.5px;
				line-height: (42, 36);
			}
		}

		p {
			@include font-size(1.8);
			font-weight: 400;
			letter-spacing: -0.5px;
			line-height: lh(28, 18);
			margin: 0;
		}
	}

	.inputs-container {
		@extend .global-form-input;
		margin-bottom: 24px;

		@include break-min($large1) {
			margin: 0 auto 24px;
			width: 460px;
		}

		input {
			// sass-lint:disable no-important
			border: 1px solid $rolling-stone;
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
			margin: 16px 0 0;
		}

		div {
			&:first-of-type {
				input {
					margin-top: 0;
				}
			}
		}

		.additional-info {
			margin: 8px 0 0 0;

			p {
				@include font-size(1.4);
				color: $rolling-stone;
				font-weight: 400;
				letter-spacing: -0.25px;
				line-height: lh(23, 14);

				&:first-child {
					margin-bottom: 8px;
				}
			}
		}

		.input-wrapper {
			position: relative;

			&:first-of-type {
				margin-bottom: 24px;
			}

			&.hasError {
				@extend .global-form-error;
			}
		}

		.error-container {
			padding: 3px 0 0 3px;

			label {
				color: $red;
				position: unset;
			}
		}
	}

	label[for="custom"] {
		color: $red;
	}

	.error-container {
		padding: 3px 0 0 3px;

		label {
			position: unset;
		}
	}

	button[type="submit"] {
		margin-bottom: 64px;

		@include break-min($large1) {
			width: 144px;
		}

		&.disabled {
			pointer-events: none;
		}
	}
}
