.location-tools {
	display: block;
	position: relative;
	padding: 0 0 0 40px;
	margin: 0;
	z-index: 100;
	max-width: calc(100vw - 135px);
	@include flex(0 1 auto);

	@media only screen and (max-width: 320px) {
		padding: 0 0 0 15px;

		.current-location .icon-location-off {
			display: none;
		}
	}

	@include break-min($large1) {
		max-width: calc(50vw - 240px);
	}

	.ie & {
		width: 100%;
	}

	&.location-tools-open {
		// sass-lint:disable-block no-duplicate-properties
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		max-width: 100%;
		padding: 0 60px 0 95px;
		@include flexbox();

		// because jquery hide/show conflicts
		display: flex;
		@include align-items(center);
		@include justify-content(center);

		.location-search-frame {
			@include flex(1 0 100%);
		}

		.location-title {
			background-color: $white;
			position: relative;
			text-align: center;
			margin: 0;
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			height: $nav-base-height;

			.icon-location-off {
				@include flex(0 0 auto);
				position: relative;
				top: auto;
				left: auto;
				bottom: auto;
				margin: 0;
				height: 24px;
				width: 30px;
			}

			@include break-min($large1) {
				display: none;
			}
		}
	}

	.icon-location-off {
		position: absolute;
		top: 0;
		left: 12px;
		bottom: 0;
		margin: auto;
		height: 24px;

		&::before {
			line-height: 1;
			font-size: 2.4rem;
			height: 24px;
		}
	}

	.current-location {
		a {
			padding: 5px 5px 5px 0;
			border: 0;
			font-weight: 400;
		}
	}

	.change {
		@include font-size(1.2);
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		display: inline;
		font-family: $primary;
		font-weight: $medium-weight;
		line-height: inherit;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;
		width: 100%;

		a {
			@include link-underline();
			line-height: inherit;
			padding: 0 4px;
		}
	}

	.store-name {
		@include link-underline();
		@include font-size(1.5);
		font-family: $primary;
		font-weight: $medium-weight;
		line-height: 1.2;
		// sass-lint:disable no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale;
		// sass-lint:enable no-vendor-prefixes
		cursor: pointer;
		display: inline-block;
		margin: 0;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: nowrap;
	}
}
