.generic-icon-set {
	@include applyGapOptions("ul");
	padding: 58px 24px;

	@include break-min($large1) {
		padding: 98px 24px;
	}

	@include break-min($large1) {
		margin: auto;
		max-width: 1000px;
		text-align: center;
	}

	.title {
		margin: 0 0 34px;

		@include break-min($large1) {
			margin: 0 auto 60px;
			max-width: 700px;
		}

		> *:first-child {
			@include type-h1-refresh();
			color: $blue;
			margin: 0 0 22px;

			@include break-min($large1) {
				margin: 0 0 34px;
			}
		}

		p {
			@include type-p1-refresh();
			color: $grey;
			margin: 0 0 22px;
		}
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		@include break-min($large1) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	li {
		margin: 0 auto 28px;
		max-width: 250px;
		text-align: center;

		@include break-min($large1) {
			margin: 0 0 32px;
			width: 25%;
		}

		.icon-img {
			height: 56px;
			margin: 0 0 16px;
		}

		.content {
			> *:first-child {
				@include type-h4-refresh();
				color: $blue;
				margin: 0 0 16px;
			}

			p {
				@include font-size(1.4);
				line-height: lh(23, 14);
				letter-spacing: 0px;
			}
		}
	}

	&.no-links {
		li {
			a,
			button {
				display: none;
			}
		}
	}
}
