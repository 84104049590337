.communities-three-column {
	background-color: $warm-white;

	ul {
		list-style: none;
		padding: 0;
	}

	&-headline-container {
		justify-content: center;
		align-items: center;
		margin: auto;
		max-width: 800px;
		padding: 0;
		text-align: center;
		@include flex-flow(column);
		@include flexbox();

		@include break-min($large1) {
			padding: 0 24px;
		}

		h2 {
			@include type-h1();
			color: $blue;
			line-height: lh(34, 30);
			margin: 0 0 22px;
			@include font-size(2.8);

			@include break-min($large1) {
				@include font-size(3.6);
			}
		}

		p {
			line-height: 28px;
			letter-spacing: -0.5px;
			@include font-size(1.8);
		}
	}

	.component-wrapper {
		padding-top: 0;
		padding-bottom: 42px;
		margin: 0 auto;
		max-width: 1580px;
		padding: 60px 20px 30px;
		width: auto;

		@include break-min($large1) {
			padding: 100px 30px 30px;
		}

		ul {
			margin: 15px 0;

			@include break-min($large1) {
				margin: 56px 0;
			}

			li {
				padding: 0 0 40px;
				border: none;

				&:last-child {
					border: none;
					padding-bottom: 0;
				}

				@include break-min($large1) {
					padding: 0 60px;
					border-right: 1px solid $faint-grey;
				}

				.img-container {
					border: 1px solid $iron;
					border-radius: 0;
					overflow: hidden;

					@include break-min($large1) {
						margin-left: 0;
						margin-right: 0;
					}

					img {
						object-fit: cover;
						width: 100%;
						max-height: 200px;
					}
				}

				.content {
					text-align: left;
					margin-top: 40px;
					padding: 0;
					@include flexbox();
					@include flex-flow(column);

					p {
						margin: 0;
						line-height: 25px;
						font-weight: 700;
						@include font-size(1.6);
					}

					h3 {
						color: $red;
						line-height: 34px;
						margin: 0;
						font-family: $primary;
						font-weight: 700;
						letter-spacing: -0.5px;
						@include font-size(2.4);

						@include break-min($large1) {
							margin: 0 0 15px;
						}
					}
				}

				.cta-container {
					margin-top: 24px;
					align-items: center;
					@include flexbox();

					@include break-min($large1) {
						margin-top: 20px;
					}

					a {
						margin: 10px 5px;

						&:first-child {
							margin: 0 5px 10px;
						}

						@include break-min($large1) {
							margin: 0 5px;
						}
					}
				}
			}
		}
	}

	.component-cta-container {
		margin-top: 0;
		justify-content: center;
		@include flexbox();

		@include break-min($large1) {
			margin-top: 50px;
		}
	}
}
