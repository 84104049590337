.g-header-img-block {
	padding: 25px;
	text-align: center;

	.header {
		margin: auto;

		@media screen and (min-width: $mobile) {
			width: $col8;
		}

		h2 {
			@include h3();
			color: $blue;
			margin-bottom: 50px;
		}

		p {
			@include p1();
			margin-bottom: 50px;
		}
	}

	.img-wrapper {
		margin-bottom: 50px;
	}

	.btn {
		@media screen and (max-width: $mobile) {
			width: 100%;
		}
	}
}
