.menu-mobile-toggle {
	align-items: center;
	background: transparent;
	border: 0;
	display: flex;
	justify-content: space-between;
	margin: 0 0 8px;
	padding: 0;
	width: 100%;

	@include break-min($medium4) {
		pointer-events: none;
	}

	> :first-child {
		@include font-size(2);
		color: $red;
		font-weight: $bold-weight;
		letter-spacing: -0.5px;
		line-height: lh(28, 20);
		margin: 0;

		@include break-min($medium4) {
			@include font-size(3.6);
			color: $blue;
			line-height: lh(42, 26);
		}
	}

	img {
		transition: 300ms ease-in;

		@include break-min($medium4) {
			display: none;
		}
	}

	&.active {
		img {
			transform: rotate(180deg);
		}
	}
}
