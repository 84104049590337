.global-form-input {
	position: relative;

	label {
		position: absolute;
		top: 10px;
		left: 18px;
		@include p2();
		font-weight: $medium-weight;
		color: $light-grey;
		pointer-events: none;
	}

	input {
		@include p1();
		@include appearance(none);
		color: $vis-grey;
		padding: 22px 60px 0 15px;
		display: block;
		height: 68px;
		width: 100%;
		cursor: pointer;
		background-color: transparent;
		border: 3px solid $vis-grey;
		border-radius: 6px;

		&:focus {
			border-color: $vis-blue;
			outline: 1px dotted $red;
			outline-offset: 3px;
		}

		&:disabled {
			background-color: $concrete;
			border-color: $mdgrey;
			cursor: not-allowed;
		}
	}
}
