.g-hero {
	&.cfaOne-hero {
		background: black;
		height: auto;
		padding: 50px 3px;

		@media screen and (min-width: $mobile) {
			display: flex;
			min-height: 800px;
		}

		.g-hero__text {
			h1,
			p {
				margin-right: 0;
				max-width: none;
				text-shadow: none;
			}

			p {
				margin: 15px 0;
			}

			.tagline {
				@include h5();
				font-weight: 900;
				margin: 15px auto;

				@media screen and (min-width: $mobile) {
					margin-top: 0;
				}
			}

			@media screen and (min-width: $mobile) {
				text-align: center;
				margin: auto;

				h1,
				p {
					margin: auto;
				}
			}
		}

		.g-hero__inner {
			.btn {
				@media screen and (max-width: $mobile) {
					width: 100%;
				}
			}
		}

		.app-stores {
			margin: 35px auto 0;

			a:first-child {
				margin-right: 10px;
			}
		}

		&.text-left {
			.g-hero__inner {
				.g-hero__text {
					text-align: left;

					h1 {
						margin-left: 0;
						max-width: 620px;
					}

					h5,
					p {
						margin-left: 0;
						max-width: 600px;
					}

					.tagline {
						margin-left: 0;
					}

					form > a {
						float: left;
					}

					a {
						float: left;
					}

					.app-stores {
						float: left;
						margin: 33px 0;

						a:first-child {
							margin-left: 10px;
						}
					}
				}
			}
		}

		&.mobile-center {
			@media screen and (max-width: $mobile) {
				.g-hero__inner {
					.g-hero__text {
						text-align: center;

						h1 {
							margin-left: auto;
							max-width: inherit;
						}

						h5,
						p {
							margin-left: auto;
							max-width: inherit;
						}

						.tagline {
							margin-left: auto;
						}

						form > a {
							float: none;
						}

						a {
							float: none;
						}

						.app-stores {
							float: none;

							a:first-child {
								margin-left: 0;
							}
						}
					}
				}
			}
		}

		&.mobile-img {
			background-image: none;
			padding-top: 0;
			max-height: none;
			height: 100%;

			.g-hero__img {
				&.g-hero__img--mobile-block {
					display: none;
					padding-bottom: 40px;

					@media screen and (max-width: $mobile) {
						display: block;
						position: relative;
					}
				}
			}
		}

		&.text-dark {
			background: $bkgrey;

			.g-hero__text {
				h1 {
					color: $vis-blue;
				}

				h5,
				p {
					color: $vis-grey;
				}
			}
		}
	}
}

.cfaOne-program {
	.warm-white-bckgrd {
		padding: 40px 16px 20px;

		@media screen and (min-width: $mobile) {
			padding: 100px 16px 20px;
		}

		&:first-child {
			padding-top: 50px;
		}
	}

	.block_img_header_txt {
		background: $warm-white;
		margin-bottom: 0;
		padding: 16px;

		@media screen and (max-width: $mobile) {
			text-align: left;
		}

		@media screen and (min-width: $mobile) {
			padding: 35px 0 0;
		}

		&:nth-child(even) {
			flex-direction: row-reverse;

			.content {
				@media screen and (min-width: $mobile) {
					margin: auto 40px;
				}
			}
		}

		&.last {
			padding-bottom: 50px;

			@media screen and (min-width: $mobile) {
				padding-bottom: 0;
			}
		}

		.img-wrapper {
			flex-basis: 40%;
		}

		.content {
			@include font-size(1.6);

			@media screen and (min-width: $mobile) {
				flex-basis: 33.33%;
				margin: auto 0 auto 50px;
			}
		}

		ul li {
			list-style-image: url("img/bullet.svg");
		}

		h3 {
			@include font-size(2.8);
			color: $blue;
		}

		a {
			@include link-underline();
		}
	}

	.nge-membership-tiers {
		.member-tier-benefits {
			border: 1px solid lightgrey;
			border-top: 0;
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
			margin: 0px 20px;
			padding-top: 30px;
		}
	}

	.header-columns-text {
		background: $warm-white;
		max-width: none;

		@media screen and (max-width: $mobile) {
			text-align: left;
		}

		h2 {
			@media screen and (max-width: $mobile) {
				margin-left: 28px;
				text-align: left;
			}
		}

		.flex {
			@media screen and (max-width: $mobile) {
				flex-direction: row;
				flex-wrap: nowrap;
				overflow-x: auto;
				scroll-snap-type: x mandatory;
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}

		dl {
			text-align: left;

			@media screen and (max-width: $mobile) {
				flex: 0 0 auto;
				margin: 0px 5px 30px;
				scroll-snap-align: center;
				width: 95vw;
			}

			a {
				@include font-size(1.6);
				@include link-underline();
			}
		}

		dd:after {
			display: none;
		}
	}
}
