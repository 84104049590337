.app-callout {
	background-color: $warm-white;
	min-height: 600px;
	padding: 40px 25px;
	position: relative;
	z-index: 1;

	.content {
		position: absolute;
		text-align: center;
		transform: translateY(-50%);
		top: 46%;
		z-index: 3;

		@media screen and (min-width: $mobile) {
			max-width: $col7;
			padding-left: 85px;
			text-align: left;
		}

		@media screen and (min-width: $sml-desktop) {
			max-width: $col6;
		}
	}

	h3 {
		@include h3();
		color: $blue;
	}

	.btn {
		margin-bottom: 50px;
		width: 100%;

		@media screen and (min-width: $mobile) {
			margin: -25px 0 45px;
			//margin-top: -25px;
			width: auto;
		}
	}

	.app-stores {
		a:first-child {
			margin-right: 10px;
		}
	}

	.bckgrd-img {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		overflow: hidden;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 2;
	}

	.mobile {
		display: none;

		@media screen and (min-width: $mobile) {
			display: none;
		}
	}

	.desktop {
		//display: none;
		@media screen and (min-width: $mobile) {
			display: block;
		}
	}
}
